import axios from "axios";
import {BaseService} from '../service/api';
import authConstants from '../utils/authConstants';
import broadCastTokenEvent from '../events/tokenAuthrizer';
// import { setBrandId }  from '../utils/appConstants';
import authHelper from '../utils/authHelper';
import  auth   from './authService';

// import logger from "./loggerService";
// import { toast } from "react-toastify";
 
const { tokenKey, authorization, loginInfo, refreshToken, tokenExpireTime, allFeaturesKey, currentFeaturesKey, refreshTokenErrorStatus, signoutStatus }  = authConstants;
const hostname = window && window.location && window.location.hostname;

const axionInstance = axios.create({
  baseURL: BaseService.root
  //baseURL : 'https://api.welbiltdigitaldev.com/'
});

/**
 * Before API request start 
 * Set Headers configurations
 * Set Authorization Token Header on each request
*/
axionInstance.interceptors.request.use(config=>{
  let { customHeaders } = config;
  let token = localStorage.getItem(tokenKey);
  // let token = config.headers[authorization] ? config.headers[authorization] : localStorage.getItem(tokenKey);
  if(config.method.toLowerCase()==="get"){
    if(isIEVesrion()){
      config.url =config.url.indexOf("?") > -1 ? `${config.url}&&refreshCache=${new Date().getTime()}` : `${config.url}?refreshCache=${new Date().getTime()}`;
    }
  }
  //Set Customer Headers
  if(customHeaders!=null){
    for(var key in customHeaders){
      config.headers[key]= customHeaders[key];
    }
  }
  config.headers[authorization] = token;
  return config;
});

/**
 * Transform API response
 * Reset Authorization Token 
 * Retry AJAX call after Athorization failed
*/
axionInstance.interceptors.response.use(

response =>{
  let token = localStorage.getItem(refreshToken);
  let expTime = localStorage.getItem(tokenExpireTime);
  let date = new Date();
  // date.setHours(date.getHours() + 1);
    if (hostname === 'www.welbiltconnect.cn' || hostname === 'welbiltconnect.cn') {
      date.setHours(date.getHours() + 7);console.log('Session timeout increased');
    }
    else { date.setHours(date.getHours() + 1);console.log('Session timeout 1 hour'); }
  let expireTime = expTime ? new Date(expTime) : new Date(date);
  let currentTime = new Date();
  let isExpired = currentTime > expireTime ;
  let rememberMe = localStorage.getItem('RememberMe');
  if (token && !isExpired) {
    localStorage.setItem(tokenExpireTime, date);
  }

  return Promise.resolve(response);
}, 
  
error => {
  if(error && !error.response && error.config && !error.config.__isRetry){
    broadCastTokenEvent.IsBroadCastJwtTokenExpired();
    error.config.__isRetry = true;
  }
  if (error.config && error.config.__retryCount === undefined) {
    error.config.__retryCount = 0;
  }
  // return getNewToken()
  // debugger;
  //const isAuthorizationError = error.response && (error.response.status ==400 || error.response.status ==401 || error.response.status ==403 || error.response.status ==500 || error.response ==undefined);
  let isAuthorizationError=false;
  if (error.response !== undefined)
  {
    isAuthorizationError = (error.response.status ===400 || error.response.status ===401 || error.response.status ===403 || error.response.status ===500);
  }

  let token = localStorage.getItem(refreshToken);

  let expTime = localStorage.getItem(tokenExpireTime);

  let expireTime = new Date(expTime);
  let currentTime = new Date();
  
  let isExpired = currentTime > expireTime ;
  let emulation = localStorage.getItem('emulation');
  let rememberMe = localStorage.getItem('RememberMe');

  if ((rememberMe && token && error.response.status === 403) || (token && !emulation && !isExpired && error.response.status === 403) ) {    
    axios.interceptors.response.eject(axionInstance);
    return getNewToken()
    .then(response => {
      if (response.customerId) {
        error.config.__isRetry = true;
        error.config.headers[authorization] = response.accessToken;
        return axionInstance(error.config);
      } else {
        let errorStatus = localStorage.getItem(refreshTokenErrorStatus)
        if (error.config.__retryCount === 0 && errorStatus === 'false') {
          error.config.__isRetry = true;
          error.config.__retryCount = error.config.__retryCount + 1;
          let token = localStorage.getItem(tokenKey);
          error.config.headers[authorization] = token;
          return axionInstance(error.config);
        } else if (errorStatus === 'false') {
          error.config.__retryCount = error.config.__retryCount + 1;
          localStorage.setItem(refreshTokenErrorStatus, true);
          window.location.reload();
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }

    })
    .catch(function (err) {
      console.log('Refresh login error:: ', err);
      return Promise.reject(err);
    });
  }
  else {
    return Promise.reject(error);
  }
  
  // if (!token && error.config && !error.config.__isRetry) {
  //   console.log('ACCESS TOKEN EXPIRED!');
  //   return getNewToken()
  //   .then(token => {
  //       console.log('TOKEN RECEIVED', token);
  //       error.config.__isRetry = true;
  //       error.config.headers[authorization] =  token;
  //       return axionInstance(error.config);
  //   })
  //   .catch(function () {
  //       console.log('Refresh login error: ', error);
  //       return Promise.reject(error);
  //   });
  // }
  // else{
  //   return Promise.reject(error);
  // }

  // Log Errors 
  // if (!expectedError) {
  //   logger.log(error);
  //   toast.error("An unexpected error occurrred.");
  // }
});

/**
 * Get New Token
 * Through relogin / refresh token
 */
function getNewToken(){
  return new Promise((resolve, reject)=>{
    try {
      let url = `${BaseService.root}` + `${BaseService.refreshToken}`;
      let loginUser =   JSON.parse(localStorage.getItem(loginInfo));
      const body = {
        username: loginUser.username,
        refreshToken: localStorage.getItem(refreshToken)
      };
      axios({
        url: url,
        method: 'POST',
        data: body,
        headers : {
          "content-type" : "application/json" 
        }
      }).then(response =>{
        let { data } = response;
        let isSignout = localStorage.getItem(signoutStatus);
        if (data.customerId && isSignout !== 'true') {
          auth.setLocalStorage(data);
        }
        if (isSignout === 'true' || !isSignout) {
          if(localStorage.getItem('isCookieAccepted')){
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
          }else{
              localStorage.clear();
          }
        }
        resolve(data);
      }).catch(err => {
        // console.log(", err.rr ",err.response);
        // if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
          localStorage.removeItem(refreshToken);
          window.location.reload();
        // }
        reject(err)
      })
    } catch {
      reject("Authorization Error");
    }
  });
}

axionInstance.setJwt = function(token){
  axionInstance.defaults.headers[authorization] = token;
};

function isIEVesrion(){
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older 
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // var edge = ua.indexOf('Edge/');
  // if (edge > 0) {
  //   // Edge (IE 12+)
  //   return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  // }

  // other browser
  return false;
}


export default axionInstance;

