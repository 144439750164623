import React, { PureComponent } from 'react';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import $ from 'jquery';


const yamlFiles = [{
    fileName: "deviceManagement",
    ymlFileName: "device-management-swagger.yaml"
},
{
    fileName: "organizationManagement",
    ymlFileName: "organization-management-swagger.yaml"
},
{
    fileName: "filesManagement",
    ymlFileName: "files-management-swagger.yaml"
},
{
    fileName: "productionManagement",
    ymlFileName: "production-management-swagger.yaml"
},
{
    fileName: "pushDataManagement",
    ymlFileName: "push-data-configuration-management-swagger.yaml"
},
{
    fileName: "huePhilipsApi",
    ymlFileName: "lights-api-swagger.yaml"
}]

class SwaggerInterface extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            showFileUrl: "",
        }
    }

    getSwaggerEnv() {
        const hostname = window && window.location && window.location.hostname;
        let swaggerEnvHost = '';
        if (hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com') {
            //For Development Environment
            swaggerEnvHost = './SwaggerUI/yaml/dev/';
        } else if (hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com') {
            //For QA Environment
            swaggerEnvHost = './SwaggerUI/yaml/qa/';
        } else if (hostname === 'www.welbiltdigitaluat.com' || hostname === 'welbiltdigitaluat.com') {
            //For UAT Environment
            swaggerEnvHost = './SwaggerUI/yaml/uat/';
        } else if (hostname === 'www.welbiltconnect.com' || hostname === 'welbiltconnect.com') {
            //For Production Environment
            swaggerEnvHost = './SwaggerUI/yaml/prod/';
        } else if (hostname === 'www.welbiltdigitalintegration.com' || hostname === 'welbiltdigitalintegration.com') {
            //For Integration Environment
            swaggerEnvHost = './SwaggerUI/yaml/int/';
        } else if (hostname === 'www.welbiltconnect.cn' || hostname === 'welbiltconnect.cn') {
            //For China PROD Environment
            swaggerEnvHost = './SwaggerUI/yaml/china-prod/';
        }else {
            //For Local Environment
            swaggerEnvHost = './SwaggerUI/yaml/dev/';
        }
        return swaggerEnvHost;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        window.addEventListener('resize', this.updateDimensions());
        $(document).on('click', "#CountryInfoModalBtn", function () {
            $('#CountryInfoModal').modal('show');
        });

        $(document).on('click', "#TimeZoneInfoModalBtn", function () {
            $('#TimeZoneInfoModal').modal('show');
        });

        $(document).on('click', "#ResponseCodeInfoModalBtn", function () {
            $('#ResponseCodeInfoModal').modal('show');
        });

        const showSwaggerView = this.getSwaggerEnv();        
        const fileObject = yamlFiles.find(item => item.fileName === this.props.location.fileName );
        const urlRouter = fileObject ? `${showSwaggerView}${fileObject.ymlFileName}` : `${showSwaggerView}${localStorage.getItem("ymlFileName")}`;
        this.setState({
            showFileUrl: urlRouter
        })
    }

    updateDimensions() {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody && $tableBody.offset() && $tableBody.offset().top));
	}

	componentDidUpdate = () => {
		this.updateDimensions();
	}

    render() {
        const { showFileUrl } = this.state;
        return (
            <React.Fragment>
                <div id="adminWrapper">

                    <div class="modal fade swaggerInfoModal" data-keyboard="true" data-backdrop="static" id="CountryInfoModal" tabindex="-1" role="dialog" aria-labelledby="errorInfoModalLabel">
                        <div class="modal-dialog modal-lg swaggerInfoModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                Country and Country ISO list <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                                </div>
                                <div class="modal-body">
                                    <form class="CountryListForm">
                                        <div class="countryListTable">
                                            <ul class="tHead">
                                                <li>#</li>
                                                <li>Country</li>
                                                <li>Continent</li>
                                                <li>Country ISO Alpha 2 Code</li>
                                            </ul>
                                        </div>
                                        <div class="countryListTableS">
                                            <div class="countryListTable">
                                                <ul class="tBody">
                                                    <li>01</li>
                                                    <li>Algeria</li>
                                                    <li>Africa</li>
                                                    <li>DZ</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>02</li>
                                                    <li>Angola</li>
                                                    <li>Africa</li>
                                                    <li>AO</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>03</li>
                                                    <li>Benin</li>
                                                    <li>Africa</li>
                                                    <li>BJ</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>04</li>
                                                    <li>Botswana</li>
                                                    <li>Africa</li>
                                                    <li>BW</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>05</li>
                                                    <li>Burkina Faso</li>
                                                    <li>Africa</li>
                                                    <li>BF</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>06</li>
                                                    <li>Burundi</li>
                                                    <li>Africa</li>
                                                    <li>BI</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>07</li>
                                                    <li>Cameroon</li>
                                                    <li>Africa</li>
                                                    <li>CM</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>08</li>
                                                    <li>Capo Verde</li>
                                                    <li>Africa</li>
                                                    <li>CV</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>09</li>
                                                    <li>Central African Republic</li>
                                                    <li>Africa</li>
                                                    <li>CF</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>10</li>
                                                    <li>Chad</li>
                                                    <li>Africa</li>
                                                    <li>TD</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>11</li>
                                                    <li>Comoros</li>
                                                    <li>Africa</li>
                                                    <li>KM</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>12</li>
                                                    <li>Congo</li>
                                                    <li>Africa</li>
                                                    <li>CG</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>13</li>
                                                    <li>Congo, Democratic Republic of</li>
                                                    <li>Africa</li>
                                                    <li>CD</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>14</li>
                                                    <li>Djibouti</li>
                                                    <li>Africa</li>
                                                    <li>DJ</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>15</li>
                                                    <li>Egypt</li>
                                                    <li>Africa</li>
                                                    <li>EG</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>16</li>
                                                    <li>Equatorial Guinea</li>
                                                    <li>Africa</li>
                                                    <li>GQ</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>17</li>
                                                    <li>Eritrea</li>
                                                    <li>Africa</li>
                                                    <li>ER</li>
                                                </ul>
                                                <ul class="tBody">
                                                    <li>18</li>
                                                    <li>Ethiopia</li>
                                                    <li>Africa</li>
                                                    <li>ET</li>
                                                </ul>
                                                <ul class="tBody"> <li>19</li> <li>Gabon</li> <li>Africa</li> <li>GA</li></ul>
                                                <ul class="tBody"> <li>20</li> <li>Gambia</li> <li>Africa</li> <li>GM</li></ul>
                                                <ul class="tBody"> <li>21</li> <li>Ghana</li> <li>Africa</li> <li>GH</li></ul>
                                                <ul class="tBody"> <li>22</li> <li>Guinea</li> <li>Africa</li> <li>GN</li></ul>
                                                <ul class="tBody"> <li>23</li> <li>Guinea-Bissau</li> <li>Africa</li> <li>GW</li></ul>
                                                <ul class="tBody"> <li>24</li> <li>Ivory Coast</li> <li>Africa</li> <li>CI</li></ul>
                                                <ul class="tBody"> <li>25</li> <li>Kenya</li> <li>Africa</li> <li>KE</li></ul>
                                                <ul class="tBody"> <li>26</li> <li>Lesotho</li> <li>Africa</li> <li>LS</li></ul>
                                                <ul class="tBody"> <li>27</li> <li>Liberia</li> <li>Africa</li> <li>LR</li></ul>
                                                <ul class="tBody"> <li>28</li> <li>Libya</li> <li>Africa</li> <li>LY</li></ul>
                                                <ul class="tBody"> <li>29</li> <li>Madagascar</li> <li>Africa</li> <li>MG</li></ul>
                                                <ul class="tBody"> <li>30</li> <li>Malawi</li> <li>Africa</li> <li>MW</li></ul>
                                                <ul class="tBody"> <li>31</li> <li>Mali</li> <li>Africa</li> <li>ML</li></ul>
                                                <ul class="tBody"> <li>32</li> <li>Mauritania</li> <li>Africa</li> <li>MR</li></ul>
                                                <ul class="tBody"> <li>33</li> <li>Mauritius</li> <li>Africa</li> <li>MU</li></ul>
                                                <ul class="tBody"> <li>34</li> <li>Morocco</li> <li>Africa</li> <li>MA</li></ul>
                                                <ul class="tBody"> <li>35</li> <li>Mozambique</li> <li>Africa</li> <li>MZ</li></ul>
                                                <ul class="tBody"> <li>36</li> <li>Namibia</li> <li>Africa</li> <li>NA</li></ul>
                                                <ul class="tBody"> <li>37</li> <li>Niger</li> <li>Africa</li> <li>NE</li></ul>
                                                <ul class="tBody"> <li>38</li> <li>Nigeria</li> <li>Africa</li> <li>NG</li></ul>
                                                <ul class="tBody"> <li>39</li> <li>Rwanda</li> <li>Africa</li> <li>RW</li></ul>
                                                <ul class="tBody"> <li>40</li> <li>Sao Tome and Principe</li> <li>Africa</li> <li>ST</li></ul>
                                                <ul class="tBody"> <li>41</li> <li>Senegal</li> <li>Africa</li> <li>SN</li></ul>
                                                <ul class="tBody"> <li>42</li> <li>Seychelles</li> <li>Africa</li> <li>SC</li></ul>
                                                <ul class="tBody"> <li>43</li> <li>Sierra Leone</li> <li>Africa</li> <li>SL</li></ul>
                                                <ul class="tBody"> <li>44</li> <li>Somalia</li> <li>Africa</li> <li>SO</li></ul>
                                                <ul class="tBody"> <li>45</li> <li>South Africa</li> <li>Africa</li> <li>ZA</li></ul>
                                                <ul class="tBody"> <li>46</li> <li>South Sudan</li> <li>Africa</li> <li>SS</li></ul>
                                                <ul class="tBody"> <li>47</li> <li>Sudan</li> <li>Africa</li> <li>SD</li></ul>
                                                <ul class="tBody"> <li>48</li> <li>Swaziland</li> <li>Africa</li> <li>SZ</li></ul>
                                                <ul class="tBody"> <li>49</li> <li>Tanzania</li> <li>Africa</li> <li>TZ</li></ul>
                                                <ul class="tBody"> <li>50</li> <li>Togo</li> <li>Africa</li> <li>TG</li></ul>
                                                <ul class="tBody"> <li>51</li> <li>Tunisia</li> <li>Africa</li> <li>TN</li></ul>
                                                <ul class="tBody"> <li>52</li> <li>Uganda</li> <li>Africa</li> <li>UG</li></ul>
                                                <ul class="tBody"> <li>53</li> <li>Zambia</li> <li>Africa</li> <li>ZM</li></ul>
                                                <ul class="tBody"> <li>54</li> <li>Zimbabwe</li> <li>Africa</li> <li>ZW</li></ul>
                                                <ul class="tBody"> <li>55</li> <li>Afghanistan</li> <li>Asia</li> <li>AF</li></ul>
                                                <ul class="tBody"> <li>56</li> <li>Bahrain</li> <li>Asia</li> <li>BH</li></ul>
                                                <ul class="tBody"> <li>57</li> <li>Bangladesh</li> <li>Asia</li> <li>BD</li></ul>
                                                <ul class="tBody"> <li>58</li> <li>Bhutan</li> <li>Asia</li> <li>BT</li></ul>
                                                <ul class="tBody"> <li>59</li> <li>Brunei</li> <li>Asia</li> <li>BN</li></ul>
                                                <ul class="tBody"> <li>60</li> <li>Burma (Myanmar)</li> <li>Asia</li> <li>MM</li></ul>
                                                <ul class="tBody"> <li>61</li> <li>Cambodia</li> <li>Asia</li> <li>KH</li></ul>
                                                <ul class="tBody"> <li>62</li> <li>China</li> <li>Asia</li> <li>CN</li></ul>
                                                <ul class="tBody"> <li>63</li> <li>East Timor</li> <li>Asia</li> <li>TL</li></ul>
                                                <ul class="tBody"> <li>64</li> <li>India</li> <li>Asia</li> <li>IN</li></ul>
                                                <ul class="tBody"> <li>65</li> <li>Indonesia</li> <li>Asia</li> <li>ID</li></ul>
                                                <ul class="tBody"> <li>66</li> <li>Iran</li> <li>Asia</li> <li>IR</li></ul>
                                                <ul class="tBody"> <li>67</li> <li>Iraq</li> <li>Asia</li> <li>IQ</li></ul>
                                                <ul class="tBody"> <li>68</li> <li>Israel</li> <li>Asia</li> <li>IL</li></ul>
                                                <ul class="tBody"> <li>69</li> <li>Japan</li> <li>Asia</li> <li>JP</li></ul>
                                                <ul class="tBody"> <li>70</li> <li>Jordan</li> <li>Asia</li> <li>JO</li></ul>
                                                <ul class="tBody"> <li>71</li> <li>Kazakhstan</li> <li>Asia</li> <li>KZ</li></ul>
                                                <ul class="tBody"> <li>72</li> <li>Korea, North</li> <li>Asia</li> <li>KP</li></ul>
                                                <ul class="tBody"> <li>73</li> <li>Korea, South</li> <li>Asia</li> <li>KR</li></ul>
                                                <ul class="tBody"> <li>74</li> <li>Kuwait</li> <li>Asia</li> <li>KW</li></ul>
                                                <ul class="tBody"> <li>75</li> <li>Kyrgyzstan</li> <li>Asia</li> <li>KG</li></ul>
                                                <ul class="tBody"> <li>76</li> <li>Laos</li> <li>Asia</li> <li>LA</li></ul>
                                                <ul class="tBody"> <li>77</li> <li>Lebanon</li> <li>Asia</li> <li>LB</li></ul>
                                                <ul class="tBody"> <li>78</li> <li>Malaysia</li> <li>Asia</li> <li>MY</li></ul>
                                                <ul class="tBody"> <li>79</li> <li>Maldives</li> <li>Asia</li> <li>MV</li></ul>
                                                <ul class="tBody"> <li>80</li> <li>Mongolia</li> <li>Asia</li> <li>MN</li></ul>
                                                <ul class="tBody"> <li>81</li> <li>Nepal</li> <li>Asia</li> <li>NP</li></ul>
                                                <ul class="tBody"> <li>82</li> <li>Oman</li> <li>Asia</li> <li>OM</li></ul>
                                                <ul class="tBody"> <li>83</li> <li>Pakistan</li> <li>Asia</li> <li>PK</li></ul>
                                                <ul class="tBody"> <li>84</li> <li>Philippines</li> <li>Asia</li> <li>PH</li></ul>
                                                <ul class="tBody"> <li>85</li> <li>Qatar</li> <li>Asia</li> <li>QA</li></ul>
                                                <ul class="tBody"> <li>86</li> <li>Russian Federation</li> <li>Asia</li> <li>RU</li></ul>
                                                <ul class="tBody"> <li>87</li> <li>Saudi Arabia</li> <li>Asia</li> <li>SA</li></ul>
                                                <ul class="tBody"> <li>88</li> <li>Singapore</li> <li>Asia</li> <li>SG</li></ul>
                                                <ul class="tBody"> <li>89</li> <li>Sri Lanka</li> <li>Asia</li> <li>LK</li></ul>
                                                <ul class="tBody"> <li>90</li> <li>Syria</li> <li>Asia</li> <li>SY</li></ul>
                                                <ul class="tBody"> <li>91</li> <li>Tajikistan</li> <li>Asia</li> <li>TJ</li></ul>
                                                <ul class="tBody"> <li>92</li> <li>Thailand</li> <li>Asia</li> <li>TH</li></ul>
                                                <ul class="tBody"> <li>93</li> <li>Turkey</li> <li>Asia</li> <li>TR</li></ul>
                                                <ul class="tBody"> <li>94</li> <li>Turkmenistan</li> <li>Asia</li> <li>TM</li></ul>
                                                <ul class="tBody"> <li>95</li> <li>United Arab Emirates</li> <li>Asia</li> <li>AE</li></ul>
                                                <ul class="tBody"> <li>96</li> <li>Uzbekistan</li> <li>Asia</li> <li>UZ</li></ul>
                                                <ul class="tBody"> <li>97</li> <li>Vietnam</li> <li>Asia</li> <li>VN</li></ul>
                                                <ul class="tBody"> <li>98</li> <li>Yemen</li> <li>Asia</li> <li>YE</li></ul>
                                                <ul class="tBody"> <li>99</li> <li>Albania</li> <li>Europe</li> <li>AL</li></ul>
                                                <ul class="tBody"> <li>100</li> <li>Andorra</li> <li>Europe</li> <li>AD</li></ul>
                                                <ul class="tBody"> <li>101</li> <li>Armenia</li> <li>Europe</li> <li>AM</li></ul>
                                                <ul class="tBody"> <li>102</li> <li>Austria</li> <li>Europe</li> <li>AT</li></ul>
                                                <ul class="tBody"> <li>103</li> <li>Azerbaijan</li> <li>Europe</li> <li>AZ</li></ul>
                                                <ul class="tBody"> <li>104</li> <li>Belarus</li> <li>Europe</li> <li>BY</li></ul>
                                                <ul class="tBody"> <li>105</li> <li>Belgium</li> <li>Europe</li> <li>BE</li></ul>
                                                <ul class="tBody"> <li>106</li> <li>Bosnia and Herzegovina</li> <li>Europe</li> <li>BA</li></ul>
                                                <ul class="tBody"> <li>107</li> <li>Bulgaria</li> <li>Europe</li> <li>BG</li></ul>
                                                <ul class="tBody"> <li>108</li> <li>Croatia</li> <li>Europe</li> <li>HR</li></ul>
                                                <ul class="tBody"> <li>109</li> <li>Cyprus</li> <li>Europe</li> <li>CY</li></ul>
                                                <ul class="tBody"> <li>110</li> <li>Czech Republic</li> <li>Europe</li> <li>CZ</li></ul>
                                                <ul class="tBody"> <li>111</li> <li>Denmark</li> <li>Europe</li> <li>DK</li></ul>
                                                <ul class="tBody"> <li>112</li> <li>Estonia</li> <li>Europe</li> <li>EE</li></ul>
                                                <ul class="tBody"> <li>113</li> <li>Finland</li> <li>Europe</li> <li>FI</li></ul>
                                                <ul class="tBody"> <li>114</li> <li>France</li> <li>Europe</li> <li>FR</li></ul>
                                                <ul class="tBody"> <li>115</li> <li>Georgia</li> <li>Europe</li> <li>GE</li></ul>
                                                <ul class="tBody"> <li>116</li> <li>Germany</li> <li>Europe</li> <li>DE</li></ul>
                                                <ul class="tBody"> <li>117</li> <li>Greece</li> <li>Europe</li> <li>GR</li></ul>
                                                <ul class="tBody"> <li>118</li> <li>Hungary</li> <li>Europe</li> <li>HU</li></ul>
                                                <ul class="tBody"> <li>119</li> <li>Iceland</li> <li>Europe</li> <li>IS</li></ul>
                                                <ul class="tBody"> <li>120</li> <li>Ireland</li> <li>Europe</li> <li>IE</li></ul>
                                                <ul class="tBody"> <li>121</li> <li>Italy</li> <li>Europe</li> <li>IT</li></ul>
                                                <ul class="tBody"> <li>122</li> <li>Latvia</li> <li>Europe</li> <li>LV</li></ul>
                                                <ul class="tBody"> <li>123</li> <li>Liechtenstein</li> <li>Europe</li> <li>LI</li></ul>
                                                <ul class="tBody"> <li>124</li> <li>Lithuania</li> <li>Europe</li> <li>LT</li></ul>
                                                <ul class="tBody"> <li>125</li> <li>Luxembourg</li> <li>Europe</li> <li>LU</li></ul>
                                                <ul class="tBody"> <li>126</li> <li>Macedonia</li> <li>Europe</li> <li>MK</li></ul>
                                                <ul class="tBody"> <li>127</li> <li>Malta</li> <li>Europe</li> <li>MT</li></ul>
                                                <ul class="tBody"> <li>128</li> <li>Moldova</li> <li>Europe</li> <li>MD</li></ul>
                                                <ul class="tBody"> <li>129</li> <li>Monaco</li> <li>Europe</li> <li>MC</li></ul>
                                                <ul class="tBody"> <li>130</li> <li>Montenegro</li> <li>Europe</li> <li>ME</li></ul>
                                                <ul class="tBody"> <li>131</li> <li>Netherlands</li> <li>Europe</li> <li>NL</li></ul>
                                                <ul class="tBody"> <li>132</li> <li>Norway</li> <li>Europe</li> <li>NO</li></ul>
                                                <ul class="tBody"> <li>133</li> <li>Poland</li> <li>Europe</li> <li>PL</li></ul>
                                                <ul class="tBody"> <li>134</li> <li>Portugal</li> <li>Europe</li> <li>PT</li></ul>
                                                <ul class="tBody"> <li>135</li> <li>Romania</li> <li>Europe</li> <li>RO</li></ul>
                                                <ul class="tBody"> <li>136</li> <li>San Marino</li> <li>Europe</li> <li>SM</li></ul>
                                                <ul class="tBody"> <li>137</li> <li>Serbia</li> <li>Europe</li> <li>RS</li></ul>
                                                <ul class="tBody"> <li>138</li> <li>Slovakia</li> <li>Europe</li> <li>SK</li></ul>
                                                <ul class="tBody"> <li>139</li> <li>Slovenia</li> <li>Europe</li> <li>SI</li></ul>
                                                <ul class="tBody"> <li>140</li> <li>Spain</li> <li>Europe</li> <li>ES</li></ul>
                                                <ul class="tBody"> <li>141</li> <li>Sweden</li> <li>Europe</li> <li>SE</li></ul>
                                                <ul class="tBody"> <li>142</li> <li>Switzerland</li> <li>Europe</li> <li>CH</li></ul>
                                                <ul class="tBody"> <li>143</li> <li>Ukraine</li> <li>Europe</li> <li>UA</li></ul>
                                                <ul class="tBody"> <li>144</li> <li>United Kingdom</li> <li>Europe</li> <li>GB</li></ul>
                                                <ul class="tBody"> <li>145</li> <li>Vatican City</li> <li>Europe</li> <li>VA</li></ul>
                                                <ul class="tBody"> <li>146</li> <li>Antigua and Barbuda</li> <li>N.America</li> <li>AG</li></ul>
                                                <ul class="tBody"> <li>147</li> <li>Bahamas</li> <li>N.America</li> <li>BS</li></ul>
                                                <ul class="tBody"> <li>148</li> <li>Barbados</li> <li>N.America</li> <li>BB</li></ul>
                                                <ul class="tBody"> <li>149</li> <li>Belize</li> <li>N.America</li> <li>BZ</li></ul>
                                                <ul class="tBody"> <li>150</li> <li>Canada</li> <li>N.America</li> <li>CA</li></ul>
                                                <ul class="tBody"> <li>151</li> <li>Costa Rica</li> <li>N.America</li> <li>CR</li></ul>
                                                <ul class="tBody"> <li>152</li> <li>Cuba</li> <li>N.America</li> <li>CU</li></ul>
                                                <ul class="tBody"> <li>153</li> <li>Dominica</li> <li>N.America</li> <li>DM</li></ul>
                                                <ul class="tBody"> <li>154</li> <li>Dominican Republic</li> <li>N.America</li> <li>DO</li></ul>
                                                <ul class="tBody"> <li>155</li> <li>El Salvador</li> <li>N.America</li> <li>SV</li></ul>
                                                <ul class="tBody"> <li>156</li> <li>Grenada</li> <li>N.America</li> <li>GD</li></ul>
                                                <ul class="tBody"> <li>157</li> <li>Guatemala</li> <li>N.America</li> <li>GT</li></ul>
                                                <ul class="tBody"> <li>158</li> <li>Haiti</li> <li>N.America</li> <li>HT</li></ul>
                                                <ul class="tBody"> <li>159</li> <li>Honduras</li> <li>N.America</li> <li>HN</li></ul>
                                                <ul class="tBody"> <li>160</li> <li>Jamaica</li> <li>N.America</li> <li>JM</li></ul>
                                                <ul class="tBody"> <li>161</li> <li>Mexico</li> <li>N.America</li> <li>MX</li></ul>
                                                <ul class="tBody"> <li>162</li> <li>Nicaragua</li> <li>N.America</li> <li>NI</li></ul>
                                                <ul class="tBody"> <li>163</li> <li>Panama</li> <li>N.America</li> <li>PA</li></ul>
                                                <ul class="tBody"> <li>164</li> <li>Saint Kitts and Nevis</li> <li>N.America</li> <li>KN</li></ul>
                                                <ul class="tBody"> <li>165</li> <li>Saint Lucia</li> <li>N.America</li> <li>LC</li></ul>
                                                <ul class="tBody"> <li>166</li> <li>Saint Vincent and the Grenadines</li> <li>N.America</li> <li>VC</li></ul>
                                                <ul class="tBody"> <li>167</li> <li>Trinidad and Tobago</li> <li>N.America</li> <li>TT</li></ul>
                                                <ul class="tBody"> <li>168</li> <li>United States</li> <li>N.America</li> <li>US</li></ul>
                                                <ul class="tBody"> <li>169</li> <li>Australia</li> <li>Oceania/Australia</li> <li>AU</li></ul>
                                                <ul class="tBody"> <li>170</li> <li>Fiji</li> <li>Oceania/Australia</li> <li>FJ</li></ul>
                                                <ul class="tBody"> <li>171</li> <li>Kiribati</li> <li>Oceania/Australia</li> <li>KI</li></ul>
                                                <ul class="tBody"> <li>172</li> <li>Marshall Islands</li> <li>Oceania/Australia</li> <li>MH</li></ul>
                                                <ul class="tBody"> <li>173</li> <li>Micronesia</li> <li>Oceania/Australia</li> <li>FM</li></ul>
                                                <ul class="tBody"> <li>174</li> <li>Nauru</li> <li>Oceania/Australia</li> <li>NR</li></ul>
                                                <ul class="tBody"> <li>175</li> <li>New Zealand</li> <li>Oceania/Australia</li> <li>NZ</li></ul>
                                                <ul class="tBody"> <li>176</li> <li>Palau</li> <li>Oceania/Australia</li> <li>PW</li></ul>
                                                <ul class="tBody"> <li>177</li> <li>Papua New Guinea</li> <li>Oceania/Australia</li> <li>PG</li></ul>
                                                <ul class="tBody"> <li>178</li> <li>Samoa</li> <li>Oceania/Australia</li> <li>WS</li></ul>
                                                <ul class="tBody"> <li>179</li> <li>Solomon Islands</li> <li>Oceania/Australia</li> <li>SB</li></ul>
                                                <ul class="tBody"> <li>180</li> <li>Tonga</li> <li>Oceania/Australia</li> <li>TO</li></ul>
                                                <ul class="tBody"> <li>181</li> <li>Tuvalu</li> <li>Oceania/Australia</li> <li>TV</li></ul>
                                                <ul class="tBody"> <li>182</li> <li>Vanuatu</li> <li>Oceania/Australia</li> <li>VU</li></ul>
                                                <ul class="tBody"> <li>183</li> <li>Argentina</li> <li>S.America</li> <li>AR</li></ul>
                                                <ul class="tBody"> <li>184</li> <li>Bolivia</li> <li>S.America</li> <li>BO</li></ul>
                                                <ul class="tBody"> <li>185</li> <li>Brazil</li> <li>S.America</li> <li>BR</li></ul>
                                                <ul class="tBody"> <li>186</li> <li>Chile</li> <li>S.America</li> <li>CL</li></ul>
                                                <ul class="tBody"> <li>187</li> <li>Colombia</li> <li>S.America</li> <li>CO</li></ul>
                                                <ul class="tBody"> <li>188</li> <li>Ecuador</li> <li>S.America</li> <li>EC</li></ul>
                                                <ul class="tBody"> <li>189</li> <li>Guyana</li> <li>S.America</li> <li>GY</li></ul>
                                                <ul class="tBody"> <li>190</li> <li>Paraguay</li> <li>S.America</li> <li>PY</li></ul>
                                                <ul class="tBody"> <li>191</li> <li>Peru</li> <li>S.America</li> <li>PE</li></ul>
                                                <ul class="tBody"> <li>192</li> <li>Suriname</li> <li>S.America</li> <li>SR</li></ul>
                                                <ul class="tBody"> <li>193</li> <li>Uruguay</li> <li>S.America</li> <li>UY</li></ul>
                                                <ul class="tBody"> <li>194</li> <li>Venezuela</li> <li>S.America</li> <li>VE</li></ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Time Zone Info Modal --> */}
                    <div class="modal fade swaggerInfoModal" data-keyboard="true" data-backdrop="static" id="TimeZoneInfoModal" tabindex="-1" role="dialog" aria-labelledby="errorInfoModalLabel">
                        <div class="modal-dialog modal-lg swaggerInfoModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                TimezoneID list <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                                </div>
                                <div class="modal-body">
                                    <form class="CountryListForm">
                                        <div class="timeZoneListTable">
                                            <ul class="tHead">
                                                <li>TimezoneID</li>
                                                <li>Country</li>
                                                <li>Standard Name</li>
                                                <li>Display Name</li>
                                            </ul>
                                        </div>
                                        <div class="countryListTableS">
                                            <div class="timeZoneListTable">                                                                                            
                                                <ul class="tBody"><li>01</li><li>Baker Island</li><li>Etc/GMT+12</li><li>(UTC-12:00) International Date Line West</li></ul>
                                                <ul class="tBody"><li>02</li><li>American Samoa</li><li>Etc/GMT+11</li><li>(UTC-11:00) Coordinated Universal Time-11</li></ul>
                                                <ul class="tBody"><li>03</li><li>United States</li><li>US/Aleutian</li><li>(UTC-10:00) Aleutian Islands</li></ul>
                                                <ul class="tBody"><li>04</li><li>Cook Islands</li><li>US/Hawaii</li><li>(UTC-10:00) Hawaii</li></ul>
                                                <ul class="tBody"><li>05</li><li>French Polynesia</li><li>Pacific/Marquesas</li><li>(UTC-09:30) Marquesas Islands</li></ul>
                                                <ul class="tBody"><li>06</li><li>United States</li><li>US/Alaska</li><li>(UTC-09:00) Alaska</li></ul>
                                                <ul class="tBody"><li>07</li><li>Alaska</li><li>Etc/GMT+9</li><li>(UTC-09:00) Coordinated Universal Time-09</li></ul>
                                                <ul class="tBody"><li>08</li><li>Mexico</li><li>Mexico/BajaNorte</li><li>(UTC-08:00) Baja California</li></ul>
                                                <ul class="tBody"><li>09</li><li>British Columbia</li><li>Etc/GMT+8</li><li>(UTC-08:00) Coordinated Universal Time-08</li></ul>
                                                <ul class="tBody"><li>10</li><li>California</li><li>PST8PDT</li><li>(UTC-08:00) Pacific Time (US & Canada)</li></ul>
                                                <ul class="tBody"><li>11</li><li>United States</li><li>US/Arizona</li><li>(UTC-07:00) Arizona</li></ul>
                                                <ul class="tBody"><li>12</li><li>Mexico</li><li>America/Chihuahua</li><li>(UTC-07:00) Chihuahua, La Paz, Mazatlan</li></ul>
                                                <ul class="tBody"><li>13</li><li>Alberta</li><li>MST7MDT</li><li>(UTC-07:00) Mountain Time (US & Canada)</li></ul>
                                                <ul class="tBody"><li>14</li><li>Belize</li><li>Etc/GMT+6</li><li>(UTC-06:00) Central America</li></ul>
                                                <ul class="tBody"><li>15</li><li>Manitoba</li><li>CST6CDT</li><li>(UTC-06:00) Central Time (US & Canada)</li></ul>
                                                <ul class="tBody"><li>16</li><li>Chile</li><li>Pacific/Easter</li><li>(UTC-06:00) Easter Island</li></ul>
                                                <ul class="tBody"><li>17</li><li>Mexico</li><li>America/Mexico_City</li><li>(UTC-06:00) Guadalajara, Mexico City, Monterrey</li></ul>
                                                <ul class="tBody"><li>18</li><li>Canada</li><li>Canada/Saskatchewan</li><li>(UTC-06:00) Saskatchewan</li></ul>
                                                <ul class="tBody"><li>19</li><li>Brazil</li><li>America/Rio_Branco</li><li>(UTC-05:00) Bogota, Lima, Quito, Rio Branco</li></ul>
                                                <ul class="tBody"><li>20</li><li>Mexico</li><li>EST5EDT</li><li>(UTC-05:00) Chetumal</li></ul>
                                                <ul class="tBody"><li>21</li><li>Canada</li><li>EST5EDT</li><li>(UTC-05:00) Eastern Time (US & Canada)</li></ul>
                                                <ul class="tBody"><li>22</li><li>Haiti</li><li>EST5EDT</li><li>(UTC-05:00) Haiti</li></ul>
                                                <ul class="tBody"><li>23</li><li>Cuba</li><li>America/Havana</li><li>(UTC-05:00) Havana</li></ul>
                                                <ul class="tBody"><li>24</li><li>United States</li><li>America/Indiana/Indianapolis</li><li>(UTC-05:00) Indiana (East)</li></ul>
                                                <ul class="tBody"><li>25</li><li>Paraguay</li><li>America/Asuncion</li><li>(UTC-04:00) Asuncion</li></ul>
                                                <ul class="tBody"><li>26</li><li>Bermuda</li><li>Atlantic/Bermuda</li><li>(UTC-04:00) Atlantic Time (Canada)</li></ul>
                                                <ul class="tBody"><li>27</li><li>Bolivarian Republic of Venezuela</li><li>America/Caracas</li><li>(UTC-04:00) Caracas</li></ul>
                                                <ul class="tBody"><li>28</li><li>Brazil</li><li>America/Cuiaba</li><li>(UTC-04:00) Cuiaba</li></ul>
                                                <ul class="tBody"><li>29</li><li>Bolivia, Plurinational State of</li><li>America/La_Paz</li><li>(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</li></ul>
                                                <ul class="tBody"><li>30</li><li>Chile</li><li>America/Santiago</li><li>(UTC-04:00) Santiago</li></ul>
                                                <ul class="tBody"><li>31</li><li>Canada</li><li>America/Glace_Bay</li><li>(UTC-04:00) Turks and Caicos</li></ul>
                                                <ul class="tBody"><li>32</li><li>Canada</li><li>America/St_Johns</li><li>(UTC-03:30) Newfoundland</li></ul>
                                                <ul class="tBody"><li>33</li><li>Brazil</li><li>America/Araguaina</li><li>(UTC-03:00) Araguaina</li></ul>
                                                <ul class="tBody"><li>34</li><li>Brazil</li><li>Brazil/East</li><li>(UTC-03:00) Brasilia</li></ul>
                                                <ul class="tBody"><li>35</li><li>Brazil</li><li>America/Fortaleza</li><li>(UTC-03:00) Cayenne, Fortaleza</li></ul>
                                                <ul class="tBody"><li>36</li><li>Argentina</li><li>America/Argentina/Buenos_Aires</li><li>(UTC-03:00) City of Buenos Aires</li></ul>
                                                <ul class="tBody"><li>37</li><li>Greenland</li><li>America/Godthab</li><li>(UTC-03:00) Greenland</li></ul>
                                                <ul class="tBody"><li>38</li><li>Uruguay</li><li>America/Montevideo</li><li>(UTC-03:00) Montevideo</li></ul>
                                                <ul class="tBody"><li>39</li><li>Chile</li><li>America/Punta_Arenas</li><li>(UTC-03:00) Punta Arenas</li></ul>
                                                <ul class="tBody"><li>40</li><li>Saint Pierre and Miquelon</li><li>America/Miquelon</li><li>(UTC-03:00) Saint Pierre and Miquelon</li></ul>
                                                <ul class="tBody"><li>41</li><li>Brazil</li><li>Etc/GMT+3</li><li>(UTC-03:00) Salvador</li></ul>
                                                <ul class="tBody"><li>42</li><li>South Georgia and the South Sandwich Islands</li><li>Etc/GMT+2</li><li>(UTC-02:00) Coordinated Universal Time-02</li></ul>
                                                <ul class="tBody"><li>43</li><li>South Georgia and the South Sandwich Islands</li><li>Atlantic/South_Georgia</li><li>(UTC-02:00) Mid-Atlantic - Old</li></ul>
                                                <ul class="tBody"><li>44</li><li>Portugal</li><li>Atlantic/Azores</li><li>(UTC-01:00) Azores</li></ul>
                                                <ul class="tBody"><li>45</li><li>Cape Verde</li><li>Atlantic/Cape_Verde</li><li>(UTC-01:00) Cabo Verde Is.</li></ul>
                                                <ul class="tBody"><li>46</li><li>"Bouvet Island	"</li><li>Etc/UTC</li><li>(UTC) Coordinated Universal Time</li></ul>
                                                <ul class="tBody"><li>47</li><li>United Kingdom</li><li>Europe/London</li><li>(UTC+00:00) Dublin, Edinburgh, Lisbon, London</li></ul>
                                                <ul class="tBody"><li>48</li><li>Liberia</li><li>Africa/Monrovia</li><li>(UTC+00:00) Monrovia, Reykjavik</li></ul>
                                                <ul class="tBody"><li>49</li><li>Morocco</li><li>Africa/Casablanca</li><li>(UTC+01:00) Casablanca</li></ul>
                                                <ul class="tBody"><li>50</li><li>Netherlands</li><li>Europe/Amsterdam</li><li>(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</li></ul>
                                                <ul class="tBody"><li>51</li><li>Serbia</li><li>Europe/Belgrade</li><li>(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</li></ul>
                                                <ul class="tBody"><li>52</li><li>Belgium</li><li>Europe/Brussels</li><li>(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</li></ul>
                                                <ul class="tBody"><li>53</li><li>"Bosnia and Herzegovina	"</li><li>Europe/Sarajevo</li><li>(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb</li></ul>
                                                <ul class="tBody"><li>54</li><li>Nigeria</li><li>Africa/Lagos</li><li>(UTC+01:00) West Central Africa</li></ul>
                                                <ul class="tBody"><li>55</li><li>Namibia</li><li>Africa/Windhoek</li><li>(UTC+02:00) Windhoek</li></ul>
                                                <ul class="tBody"><li>56</li><li>Jordan</li><li>Asia/Amman</li><li>(UTC+02:00) Amman</li></ul>
                                                <ul class="tBody"><li>57</li><li>Greece</li><li>Europe/Athens</li><li>(UTC+02:00) Athens, Bucharest</li></ul>
                                                <ul class="tBody"><li>58</li><li>Lebanon</li><li>Asia/Beirut</li><li>(UTC+02:00) Beirut</li></ul>
                                                <ul class="tBody"><li>59</li><li>Egypt</li><li>Africa/Cairo</li><li>(UTC+02:00) Cairo</li></ul>
                                                <ul class="tBody"><li>60</li><li>Moldova, Republic of</li><li>Europe/Chisinau</li><li>(UTC+02:00) Chisinau</li></ul>
                                                <ul class="tBody"><li>61</li><li>Syrian Arab Republic</li><li>Asia/Damascus</li><li>(UTC+02:00) Damascus</li></ul>
                                                <ul class="tBody"><li>62</li><li>Palestine, State of</li><li>Asia/Hebron</li><li>(UTC+02:00) Gaza, Hebron</li></ul>
                                                <ul class="tBody"><li>63</li><li>Zimbabwe</li><li>Africa/Harare</li><li>(UTC+02:00) Harare, Pretoria</li></ul>
                                                <ul class="tBody"><li>64</li><li>Finland</li><li>Europe/Helsinki</li><li>(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</li></ul>
                                                <ul class="tBody"><li>65</li><li>Israel</li><li>Asia/Jerusalem</li><li>(UTC+02:00) Jerusalem</li></ul>
                                                <ul class="tBody"><li>66</li><li>"Russian Federation	"</li><li>Europe/Kaliningrad</li><li>(UTC+02:00) Kaliningrad</li></ul>
                                                <ul class="tBody"><li>67</li><li>Libya</li><li>Africa/Tripoli</li><li>(UTC+02:00) Tripoli</li></ul>
                                                <ul class="tBody"><li>68</li><li>Iraq</li><li>Asia/Baghdad</li><li>(UTC+03:00) Baghdad</li></ul>
                                                <ul class="tBody"><li>69</li><li>Turkey</li><li>Europe/Istanbul</li><li>(UTC+03:00) Istanbul</li></ul>
                                                <ul class="tBody"><li>70</li><li>Kuwait</li><li>Asia/Kuwait</li><li>(UTC+03:00) Kuwait, Riyadh</li></ul>
                                                <ul class="tBody"><li>71</li><li>Belarus</li><li>Europe/Minsk</li><li>(UTC+03:00) Minsk</li></ul>
                                                <ul class="tBody"><li>72</li><li>"Russian Federation	"</li><li>Europe/Moscow</li><li>(UTC+03:00) Moscow, St. Petersburg, Volgograd</li></ul>
                                                <ul class="tBody"><li>73</li><li>Kenya</li><li>Africa/Nairobi</li><li>(UTC+03:00) Nairobi</li></ul>
                                                <ul class="tBody"><li>74</li><li>"Iran, Islamic Republic of	"</li><li>Asia/Tehran</li><li>(UTC+03:30) Tehran</li></ul>
                                                <ul class="tBody"><li>75</li><li>"United Arab Emirates	"</li><li>Asia/Dubai</li><li>(UTC+04:00) Abu Dhabi, Muscat</li></ul>
                                                <ul class="tBody"><li>76</li><li>"Russian Federation	"</li><li>Europe/Astrakhan</li><li>(UTC+04:00) Astrakhan, Ulyanovsk</li></ul>
                                                <ul class="tBody"><li>77</li><li>Azerbaijan</li><li>Asia/Baku</li><li>(UTC+04:00) Baku</li></ul>
                                                <ul class="tBody"><li>78</li><li>Russian Federation</li><li>Europe/Samara</li><li>(UTC+04:00) Izhevsk, Samara</li></ul>
                                                <ul class="tBody"><li>79</li><li>Mauritius</li><li>Etc/GMT-4</li><li>(UTC+04:00) Port Louis</li></ul>
                                                <ul class="tBody"><li>80</li><li>"Russian Federation	"</li><li>Europe/Saratov</li><li>(UTC+04:00) Saratov</li></ul>
                                                <ul class="tBody"><li>81</li><li>Georgia</li><li>Asia/Tbilisi</li><li>(UTC+04:00) Tbilisi</li></ul>
                                                <ul class="tBody"><li>82</li><li>Armenia</li><li>Asia/Yerevan</li><li>(UTC+04:00) Yerevan</li></ul>
                                                <ul class="tBody"><li>83</li><li>Afghanistan</li><li>Asia/Kabul</li><li>(UTC+04:30) Kabul</li></ul>
                                                <ul class="tBody"><li>84</li><li>Turkmenistan</li><li>Asia/Ashgabat</li><li>(UTC+05:00) Ashgabat, Tashkent</li></ul>
                                                <ul class="tBody"><li>85</li><li>"Russian Federation	"</li><li>Asia/Yekaterinburg</li><li>(UTC+05:00) Ekaterinburg</li></ul>
                                                <ul class="tBody"><li>86</li><li>Pakistan</li><li>Asia/Karachi</li><li>(UTC+05:00) Islamabad, Karachi</li></ul>
                                                <ul class="tBody"><li>87</li><li>India</li><li>Asia/Kolkata</li><li>(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</li></ul>
                                                <ul class="tBody"><li>88</li><li>"Sri Lanka	"</li><li>Asia/Colombo</li><li>(UTC+05:30) Sri Jayawardenepura</li></ul>
                                                <ul class="tBody"><li>89</li><li>Nepal</li><li>Asia/Kathmandu</li><li>(UTC+05:45) Kathmandu</li></ul>
                                                <ul class="tBody"><li>90</li><li>Kazakhstan</li><li>Etc/GMT-6</li><li>(UTC+06:00) Astana</li></ul>
                                                <ul class="tBody"><li>91</li><li>Bangladesh</li><li>Asia/Dhaka</li><li>(UTC+06:00) Dhaka</li></ul>
                                                <ul class="tBody"><li>92</li><li>"Russian Federation	"</li><li>Asia/Omsk</li><li>(UTC+06:00) Omsk</li></ul>
                                                <ul class="tBody"><li>93</li><li>Myanmar</li><li>Asia/Yangon</li><li>(UTC+06:30) Yangon (Rangoon)</li></ul>
                                                <ul class="tBody"><li>94</li><li>Thailand</li><li>Asia/Bangkok</li><li>(UTC+07:00) Bangkok, Hanoi, Jakarta</li></ul>
                                                <ul class="tBody"><li>95</li><li>"Russian Federation	"</li><li>Asia/Barnaul</li><li>(UTC+07:00) Barnaul, Gorno-Altaysk</li></ul>
                                                <ul class="tBody"><li>96</li><li>Mongolia</li><li>Asia/Hovd</li><li>(UTC+07:00) Hovd</li></ul>
                                                <ul class="tBody"><li>97</li><li>"Russian Federation	"</li><li>Asia/Krasnoyarsk</li><li>(UTC+07:00) Krasnoyarsk</li></ul>
                                                <ul class="tBody"><li>98</li><li>"Russian Federation	"</li><li>Asia/Novosibirsk</li><li>(UTC+07:00) Novosibirsk</li></ul>
                                                <ul class="tBody"><li>99</li><li>"Russian Federation	"</li><li>Asia/Tomsk</li><li>(UTC+07:00) Tomsk</li></ul>
                                                <ul class="tBody"><li>100</li><li>Hong Kong</li><li>Asia/Hong_Kong</li><li>(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</li></ul>
                                                <ul class="tBody"><li>101</li><li>"Russian Federation	"</li><li>Asia/Irkutsk</li><li>(UTC+08:00) Irkutsk</li></ul>
                                                <ul class="tBody"><li>102</li><li>"Singapore	"</li><li>Asia/Singapore</li><li>(UTC+08:00) Kuala Lumpur, Singapore</li></ul>
                                                <ul class="tBody"><li>103</li><li>Australia</li><li>Australia/Perth</li><li>(UTC+08:00) Perth</li></ul>
                                                <ul class="tBody"><li>104</li><li>"Taiwan, Province of China	"</li><li>Asia/Taipei</li><li>(UTC+08:00) Taipei</li></ul>
                                                <ul class="tBody"><li>105</li><li>"Mongolia	"</li><li>Asia/Ulaanbaatar</li><li>(UTC+08:00) Ulaanbaatar</li></ul>
                                                <ul class="tBody"><li>106</li><li>Australia</li><li>Australia/Eucla</li><li>(UTC+08:45) Eucla</li></ul>
                                                <ul class="tBody"><li>107</li><li>Korea, Democratic People's Republic of</li><li>Asia/Pyongyang</li><li>(UTC+09:00) Pyongyang</li></ul>
                                                <ul class="tBody"><li>108</li><li>"Russian Federation	"</li><li>Asia/Chita</li><li>(UTC+09:00) Chita</li></ul>
                                                <ul class="tBody"><li>109</li><li>Japan</li><li>Asia/Tokyo</li><li>(UTC+09:00) Osaka, Sapporo, Tokyo</li></ul>
                                                <ul class="tBody"><li>110</li><li>"Korea, Republic of	"</li><li>Asia/Seoul</li><li>(UTC+09:00) Seoul</li></ul>
                                                <ul class="tBody"><li>111</li><li>"Russian Federation	"</li><li>Asia/Yakutsk</li><li>(UTC+09:00) Yakutsk</li></ul>
                                                <ul class="tBody"><li>112</li><li>Australia</li><li>Australia/Adelaide</li><li>(UTC+09:30) Adelaide</li></ul>
                                                <ul class="tBody"><li>113</li><li>Australia</li><li>Australia/Darwin</li><li>(UTC+09:30) Darwin</li></ul>
                                                <ul class="tBody"><li>114</li><li>"Australia	"</li><li>Australia/Brisbane</li><li>(UTC+10:00) Brisbane</li></ul>
                                                <ul class="tBody"><li>115</li><li>Australia</li><li>Australia/Canberra</li><li>(UTC+10:00) Canberra, Melbourne, Sydney</li></ul>
                                                <ul class="tBody"><li>116</li><li>Guam</li><li>Pacific/Guam</li><li>(UTC+10:00) Guam, Port Moresby</li></ul>
                                                <ul class="tBody"><li>117</li><li>Australia</li><li>Australia/Hobart</li><li>(UTC+10:00) Hobart</li></ul>
                                                <ul class="tBody"><li>118</li><li>Russian Federation</li><li>Asia/Vladivostok</li><li>(UTC+10:00) Vladivostok</li></ul>
                                                <ul class="tBody"><li>119</li><li>"Australia	"</li><li>Australia/Lord_Howe</li><li>(UTC+10:30) Lord Howe Island</li></ul>
                                                <ul class="tBody"><li>120</li><li>"Papua New Guinea	"</li><li>Pacific/Bougainville</li><li>(UTC+11:00) Bougainville Island</li></ul>
                                                <ul class="tBody"><li>121</li><li>Russia</li><li>Etc/GMT-11</li><li>(UTC+11:00) Chokurdakh</li></ul>
                                                <ul class="tBody"><li>122</li><li>"Russian Federation	"</li><li>Asia/Magadan</li><li>(UTC+11:00) Magadan</li></ul>
                                                <ul class="tBody"><li>123</li><li>"Norfolk Island	"</li><li>Pacific/Norfolk</li><li>(UTC+11:00) Norfolk Island</li></ul>
                                                <ul class="tBody"><li>124</li><li>"Russian Federation	"</li><li>Asia/Sakhalin</li><li>(UTC+11:00) Sakhalin</li></ul>
                                                <ul class="tBody"><li>125</li><li>Solomon Islands</li><li>Etc/GMT-11</li><li>(UTC+11:00) Solomon Is., New Caledonia</li></ul>
                                                <ul class="tBody"><li>126</li><li>"Russian Federation	"</li><li>Asia/Anadyr</li><li>(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky</li></ul>
                                                <ul class="tBody"><li>127</li><li>"New Zealand	"</li><li>Pacific/Auckland</li><li>(UTC+12:00) Auckland, Wellington</li></ul>
                                                <ul class="tBody"><li>128</li><li>Marshall Islands</li><li>Etc/GMT-12</li><li>(UTC+12:00) Coordinated Universal Time+12</li></ul>
                                                <ul class="tBody"><li>129</li><li>"Fiji	"</li><li>Pacific/Fiji</li><li>(UTC+12:00) Fiji</li></ul>
                                                <ul class="tBody"><li>130</li><li>"Russian Federation	"</li><li>Asia/Kamchatka</li><li>(UTC+12:00) Petropavlovsk-Kamchatsky - Old</li></ul>
                                                <ul class="tBody"><li>131</li><li>New Zealand</li><li>Pacific/Chatham</li><li>(UTC+12:45) Chatham Islands</li></ul>
                                                <ul class="tBody"><li>132</li><li>Phoenix Islands</li><li>Etc/GMT-13</li><li>(UTC+13:00) Coordinated Universal Time+13</li></ul>
                                                <ul class="tBody"><li>133</li><li>Tonga</li><li>Pacific/Tongatapu</li><li>(UTC+13:00) Nuku'alofa</li></ul>
                                                <ul class="tBody"><li>134</li><li>Samoa</li><li>Pacific/Apia</li><li>(UTC+13:00) Samoa</li></ul>
                                                <ul class="tBody"><li>135</li><li>Kiribati</li><li>Pacific/Kiritimati</li><li>(UTC+14:00) Kiritimati Island</li></ul>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Time Zone Info Modal --> */}

                    {/* <!-- //Error Code Info Modal --> */}
                    <div class="modal fade swaggerInfoModal" data-keyboard="true" data-backdrop="static" id="ResponseCodeInfoModal" tabindex="-1" role="dialog" aria-labelledby="swaggerInfoModalLabel">
                        <div class="modal-dialog modal-lg swaggerInfoModal errorCodeModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                    Response Code <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                                </div>
                                <div class="modal-body">
                                    <form class="responseCListTableForm">
                                        <div class="responseCListTable">
                                            <ul class="tHead">
                                                <li>Message</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>Missing Parameters</li>
                                                <li>Unsupported Parameters</li>
                                                <li>Out of Range</li>
                                                <li>Bad Request parameters</li>
                                                <li>Bad Request body</li>
                                                <li>Unsupported Feature</li>
                                                <li>Parameters Already Exist</li>
                                                <li>Access denied</li>
                                                <li>Unauthorized</li>
                                                <li>API key expired</li>
                                                <li>Invalid API key</li>
                                                <li>Access blocked</li>
                                                <li>Resource not found</li>
                                                <li>Request throttled</li>
                                                <li>API quota exceeded</li>
                                                <li>Too many requests</li>
                                                <li>Internal server error</li>
                                                <li>Not implemented</li>
                                                <li>Service unavailable</li>
                                                <li>Gateway timeout</li>
                                            </ul>
                                            <ul class="tHead">
                                                <li>HTTP Status</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>400</li>
                                                <li>401</li>
                                                <li>401</li>
                                                <li>403</li>
                                                <li>403</li>
                                                <li>403</li>
                                                <li>404</li>
                                                <li>429</li>
                                                <li>429</li>
                                                <li>429</li>
                                                <li>500</li>
                                                <li>501</li>
                                                <li>503</li>
                                                <li>504</li>
                                            </ul>
                                            <ul class="tHead">
                                                <li>C2C ErrorCode</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>4000xx</li>
                                                <li>4001xx</li>
                                                <li>4002xx</li>
                                                <li>4003xx</li>
                                                <li>4004xx</li>
                                                <li>4005xx</li>
                                                <li>4006xx</li>
                                                <li>4010xx</li>
                                                <li>4011xx</li>
                                                <li>4030xx</li>
                                                <li>4031xx</li>
                                                <li>4032xx</li>
                                                <li>4040xx</li>
                                                <li>4290xx</li>
                                                <li>4291xx</li>
                                                <li>4292xx</li>
                                                <li>5000xx</li>
                                                <li>5010xx</li>
                                                <li>5030xx</li>
                                                <li>5040xx</li>
                                            </ul>
                                            <ul class="tHead">
                                                <li>Parameters</li>
                                                <li>Type</li>
                                                <li>Min Value</li>
                                                <li>Max Value</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                                <li>&nbsp;</li>
                                            </ul>                                            
                                            <ul class="tBody"><li>Others</li><li></li><li></li><li></li><li>400000</li><li>400100</li><li>400200</li><li>400300</li><li>400400</li><li>400500</li><li>400600</li><li>401000</li><li>401100</li><li></li><li></li><li>403200</li><li>404000</li><li>429000</li><li></li><li>429200</li><li>500000</li><li>501000</li><li>503000</li><li>504000</li></ul>
                                            <ul class="tBody"><li>brandid</li><li>num</li><li>1</li><li>12</li><li>400001</li><li>NA</li><li>400201</li><li>400301</li><li>400401</li><li>NA</li><li>400601</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>country</li><li>string</li><li>2</li><li>2</li><li>400002</li><li>NA</li><li>400202</li><li>400302</li><li>400402</li><li>NA</li><li>400602</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>filter</li><li>string</li><li>3</li><li>7</li><li>400003</li><li>NA</li><li>400203</li><li>400303</li><li>NA</li><li>NA</li><li>400603</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>holderid</li><li>num</li><li>1</li><li>6</li><li>400004</li><li>NA</li><li>400204</li><li>400304</li><li>NA</li><li>NA</li><li>400604</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>cycle</li><li>num</li><li>1</li><li>6</li><li>400005</li><li>NA</li><li>400205</li><li>400305</li><li>NA</li><li>NA</li><li>400605</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>startDate</li><li>string</li><li>10</li><li>10</li><li>400006</li><li>NA</li><li>400206</li><li>400306</li><li>NA</li><li>NA</li><li>400606</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>endDate</li><li>string</li><li>10</li><li>10</li><li>400007</li><li>NA</li><li>400207</li><li>400307</li><li>NA</li><li>NA</li><li>400607</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>analyticsType</li><li>num</li><li>2</li><li>128</li><li>400008</li><li>NA</li><li>400208</li><li>400308</li><li>NA</li><li>NA</li><li>400608</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>utilisationType</li><li>num</li><li>2</li><li>128</li><li>400009</li><li>NA</li><li>400209</li><li>400309</li><li>NA</li><li>NA</li><li>400609</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>analyticsCount</li><li>num</li><li>1</li><li>20</li><li>400010</li><li>NA</li><li>400210</li><li>400310</li><li>NA</li><li>NA</li><li>400610</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>deviceid</li><li>string</li><li>2</li><li>128</li><li>400011</li><li>NA</li><li>400211</li><li>400311</li><li>NA</li><li>NA</li><li>400611</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>level</li><li>num</li><li>1</li><li>6</li><li>400012</li><li>NA</li><li>400212</li><li>400312</li><li>NA</li><li>NA</li><li>400612</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>status</li><li>string</li><li>6</li><li>10</li><li>400013</li><li>NA</li><li>400213</li><li>400313</li><li>400413</li><li>NA</li><li>400613</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>modelType</li><li>string</li><li>2</li><li>128</li><li>400014</li><li>NA</li><li>400214</li><li>400314</li><li>NA</li><li>NA</li><li>400614</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>model</li><li>string</li><li>2</li><li>128</li><li>400015</li><li>NA</li><li>400215</li><li>400315</li><li>NA</li><li>NA</li><li>400615</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>sortBy</li><li>string</li><li>2</li><li>128</li><li>400016</li><li>NA</li><li>400216</li><li>400316</li><li>NA</li><li>NA</li><li>400616</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>sortOrder</li><li>string</li><li>3</li><li>4</li><li>400017</li><li>NA</li><li>400217</li><li>400317</li><li>NA</li><li>NA</li><li>400617</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>page</li><li>num</li><li>1</li><li>10000</li><li>400018</li><li>NA</li><li>400218</li><li>400318</li><li>NA</li><li>NA</li><li>400618</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>limit</li><li>num</li><li>1</li><li>100</li><li>400019</li><li>NA</li><li>400219</li><li>400319</li><li>NA</li><li>NA</li><li>400619</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>parentNodeid</li><li>num</li><li>1</li><li>4294967295</li><li>400020</li><li>NA</li><li>400220</li><li>NA</li><li>400420</li><li>NA</li><li>400620</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>nodeName</li><li>string</li><li>2</li><li>128</li><li>400021</li><li>NA</li><li>400221</li><li>NA</li><li>400421</li><li>NA</li><li>400621</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>categoryid</li><li>num</li><li>1</li><li>6</li><li>400022</li><li>NA</li><li>400222</li><li>NA</li><li>400422</li><li>NA</li><li>400622</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>nodeid</li><li>num</li><li>1</li><li>4294967295</li><li>400023</li><li>NA</li><li>400223</li><li>NA</li><li>400423</li><li>NA</li><li>400623</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>street</li><li>string</li><li>2</li><li>128</li><li>400024</li><li>NA</li><li>400224</li><li>NA</li><li>400424</li><li>NA</li><li>400624</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>city</li><li>string</li><li>2</li><li>128</li><li>400025</li><li>NA</li><li>400225</li><li>NA</li><li>400425</li><li>NA</li><li>400625</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>zipcode</li><li>string</li><li>2</li><li>128</li><li>400026</li><li>NA</li><li>400226</li><li>NA</li><li>400426</li><li>NA</li><li>400626</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>custGpTypeid</li><li>string</li><li>1</li><li>11</li><li>400027</li><li>NA</li><li>400227</li><li>NA</li><li>400427</li><li>NA</li><li>400627</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>custGpTypeName</li><li>string</li><li>2</li><li>128</li><li>400028</li><li>NA</li><li>400228</li><li>NA</li><li>400428</li><li>NA</li><li>400628</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>unitAssignable</li><li>string</li><li></li><li></li><li>400029</li><li>NA</li><li>400229</li><li>NA</li><li>400429</li><li>NA</li><li>400629</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>imageName</li><li>string</li><li>2</li><li>128</li><li>400030</li><li>NA</li><li>400230</li><li>NA</li><li>400430</li><li>NA</li><li>400630</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>requestType</li><li>string</li><li>3</li><li>16</li><li>400031</li><li>NA</li><li>400231</li><li>NA</li><li>400431</li><li>NA</li><li>400631</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>fileName</li><li>string</li><li>2</li><li>128</li><li>400032</li><li>NA</li><li>400232</li><li>NA</li><li>400432</li><li>NA</li><li>400632</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>fileid</li><li>string</li><li>2</li><li>128</li><li>400033</li><li>NA</li><li>400233</li><li>NA</li><li>400433</li><li>NA</li><li>400633</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>devices</li><li>array</li><li>1</li><li>50</li><li>400034</li><li>NA</li><li>400234</li><li>NA</li><li>400434</li><li>NA</li><li>400634</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>distributionid</li><li>num</li><li>1</li><li>4294967295</li><li>400035</li><li>NA</li><li>400235</li><li>NA</li><li>400435</li><li>NA</li><li>400635</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>content-type</li><li>string</li><li></li><li></li><li>400036</li><li>NA</li><li>400236</li><li>400336</li><li>400436</li><li>NA</li><li>400636</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li><li>NA</li></ul>
                                            <ul class="tBody"><li>x-api-key</li><li>string</li><li></li><li></li><li>400037</li><li>NA</li><li>400237</li><li>400337</li><li>400437</li><li>NA</li><li>400637</li><li>NA</li><li>NA</li><li>403037</li><li>403137</li><li></li><li></li><li></li><li>429137</li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>countryName</li><li>string</li><li>2</li><li>128</li><li>400038</li><li>NA</li><li>400238</li><li>400338</li><li>400438</li><li>NA</li><li>400638</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>brandName</li><li>string</li><li>4</li><li>12</li><li>400039</li><li>NA</li><li>400239</li><li>400339</li><li>400439</li><li>NA</li><li>400639</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>credential</li><li>object</li><li></li><li></li><li>400040</li><li>NA</li><li>400240</li><li>400340</li><li>400440</li><li>NA</li><li>400640</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>endPoints</li><li>object</li><li></li><li></li><li>400041</li><li>NA</li><li>400241</li><li>400341</li><li>400441</li><li>NA</li><li>400641</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>notificationEmailids</li><li>array</li><li></li><li></li><li>400042</li><li>NA</li><li>400242</li><li>400342</li><li>400442</li><li>NA</li><li>400642</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>batchSchedule</li><li>object</li><li></li><li></li><li>400043</li><li>NA</li><li>400243</li><li>400343</li><li>400443</li><li>NA</li><li>400643</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Nr</li><li>num</li><li>1</li><li>4294967295</li><li>400044</li><li>NA</li><li>400244</li><li>400344</li><li>400444</li><li>NA</li><li>400644</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Name</li><li>string</li><li>2</li><li>128</li><li>400045</li><li>NA</li><li>400245</li><li>400345</li><li>400445</li><li>NA</li><li>400645</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Address</li><li>string</li><li>2</li><li>500</li><li>400046</li><li>NA</li><li>400246</li><li>400346</li><li>400446</li><li>NA</li><li>400646</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Description</li><li>string</li><li>2</li><li>500</li><li>400047</li><li>NA</li><li>400247</li><li>400347</li><li>400447</li><li>NA</li><li>400647</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>SoldtoNr</li><li>num</li><li>1</li><li>4294967295</li><li>400048</li><li>NA</li><li>400248</li><li>400348</li><li>400448</li><li>NA</li><li>400648</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>ShiptoNr</li><li>num</li><li>1</li><li>4294967295</li><li>400049</li><li>NA</li><li>400249</li><li>400349</li><li>400449</li><li>NA</li><li>400649</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>BuildingNr</li><li>num</li><li>1</li><li>4294967295</li><li>400050</li><li>NA</li><li>400250</li><li>400350</li><li>400450</li><li>NA</li><li>400650</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>LevelNr</li><li>num</li><li>1</li><li>5</li><li>400051</li><li>NA</li><li>400251</li><li>400351</li><li>400451</li><li>NA</li><li>400651</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Caption</li><li>string</li><li>2</li><li>128</li><li>400052</li><li>NA</li><li>400252</li><li>400352</li><li>400452</li><li>NA</li><li>400652</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>offset</li><li>num</li><li>1</li><li>1000</li><li>400053</li><li>NA</li><li>400253</li><li>400353</li><li>400453</li><li>NA</li><li>400653</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>SerialNr</li><li>string</li><li>2</li><li>128</li><li>400054</li><li>NA</li><li>400254</li><li>400354</li><li>400454</li><li>NA</li><li>400654</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>AssetNr</li><li>string</li><li>2</li><li>128</li><li>400055</li><li>NA</li><li>400255</li><li>400355</li><li>400455</li><li>NA</li><li>400655</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>Type</li><li>string</li><li>2</li><li>128</li><li>400056</li><li>NA</li><li>400256</li><li>400356</li><li>400456</li><li>NA</li><li>400656</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>PosNr</li><li>num</li><li>1</li><li>4294967295</li><li>400057</li><li>NA</li><li>400257</li><li>400357</li><li>400457</li><li>NA</li><li>400657</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>startTime</li><li>string</li><li>1</li><li></li><li>400058</li><li>NA</li><li>400258</li><li>400358</li><li>400458</li><li>NA</li><li>400658</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>endTime</li><li>string</li><li>1</li><li></li><li>400059</li><li>NA</li><li>400259</li><li>400359</li><li>400459</li><li>NA</li><li>400659</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>caption</li><li>string</li><li>2</li><li>128</li><li>400060</li><li>NA</li><li>400260</li><li>400360</li><li>400460</li><li>NA</li><li>400660</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>timeZoneid</li><li>num</li><li>1</li><li>135</li><li>400061</li><li>NA</li><li>400261</li><li>400361</li><li>400461</li><li>NA</li><li>400661</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>assetNumber</li><li>string</li><li>2</li><li>128</li><li>400062</li><li>NA</li><li>400262</li><li>400362</li><li>400462</li><li>NA</li><li>400662</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>fileType</li><li>string</li><li>3</li><li>16</li><li>400063</li><li>NA</li><li>400263</li><li>400363</li><li>400463</li><li>NA</li><li>400663</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
											<ul class="tBody"><li>jobid</li><li>string</li><li>2</li><li>128</li><li>400064</li><li>NA</li><li>400264</li><li>400364</li><li>400464</li><li>NA</li><li>400664</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>version</li><li>string</li><li>2</li><li>50</li><li>400065</li><li>NA</li><li>400265</li><li>400365</li><li>400465</li><li>NA</li><li>400665</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>models</li><li>array</li><li>1</li><li>100</li><li>400066</li><li>NA</li><li>400266</li><li>400366</li><li>400466</li><li>NA</li><li>400666</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            <ul class="tBody"><li>description</li><li>string</li><li>5</li><li>250</li><li>400067</li><li>NA</li><li>400267</li><li>400367</li><li>400467</li><li>NA</li><li>400667</li><li>NA</li><li>NA</li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Error Code Info Modal --> */}
                    <div id="page-content-wrapper">
                    <div className="pageScrollbar">
                        <div className="mainMenuGrid swagger-interface">
                            {showFileUrl && <SwaggerUI url={require(`${showFileUrl}`)} />}
                        </div>
                        </div>
                    </div>
                    <UserManagementHeader headerName="KC API Portal" activeClass="swaggerNav" previousPage="Swagger List" />
                </div>
            </React.Fragment>
        )
    }
}
export default SwaggerInterface