import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { LocationInfo, DeviceRegistration, UnitInfo, UserProfile} from '../../service/api';
import ConnectivityChart from './charts/connectivityStatusChart';
import PeakHoursChart from './charts/peakHourChart';
import ErrorChart from './charts/errorStatusChart';
import ProductionLineChart from './charts/productionLineChart';
import { getconnectivityStatusInfo } from '../../actions/allLocations/locations/connectivityStatusAction'
import { LocationBuilder } from '../../utils/locationResponseBuilder';
import { setCurrentLocation } from '../../actions/organizationentity/entityactions';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import { LocationCard } from '../controls/locationCard';
import $ from 'jquery';
import * as _ from 'lodash';
import { updateDimensions } from '../../utils/csvExUnitRedirect'
import appconstants from '../../utils/appConstants'
import { getSelectedFilterList } from '../../actions/listView/locations/filterListAction';
import NoRecordsFound from '../controls/noRecordsFound';
import { CUSTOMER_ID, BRAND_ID, setBrandId } from '../../utils/appConstants';
import { searchVisible, searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../globalSearchBar/selectOption';
import { DropDownListLink } from '../controls/dropDownListLink';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';

var moment = require('moment');
var todaysDate = new Date();

class DashBoardLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: null,
            currentPage: 1,
            pageSize: 25,
            sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
            loading: true,
            isLocationsDataLoaded: false,
            datapickflag: false,
            globalDropdownFilter: 'Month',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            searchData: null,
            showHelpModal: false,
            dontShowMeAgain: false
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
            sSmartTags: [],
        };
        window.addEventListener("load", updateDimensions($));
        this.setBrandList = this.setBrandList.bind(this);
    }

    updateDimension() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

    async componentDidUpdate(prevState) {
        let { searchData } = prevState;
        let curSearchData = this.state && this.state.searchData;
        if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
            this.setState({ loading: true, currentPage: 1 }, () => {
                const { currentPage, sortColumn } = this.state;
                this.loadData(currentPage, sortColumn);
            });
        }
        window.addEventListener("resize", this.updateDimension());
	}


    async componentDidMount() {
        let _getBrandsList;
        let _getbrandIds = localStorage.getItem('brandId').replace(/[\[\]']+/g, '');
        _getBrandsList = _.isEqual(BRAND_ID.split(',').sort(), _getbrandIds.split(',').sort())
        if(!_getBrandsList) {
            setBrandId(_getbrandIds);
        }
        this.props.searchVisible(true);
        this.updateDimension();
        const { currentPage, sortColumn } = this.state;
        await this.loadData(currentPage, sortColumn);
        this.showHelpPopup();
    }

    showHelpPopup = async () => {
        const getLocalStorage = await localStorage.getItem('showHelpModal');
        if (!getLocalStorage || getLocalStorage === "false") {
            $('#howToModal').modal('show');
        }
    }

    handleHelpPopup = () => {
        if (this.state.dontShowMeAgain) {
            this.updateHelpPreference();
        }
        localStorage.setItem('showHelpModal', !this.state.showHelpModal);
    }

    updateHelpPreference = () => {
        return new Promise((res, rej) => {
            const reqData = {
                "email": localStorage.getItem('user'),
                "preference_value": this.state.dontShowMeAgain ? 1 : 0
            }
            http.put(DeviceRegistration.updateHelpPreference, reqData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                res(response)
            }).catch(err => {
                rej(err)
            });
        })
    }

    /**
     * Fetch server data by On-Demand
     * Send current page number
     * Send Sorted details
     */
    loadData = async (currentPage, sortColumn) => {
        try {
            const response = await this.requestData(currentPage, sortColumn);
            this.setState({
                data: response.rows,
                totalPages: response.pages,
                currentPage: currentPage,
                loading: response.showLoading,
                isLocationsDataLoaded: response.isLocationsDataLoaded
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    onDropDownHandler(e) {
        const filterType = e.nodeText;
        this.setState({
            globalDropdownFilter: filterType
        });
        this.refs.peakHourChart.fetchData(this.refs.peakHourChart.state.brand_id, filterType)
        this.refs.productionHourChart.fetchData(this.refs.productionHourChart.state.brand_id, filterType)
        this.refs.peakHourChart.setState({ datapickflag: filterType === 'Date Range' })
        this.refs.productionHourChart.setState({ datapickflag: filterType === 'Date Range' })
    }

    /**
     * Fetch server data by On-Demand
     * Send current page number
     * Send Sorted details
     */
    loadData = async (currentPage, sortColumn) => {
        try {
            const response = await this.requestData(currentPage, sortColumn);
            await this.setBrandList();
            this.setState({
                data: response.rows,
                totalPages: response.pages,
                currentPage: currentPage,
                loading: response.showLoading,
                isLocationsDataLoaded: response.isLocationsDataLoaded
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    /**
     * When navigate new page this event will return current page number
     * @param {*} currentPage 
     */
    onPageChange = (currentPage) => {
        this.setState({ loading: true });
        this.loadData(currentPage);
    }

    /**
     * When sort the record by On-Demand
     * This event will return the sorted column details
     */
    onSortedChange = (sortColumn) => {
        this.setState({
            sortColumn: sortColumn,
            currentPage: 1
        });
        this.loadData(1, sortColumn);
    }

    // componentWillUnmount
    async componentWillUnmount() {
        this.props.searchText({});
        this.props.searchVisible(false);
    }

    /**
     * Business login to fetch units by below constraints
     * 1. Fetch by Brand specific units
     * 2. Fetch by Sorted column details
     * 3. Fetch by page wise
     */
    requestData = async (currentPage, sortColumn) => {
        try {
            const sortData = sortColumn ? sortColumn : this.state.sortColumn;
            const pageSize = this.state.pageSize;
            const sortType = sortData.order;
            const sortBy = sortData.path;
            const page = currentPage - 1;

            let filterModel = this.props.filterlistInformtion.filterModel;
            const ffModel = localStorage.getItem(appconstants.filterKey);
            const filterModel1 = ffModel && JSON.parse(ffModel);
            if (filterModel1) {
                filterModel = filterModel1;
            }

            let selectedCountryList = filterModel.find(el => el.field_name === 'country');
            let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
            let selectedLocationList = filterModel.find(el => el.field_name === 'location');
            let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
            let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
            let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
            let selectedModelList = filterModel.find(el => el.field_name === 'model');
            let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
            let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
            let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');
            let selectedCgids = [];
            let selectedCids = [];
            let selectedBrands = [];
            let selectedModels = [];
            let selectedMachineTypes = [];
            let selectedST = [];

            selectedCountryList.dataSource.map(c => {
                return selectedCgids.push(c.id)
            });

            if (selectedRegionList.dataSource.length > 0) {
                selectedCgids = [];
            }

            selectedRegionList.dataSource.map(c => {
                return selectedCgids.push(c.cust_gp_id)
            });

            if (selectedLocationList.dataSource.length > 0) {
                selectedCgids = [];
            }

            selectedLocationList.dataSource.map(c => {
                return selectedCgids.push(c.cust_gp_id)
            });

            if (selectedWithinLocationList.dataSource.length > 0) {
                selectedCgids = [];
            }

            selectedWithinLocationList.dataSource.map(c => {
                return selectedCgids.push(c.cust_gp_id)
            });

            selectedCustomerList.dataSource.map(c => {
                return selectedCids.push(c.ID)
            });

            selectedBrandList.dataSource.map(c => {
                return selectedBrands.push(c.ID)
            });

            selectedModelList.dataSource.map(c => {
                return selectedModels.push(c.ID)
            });

            selectedMachineTypeList.dataSource.map(c => {
                return selectedMachineTypes.push(c.ID)
            });
            
            selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

            selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });
            this.filterstate = ({
                sCgids: selectedCgids,
                sCids: selectedCids,
                sBrandids: selectedBrands,
                sModels: selectedModels,
                sMachineTypes: selectedMachineTypes,
                sSmartTags: selectedST,
            });

            var d = document.getElementById("spannavNotifiStatus");
            let cgids = "";
            if (selectedCgids.length > 0) {
                cgids = selectedCgids.join(',');
            }
            else {
                cgids = localStorage.getItem("custGroupID");
            }

            if (selectedCgids.length > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
                d.className = "navNotifiStatus";
            }
            else {
                d.className = "";
            }
            let url = `${LocationInfo.list}`;
            let pageNumber = '?pageNum=' + (page + 1);
            let pageLimit = '&pageSize=' + pageSize;

            let order = page * pageSize;

            let { searchData } = this.state;
            let apiUrl = url + pageNumber + pageLimit;

            if (searchData && searchData.searchtext) {
                apiUrl = `${apiUrl}&searchText=${searchData.searchtext}`;
            }

            if (searchData) {
                let searchParam = {};

                Object.keys(searchData).forEach((item) => {
                    if (item !== "searchtext") {
                        return searchParam[item] = searchData[item];
                    }
                })

                if (searchParam && Object.keys(searchParam).length) {
                    apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
                }
            }

            let headers = {
                'Content-Type': 'application/json',
                'cgid': cgids ? cgids : "NA",
                'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,
                'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : BRAND_ID,
                'model': selectedModels.join(','),
                'machinetype': selectedMachineTypes.join(','),
                'sortby': sortBy,
                'sorttype': sortType
            }
            console.log("selectedST", selectedST);
            if(selectedST && selectedST.length){
                headers['tags'] = selectedST.join(',')
            }

            console.log("headers", headers);

            const response = await http.get(apiUrl,
                {
                    headers,
                    data: {}
                })

            let { locations, numberOfPages } = response.data;
            locations.map((item, i) => {
                item.order_id = ++order;
            });
            locations = LocationBuilder(locations);

            return {
                rows: locations,
                pages: numberOfPages,
                isLocationsDataLoaded: true,
                showLoading: false,
            };

        } catch (error) {
            return {
                rows: [],
                pages: 0,
                showLoading: false,
            };
        }
    };

    static getDerivedStateFromProps = (props) => ({ ...props });

    trackLocation = (event) => {
        let locationDetails = `${event.country}${event.city}${constant.DASHBOARD_UNIT_LOCATION}`;
        WoopraEvents(locationDetails);
    }

    trackEvent = () => {
        WoopraEvents(`${constant.UNITS}`);
    }

    setBrandList = async () => {
        try {
            let cgids = localStorage.getItem("custGroupID");
			let brandIds = localStorage.getItem("brandId")
            let url = `${UnitInfo.uniqueBrands}`;
            const response = await http.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgid': cgids ? cgids : "NA",
                        'cid': CUSTOMER_ID,
						'brandid': BRAND_ID
                    },
                    data: {}
                });
            let brandIDNameArr = response.data.brands.map(function (item) {
                return {
                    BRAND_NAME: item.BRAND_NAME,
                    BRAND_ID: item.BRAND_ID
                }
            });
            let uniqueObjArray = [...new Map(brandIDNameArr.map((item) => [item["BRAND_ID"], item])).values()];
            let brandIDName = uniqueObjArray.sort((a, b) => (a.BRAND_NAME > b.BRAND_NAME) ? 1 : ((b.BRAND_NAME > a.BRAND_NAME) ? -1 : 0));
            localStorage.setItem("availableUnitsBrand", JSON.stringify(brandIDName));
        } catch (error) {
            return error;
        }
    }

    render() {
        const { sCgids, sCids, sBrandids, sModels, sMachineTypes, sSmartTags } = this.filterstate;
        const { data, totalPages, currentPage, loading, isLocationsDataLoaded, globalDropdownFilter } = this.state;
        let custGroupID = localStorage.getItem("custGroupID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <AllLocationHeaderLayout activeClass="dashboardNav" filterKey={appconstants.filterKey} history={this.props.history} />
                    {<div id="page-content-wrapper" className="withSearchBar">
                        <div className="pageHeader">
                            <ul>
                                <li>
                                    <Link to="/dashBoardLocations" title={formatMessage({ id: 'KC0054' })} className="active"><FormattedMessage id='KC0054' /></Link>
                                </li>
                                <li className="filterDropdown">
                                    <p className="globalFilterText"><FormattedMessage id='KC0097' /></p>
                                    <DropDownListLink translation={true} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.globalDropdownFilter} disableDatePicker={true} />
                                </li>
                            </ul>
                        </div>
                        <div class="pageScrollbar">
                            <div className="gridViewTable">
                                <div className="gridView">
                                    <div className="colm3D colm3D1">
                                        {isLocationsDataLoaded && <div className="colmDi">
                                            <h4><FormattedMessage id='KC0040' /></h4>
                                            <ConnectivityChart Type={'Location'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>
                                        </div>}
                                    </div>
                                    <div className="colm3D">
                                        {isLocationsDataLoaded && <div className="colmDi">
                                            <h4><FormattedMessage id='KC0041' /></h4>
                                            <ErrorChart Type={'Location'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>
                                        </div>
                                        }
                                    </div>
                                    <div className="colm3D">
                                        {isLocationsDataLoaded && <PeakHoursChart ref="peakHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>}
                                    </div>
                                    <div className="colm3D colm3D4">
                                        {isLocationsDataLoaded && <ProductionLineChart ref="productionHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>}
                                    </div>
                                </div>
                            </div>

                            <div className="pageTabHeader">
                                <ul>
                                    <li>
                                        <Link to="/dashboardLocations" title={formatMessage({ id: 'KC0044' })} className="locationsView active"><FormattedMessage id='KC0044' /></Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboardUnits" title={formatMessage({ id: 'KC0045' })} className="unitsView" onClick={() => this.trackEvent()}><FormattedMessage id='KC0045' /></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={data && data.length > 0 ? "allLocationsGrid" : "displaynone"}>
                                <ul>
                                    {Object.keys(data).map((item, i) => {
                                        const url = "/dashBoardLocations/specificLocation?groupId=" + data[i].location_id;
                                        return (
                                            <li key={i}>
                                                <Link to={url} onClick={() => { this.props.setCurrentLocation(data[i]); this.trackLocation(data[i]) }}>
                                                    <LocationCard location={data[i]} />
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <NoRecordsFound loaderIconVisible={!isLocationsDataLoaded} length={data !== undefined ? data.length : 0} classname={'norecordsfound'} stringID={this.state.searchData && data && data.length === 0 ? "KC0853" : "KC0205"} />
                        </div>
                        { totalPages && totalPages > 1 ? <GoToPagination
                            isBlackBackground={true}
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
						navigateTo={(p) => this.onPageChange(p)} /> : null }
                    </div>}

                    <div className="modal fade subscriptionModal" data-keyboard="true" data-backdrop="static" id="howToModal"
                        tabIndex="-1" role="dialog" aria-labelledby="howToModalLabel">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                    <p>Do you need some guidance? Please click <a href="/howTo" onClick={() => localStorage.setItem('showHelpModal', !this.state.showHelpModal)}>here</a> to access documentation on how to use KitchenConnect. </p>

                                    <div className="customCheckbox customCheckboxWlabel">
                                        <input id="TermsandConditions" name="TermsandConditions" type="checkbox" value="TermsandConditions" checked={this.state.dontShowMeAgain} onClick={() => {
                                            this.setState(prevState => ({ dontShowMeAgain: !prevState.dontShowMeAgain }))
                                        }} />
                                        <label for="TermsandConditions">Don't ask me again</label>
                                    </div>
                                </div>
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.handleHelpPopup()} type="button"><FormattedMessage id="KC0777" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        filterlistInformtion: state.filterModel,
        connectivityStatusInfo: state.connectivityStatusInformation,
        selectedCountries: state.filterInformation.selectedCountries,
        selectedRegions: state.filterInformation.selectedRegions,
        selectedLocations: state.filterInformation.selectedLocations,
        selectedCustomers: state.filterInformation.selectedCustomers,
        selectedBrands: state.filterInformation.selectedBrands,
        selectedModels: state.filterInformation.selectedModels,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCurrentLocation, getconnectivityStatusInfo, getSelectedFilterList, searchVisible, searchText }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashBoardLocations));
