import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, UnitInfo, ReportManagement } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import GenericModal from '../../modal/generic-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import moment from '../../../utils/momentHelper.js';
import * as momentFormat  from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';

// var bg = require('../../../images/unit/orgDefault.png')
var bg = require('../../../images/recipecreation/photoPlaceholder.png');
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;

class ReportAssignTopology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            generic_modal: {
                open: false,
                message: "",
                messagetype: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            nameFromReport: '',
            startDateFromReport: '',
            endDateFromReport: '',
            selectedoptionsFromReport: '',
            isUnitItemClicked: false,
            checkedUnits: [],
            hourFormat: 'false',
			scheduledTime_mask: '',
			scheduledHourValue: '',
			scheduledMinsValue: '',
			noEndDate: true, 
			endDate: false,
			lastDays: true,
			currentDays: false,
			lastDaysPeriod: 0,
			selectedRepetitionPattern: "", 
            selectedLastPeriod: "daily",
            selectedCurrentPeriod: "weekly",
            generateNow: false,
            scheduleReport: false,
            AMPMformat: "",
            initialload: true,
            units: [],
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            searchData: null,
        };
        this.showLinks = this.showLinks.bind(this);              
        this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
    }

    onGenericCloseModal = (e, messagetype) => {
        if (messagetype === "Success") {
            if (this.state.reportType === 1) {
                this.props.history.push(`/scheduledReportList`);
            }
            else {
                this.props.history.push(`/reportList`);
            }
        }
        else {
            this.setState({
                generic_modal: {
                    open: false,
                    message: "",
                    messagetype: ""
                }
            });
        }
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, body } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    generic_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : "",
                        messagetype: "Error"
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    generic_modal: {
                        open: true,
                        message: body.message ? body.message : "",
                        messagetype: "Error"
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    message: "KC1065",
                    messagetype: "Alert"
                }

            });
        }
    }

    handleWillMount = () => {
        let cgids = localStorage.getItem("selectedGroupID") ? localStorage.getItem("selectedGroupID").split(',') : [];
        let selectedGroupNamearr = [];
        for (let x = 0; x < cgids.length; x++) {
            if (cgids[x]) {
                selectedGroupNamearr.push({cust_gp_id : parseInt(cgids[x])});
            }
        }
        if (this.props.location && this.props.location.state) {
            this.setState({
                reportType: this.props.location.state.reportType,
                nameFromReport: this.props.location.state.nameFromReport,
                startDateFromReport: this.props.location.state.startDateFromReport ? this.props.location.state.startDateFromReport : "",// "2020-04-09",
                endDateFromReport: this.props.location.state.endDateFromReport ? this.props.location.state.endDateFromReport : "",// "2020-04-09",
                selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
                generateNow: this.props.location.state.generateNow,
                scheduleReport: this.props.location.state.scheduleReport,
                recipientsEmail: this.props.location.state.recipientsEmail,
                comment: this.props.location.state.comment,
                selectedRepetitionPattern: this.props.location.state.selectedRepetitionPattern,
				selectedLastPeriod: this.props.location.state.selectedLastPeriod,
				selectedCurrentPeriod: this.props.location.state.selectedCurrentPeriod,
				lastDays: this.props.location.state.lastDays,
				currentDays: this.props.location.state.currentDays,
				lastDaysPeriod: this.props.location.state.lastDaysPeriod,
				hourFormat: this.props.location.state.hourFormat,
				noEndDate: this.props.location.state.noEndDate,
                endDate: this.props.location.state.endDate,
                AMPMformat: this.props.location.state.AMPMformat,
                isEdit: this.props.location.state.isEdit,
                id: this.props.location.state.id,
                schedule_id: this.props.location.state.schedule_id,
                checkedUnits:  this.props.location.state.checkedUnits ? this.props.location.state.checkedUnits : [],
                selectedGroupName: this.props.location.state.selectedGroupName ? this.props.location.state.selectedGroupName : [],
                selectedDynasty: this.props.location.state.selectedDynasty ? this.props.location.state.selectedDynasty : [],
                scheduledHourValue: this.props.location.state.scheduledHourValue ? this.props.location.state.scheduledHourValue : '',
                scheduledMinsValue: this.props.location.state.scheduledMinsValue? this.props.location.state.scheduledMinsValue : ''
            });
        }
        if ((localStorage.getItem("pageType") === "View" || localStorage.getItem("pageType") === "Edit")) {
            this.setState({
                checkedUnits: localStorage.getItem("selectedUnitID") ? JSON.parse(localStorage.getItem("selectedUnitID")) : [],
            });
        }
    }

    componentDidUpdate = (prevState) => { 
        this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getCustGroupID();
		}
	}
	static getDerivedStateFromProps = (props) => ({...props});

    componentDidMount= async() => {
        document.body.style.backgroundColor = "#F2F2F2";
        this.handleWillMount();
        $("div").removeClass("modal-backdrop fade show");
        this.props.searchVisible(true);
        if ((localStorage.getItem("pageType") === "View" || localStorage.getItem("pageType") === "Edit")) {
            let brandid = localStorage.getItem("selectedbrandID");
            await this.getDynacity(brandid).then(response => {
                let dynasty = response ? response : [];
                dynasty = dynasty.map(d => d.dynasty);
                this.setState({
                    selectedGroupName: response,
                    selectedDynasty: dynasty,
                    loaderIconVisible: false
                })
                this.getCustGroupID();
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        }
        else {
            this.getCustGroupID();
        }
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeOuter');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top);
    }

    getCustGroupID = () => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true, })

        this.setState({ queryString: loggedinUser });
        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });

            if (setcustGroupIDList !== "") {
                this.getOrgnanizationView(setcustGroupIDList);
            }
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
 * Convert hierarchy data structure into flatten array
 * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
 * @param {*} nodeLevel Node level No
 * @param {*} currentRowIndex Current row index for Tree Data
 * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
 * @param {*} parentRowIndex Parent Node row index
 * @param {*} parentColIndex Parent Node column index
 * @returns Array<Array>
 */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
        // const length = orgJSON.start_level_id;
        const length =1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,            
            isUnitItemClicked: true
        });
    }

    componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    // clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    getOrgnanizationView = async(values) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
        let url = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}?removeCache=${new Date().getTime()}`;
        let { searchData } = this.state;
        if (searchData && searchData.searchtext) {
            url = `${url}&searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                url = `${url}&searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: url,
            headers: {
                "Cgids": values,
                'brandids': localStorage.getItem("selectedbrandID"),
                'Content-Type': 'application/json',
				'useremail': loggedinUser,
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
				await $.getJSON(response.data.presignedS3URL, (data) => {
					this.processOrgnanizationJSON(data);
				});
			}
			else {
				this.processOrgnanizationJSON(response.data);
            }
            
            this.setState({
                cgIds: values
            })
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    submitForExportReport = async (e) => {
        this.setState({ loaderIconVisible: true, })
        let userCheckedData = this.state.checkedUnits ? this.state.checkedUnits : [];
        let userCheckedUnitData = userCheckedData.filter(unit => unit.isNodeSelected === false);
        let selectedOptions = this.state.selectedoptionsFromReport ? this.state.selectedoptionsFromReport.filter(item => item.checked === true) : [];
        let brandUnitLimit = localStorage.getItem("selectedBrandUnitLimit");
        brandUnitLimit = brandUnitLimit? parseInt(brandUnitLimit): 500;
        if (!userCheckedData.length) { 
            this.setState({
                loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    message: "KC1154",
                    messagetype: "Alert"
                }
            });
        } 
        else if (userCheckedData.length > brandUnitLimit && selectedOptions.length !== 1) {
            this.setState({
                loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    // message: "Your selection includes " + userCheckedData.length + " units, please select a maximum of 500 units.",
                    message: "KC2144",
                    messagetype: "Alert",
                    count: userCheckedData.length,
                    total: brandUnitLimit
                }
            });
        } else {
            let isCGIDSel = [];
            let selCGIDS="";
            isCGIDSel = this.state.selectedGroupName;
            if (isCGIDSel.length>0){  
                if (isCGIDSel && isCGIDSel.length > 1) {
                    isCGIDSel.forEach(function (item, index) {
                        if (selCGIDS) {
                            selCGIDS = selCGIDS + "," + item.cust_gp_id;
                        } else {
                            selCGIDS = item.cust_gp_id;
                        }
                    })
                } else if (isCGIDSel && isCGIDSel.length === 1) {
                    selCGIDS = isCGIDSel[0].cust_gp_id;
                }
            }
            let fileName = this.state.nameFromReport;
            //if no filename exist send below format as filename
            if( !fileName) {
                fileName = moment.ConvertLocalTimeFromUTCTime(new Date(), "MMDDYYYY_hhmmss");
            }
            
            let fromDateTime = "";
            if (this.state.reportType === 1) {
                fromDateTime = momentFormat(this.state.startDateFromReport).format("YYYY-MM-DD") + ' ' + this.state.scheduledHourValue + ':' + this.state.scheduledMinsValue + ':00';
                fromDateTime = localStorage.getItem("timeFormat") === "12" ? fromDateTime + ' ' + this.state.AMPMformat : fromDateTime;
                fromDateTime = fromDateTime.replace(/-/g, '/');
                fromDateTime = moment.ConvertLocalTimeFromDeviceUTCTime(fromDateTime, "YYYY-MM-DD HH:mm:ss");
            }
            else {
                fromDateTime = momentFormat(this.state.startDateFromReport).format("YYYY-MM-DD")
            }

            let toDateTime = "";
            if (this.state.reportType === 1 && this.state.endDateFromReport && this.state.endDateFromReport !== "Invalid date") {
                toDateTime = momentFormat(this.state.endDateFromReport).format("YYYY-MM-DD") + ' ' + this.state.scheduledHourValue + ':' + this.state.scheduledMinsValue + ':00';
                toDateTime = localStorage.getItem("timeFormat") === "12" ? toDateTime + ' ' + this.state.AMPMformat : toDateTime;
                toDateTime = toDateTime.replace(/-/g, '/');
                toDateTime = moment.ConvertLocalTimeFromDeviceUTCTime(toDateTime, "YYYY-MM-DD HH:mm:ss");
            }
            else if (this.state.reportType === 0 && this.state.endDateFromReport && this.state.endDateFromReport !== "Invalid date") {
                toDateTime = momentFormat(this.state.endDateFromReport).format("YYYY-MM-DD") + ' 11:59:59 PM';
            }
            else {
                toDateTime = "9999-12-31";
            }

            let dataPeriodFrequency = "";
            if (this.state.lastDays) {
                dataPeriodFrequency = this.state.selectedLastPeriod ? this.state.selectedLastPeriod : "";
            }
            else {
                dataPeriodFrequency = this.state.selectedCurrentPeriod ? this.state.selectedCurrentPeriod : "";
            }

            let postdata = {
                'is_scheduled': this.state.reportType,
                'from_date': fromDateTime,
                'to_date': toDateTime,
                "recipients_email": this.state.recipientsEmail ? this.state.recipientsEmail : "",
                "brand_id": localStorage.getItem("selectedbrandID"),
                "name": fileName,
                "report_option_id": Array.prototype.map.call(selectedOptions, m => m.id).toString(),
                "unitids": userCheckedUnitData.length > 0 ? userCheckedUnitData.map(el => el.UNITID).toString() : "",
                "cgids": selCGIDS ? selCGIDS : "",
                "unitcgids": userCheckedUnitData.length > 0 ? userCheckedUnitData.map(el => el.CUSTOMER_GROUP_ID).toString() : "",
                "memo": this.state.comment ? this.state.comment : "",
                "filetype": ".xlsx",
                "customer_id": localStorage.getItem("customerId"),
                "creationDate": moment.ConvertLocalTimeFromDeviceUTCTime(new Date(), "YYYY-MM-DD HH:mm:ss"),
                "frequency": this.state.selectedRepetitionPattern ? this.state.selectedRepetitionPattern : "",
                "data_period": this.state.lastDays ? "Last" : "Current",
                "data_period_days": this.state.lastDaysPeriod,
                "data_period_frequency": dataPeriodFrequency,
                "customer": localStorage.getItem("customerName")
            };

            let httpmethod = "POST";
            if (this.state.isEdit) {
                postdata.id = this.state.id;
                postdata.schedule_id = this.state.schedule_id;
                httpmethod = "PUT";
            }

            http({
				method: httpmethod,
				url: ReportManagement.saveReport,
				headers: {},
				data: postdata
			}).then(response => {
                if (response && response.data) {
                    this.setState({
                        responseData: response.data,
                        loaderIconVisible: false,
                        generic_modal: {
                            open: true,
                            message: this.state.reportType === 1 ? "KC2182" : "KC2183",
                            messagetype: "Success"
                        },
                    });
                    WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`, postdata);
                }
            }).catch(err => {
                this.setState({ loaderIconVisible: false,
                    generic_modal: {
                        open: true,
                        message: "KC1591",
                        messagetype: "Error"
                    }, });
                    WoopraEvents(`${Constants.GENARATE_NEW_REPORT_FAILED}`);  
            });
        }
    }

    onItemClick = async (e, link, isSelected) => {
        if(localStorage.getItem("pageType") === "View"){
            this.setState({ 
                loaderIconVisible: false
            });
            return;
        }
        else if(link.level_id === 1){
            this.setState({ loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    message: "KC2181",
                    messagetype: "Alert"
                }, 
            });
            return;
        }
        
        if (this.state.linkList.start_level_id){
            if (link.level_id<this.state.linkList.start_level_id){
                return
            }
        }
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        let cgid = [],prevCgid=[];
        for(let i=0; i<Currentselected.length; i++){
            cgid.push(Currentselected[i].cust_gp_id)
        }
        for(let i=0; i<previousselected.length; i++){
            if(!cgid.includes(previousselected[i].cust_gp_id)){
                prevCgid.push(previousselected[i].cust_gp_id)
            }
        }

        if(prevCgid.length || !isSelected){
            await this.unselectCGID(prevCgid.length ? prevCgid.join(',') : link.cust_gp_id)
        }
        if(isSelected){
            await this.getUnitsViaCGID(cgid.join(','));
        }

        localStorage.setItem("selectedGroupID", cgid.join(','));

        this.setState({
            link: link, isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false
        })
    }

    onUnitItemClick(e, unit) {
        if(localStorage.getItem("pageType") === "View"){
            this.setState({ 
                loaderIconVisible: false,
            });
            return;
        }

        let unitSelected = this.state.checkedUnits ? this.state.checkedUnits : [];
        let userCheckedUnitData = unitSelected.filter(unit => unit.isNodeSelected === false);
        if (unitSelected.length >= 500) {
            this.setState({
                loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    // message: "Your selection includes " + unitSelected.length + " units, please select a maximum of 500 units.",
                    message: 'KC2144',
                    messagetype: "Alert",
                    count: unitSelected.length,
                    total: 500
                }
            });
        }
        else if (userCheckedUnitData.length >= 50) {
            this.setState({
                loaderIconVisible: false,
                generic_modal: {
                    open: true,
                    // message: "You have reached the limit of " + userCheckedUnitData.length + " individual units selected. You can still select nodes, to a maximum of 500 units.",
                    message: 'KC2146',
                    messagetype: "Alert",
                    count: userCheckedUnitData.length,
                    total: 500
                }
            });
        }
        else {
            let isExist = unitSelected.filter((i) => i.ID === unit.id);

            if (isExist.length > 0) {
                const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
                unitSelected.splice(findIndex, 1);
            } else {
                unitSelected.push({ ID: unit.id, UNITID: unit.UNITID, CUSTOMER_GROUP_ID: unit.CUSTOMER_GROUP_ID, isNodeSelected: false });
            }

            this.setState({
                checkedUnits: unitSelected,
                isFormValidated: unitSelected.length > 0,
                isUnitItemClicked: true,
                link: {},
                isAddressAvailable: false,
                unit: unit,
                isLoactionTypeAvailable: false,
                level: 6
            })
        }
    }

    onOrgCheckBoxChange(e, unit) {
        if(localStorage.getItem("pageType") === "View"){
            this.setState({ 
                loaderIconVisible: false
            });
            return;
        }

        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id, UNITID: unit.UNITID, CUSTOMER_GROUP_ID: unit.CUSTOMER_GROUP_ID, isNodeSelected: false });
        }

        this.setState({
            isFormValidated: unitSelected.length > 0 ? true : false,
            checkedUnits: unitSelected,
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6,
        })
    }

    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            let { checkedUnits } = this.state;
            return link.units.map((unit, index) => {
                let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled" : ""
                treeActive = "";
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                let unitInActive = (unit.ACTIVATION_STATUS === "INACTIVE") ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
                
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false
                treeActive = ifExist ? "treeActive" : "";
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';

                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        {!showCollpaeCount && <div class="customCheckbox">
                            <input id={unit.UNITID} name={unit.UNITID} type="checkbox" checked={ifExist} value={unit.UNITID} onChange={(e) => this.onOrgCheckBoxChange(e, unit)} />
                            <label for={unit.UNITID}></label>
                        </div>}
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                >{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }

    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }

    onCollapseButtonClick(e, link) {
        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }
        this.setState({ showLink: showLink })
    }

    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }

    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        let selectedGroupID = this.state.selectedGroupName;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;
                let cust_gp_value = selectedGroupID.length > 0 ?  selectedGroupID.find(cust => cust.cust_gp_id === link.cust_gp_id.toString()) : "";
                const isParentDivActive = (link.dynasty && (selectedDynasty.indexOf(link.dynasty) > -1 || (cust_gp_value !== undefined && cust_gp_value !== ""))) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && (selectedDynasty.indexOf(link.dynasty) > -1 || (cust_gp_value !== undefined && cust_gp_value !== ""))) ? 'treeActive' : '';
                const showCollpaeCount = this.showCollpaseItemCount(link);
                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link, isParentDivActive === '') }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList, isParentDivActive === '') }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }

    getUnitsViaCGID = async (cgid) => {
        this.setState({ loaderIconVisible: true })
        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid': localStorage.getItem("selectedbrandID"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID, UNITID, CUSTOMER_GROUP_ID } = response.data.units[i];
                    let isExist = unitSelected.filter((i) => i.ID === ID);
                    if (isExist.length === 0) {
                        unitSelected.push({ ID, UNITID, CUSTOMER_GROUP_ID, isNodeSelected: true })
                    }
                }
                this.setState({ checkedUnits: unitSelected, loaderIconVisible: false })
            }

        }).catch(err => {
            this.setState({ loaderIconVisible: false })
        });
    }

    unselectCGID = async (cgid) => {
        this.setState({loaderIconVisible: true})

        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid': localStorage.getItem("selectedbrandID"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID } = response.data.units[i];
                    let index = unitSelected.findIndex((i) => i.ID === ID);
                    unitSelected.splice(index, 1);
                }
                this.setState({ checkedUnits: unitSelected,loaderIconVisible:false })
            }

        }).catch(err => {
        });
    }

    getDynacity = () => {
        let selCgid = localStorage.getItem("selectedGroupID");
        return new Promise((resolve, reject) => {
            if (selCgid) {
                http.get(`${UserProfile.get_customer_Dynasty}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgids': [selCgid],
                    },
                    data: {}
                })
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                })
            }
            else {
                resolve([]);
            }
        })
    }

    render() {
        let { generic_modal, selectedGroupName, checkedUnits} = this.state;
        const { loaderIconVisible, linkList, findTreeLevel } = this.state;  
        const pageType = localStorage.getItem("pageType"); 
        const { formatMessage } = this.props.intl;
        let selectedUnits = checkedUnits.filter(item => item.isNodeSelected === false);     
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={"Level" + item.level_id} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="orgznManagementWrapper">
                       <div className="assignOrgHeader">
                       {pageType === "View" ?  <ul>
                                <li></li>
                                <li>
                                    <FormattedMessage id="KC2204"/>
                                </li>
                                <li></li>
                            </ul>
                            : <ul>
                                <li>
                                    <Link to={{
                                        pathname: "/newReportWithOptions",
                                        state: {
                                            reportType: this.state.reportType,
                                            nameFromReport: this.state.nameFromReport,
                                            startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
                                            endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
                                            selectedoptionsFromReport: this.state.selectedoptionsFromReport,
                                            generateNow: this.state.generateNow,
                                            scheduleReport: this.state.scheduleReport,
                                            recipientsEmail: this.state.recipientsEmail,
                                            comment: this.state.comment,
                                            selectedRepetitionPattern: this.state.selectedRepetitionPattern,
											selectedLastPeriod: this.state.selectedLastPeriod,
											selectedCurrentPeriod: this.state.selectedCurrentPeriod,
											lastDays: this.state.lastDays,
											currentDays: this.state.currentDays,
											lastDaysPeriod: this.state.lastDaysPeriod,
											hourFormat: this.state.hourFormat,
											noEndDate: this.state.noEndDate,
                                            endDate: this.state.endDate,
                                            AMPMformat: this.state.AMPMformat,
                                            isEdit: this.state.isEdit,
                                            id: this.state.id,
                                            schedule_id: this.state.schedule_id,
                                            checkedUnits: this.state.checkedUnits,
                                            selectedGroupName: this.state.selectedGroupName,
                                            selectedDynasty: this.state.selectedDynasty,
                                            scheduledHourValue: this.state.scheduledHourValue,
											scheduledMinsValue: this.state.scheduledMinsValue,
                                        }
                                    }} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC2141"/>
					            </li>
                                <li >
                                    <Link id="#btnfinish" to="#" className={`btn-default-text btn forwardNext ${selectedGroupName.length>0 || selectedUnits.length>0 ? "activeState" : "disabled"}`} title={formatMessage({ id: 'KC0056' })} onClick={(e) => this.submitForExportReport(e)}><FormattedMessage id="KC0056"/></Link>
                                </li>
                            </ul>
                           }
                        </div>
                        <div className="selectedUnitHeader">
                            {
                                <ul>
                                    <li>
                                    {
                                    selectedGroupName.map((subitem, j) =>
                                        <span className="selectedVal">
                                        {subitem.group_name} &nbsp; 
                                        </span>
                                    )}
                                    </li>
                                    <li>
                                        &nbsp;
                                    </li>
                                </ul>
                            }
                        </div>
                            <div className="collapseExpandHeader">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>

                            <div className="assignOrgTreeOuter">

                                <div className="orgznSiteTree">
                                    {linkList && linkList.level1 &&
                                        linkList.level1.map((item) => this.showLinks(findTreeLevel))}
                                </div>

                            </div>
                        
                    </div>
                </div>
                <GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} values={{ count: generic_modal.count ? generic_modal.count : 0, total: generic_modal.total ? generic_modal.total : 0 }} onGenericCloseModal={(e) => this.onGenericCloseModal(e, generic_modal.messagetype)} />
                <UserManagementHeader headerName="Report Management" activeClass="reportNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel,
        searchVisible, searchText, clearSearchText
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportAssignTopology));
