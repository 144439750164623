import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { BaseService, MenuUploadAPI } from '../../service/api';
import http from '../../service/httpService';
import _ from 'lodash';
import moment from '../../utils/momentHelper.js';
import CommonOrgViewEditTopology from './CommonOrgViewEditTopology';
import momentJS from 'moment';


export class CommonDetailViewEdit extends Component {

    constructor(props) {
        super(props);
        this.descRef = React.createRef();
        this.state = {
            isEdit: false,
            loaderIconVisible: false,
            updatedCustomerGroups: null,
            dirty: false,
            descriptionDirty: false,
            customerGroupDirty: false,
            isOrgEdit: false,
        }
        this.onChangeDesc = this.onChangeDesc.bind(this);
        this.checkDirty = this.checkDirty.bind(this);
    }


    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var reportDetailsDHeight = ((windowHeight) - 120);
        $(".reportDetailsD").css("max-height", reportDetailsDHeight);
        var modalOrgViewHeight = ((windowHeight) - 100);
        $(".modalOrgView").css("height", modalOrgViewHeight);
    }

    closePopup() {
        this.props.onClose();
    }


    onClickEdit() {
        this.setState({
            isEdit: true,
        })
    }

    openOrgModal() {
        const { detailsProps } = this.props;
        if (!this.state.updatedCustomerGroups) {
            this.setState({
                updatedCustomerGroups: detailsProps.ASSIGNED_CUSTOMER_GROUP
            }, () => {
                $('#orgViewModal').modal('show');
            })
        } else {
            $('#orgViewModal').modal('show');
        }
    }

    checkDirty = () => {
        const { updatedCustomerGroups, isEdit } = this.state;
        const { ASSIGNED_CUSTOMER_GROUP, DESCRIPTION } = this.props.detailsProps;
        let descriptionDirty = false;
        let customerGroupDirty = false

        if (isEdit) {
            if (!DESCRIPTION) {
                descriptionDirty = !!this.descRef.current.value;
            } else if (DESCRIPTION !== this.descRef.current.value) {
                descriptionDirty = true
            }
        }

        if (updatedCustomerGroups != null) {
            // compare updatedCustomerGroups and updatedCustomerGroups
            if (updatedCustomerGroups.length == ASSIGNED_CUSTOMER_GROUP.length) {
                let compareCustomerGroups = _.difference(_.map(updatedCustomerGroups, 'cust_gp_id'), _.map(ASSIGNED_CUSTOMER_GROUP, 'cust_gp_id'));
                if (compareCustomerGroups.length > 0) {
                    customerGroupDirty = true;
                }
            } else {
                customerGroupDirty = true;
            }
        }

        this.setState({
            dirty: descriptionDirty || customerGroupDirty,
            descriptionDirty,
            customerGroupDirty
        });
    }

    updateNodeDetail() {
        this.checkDirty();

        if (!this.state.dirty) {
            return
        }
        const { updatedCustomerGroups, descriptionDirty, customerGroupDirty, isEdit } = this.state;
        const { detailsProps } = this.props;
        const { ID, BRAND_ID, ASSIGNED_CUSTOMER_GROUP,DESCRIPTION } = detailsProps;
        let payload = {};

        // TODO = GET UPDATED BY USER
        let loggedInUser = JSON.parse(localStorage.getItem("loginInfo"));
        let updateBy = loggedInUser.username;

        let cgIds = (updatedCustomerGroups || ASSIGNED_CUSTOMER_GROUP).map(e => e.cust_gp_id);
        let updatedDesc;
        if (isEdit) {
            updatedDesc = this.descRef.current.value;
        } else {
            updatedDesc = DESCRIPTION
        }

        payload.id = ID;
        payload.brandId = BRAND_ID;
        payload.isDescEdited = descriptionDirty;
        payload.description = updatedDesc;
        payload.isCgEdited = customerGroupDirty;
        payload.cgids = cgIds;
        payload.updatedUser = updateBy;


        // Update library details (node and desc) 
        this.setState({
            loaderIconVisible: true
        })
        http({
            method: "POST",
            url: `${BaseService.root}${MenuUploadAPI.updateLibraryInfo}`,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.status == '200') {
                // close loader
                this.setState({ loaderIconVisible: false })
                const event = { ...detailsProps }
                if (descriptionDirty) {
                    event.DESCRIPTION = payload.description
                }
                if (customerGroupDirty) {
                    event.ASSIGNED_CUSTOMER_GROUP = updatedCustomerGroups.map(e => { return { group_name: e.group_name, cust_gp_id: e.cust_gp_id } })
                }
                event.UPDATED_ON = moment.ConvertLocalTimeFromUTCTime(response.data.updatedOn, 'MMMM D, YYYY hh:mm A');
                event.UPDATED_BY = payload.updatedUser;
                $('#detailsInfoModal').modal('hide');
                this.props.onSave(event)
                this.props.onClose();
            }
        }).catch(err => {
            console.log("Failed to update:", err)
            this.setState({ loaderIconVisible: false })
        });

    }

    onSaveOrgPopup = (event) => {
        this.setState({
            updatedCustomerGroups: event.selectedNodes,
            isOrgEdit: event.isOrgDirty
        }, () => {
            this.checkDirty()
        })
    }

    onCloseOrgPopup = () => {
        $('#orgViewModal').modal('hide');
    }

    onChangeDesc = () => {
        this.checkDirty();
    }

    render() {
        let createBy;
        let updateBy;
        let updatedByEmail;
        let createdByEmail;
        let updatedOn;
        let createdOn;

        const { isEdit, updatedCustomerGroups, dirty, loaderIconVisible, isOrgEdit } = this.state;

        const { detailsProps, thumbnail } = this.props;
        const { ASSIGNED_CUSTOMER_GROUP, CREATED_BY, CREATED_ON, UPDATED_BY, UPDATED_ON, DESCRIPTION, LIBRARY_NAME } = detailsProps;
        const assignedCustomerGroups = updatedCustomerGroups || ASSIGNED_CUSTOMER_GROUP;
        createdOn = moment.ConvertLocalTimeFromUTCTime(CREATED_ON, 'MMMM D, YYYY, hh:mm A');
        if (!momentJS(UPDATED_ON, 'MMMM D, YYYY hh:mm A', true).isValid()) {
            updatedOn = moment.ConvertLocalTimeFromUTCTime(momentJS(UPDATED_ON), 'MMMM D, YYYY, hh:mm A');
        } else {
            updatedOn = UPDATED_ON;
        }
        if (CREATED_BY && UPDATED_BY) {

            let getNameCreatedBy = CREATED_BY && CREATED_BY.split("(");
            let getNameUpdatedBy = UPDATED_BY && UPDATED_BY.split("(");


            createBy = getNameCreatedBy[0];
            updateBy = getNameUpdatedBy[0];

            if(createBy.trim().length === 0 && getNameCreatedBy.length > 1){
                createBy = getNameCreatedBy[1].replace(")","")
            }
            if(updateBy.trim().length === 0 && getNameUpdatedBy.length > 1){
                updateBy = getNameUpdatedBy[1].replace(")","")
            }

            createdByEmail = getNameCreatedBy && getNameCreatedBy.length > 1 ? getNameCreatedBy[1].replace(')', '') : CREATED_BY
            updatedByEmail = getNameUpdatedBy && getNameUpdatedBy.length > 1 ? getNameUpdatedBy[1].replace(')', '') : UPDATED_BY
        }


        return (
            <React.Fragment>
                <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsEditModalLabel">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                {isEdit ? <FormattedMessage id='KC2279' values={{ libraryName: LIBRARY_NAME }} /> : <FormattedMessage id='KC2278' values={{ libraryName: LIBRARY_NAME }} />} <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closePopup()}>&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="reportDetailsD">
                                    <div className="descriptionEditV">
                                        <ul>
                                            {thumbnail ? <li className="menuPictureLi">
                                                <span className="menuPicture"><img src={thumbnail} /></span>
                                            </li> : null}
                                            <li>
                                                <h6><FormattedMessage id="KC0195" />{!isEdit && <button type="button" className="btn descriptionEdit" onClick={() => this.onClickEdit()}>&nbsp;</button>}</h6>
                                                {isEdit ? <textarea maxLength={250} minLength={10} ref={this.descRef} id="desc" name='desc' defaultValue={DESCRIPTION} onChange={this.onChangeDesc} className="form-control" rows="3" cols="50" /> :
                                                    DESCRIPTION ? DESCRIPTION :
                                                        <div className="descriptionPlaceholder">
                                                            {DESCRIPTION ? DESCRIPTION : <FormattedMessage id="KC2280" />}
                                                        </div>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    {localStorage.getItem("brandName") === "MERRYCHEF"?"":<h6><FormattedMessage id="KC2275" /></h6>}
                                    {localStorage.getItem("brandName") === "MERRYCHEF"?"":<div className="nodeList">
                                        <ul>
                                            {
                                                assignedCustomerGroups ? assignedCustomerGroups.map((subitem, j) =>
                                                    <li>{subitem.group_name}</li>
                                                ) : <li><FormattedMessage id='KC1919' /></li>
                                            }
                                        </ul>
                                    </div>}
                                    {localStorage.getItem("brandName") === "MERRYCHEF"?"":<p><FormattedMessage id='KC2122' /> <button type="button" data-toggle="modal" data-target="#orgViewModal" onClick={() => this.openOrgModal()} className="orgViewIcon btn" ><img src={require("../../images/icons/iconUnitSelection.svg").default} /></button><FormattedMessage id='to view assigned nodes.' /></p>}
                                    <div class="createdEditedDetailsO">
                                        <ul class="tHead">
                                            {localStorage.getItem("brandName") === "MERRYCHEF"? <li><FormattedMessage id='KC2369' /></li>:<li><FormattedMessage id='KC0605' /></li>}
                                            {localStorage.getItem("brandName") === "MERRYCHEF"? <li><FormattedMessage id='KC2370' /></li>:<li><FormattedMessage id='KC2277' /></li>}
                                        </ul>
                                        <ul class="tBody">
                                            <li>
                                                {localStorage.getItem("brandName") !== "MERRYCHEF" ?
                                                    <div class="createdEditedDetails">
                                                        <ul>
                                                            <li><FormattedMessage id='KC0241' />:</li>
                                                            <li>{createBy}</li>
                                                        </ul>
                                                        <ul>
                                                            <li><FormattedMessage id='KC0136' />:</li>
                                                            <li>{createdByEmail}</li>
                                                        </ul>
                                                        <ul>
                                                            <li><FormattedMessage id='KC0298' />:</li>
                                                            <li>{createdOn}</li>
                                                        </ul>
                                                    </div> :
                                                    <div class="createdEditedDetails">
                                                        <ul>
                                                            {/* <li><FormattedMessage id='KC0298' />:</li> */}
                                                            <li>{createdOn}</li>
                                                        </ul>
                                                    </div>}
                                            </li>
                                            <li>
                                            {localStorage.getItem("brandName") !== "MERRYCHEF" ?
                                                <div class="createdEditedDetails">
                                                    <ul>
                                                        <li><FormattedMessage id='KC0241' />:</li>
                                                        <li>{updateBy}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id='KC0136' />:</li>
                                                        <li>{updatedByEmail}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id='KC0298' />:</li>
                                                        <li>{updatedOn}</li>
                                                    </ul>
                                                </div> :
                                                <div class="createdEditedDetails">
                                                <ul>
                                                    {/* <li><FormattedMessage id='KC0298' />:</li> */}
                                                    <li>{updatedOn}</li>
                                                </ul>
                                            </div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {(isEdit || isOrgEdit) ? <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary cancelBtn" data-dismiss="modal" type="button" onClick={() => this.closePopup()}><FormattedMessage id='KC0021' /></button>
                                <button class='btn btn-secondary saveBtn' disabled={!dirty} type="button" onClick={() => this.updateNodeDetail()}><FormattedMessage id='KC0123' /></button>
                            </div> : null}

                        </div>
                    </div>
                </div>
                <CommonOrgViewEditTopology detailsProps={{ ...detailsProps, ASSIGNED_CUSTOMER_GROUP: assignedCustomerGroups }} onSave={this.onSaveOrgPopup} onClose={this.onCloseOrgPopup} />
            </React.Fragment>
        )
    }
}