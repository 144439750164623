
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getCustomer } from './../../../actions/listView/locations/customerAction';
import { getFilterbyCustomers } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';



class FilterByCustomer extends Component {	
	constructor(props) {
		super(props);	
		this.state ={
			customerList:"",
			selectedList:[],
			search:""
		}				
		this.getSelectedCustomerName=this.getSelectedCustomerName.bind(this);	
		this.callCustomerAction=this.callCustomerAction.bind(this);
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}

	componentDidMount() {
		let { state : previousState }  = this.props.location;
		let { prevSelectedFilterValue : prev_selectedCustomerList }  = previousState;
		if(prev_selectedCustomerList && prev_selectedCustomerList.length>0){
			this.setState({
		 selectedList : prev_selectedCustomerList
		});
		}

		let selectedLocationList = this.props.selectedLocations ? this.props.selectedLocations :[];
		let selectedCgids =[];
		selectedLocationList.map(c =>{ if(c.checked ===  true){
			selectedCgids.push(c.cust_gp_id)
        }});
		this.props.getCustomer(selectedCgids.join(','));	
	}

	getSelectedCustomerName(e,item){
		let isChecked = e.target.checked;
		this.state.selectedList.find(c => {
			if (c.ID === item.ID) {
				c.checked = isChecked
			}
		});

		this.setState({
			selectedList:this.state.selectedList
		});
		this.props.getFilterbyCustomers(this.state.selectedList);
		// var d = document.getElementById("spannavNotifiStatus");
		// d.className = "navNotifiStatus";
	}

	callCustomerAction() {
		this.state.selectedList && this.state.selectedList.length >0 && this.props.getFilterbyCustomers(this.state.selectedList);
	}

	render() {		
		let { selectedList: _customerList } = this.state;
		const {customerInfo} = this.props;

		if (customerInfo && customerInfo.length > 0) {
			if (_customerList && _customerList.length > 0) {
				for (let index = 0; index < _customerList.length; index++) {
					for (let k = 0; k < customerInfo.length; k++) {
						if (customerInfo[k].ID === _customerList[index].ID) {
							if (_customerList[index].checked) {
								customerInfo[k].checked = true;
								break;
							}
						}
					}
				}
				this.state.selectedList = customerInfo;
				_customerList = this.state.selectedList;
			} else {
				this.state.selectedList = customerInfo;
				_customerList = this.state.selectedList;
			}
		}

		const search=this.state.search;
		const searchedItemInFilter=_customerList.filter(
			(contact)=>{
				return contact.COMPANY_NAME.toLowerCase().startsWith(search.toLowerCase() )|| false;				
			}
		);
		_customerList=searchedItemInFilter;

		let previouspage = "/dashboardLocations";
		if (this.props.location.state !== undefined)
		{
			previouspage = this.props.location.state.prevPath.previouspage;
		}
		
		return (			
				<div>							
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>
						<LocationHeader />
					<div class="navFilterWrapper" style={{ display: 'block' }}>
						<div id="CountryFilter">
							<div class="filterHeader">
								<ul>
									<li>
										<Link onClick={this.callCustomerAction.bind(this)} to={{ pathname: '/locationsView/filterBy/', state: { paramvalue: this.state.customerList, selectedList: this.state.selectedList, prevPath: {previouspage} } }} title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>
									</li>
									<li>
										Customer
									</li>
									<li>
										<Link to={previouspage} title="Finish">Finish</Link>
										{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
									</li>
								</ul>
							</div>

							<div class="filterListOuter">
								<input class="form-control headerFilterSearch" value={this.state.search} onChange={this.updatesearch.bind(this)} type="text" placeholder="Search" />
								<div class={_customerList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
									{Object.keys(_customerList).map((item, i) => (
										<div key={_customerList[i].ID} class="customCheckbox customCheckboxWlabel">
											<input id={_customerList[i].COMPANY_NAME} class="chkGroupName" value={_customerList[i].COMPANY_NAME} checked={_customerList[i].checked} onChange={(e) => this.getSelectedCustomerName(e, _customerList[i])} name="testgroupName" type="checkbox" />
											<label for={_customerList[i].COMPANY_NAME}>{_customerList[i].COMPANY_NAME}</label>
										</div>
									))}
								</div>
							</div>
						</div>

					</div>
					
    				</div>

					</div>  
				)
			}
		};	

function mapStateToProps(state) {
	return {
		customerInfo: state.customerInformation.customerInformation,
		selectedLocations: state.filterInformation.selectedLocations,
	}
}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getCustomer, getFilterbyCustomers }, dispatch);
			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByCustomer);
