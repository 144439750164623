import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorMoal from '../../modal/error-model';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { getMediaImageLibraryList } from '../../../actions/navigation/mediaManagement/mediaImageLibraryListAction'
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BRAND_ID,  BASECOUNTRY_ID, } from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { MediaManagementAPI, BaseService, SoftwareManagementAPI } from '../../../service/api';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import _ from 'lodash';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';

class SoftwareLibraryList extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			mediaSummaryList: [],
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			brandList: []
		};
	}

	getBrands = () => {
		this.setState({ loaderIconVisible: true });
		let url = BaseService.root + SoftwareManagementAPI.getSoftwareBrandList;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				brandId: BRAND_ID,
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					brandList: response.data,
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener("resize", this.updateDimensions());
		// this.props.getMediaImageLibraryList('Reset');
		this.getBrands();
		localStorage.removeItem("selected_cgid")
		localStorage.removeItem('selectedFamilyID');
	}

	updateDimensions() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

	componentDidUpdate = () => {
        this.updateDimensions();
    }

	getMediaLibrary = () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let responseResult = {
			data: []
		}
		return new Promise((resolve, reject) => {
			http.get(MediaManagementAPI.getMediaLibraryAPI, {
				headers: {
					'Content-Type': 'application/json',
					'customerId': CUSTOMER_ID,
					'brandId': BRAND_ID,
					'cgids': cgids,
					'basecountry': BASECOUNTRY_ID
				},
				data: {}
			}).then(response => {
				this.setState({
					loaderIconVisible: false,
					mediaLibraryListInformation: response.data
				});
				resolve(responseResult);
			}).catch(err => {
				reject(err);
			});
		});
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	onButtonClickHandler = (item) => {
		WoopraEvents(`${Constants.SOFTWARE_BRAND_SELECTED} ${item.BRAND_NAME}`);
		localStorage.setItem("brandName", item.BRAND_NAME);
		localStorage.setItem("unitBrandId", item.BRAND_ID)
		localStorage.setItem("selectedbrandID", item.BRAND_ID)
		if (item.BRAND_NAME === "CREM") {
			this.props.history.push(`/softwareLibraryUpdate`);
		}
		else {
			this.props.history.push(`/softwareAvailableUpdates`);
		}
	}

	// Woopra Events
	trackEvent =(e) => {
		WoopraEvents(`${Constants.MENU_AND_UNITSETTING}-${e.target.title}`)
	}

	render() {
		const { loaderIconVisible, error_modal } = this.state;
		 
		let { brandList } = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		if (Object.keys(brandList).length > 0 && brandList.branList) {

			branList = MenuBrandsResponse(brandList.branList);
			branList = _.orderBy(branList, "BRAND_NAME", "asc");
			menuItemList = (
				branList.map((item) =>
					<ul className="tBody" key={item.ID} onClick = {() => this.onButtonClickHandler(item)}>
						<li>{orderId++}</li>
						<li>{item.BRAND_NAME}</li>
						<li className={item.BRAND_NAME === "CONVOTHERM" ? "logoH" : ""}>{<img src={item.img} alt="" />} </li>
						<li><button type="button" className="btn forwardNext">&nbsp;</button></li>
					</ul>
				)
			)
		}
		else {
			menuItemList = (
				<div className="brandsLibraryTable">
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={branList !== undefined ? branList.length : 0} classname={'norecordsfoundbluetext'} />
					</div>)
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress -->
        		<!-- Fixed Header navbar --> */}
				{/* <!-- //Fixed Header navbar --> */}
				<div id="page-content-wrapper">
					<div className="softwareUpdateWrapper">
						<div className="mediaULHeader">
							<ul>
								<li className="text-center p-3">
									<FormattedMessage id="KC0884"/>
								</li>
							</ul>
						</div>
						<div className="brandsLibraryTable">
									<ul className="tHead">
										<li>#</li>
										<li><FormattedMessage id="KC0873"/></li>
										<li><FormattedMessage id="KC2332"/></li>
										<li>&nbsp;</li>
									</ul>
							</div>
						<div className='pageScrollbar'>
						<form className="brandsLibraryForm">
							<div className="brandsLibraryTable">
								{menuItemList}
							</div>
						</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				</div>
				<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
		mediaImageLibraryListInfo: state.mediaImageLibraryListInformation.mediaImageLibraryListInformation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
		getMediaImageLibraryList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareLibraryList);