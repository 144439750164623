export default {
    filterKey: "dashBoardFilters",
    mediaFilters: "mediaFilters",
    softwareFilters: "softwareFilters",
    menuFilters: "menuFilters",
    basecountryHeaderName: "basecountry",
    errorCenterFilter:"errorCenterFilter",
    userManagementFilter:"userManagementFilter",
    orgManagementFilter:"orgManagementFilter",
    smartGroupFilter:"smartGroupFilter",
    monday: "Monday",
    kcbrandsitedata: "kcbrandsitedata",
    kcTransferData: "kcTransferData"
}

export const BRAND_ID_VALUE = {
    CREM: 4,
    MERCO: 2,
    MERRYCHEF: 1,
    CONVOTHERM: 6,
    FRYMASTER: 3,
    ['MANITOWOC ICE']: 5,
    DELFIELD: 7,
    MULTIPLEX: 8,
    GARLAND: 9,
    LINCOLN: 10,
    ['FRYMASTER-UHCTHD']: 11
};

let brands = localStorage.getItem('brandId') !== "undefined" ? JSON.parse(localStorage.getItem('brandId')) : [];
export let CUSTOMER_ID = localStorage.getItem("customerId") || 1;
export let BASECOUNTRY_ID = localStorage.getItem("basecountry") || "";
export let ACCESSCOUNTRY_ID = localStorage.getItem("accesscountry") || "";
export let BRAND_ID = brands && brands.length>0 ? brands.join(',') : [1,2,4,6,3,5,7,8,9,10,11].join(',');
export const BRANDNAME = localStorage.getItem("brandName");
export let UNIT_BRAND_ID = localStorage.getItem('unitBrandId');
export let UNIT_ID = localStorage.getItem('unitID');
export let PRODUCT_NAME = localStorage.getItem('modelName');
export const TIMER_DOUBLE_TAPS_WIDGET_ID = 1;
export const NO_OF_HOLDING_CYCLES_WIDGET_ID = 2;
export const AVERAGE_HOLDING_CYCLES_WIDGET_ID = 3;
export const TOTAL_ON_TIME_WIDGET_ID = 5;
export const TOTAL_ON_PERIOD_TIME_WIDGET_ID = 6;
export const TOTAL_OFF_TIME_WIDGET_ID = 7;
export const ON_TIME_PERCENTAGE_WIDGET_ID = 8;
export const RESET_CYCLE_WIDGET_ID = 9;
export const OVERALL_PAN_UTILIZATION_WIDGET_ID = 20;
export const TOTAL_TRAY_WIDGET_ID = 30;
export const AVG_TRAYS_WIDGET_ID = 31;
export const CONVO_TOP_TEN_PRODUCT_WIDGET_ID = 37;
export const FRYMASTER_UHCTHD_TOTAL_ON_TIME_WIDGET_ID = 95;
export const CONVOTHERM_TOTAL_ON_TIME_WIDGET_ID = 115;
export const FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID = 93;
export const FRYMASTER_UHCTHD_TIMER_DOUBLE_TAPS_WIDGET_ID = 92;
export const FRYMASTER_UHCTHD_RESET_CYCLE_WIDGET_ID = 99;
export const FRYMASTER_UHCTHD_AVERAGE_HOLDING_CYCLES_WIDGET_ID = 94;
export const FRYMASTER_UHCTHD_TOTAL_OFF_TIME_WIDGET_ID = 97;
export const FRYMASTER_UHCTHD_TOTAL_ON_PERIOD_TIME_WIDGET_ID = 96;
export const FRYMASTER_UHCTHD_ON_TIME_PERCENTAGE_WIDGET_ID = 98;
export const RESTRICTED_CHAR = "%^&./?\:*<>|";

export const getRestrictSmartGroupCountValue = () =>{
    const hostname = window && window.location && window.location.hostname;
    let RESTRICT_SMART_GROUP_UNIT_COUNT = 200;
    if(hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com' || hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com' || hostname === 'localhost') {
        RESTRICT_SMART_GROUP_UNIT_COUNT = 20;
    } 
     return RESTRICT_SMART_GROUP_UNIT_COUNT;
};

export const setBrandId = (brandids) =>{
    BRAND_ID = brandids;
};
export const setunitBrandId = (unitbrandids,unitid,modelname) =>{
    UNIT_BRAND_ID = unitbrandids;
    UNIT_ID = unitid;
    PRODUCT_NAME = modelname;
};
export const setcustomerId = (customerids) =>{
    CUSTOMER_ID = customerids;
};
export const setbasecountryId = (basecountryids) =>{
    BASECOUNTRY_ID = basecountryids;
};
export const setaccessCountryId = (accesscountryids) =>{
    ACCESSCOUNTRY_ID = accesscountryids;
}

export const restrictedCharAvl = (itemOfRestChar,typedvalue) => { 
    return typedvalue.includes(itemOfRestChar);
}

var quarterHours = ["00", "30"];
var thirtyMntstimesIntervel = ["12:00 AM", "12:30 AM",];
for(var i = 1; i < 12; i++){
    for(var j = 0; j < 2; j++){				 
        thirtyMntstimesIntervel.push(i + ":" + quarterHours[j]+" AM");
    }
        
}
thirtyMntstimesIntervel.push("12:00 PM", "12:30 PM",);
for(var i = 1; i < 12; i++){
    for(var j = 0; j < 2; j++){				
        thirtyMntstimesIntervel.push(i + ":" + quarterHours[j]+" PM");
    }
}
export let thirty_Mnts_Interval = thirtyMntstimesIntervel;

export const NO_RESULT_MSG = "No Results";
export const MEDIA_NO_RESULT_MSG = "Media management feature not available.";
export const MERCO_UNIT_REPORT = "Merco Unit(s) Report";
export const BY_FRYER_SERIAL_NUMBER = "Reports by Fryer Serial Number";
export const FRYMASTER_UHCTHD_UNIT_REPORT = "FRYMASTER-UHCTHD Units Report";
export const ASSIGN_UNIT_POPUP_MSG = "Units that haven't been activated will not display any data.";
//User Story 55218: KC Portal - Software Transfer Status changes
export const HOVER_TEXT_SCHEDULED = "KC1727";
export const HOVER_TEXT_QUEUED = "KC1728";
export const HOVER_TEXT_UPDATING = "KC1729";
export const HOVER_TEXT_PENDING = "KC1742";
export const HOVER_TEXT_EXPIRED = "KC1730";
export const HOVER_TEXT_COMPLETED = "KC1731";
export const HOVER_TEXT_CANCELED = "KC1732";
//User Story 55073: KC Portal - Add Forgot Password link in managePassword page
export const FORGET_PASSWORD_CONFIRM_MESSAGE = "This action will reset your password and you'll receive an email with a new temporary password to access KitchenConnect. Do you want to proceed?";

export const THANK_YOU_TEXT = 'Thank you for registering your new ';
export const THANK_YOU_TEXT_END = ' into KitchenConnect, the online platform to remotely monitor and maintain your equipment.';

export const twelvehourArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export const twentyfourhourArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
export const bakingPlanCustomer = "rewe";
export const bakingPlanDescription = "BakingPlanUpload";
export const bakingPlanType = "productionPlan"
export const unitSelectionAllowOnTransfer = 300;