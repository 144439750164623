
export default {
    tokenKey : "Access-Token",
    loginInfo : "loginInfo",
    rememberMe : "RememberMe",
    authorization: "Authorization",
    tokenExpireTime :"tokenExpireTime",
    allFeaturesKey: "allFeatures",
    currentFeaturesKey : "currentFeatures",
    userBrands : "brandId",
    refreshToken: "refreshToken",
    refreshTokenErrorStatus: "refreshTokenErrorStatus",
    signoutStatus: "signoutStatus"
}