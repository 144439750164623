import React, { Component } from 'react';
import Routes from './routers/routes';
import MainLayout from './layout/mainLayout';
import CheckInternetConnection from './components/controls/checkInternetConnection';
import $ from 'jquery';
import ReactGA from 'react-ga';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import {projectTitleCustom} from './components/controls/footerComponent';
import authConstants from './utils/authConstants';
import authHelper from './utils/authHelper';
registerLocale('es', es);

ReactGA.initialize('UA-136516950-3');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  componentDidMount() {
    $("#NoInternetConnectionModal").modal("show");
    document.title = projectTitleCustom();
    const { refreshToken, tokenExpireTime } = authConstants;
    window.onunload = function (e) {
      const newTabCount = localStorage.getItem('tabsOpen')
      if (newTabCount !== null && newTabCount > 0) {
        localStorage.setItem('tabsOpen', parseInt(newTabCount) - parseInt(1))
      }
    }
    let isExpired, emulation, rememberMe;
    let token = localStorage.getItem(refreshToken);
    let expTime = localStorage.getItem(tokenExpireTime);
    if (expTime != null) {
      let expireTime = new Date(expTime);
      let currentTime = new Date();
      isExpired = currentTime > expireTime;
      emulation = localStorage.getItem('emulation');
      rememberMe = localStorage.getItem('RememberMe');
    }
    
    localStorage.refresh = localStorage.refresh != null ? localStorage.refresh : 0;
    const _prevValue = localStorage.refresh;
    localStorage.setItem("refresh", parseInt(localStorage.refresh) + 1);
    const _currentValue = localStorage.refresh;
    const tabsOpen = localStorage.getItem('tabsOpen')
    if (tabsOpen == null || tabsOpen == 0) {
      localStorage.setItem('tabsOpen', 1)
      if (_prevValue != _currentValue) {
        if ((!rememberMe && isExpired) || (!token && isExpired)) {
          authHelper.deleteLocalSession();
          window.location.href = '/';
        }
      }
    } else {
      localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
      const newTabCount = localStorage.getItem('tabsOpen')
      if (newTabCount !== null && newTabCount > 1) {
        if ((rememberMe && token) || (token && !emulation && !isExpired)) { }
        else if ((!rememberMe && isExpired) || (!token && isExpired)) {
          authHelper.deleteLocalSession();
          window.location.href = '/';
        }
      }
    }
  }
  
 

  render() {
    return (
      <React.Fragment>
        <CheckInternetConnection />
        <MainLayout />
        <Routes />
      </React.Fragment>
    )
  }
};

export default App;
