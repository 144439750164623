import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import GlobalHeaderLayout from '../../layoutSection/globalHeaderLayout'; 
import DeviceDetailsWidget from './devicedetailswidget';
import DeviceStatusWidget from './devicestatuswidget';
import ModelInformationWidget from './modelinformationwidget';
import LocationInformationWidget from './locationinformationwidget';
import SoftwareVersionWidget from './softwareversionwidget';
import ErrorSolvedWidget from './errorsolvedwidget';
import HelpWidget from './helpwidget';
import ActionsWidget from './actionswidget';
import WarrantyWidget from './warrantywidget';
import SupportWidget from './supportwidget';
import ConnectivityStatusWidget from './connectivitystatus';
import InstalledPackages from './installedpackages';

class MainUnitManagementWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {  
			loaderIconVisible: true
		}
	}

	componentDidMount = async () => {		
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	render() { 
		const { loaderIconVisible } = this.state;
		let storageBrandName = localStorage.getItem("brandName"); 
		const expobarProductName = localStorage.getItem("expobarModelName"); 
			return (
				<React.Fragment>
					<div id="wrapper">
						<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
							<div className="loadingProgressIn"></div>
						</div>
						<GlobalHeaderLayout activeClass="unitNav" />
						<div id="page-content-wrapper">
							<div class="gridViewTable marginTop2px">
								<div class="gridView selectedUnitBg">
									<DeviceDetailsWidget  currentUnit= {this.props}  currentLocation  = {this.props}/>
									<DeviceStatusWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>									
								</div>
							</div>
							<div class="gridViewTable gridViewAMcolm6DSM">
								<div class="gridView">
								{storageBrandName === "FRYMASTER" || storageBrandName === "MERRYCHEF" ? 
									<>
										<ModelInformationWidget  currentUnit= {this.props}  currentLocation  = {this.props}/>
										<LocationInformationWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
									</> :
									<>
										<ModelInformationWidget  currentUnit= {this.props}  currentLocation  = {this.props}/>
										<ConnectivityStatusWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
										<LocationInformationWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
									</>
								}
								</div>
							</div>

							<div class="gridViewTable gridViewAMcolm6DSM">
								<div class="gridView">
								{ (storageBrandName === "CREM" && (expobarProductName === "EX3" || expobarProductName === "DIAMANT PRO")) ?
										<>
											<SoftwareVersionWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
											<ErrorSolvedWidget withTag={true}  currentUnit= {this.props}  currentLocation  = {this.props}/>
										</>
									: (storageBrandName === "CREM") ?
										<>
											<InstalledPackages   currentUnit= {this.props}  currentLocation  = {this.props}/>
											<ErrorSolvedWidget withTag={true}  currentUnit= {this.props}  currentLocation  = {this.props}/>
										</>
									:
										<>
											<SoftwareVersionWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
											<ErrorSolvedWidget withTag={true}  currentUnit= {this.props}  currentLocation  = {this.props}/>											
										</> 
									}
								</div> 
							</div>

							<div class="gridViewTable gridViewAMcolm3D6DSM">
								<div class="gridView">
									{ localStorage.getItem("brandName") === "CREM" ?
										<>
										<ActionsWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>									
										<SupportWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>										
										<WarrantyWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>									
										</>
									:
										<>
										<HelpWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
										<ActionsWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>									
										<WarrantyWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
										</>								
									}
								</div>
							</div>

						</div>
					</div>
				</React.Fragment>
			)
	}
};
export default MainUnitManagementWidget; 