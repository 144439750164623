import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getBrand } from './../../../actions/listView/locations/brandAction';
import { getFilterbyBrands } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';
import { getLocationGridInfo } from '../../../actions/allLocations/locations/dashboardLocationsGridAction';

let FindSelectedBrandName="";

class FilterByBrand extends Component {	
	constructor(props) {
		super(props);	
		this.state ={
			brandList:"",
			selectedList:[],
			search:'',			
		}				
		this.getSlectedBrandName=this.getSlectedBrandName.bind(this);	
		this.callBrandAction=this.callBrandAction.bind(this);
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}

	componentDidMount() {		
		 
		let { state : previousState }  = this.props.location;
		let { prevSelectedFilterValue : prev_selectedBrandList }  = previousState;
		if(prev_selectedBrandList && prev_selectedBrandList.length>0){
			this.setState({
		 selectedList : prev_selectedBrandList
		});
		}

		let selectedCustomerList = this.props.selectedCustomers ? this.props.selectedCustomers :[];
		let selectedCgids =[];
		selectedCustomerList.map(c =>{ if(c.checked ===  true){
			selectedCgids.push(c.ID)
        }});
		this.props.getBrand(selectedCgids.join(','));
		FindSelectedBrandName="";	
		
	}

	getSlectedBrandName(e,item){
		let isChecked = e.target.checked;
		
		this.state.selectedList.find(c =>{ if(c.ID === item.ID){
			c.checked = isChecked 
		}});
		let selectedCgids =[];
		this.state.selectedList.map(c =>{ if(c.checked ===  true){
			selectedCgids.push(c.cust_gp_id)
		}});

		
		this.setState({
			selectedList:this.state.selectedList,
			cgid:selectedCgids.join(','),

		});
		this.props.getFilterbyBrands(this.state.selectedList);

		// var d = document.getElementById("spannavNotifiStatus");
		// d.className = "navNotifiStatus";

		if(selectedCgids.length > 0)
		this.props.getLocationGridInfo(selectedCgids.join(','));
		else{
			this.props.getLocationGridInfo();
		}

	}	

	callBrandAction() {
		this.props.getFilterbyBrands(this.state.brandList);
		this.state.selectedList && this.state.selectedList.length >0 && this.props.getFilterbyBrands(this.state.selectedList);
	}

	render() {									
			
			let checkvalue=[];			
			let { selectedList : _brandList  } = this.state;			
              const {brandInfo} = this.props;
			          
			if (brandInfo && brandInfo.length > 0) {
				if(_brandList && _brandList.length>0){
									 for (let index = 0; index < _brandList.length; index++) {
						 for (let k = 0; k < brandInfo.length; k++) {
								if(brandInfo[k].ID === _brandList[index].ID)
								{
								if(_brandList[index].checked){
									brandInfo[k].checked=true;
									break;
								} 
								}
						 }
						}
						this.state.selectedList = brandInfo;
						_brandList = this.state.selectedList;
				}else{
					this.state.selectedList = brandInfo;
					_brandList = this.state.selectedList;
				}	 
			}

			const search=this.state.search;
			const searchedItemInFilter=_brandList.filter(
				(contact)=>{
					return contact.BRAND_NAME.toLowerCase().startsWith(search.toLowerCase()) || false; 				
				}
			);
			_brandList=searchedItemInFilter;

			let previouspage = "/dashboardLocations";
			let locationDb = "/dashboardLocations?cgid="+this.state.cgid;
			if (this.props.location.state != undefined)
			{
				locationDb = "/" + this.props.location.state.prevPath.previouspage + "?cgid="+this.state.cgid;
				previouspage = this.props.location.state.prevPath.previouspage;
			}
			
			return (			
				<div>							
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>
						<LocationHeader />
						<div class="navFilterWrapper" style={{display: 'block'}}>
							<div id="CountryFilter">
								<div class="filterHeader">
									<ul>										
										<li>
											<Link onClick={this.callBrandAction.bind(this)} to={{pathname:'/locationsView/filterBy/', state : {paramvalue: this.state.brandList, selectedList: this.state.selectedList, prevPath: {previouspage}} }}   title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>																						
										</li>										
										<li>
											Brand
										</li> 																
										<li>
											<Link to={previouspage} title="Finish">Finish</Link>
											{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
										</li>
									</ul>
								</div> 
								
								<div class="filterListOuter">
									<input class="form-control headerFilterSearch" value={this.state.search} onChange={this.updatesearch.bind(this)} type="text" placeholder="Search"/> 
									<div class={_brandList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
										{Object.keys(_brandList).map((item, i) => (
											<div key={_brandList[i].ID} class="customCheckbox customCheckboxWlabel">												
												<input  id={_brandList[i].BRAND_NAME} class="chkGroupName" value={_brandList[i].BRAND_NAME} checked={_brandList[i].checked} onChange={(e) => this.getSlectedBrandName(e, _brandList[i])} name="testgroupName" type="checkbox" />
												<label for= {_brandList[i].BRAND_NAME}>{_brandList[i].BRAND_NAME}</label>	
											</div>
										))}																			
									</div>  
								</div>
							</div>
							
						</div>
    				</div>

					</div>  
				)
			}
		};	

		function mapStateToProps(state) {
			return {
				brandInfo: state.brandInformation.brandInformation,			
				FindSelectedBrandName:state.FindSelectedBrandName,
				selectedCustomers: state.filterInformation.selectedCustomers,
			}
		}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getBrand,getFilterbyBrands,getLocationGridInfo }, dispatch);
			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByBrand);
