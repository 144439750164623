import React, { Component } from 'react';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import { UserMgmtAddUser,BaseService } from '../../../service/api';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserDataModel, setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';	
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import NoRecordsFound from '../../controls/noRecordsFound';
import { BASECOUNTRY_ID ,BRAND_ID } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants'
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import Switch from 'react-switch';
import 'bootstrap/dist/css/bootstrap.min.css';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { userManagementData } from '../../../actions/userManagement/userManagementAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import moment from '../../../utils/momentHelper.js';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage,injectIntl } from 'react-intl';
import $ from 'jquery';
import GoToPagination from '../../controls/goToPagination';
let isSelectedAllCheckbox = false;


const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

const userImages = [
	{ name: "System Manager", img: require("../../../images/users/owner.svg").default },
	{ name: "Crem System Admin", img: require("../../../images/users/owner.svg").default },
	// { name: "Admin", img: require("../../../images/users/manager.svg").default },
	{ name: "Service Technician", img: require("../../../images/users/service.svg").default },
	// { name: "Media Manager", img: require("../../../images/users/leaser.svg").default },
	// { name: "Operator", img: require("../../../images/users/chef.svg").default },
	{ name: "1st Line User", img: require("../../../images/users/leaser.svg").default },
	{ name: "2nd Line User", img: require("../../../images/users/chef.svg").default },
	{ name: "Crem User", img: require("../../../images/users/manager.svg").default }
]

class UserManagementList extends Component {

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	constructor(props) {
		super(props);

		this.state = {
			customerId: localStorage.getItem("customerId"),
			userMgmtList: [],
			users: [],
			userSelectAll: false,
			isIndeterminateSelect:false,
			userChecked: [],
			editingUser: {},
			isAdd: true,
			isEdit: false,
			isDelete: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			loaderIconVisible: false,
			sortColumn: { path: "name", order: "asc", sort_color: "#63778a" },
			userListFlag: true,
			searchData: null,
			userEmail:"",
			totalPages: null,
			currentPage: 1,
			pageSize: 25,
		};

		this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
        };
		// this.getAddUserList(); for default checkbox auto disables and edit and delete icon enabled fix
		this.getUserToState = this.getUserToState.bind(this);
		this.resetUserModel = this.resetUserModel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.editUser = this.editUser.bind(this);
		this.closeModel = this.closeModel.bind(this);
		// this.cancelDeleteUser = this.cancelDeleteUser.bind(this);
		this.deleteSelectedUser = this.deleteSelectedUser.bind(this);
		this.handleActiveCheckboxChange = this.handleActiveCheckboxChange.bind(this);
		this.getAddUserList = this.getAddUserList.bind(this);
	}

	updateDimensions() {
        const windowHeight = $(window).height();
        const $tableBody = $('.userMgmtTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0; 
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

	componentDidMount = async () => { 
		window.addEventListener('resize', this.updateDimensions()); 
		this.updateDimensions();
		this.props.searchVisible(true);
		this.setState({ loaderIconVisible: true }); 
		document.body.style.backgroundColor = "#F2F2F2";

		try {
			await this.getFilterDetails();
			this.getAddUserList(1);
		} catch (error) {
			//Error Goes Here
		}
	}
	
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message === "The incoming token has expired" ? "KC1065" : data.stringID || data.message
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false
				// error_modal:{
				// 	open:true,
				// 	message: "Your session has expired. Please log in again."
				// }

			});
			//  localStorage.removeItem("Access-Token");
			//  this.props.history.push(`/`) 
		}
	}

	getFilterDetails = async () =>{
		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.userManagementFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			 selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
		});

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			//cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
			sMachineTypes: selectedMachineTypes,
		});

		var d = document.getElementById("spannavNotifiStatus");
        if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
            d.className = "navNotifiStatus";
        }			
        else {
            d.className = "";
        }
	}

	getAddUserList = async (currentPage, sortColumn = this.state.sortColumn) => {
		let { searchData } = this.state;
		let req = this.filterstate;
		let userDetails = localStorage.getItem("loginInfo");
		let userName = JSON.parse(userDetails).username;
		let url = UserMgmtAddUser.getUserList + localStorage.getItem("customerId") + "&&email=" + userName;

		//searchData_function
		let _search = await searchData_function(searchData);

		if( _search ){
			url = `${url}${_search}`;
		}
		if(sortColumn && sortColumn.path){
			url = `${url}&sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
		}

		this.setState({loaderIconVisible: true} , () =>  {
		});
		let headers = { 
			'Content-Type': 'application/json',
			'cgid': req.sCgids,
			'brandid': req.sBrandids.length>0?req.sBrandids.join(','): "",					
			'machinetype': req.sMachineTypes,
			'role': localStorage.getItem('roleId') || ''
	 	}
		 if (localStorage.getItem('emulation')) {
			headers.emulation = localStorage.getItem('emulation');
			headers.base_country = localStorage.getItem('basecountry');
		 }
		
		 let pageNumber = `&pageNum=` + currentPage;
		 let pageLimit = '&pageSize=' + this.state.pageSize;
		 url = url + pageNumber + pageLimit;
    	http.get(url,
			{
				headers,
				data: {}
			}).then(response => {
				if ( response && response.data ) {
					let { response: usersList } = response.data;

					usersList.map((item, i) => {
						item.order_id = ++i;
						let pickRole = userImages.find(r => r.name === item.roleName);
						if (pickRole != null) {
							item.img = pickRole.img;
						}
						else if (pickRole === undefined) {
							item.img = require("../../../images/users/user.png");
						}
					});
					let checkAll = [];
					checkAll = usersList.filter(c => c.checked === false || c.checked === undefined);
					if(usersList){
						this.setState({ currentPage })
					}
					this.setState({totalPages: response.data.numberOfPages, sortColumn, userMgmtList: usersList, loaderIconVisible: false,...this.getCheckBoxstate(usersList.length,checkAll.length)});
					//this.setState({userMgmtList: usersList, loaderIconVisible: false});
					
				}

		}).catch(err => {
			this.setState({loaderIconVisible:false})
		});


	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		isSelectedAllCheckbox = userSelectAll;

		this.setState({ userSelectAll: userSelectAll,isIndeterminateSelect:false });
		var checkedCopy = [];
		let users = [...this.state.userMgmtList];
		users.forEach(function (e, index) {
			checkedCopy.push(userSelectAll);
		});

		users && users.length > 0 && users.map(item => {
			item.checked = userSelectAll;
		});

		this.setState({
			userChecked: checkedCopy,
			userMgmtList: users
		});

		this.isSelectEditDelete(checkedCopy);
	};


	getCheckBoxstate(ListCount,unselectedCount) {
		if (ListCount == unselectedCount) {
		  return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (unselectedCount == 0) {
		  return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
		  return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, path, order) => {
		isSelectedAllCheckbox = false;
		var checkedCopy = this.state.userChecked;
		checkedCopy[ index ] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		
		let checkAll = [];
		var users = [...this.state.userMgmtList];
		users[index] = { ...users[index] };
		users[index].checked = e.target.checked;
		checkAll = users.filter(c => c.checked === false || c.checked === undefined);

		this.setState({
			userMgmtList: users,
			userChecked: checkedCopy,...this.getCheckBoxstate(users.length,checkAll.length)
		});
		this.isSelectEditDelete(users, path, order);
		
	};

	isSelectEditDelete = (users, path, order) => {
		let ictr = 0;
		var checkedCopy = this.state.userChecked;
		if (users.length === 0) {
			this.setState({ userSelectAll: false });
			return;
		}
		var userNames = [...this.state.userMgmtList];
		userNames = _.orderBy(userNames, path, order);

		users.forEach(i => {
			if (i.checked === true || i === true) {
				ictr = ictr + 1
			}

		});

		if (ictr > 1) {
			this.setState({
				isAdd: false,
				isEdit: false,
				isDelete: true
			});
		}
		else if (ictr === 0) {
			this.setState({
				isAdd: true,
				isEdit: false,
				isDelete: false
			});
		}
		else if (ictr === 1) {
			this.setState({
				isAdd: false,
				isEdit: true,
				isDelete: true
			});
		}

		if (isSelectedAllCheckbox === true) {
			if (ictr === 0) {
				this.setState({
					isEdit: true,
					isDelete: true,
				});
			}
			else if (ictr === 1) {
				this.setState({
					isAdd: false,
					isEdit: true,
					isDelete: true
				});
			}
			else if (ictr > 1) {
				this.setState({
					isAdd: false,
					isEdit: false,
					isDelete: true
				});
			}
		}

	}

	handleActiveCheckboxChange = (index, userEmail, status, e) => {

		this.setState({
			loaderIconVisible: true
		});

		isSelectedAllCheckbox = false;
		index = index - 10001;
		var checkedCopy = this.state.userChecked;

		var users = [...this.state.userMgmtList];
		users[index] = { ...users[index] };
		users[index].is_active = !status;

		this.setState({
			userMgmtList: users,
			userSelectAll: false,
			userChecked: checkedCopy
		});

		let postData = {};
		let alertMessage = "";
		if (!status) {
			alertMessage = "activated";
			postData = {
				"type": "activated-user",
				"user": {
					"email": userEmail,
					"is_active": 1,
				}
			}
		}
		else {
			alertMessage = "deactivated";
			postData = {
				"type": "deactivate-user",
				"user": {
					"email": userEmail,
					"is_active": 0,
				}
			}
		}


		this.putData(postData, UserMgmtAddUser.addUser, HTTP_METHOD.POST).then(response => {
			if (response.data.responseCode === 200) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: !status? 'KC0607': 'KC0608'
					}
				});
				WoopraEvents(`${Constants.USER_STATUS}-${alertMessage}`)
			}
		}).catch(err => {
			this.showError(err);
		});
	};


	editUser = () => {
		let userModel = this.getUserToState();
		let allUsers = [ ...this.state.userMgmtList ];
		
		let editUserDetails = allUsers.filter(item => item.checked === true);
		WoopraEvents(`${Constants.USER_EDIT}`);
		this.props.history.push({
			pathname: '/editUser',
			userModel: userModel,
			role_id: editUserDetails[0].role_id,
			email: editUserDetails[0].email
		})
	}


	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { responseCode } = data;
				if (responseCode !== 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getUserToState = () => {
		let getUserModel = {
			user: {
				name: "",
				firstName: "",
				lastName: "",
				email: "",
				retypeEmail: "",
				country_code: "",
				phoneNumber: "",
				customerId: localStorage.getItem("customerId")
			},
			roleId: 0,
			userGroup: [],
			userPreference: [],
			countryCodeList: []
		};

		this.props.getUserDataModel(getUserModel);
	}

	resetUserModel = (e) => {
		let initialUserModel = {
			user: {
				name: "",
				email: "",
				retypeEmail: "",
				country_code: "",
				phoneNumber: "",
				customerId: localStorage.getItem("customerId")
			},
			roleId: 0,
			userGroup: [],
			userPreference: [],
			countryCodeList: []
		};
	
	}

	deleteSelectedUser = () => {
		let allUsers = [...this.state.userMgmtList];
		let deletedUsers = allUsers.filter(item => item.checked === true);
		let checkEmail = deletedUsers.map(function (item) { return item.email });
		const selectedEmail = JSON.stringify(checkEmail);
		let selfEmail = JSON.parse(selectedEmail);
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;

		let Loginuserinformation = [];
		selfEmail.map(c => {
			if (c === loggedinUser) {
				Loginuserinformation.push(c)
			}
		});

		if (Loginuserinformation && Loginuserinformation.length > 0) {
			let currentuser = deletedUsers.filter(item => item.email === Loginuserinformation);
			deletedUsers = deletedUsers.filter(item => item.email !== currentuser[0].email);
			const { formatMessage } = this.props.intl;
			this.setState({
				loaderIconVisible: false,
				userSelectAll: false,
				isAdd: true,
				isEdit: false,
				isDelete: false,
				error_modal: {
					open: true,
					message: formatMessage({ id: "KC1067" }, { name: currentuser[0].name })
				}
			});
		}

		if (deletedUsers && deletedUsers.length > 0 && deletedUsers != null) {
			let postData = {
				users: deletedUsers.map(item => {
					return {
						username: item.email,
						base_country: BASECOUNTRY_ID,
						"Id": item.Id,						
						"createdBy": item.createdBy,
					}
				})
			};

			this.setState({
				loaderIconVisible: true
			})


			this.putData(postData, UserMgmtAddUser.deleteUser, HTTP_METHOD.DELETE).then(response => {
				if (response.data.responseCode === 200) {
					let newUsersList = allUsers.filter(item => !item.checked || item.checked === false);

					var checkedCopy = [];
					newUsersList.forEach(function (e, index) {
						checkedCopy.push(false);
					});

					newUsersList && newUsersList.length > 0 && newUsersList.map(item => {
						item.checked = false;
					});

					this.setState({
						loaderIconVisible: false,
						userChecked: checkedCopy,
						userSelectAll: false,
						userMgmtList: newUsersList,
						isAdd: true,
						isEdit: false,
						isDelete: false,
						success_modal: {
							open: true,
							message: "KC1068"
						}
					});
					WoopraEvents(`${Constants.USER_DELETED}`);
				}
			}).catch(err => {
				this.showError(err);
			});
		}

		this.getAddUserList(1);
	}

	onSuccessCloseModal = () => {
		this.getAddUserList(1);
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		});
	}

	onSort = sortColumn => {
		this.getAddUserList(1, sortColumn)
	}

	componentDidUpdate ( prevState ) {
		this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getAddUserList(1);
		}
	}

	resetPasswordHanddler = (e) => {
		this.setState({ loaderIconVisible: true	});
		let postData = {
			"username": this.state.userEmail
		}
		http({
			method: "POST",
			url: BaseService.submit_forget_password,
			data: postData,
		}).then(response => {
			if (response && response.data) {
				if (response.data.responseCode === 200) {
					this.setState({
						loaderIconVisible: false,
						success_modal: {
							open: true,
							message: response.data.stringID || response.data.message
						}
					})
					WoopraEvents(`${Constants.RESET_PASSWORD_REQUEST_SEND}`);
				} else {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: (response.data.stringID || response.data.message) || "KC1069"
						}
					})
					WoopraEvents(`${Constants.RESET_PASSWORD_REQUEST_FAILED}`)
				}
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response.data.stringID || err.response.data.message
				}
			})
		})
	}

	setSelectedUserValue = (e) => {
		this.setState({
			userStatus: e.target.checked ? "activated-user" : "deactivate-user",
			userEmail: e.target.value,
			userID: e.target.id,
		})
	}

	callOrgView = () => {		
		let allUsers = [ ...this.state.userMgmtList ];
		let editUserDetails = allUsers.filter(item => item.checked === true);

		let getUserModel = {
			user: {
				name: editUserDetails[0].name,
				firstName: editUserDetails[0].first_name,
				lastName: editUserDetails[0].last_name,
				email: editUserDetails[0].email,
				retypeEmail: editUserDetails[0].email,
				country_code: "",
				phoneNumber: "",
				customerId: localStorage.getItem("customerId")
			},
			roleId: editUserDetails[0].role_id,
			userGroup: [],
			userPreference: [],
			countryCodeList: [],
			previousPage:"/userManagementList"
		};

		let userModel = this.props.getUserDataModel(getUserModel);
		WoopraEvents(`${Constants.USER_ASSIGN}`);
		this.props.history.push({
			pathname: '/assignUserToTopology',
			userModel: getUserModel,
			role_id: editUserDetails[0].role_id,
			email: editUserDetails[0].email
		})
	}

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${Constants.USER_MANAGEMENT}-${e.target.title}`)
	}

	static getDerivedStateFromProps = (props) => ({...props});

	/**
	* When navigate new page this event will return current page number
	* @param {*} currentPage 
	*/
	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true },()=>{
			this.getAddUserList(currentPage);
		})
	}

	render() {
		const { sCgids, sCids, sBrandids, sModels, sMachineTypes } = this.filterstate;
		let { success_modal, error_modal, loaderIconVisible, totalPages, currentPage, pageSize} = this.state;
		let { userMgmtList, isAdd, isEdit, isDelete, sortColumn} = this.state;
		let userProfileAuthFeature = authService.getFeature("/addUser");
		let orderId = 1;
		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "name", labelStringID: "KC0241" },
			{ path: "roleName", labelStringID: "KC0588" },
			{ path: "createdBy", labelStringID: "KC0589", isNbSp: true },
			{ path: "lastLogin", labelStringID: "KC0590" },
			{ path: "activation_status", labelStringID: "KC0536" },
			{ path: "", labelStringID: "KC0591" },
			{ path: "", label: "" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];
		let rowNumber = (currentPage * pageSize) - pageSize;

		userMgmtList.forEach(item => {
			item.order_id = orderId++;
		});

		if (sortColumn.path === "name") {
			userMgmtList = _.orderBy(userMgmtList, [userMgmtList => userMgmtList.name && userMgmtList.name.toString().toLowerCase()], [sortColumn.order]);
		}
		else if (sortColumn.path === "roleName") {
			userMgmtList = _.orderBy(userMgmtList, [userMgmtList => userMgmtList.roleName && userMgmtList.roleName.toString().toLowerCase()], [sortColumn.order]);
		}
		// show loader
		let ele = document.getElementById("page-loader");

		if( ele) {

			let show ="none";
			if ( loaderIconVisible) {
				show = 'block';
			}
			setTimeout(()=> {
				ele.removeAttribute('style');
				ele.style.display = `${show}`;
			}, 0) ;
		}

		const { formatMessage } = this.props.intl;
		return (
			<div id="adminWrapper">
				<div id= "page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="userManagementWrapper"> 
							<div className="userMgmtHeader">
								<ul>
									<li>
										<Link to="/userManagementList" className="active" title={formatMessage({ id: "KC0592"})} ><FormattedMessage id='KC0592' /></Link>		
										<Link to="/userManagementRoles"  title={formatMessage({ id: "KC0585"})} ><FormattedMessage id='KC0585' /></Link>										
									</li>
									<li>
										<React.Fragment>
												<button type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text assignunitSite ${isEdit ? "" : "disabled"}` : "btn btn-default-text assignunitSite disabled"} title={formatMessage({ id: "KC0594"})}  onClick={() => { this.callOrgView() }}>&nbsp;</button>
												<button type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userEdit ${isEdit ? "" : "disabled"}` : "btn btn-default-text userEdit disabled"} title={formatMessage({ id: "KC0595"})}  onClick={() => { this.editUser() }}>&nbsp;</button>											
												<button type="button" data-toggle="modal" data-target="#DeleteConfirmationModal" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text userDelete disabled"} title={formatMessage({ id: "KC0584"})} onClick={() => { }}>&nbsp;</button>
												<Link to={{ pathname: '/addUser', state: { prevPath: "userManagementList" } }} onClick={() => { this.resetUserModel() }} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userAdd ${isAdd ? "" : "disabled"}` : "btn btn-default-text userAdd disabled"} draggable="false" title={formatMessage({ id: "KC0593"})}>&nbsp;</Link>
										</React.Fragment>
									</li>
								</ul>
							</div>
						<form className="userMgmtListForm">
							<div className="userMgmtListTable">
								<TableHeader
									userMgmtListScreen={this.state.userListFlag}
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isIndeterminate={this.state.isIndeterminateSelect}
									isSelectAll={this.state.userSelectAll} />
							</div> 
							<div class="userMgmtTableSrlBar">

							
						
							<div className="userMgmtListTable">
								{
									userMgmtList.map((item, i) => {
										var emailValue = Buffer.from(userMgmtList[i].email);
										var ciphertext = emailValue.toString('base64');

										//const url = "/myInformation?user="+userMgmtList[i].email;
										const url = "/myInformation?user=" + ciphertext;
										return (
											<ul className={item.createdBy === '-'?"tBody disabled":"tBody"} key={i}>
												<li>{rowNumber + i + 1}</li>
												<li className={item.is_active ? "" : "disabled"}><span className="userDP"><img src={item.img} /></span></li>
												<li className={item.is_active ? "" : "disabled"}><Link to={url}>{item.name}</Link><span class="userMListRL">{item.roleName}</span></li>
												<li className={item.is_active ? "" : "disabled"}>{item.roleName}</li>
												<li>{item.createdBy}</li>
												<li>{item.lastLogin? moment.GetDateTimeByFormat(item.lastLogin, 'LLL'):""}</li>
												<li>{item.activation_status}</li>
												<li>
													<div className="btn-group btn-group-toggle" data-toggle="buttons">
														<Switch
															onChange={(e) => this.handleActiveCheckboxChange(item.order_id + 10000, item.email, item.is_active, e)}
															checked={item.is_active}
															uncheckedIcon={false}
															checkedIcon={false}
															onColor={'#c5cdd7'}
															offColor={'#ffffff'}
															offHandleColor={'#0d3968'}
															onHandleColor={'#0d3968'}
														/> 
													</div>
												</li>
												<li>
													<button type="button" value={item.email} onBlur={(e) => this.setSelectedUserValue(e)} onChange={(e) => this.setSelectedUserValue(e)} onClick={(e) => this.setSelectedUserValue(e)}
													data-toggle="modal" data-target="#confirmationYesNoModal" className="btn btn-default" ><FormattedMessage id={'KC0609'} /></button>
												</li>

												{<li>
													<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
														<input id={item.order_id - 1} type="checkbox" value={item.createdBy === '-' ? false :item.checked} defaultChecked={item.createdBy === '-' ? false :item.checked}
															checked={item.createdBy === '-' ? false :item.checked}
															onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1, [sortColumn.path], [sortColumn.order])} />
														<label htmlFor={item.order_id - 1}></label>
													</div>
												</li>
												}
											</ul>
										)
									})
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={userMgmtList !== undefined ? userMgmtList.length : 0} classname={'norecordsfoundbluetext'} stringID={this.state.searchData && Object.keys(this.state.searchData).length !== 0 && userMgmtList && userMgmtList.length === 0 ? "KC0853" : "KC0205"}/>
							</div>
							{ totalPages && totalPages > 1 ? <GoToPagination
								totalPageNumber={totalPages}
								pageNumber={currentPage}
								navigateTo={(p) => this.onPageChange(p)} /> : null }
						</form>
					</div>
				</div>
				<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
							<div className="modal-dialog modal-md loginModal">
								<div className="modal-content" >
									<div className="modal-body">
										<div className="singOutConfirmation">
										<FormattedMessage id={'KC0614'} />
                            		</div>
									</div>
									<div className="modal-footer textAlignCenter">
										<button className="btn btn-secondary signOutNoBtn" onClick={() => this.cancelDeleteUser()} data-dismiss="modal" type="button"><FormattedMessage id={'KC0021'} /></button>
										<button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedUser()} data-dismiss="modal" type="button"><FormattedMessage id={'KC0611'} /></button>
									</div>
								</div>
							</div>
						</div>
				{/* <!-- //Inactive Confirmation -->
                        <!-- Confirmation Modal --> */}
                            <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="confirmationYesNoModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="confirmationYesNo">
                                                <h5><FormattedMessage id={'KC0609'} /></h5>
                                                <p><FormattedMessage id={'KC0610'} /></p>
                                            </div>
                                        </div>
                                        <div className="modal-footer textAlignCenter">
                                            <button className="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id={'KC0580'} /></button>
                                            <button className="btn btn-secondary yesBtn" id="resetPasswordYes" data-dismiss="modal" onClick={(e) => this.resetPasswordHanddler(e)} type="button"><FormattedMessage id={'KC0611'} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- //Confirmation -->
                        <!-- Confirmation Modal --> */}
                            <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="confirmationDiv">
											<FormattedMessage id="KC1070"/>.
                                        </div>
                                        </div>
                                        <div className="modal-footer textAlignCenter">
                                            <button className="btn btn-secondary confirmationOKBtn" onclick="location.href='usermanagementlist.html'" type="button"><FormattedMessage id={'KC0777'} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* END */}



				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />				
				<UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav"  />
			</div>
		)
	}
};



function mapDispatchToProps(dispatch, ownProps) 
{
	return bindActionCreators({
		getUserDataModel, setUserDataModel,getSelectedFilterList,
		searchVisible, userManagementData, searchText
	}, dispatch);
}

function mapStateToProps(state, ownProps) {
	return {
		userModel: state.userReducer.UserModel,
		filterlistInformtion: state.filterModel,
		searchData: state.advanceSearch.searchData,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagementList));