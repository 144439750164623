import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class SuccessMoal extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);

        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = (e) => {
        this.props.onSuccessModel();
    };

    render() {
        const { open, message, isHtml = false, stringID, newMessageFormat = false } = this.props;
        let successMsg = (
            <div className="modal fade SignInSuccessModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel" style={{ display: "block" }}>
                <div className="modal-dialog modal-md loginModal">
                    <div className="modal-content">
                        <div className="modal-body">
                            {isHtml ? <div className="singINConfirmation" dangerouslySetInnerHTML={{ __html: stringID || message }} /> : <div className="singINConfirmation">{stringID ? <FormattedMessage id={stringID} /> : message}</div>}
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" type="button" onClick={this.onCloseModal}><FormattedMessage id="KC0777" /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
        if (newMessageFormat) {
            successMsg = (
                <div>
                    <div className="modal fade confirmationRMModal show" data-keyboard="true" data-backdrop="static" id="confirmationOkModa1" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog modal-md loginModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                    Success 
                                </div>
                                <div class="modal-body">
                                    <div class="reportStatusModal">
                                    <span class="rmStatusIcon"><img src={require('../../images/icons/iconReportSuccessful.svg').default} /></span>
                                    <h6> {stringID ? <FormattedMessage id={stringID} /> : message}</h6>
                                    </div>
                                </div>
                                <div class="modal-footer textAlignCenter">
                                    <button className="btn btn-secondary confirmationOKBtn" onClick={this.onCloseModal} type="button"><FormattedMessage id="KC0777" /></button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={() => { }}>
                    {successMsg}
                </Modal>
            </div>
        );
    }
}
export default SuccessMoal;