
import $ from 'jquery';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const EmailContentModal = ({ selectedNotification, addSpaceBetweenComma }) => {
    return (
        <div class="modal fade contentViewModal" data-keyboard="true" data-backdrop="static" id="emailContentModal" tabindex="-1" role="dialog" aria-labelledby="contentViewModalLabel">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header modal-header-danger">
                        <FormattedMessage id="KC0136"/> <button type="button" class="close" onClick={() => $('#flowDetailsViewModal').modal('show')} data-dismiss="modal" aria-label="Close">&nbsp;</button>
                    </div>
                    <div class="modal-body">
                        <div className='modalOverflowScroll' style={{ height: (($(window).height()) - 120) }}>
                            <table width="100%" bgcolor="#253466" cellpadding="0" cellspacing="0" border="0" id="backgroundTable" st-sortable="header">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="tableDevicewidth" style={{ fontFamily: 'Calibri, Candara, Segoe,Arial, sans-serif' }}>
                                                <tbody>
                                                    <tr>
                                                        <td width="100%">
                                                            {/* <!-- Header --> */}
                                                            <table bgcolor={selectedNotification.event === 'Error Critical' ? "#C8102E" : selectedNotification.event === 'Error Non-critical' ? '#FFC000' : '#3E5796'} width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="tableDevicewidth">
                                                                <tbody>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td height="5" className="msoLineHeightClass" style={{ fontSize: 1, lineHeight: 1 }}>&nbsp;</td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td>
                                                                            {/* <!-- start of menu --> */}
                                                                            <table width="250" border="0" align="left" valign="middle" cellpadding="10" cellspacing="0" border="0" class="tableDevicewidth">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ fontSize: 20, color: '#ffffff', textTransform: 'uppercase' }} st-content="phone" height="50">
                                                                                            <FormattedMessage id="KC1704"/>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            {/* <!-- end of menu -->
                                                                            <!-- logo --> */}
                                                                            <table width="140" align="right" border="0" cellpadding="10" cellspacing="0" class="tableDevicewidth">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="140" height="60" align="center">
                                                                                            <div class="imgpop">
                                                                                                <a target="_blank" href="https://www.welbiltconnect.com/">
                                                                                                    <img src="https://www.welbiltconnect.com/static/media/WBTKitchenConnectWhite.280cdc8b.png" alt="" border="0" width="280" style={{ display: 'block', border: 'none', outline: 'none', textDecoration: 'none' }} />
                                                                                                </a>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            {/* <!-- end of logo -->  */}
                                                                        </td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td height="5" className="msoLineHeightClass" style={{ fontSize: 1, lineHeight: 1 }}>&nbsp;</td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}


                                                                    {/* <!-- Start of main-Content -->  */}
                                                                    <tr>
                                                                        <td>
                                                                            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="tableDevicewidth">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: 10, fontSize: 18, color: '#ffffff', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                                            <FormattedMessage id="KC1705"/>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ padding: 10, fontSize: 18, color: '#ffffff', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                                            <FormattedMessage id="KC0864"/>: {selectedNotification && selectedNotification.brand ? selectedNotification.brand : ''} {selectedNotification && selectedNotification.model ? addSpaceBetweenComma(selectedNotification.model) : ''}, {selectedNotification && selectedNotification.equipment_type ? selectedNotification.equipment_type : ''}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ padding: 10, fontSize: 18, color: '#ffffff', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                                            <FormattedMessage id="KC1706"/>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    {/* <!-- End of main-Content -->   */}
                                                                </tbody>
                                                            </table>
                                                            {/* <!-- end of Header --> */}
                                                            {/* <!-- Body Content --> */}
                                                            <table bgcolor="#f2f2f2" width="600" cellpadding="10" cellspacing="0" border="0" align="center" class="tableDevicewidth">
                                                                <tbody>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td height="5" className="msoLineHeightClass" style={{ fontSize: 1, lineHeight: 1 }}>&nbsp;</td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <FormattedMessage id="KC1707"/>:
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <FormattedMessage id="KC0102"/>: <b>{selectedNotification && selectedNotification.event ? selectedNotification.event : ''}</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <FormattedMessage id="KC1708"/>: <b>{selectedNotification && selectedNotification.event_code ? selectedNotification.event_code : ''}, {selectedNotification && selectedNotification.event_name ? selectedNotification.event_name : ''}</b>
                                                                        </td>
                                                                    </tr>
                                                                    {selectedNotification && selectedNotification.event_description ? <tr>
                                                                        <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <b><FormattedMessage id="KC0195"/></b><br />
                                                                            {selectedNotification && selectedNotification.event_description ? selectedNotification.event_description : ''}
                                                                        </td>
                                                                    </tr> : null}
                                                                    {selectedNotification.resolution && selectedNotification.resolution.length ? <tr>
                                                                        <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <b><FormattedMessage id="KC1697"/></b><br />
                                                                            <ul className="tBody">
                                                                                <li>{selectedNotification.resolution && selectedNotification.resolution.length ? selectedNotification.resolution.map((action, index) => {
                                                                                    return <p className="notificationResolutionStep" key={'action_' + index}>{index + 1}. {action}</p>
                                                                                }) : null}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr> : null}
                                                                    <tr>
                                                                        <td style={{ width: '100%' }}>
                                                                            <table width="100%" cellpadding="0" cellspacing="0" border="0" align="center" class="tableDevicewidth">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        {selectedNotification.video_url && selectedNotification.video_url !== "" ? <td style={{ padding: 10, fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif', width: '50%' }}>
                                                                                            <b><FormattedMessage id="KC1698"/></b><br />
                                                                                            <a href={selectedNotification.video_url} target="_blank"><FormattedMessage id="KC1709"/></a>
                                                                                        </td> : null}
                                                                                        {selectedNotification.other_link_url && selectedNotification.other_link_url.website_link && selectedNotification.other_link_url.website_link !== "" ? <td style={{ fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif', width: '50%' }}>
                                                                                            <b><FormattedMessage id="KC1699"/></b><br />
                                                                                            <a href={selectedNotification.other_link_url.website_link} target="_blank"><FormattedMessage id="KC1709"/></a>
                                                                                        </td> : null}
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ borderTop: '1px solid #bfbfbf', fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            <b><FormattedMessage id="KC1710"/></b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontSize: 16, color: '#253466', fontFamily: 'Helvetica, arial, sans-serif' }}>
                                                                            (<FormattedMessage id="KC1711"/>)<br />
                                                                            (<FormattedMessage id="KC1712"/>) (<FormattedMessage id="KC1713"/>) <br />
                                                                            (<FormattedMessage id="KC1714"/>) (<FormattedMessage id="KC1715"/>)<br />
                                                                            (<FormattedMessage id="KC1716"/>)<br />
                                                                        </td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td height="5" className="msoLineHeightClass" style={{ fontSize: 1, lineHeight: 1 }}>&nbsp;</td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                </tbody>
                                                            </table>
                                                            {/* <!-- end of Body Content --> */}
                                                            {/* <!-- Footer With Logo --> */}
                                                            <table width="600" cellspacing="0" cellpadding="0" border="0" class="tableDevicewidth">
                                                                <tbody>
                                                                    <tr>
                                                                        {/* <!-- start of image --> */}
                                                                        <td align="center">
                                                                            <img width="100%" border="0" alt="" border="0" src="https://www.welbiltconnect.com/static/media/Welbilt_Colored_2.jpg" />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/* <!-- end of Footer With Logo --> */}
                                                            {/* <!-- Footer --> */}
                                                            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="tableDevicewidth">
                                                                <tbody>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td width="100%" height="20"></td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td align="center" valign="middle"
                                                                            style={{
                                                                                fontFamily: 'Helvetica, arial, sans-serif',
                                                                                fontSize: '14px',
                                                                                color: '#ffffff'
                                                                            }}
                                                                            st-content="preheader">
                                                                            <FormattedMessage id="KC1717"/>
                                                                        </td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td width="100%" height="10"></td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td align="center" valign="middle" style={{ fontFamily: 'Helvetica, arial, sans-serif', fontSize: 14, color: '#ffffff' }} st-content="preheader">
                                                                            <FormattedMessage id="KC1718" values={{ year: '2021' }}/> <a href="http://www.welbilt.com/" style={{ textDecoration: 'none', color: '#ffffff', textAlign: 'right' }}>Welbilt</a> | <FormattedMessage id="KC1719"/>
                                                                            &nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                    <tr>
                                                                        <td width="100%" height="20"></td>
                                                                    </tr>
                                                                    {/* <!-- Spacing --> */}
                                                                </tbody>
                                                            </table>
                                                            {/* <!-- end of Footer --> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailContentModal;