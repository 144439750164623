import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getMediaPackageList,  mediaSelectedItems } from '../../../actions/navigation/mediaManagement/mediaPackageListAction'
import $ from 'jquery';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import { MediaManagementAPI } from '../../../service/api';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { mediaDraggedItem, mediaDragDeleteItem, resetSelectedItems } from '../../../actions/navigation/mediaManagement/mediaDragAction';
import ReactPlayer from 'react-player'
import { BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../utils/appConstants';
import {updateDimensions_MediaPackage} from '../../helperFunctions'
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

class MediaPackage extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.loadPage();
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	closePlayer() {
		this.setState({
			previewurl: '',
			previewfilename: ''
		});
	}


	constructor(props) {
		super(props);
		this.state = {
			imagedata: {},
			userData: {},
			userCheckedData: [],
			userSelectAll: false,
			isIndeterminateSelect: false,
			loaderIconVisible: true,
			previewfilename: "",
			previewfileformat: "",
			previewurl: "",
			previewurl11: "",
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			isAdd: true,
			isDelete: false,
			isEdit: false,
			isAddToPreparation: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			dragItem: {},
			selectFileText: "Select Preparation from list",
			preparationSelectionClass: "initialActive",
			searchData: null,
		};

		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.cancelDeleteUser = this.cancelDeleteUser.bind(this);
		this.deleteSelectedMedia = this.deleteSelectedMedia.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.closePlayer = this.closePlayer.bind(this);
	}

	componentDidMount = async () => {
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		this.loadPage();
		$('.OpenImgMp4Upload').click(function () { $('#imgMp4upload').trigger('click'); });
		window.addEventListener('resize', updateDimensions_MediaPackage());
	}

	loadPage = () => {
		this.setState({loaderIconVisible: true});
		let { searchData } = this.state;
		let searchParam = {};
		if (searchData && searchData.searchtext) {
			searchParam['searchtext'] = encodeURIComponent(searchData.searchtext);
		}

		if (searchData) {
			Object.keys(searchData).map((item) => {
				if (item !== "searchtext") {
					searchParam[item] = encodeURIComponent(searchData[item]);
				}
			})
		}
		this.props.getMediaPackageList(searchParam).then((res)=>{
			this.setState({loaderIconVisible: false})
		});
	}

	async componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loaderIconVisible: true },()=>{
                this.loadPage();
            });
		}
	}

	
    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
		if(this.props.mediaPreparationListInfo && this.props.mediaPreparationListInfo.mediaList){
			this.props.mediaPreparationListInfo.mediaList = [];
		}
	}

	static getDerivedStateFromProps = (props) => ({...props});

	cancelDeleteUser = () => {
		WoopraEvents(`${Constants.MEDIA_CANCELED}`)
	}

	onAssignSmartGroupsList() {
		WoopraEvents(`${Constants.MEDIA_SG_ASSIGN}`);
		var rolesList = this.state.userCheckedData;
		if (rolesList.length > 0) {
			const item = rolesList[0];
			localStorage.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
			this.props.history.push({
				pathname: "/mediaAssignSmartGroupsList",
				previouspath: "/mediapackage",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				storeInstalledItemInfo: item,
				smart_groups_ids: item.SMARTGROUPS_ID,
			});
		}
	}

	onTransferMedia() {
		WoopraEvents(`${Constants.MEDIA_TRANSFER}`);
		const { mediaDraggedItemInfo } = this.props;
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			localStorage.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`;
			let val="";
			// if (item.SMARTGROUPS_ID){
			// 	val="smart-group-transfer";
			// }
			this.props.history.push({
				pathname: val?"mediaTransferUnits":"/assignmedialistView",
				previouspath: "/mediapackage",
				mediaSelectedItemsInfo:mediaDraggedItemInfo,
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				mediaDraggedItemInfo: item,
				transferType : val,
			});
		}
	}

	onChangeHandler = (e) => {
		let _this = this;

		if (e.target.files[0] !== null) {

			this.setState({
				loaderIconVisible: true
			});

			let fileSize = e.target.files[0].size;
			fileSize = (fileSize / 1048576).toFixed(2);

			let fileName = e.target.files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileextSplit = fileName.split('.');
			let fileext = fileextSplit[1].toLowerCase();
			let fileNamewithOutExt = fileextSplit[0];
			let filelength = fileName.length;

			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;

			let resolutionofimageVideo = 0;
			let mediaid = fileNamewithOutExt + '_' + Date.now();
			mediaid = mediaid.replace(/\s+/g, '');

			var FileUploadThumbnail = require('../../../utils/fileThumnailProcesser');
			let md5 = require('md5');
			let data = {
				"customer": localStorage.getItem("customerId"),
				"brand": 'CREM',
				"brand_id": 4,
				"type": 'preparation',
				"fileName": fileName,
				"fileFormat": '.' + fileext,
				"fileSize": fileSize,
				"user": loggedinUser,
				"mediaId": mediaid,
			};


			let url = `${MediaManagementAPI.getMedias3uploadpresigned}` + '?customer=' + localStorage.getItem("customerId") + '&key=' + mediaid + '.' + fileext + '&thumbnail=true';

			if (fileSize <= 80 && filelength <= 30) {
				var width;
				var height;
				let srcfile = e.target.files[0];
				if (fileext === "png") {
					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						var image = new Image();
						image.src = e.target.result;
						data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/png;base64,/, ""), "base64"));
						image.onload = (img) => {
							width = img.target.width;
							height = img.target.height;
							resolutionofimageVideo = width + " x " + height;
							if ((width > 1368 || height > 800)) {
								_this.setState({
									loaderIconVisible: false,
									error_modal: {
										open: true,
										message: "KC1514"
									}
								});
							}
							else {
								data.resolution = resolutionofimageVideo;
								data.category = 'picture';
								if (srcfile) {
									let avb = new FileUploadThumbnail({
										maxWidth: 80,
										maxHeight: 80,
										file: srcfile,
										onSuccess: (src) => {
											src = src.replace(/^data:image\/png;base64,/, "");
											let imageBuffer = Buffer.from(src, "base64");
											this.setState({
												loaderIconVisible: false
											});
											this.props.history.push({
												pathname: "/assignMedia",
												url: url,
												data: data,
												srcfile: srcfile,
												imageBuffer: imageBuffer,
												previouspath: "/MediaPreparation"
											});
										},
										onError: function (err) {
											_this.showError(err);
										}
									}).createThumbnail();
								}
							}
						};
					}
				}
				else if (fileext === 'jpg') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var image = new Image()
            image.src = e.target.result
            data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/jpg;base64,/, ''), 'base64'))
            image.onload = img => {
              width = img.target.width
              height = img.target.height
              resolutionofimageVideo = width + ' x ' + height
              if (width > 1368 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: 'KC1514'
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'picture'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')
                      this.setState({
                        loaderIconVisible: false
                      })
                      this.props.history.push({
                        pathname: '/assignMedia',
                        url: url,
                        data: data,
                        srcfile: srcfile,
                        imageBuffer: imageBuffer,
                        previouspath: '/MediaPreparation'
                      })
                    },
                    onError: function(err) {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            }
          }
        } else if (fileext === 'jpeg') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var image = new Image()
            image.src = e.target.result
            data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/jpeg;base64,/, ''), 'base64'))
            image.onload = img => {
              width = img.target.width
              height = img.target.height
              resolutionofimageVideo = width + ' x ' + height
              if (width > 1368 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: 'KC1514'
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'picture'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')
                      this.setState({
                        loaderIconVisible: false
                      })
                      this.props.history.push({
                        pathname: '/assignMedia',
                        url: url,
                        data: data,
                        srcfile: srcfile,
                        imageBuffer: imageBuffer,
                        previouspath: '/MediaPreparation'
                      })
                    },
                    onError: function(err) {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            }
          }
        }
				else if (fileext === "mp4") {

					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						var dataUrl = reader.result;
						var $videoEl = $('<video id="videoMain" class="displaynone"></video>');
						$("body").append($videoEl);
						$videoEl.attr('src', dataUrl);
						var videoTagRef = $videoEl[0];
						data.crc = md5(Buffer.from(reader.result.replace(/^data:video\/mp4;base64,/, ""), "base64"));
						videoTagRef.addEventListener('loadedmetadata', (e) => {
							width = videoTagRef.videoWidth;
							height = videoTagRef.videoHeight;
							resolutionofimageVideo = width + " x " + height;

							if ((width > 1500 || height > 800)) {
								_this.setState({
									loaderIconVisible: false,
									error_modal: {
										open: true,
										message: "KC1514"
									}
								});
							}
							else {
								data.resolution = resolutionofimageVideo;
								data.category = 'video';
								if (srcfile) {
									let avb = new FileUploadThumbnail({
										maxWidth: 80,
										maxHeight: 80,
										file: srcfile,
										onSuccess: (src) => {
											src = src.replace(/^data:image\/png;base64,/, "");
											let imageBuffer = Buffer.from(src, "base64");

											this.setState({
												loaderIconVisible: false
											});

											this.props.history.push({
												pathname: "/assignMedia",
												url: url,
												data: data,
												srcfile: srcfile,
												imageBuffer: imageBuffer,
												previouspath: "/MediaPreparation"
											});
										},
										onError: (err) => {
											_this.showError(err);
										}
									}).createThumbnail();
								}
							}
						});
					};
				}
				else if (fileext === 'h264') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var dataUrl = reader.result
            var $videoEl = $('<video id="videoMain" class="displaynone"></video>')
            $('body').append($videoEl)
            $videoEl.attr('src', dataUrl)
            var videoTagRef = $videoEl[0]
            data.crc = md5(Buffer.from(reader.result.replace(/^data:video\/h264;base64,/, ''), 'base64'))
            videoTagRef.addEventListener('loadedmetadata', e => {
              width = videoTagRef.videoWidth
              height = videoTagRef.videoHeight
              resolutionofimageVideo = width + ' x ' + height

              if (width > 1500 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: 'KC1514'
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'video'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')

                      this.setState({
                        loaderIconVisible: false
                      })

                      this.props.history.push({
                        pathname: '/assignMedia',
                        url: url,
                        data: data,
                        srcfile: srcfile,
                        imageBuffer: imageBuffer,
                        previouspath: '/MediaPreparation'
                      })
                    },
                    onError: err => {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            })
          }
        }
				else if (fileext === "zip") {
					data.resolution = resolutionofimageVideo;
					data.category = 'zip';
					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						data.crc = md5(Buffer.from(reader.result.replace(/^data:application\/x-zip-compressed;base64,/, ""), "base64"));
						this.setState({
							loaderIconVisible: false
						});
						this.props.history.push({
							pathname: "/assignMedia",
							url: url,
							data: data,
							srcfile: srcfile,
							imageBuffer: '',
							previouspath: "/MediaPreparation"
						});
					};
				}
				else {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: "KC1516"
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1517"
					}
				});
			}
		}
		$("#imgMp4upload").val('');
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,

			});

		}
	}

	onAssignClickHandler(item) {
		const { mediaDraggedItemInfo } = this.props;
		let selectedList = [];
		if (mediaDraggedItemInfo.other && mediaDraggedItemInfo.other.length > 0) {
			selectedList.push(mediaDraggedItemInfo.other[0].MEDIA_ID)
		}
		if (mediaDraggedItemInfo.prepartions && mediaDraggedItemInfo.prepartions.length > 0) {
			selectedList = selectedList.concat(new Array(mediaDraggedItemInfo.prepartions[0].MEDIA_ID));
		}

		this.props.mediaSelectedItems(selectedList);
		this.props.resetSelectedItems();
		this.props.history.push(`/assignPackageMedia`);

	}

	getCheckBoxstate(ListCount,SelectedCount) {
		if (SelectedCount == ListCount) {
		return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount == 0 && ListCount != 0) {
		return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
		return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange(e, rowData, listCount) {
		this.setState({ dragItem: rowData });
		let userCheckedData = this.state.userCheckedData;
		const findIndex = userCheckedData.findIndex((item) => item.MEDIA_ID === rowData.MEDIA_ID)
		if (findIndex > -1) {
			if (e.target.checked === false) {
				userCheckedData.splice(findIndex, 1)
			}
		} else {
			if (e.target.checked === true && rowData.isAuthorized) {
				userCheckedData.push(rowData)
			}
		}

		this.setState({
			userCheckedData: userCheckedData,
			...this.getCheckBoxstate(listCount,userCheckedData.length)
		});

		this.isSelectEditDelete(this.state.userCheckedData);
	}

	isSelectEditDelete = async(CheckedData) => {

		if (CheckedData && CheckedData.length === 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isEdit: true,
				isAddToPreparation: true,
				selectFileText: "Confirm selection",
				preparationSelectionClass: "active"
			});
		}
		else if (CheckedData && CheckedData.length > 1) {
			this.setState({
				isAdd: false,
				isDelete:true,
				isEdit: false,
				isAddToPreparation: false,
				selectFileText: "Select Preparation from list",
				preparationSelectionClass: "initialActive"
			});
		}
		else {
			this.setState({
				isAdd: true,
				isDelete: false,
				isEdit: false,
				isAddToPreparation: false,
				selectFileText: "Select Preparation from list",
				preparationSelectionClass: "initialActive"
			});
		}
	}

	handleChange = async() => {

		var userSelectAll = !this.state.userSelectAll;
		const { mediaPreparationListInfo } = this.props;
		let userChecked = [];
		for(let i=0; i<mediaPreparationListInfo.mediaList.length; i++){
			if(mediaPreparationListInfo.mediaList[i].isAuthorized){
				userChecked.push(mediaPreparationListInfo.mediaList[i]);
			}
		}
		mediaPreparationListInfo && mediaPreparationListInfo.length > 0 && mediaPreparationListInfo.map(item => {
			item.checked = item.isAuthorized ? userSelectAll: false;
		});


		if (userSelectAll === true) {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect: false,
				userCheckedData: userChecked,
				isAdd: false,
				isDelete:userChecked.length > 0,
				isEdit: userChecked.length === 1,
				isAddToPreparation: false
			});
		} else {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect: false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isEdit: false,
				isAddToPreparation: false
			});
		}
		this.setState({
			...this.getCheckBoxstate(mediaPreparationListInfo.mediaList.length, userChecked.length)
		});
	}

	deleteSelectedMedia = () => {
		const mediaPreparationList = [...this.state.userCheckedData];
		if (mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList != null) {
			let postData = {
				mediaIds: mediaPreparationList.map(function (item) { return item.MEDIA_ID })
			}
			this.setState({
				loaderIconVisible: true
			})
			this.putData(postData, MediaManagementAPI.getMediaBrandCustomer, HTTP_METHOD.DELETE).then(response => {
				if (response && response.data && response.data.message) {
					if (response.data.httpCode === "HTTP_200") {
						this.setState({
							isDelete: false,
							isAdd: true,
							isAddToPreparation: false,
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (response.data.stringID || response.data.message) || ""
							},
							isIndeterminateSelect: false,
							userSelectAll: false,
							isEdit: false
						});
						mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList.map(item => {
							item.checked = false;
						});
					}
				}
			}).catch(err => {
				this.showError(err);
			});
		}
		this.removeFileHandler('preparations');
		this.setState({
			selectFileText: "Select Preparation from list",
			preparationSelectionClass: "initialActive"
		});
	}

	LoadMediaList = () => {

		http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'customer': localStorage.getItem("customerId"),
					'brand': 'CREM',
					'type': 'preparation',
					'basecountry': BASECOUNTRY_ID
				},
				data: {}

			})
			.then(response => {
				console.log('media_preparation_list--->', response.data);
			})
			.catch(error => {
				this.showError(error);
			})
	}

	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	previewMediafile = (MEDIA_ID, FILE_NAME) => {
		if (MEDIA_ID && MEDIA_ID.length > 0 && MEDIA_ID != null) {
			http.get(`${MediaManagementAPI.getMediamediapreview}` + MEDIA_ID,
				{
					headers: {
						'Content-Type': 'application/json'
					},
					data: {}
				})
				.then(response => {

					let fileext = FILE_NAME.split('.');
					fileext = fileext[1];
					let previewformat = "";
					if (fileext === "png" || fileext === "jpg" || fileext === "jpeg")  {
						previewformat = "image";
					}
					else if (fileext === "mp4" || fileext === "h264" || fileext === 'zip') {
						previewformat = "video";
					}
					this.setState({
						previewfilename: FILE_NAME,
						previewurl: response.data.imageUrl,
						previewfileformat: previewformat
					});
				})
				.catch(error => {
					this.showError(error);
				})
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	componentWillReceiveProps(nextProps) {
		console.log('componentWillReceiveProps')
	}
	shouldComponentUpdate(nextProps, nextState) {
		console.log('shouldComponentUpdate')
		return true;
	}

	dragStart(e, item) {
		this.setState({ dragItem: item })

	}
	onDrop(e) {
		const { formatMessage } = this.props.intl;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		if (userProfileAuthFeature.is_editable === 1) {
			const { dragItem } = this.state;
			const { mediaDraggedItemInfo } = this.props;
			const draggedItem = { type: 'preparation', item: dragItem }
			if (!mediaDraggedItemInfo.prepartions || mediaDraggedItemInfo.prepartions.length === 0) {
				this.props.mediaDraggedItem(draggedItem);
			}

			this.setState({
				userSelectAll: false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isAddToPreparation: false,
				selectFileText: formatMessage({ id: 'KC0731' }),
				preparationSelectionClass: "initialActive"
			});
		}
		console.log('onDrop', e);
	}
	onDragOver(e) {
		console.log('onDragOver', e);
	}

	removeFileHandler(type = '') {
		if (type === 'preparations') {
			this.props.mediaDragDeleteItem({ type: 'preparations' });
		} else if (type === 'other') {
			this.props.mediaDragDeleteItem({ type: 'other' });
		}
	}
	updatePrepartionSelectedImage() {
		WoopraEvents(`${Constants.FILE_SELECTED}`);
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const { prepartions } = this.props.mediaDraggedItemInfo;
		return <div class="dragedDetailsTable">
			{prepartions && prepartions.length > 0 && <div class="dragedDetailsRow">
				<div class="dragedImage">
					<img src={(prepartions[0].FILE_FORMAT === 'zip' || prepartions[0].FILE_FORMAT === '.zip') ? zipimage : prepartions[0].thumbUrl} />
				</div>
				<div class="dragedText">
					<h5>{prepartions[0].FILE_NAME}</h5>
					<h6>{prepartions[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" class="removeImageVideo" onClick={() => this.removeFileHandler('preparations')}></a>
				</div>
			</div>}
		</div>
	}
	updateOtherSelectedImage() {
		const { other } = this.props.mediaDraggedItemInfo;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		return <div class="dragedDetailsTable compilationBg">
			{other && other.length > 0 && <div class="dragedDetailsRow">
				<div class="dragedImage">
					<video poster={(other[0].FILE_FORMAT === '.zip' || other[0].FILE_FORMAT === 'zip') ? zipimage : other[0].thumbUrl}>
						<source src={( other[0].FILE_FORMAT === '.zip' || other[0].FILE_FORMAT === 'zip') ? zipimage : other[0].thumbUrl} type="video/mp4" />
						<FormattedMessage id="KC1518"/>
												</video>
				</div>
				<div class="dragedText">
					<h5>{other[0].FILE_NAME}</h5>
					<h6>{other[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" class="removeImageVideo" onClick={() => this.removeFileHandler('other')}></a>
				</div>
			</div>}
		</div>
	}
	updateSize() {
		const { size } = this.props.mediaDraggedItemInfo;
		let widthPercent = '0%';
		if (size <= 80) {
			widthPercent = (size * 1.25) + '%';
		} else if (size > 80) {
			widthPercent = '100%';
		}
		
		return <div>
			<div class="maxFileSize">
				<div class={(size > 80) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="80">
					<FormattedMessage id="KC1734" values={{ size: this.roundingTheSize() }}/></div>
					<FormattedMessage id="KC1525"/></div>
		</div>
	}
	roundingTheSize() {
		const { size } = this.props.mediaDraggedItemInfo
		if (size >= 0)
			return Math.floor(size * 100) / 100;
		else
			return 0;
	}

	renderButton(filetype, mediaID, fileName) {
		const { formatMessage } = this.props.intl;
		if (filetype === '.zip' || filetype === 'zip') {
			return (
				<a href="javascript:void(0)" className="mediaPreview disabled" title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>
			);
			
	   
		} else {
			return (
				<a href="javascript:void(0)" data-toggle="modal" data-target="#mediaPreviewModal"
					onClick={() => this.previewMediafile(mediaID, fileName)}
					className="mediaPreview " title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>
			);
		}
	}

	getDownloadURL = async () => {
		const mediaPreparationList = [...this.state.userCheckedData];
		mediaPreparationList && mediaPreparationList.map((item, i) =>
			this.downloadFiles(item.downloadMediaSignedUrl),
		)		
	}

	downloadFiles = (uri) => {
		const { formatMessage } = this.props.intl;
		const  link = document.createElement("a");
		link.href = uri;
		// link.setAttribute('target', '_blank');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: formatMessage({ id: 'KC1519' })
				},
			});					
    }

	// Woopra Events
    trackEvent = (e) => {
        WoopraEvents(`${Constants.MEDIA}-${e.trackTitle}`);
    }

	render() {
		const { formatMessage } = this.props.intl;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const { userSelectAll, userCheckedData, isDelete, isEdit, previewfilename, previewurl, previewurl11, sortColumn, isAdd, loaderIconVisible, previewfileformat, isAddToPreparation, preparationSelectionClass } = this.state;
		const { prepartions, other, size } = this.props.mediaDraggedItemInfo;
		let { mediaPreparationListInfo = {}, mediaDraggedItemInfo } = this.props;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		let { success_modal, error_modal, selectFileText } = this.state;

		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102", isNbSp: true },
			{ path: "FILE_NAME", labelStringID: "KC0677" },			
			{ path: "CREATEDAT", labelStringID: "KC0725" },
			{ path: "RESOLUTION", labelStringID: "KC0726", isNbSp: true },
			{ path: "FILE_SIZE", labelStringID: "KC0682", isNbSp: true },
			{ path: "IN_USE_COUNT", labelStringID: "KC0728", isNbSp: true },
			{ path: "", labelStringID: "KC0729", isNbSp: true },
			// { path: "", labelStringID: "KC2317" }, 
			{ path: "", labelStringID: "KC0730", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let listCount;
		let allowDownload=false;
		let selectedCheckboxCount=0;

		if(!mediaPreparationListInfo || !mediaPreparationListInfo.mediaList){
			mediaPreparationListInfo = {};
			mediaPreparationListInfo.mediaList = [];
		}

		if ( mediaPreparationListInfo ) {
			// User Story 72565: [Quickwin]: Sorting on Date Fields in CREM Library
			if (sortColumn.path === "CREATEDAT") {
				if (sortColumn.order === "desc") {
					mediaPreparationListInfo.mediaList = mediaPreparationListInfo.mediaList.sort((d1, d2) => new Date(d2.CREATEDAT) - new Date(d1.CREATEDAT));
				}
				else {
					mediaPreparationListInfo.mediaList = mediaPreparationListInfo.mediaList.sort((d1, d2) => new Date(d1.CREATEDAT) - new Date(d2.CREATEDAT));
				}
			}
			else {
				mediaPreparationListInfo.mediaList = _.orderBy(mediaPreparationListInfo.mediaList, [sortColumn.path], [sortColumn.order]);
			}
		mediaPreparationListInfo.mediaList.forEach(item => {
			const findIndex = userCheckedData.findIndex((checkedData) => checkedData.MEDIA_ID === item.MEDIA_ID && item.isAuthorized)
			if (findIndex > -1) {
				item.checked = true;
			}
			else {
				item.checked = false;
			}
			item.order_id = orderId++
		});

		listCount = mediaPreparationListInfo.mediaList.length;
		mediaPreparationListInfo.mediaList.forEach(item => {
			if (item.checked){
				selectedCheckboxCount = selectedCheckboxCount+1;
			}
		});


	}

		
		
		if (selectedCheckboxCount>1){
			allowDownload = false;
		} else if  (selectedCheckboxCount===1){
			allowDownload = true;
		}

		if (previewfileformat === "image") {
			$("#imgdiv").removeClass("displaynone");
			$("#videodiv").addClass("displaynone");
		}
		else if (previewfileformat == "video") {
			$("#videodiv").removeClass("displaynone");
			$("#imgdiv").addClass("displaynone");
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="mediaWrapper">
						<div>
							<div class="mediaHeader">
								<ul>
									<li>
										<Link to="/mediapreparation" title={formatMessage({ id: 'KC0719' })} onClick={(e)=> {e.trackTitle="Preparation";this.trackEvent(e)}}><FormattedMessage id="KC0719"/></Link> 
									</li>
									<li>
										<Link to="/mediascreensaver" title={formatMessage({ id: 'KC0209' })} onClick={(e)=> {e.trackTitle="Screensaver";this.trackEvent(e)}}><FormattedMessage id="KC0209"/></Link>
									</li>
									<li>
										<Link to="/mediacompilation" title={formatMessage({ id: 'KC0736' })} onClick={(e)=> {e.trackTitle="Compilation";this.trackEvent(e)}}><FormattedMessage id="KC0736"/></Link>
									</li>
									<li>
										<Link to="/mediapackage" title={formatMessage({ id: 'KC0737' })} className="active" onClick={(e)=> {e.trackTitle="Package";this.trackEvent(e)}}><FormattedMessage id="KC0737"/></Link>
									</li>
									<li>
									{/* <button onClick={() => this.onAssignSmartGroupsList()} title={formatMessage({ id: 'KC0722' })}  className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn sgStatus" : "btn sgStatus disabled"}><span className="btnIcon downloadLogbook" /></button> */}
										<button  title={formatMessage({ id: 'KC0710' })} className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn assignMedia" : "btn assignMedia disabled"} onClick={(e) => this.onTransferMedia(e)} >&nbsp;</button>
										<button data-toggle="modal" data-target="#confirmationDownloadModal" type="button" title={formatMessage({ id: 'KC0723' })}  className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn btn-default-text download" : "btn btn-default-text download disabled"}><span className="btnIcon downloadLogbook"></span></button>
										<button type="button" className="btn rowEdit disabled">&nbsp;</button>
										<button type="button" className={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn rowDelete" :"btn rowDelete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" title={formatMessage({ id: 'KC0721' })} >&nbsp;</button>
									</li>
								</ul>
						 
							</div>
							<div className="mediaPreparationTable mediatHead">
							<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
							</div>
                          </div>
							<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
								<div className="modal-dialog modal-md">
									<div className="modal-content" >

										<div className="modal-body">
											<div className="deleteConfirmation">
												<FormattedMessage id="KC0753"/>
                            				</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary deleteNoBtn" onClick={() => this.cancelDeleteUser()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary deleteYesBtn" onClick={() => this.deleteSelectedMedia()} data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/></button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal fade mediaPreviewModal" data-keyboard="true" data-backdrop="static" id="mediaPreviewModal" tabIndex="-1" role="dialog" aria-labelledby="mediaPreviewModalLabel">
								<div className="modal-dialog modal-lg modal-xs">
									<div className="modal-content">
										<div className="modal-header modal-header-danger">
											{previewfilename} <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closePlayer}>&nbsp;</button>
										</div>
										<div className="modal-body">

											<div id="imgdiv" className="imageandVideoPreview displaynone">
												<img src={previewurl}></img>
											</div>

											<div id="videodiv" className="imageandVideoPreview displaynone">
												<ReactPlayer url={previewurl} playing controls />
											</div>
										</div>
									</div>
								</div>
							</div>

							<form className="mediaPreparationForm mediaTableListForm">
								<div className="mediaPreparationTable">								 
									{mediaPreparationListInfo && mediaPreparationListInfo.mediaList && mediaPreparationListInfo.mediaList.map((item, i) =>					
										<ul className="tBody">
											<li>{i + 1}</li>
											<li draggable="true" onDragStart={(e) => this.dragStart(e, item)}>
												<span className="mediaFileSpan"> 
												<img src={(item.FILE_FORMAT === 'zip' || item.FILE_FORMAT === '.zip') ? zipimage : item.thumbUrl} />
												</span>
											</li>
											<li>{item.FILE_NAME}
											{/* .split(".").length>1?item.FILE_NAME.split(".")[0] + item.FILE_FORMAT.toLowerCase():item.FILE_NAME} */}
												<div className="mediaSListSM">{item.CREATEDAT}</div>
												<div className="mediaSListRS">{item.RESOLUTION}, {item.FILE_SIZE} <FormattedMessage id="KC1498"/></div>
												<div className="mediaSListAS">
													{item.assignedCustomerGrops.map((subitem, j) =>
														<div className="assignedTo alert alert-dismissible fade show" role="alert">
															{subitem}
														</div>
													)}
												</div></li>												
											<li>{item.CREATEDAT}</li>
											<li>{item.RESOLUTION}</li>
											<li>{item.FILE_SIZE}</li>
											<li>{item.IN_USE_COUNT}</li>
											<li>{item.assignedCustomerGrops.map((subitem, j) =>
												<div className="assignedTo alert alert-dismissible fade show" role="alert">
													{subitem}
												</div>
											)}
											</li> 
											<li>{this.renderButton(item.FILE_FORMAT, item.MEDIA_ID, item.FILE_NAME)}</li>
											<li>
											{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabled">
												<div className={"customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														disabled
														onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
													<label for={i + 1}></label>
												</div>
												</Tooltip> : <div className={userProfileAuthFeature.is_editable == 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
													<label for={i + 1}></label>
												</div>}
											</li>
										</ul>)}
								</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={(mediaPreparationListInfo && mediaPreparationListInfo.mediaList !== undefined) ? mediaPreparationListInfo.mediaList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>	

							{size > 80 && <div className="mediaFileSizeTooLarge"><FormattedMessage id="KC1522"/></div>}

							<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDownloadModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDownloadModalLabel">
								<div className="modal-dialog modal-md">
									<div className="modal-content">
										<div className="modal-body">
											<div className="deleteConfirmation">
												<FormattedMessage id="KC1580"/>
											</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
											<button onClick={(e) => { this.getDownloadURL() }} className="btn btn-secondary deleteYesBtn" id="downloadYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" activeClass="mediaNav" />
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		mediaPreparationListInfo: state.mediaPreparationListInformation.mediaPreparationListInformation,
		mediaDraggedItemInfo: state.mediaDraggedItem.mediaDraggedItem,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaPackageList, mediaSelectedItems, mediaDraggedItem,
		mediaDragDeleteItem, resetSelectedItems,searchVisible, searchText
		
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaPackage));