import React, { Component } from 'react';
import http from '../../service/httpService';
import { BaseService } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';
import countryCodeArray from './countryCodeArray';
import countryNameArray from './countryNameArray';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constants from '../../woopraConfig/Woopraconstant';
class RegisterAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            customerName: '',
            phoneNumber: '',
            isVerified: false,
            countryCode: '+1',
            termsAndCondition: false,
            errors: {
                emailid: '',
                name: '',
                customerName: '',
                phoneNumber: '',
                recaptcha: '',
                termsAndCondition:''
            },
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            loaderIconVisible: false,
            userData: {},
            isExists: false,
            countyNameData: [],
            selectedCountryName: 'United States'
        };
        this.handleChange = this.handleChange.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this._reCaptchaRef = React.createRef();
        this.verifyCallback = this.verifyCallback.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    }

    selectCountryCode = (e) => {
        if (e.target.value) {
            this.setState({ countryCode: e.target.value });
        }
    }

    componentWillMount = () => {
        document.body.style.backgroundColor = "#0d3968";
        $("body").addClass("loginBg");
        this.updateLoginDimensions();
        const { email='', isExists=false } = this.props.history.location.state;
        this.setState({ email: email ? email.toLowerCase() : "", isExists, countryCodeList: countryCodeArray, countyNameData: countryNameArray })
    }

    updateLoginDimensions() {
        var windowHeight = $(window).height();
        var welcomTextH = $(".welcomText").height();
        var bgAdminH = $(".bgAdmin").height() - 25;
        //Sign In & Sign Up Modal
        var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH));
        var loginScrollbarHeightS = ((windowHeight) - (welcomTextHbgAdminH));
        $(".LoginBtnRegisterWrapper").css("max-height", loginScrollbarHeightS);
    }

    handleChange(key, event) {
        if (key === 'email') {
            this.setState({ [key]: event.target.value.toLowerCase() });
        }
        else {
            this.setState({ [key]: event.target.value });
        }
        event.preventDefault();
    }

    validateForm() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["emailid"] = "Please enter your email-ID.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["emailid"] = "Please enter valid email-ID.";
            }
        }

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter your Name";
        }

        if (typeof fields["name"] !== "undefined") { 
            
            if (fields["name"].length < 4 || fields["name"].length >= 100) {
                formIsValid = false;
                errors["name"] = "Please enter min characters of 4 and upto maximum of 100";
            }
        }

        if (!fields["customerName"]) {
            formIsValid = false;
            errors["customerName"] = "Please enter your Customer Name";
        }

        if (typeof fields["customerName"] !== "undefined") {
            if (fields["customerName"].length < 4 || fields["customerName"].length >= 100) {
                formIsValid = false;
                errors["customerName"] = "Please enter min characters of 4 and upto maximum of 100";
            }
        }

        if(!fields['termsAndCondition']){
            formIsValid = false;
            errors["termsAndCondition"] = "Please accept terms and condition.";
        }

        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = "Please enter valid cell phone number.";
        }

        if (typeof fields["phoneNumber"] !== "undefined") {
            if (!fields["phoneNumber"].match(/^[0-9\b]{7,15}$/)) {
                formIsValid = false;
                errors["phoneNumber"] = "Not a valid phone number";
            }
        }

        if (!this.state.isVerified && this.state.isVerified !== undefined) {
            formIsValid = false;
            errors["recaptcha"] = "Please select reCAPTCHA.";
        }
        

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    createAccount(e) {
        if (this.validateForm()) {
            this.setState({ loaderIconVisible: true });
            const { customerName, email, name, selectedCountryName, countryCode, phoneNumber } = this.state;

            let postData = {
                'customername': customerName,
                'email': email,
                'startdate' : '2021-01-01',
                'enddate' : '2050-12-31',
                'name': name,
                "brandIds": [1,3],
                "base_country": selectedCountryName === "United States" ? "USA" : "UK",
                "access_countries": selectedCountryName === "United States" ? "USA" : "UK",
                'country_code': countryCode,
                'phoneNumber': phoneNumber,
                'action_type': 'publicAccount'
            }

            http({
                method: "post",
                url: BaseService.create_Account,
                data: postData,
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                if(response.data.responseCode === 200) { 	 
                    this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: 'You successfully created your Account! Default password will be sent to Email. You will be asked to change password during your first log in.'
                        },
                    });
                    WoopraEvents(`${constants.NEW_ACCOUNT_CREATED}`, postData);
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        isExists: true
                    });
                }
            }).catch(err => {
                this.setState({ loaderIconVisible: false });
                if (err.response.data.KC_ERR_400 !== undefined && err.response.status === 400) {
                    this.setState({
                        isExists: true
                    });
                }
                else if (err.response.data.body.responseCode !== null && err.response.data.body.responseCode === 400) {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: err.response.data.body.message
                            },
                            navigateRoute:'Navigate'
                    });
                }
            });
        }
    }

    closeModel = (err) => {
        this.setState({
            loaderIconVisible: false,
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        this.props.history.push(`/login`);
    }

    componentDidMount() {
        $("body").removeClass("modal-open");
        setTimeout(() => {
            var width = $('.g-recaptcha').parent().width();
            var scale = width / 304;
            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
            $('.g-recaptcha').css('display', 'block');
        }, 100);
    }

    verifyCallback = (recaptchaToken) => {
        if (recaptchaToken) {
            this.setState({
                isVerified: true,
                errors: {
                    ...this.state.errors,
                    recaptcha: ''
                }
            });
        }
    }

    goToLogin = (e) => {
        this.props.history.push(`/login`);
    }

    countryChangeEvent= (e) => {
		this.setState( {selectedCountryName:e.target.value });		
	}

    render() {
        const { error_modal, success_modal, loaderIconVisible, countryCodeList, countyNameData, isExists } = this.state;
        return (
            <div className="loginBg">
                <div id="adminWrapper">
                    {/* <!-- Loading Progress --> */}
                    <div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    {/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
                    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                        <Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
                    </nav>
                    {/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
                    <div id="page-wrapper">
                        <div className="welcomText">Welcome</div>
                        {/* <!-- Login Form --> */}
                        <div class={isExists ? "LoginBtnWrapper" : "LoginBtnRegisterWrapper"}>
                            <div id="loginBtnFormID">
                                <div class="loginButton">
                                    <ul>
                                        <li>&nbsp;</li>
                                        <li>
                                            <div class="loginIWrapper">
                                                <div class="existingLoginForm">
                                                    <ul class={isExists ? "displaynone" : ""}>
                                                        <li class="form-group">
                                                        <p>
                                                            Registration for individual users.
                                                        </p>
                                                        <p>
                                                            If your corporation already has access KitchenConnect please ask your adminstrator to provide an Account.
                                                        </p>
                                                        </li>
                                                        <li class="form-group">
                                                            <div class="input-group">
                                                                <input className="form-control" placeholder="Customer Name" type="text" name="customerName" value={this.state.customerName} onChange={(e) => this.handleChange('customerName', e)} />
                                                                <div className="errorMsg">{this.state.errors.customerName}</div>
                                                            </div>
                                                        </li>
                                                        <li class="form-group marginBottom10px">
                                                            <div class="input-group">
                                                                <input className="form-control" placeholder="Enter your email" type="text" name="emailid" value={this.state.email} onChange={(e) => this.handleChange('email', e)} onBlur={(e) => this.handleChange('email', e)} />
                                                                <div className="errorMsg">{this.state.errors.emailid}</div>
                                                            </div>
                                                        </li>
                                                        <li class="form-group">
                                                            <div class="input-group">
                                                                <input className="form-control" placeholder="Name" type="text" name="name" value={this.state.name} onChange={(e) => this.handleChange('name', e)} />
                                                                <div className="errorMsg">{this.state.errors.name}</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-group">
                                                                <select id="countrycodeselect" className="form-control countryCode" onChange={(e) => this.selectCountryCode(e)}>
                                                                    {
                                                                        countryCodeList && countryCodeList.length && countryCodeList.map((item, i) =>
                                                                            <option key={i} value={item.code} selected={item.code === this.state.countryCode}>{item.code}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                                <input type="number" name="phoneNumber" className="form-control phoneNumber" onChange={(e) => this.handleChange('phoneNumber', e)} value={this.state.phoneNumber} placeholder="1234567890" />
                                                            </div><div className="errorMsg">{this.state.errors.phoneNumber}</div>
                                                        </li>
                                                        <li>
                                                            <div class="input-group">
                                                                <select ref="selectEl" id="countrynamed" class={countyNameData.length > 1 ? "form-control" : "form-control disabled"} value={this.state.selectedCountryName}   onChange={(e) => this.countryChangeEvent(e)} >																
                                                                    {countyNameData.map((item, i) =>																	
                                                                        <option ref="countryname"  key={i} defaultValue ={this.state.selectedCountryName} >{item}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="customCheckbox termsConditionsCkB customCheckboxWlabel" style={{ float: 'left' }}>
                                                                <input id="termsAndCondition" name="termsAndCondition" type="checkbox" value={this.state.termsAndCondition} defaultChecked={this.state.termsAndCondition}
                                                                    onChange={(e) => this.setState({ 'termsAndCondition': e.target.checked })} />
                                                                <label htmlFor="termsAndCondition">I Accept <a href="https://www.welbiltdigital.com/" target="_blank">Terms & Conditions</a></label>
                                                                <div className="errorMsg">{this.state.errors.termsAndCondition}</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="g-recaptcha">
                                                                <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
                                                            </div>
                                                            <div className="errorMsg">{this.state.errors.recaptcha}</div>
                                                        </li>
                                                        <li class="form-group pass">
                                                            <button class="btn btn-secondary cancelBtn" onClick={() => this.props.history.push('login')} type="button">Cancel</button>
                                                            <button class="btn btn-secondary submitBtn" onClick={this.createAccount} type="button">Create Account</button>
                                                        </li>
                                                    </ul>
                                                    <ul class={isExists ? "" : "displaynone"}>
                                                        <li class="form-group">
                                                            <p>
                                                                This Email address is already registrated into KitchenConnect.
                                                            </p>
                                                        </li>
                                                        <li className="form-group pass">
                                                            <button className="btn btn-secondary singINBtn" type="button" id="SignInButton" onClick = {(e) => this.goToLogin(e)}>Go To Login</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>&nbsp;</li>
                                    </ul>
                                    <ul>
                                        <li>&nbsp;</li>
                                        <li>
                                            <div class="loginIWrapper">
                                                &nbsp;
							                </div>
                                        </li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- //Login Form -->  */}
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div>
        )
    }
};

export default RegisterAccount;
