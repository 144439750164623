import { combineReducers } from 'redux';
import averageDrinksReducer from './reducers/reportManagement/averageDrinksReducer';
import totalDrinksReducer from './reducers/reportManagement/totalDrinksReducer';
import peakHourChartReducer from './reducers/reportManagement/peakHourChartReducer';
import locationInfoReducer from './reducers/unitManagement/locationInfoReducer';
import mediaLibraryReducer from './reducers/menuManagement/mediaLibraryReducer';
import modelInfoReducer from './reducers/unitManagement/modelInfoReducer';
import UnitDashboardReducer from './reducers/unitDashbord/UnitDashboardReducer';
import errorCountReducers from './reducers/serviceManagement/errorCountReducers';
import heatMapTableReducer from './reducers/reportManagement/heatMapTableReducer';
import topTenTableReducer from './reducers/reportManagement/topTenTableReducer';
import dashboardUnitReducer from './reducers/dashboardUnits/dashboardUnitReducer';
import dashboardLocationReducer from './reducers/dashboardLocations/dashboardLocationReducer';
import countryReducer from './reducers/listView/locations/countryReducer';
import regionReducer from './reducers/listView/locations/regionReducer';
import filterbyReducer from './reducers/listView/locations/filterbyReducer';
import locationReducer from './reducers/listView/locations/locationReducer';
import withinlocationReducer from './reducers/listView/locations/withinlocationReducer';
import customerReducer from './reducers/listView/locations/customerReducer';
import brandReducer from './reducers/listView/locations/brandReducer';
import modelReducer from './reducers/listView/locations/modelReducer';
import machineTypeReducer from './reducers/listView/locations/machineTypeReducer';
import purposesReducer from './reducers/listView/locations/purposesReducer';
import smartgroupReducer from './reducers/listView/locations/smartgroupReducer';
import entityReducer from './reducers/organizationentity/entityReducer';
import userReducer from './reducers/stateData/userReducer';
import filterListReducer from './reducers/listView/locations/filterListReducer';
import OrganizationCategoryDetailsReducer from './reducers/navigation/organizationManagement/organizationCategoryDetailsReducer';
import OrganizationLocationTypesReducer from './reducers/navigation/organizationManagement/organizationLocationTypesReducer';
import OrganizationSubElementCountReducer from './reducers/navigation/organizationManagement/organizationSubElementCountReducer';
import OrganizationUnitsDetailsReducer from './reducers/navigation/organizationManagement/organizationUnitsDetailsReducer';
import deleteElementReducer from './reducers/navigation/organizationManagement/deleteOrganizationElementReducer';
import mediaLibraryListReducer from './reducers/navigation/mediaManagement/mediaLibraryListReducer';
import softwareAvailableUpdatesReducer from './reducers/navigation/softwareManagement/softwareAvailableUpdatesReducer';
import softwareUpdateStatusReducer from './reducers/navigation/softwareManagement/softwareUpdateStatusReducer';
import storeInstalledItemReducer from './reducers/navigation/softwareManagement/storeInstalledItemReducer';
import mediaPreparationListReducer from './reducers/navigation/mediaManagement/mediaPreparationListReducer';
import mediaUploadHistoryReducer from './reducers/navigation/mediaManagement/mediaUploadHistoryReducer';
import mediaScreensaverListReducer from './reducers/navigation/mediaManagement/mediaScreensaverListReducer';
import mediaCompilationListReducer from './reducers/navigation/mediaManagement/mediaCompilationListReducer';
import mediaImageLibraryListReducer from './reducers/navigation/mediaManagement/mediaImageLibraryListReducer';
import mediaAudioLibraryListReducer from './reducers/navigation/mediaManagement/mediaAudioLibraryListReducer';
import mediaDraggedItemReducer from './reducers/navigation/mediaManagement/mediaDragReducer';
import timerDoubleTapsReducer from './reducers/unitDashbord/timerDoubleTapsReducer';
import cycleRatesOnTimeReducer from './reducers/unitDashbord/cycleRatesOnTimeReducer';
import cycleRatesOffTimeReducer from './reducers/reportManagement/utilization/cycleRatesOffTimeReducer';
import cycleRatesTotalPeriodTimeReducer from './reducers/reportManagement/utilization/cycleRatesTotalPeriodTimeReducer';
import cycleRatesOnTimePercentageReducer from './reducers/reportManagement/utilization/cycleRatesOnTimePercentageReducer';
import averageHoldingCyclesReducer from './reducers/reportManagement/averageHoldingCyclesReducer';
import numberOfHoldingCyclesReducer from './reducers/reportManagement/numberOfHoldingCyclesReducer';
import ResetCycleResponse from './reducers/unitDashbord/resetCycleReducer';
import panVsTimeResponse from './reducers/reportManagement/panVsTimeReducer';
import unitDashboardSingleDataReducer from './reducers/unitDashbord/unitDashboardSingleDataReducer';
import unitDashboardDynamicDataReducer from './reducers/unitDashbord/unitDashboardDynamicDataReducer';
import totalCookCountReducer from './reducers/unitDashbord/totalCookCountReducer';
import averageCookCountReducer from './reducers/unitDashbord/averageCookCountReducer';
import filterCleaningsReducer from './reducers/unitDashbord/filterCleaningsReducer';
import filterComplianceReducer from './reducers/unitDashbord/filterComplianceReducer';
import doorOpeningsReducer from './reducers/unitDashbord/doorOpeningsReducer';
import overAllPanUtilizationReducer from './reducers/unitDashbord/overAllPanUtiizationReducer';
import holdingTableReducer from './reducers/reportManagement/holdingTableReducer';
import utilizationTableReducer from './reducers/reportManagement/utilization/utilizationTableReducer';
import averageTraysReducer from './reducers/unitDashbord/averageTraysReducer';
import totalTrayCountReducer from './reducers/unitDashbord/totalTrayCountReducer';
import cleaningTableReducer from './reducers/reportManagement/cleaningTableReducer';
import logOutReducer from './reducers/logout/logoutReducer';
import smartGroupUnitDetails from './reducers/smart-groups/smartUnitReducer';
import smartGroupUnitHistory from './reducers/smart-groups/UpdateHistoryUnitReducer';
import smartGroupUnitDelete from './reducers/smart-groups/smartUnitDeleteReducer';
import deleteReport from './reducers/Reports/reportDeleteReducer';
import addMenuLibrary from './reducers/menuManagement/addNewMenuLibrary';
import menuListLibrary from './reducers/menuManagement/menuList';
import packageList from './reducers/mediaManagement/packageList';
import advanceSearch from './reducers/advanceSearch/advanceSearchReducer';
import userManagementList from './reducers/userManagement/userManagementReducer';
import userManagementRoleList from './reducers/userManagement/userManagementRoleReducer';
import localeInfo from './reducers/localeReducer/localeReducer';
import brandList from './reducers/connectivityTest/getBrandListReducer';
import connectivityState from './reducers/connectivityTest/getDeviceConnectivityStatusReducer';
import bakingPlan from './reducers/softwareManagement/bakingPlanReducer';
import smartTag from './reducers/smartTag/smartTagReducer'

const appReducer = combineReducers({
    totalDrinksInformation: totalDrinksReducer,
    averageDrinksInformation: averageDrinksReducer,
    peakHourChartInformation:peakHourChartReducer,
    heatMapTableInformation:heatMapTableReducer,
    locationInformation: locationInfoReducer,
    mediaLibraryInformation: mediaLibraryReducer,
    deviceInformation: modelInfoReducer,
    mediaSpaceInformation: mediaLibraryReducer,
    unitdashboardInformation:UnitDashboardReducer,
    ErrorsCountInformation:errorCountReducers,
    errorList:errorCountReducers,
    topTenTableInformation:topTenTableReducer,
    dashboardUnitsValue:dashboardUnitReducer,
    dashboardLocationValue:dashboardLocationReducer,
	countryInformation: countryReducer,
    regionInformation:regionReducer,
    filterInformation: filterbyReducer,
    locationInformation: locationReducer,
    withinlocationInformation:withinlocationReducer,
    customerInformation: customerReducer,
	brandInformation: brandReducer,
    modelInformation: modelReducer,
    machineTypeInformation: machineTypeReducer,
    purposesInformation: purposesReducer,
    smartgroupInformation: smartgroupReducer,
    filterModel:filterListReducer,
    entityReducer: entityReducer,
    OrganizationCategoryDetailsInformation: OrganizationCategoryDetailsReducer,
    OrganizationLocationTypesInformation: OrganizationLocationTypesReducer,
    OrganizationSubElementCountInformation: OrganizationSubElementCountReducer,
    deleteElementInformation:deleteElementReducer,
    userReducer:userReducer,
    OrganizationUnitsDetailsInformation: OrganizationUnitsDetailsReducer,
    mediaLibraryListInformation: mediaLibraryListReducer,
    softwareAvailableUpdatesInformation:softwareAvailableUpdatesReducer,
    softwareUpdateStatusInformation:softwareUpdateStatusReducer,
    storeInstalledItemInformation:storeInstalledItemReducer,
    mediaUploadHistoryResponse:mediaUploadHistoryReducer,
    mediaPreparationListInformation:mediaPreparationListReducer,
    mediaScreensaverListInformation:mediaScreensaverListReducer,
    mediaCompilationListInformation:mediaCompilationListReducer,
    mediaImageLibraryListInformation:mediaImageLibraryListReducer,
    mediaAudioLibraryListInformation:mediaAudioLibraryListReducer,
    mediaDraggedItem:mediaDraggedItemReducer,
    timerDoubleTapsResponse:timerDoubleTapsReducer,
    cycleRatesOnTimeResponse:cycleRatesOnTimeReducer,
    cycleRatesOffTimeResponse:cycleRatesOffTimeReducer,
    cycleRatesTotalPeriodTimeResponse:cycleRatesTotalPeriodTimeReducer,
    cycleRatesOnTimePercentageResponse:cycleRatesOnTimePercentageReducer,
    averageHoldingCyclesResponse:averageHoldingCyclesReducer,
    noOfHoldingCyclesResponse:numberOfHoldingCyclesReducer,
    ResetCycleResponse:ResetCycleResponse,
    panVsTimeResponse:panVsTimeResponse,
    singleData:unitDashboardSingleDataReducer,
    dynamicData:unitDashboardDynamicDataReducer,
    totalCookCountData:totalCookCountReducer,
    averageCookCountData:averageCookCountReducer,
    filterCleaningsData:filterCleaningsReducer,
    filterComplianceData:filterComplianceReducer,
    doorOpeningsData:doorOpeningsReducer,
    overAllPanUtilizationResponse:overAllPanUtilizationReducer,
    holdingTableResponse: holdingTableReducer,
    utilizationTableResponse: utilizationTableReducer,
    errormsgUtilizationTable: utilizationTableReducer,
    filterType: heatMapTableReducer,
    avgTraysResponse: averageTraysReducer,
    totalTrayCountResponse: totalTrayCountReducer,
    cleaningTableInformation: cleaningTableReducer,
    logOutReducer,
    smartGroups: smartGroupUnitDetails,
    smartGroupsHistory: smartGroupUnitHistory,
    smartGroupUnitDelete,
    deleteReport,
    addMenuLibrary,
    menuListLibrary,
    packageList,
    advanceSearch,
    userManagementList,
    userManagementRoleList,
    localeInfo: localeInfo,
    brandList: brandList,
    connectivityState:connectivityState,
    bakingPlan: bakingPlan,
    smartTag:smartTag
});

/**
 * Root reducer used to handle applications states data before/after login and logout applications. 
 * In order to reset all reducers back to their initial states when user logout,
 * rewrite rootReducer to assign '{}' to state when logout
 */
const rootReducer = (state, action) =>{
    if(action.type === 'LOG_OUT'){
      state = {};
    }
    return appReducer(state, action);
}

export default rootReducer;