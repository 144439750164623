import http from '../../../service/httpService';
import React, { Component } from 'react';
import { unitAPI } from '../../../service/api';
import { userRoleURI } from '../../../service/api';
import authService from '../../../service/authService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import { FeaturesList } from '../../controls/featureList';
import { BrandList } from '../../controls/brandList';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { BRAND_ID } from '../../../utils/appConstants';
import authConstants  from '../../../utils/authConstants';
import _ from 'lodash';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';

const { allFeaturesKey }  = authConstants;
const FeaturesCategory = {
	GF: { title: "Global Functions", stringID: "KC0622" },
	FP: { title: "Unit Data", stringID: "KC0625" },
	MMA: { title: "Main Menu Apps", stringID: "KC0587" }
}

class ChooseUserRole extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userData: {},
			roleId: 0,
			customerId: 0,
			isRoleFeatureUpdated: false,
			corborateRolesDropDownSource: [],
			allDefaultBrandsList: [],
			roleFeaturesList: [],
			allFeaturesData: [],
			loaderIconVisible: false,
			permissions: {
				global_functions: [],
				five_pillars: [],
				main_menu_apps: []
			},
			FeaturesCategoryList: [FeaturesCategory.GF, FeaturesCategory.FP, FeaturesCategory.MMA],
			viewBrandsList: []
		}
		this.selectBrand = this.selectBrand.bind(this);
		this.setUserToState = this.setUserToState.bind(this);
		this.setUserToRole = this.setUserToRole.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		let { userModel } = this.props;
		if (userModel.roleId) {
			this.setRolesAndBrand(userModel.roleId);
		}
		else {
			this.setDefaultBrandsAndFeatures()
		}
	}

	UserRole = (e) => {
		this.props.history.push('/assignUserToTopology');
	}

	selectBrand = (e) => {
		if (e.target.value) {
			this.state.roleId = e.target.value;
			let { userModel } = this.props;
			userModel.roleId = this.state.roleId;
			this.setRolesAndBrand(e.target.value);
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		} else {
			this.setDefaultBrandsAndFeatures()
		}
	}

	setRolesAndBrand = (roleId) => {

		this.loadingPopupShowHide(true);
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loginFeatureInfo = JSON.parse(localStorage.getItem(allFeaturesKey));
		let loggedinUser = loginUser.username;
		let loggedinUserRoleId = loginFeatureInfo[0].role_id;
		let rolesBrandsFeatures = this.state.allDefaultBrandsList.length > 0 ? [
		this.getData(userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + localStorage.getItem("customerId")),
		this.getData(userRoleURI.getRolesBrand, {roleId}), 
		this.getData(userRoleURI.getRolesByCustomer + localStorage.getItem("customerId")+"&user_email="+loggedinUser+"&loginUserRoleid="+loggedinUserRoleId+"&brandIds="+BRAND_ID), 
		this.getData(userRoleURI.getAllFeatures, {BRAND_ID})] : [this.getData(userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + localStorage.getItem("customerId")),
		this.getData(userRoleURI.getRolesBrand, {roleId}), 
		this.getData(userRoleURI.getRolesByCustomer + localStorage.getItem("customerId")+"&user_email="+loggedinUser+"&loginUserRoleid="+loggedinUserRoleId+"&brandIds="+BRAND_ID), 
		this.getData(userRoleURI.getAllFeatures, {BRAND_ID}), 
		this.getData(unitAPI.getBrands)]

		Promise.all(rolesBrandsFeatures)
			.then(responses => {
				let featuresData = responses && responses.length >= 1 ? responses[0].data : [];
				let userBrands = responses && responses.length >= 2 ? responses[1].data.data : [];
				let featureList = featuresData && featuresData.data && featuresData.data.features.length > 0 ? featuresData.data.features.filter((c)=>c.feature_name!="Ready Recipe") : [];
				let rolesList = responses.length >= 3 ? responses[2].data.data : [...this.state.corborateRolesDropDownSource];
				const brands = responses.length >= 5 ? responses[4].data : [...this.state.allDefaultBrandsList];
				const allDefaultFeatures = responses.length >= 4 ? responses[3].data.data.filter((c)=>c.feature_name!="Ready Recipe") : [...this.state.allFeaturesData.filter((c)=>c.feature_name!="Ready Recipe")];

				let selctedRole = rolesList.filter(item => item.Id === parseInt(roleId));
				if (selctedRole && selctedRole.length > 0)
					selctedRole[0].isSelected = true;

				this.setState({
					roleId: roleId,
					allFeaturesData: allDefaultFeatures,
					viewBrandsList: this.getBrandsByUserRoleSelection(this.getLoginUserBrands(brands), userBrands),
					roleFeaturesList: featureList,
					allDefaultBrandsList: brands,
					corborateRolesDropDownSource: rolesList,
				});
				this.loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				this.loadingPopupShowHide(false);
			});
	}

	setDefaultBrandsAndFeatures = () => {
		this.loadingPopupShowHide(true);
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loginFeatureInfo = JSON.parse(localStorage.getItem(allFeaturesKey));
		let loggedinUser = loginUser.username;
		let loggedinUserRoleId = loginFeatureInfo[0].role_id;
		return Promise.all([this.getData(userRoleURI.getAllFeatures, {BRAND_ID}),
		this.getData(unitAPI.getBrands),
		this.getData(userRoleURI.getRolesByCustomer + localStorage.getItem("customerId")+"&user_email="+loggedinUser+"&loginUserRoleid="+loggedinUserRoleId+"&brandIds="+BRAND_ID)])
			.then(responses => {

				let featuresData = responses[0].data.data.filter((c=>c.feature_name!="Ready Recipe"));
				let brands = responses[1].data;
				let rolesList = responses && responses.length >= 2 ? responses[2].data.data : [];
				featuresData.forEach(f => {
					f.feature_active = false;
				});

				this.setState({
					roleId: 0,
					roleFeaturesList: [],
					allFeaturesData: featuresData,
					allDefaultBrandsList: brands,
					viewBrandsList: this.getLoginUserBrands(brands),
					corborateRolesDropDownSource: rolesList,
				});
				this.loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				this.loadingPopupShowHide(false);
			});
	}

	getData = (url, args={}) => {
		let headers = {};
		const {roleId, BRAND_ID} = args;
		headers["Content-Type"] = 'application/json';
		if (roleId) {
			headers["role_id"] = roleId;
		} else if (BRAND_ID) {
			headers["brandid"] = BRAND_ID;
		}
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: headers,
				data: {}
			}).then(response => resolve(this.responseSuccessParser(response))).catch(err => {
					this.loadingPopupShowHide(false);
				});
		});

	}

	responseSuccessParser = (response) => {
		return new Promise((resolve, reject) => {
			const { data } = response;
			if (data) {
				resolve(response);
			} else {
				let { data } = response;
				if (data) {
					let { body } = data;
					if (body != null)
						reject(body);
				}
			}
		});
	}

	responseErrorParser = (err) => {
		const { response } = err;
		let { data } = response;
		if (data) {
			let { body } = data;
			if (body != null)
				return body;
		}
	}

	setUserToState = (e) => {
		let { userModel } = this.props;
		userModel.roleId = this.state.roleId;
		this.props.setUserDataModel(userModel);
	}

	setUserToRole = (e) => {
		if (this.state.roleId === 0) {
			e.preventDefault();
		}
	}

	loadingPopupShowHide = (isActive) => {
		this.setState({
			loaderIconVisible: isActive
		});
	}

	/**
   * Get Default brands which was subscription by customer
   */
	getLoginUserBrands = (brands) => {

		let loginUserBrands = authService.getLoginUserBrands();
		let viewBrandsList = [], allBrandsList = [...brands];
		loginUserBrands.forEach(bId => {
			let selectedBrans = allBrandsList.filter(item => item.ID === bId);
			if (selectedBrans && selectedBrans.length > 0) {
				let brand = { ...selectedBrans[0] };
				brand.isChecked = false;
				viewBrandsList.push(brand);
			}
		});

		return viewBrandsList;
	}

	/**
	 * Get Brands which was chosen by selected role
	 */
	getBrandsByUserRoleSelection = (viewBrandsList, selectedBrands) => {

		for (let index = 0, len = selectedBrands.length; index < len; index++) {
			let selectedBrans = viewBrandsList.filter(item => item.ID === selectedBrands[index]);
			if (selectedBrans && selectedBrans.length > 0) {
				selectedBrans[0].isChecked = true;
			}
		}

		return viewBrandsList;
	}


	render() {
		let { corborateRolesDropDownSource, viewBrandsList, loaderIconVisible, roleFeaturesList, allFeaturesData, roleId,  FeaturesCategoryList } = this.state;

		corborateRolesDropDownSource = _.orderBy(corborateRolesDropDownSource, [corborateRolesDropDownSource => corborateRolesDropDownSource.role_name && corborateRolesDropDownSource.role_name.toString().toLowerCase()]);
		const { formatMessage } = this.props.intl;
		return (

			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div className="userManagementWrapper" >
						<div className="addUserHeader">
							<ul>
								<li>
									<Link to={{
										pathname: this.props.userModel.previousPage,
										data: this.props.location.data
									}} title={formatMessage({ id: 'KC0989' })} onClick={(e) => this.setUserToState(e)} className="backIcon">&nbsp;</Link>
								</li>
								<li>
								<FormattedMessage id={'KC0603'} />
								</li>
								<li className={roleId ? "activeState" : "disabled"}>
									<Link className={roleId ? "btn btn-default-text activeState" : ""} id="btnnext" to={{
										pathname: "/assignUserToTopology",
										data: this.props.userModel.roleId
									}} title={formatMessage({ id: "KC0543"})} onClick={(e) => this.setUserToRole(e)}><FormattedMessage id={'KC0543'} /></Link>

								</li>
							</ul>
						</div>

						<div className="chooseUserFormWrapper" style={{ background: "#fff" }}>

							<div className="chooseUserForm">
								<ul>
									<li>
										<div className="userNameDP">
											<span className="userDPImg"><img src={require("../../../images/icons/neutral.svg").default} alt="" /></span> {this.props.userModel.user.firstName + ' ' + this.props.userModel.user.lastName}
										</div>
									</li>
									<li className="marginBottom10px">
										<div className="input-group">
											<select className="form-control" onChange={(e) => this.selectBrand(e)}>
												<option value=""> {formatMessage({ id: "KC0621"})}</option>
												{corborateRolesDropDownSource.map((item, i) =>
													<option key={i} value={item.Id} selected={item.isSelected}>{item.role_name}</option>
												)}
											</select>
										</div>
									</li>

								</ul>
							</div>
							<BrandList
								brandList={viewBrandsList}
								isBrandEditable={false} />
							{
								FeaturesCategoryList.map((item, i) =>
									<FeaturesList key={i}
										isRoleFeatureUpdated={true}
										isRoleEditable={false}
										featureTitle={item.title}
										featureTitleStringID={item.stringID}
										roleFeaturesList={roleFeaturesList}
										allFeaturesData={allFeaturesData} />
								)
							}

						</div>
					</div>
				</div>
				<UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
			</div>
		)
	}
};


function mapStateToProps(state) {

	return {
		userModel: state.userReducer.UserModel
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setUserDataModel
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChooseUserRole));

