
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import auth from '../../service/authService';
import fConsts from '../../utils/featureConstants';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

const mappingStringID = {
    "Unit Dashboard":"KC0149",
    "Reports & Analytics":"KC0150",
    "Service Management": "KC0151",
    "Media":"KC0152",
    "Asset Management":"KC0253",
    "Quality Management":"KC0428"
}

class SideBar extends Component {

    // woopra events
    featureEvent = (value) => {
        let title =   value === "Reports & Analytics" ? "Reports and Analytics" : value;
        WoopraEvents(`${constant.SIDE_BAR_MENU}${title}`)
    }
    
  render() {
      let { activeClass } = this.props;
      const { formatMessage } = this.props.intl;
      
      let features = auth.getAllFeatures();
      features = features ? features : [];
      let storageBrandName = localStorage.getItem("brandName");
      let storageModelName = localStorage.getItem("modelName");
      if (storageBrandName !== "CREM")
      {
        features = features.filter(a => a.feature_name !== fConsts.MediaLibrary);
      }
      if (storageBrandName !== "FRYMASTER")
      {
        features = features.filter(a => a.feature_name !== fConsts.Quality);
      }
      /** To Hide the Media page under 5 Pillars as this is not applicable for CREM Expobar Models[EX3 DISPLAY & DIAMANT PRO DISPLAY] 02/18 */
      const modelList = ["EX3","DIAMANT PRO"];
      if((storageBrandName === "CREM") && (modelList.indexOf(storageModelName) > -1))
      { 
        features = features.filter(a => a.feature_name !== fConsts.MediaLibrary);
      }

    return (
    <div id="sidebar-wrapper">
        <ul className="sidebarNav">
            <li className="brandName">
                <Link to="/dashBoardLocations" className="homeNav" title={formatMessage({id: "KC0999"})}>&nbsp;</Link>
            </li>

            {features.map((item, i)=>{
					 return item.feature_category === "Unit Data" && item.featureAvailable && item.navigateUrl !== "" && <li key={i}>
						<span>
							<Link to={item.navigateUrl} className={item.className === activeClass ? item.className + " active" : item.className } onClick={()=>this.featureEvent(item.linkTitle)} title={ mappingStringID[item.linkTitle] ? formatMessage({id:mappingStringID[item.linkTitle] }) : item.linkTitle }>
                            {/* {item.linkTitle} */}
                            { mappingStringID[item.linkTitle] ? <FormattedMessage id={mappingStringID[item.linkTitle]}/> : item.linkTitle }
							 </Link> 
                        </span>  
					   </li>
                    })}
                    
            {/* <li>
                <Link to="/unitDashboard" className={ activeClass === "dashboardNav" ? "dashboardNav active" : "dashboardNav" } title="Unit Dashboard">&nbsp;</Link>
            </li>
            <li>
                <Link to="/reportManagement" className={ activeClass === "reportNav" ? "reportNav active" : "reportNav" } title="Report Management">&nbsp;</Link>
            </li>
            <li>
                <Link to="/serviceManagement" className={activeClass === "serviceNav" ? "serviceNav active" : "serviceNav"} title="Service Management">&nbsp;</Link>
            </li>
            <li>
                <Link to="/mediaLibrary" className={activeClass === "recipeNav" ? "recipeNav active" : "recipeNav"} title="Media Management">&nbsp;</Link>
            </li>
            <li>
                <Link to="/unitManagement" className={activeClass === "unitNav" ? "unitNav active" : "unitNav"} title="Asset Management">&nbsp;</Link>
            </li> */}
        </ul>
<ul class="sidebarNav bottomNav"> 
    {/* <li>
        <Link to="#" class="uploadNav">&nbsp;</Link>
    </li> */}
    <li>
        <Link to="/mainMenu" title={formatMessage({id: "KC1061"})} class="menuNav"><FormattedMessage id ="Main Menu"/></Link>
    </li> 
</ul>
</div>
    )
  }
}


export default injectIntl(SideBar);
