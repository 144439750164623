import jwtDecode from "jwt-decode";
import http from './httpService';
import authHelper from '../utils/authHelper';
import authConstants from '../utils/authConstants';
import { setBrandId }  from '../utils/appConstants';
import * as API from './api';

const { tokenKey, loginInfo, rememberMe, tokenExpireTime, allFeaturesKey, currentFeaturesKey, 
    refreshToken, refreshTokenErrorStatus, signoutStatus }  = authConstants;

export const login = (postData, isRemember) => {
    return new Promise((resolve, reject)=> {
     http.post(API.BaseService.login, postData).then(response =>{  
        const { data : responseData } = response;
            console.log("responseData =============== ",responseData)

            let { customerId } = responseData;
            if(customerId !== undefined && customerId !== ''){ 	 

            // var passwordVal = Buffer.from(postData.password);
			// var password_Encry = passwordVal.toString('base64');
			let encode_postData ={
				username:postData.username, 
				// password:password_Encry,			
			}

            localStorage.setItem(loginInfo, JSON.stringify(encode_postData));  
            http.setJwt(getJwt()); 
            setLocalStorage(responseData, isRemember);

            // setJwt(responseData.accessToken);
            
            // let rolefeatures = responseData.features;
            // localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

            // //Store BrandIds of the User
            // localStorage.setItem(authConstants.userBrands, JSON.stringify(responseData.brandIds));
            // setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
            // http.setJwt(getJwt());  
                      
            // //Set Features
            // let currentFeatures = authHelper.applyPermissions(rolefeatures);
            // localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

            // // Set Refresh Token
            // localStorage.setItem(refreshToken, JSON.stringify(responseData.refreshToken));


            // isRemember && localStorage.setItem(rememberMe, isRemember);
            resolve(response);
            // setFeatures(rolefeatures).then(isSucceed=>{
            //         isRemember && localStorage.setItem(rememberMe, isRemember);
            //         resolve(response);
            // }).catch(err=>{
            //     reject(err);
            // });                    
           
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

export const setRememberMe = (isRemember) => {
    localStorage.setItem(rememberMe, isRemember);
}

export const getRememberMe = () =>{
    let isRemember = JSON.parse(localStorage.getItem(rememberMe));
    return isRemember;
}

export const getCurrentUser = () => {
    try {
      const jwt = getJwt();
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
}

export const getJwt = () =>{
    // let token ="", cookieValue, requestCookie;
    // cookieValue = "; " + document.cookie;
    // requestCookie = cookieValue.split("; " + tokenKey + "=");
    // if (requestCookie.length === 2) 
    //   token = requestCookie.pop().split(";").shift();
    return localStorage.getItem(tokenKey);
}

export const setJwt = (token) => { 
    localStorage.setItem(tokenKey, token);
    document.cookie = `${tokenKey}=${token}`;   
}

export const deleteJwt = () =>
{
    localStorage.removeItem(tokenKey);
    // try{
    // let token = getJwt();
    // let jwt = token && jwtDecode(token);
    // document.cookie = token && (tokenKey + `=;expires=${new Date(jwt.exp * 1000)};`);
    // }
    // catch {
    //     return false;
    // }
}

export const IsTokenTimeExipired = () =>{    
    let errorStatus = localStorage.getItem(refreshTokenErrorStatus);
    let token = localStorage.getItem(refreshToken);

    let exp = localStorage.getItem(tokenExpireTime);
    let remember = localStorage.getItem(rememberMe);

    let expireTime = new Date(exp);
    let currentTime = new Date();
    let isExpired = currentTime > expireTime ;
    console.log({expireTime, currentTime, isExpired, remember});

    console.log("typeof = ",typeof token,", remember = ",remember, ", type = ",typeof remember);
    console.log("errorStatus = ",errorStatus, ", type of errorStatus = ",typeof errorStatus);
    if((!remember && isExpired) ||errorStatus === 'true' || token === 'undefined' || token == null || token === undefined|| token === '') {
        return true;
    } else {
        return false;   
    }

}

/**
 * Store Current Features 
 * @param {*} serverFeatures 
 */
export const setFeatures = (rolefeatures) =>{
    let currentFeatures = authHelper.applyPermissions(rolefeatures);
    localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));
}

/**
 * Get Single Feature by route path
 * @param {*} route_path 
 */
export const getFeature = (route_path) =>{
    route_path = route_path.indexOf("userrole") !== -1 ? "/userrole" : route_path;
    let featuers = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(featuers && featuers.length>0)
    for (let index = 0; index < featuers.length; index++) {
        let productedRoutes = featuers[index].productedRoutes;
        let routeAuthorized = productedRoutes.filter(f => f === route_path && featuers[index].feature_active === 1);
        if(routeAuthorized && routeAuthorized.length>0){
            authorizedFeature = featuers[index];
            break;
        }     
    };
    return authorizedFeature;
}

/**
 * Get all features by rendering Main Menu Apps.
 */
export const getAllFeatures = () =>{
    let featuers =JSON.parse(localStorage.getItem(currentFeaturesKey));
    featuers.push({
        featureAvailable: true,
        feature_active: 1,
        feature_category: "Main Menu Apps",
        feature_name: "KitchenConnect - How To",
        featuteIcon: require('../images/mainmenu/Help.svg').default,
        isMainMenu: true,
        is_editable: 1,
        is_view_only: 1,
        linkTitle: "KitchenConnect - How To",
        navigateUrl: "/howTo",
        productedRoutes: ["/howTo"],
        stringID: "KitchenConnect - How To",
        title: "KitchenConnect - How To"
    })
    let authorizedFeatures = featuers.filter(f => f.feature_active === 1);
    return authorizedFeatures;
}

export const clearLastSessionData = () =>{
    let cfeatuers = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let allfeatuers = JSON.parse(localStorage.getItem(allFeaturesKey));   
    if(!cfeatuers || !allfeatuers){
       authHelper.deleteLocalSession();
     }
}

export const getLoginUserBrands = () =>{
    let userBrands = localStorage.getItem(authConstants.userBrands);
    userBrands = userBrands && JSON.parse(userBrands);
    console.log("userBrands = ",userBrands)
    return userBrands && userBrands.length >0 ? userBrands : [];
}

/**
 * Get Single Feature by Feature Name
 * @param {*} feature_name 
 */
export const getFeatureByFeatureName = (feature_name) =>{
    let features = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(features && features.length>0)
    {
        authorizedFeature = features.find(f => f.feature_name === feature_name && f.feature_active === 1);
    }
    return authorizedFeature;
}

export const setLocalStorage = async (responseData, isRemember) =>{  

    setJwt(responseData.accessToken);
    
    let rolefeatures = responseData.features;
    localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

    //Store BrandIds of the User
    localStorage.setItem(authConstants.userBrands, JSON.stringify(responseData.brandIds));
    setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
              
    //Set Features
    let currentFeatures = authHelper.applyPermissions(rolefeatures);
    localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

    // Set Refresh Token
    localStorage.setItem(refreshToken, responseData.refreshToken);
    localStorage.setItem(refreshTokenErrorStatus, false);

    isRemember && localStorage.setItem(rememberMe, isRemember);  
    localStorage.setItem(signoutStatus, false);
    const preferenceValue = await responseData.userTimeNumberPreferences ? responseData.userTimeNumberPreferences.filter((i)=>i.preference_name === "help-preference") :  []
    localStorage.setItem('showHelpModal', preferenceValue.length ? preferenceValue[0].preference_value === "1" ? true : false : false)
}

export default {
    login,
    getJwt,
    getCurrentUser,
    setRememberMe,
    getRememberMe,
    setJwt,
    deleteJwt,
    IsTokenTimeExipired,
    getFeature,
    getAllFeatures,
    clearLastSessionData,
    getLoginUserBrands,
    getFeatureByFeatureName,
    setLocalStorage
};
 