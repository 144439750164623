import React, { Component, PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { ProductListPopup } from '../frymasterWidgets/ProductListPopup';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';


am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var startOfQuarter = require('date-fns/start_of_quarter');
var endOfWeek = require('date-fns/end_of_week');
var endOfMonth = require('date-fns/end_of_month');
var endOfQuarter = require('date-fns/end_of_quarter');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let currentUnit;

const chartMapping = {
    "Cooks": "KC0095",
    "Trays": "KC1897",
    "Cleanings": "KC0407",
}

class TotalProductCookedChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			peakHourChartInformation: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			average: 0,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			productList: [],
			showPopup: false,
			datapickflag: false
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
	}

	componentDidMount() {
		this.getReportData(this.state.filterType, "", "");
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	onCloseModal = () => {
		this.setState({
			showPopup: false
		});
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e);
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e);
		});
	}

	getReportData(text, startDate, date) {
		let unitID = "";
		let brandID = "";
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

		const header = this.getHeaders(text, startDate, date, unitID);
		let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;
		this.getData(url, header).then(response => {
			let _average = response.peakHourChartInformation.average ? response.peakHourChartInformation.average : 0;
			this.setState({
				average: _average
			})
			if (response.peakHourChartInformation) {
				this.renderChartData(text, response.peakHourChartInformation);
			}
		}).catch(err => {
			//this.renderChartData(err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({datapickflag: text === 'Date Range'})
		this.handleTotalProductCooked(text);
	}

	handleTotalProductCooked(text) {
		this.setState({ filterType: text });
		const { startDate, endDate } = this.state;

		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (this.props.widgetID === "65") {
				if (type === 'Week') {
					endDateFormatted = format(todaysDate, 'YYYY-MM-DD');
					startDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
				}
				else {
					startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
					endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');
				}
			}
			else {
				if (type === "Date Range") {
					startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
					endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');
				}
				else if (type === 'Week') {
					startDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
					endDateFormatted = format(todaysDate, 'YYYY-MM-DD');

					var startDateMonth = getMonth(new Date(startDateFormatted));
					var endDateMonth = getMonth(new Date(endDateFormatted));

					if (startDateMonth !== endDateMonth) {
						startDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
					}
				}
				else {
					endDateFormatted = format(new Date(), 'YYYY-MM-DD');
					startDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
				}
			}

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType
			};
		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType
			};
		}

		return header;
	}


	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						peakHourChartInformation: response.data
					})
				})
				.catch(error => {
					reject({
						peakHourChartInformation: []
					})
				});
		});
	}

	renderChartData = (type, data) => {
		let response = data.Data && data.Data.length > 0 ? data.Data : [];
		if (this.props.widgetID !== "65") {
			if (type !== "Day") {
				let dateFormat = (type === "Week" || type === "Month" || type === "Custom") ? "D" : (type === "Year" || type === "Quarter") ? "MMMM" : "";
				if (response.length > 0) {
					response.map(item => {
						let day = item.Key.split('-')[2];
						let month = item.Key.split('-')[1];
						day = day && day.length === 1 ? '0' + day : day;
						month = month && month.length === 1 ? '0' + month : month;
						let date = item.Key.split('-')[0] + '-' + month + '-' + day;
						item.Key = (moment(date).format(dateFormat));
						item.alpha = 1;
					});
				}
				else {
					response = [];
				}
			}
			else {
				if (response.length > 0) {
					if (localStorage.getItem("timeFormat") === "24"){
						response.map(item => { item.Key = (item.Key === "24" ? "23-00" : `${item.Key - 1}-${item.Key}` ); item.alpha = 1 });
					} else {
						response.map(item => { item.Key = (item.Key === "12" ? "11-12 pm" : item.Key === "24" ? "11-12 am" : item.Key > 12 ? item.Key === "13" ? "12-1 pm" : `${item.Key-13}-${item.Key-12} pm` : `${item.Key -1}-${item.Key} am`); item.alpha = 1});
					}
				}
				else {
					response = [];
				}
			}
		}

		let chart = am4core.create("totalProductsCookedBarChartdiv", am4charts.XYChart);
		if (response && response.length > 0) {
			// Tooltip to show data with . as thousand separator
			let separator = localStorage.getItem("thousandSeparator");
			response.forEach((item) => {
				item.Value = separator === "." ? item.Value.toString().replace(',', separator) : item.Value;
      });
			chart.seriesContainer.zIndex = -1;
			chart.data = response;
			this.chart = chart;
			chart.responsive.enabled = true;

			// Create axes
			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "Key";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = (type === "Year" || type === "Quarter") ? 60 : 10;
			categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
			categoryAxis.renderer.labels.template.fontSize = 10;
			categoryAxis.renderer.inside = false;
			categoryAxis.renderer.labels.template.disabled = false;
			categoryAxis.renderer.grid.template.strokeOpacity = 0;

			categoryAxis.renderer.axisFills.template.disabled = false;
			categoryAxis.renderer.axisFills.template.fill = am4core.color("#D2D68D");
			categoryAxis.renderer.axisFills.template.fillOpacity = 0.2;

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.grid.template.location = 0;
			valueAxis.renderer.minGridDistance = 100;
			// Enable .total and .totalPercent placeholders for stacked series
			valueAxis.calculateTotals = true;
			valueAxis.renderer.inside = false;
			valueAxis.renderer.labels.template.disabled = false;
			valueAxis.min = 0;
			valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
			valueAxis.renderer.labels.template.fontSize = 10;
			valueAxis.renderer.grid.template.strokeOpacity = 1;
			valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
			valueAxis.renderer.grid.template.strokeWidth = 0.3;

			let average = Math.round(data.average ? data.average : 0);
			if (average > 0) {
				var range = valueAxis.axisRanges.create();
				range.value = average;
				range.grid.stroke.above = true;
				range.grid.above = true;
				range.grid.stroke = am4core.color("#FFFFFF");
				range.grid.strokeWidth = 1.5;
				range.grid.strokeOpacity = 1;
			}

			// changing name(Tooltip-cleaning name)
			if (localStorage.getItem("brandName") === "CONVOTHERM" && this.props.widgetID === "48") {
				response.map((item) => {
					item["Total Cleanings"] = item.Value
					// createSeries(item, item.toString())
				})
			}
			// Setting the Series Name based on the unit brand
			let seriesName = localStorage.getItem("brandName") === "CONVOTHERM" ?
				(this.props.widgetID === "32") ? "Trays" :
					(this.props.widgetID === "48") ? "Cleanings" : "" : "Cooks";   
			// Create series
			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = "Value";
			series.dataFields.categoryX = "Key";
			series.dataFields.tootltipY = "tooltipNum";
			series.name = seriesName;
			series.columns.template.width = am4core.percent(93);
			series.columns.template.tooltipText = " " + seriesName + " : [bold]{valueY}[/]";
			series.columns.template.properties.fillOpacity = 1;

			if (this.props.widgetID === "65") {
				series.columns.template.adapter.add("fill", function (fill, target) {
					if (target.dataItem && (target.dataItem.dataContext.Value === 0)) {
						target.dataItem.valueY = (valueAxis.max <= 1 ? 6 : valueAxis.max) - 0.1;
						return am4core.color("#263a44");
					}
					else {
						return am4core.color("#00cc66");;
					}
				});

				series.columns.template.events.on("hit", function (ev) {
					if (ev.target.dataItem && (ev.target.dataItem.dataContext.Value !== 0)) {
						this.setState({
							showPopup: true,
							productList: ev.target.dataItem.dataContext
						});
					}
				}, this);

				series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
			}
			else {
				series.columns.template.properties.fill = am4core.color("#006b94");
			}
			// tooltip - to display the cleaning name
			if (this.props.widgetID === "48") {
				series.tooltip.label.adapter.add("textOutput", function (text, target) {
					var res = "";
					if (target.dataItem.dataContext != undefined) {
						var result = Object.keys(target.dataItem.dataContext).map(function (key) {
							return { key: key, value: Number(target.dataItem.dataContext[key]) };
						});

						var topValues = result.sort(function (p1, p2) { return p2.value - p1.value; }).filter(function (a) { return a.key != 'Key' && a.key != 'Value' && a.key != 'alpha' });
						topValues.forEach(function (item, i) {
							res += "[font-size:12px]" + item.key + " : " + item.value + "\n";
						})
					}
					return res;
				});
			} else {
				series.tooltip.label.adapter.add("textOutput", function (text, target) {
					let seriesNameTrans = seriesName != '' ? reactIntl.formatMessage({ id: chartMapping[seriesName] }) : '';
					if (target.dataItem.dataContext != undefined) {
						if ((target.dataItem.dataContext.Value !== 0)) {
							return " " + seriesNameTrans + " : [bold]" + target.dataItem.dataContext.Value + "[/]";
						}
						else {
							return "";
						}
					}
					else {
						return "";
					}
				});
			}

			// Series Tooltip Test and bg color
			series.tooltip.getFillFromObject = false;
			series.tooltip.background.fill = am4core.color("#FFFFFF");
			series.tooltip.label.fill = am4core.color("#000000");

			categoryAxis.fillRule = function (dataItem) {
				if (dataItem.alpha !== undefined && dataItem.alpha === 1) {
					dataItem.axisFill.visible = true;
				}
				else {
					dataItem.axisFill.visible = false;
				}
			}

			if (this.props.widgetID !== "65") {
				let categoryStarts = null;
				let categoryEnds = null;
				if (type === "Week") {
					var startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
					var endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
					var todayMonth = getMonth(todaysDate);

					if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
						categoryStarts = format(startOfMonth(todaysDate), 'D');
						categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					}
					else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
						categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
						categoryEnds = format(endOfMonth(todaysDate), 'D');
					}
					else {
						categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
						categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					}
				}
				else if (type === "Quarter") {
					categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
					categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
				}

				// Create category axis range
				var categoryAxis_range = categoryAxis.axisRanges.create();
				categoryAxis_range.category = categoryStarts;
				categoryAxis_range.endCategory = categoryEnds;
				categoryAxis_range.axisFill.fill = am4core.color("#396478");
				categoryAxis_range.axisFill.fillOpacity = 0.3;
				categoryAxis_range.label.disabled = true;

				// Create a hover state
				var hoverState = series.columns.template.states.create("hover");
				hoverState.properties.fill = am4core.color("#00a4f2");
			}

			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = 0;
			columnTemplate.strokeOpacity = 0;

			categoryAxis.renderer.grid.template.location = 0;
		}
		else {
			if (this.chart) {
				this.chart.data = [];
			}
		}
	}

	componentWillUnmount = () => {
		if (this.chart) {
			this.chart.dispose();
		}
	}

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

	render() {
		let { showPopup, productList, average } = this.state;
		let averageValue = (average > 0 && average < 1) ? "<1" : this.returnNumberFormat(Math.round(average))
		if(this.props.widgetID == 48 && localStorage.getItem("brandName") === "CONVOTHERM"){
			averageValue = average.toFixed(1) == 0 ? 0 : average.toFixed(1)
		}
		return (
			<div className="colmDi">
				<h4>{ this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID} /> : this.props.widgetName}
					<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} />
				</h4>
				<div class="chartTitleLgnd">{this.props.chartTitleStringID ? <FormattedMessage id={this.props.chartTitleStringID} /> : this.props.chartTitle}</div>
				<div class="chartValLgnd">{averageValue}</div>
				<div id="totalProductsCookedBarChartdiv"></div>
				<ProductListPopup open={showPopup} productList={productList} onCloseModal={this.onCloseModal} />
			</div>
		);
	}
}

export default TotalProductCookedChart;
