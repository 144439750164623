import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getMediaImageLibraryList, mediaSelectedItems } from '../../../actions/navigation/mediaManagement/mediaImageLibraryListAction'
import $ from 'jquery';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import { MediaManagementAPI } from '../../../service/api';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { mediaDraggedItem, mediaDragDeleteItem, resetSelectedItems } from '../../../actions/navigation/mediaManagement/mediaDragAction';
import ReactPlayer from 'react-player'
import { CUSTOMER_ID } from '../../../utils/appConstants';
import { Cropper } from '../../controls/cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import { FormattedMessage, injectIntl } from 'react-intl';
import NoRecordsFound from '../../controls/noRecordsFound';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';

var request = require('request');

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

class MediaImageLibrary extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.loadPage();
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	closePlayer() {
		this.setState({
			previewurl: '',
			previewfilename: ''
		});
	}

	constructor(props) {
		super(props);
		this.state = {
			imagedata: {},
			userData: {},
			userCheckedData: [],
			userSelectAll: false,
			isIndeterminateSelect: false,
			loading: true,
			previewfilename: "",
			previewfileformat: "",
			previewurl: "",
			previewurl11: "",
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			isAdd: true,
			isDelete: false,
			isAddToPreparation: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			dragItem: {},
			selectFileText: "Select Preparation from list",
			preparationSelectionClass: "initialActive",
			showCropPopup: false,
			src: null,
			cropWidth: 100,
			cropHeight: 100,
			searchData: null,
		};

		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.cancelDeleteUser = this.cancelDeleteUser.bind(this);
		this.deleteSelectedMedia = this.deleteSelectedMedia.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.closePlayer = this.closePlayer.bind(this);
		this.closeCropPopup = this.closeCropPopup.bind(this);
		this.cropSubmit = this.cropSubmit.bind(this);
	}

	updateDimensions() {
		const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
	}

	componentDidMount() {
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		if (localStorage.getItem("unitBrandId") === "1") {
			localStorage.setItem("selectedFamilyID", '1,2,40,41');
		}
		else {
			localStorage.setItem("selectedFamilyID", '');
		}
		window.addEventListener('resize', this.updateDimensions());
		this.loadPage();
		$('.OpenImgMp4Upload').click(function () { $('#imgMp4upload').trigger('click'); });
	}

	
	loadPage = () => {
		this.setState({loading: true});
		let { searchData } = this.state;
		let searchParam = {};
		if (searchData && searchData.searchtext) {
			searchParam['searchtext'] = encodeURIComponent(searchData.searchtext);
		}

		if (searchData) {
			Object.keys(searchData).map((item) => {
				if (item !== "searchtext") {
					searchParam[item] = encodeURIComponent(searchData[item]);
				}
			})
		}
		this.props.getMediaImageLibraryList('',searchParam).then((res)=>{
			this.setState({loading: false})
		});
	}

	async componentDidUpdate(prevState) {
		this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loading: true },()=>{
                this.loadPage();
            });
		}
	}

	
    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	static getDerivedStateFromProps = (props) => ({...props});

	componentWillMount() {
		this.setState({
			loading: true
		});
	}

	cancelDeleteUser = () => {

	}

	closeCropPopup = () => {
		this.setState({
			showCropPopup: false
		});
	}

	async cropSubmit(croppedImage) {
		let _this = this;
		let url = `${MediaManagementAPI.getMedias3uploadpresigned}` + '?customer=' + CUSTOMER_ID + '&key=' + _this.state.data.mediaId + '.' + croppedImage.type + '&thumbnail=true';

		let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
		Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
					let md5 = require('md5');
					let fileSize = Buffer.byteLength(imagebuffer);
					fileSize = (fileSize / 1048576).toFixed(2);
					_this.state.data.resolution = "400 x 400";
					_this.state.data.category = "picture";
					_this.state.data.crc = md5(imagebuffer);
					_this.state.data.fileSize = fileSize;
					Jimp.read(imagebuffer)
					.then(thumbnail => {
						thumbnail.resize(80, 80, (err, thumbnail) => {
							thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
								_this.props.history.push({
									pathname: "/assignMedia",
									url: url,
									data: _this.state.data,
									srcfile: imagebuffer,
									imageBuffer: thumbnailbuffer,
									previouspath: "/MediaImageLibrary",
									loading: false
								});
							})            
						})
					})
                })
            })
        })
        .catch(err => {
            _this.showError(err);
        })
	}

	onChangeHandler = (e) => {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible } = this.props;
		let _this = this;
		if (e.target.files[0] !== null) {

			this.setState({
				loading: loaderIconVisible
			});
			let brandName = localStorage.getItem("brandName");
			let fileSize = e.target.files[0].size;
			fileSize = (fileSize / 1048576).toFixed(2);

			let fileName = e.target.files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileext =  fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.'+fileext, "");
			let filelength = fileName.length;

			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;

			let resolutionofimageVideo = 0;
			let mediaid = fileNamewithOutExt + '_' + Date.now();
			mediaid = mediaid.replace(/\s+/g, '');

			var FileUploadThumbnail = require('../../../utils/fileThumnailProcesser');
			let md5 = require('md5');
			let data = {
				"customer": CUSTOMER_ID,
				"brand": localStorage.getItem("brandName"),
				"brand_id": localStorage.getItem("unitBrandId"),
				"type": 'imageLibrary',
				"fileName": fileName,
				"fileFormat": '.' + fileext,
				"fileSize": fileSize,
				"user": loggedinUser,
				"mediaId": mediaid,
			};


			let url = `${MediaManagementAPI.getMedias3uploadpresigned}` + '?customer=' + CUSTOMER_ID + '&key=' + mediaid + '.' + fileext + '&thumbnail=true';

			if (fileSize) {
				var width;
				var height;
				let srcfile = e.target.files[0];
				if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						var image = new Image();
						image.src = e.target.result;
						data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""), "base64"));
						image.onload = (img) => {
							width = img.target.width;
							height = img.target.height;
							resolutionofimageVideo = width + " x " + height;
							if ((brandName === "FRYMASTER") && (width < 100 || height < 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								_this.setState({
									loading: loaderIconVisible,
									error_modal: {
										open: true,
										message: formatMessage({ id: 'KC1224' })
									}
								});
							}
							else if ((width > 100 || height > 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								_this.setState({
									loading: loaderIconVisible,
									showCropPopup:true,
									src: reader.result,
									cropWidth: width < height ? width : height,
									cropHeight: width < height ? width : height,
									data: data,
								});
								$('#popupTrigger').trigger('click');
							}
							else {
								data.resolution = resolutionofimageVideo;
								data.category = 'picture';
								if (srcfile) {
									let avb = new FileUploadThumbnail({
										maxWidth: 80,
										maxHeight: 80,
										file: srcfile,
										onSuccess: (src) => {
											src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
											let imageBuffer = Buffer.from(src, "base64");
											this.setState({
												loading: loaderIconVisible
											});
											this.props.history.push({
												pathname: "/assignMedia",
												url: url,
												data: data,
												srcfile: srcfile,
												imageBuffer: imageBuffer,
												previouspath: "/MediaImageLibrary"
											});
										},
										onError: function (err) {
											_this.showError(err);
										}
									}).createThumbnail(); 
								}
							}
						};
					}
				}
				else {
					this.setState({
						loading: loaderIconVisible,
						error_modal: {
							open: true,
							message: formatMessage({ id: 'KC1529' })
						}
					});
				}
			}
			else {
				this.setState({
					loading: loaderIconVisible,
					error_modal: {
						open: true,
						message: formatMessage({ id: 'KC1530' })
					}
				});
			}
		}
		$("#imgMp4upload").val('');
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loading: false,
					error_modal: {
						open: true,
						message: data ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loading: false,

			});

		}
	}

	onAssignClickHandler(item) {
		const { mediaDraggedItemInfo } = this.props;
		let selectedList = [];
		if (mediaDraggedItemInfo.other && mediaDraggedItemInfo.other.length > 0) {
			selectedList.push(mediaDraggedItemInfo.other[0].MEDIA_ID)
		}
		if (mediaDraggedItemInfo.prepartions && mediaDraggedItemInfo.prepartions.length > 0) {
			selectedList = selectedList.concat(new Array(mediaDraggedItemInfo.prepartions[0].MEDIA_ID));
		}

		this.props.mediaSelectedItems(selectedList);
		this.props.resetSelectedItems();
		this.props.history.push(`/assignPackageMedia`);

	}

	handleSingleCheckboxChange(e, rowData, listCount) {
		this.setState({ dragItem: rowData });
		let userCheckedData = this.state.userCheckedData;
		const findIndex = userCheckedData.findIndex((item) => item.MEDIA_ID === rowData.MEDIA_ID)
		if (findIndex > -1) {
			if (e.target.checked === false) {
				userCheckedData.splice(findIndex, 1)
			}
		} else {
			if (e.target.checked === true) {
				userCheckedData.push(rowData)
			}
		}

		function getCheckBoxstate() {
			if (userCheckedData.length == listCount) {
			  return { userSelectAll: true, isIndeterminateSelect: false };
			} else if (userCheckedData.length == 0 && listCount != 0) {
			  return { userSelectAll: false, isIndeterminateSelect: false };
			} else if (listCount >= userCheckedData.length) {
			  return { userSelectAll: true, isIndeterminateSelect: true };
			}
		}

		this.setState({
			userCheckedData: userCheckedData,
			...getCheckBoxstate()
		});

		this.isSelectEditDelete(this.state.userCheckedData);
	}

	isSelectEditDelete = (CheckedData) => {
		if (CheckedData && CheckedData.length === 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isAddToPreparation: true,
				selectFileText: "Confirm selection",
				preparationSelectionClass: "active"
			});
		}
		else if (CheckedData && CheckedData.length > 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isAddToPreparation: false,
				selectFileText: "Select Preparation from list",
				preparationSelectionClass: "initialActive"
			});
		}
		else {
			this.setState({
				isAdd: true,
				isDelete: false,
				isAddToPreparation: false,
				selectFileText: "Select Preparation from list",
				preparationSelectionClass: "initialActive"
			});
		}
	}

	handleChange = () => {

		var userSelectAll = !this.state.userSelectAll;
		const { mediaImageLibraryListInfo } = this.props;
		mediaImageLibraryListInfo && mediaImageLibraryListInfo.mediaList.length>0 && mediaImageLibraryListInfo.mediaList.map(item => {
            if (item.TYPE != 'defaultImageLibrary') {
                item.checked = userSelectAll;
            }
        });
		if (!this.state.userSelectAll === true) {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect: false,
				userCheckedData: mediaImageLibraryListInfo.mediaList,
				isAdd: false,
				isDelete: true,
				isAddToPreparation: false
			});
		} else {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect: false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isAddToPreparation: false
			});
		}
	}

	deleteSelectedMedia = () => {
		this.setState({
			loading: true
		})
		const mediaPreparationList = [...this.state.userCheckedData];
		const { loaderIconVisible } = this.props;
		if (mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList != null) {
			let postData = {
				mediaIds: mediaPreparationList.map(function (item) { return item.MEDIA_ID })
			}
			// this.setState({
			// 	loading: loaderIconVisible
			// })
			this.putData(postData, MediaManagementAPI.getMediaBrandCustomer, HTTP_METHOD.DELETE).then(response => {
				if (response && response.data && response.data.message) {
					if (response.data.httpCode === "HTTP_200") {
						// this.props.getMediaImageLibraryList();
						// let unAssignedMedia = mediaPreparationList.filter(item => item.checked !== true);
						this.setState({
							// userCheckedData: unAssignedMedia,
							isDelete: false,
							isAdd: true,
							isAddToPreparation: false,
							loading: false,
							success_modal: {
								open: true,
								message: (response.data.stringID || response.data.message) || ""
							},
							isIndeterminateSelect: false,
							userSelectAll:false,
						});
						mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList.map(item => {
							item.checked = false;
						});
					}
				}
			}).catch(err => {
				this.showError(err);
			});
		}
		this.removeFileHandler('preparations');
		this.setState({
			selectFileText: "Select Preparation from list",
			preparationSelectionClass: "initialActive"
		});
	}

	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	previewMediafile = (MEDIA_ID, FILE_NAME, FILE_TYPE) => {
		if (MEDIA_ID && MEDIA_ID.length > 0 && MEDIA_ID != null) {
			http.get(`${MediaManagementAPI.getMediamediapreview}` + MEDIA_ID,
				{
					headers: {
						'Content-Type': 'application/json',
						'type' : FILE_TYPE
					},
					data: {}
				})
				.then(response => {

					let fileext = FILE_NAME.split('.');
					fileext = fileext[1];
					let previewformat = "";
					if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
						previewformat = "image";
					}
					else if (fileext === "mp4" || fileext === 'zip') {
						previewformat = "video";
					}

					if (fileext === "svg" || fileext === "SVG" ) {
						previewformat = "image";
						request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
							let src = 'data:image/svg+xml;base64,' + body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
							this.setState({
								previewfilename: FILE_NAME,
								previewurl: src,
								previewfileformat: previewformat
							});
						});
					}
					else {
						this.setState({
							previewfilename: FILE_NAME,
							previewurl: response.data.imageUrl,
							previewfileformat: previewformat
						});
					}
				})
				.catch(error => {
					this.showError(error);
				})
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	componentWillReceiveProps(nextProps) {
		console.log('componentWillReceiveProps')
	}
	shouldComponentUpdate(nextProps, nextState) {
		console.log('shouldComponentUpdate')
		return true;
	}

	dragStart(e, item) {
		this.setState({ dragItem: item })

	}
	onDrop(e) {
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		if (userProfileAuthFeature.is_editable === 1) {
			const { dragItem } = this.state;
			const { mediaDraggedItemInfo } = this.props;
			const draggedItem = { type: 'preparation', item: dragItem }
			if (!mediaDraggedItemInfo.prepartions || mediaDraggedItemInfo.prepartions.length === 0) {
				this.props.mediaDraggedItem(draggedItem);
			}

			this.setState({
				userSelectAll: false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isAddToPreparation: false,
				selectFileText: "Select Preparation from list",
				preparationSelectionClass: "initialActive"
			});
		}
		console.log('onDrop', e);
	}
	onDragOver(e) {
		console.log('onDragOver', e);
	}

	removeFileHandler(type = '') {
		if (type === 'preparations') {
			this.props.mediaDragDeleteItem({ type: 'preparations' });
		} else if (type === 'other') {
			this.props.mediaDragDeleteItem({ type: 'other' });
		}
	}
	updatePrepartionSelectedImage() {
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const { prepartions } = this.props.mediaDraggedItemInfo;
		return <div class="dragedDetailsTable">
			{prepartions && prepartions.length > 0 && <div class="dragedDetailsRow">
				<div class="dragedImage">
					<img src={prepartions[0].FILE_FORMAT === '.zip' ? zipimage : prepartions[0].thumbUrl} />
				</div>
				<div class="dragedText">
					<h5>{prepartions[0].FILE_NAME}</h5>
					<h6>{prepartions[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" class="removeImageVideo" onClick={() => this.removeFileHandler('preparations')}></a>
				</div>
			</div>}
		</div>
	}
	updateOtherSelectedImage() {
		const { other } = this.props.mediaDraggedItemInfo;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		return <div class="dragedDetailsTable compilationBg">
			{other && other.length > 0 && <div class="dragedDetailsRow">
				<div class="dragedImage">
					<video poster={other[0].FILE_FORMAT === '.zip' ? zipimage : other[0].thumbUrl}>
						<source src={other[0].FILE_FORMAT === '.zip' ? zipimage : other[0].thumbUrl} type="video/mp4" />
						<FormattedMessage id="KC1518"/>
												</video>
				</div>
				<div class="dragedText">
					<h5>{other[0].FILE_NAME}</h5>
					<h6>{other[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" class="removeImageVideo" onClick={() => this.removeFileHandler('other')}></a>
				</div>
			</div>}
		</div>
	}
	updateSize() {
		const { size } = this.props.mediaDraggedItemInfo;
		let widthPercent = '0%';
		if (size <= 80) {
			widthPercent = (size * 1.25) + '%';
		} else if (size > 80) {
			widthPercent = '100%';
		}
		
		return <div>
			<div class="maxFileSize">
				<div class={(size > 80) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="80">
					<FormattedMessage id="KC1734" values={{ size: this.roundingTheSize() }}/></div>
				<FormattedMessage id="KC1525"/></div>
		</div>
	}
	roundingTheSize() {
		const { size } = this.props.mediaDraggedItemInfo
		if (size >= 0)
			return Math.floor(size * 100) / 100;
		else
			return 0;
	}

	renderButton(filetype, mediaID, fileName, type) {
		const { formatMessage } = this.props.intl;
		if (filetype === '.zip') {
			return (
				<a href="javascript:void(0)" class="mediaPreview" title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>
			);
		} else {
			return (
				<a href="javascript:void(0)" data-toggle="modal" data-target="#mediaPreviewModal"
					onClick={() => this.previewMediafile(mediaID, fileName, type)}
					class="mediaPreview" title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>
			);
		}
	}

	render() {
		const { formatMessage } = this.props.intl;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const { userCheckedData, isDelete, previewfilename, previewurl, sortColumn, isAdd, previewfileformat, cropWidth, cropHeight, loading } = this.state;
		const { mediaImageLibraryListInfo, loaderIconVisible } = this.props;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		let { data, success_modal, error_modal } = this.state;
		const deviceBrandname = localStorage.getItem("unitBrandId");
		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102", isNbSp: true },
			{ path: "FILE_NAME", labelStringID: "KC0677" },
			{ path: "CREATEDAT", labelStringID: "KC0725" },
			{ path: "RESOLUTION", labelStringID: "KC0726", isNbSp: true },
			{ path: "FILE_SIZE", labelStringID: "KC0682", isNbSp: true },
			{ path: "IN_USE_COUNT", labelStringID: "KC0728", isNbSp: true },
			{ path: "", labelStringID: "KC0729", isNbSp: true },
			{ path: "", labelStringID: "KC0730", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		if (sortColumn.path === "CREATEDAT") {
			if (sortColumn.order === "desc") {
				mediaImageLibraryListInfo.mediaList = mediaImageLibraryListInfo.mediaList.sort((d1, d2) => new Date(d2.CREATEDAT) - new Date(d1.CREATEDAT));
			}
			else {
				mediaImageLibraryListInfo.mediaList = mediaImageLibraryListInfo.mediaList.sort((d1, d2) => new Date(d1.CREATEDAT) - new Date(d2.CREATEDAT));
			}
		}
		else {
			mediaImageLibraryListInfo.mediaList = _.orderBy(mediaImageLibraryListInfo.mediaList, [sortColumn.path], [sortColumn.order]);
		}

		mediaImageLibraryListInfo.mediaList.forEach(item => {
			const findIndex = userCheckedData.findIndex((checkedData) => checkedData.MEDIA_ID === item.MEDIA_ID && checkedData.TYPE != 'defaultImageLibrary')
			if (findIndex > -1) {
				item.checked = true;
			}
			else {
				item.checked = false;
			}
			item.order_id = orderId++
		});

		let listCount = mediaImageLibraryListInfo.mediaList.length;

		if (previewfileformat === "image") {
			$("#imgdiv").removeClass("displaynone");
			$("#videodiv").addClass("displaynone");
		}
		else if (previewfileformat == "video") {
			$("#videodiv").removeClass("displaynone");
			$("#imgdiv").addClass("displaynone");
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div className="mediaWrapper">
							<div className="mediaHeader mediamclibraryH">
								<ul>
									<li>
										<Link to="/mediaImageLibrary" title={formatMessage({ id: 'KC1531' })} className="active"><FormattedMessage id="KC1531"/></Link>
									</li>
									<li>
										<Link to="/mediaAudioLibrary" title={formatMessage({ id: 'KC1532' })} className={localStorage.getItem("brandName") === "MERRYCHEF" ? "" : "displaynone"}><FormattedMessage id="KC1532"/></Link>
									</li>
									<li>
										<button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
										<button type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text rowDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text rowDelete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" title={formatMessage({ id: 'KC0721' })} onClick={() => { }}>&nbsp;</button>
										<input id="imgMp4upload" type="file" className="mediaUploadImgMp4" accept="image/png, image/jpeg, image/jpg,image/gif,image/bmp,image/svg+xml" onChange={(e) => this.onChangeHandler(e)} />
										<button id="btnadd" type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text rowAdd OpenImgMp4Upload ${isAdd ? "" : "disabled"}` : "btn btn-default-text rowAdd OpenImgMp4Upload disabled"} title={formatMessage({ id: 'KC0720' })}>&nbsp;</button>
									</li>
								</ul>
							</div>
							<form className="mediamclibraryForm">
							<div className="mediaPreparationTable">
							<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
							</div>
                          	<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
								<div className="modal-dialog modal-md loginModal">
									<div className="modal-content" >

										<div className="modal-body">
											<div className="singOutConfirmation">
											<FormattedMessage id="KC1533"/>
                            				</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary signOutNoBtn" onClick={() => this.cancelDeleteUser()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedMedia()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal fade mediaPreviewModal" data-keyboard="true" data-backdrop="static" id="mediaPreviewModal" tabIndex="-1" role="dialog" aria-labelledby="mediaPreviewModalLabel">
								<div className="modal-dialog modal-lg modal-xs">
									<div className="modal-content">
										<div className="modal-header modal-header-danger">
											{previewfilename} <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closePlayer}>&nbsp;</button>
										</div>
										<div className="modal-body">
											<div id="imgdiv" className="imageandVideoPreview displaynone">
												<img src={previewurl}></img>
											</div>
											<div id="videodiv" className="imageandVideoPreview displaynone">
												<ReactPlayer url={previewurl} playing controls />
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- imageResizeModal --> */}
							<div className="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabIndex="-1" role="dialog" aria-labelledby="infoModalLabel">
								<div className="modal-dialog modal-lg imageResizeModal">
									<div className="modal-content">  
										<div className="modal-header modal-header-danger">
										<FormattedMessage id="KC1234"/> <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
										</div>
										<div className="modal-body"> 
											<form className="imageResizeForm"> 
												<Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={data ? data.fileName : ""} fileFormat={data ? data.fileFormat : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
											</form>
										</div> 
									</div>
								</div>
							</div>
							{/* <!-- //imageResizeModal --> */}
							<div className='pageScrollbar'>
							<form className="mediamclibraryForm">
								<div className="mediaPreparationTable">								 
									{mediaImageLibraryListInfo.mediaList && mediaImageLibraryListInfo.mediaList.map((item, i) =>
										<ul className="tBody">
											<li>{i + 1}</li>
											<li draggable="true" onDragStart={(e) => this.dragStart(e, item)}>
												<span className="mediaFileSpan"> <img src={item.FILE_FORMAT === '.zip' ? zipimage : item.thumbUrl} /></span>
											</li>
											<li>{item.FILE_NAME}
												<div className="mediaSListSM">{item.CREATEDAT}</div>
												<div className="mediaSListRS">{item.RESOLUTION}, {item.FILE_SIZE} <FormattedMessage id="KC1498"/></div>
												<div className="mediaSListAS">
													{item.assignedCustomerGrops.map((subitem, j) =>
														<div className="assignedTo alert alert-dismissible fade show" role="alert">
															{subitem}
														</div>
													)}
												</div></li>
											<li>{item.CREATEDAT}</li>
											<li>{item.RESOLUTION}</li>
											<li>{item.FILE_SIZE}</li>
											<li>{item.IN_USE_COUNT}</li>
											<li>{item.assignedCustomerGrops.map((subitem, j) =>
												<div className="assignedTo alert alert-dismissible fade show" role="alert">
													{subitem}
												</div>
											)}
											</li>
											<li>{this.renderButton(item.FILE_FORMAT, item.MEDIA_ID, item.FILE_NAME, item.TYPE)}</li>
											<li>
												<div className={userProfileAuthFeature.is_editable == 1 && item.TYPE != "defaultImageLibrary" ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
													<label for={i + 1}></label>
												</div>
											</li>
										</ul>)}
								</div>
								<NoRecordsFound loaderIconVisible={loading} length={(mediaImageLibraryListInfo && mediaImageLibraryListInfo.mediaList !== undefined) ? mediaImageLibraryListInfo.mediaList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>
							</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName={`Media – ${deviceBrandname === "1" ? "Merrychef" : "Frymaster"} Library`} headerNameStringID={formatMessage({ id: 'KC0152' }) + " – " + (deviceBrandname === "1" ? "Merrychef " : "Frymaster ") + formatMessage({ id: 'KC0658' })} activeClass="mediaNav" />
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		mediaImageLibraryListInfo: state.mediaImageLibraryListInformation.mediaImageLibraryListInformation,
		mediaDraggedItemInfo: state.mediaDraggedItem.mediaDraggedItem,
		loaderIconVisible: state.mediaImageLibraryListInformation.loaded,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaImageLibraryList, mediaSelectedItems, mediaDraggedItem,
		mediaDragDeleteItem, resetSelectedItems, searchVisible, searchText
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaImageLibrary));
