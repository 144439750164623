import $ from 'jquery';
import _ from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { bindActionCreators } from 'redux';
import { searchText, searchVisible } from '../../actions/advanceSearch/advanceSearchAction';
import { NotificationCenter, unitAPI, UserProfile } from '../../service/api';
import authService from '../../service/authService';
import http from '../../service/httpService';
import { CUSTOMER_ID } from '../../utils/appConstants';
import { brandLogo, brandUnits } from '../../utils/imgContants';
import Constants from '../../woopraConfig/Woopraconstant';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { Paginator } from '../controls/paginator';
import { TableHeader } from '../controls/tableHeader';
import { isEqualObj } from '../globalSearchBar/selectOption';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import EventContentModal from './EventModal/contentModal';
import NotificationFlowModal from './EventModal/notificationFlowModal';
import EmailContentModal from './EventModal/emailContentModal';
import EventOrgModal from './EventModal/eventOrgModal';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';

let headers = {
    'Content-Type': 'application/json',
};

const TEXT_STRING_ID_MAP = {
    // Workflow node names
    'DEVICE': 'KC0864',
    'WEB PORTAL': 'KC0865',
    'EMAIL': 'KC0136'
};

class EventLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            internalLoader: false,
            eventLibrary: [],
            modelUnit: {},
            sortColumn: { path: "event_code", order: "asc", sort_color: "#63778a" },
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedNotification: {},
            workflowDropdown: [],
            workflowDetails: {},
            searchData: null,
            currentPage: 1,
            pageSize: 25,
            totalPages: null,
            modelList: [],
            linkList: {},
            selectedWorkflowId: 1,
            units: [],
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
        }
    }

    async componentDidMount() {
        this.props.searchVisible(true);
        document.body.style.backgroundColor = "#f2f2f2";
        this.getBrands();
        window.addEventListener('resize', this.updateDimensions());
        this.updateDimensions();
        this.getEventLibrary();

    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const $tableBody = $('.notificationlibrary');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0; 
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }


    async componentDidUpdate(prevState) {
        this.updateDimensions();
        let { searchData } = prevState;
        let curSearchData = this.state && this.state.searchData;
        if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
            this.setState({ loaderIconVisible: true, currentPage: 1 }, async () => {
                await this.getEventLibrary();
            })
        }
    }

    getBrands = () => {
        http.get(`${unitAPI.getBrands}`, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({
                    modelList: response.data
                });
            }
        }).catch(err => {
        });
    }

    // componentWillUnmount
    componentWillUnmount() {
        this.props.searchText({});
        this.props.searchVisible(false);
        $("div").removeClass("modal-backdrop fade show");
    }

    setModelUnitName = (modelUnits) => {
        const returnObj = {};
        let keys = Object.keys(modelUnits);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            returnObj[element.toLowerCase()] = modelUnits[element];
        }
        return returnObj;
    }

    getEventLibrary = (currentPage = 1, sortColumn = this.state.sortColumn) => {
        this.setState({ loaderIconVisible: true });
        let { searchData } = this.state;
        let headerObj = {};

        if (searchData && searchData.searchtext) {
            headerObj['searchText'] = searchData.searchtext;
        }

        if (searchData) {
            let searchParam = {};

            Object.keys(searchData).forEach((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })

            if (searchParam && Object.keys(searchParam).length) {
                headerObj['searchParams'] = btoa(JSON.stringify(searchParam));
            }
        }


        let url = NotificationCenter.getNotificationLibrary;
        const stringified = queryString.stringify({
            "pageNumber":currentPage,
            "limit":this.state.pageSize,
            "cid": CUSTOMER_ID,
            'basecountry': localStorage.getItem('basecountry')
        });
        url = url + (stringified ? `?${stringified}` : '') + `&cgid=` + localStorage.getItem("custGroupID") + `&brandid=${localStorage.getItem("brandId")}`
        if(sortColumn && sortColumn.path){
			url = `${url}&sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
		}
        http.get(url, {
            headers: { ...headers, ...headerObj },
            data: {}
        }).then(async response => {
            if (response && response.data && response.data.body) {
                const { body: eventLibrary, modelBasedUnitsCount, totalPageCount: totalPages } = response.data;
                const modelUnit = await this.setModelUnitName(modelBasedUnitsCount);
                this.setState({
                    loaderIconVisible: false,
                    eventLibrary,
                    modelUnit,
                    totalPages,
                    currentPage,
                    sortColumn
                });
            }
        }).catch(err => {
            //To avoid the two popup at the same time
            if (err && err.response && err.response.status && err.response.status === 403){
				this.setState({
					loaderIconVisible: false,
				},() => {					
				});
				return;
			}
            this.setState({
                loaderIconVisible: false,
                eventLibrary: [],
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : err && err.message ? err.message : 'KC0944',
                },
            });
        });
    }

    getOrgnanizationView = async (model) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let headerVal = {
            "Cgids": localStorage.getItem('custGroupID'),
            'Content-Type': 'application/json',
            'model': model,
            'useremail': loggedinUser,
            'basecountry': localStorage.getItem('basecountry')
        }

        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: headerVal, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, (data) => {
                    this.processOrgnanizationJSON(data);
                });
            }
            else {
                this.processOrgnanizationJSON(response.data);
            }
        }).catch(err => {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    /**
   * Get Unit Smart Group names for display at Unit Node
   * @param {*} unitNode Unit Node object
   * @returns Array<String> Smart Group names
   */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
    * Convert hierarchy data structure into flatten array
    * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
    * @param {*} nodeLevel Node level No
    * @param {*} currentRowIndex Current row index for Tree Data
    * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
    * @param {*} parentRowIndex Parent Node row index
    * @param {*} parentColIndex Parent Node column index
    * @returns Array<Array>
    */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            loaderIconVisible: false,
        })
    }

    onSort = sortColumn => {
        // this.setState({ loaderIconVisible: true }, () => {
        //     this.setState({
        //         sortColumn
        //     },() => {
        //         const { sortColumn, eventLibrary } = this.state;
        //         setTimeout(async() => {
        //             const eventLibrarySort = await eventLibrary && eventLibrary.length ? _.orderBy(eventLibrary, [sortColumn.path], [sortColumn.order]) : [];
        //             this.setState({ eventLibrary: eventLibrarySort, loaderIconVisible: false })
        //         }, 100)
        //     })
        // })
        this.getEventLibrary(1, sortColumn)
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            },
        }, () => {
            this.getEventLibrary();
        });
    }

    handleSubscription = (notification) => {
        this.setState({ loaderIconVisible: true });
        const { brand, model, event_code, subscribed } = notification;
        const dataObj = {
            brand,
            model,
            event_code,
            "customerId": localStorage.getItem("customerId"),
            "subscribed": subscribed ? false : true,
            "user_email": localStorage.getItem("user"),
            "customerGroupId": localStorage.getItem("custGroupID")
        };
        let successMessage = subscribed? 'KC0870': 'KC0872';

        let url = NotificationCenter.subscribeNotification;

        http.post(url, dataObj,
            {
                headers
            }
        ).then(async () => {
            this.setState({
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: successMessage
                }
            });
        }).catch((err) => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : err && err.message ? err.message : 'KC0944',
                }
            });
        });
    }


    openLink = (link, type) => {
        if (type === "Link") {
            WoopraEvents(`${Constants.TROUBLESHOOTING_THROUGH_LINK}`);
        } else {
            WoopraEvents(`${Constants.TROUBLESHOOTING_THROUGH_VIDEO}`);
        }
        window.open(link, "_blank");
    }

    sketchNode = (node, index) => {
        const { formatMessage } = this.props.intl;

        const imagePath = require(`../../images/icons/${node.image}`);
        return (
            <li key={index}>
                <h5 title={TEXT_STRING_ID_MAP[node.code] ? formatMessage({id:TEXT_STRING_ID_MAP[node.code]}) : ''}><img src={imagePath} /> {TEXT_STRING_ID_MAP[node.code] ? <FormattedMessage id={TEXT_STRING_ID_MAP[node.code]}/> : node.code.charAt(0) + node.code.slice(1).toLowerCase()}</h5>
                {node && node.Details ? node.Details.map((element, indexValue) => {
                    return (
                        <div key={indexValue} className="nFlowEvents">
                            {node.code === 'EMAIL' && element.name === 'Text' ?
                                <button type="button" data-toggle="modal" onClick={() => $('#flowDetailsViewModal').modal('hide')} data-target="#emailContentModal" className="nFlowIcon btn active"><img src={require(`../../images/icons/${element.icon}`)} /></button>
                                : <span className="nFlowIcon"><img src={require(`../../images/icons/${element.icon}`)} /></span>
                            }
                            {element.number || element.total ? <h4>{`${element.number}/${element.total}`}</h4> : <h4>&nbsp;</h4>}
                        </div>
                    )
                }) : null}
            </li>)
    }

    getBrandImage = (BRAND) => {
        let pickBrand = brandLogo.find(board => board.name.toUpperCase() === BRAND);
        if (pickBrand != null) {
            return pickBrand.img;
        }
        else {
            return require('../../images/store/Convenience.svg').default;
        }
    }

    getBrandUnitImage = (BRAND) => {
        let pickBrand = brandUnits.find(board => board.name.toUpperCase() === BRAND);
        if (pickBrand != null) {
            return pickBrand.img;
        }
        else {
            return require('../../images/unit/FRY-FQ-4000-2Vat.svg').default;
        }
    }

    /**
 * When navigate new page this event will return current page number
 * @param {*} currentPage 
 */
    onPageChange = (currentPage) => {
        this.setState({ loaderIconVisible: true }, () => {
            this.getEventLibrary(currentPage);
        });
    }

    getBrandId = (brandName) => {
        const { modelList } = this.state;
        for (let index = 0; index < modelList.length; index++) {
            const element = modelList[index];
            if (brandName.toLowerCase() === element.BRAND_NAME.toLowerCase()) {
                return element.ID;
            }
        }
    }

    getWorkflowDetails = async (workflowDropdown, brand) => {
        const { id: workFlowId, workFlowType } = workflowDropdown;
        this.setState({ internalLoader: true });
        const brandId = await this.getBrandId(brand);
        let url = NotificationCenter.workflowDetails;
        const stringified = queryString.stringify({
            workFlowId,
            workFlowType,
            customerId: localStorage.getItem("customerId"),
            brandId
        });

        url = url + (stringified ? `?${stringified}` : '');
        http.get(url, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data) {
                let workflowDetails = response.data.workflow;
                this.setState({ workflowDetails, internalLoader: false })
            }
        }).catch(err => {
            this.setState({
                workflowDetails: {},
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : err && err.message ? err.message : 'KC0944',
                },
                internalLoader: false
            });
        });
    }

    getUnitCount = (model) => {
        const splitModel = model ? model.split(',') : [];
        let unitCount = 0;
        for (let index = 0; index < splitModel.length; index++) {
            const element = splitModel[index];
            unitCount = unitCount + (this.state.modelUnit[element.toLowerCase()] ? this.state.modelUnit[element.toLowerCase()] : 0)
        }
        return unitCount;
    }

    loadDropdown = (notification) => {
        this.setState({ internalLoader: true })
        let url = NotificationCenter.workflowList;
        http.get(url, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data && response.data.body) {
                let workflowDropdown = response.data.body;
                this.setState({ workflowDropdown });
                if (workflowDropdown && workflowDropdown.length) {
                    await this.getWorkflowDetails(workflowDropdown[0], notification.brand);
                }
            }
        }).catch(err => {
            this.setState({
                workflowDropdown: [],
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : err && err.message ? err.message : 'KC0944',
                },
            });
        });
    }

    activeInactiveButton = (isAuthorized, notification, resolution) => {
        if (isAuthorized) {
            this.setState({ selectedNotification: { ...notification, resolution } });
            $('#confirmationCheckModal').modal('show');
        }
    }

    setWorkflowId = (selectedWorkflowId) => {
        this.setState({ selectedWorkflowId })
    }

    addSpaceBetweenComma = (model) => {
        return model ? model.replace(/,/g, ', ') : model;
    }

    static getDerivedStateFromProps = (props) => ({ ...props });

    render() {
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible, internalLoader, eventLibrary, selectedNotification, sortColumn, success_modal, error_modal, workflowDropdown, workflowDetails, selectedWorkflowId } = this.state;

        const eventLibrarySort = eventLibrary;

        let columns = [
            { path: "", label: "#" },
            { path: "brand", labelStringID: "KC0062" },
            { path: "model", labelStringID: "KC0649" },
            { path: "equipment_type", labelStringID: "KC0857" },
            { path: "event_code", labelStringID: "KC0858" },
            { path: "event", labelStringID: "KC0859" },
            { path: "event_description", labelStringID: "KC0860" },
            { path: "", labelStringID: "KC0325" },
            { path: "", labelStringID: "KC0624" },
            { path: "", labelStringID: "KC0537" }
        ];
        let rowNumber = (this.state.currentPage * this.state.pageSize) - this.state.pageSize;
        let userProfileAuthFeature = authService.getFeature("/eventLibrary");
        let loggedInUserLevelID = localStorage.getItem('startLevelID');
        const isAuthorized = (loggedInUserLevelID == 1 || loggedInUserLevelID === "1") && userProfileAuthFeature.is_editable === 1;

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper" className="notification-library-wrapper withSearchBar">
                    <div className="notificationWrapper">
                        <div className="ntnLibraryHeader">
                            <ul>
                                <li>
                                    <Link to="#" className="active" title={ formatMessage({ id: 'KC0641' }) }><FormattedMessage id="KC0641"/></Link>
                                </li>
                            </ul>
                        </div>
                        <form className="ntnLibraryTableForm">
                            <div className="ntnLibraryTable">
                                <TableHeader
                                    sortColumn={sortColumn}
                                    onSort={this.onSort}
                                    columns={columns}
                                />
                            </div>
                            <div class="notificationlibrary">
                                {eventLibrary && !loaderIconVisible && eventLibrary.length === 0 ? <div className="noRecordMsgBlack">{this.state.searchData && eventLibrary && eventLibrary.length === 0 ? <FormattedMessage id="KC0853"/> : <FormattedMessage id="KC0205"/>}</div> : null}
                                <div class="ntnLibraryTable">
                                    {eventLibrarySort && eventLibrarySort.length ? eventLibrarySort.map((notification, index) => {
                                        const ID = notification.brand + '_' + notification.event_code + '_' + index;
                                        const resolution = notification.resolution_steps && notification.resolution_steps.length ? notification.resolution_steps[0].resolution_steps_level_1 && notification.resolution_steps[0].resolution_steps_level_1.split('||') ? notification.resolution_steps[0].resolution_steps_level_1.split('||') : [] : []
                                        const disabledState = !(notification.event_description || resolution.length || notification.video_url || notification.other_link_url.website_link);
                                        return (
                                            <ul className="tBody" key={ID}>
                                                <li>{rowNumber + index + 1}</li>
                                                <li>{notification.brand}
                                                    <span className="unitsListSM">{this.addSpaceBetweenComma(notification.model)}</span>
                                                    <span class="unitsListSML"><FormattedMessage id="KC0858"/>: <b>{notification.event_code}</b></span>
                                                </li>
                                                <li>{this.addSpaceBetweenComma(notification.model)}</li>
                                                <li>{notification.equipment_type || 'EQ'}</li>
                                                <li>{notification.event_code}</li>
                                                <li>{notification.event}</li>
                                                <li>{notification.event_name}</li>
                                                <li><button type="button" disabled={disabledState} data-toggle="modal" onClick={() => this.setState({ selectedNotification: { ...notification, resolution } })} data-target="#detailsViewModal" className="btn detailsBtn"></button></li>
                                                <li>
                                                    <button
                                                        type="button"
                                                        data-toggle="modal"
                                                        onClick={() => {
                                                            this.loadDropdown(notification);
                                                            this.setState({ selectedNotification: { ...notification, resolution } });
                                                        }}
                                                        data-target="#flowDetailsViewModal"
                                                        className="btn bellBtn">&nbsp;</button></li>
                                                <li>
                                                    <div className={`btn-group-toggle ${!isAuthorized ? 'disabled' : ''}`} data-toggle="buttons" disabled={!isAuthorized}>
                                                        <Switch
                                                            onChange={(e) => this.activeInactiveButton(isAuthorized, notification, resolution)}
                                                            checked={notification.subscribed && notification.subscribed === 1 ? true : false}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            onColor={'#c5cdd7'}
                                                            offColor={'#cbcbcb'}
                                                            offHandleColor={'#0d3968'}
                                                            onHandleColor={'#0d3968'}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            { this.state.totalPages && this.state.totalPages > 1 ? <GoToPagination
                                totalPageNumber={this.state.totalPages}
                                pageNumber={this.state.currentPage}
                                navigateTo={(p) => this.onPageChange(p)} /> : null }
                        </form>
                    </div>
                </div>
                {/* Active/Inactive confirmation modal */}
                <div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="confirmationCheckModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="singOutConfirmation">
                                    {selectedNotification.subscribed
                                        ? <FormattedMessage
                                            id="KC1694"
                                            values={{ eventCode: selectedNotification.event_code, brand: selectedNotification.brand, model: this.addSpaceBetweenComma(selectedNotification.model) }} />
                                        : <FormattedMessage
                                            id="KC1695"
                                            values={{ eventCode: selectedNotification.event_code, brand: selectedNotification.brand, model: this.addSpaceBetweenComma(selectedNotification.model) }} />}
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.setState({ selectedNotification: {} })}><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary signOutYesBtn" onClick={() => this.handleSubscription(selectedNotification)} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Content Modal */}
                <EventContentModal selectedNotification={selectedNotification} addSpaceBetweenComma={this.addSpaceBetweenComma} />
                {/* Content Modal End */}
                {/* <!-- Notification Flow Details Modal --> */}
                <NotificationFlowModal selectedNotification={selectedNotification} addSpaceBetweenComma={this.addSpaceBetweenComma} getBrandImage={this.getBrandImage} getUnitCount={this.getUnitCount} getBrandUnitImage={this.getBrandUnitImage} internalLoader={internalLoader} workflowDetails={workflowDetails} workflowDropdown={workflowDropdown} sketchNode={this.sketchNode} selectedWorkflowId={selectedWorkflowId} setWorkflowId={this.setWorkflowId} />
                {/* //Notification Flow Details Modal */}
                {/* <!-- Email Content Modal --> */}
                <EmailContentModal selectedNotification={selectedNotification} addSpaceBetweenComma={this.addSpaceBetweenComma} />
                {/* <!-- Email Content Modal --> */}
                {/* <!-- Org Modal --> */}
                <EventOrgModal selectedNotification={selectedNotification} addSpaceBetweenComma={this.addSpaceBetweenComma} getBrandImage={this.getBrandImage} getUnitCount={this.getUnitCount} getBrandUnitImage={this.getBrandUnitImage} getBrandId={this.getBrandId} workflowDropdown={workflowDropdown} selectedWorkflowId={selectedWorkflowId} />
                {/* <!-- //Org Modal --> */}
                <UserManagementHeader headerName="Automated Notification Management [Beta]" headerNameStringID="KC1696" activeClass="notificaMgmtNav" history={this.props.history} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ searchVisible, searchText }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EventLibrary));