import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from '../../service/api';
import http from '../../service/httpService';
import { brandLocations, brandUnits } from '../../utils/imgContants';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { CommonConfirmationModal } from './commonConfirmationModal';

var bg = require('../../images/unit/orgDefault.png')
const brandsLogo = brandUnits;
const brandsLocationLogo = brandLocations;

const formUnits = (units, detailsProps, selectedNode, parentNode) => {
    const unitStyle = { 'borderRadius': '4px' };
    const unitLinkStyle = { 'border': '2px solid #0aaeff' };
    return units.map((unit, index) => {
        let pickUnit = {};
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
            pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
            pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
        } else {
            pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
        }
        let unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
        unitInActive = "";
        let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
        let smartGroupName = [];
        if (smartGroupDet.length > 3) {
            smartGroupName.push({
                "sgName": "",
                "sgFirstName": smartGroupDet.length,
            })
        } else if (smartGroupDet.length > 0) {
            smartGroupDet.map((item, i) => {
                smartGroupName.push({
                    "sgName": item.name,
                    "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                })
            })
        }
        const selectedNodesMap = {};
        selectedNode.forEach((item) => selectedNodesMap[item.cust_gp_id] = true);
        const isSelected = parentNode.dynasty.split(",").find((id) => selectedNodesMap[id]);

        return <li key={index}>
            <div className='orgznSiteTreeLiDiv' style={unitStyle}>
                <Link to="#" className={isSelected && isSelected.length ? 'treeActive' : ''} style={isSelected && isSelected.length ? unitLinkStyle : {}}>
                    <div>
                        <span className="treeImg">
                            <img src={pickUnit ? pickUnit.img : bg} alt="" />
                        </span>                                
                        <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                        <span className="treeCountDispaly" >{units.length || ''} </span>
                        <span className={unitInActive}></span>
                        {/* {smartGroupName.map((item, i) => {
                            return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                        })} */}
                    </div>
                </Link>
            </div>
        </li>
    });
}


const dirtyCheckOrgEdit = (selectedNodes, detailsProps) => {
    let updatedNodes = selectedNodes;
    let existingNodes = detailsProps.ASSIGNED_CUSTOMER_GROUP;

    if (updatedNodes.length === 0) {
        return false
    }

    // compare updatedNode and existingNode
    if (updatedNodes.length == existingNodes.length) {
        let compareCustomerGroups = _.difference(_.map(updatedNodes, 'cust_gp_id'), _.map(existingNodes, 'cust_gp_id'));
        if (compareCustomerGroups.length > 0) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

const formOrgStructure = (linkedList, isInitial, ID = '', detailsProps, selectedNode, setNode, isOrgDirty, setOrgDirty) => {
    if (!linkedList || !linkedList.length) return null;
    return (
        <ul class={isInitial ? '' : "collapse show"} id={isInitial ? '' : ID}>
            {linkedList.length && linkedList.map((linkedLevelValue, levelIndex) => {
                let level = linkedLevelValue.level_id;
                const nextLevel = level + 1;
                const id = linkedLevelValue.cust_gp_id + linkedLevelValue.parent_group_id + linkedLevelValue.category_id + linkedLevelValue.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                let picLocation;
                const location_type_Select = JSON.parse(linkedLevelValue.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                const selectedNodesMap = {};
                selectedNode.forEach((item) => selectedNodesMap[item.cust_gp_id] = true);
                const isSelected = linkedLevelValue.dynasty.split(",").find((id) => selectedNodesMap[id]);


                return (
                    <li key={linkedLevelValue.group_name + levelIndex}>
                        <div class="orgznSiteTreeLiDiv">
                            <Link onClick={() =>  {
                                let result = selectedNode || [];
                                const isExistCustomerGroup = result.filter((i) => i.cust_gp_id === linkedLevelValue.cust_gp_id);
                                
                                if (isExistCustomerGroup && isExistCustomerGroup.length === 0) {
                                    // to remove selected child nodes when parent selected
                                    result = result.filter((item) => {
                                        return item.dynasty.indexOf(linkedLevelValue.dynasty) < 0
                                    })
                                    // to remove selected parent node when its children node selected again
                                    result = result.filter((item) => {
                                        return linkedLevelValue.dynasty.indexOf(item.dynasty) < 0
                                    })

                                    const obj = {
                                        group_name: linkedLevelValue.group_name,
                                        cust_gp_id: linkedLevelValue.cust_gp_id,
                                        dynasty: linkedLevelValue.dynasty
                                    }
                                    result.push(obj);
                                    setNode(result)
                                } else {
                                    let result = selectedNode || [];
                                    
                                    const categoryKey = result.findIndex((e) => e.cust_gp_id === linkedLevelValue.cust_gp_id);
                                    result.splice(categoryKey, 1);
                                    setNode(result)
                                }
                                let isDirty = dirtyCheckOrgEdit(result, detailsProps)
                                setOrgDirty(isDirty);
                            }} to="#" className={(linkedLevelValue.level_id === 5 && linkedLevelValue.units && linkedLevelValue.units.length > 0)
                                || (linkedLevelValue['level' + nextLevel] && linkedLevelValue['level' + nextLevel].length > 0)
                                ? `treeNodeAvailable ${isSelected && isSelected.length ? "treeActive" : ""}` : `treeNodeAvailableNoLast  ${isSelected && isSelected.length ? "treeActive" : ""}`}>
                                <div>
                                    <span className="treeImg">
                                        <img src={linkedLevelValue.imagePath ? linkedLevelValue.imagePath : picLocation ? picLocation.img : bg} alt="" />
                                    </span>
                                    <span className="treeText deviceModel">
                                        <b>{linkedLevelValue.group_name || ''}</b>
                                    </span>
                                </div>
                            </Link>
                            {linkedLevelValue && linkedLevelValue['level' + nextLevel] ?
                                <React.Fragment>
                                    <button class="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                    {formOrgStructure(linkedLevelValue['level' + nextLevel], false, leveClassName, detailsProps, selectedNode, setNode, isOrgDirty, setOrgDirty)}
                                </React.Fragment>
                                : level === 5 && linkedLevelValue.units && linkedLevelValue.units.length ?
                                    <React.Fragment>
                                        <button class="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                        <ul className="collapse show" id={leveClassName}>
                                            {formUnits(linkedLevelValue.units, detailsProps, selectedNode, linkedLevelValue)}
                                        </ul>
                                    </React.Fragment>
                                    : null}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}


const setDynasty = (nodes, selectedNodesMap) => {
    const nodesLength = nodes.length;
    for (let i = 0; i < nodesLength; i++) {
        const node = nodes[i];
        const level = node.level_id;
        if (selectedNodesMap[node.cust_gp_id]) {
            selectedNodesMap[node.cust_gp_id] = node.dynasty;
        }
        if (node.child_count > 0) {
            const childNodes = node[`level${level + 1}`];
            childNodes && setDynasty(childNodes, selectedNodesMap);
        }
    }
}

const getSelectedNodes = (detailsProps, responseData) => {
    var groupId
    const custGrp = detailsProps.ASSIGNED_CUSTOMER_GROUP;

    const selectedNodesMap = {};
    custGrp.forEach((item) => selectedNodesMap[item.cust_gp_id] = true);
    setDynasty(responseData.level1, selectedNodesMap);
    groupId = custGrp.map(function (item) {
        return { group_name: item.group_name, cust_gp_id: item.cust_gp_id, dynasty: selectedNodesMap[item.cust_gp_id] };
    });
    return groupId;
}

const CommonOrgViewEditTopology = ({ detailsProps, onSave, onClose }) => {
    const [linkList, setLinkList] = useState({});
    const [loader, setLoader] = useState(true);
    const [selectedNode, setNode] = useState([]);
    const [isOrgDirty, setOrgDirty] = useState(false)

    const updateDimensions = () => {
        var windowHeight = $(window).height();
        var modalOrgViewHeight = ((windowHeight) - 150);
        $(".modalOrgView").css("height", modalOrgViewHeight);
    }


    useEffect(() => {

        updateDimensions();
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let modelFamilyIds = detailsProps.MODEL_FAMILY.map(e=>e.ID);
        let headerVal = {
            "Cgids": localStorage.getItem('custGroupID'),
            'Content-Type': 'application/json',
            'useremail': loggedinUser,
            'basecountry': localStorage.getItem('basecountry'),
            'modelFamilyIds': modelFamilyIds ? modelFamilyIds : '',
            'brandids': detailsProps.BRAND_ID || ''
        }
        http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: headerVal, data: {},
        }).then(async response => {
            let responseData = null;
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, async (data) => {
                    await setLinkList(data);
                    responseData = data;
                    setLoader(false);
                });
            }
            else {
                await setLinkList(response.data);
                responseData = response.data;
                setLoader(false);
            }

            let groupId = getSelectedNodes(detailsProps, responseData);
            setNode(groupId)
        }).catch(err => {
            setLoader(false);
            console.log("Failed", err)
        });
    }, []);

    const onSaveAction = () => {
        $('#orgEditConfirmationModal').modal("show");
    }

    const onClickClose = (detailsProps, responseData, setNode) => {
        let groupId = getSelectedNodes(detailsProps, responseData);
        setNode(groupId);
        $('#orgViewModal').modal('hide');
        onClose();        
    }


    const onCancelPopup = (setOrgDirty) => {
        setOrgDirty(false)
    }

    const onConfirmPopup = (selectedNodes,isOrgDirty) => {
        $('#orgViewModal').modal("hide");
        onSave({selectedNodes,isOrgDirty});
        onClose();
    }

    return (
        <React.Fragment>
            <div class="modal fade orgViewModal" data-keyboard="true" data-backdrop="static" id="orgViewModal" tabindex="-1" role="dialog" aria-labelledby="orgViewModalLabel">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ minHeight: $(window).height() - 60 }}>
                        <div class="modal-header modal-header-danger">
                            Edit Assign Nodes to File {isOrgDirty ? <button id="orgModalFinish" type="button" class="btn finish" data-toggle="modal" data-target="#orgEditConfirmationModal" onClick={() => onSaveAction()} aria-label="Save"><FormattedMessage id='KC0123'/></button> : <button id="orgModalClose" type="button" class="close" data-dismiss="modal" onClick={() => onClickClose(detailsProps, linkList,setNode)} aria-label="Close">&nbsp;</button>}
                        </div>
                        <div class="modal-body">
                            <React.Fragment>
                                <div id="page-loader" className={loader ? "loadingProgress showloader" : "loadingProgress"}>
                                    <div className="loadingProgressIn"></div>
                                </div>
                                <div class="orgznSiteTreeOuter1 modalOrgView">
                                    <div class="orgznSiteTree">
                                        {linkList && linkList.level1 && formOrgStructure(linkList.level1, true, '', detailsProps, selectedNode, setNode, isOrgDirty, setOrgDirty)}
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <CommonConfirmationModal
                 onCancel={() => onCancelPopup(setOrgDirty)} 
                 onConfirm={() => onConfirmPopup(selectedNode, isOrgDirty)}
                 modalText={'KC2281'} />
        </React.Fragment>
    );
}

export default CommonOrgViewEditTopology;