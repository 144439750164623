import http from '../../../service/httpService';
import authService from '../../../service/authService';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TableHeader } from '../../controls/tableHeader';
import LoadingPopup from '../../controls/loadingPopup';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { userRoleURI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import NoRecordsFound from '../../controls/noRecordsFound';
import authConstants  from '../../../utils/authConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage,injectIntl } from 'react-intl';
import $ from 'jquery';

const { allFeaturesKey }  = authConstants;
const rolesImages = [
	{ name: "Customer Admin", img: require("../../../images/users/owner.svg").default },
	{ name: "Crem System Admin", img: require("../../../images/users/owner.svg").default },
	{ name: "Service Technician", img: require("../../../images/users/service.svg").default },
	{ name: "1st Line User", img: require("../../../images/users/leaser.svg").default },
	{ name: "2nd Line User", img: require("../../../images/users/chef.svg").default },
	{ name: "Crem User", img: require("../../../images/users/manager.svg").default }
]

class UserManagementRoles extends Component {

	constructor(props) {
		super(props);
		this.state = {
			rolesList: [],
			customerId: localStorage.getItem("customerId"),
			corporateRoles: [],
			customRoles: [],
			selectAll: false,
			checked: [],
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			isAddRoleActive: true,
			isEditRoleActive: false,
			isDeleteRoleActive: false,
			isSelectAllRoles: false,
			isIndeterminateSelect: false,
			deleteRoleConfirmMessage: "",
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			userRoleFlag: true,
			searchData: null,
		}
		this.handleChange = this.handleChange.bind(this);
		this.getUserRoleList = this.getUserRoleList.bind(this);
	}

	updateDimension () {
		var windowHeight = $(window).height();
		var userMgmtHeader = $(".userMgmtHeader").height(); 
		var userMgmtListTableH = $(".userMgmtRolesTable .tHead").height();  
		var userMgmtTableSrlBarH = ((windowHeight) - (userMgmtHeader + userMgmtListTableH)) - 70;
		$(".userMgmtTableSrlBar").css("height", userMgmtTableSrlBarH);
	}
 
	componentDidMount = async () => {
		this.props.searchVisible(true);
		window.addEventListener('resize', this.updateDimension());
		this.setState({ loaderIconVisible: true });
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			await this.getUserRoleList();
		} catch (error) {
			//Error Goes Here
		}
	}

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	editRole = () => {

		let selectedRoles = this.state.corporateRoles.filter(item => item.checked === true);
		let role = selectedRoles && selectedRoles.length > 0 ? selectedRoles[0] : null;
		if (role) {
			this.props.history.push({
				pathname: `userrole/${role.Id}`
			});
		}
	}

	getUserRoleList = async () => {
		let {searchData} = this.state;
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let loginFeatureInfo = JSON.parse(localStorage.getItem(allFeaturesKey));
		let loggedinUserRoleId = loginFeatureInfo[0].role_id;
		let brandIds = BRAND_ID;
		let url = userRoleURI.getRolesByCustomer + localStorage.getItem("customerId") + "&user_email=" + loggedinUser+"&loginUserRoleid="+loggedinUserRoleId +"&brandIds="+brandIds +"&listType=true&refreshCache=" + new Date().getTime();

		//searchData_function
		let _search = searchData_function(searchData);
		if( _search ){
			url = `${url}${_search}`;
		}

		this.setState({ loaderIconVisible: true });

		http.get(url, {
			headers: {
				'Content-Type': 'application/json'
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let { data: rolesList } = response.data
				let rolemgtlist = [];
				rolemgtlist = rolesList.filter(r => r.role_name !== 'Customer Admin');
				rolemgtlist = rolemgtlist.filter(r => r.role_name !== 'Merrychef Admin');
				rolemgtlist.forEach((item, i) => {
					item.order_id = ++i;
					let pickRole = rolesImages.find(r => r.name === item.role_name);
					if (pickRole != null) {
						item.img = pickRole.img;
					}
					else if (pickRole === undefined) {
						item.img = require("../../../images/users/user.png");
					}
				});
				this.setState({ corporateRoles: rolemgtlist, loaderIconVisible: false,isSelectAllRoles: false, isIndeterminateSelect: false });
			}
		}).catch(err => {
			//Roles not get from server
			this.setState({ loaderIconVisible: false });

		});	

	}

	handleChange = (e) => {
		var rolesList = [...this.state.corporateRoles];
		rolesList.forEach(r => {
			r.checked = e.target.checked;
		});
		this.isSelectEditDeleteRole(rolesList);
	};

	onSelectRole = (e, item) => {
		if (this.state.corporateRoles && this.state.corporateRoles.length > 0) {
			const rolesList = [...this.state.corporateRoles];
			const index = rolesList.indexOf(item);
			rolesList[index] = { ...rolesList[index] };
			rolesList[index].checked = e.target.checked;
			this.isSelectEditDeleteRole(rolesList);
		}
	}

	isSelectEditDeleteRole = (rolesList) => {
		let selectedRoles = rolesList.filter(item => item.checked === true);
		let isEdit, isDelete, isAdd;


		isEdit = selectedRoles.length > 1 || selectedRoles.length === 0 ? false : true;
		isDelete = selectedRoles.length > 0 ? true : false;
		isAdd = selectedRoles.length > 0 ? false : true;

		function getCheckBoxstate() {
			if (selectedRoles.length == rolesList.length) {
			  return { isSelectAllRoles: true, isIndeterminateSelect: false };
			} else if (selectedRoles.length == 0 && rolesList.length != 0) {
			  return { isSelectAllRoles: false, isIndeterminateSelect: false };
			} else if (rolesList.length >= selectedRoles.length) {
			  return { isSelectAllRoles: true, isIndeterminateSelect: true };
			}
		}

		this.setState({
			isEditRoleActive: isEdit,
			isDeleteRoleActive: isDelete,
			corporateRoles: rolesList,
			isAddRoleActive: isAdd,...getCheckBoxstate()
		});
	}

	loadingPopupShowHide = (isActive) => {
		this.setState({
			loaderIconVisible: isActive
		});
	}

	deleteRoles = () => {

		this.loadingPopupShowHide(true);
		this.setState({
			deleteRoleConfirmMessage: "KC1071"
		});

		let selectedRolesList = this.state.corporateRoles.filter(item => item.checked === true);
		let requestObject = {
			roleIds: selectedRolesList.map(item => item.Id),
			customer_id: this.state.customerId
		}

		http({
			method: "DELETE",
			url: userRoleURI.deleteAPI,
			data: requestObject,
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			let { data } = response;
			let newRoles = [...this.state.corporateRoles];
			if (!data.data.isRoleAlreadyAssigned) {
				for (let i = 0; i < selectedRolesList.length; i++) {
					for (let k = 0; k < newRoles.length; k++) {
						if (newRoles[k].Id === selectedRolesList[i].Id) {
							newRoles.splice(k, 1);
						}
					}
				}
			}
			const { formatMessage } = this.props.intl;
			let message = data.data.isRoleAlreadyAssigned ? formatMessage({ id: 'KC1072' }, { roleNames: selectedRolesList.map(i => i.role_name).join(',') }) : (data.data.stringID || data.data.message);
			this.setState({
				isAddRoleActive: true,
				isDeleteRoleActive: data.data.isRoleAlreadyAssigned ? true : false,
				isEditRoleActive: false,
				corporateRoles: newRoles,
				loaderIconVisible: false,
				success_modal: {
					message: message,
					open: true
				},
				isSelectAllRoles: false,
				isIndeterminateSelect: false
			});
			WoopraEvents(`${Constants.DELETE_ROLE}`);
		}).catch(error => {
			let errorResponse = error.response;
			if (errorResponse.status === 400 || errorResponse.status === 500) {
				let responseMessage = errorResponse.data["KC_ERR_400"] ? errorResponse.data["KC_ERR_400"] : errorResponse.data["KC_ERR_500"] ? errorResponse.data["KC_ERR_500"] : "";
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						message: responseMessage,
						open: true
					}
				});
			}
			else {
				this.loadingPopupShowHide(false);
			}
			this.loadingPopupShowHide(false);
		});

	}

	successCloseModel = () => {
		this.setState({
			success_modal: {
				message: "",
				open: false
			}
		});
	}

	errorEloseModel = () => {
		this.setState({
			error_modal: {
				message: "",
				open: false
			}
		})
	}

	

	componentDidUpdate ( prevProps, prevState ) {

		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj(searchData, curSearchData)  === false) ) {
			this.getUserRoleList();
		}
	}

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${Constants.USER_MANAGEMENT}-${e.trackTitle}`);
	}

	
	static getDerivedStateFromProps = (props) => ({...props});

	render() {
		let {
			corporateRoles,
			sortColumn,
			loaderIconVisible,
			isEditRoleActive,
			isDeleteRoleActive,
			isSelectAllRoles,
			isIndeterminateSelect,
			success_modal,
			error_modal,
			isAddRoleActive,
			rolesList } = this.state;
		let orderId = 1;
		let userProfileAuthFeature = authService.getFeature("/userrole");
		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "role_name", labelStringID:'KC0615'},
			{ path: "", label: "", isNbSp: true }, 
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];
		if( corporateRoles ) {
			corporateRoles = _.orderBy(corporateRoles, [corporateRoles => corporateRoles.role_name && corporateRoles.role_name.toString().toLowerCase()], [sortColumn.order]);

			corporateRoles.forEach(item => {
				if ( item ) {
					item.order_id = orderId++;
				}
			});
		}
		const { formatMessage } = this.props.intl;
		return (
			<div id="adminWrapper">
				<LoadingPopup loading={loaderIconVisible} />
				<div id="page-content-wrapper">
					<div className="userManagementWrapper"> 
							<div className="userMgmtHeader">
								<ul>
									<li>
										<Link to="/userManagementList" onClick={(e)=> {e.trackTitle="User List";this.trackEvent(e)}} title={formatMessage({ id: "KC0592"})}><FormattedMessage id={'KC0592'} /></Link>
										<Link to="/userManagementRoles" className="active"  title={formatMessage({ id: "KC0585"})}> <FormattedMessage id={'KC0585'} /></Link>
									</li>
									<li>
										<Link to="#" onClick={() => this.editRole()} className={userProfileAuthFeature.is_editable === 1 && isEditRoleActive ? "btn btn-default-text userEdit" : "btn btn-default-text userEdit disabled"} title={formatMessage({ id: "KC0617"})}>&nbsp;</Link>
										<Link to="#" className={userProfileAuthFeature.is_editable === 1 && isDeleteRoleActive ? "btn btn-default-text signOutBtn userDelete" : "btn btn-default-text userDelete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" title={formatMessage({ id: "KC0618"})}>&nbsp;</Link>
										<Link to="/userrole" className={userProfileAuthFeature.is_editable === 1 && isAddRoleActive ? "btn btn-default-text userAdd" : "btn btn-default-text userAdd disabled"} title={formatMessage({ id: "KC0616"})}>&nbsp;</Link>
									
									</li>
								</ul>
							</div>
							<form className="userMgmtRolesForm">
							<div className="userMgmtRolesTable">
							<TableHeader
									userRoleScreen = {this.state.userRoleFlag}
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isIndeterminate={isIndeterminateSelect}
									isSelectAll={isSelectAllRoles} />
							</div> 
							<div class="userMgmtTableSrlBar">
							<div className="userMgmtRolesTable">
								{
									corporateRoles.map((item, i) => (
										<ul className="tBody" key={i}>
											<li>{item.order_id}</li>
											<li><span className="userDP"><img src={item.img} alt=""/></span></li>
											<li>{item.role_name}</li>
											<li>&nbsp;</li> 
											<li>
												<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i} name={i} type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
											</li>
										</ul>
									))
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={corporateRoles !== undefined ? corporateRoles.length : 0} classname={'norecordsfoundbluetext'} stringID={this.state.searchData && corporateRoles && corporateRoles.length === 0 ? "KC0853" : "KC0205"}/>
							</div>
						</form>
					</div>
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >

								<div className="modal-body">
									<div className="singOutConfirmation">
									<FormattedMessage id="KC1071"/>
                            	</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button"><FormattedMessage id={'KC0021'} /></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteRoles()} data-dismiss="modal" type="button"><FormattedMessage id={'KC0611'} /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.successCloseModel()} />
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.errorEloseModel()} />
				<UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav"  />
			</div>
		)
	}
};



function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		searchVisible, searchText
	}, dispatch);
}

function mapStateToProps(state) {
	return {
		checked: state.checkedCopy,
		selectAll: state.selectAll,
		searchData: state.advanceSearch.searchData,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagementRoles));
