import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import GenericModal from '../../modal/generic-model';
import { connect } from 'react-redux';
import NoRecordsFound from '../../controls/noRecordsFound';
import { ReportManagement } from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import authService from '../../../service/authService';
import { TableHeader } from '../../controls/tableHeader';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import {ReportDetailsModal} from './reportDetailsModal';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import $ from 'jquery';
import { FormattedMessage, injectIntl } from 'react-intl';

class reportList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			reportViewerUrl: [],
			isSelectAllReports: false,
			s3KeyVal: "",
			generic_modal: {
                open: false,
                message: "",
                messagetype: ""
            },
			selectAll: false,
			isDownloadReport: false,
			isDeleteReport: false,
			isDeleted: false,
			isAddReport: true,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			isIndeterminate: false,
			currentPage : 1,
			pageSize: 25,
            totalPages:null,
			reportItem: {},
			searchData: null,
		};
		this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
		this.deleteSelectedReport = this.deleteSelectedReport.bind(this);
		this.OnReportDetailsClick = this.OnReportDetailsClick.bind(this);
		this.OnDelayedModalClick = this.OnDelayedModalClick.bind(this);
		this.OnAddReport = this.OnAddReport.bind(this);
	}

	componentDidMount() {
		$("div").removeClass("modal-backdrop fade show");
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener('resize', this.updateDimensions());
		this.getReportList();
		localStorage.setItem("selectedbrandID", "0");
		localStorage.setItem("selectedGroupID", "");
		localStorage.setItem("selectedUnitID", "[]");
	}

	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	componentDidUpdate ( prevState ) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getReportList();
		}
	}

	updateDimensions() {
		var windowHeight = $(window).height();
		var reportHeader = $(".reportHeader").height();
		var reportTableH = $(".reportListTable .tHead").height();
		var reportListTableSrlBarH = ((windowHeight) - (reportHeader + reportTableH)) - 70;
		$(".reportListTableSrlBar").css("height", reportListTableSrlBarH);
	}

	onGenericCloseModal = (e, messagetype) => {
        this.setState({
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			},
		});
    }

	deleteSelectedReport = () => {
		this.setState({
			loaderIconVisible: true
		})
		let { data } = this.state;
		let selectedIds = [];
		data.forEach(e => {
			if (e.checked === true) {
				selectedIds.push(e.id);
			}
		});

		selectedIds = selectedIds ? selectedIds.join(',') : "";
		const headers = {'reportids' : selectedIds};
		this.putData(headers, {}, ReportManagement.deleteReport, 'DELETE').then(response => {
			if (response && response.data && response.data.status) {
				let reportdata = [...this.state.data];
				let reportList = reportdata.filter(item => item.checked !== true);
				this.setState({
					loaderIconVisible: false,
					isDownloadReport: false,
					isDeleteReport: false,
					isSelectAllReports: false,
					isAddReport: true,
					data: reportList,
					generic_modal: {
						open: true,
						message: "KC2120",
						messagetype: "Success"
					}
				});
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { status, message } = data;
				this.openErrorModal(status ? status : message);
			}
		} else {
			this.openErrorModal("KC1065");
		}
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			generic_modal: {
				open: true,
				message,
				messagetype: "Error"
			}
		});
	}

	putData = (headers, postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: headers,
				data: postData
			}).then(res => {
				let { data } = res;
				let { status } = data;
				if (!status) {
					reject({
						response: {
							data: "KC2159"
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getReportList = async () => {
		this.setState({ loaderIconVisible: true });
		let { searchData } = this.state;
		let url = ReportManagement.reportLibrary;

		//searchData_function
		let _search = await searchData_function(searchData);

		if( _search ){
			url = `${url}?${_search}`;
		}

		const reportsList = await this.reportListApi(url);
		this.setState({
			loaderIconVisible: false,
			data: reportsList ? reportsList : []
		});
	}

	reportListApi = async (url) => {
		let headerValue = {
			'Content-Type': 'application/json',
			'brandid': '',
			'customerid': localStorage.getItem("customerId"),
			'basecountry': localStorage.getItem("basecountry"),
			'is_scheduled': 0,
			'cgids': localStorage.getItem("custGroupID"),
			'userId':localStorage.getItem("user"),
		};		
		return http.get(url, {
			headers: headerValue, data: {}
		}).then(response => {
			if (response && response.data) {
				let reportsList = response.data.data;
				this.setState({ totalPages: response.data.numberOfPages });
				return reportsList;
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false,totalPages : 0 });
		});
	}

	handleChange = (e) => {
		var reportsList = [...this.state.data];
		reportsList.forEach(r => {
			r.checked = e.target.checked;
		});
		this.isSelectEditDownload(reportsList, '');
	};

	onSelectReport = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const reportsList = [...this.state.data];
			const index = reportsList.findIndex(el => el.id === item.id);
			reportsList[index] = { ...reportsList[index] };
			reportsList[index].checked = e.target.checked;
			let item_status = reportsList[index].report_status;
			this.setState({
				s3KeyVal: reportsList[index].s3_key
			}, () => this.isSelectEditDownload(reportsList, item_status))
		}
	}

	checkDeleteAccess = async (media) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
		for (let i = 0; i < media.length; i++) {
			isDelete = true;
			let mediaValue = media[i];
			if(mediaValue.createdBy && mediaValue.createdBy.toString().match('@')){
				isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.createdBy) || username === mediaValue.createdBy ? true : false;
			}else{
				isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
			}
			if (isDelete === false){
				return isDelete;
			}
		}
		return isDelete;
	}

	isSelectEditDownload = async (reportsList, item_status) => {
		let selectedReports = reportsList.filter(item => item.checked === true);
		let isDownload, isDelete, isAdd, isIndeterminate, isSelectAllReports;

		isDownload = selectedReports.length === 1 && item_status === "Completed" ? true : false;
		isDelete = selectedReports.length > 0 ? true : false;
		isAdd = selectedReports.length > 0 ? false : true;
		
		if(selectedReports.length === reportsList.length) {
			isSelectAllReports= true; isIndeterminate = false;
		} else if(selectedReports.length === 0 && reportsList.length !==0){
			isSelectAllReports= false; isIndeterminate = false;
		} else if(reportsList.length > selectedReports.length) {
			isSelectAllReports= true; isIndeterminate = true;
		} else {
			isSelectAllReports= false; isIndeterminate = false;
		}

		this.setState({
			isDownloadReport: isDownload,
			isDeleteReport: isDelete,
			isSelectAllReports: isSelectAllReports,
			isAddReport: isAdd,
			data: reportsList,
			isIndeterminate: isIndeterminate
		});
	}

	downloadLogViewer = (urls) => {
		if (urls !== '#') {
			window.location = urls;
			this.setState({
				loaderIconVisible: false,
				generic_modal: {
					open: true,
					message: "KC1578",
					messagetype: "Success"
				},
			});
			WoopraEvents(`${Constants.DOWNLOAD_THE_REPORT_LOG}`)
		}
	}

	getDownloadURL = async () => {
		this.setState({ loaderIconVisible: true });
		let url = ReportManagement.reportDownload;
		let selectedReportsList = this.state.data.filter(item => item.checked === true);
		let primaryIDs = selectedReportsList.map(item => item.id);

		await http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'id': primaryIDs
			}, data: {}
		}).then(response => {
			if (response && response.data && response.data.body) {
				this.setState({
					reportViewerUrl: response.data.body,
				});
				this.downloadLogViewer(response.data.body.s3_url);
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				generic_modal: {
					open: true,
					message: "KC1579",
					messagetype: "Error"
				},
			});
			WoopraEvents(`${Constants.DOWNLOAD_THE_REPORT_LOG_FAILED}`);
		});
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true, currentPage },()=>{
			this.getReportList();
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

	// Woopra Events
	emailReportConfig = () => {
		WoopraEvents(`${Constants.EMAIL_REPORT_CONFIGURATION}`);
	}

	static getDerivedStateFromProps(props) {
		return { ...props };
	}

	OnReportDetailsClick = (item) => {
		localStorage.setItem("selectedGroupID", item.cgids);
		localStorage.setItem("selectedbrandID", item.brand_id);
		localStorage.setItem("pageType", "View");
		let unitids = item.unitids ? item.unitids.split(',') : [];
        let selectedunitidsarr = [];
        for (let x = 0; x < unitids.length; x++) {
            selectedunitidsarr.push({ID : unitids[x], isNodeSelected : false});
        }
		localStorage.setItem("selectedUnitID", JSON.stringify(selectedunitidsarr));
		this.setState({
			reportItem: item
		});
	}

	OnDelayedModalClick = (item) => {
		if (item.statusText === 2) {
			this.setState({
				delayedMessage: "KC2186"
			});
		}
		else {
			this.setState({
				delayedMessage: "KC2187"
			});
		}
	}

	OnAddReport = () => {
		localStorage.setItem("pageType", "Add");
		localStorage.setItem("selectedbrandID", "");
		this.props.history.push({
			pathname: "/newReportWithOptions",
			state: { 
				reportType: 0,
			}
		});
	}

	render() {
		let { isDeleted, generic_modal, loaderIconVisible, data, isDownloadReport, isSelectAllReports, isAddReport, isDeleteReport, sortColumn, isIndeterminate, currentPage, pageSize, reportItem, delayedMessage } = this.state;
		let userProfileAuthFeature = authService.getFeature("/reportList");
		const { formatMessage } = this.props.intl;
		let separator = localStorage.getItem("thousandSeparator");
		let orderId = 1;
		let columns = [];
		columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102" },
			{ path: "name", labelStringID: "KC0241" },
			{ path: "date_created", labelStringID: "KC0762" },
			{ path: "size", labelStringID: "KC2199" },
			{ path: "brand_name", labelStringID: "KC0062" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		data = _.orderBy(data, [sortColumn.path], [sortColumn.order]);

		data.forEach(item => {
			item.order_id = orderId++
		});

		if (isDeleted && isDeleted.message) {
			generic_modal = { open: true, message: isDeleted.message };
		}
		let rowNumber = (currentPage * pageSize) - pageSize;   
		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="reportWrapper">
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/reportList" title={formatMessage({id: 'KC2114'})} className="active"><FormattedMessage id='KC2114'/></Link>
									<Link to="/scheduledReportList" title={formatMessage({id: 'KC2115'})}><FormattedMessage id='KC2115'/></Link>
								</li>
								<li>
									<button data-toggle="modal" data-target="#confirmationDownloadModal" type="button" title={formatMessage({ id: "KC0699"})} className={userProfileAuthFeature.is_editable === 1 && isDownloadReport ? "btn download" : "btn download disabled"}><span class="btnIcon downloadLogbook"></span></button>
									<button type="button" title={formatMessage({ id: "KC0524"})} className={userProfileAuthFeature.is_editable === 1 && isDeleteReport ? "btn delete" : "btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
									<button type="button" title={formatMessage({ id: "KC2172"})} className={userProfileAuthFeature.is_editable === 1 && isAddReport ? "btn add" : "btn add disabled"} onClick={() => this.OnAddReport()}>&nbsp;</button>
								</li>
							</ul>
						</div>
						<form className="reportListForm">
							<div className="reportListTable">
								<TableHeader
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isSelectAll={isSelectAllReports}
									isIndeterminate={isIndeterminate} />
							</div>
							<div className="reportListTableSrlBar">
								<div className="reportListTable">
									{data && data.map((item, i) => (
										<Fragment key={item.id}>
											<ul className="tBody" key={item.id}>
												<li>{rowNumber + i + 1}</li>
												<li><span class={item.is_scheduled ? "reportType scheduleReport" : "reportType"}><img src={require("../../../images/file/iconExcelReport.svg").default} alt="" /></span></li>
												<li><div class="reportListBSM">{item.brand_name}</div>{item.name}{item.filetype}<div class="reportListSM">{localStorage.getItem("timeFormat") === "24" ? 
												<li>{item.date_created ? moment.ConvertLocalTimeFromUTCTime(item.date_created, "MMM DD, YYYY HH:mm") : ""}</li>
												: <li>{item.date_created ? moment.ConvertLocalTimeFromUTCTime(item.date_created, "MMM DD, YYYY hh:mm A") : ""}</li>}</div></li>
												{item.report_status === "pending" ? <li>In progress</li> : item.report_status === "Failed" ? <li>...</li> : localStorage.getItem("timeFormat") === "24" ? 
												<li>{item.date_created ? moment.ConvertLocalTimeFromUTCTime(item.date_created, "MMM DD, YYYY HH:mm") : ""}</li>
												: <li>{item.date_created ? moment.ConvertLocalTimeFromUTCTime(item.date_created, "MMM DD, YYYY hh:mm A") : ""}</li>}
												<li>{item.report_status !== "Completed" ? "..." : item.size ? separator === "." ? item.size.toString().replace('.', ',') : item.size  : separator === "." ? "0,00" : "0.00"}</li>
												<li>{item.brand_name}</li>
												<li>{item.report_status === "Completed" ? <button type="button" className="btn reportDetails" data-toggle="modal" data-target="#reportDetailsModal" onClick={() => this.OnReportDetailsClick(item)}>&nbsp;</button> : (item.report_status === "pending") ? <button type="button" class="btn scheduleDetails" data-toggle="modal" data-target="#scheduledModal">&nbsp;</button> : <button type="button" class="btn delayedDetails" data-toggle="modal" data-target="#delayedModal" onClick={() => this.OnDelayedModalClick(item)}>&nbsp;</button>}</li>
												<li>
													<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
														<input id={item.id} name={item.id} type="checkbox"
															checked={item.checked}
															onChange={(e) => this.onSelectReport(e, item)} />
														<label htmlFor={item.id}></label>
													</div>
												</li>
											</ul>
										</Fragment>
									))}
								</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={data !== undefined ? data.length : 0} classname={'norecordsfoundbluetext'} stringID={this.state.searchData && data && data.length === 0 ? "KC0853" : "KC0205"} />
							</div>
						</form>
					</div>
				</div>
				<div className="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">  
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2174"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div class="modal-body"> 
								<div class="reportStatusModal"> 
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconDeleteReport.svg").default} /></span>
									<h6><FormattedMessage id="KC2175"/></h6>  
								</div>   
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button">No</button>
								<button className="btn btn-secondary deleteYesBtn" data-dismiss="modal" onClick={() => this.deleteSelectedReport()} type="button">Yes</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="confirmationDownloadModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDownloadModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
						<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2176"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div className="modal-body">
								<div className="reportStatusModal">
									<span class="rmStatusIcon"><img src={require("../../../images/icons/downloadA.png")} /></span>
									<h6><FormattedMessage id="KC2198"/></h6>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button">No</button>
								<button onClick={(e) => { this.getDownloadURL(e) }} class="btn btn-secondary deleteYesBtn" id="downloadYesBtn" data-dismiss="modal" type="button">Yes</button>
							</div>
						</div>
					</div>
				</div>
				<ReportDetailsModal reportDetails={reportItem ? reportItem : []} page={'reportList'} />
				<GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} onGenericCloseModal={(e) => this.onGenericCloseModal(e, generic_modal.messagetype)} />
				<UserManagementHeader headerNameStringID='KC1000' headerName="Report Management" activeClass="reportNav" history={this.props.history} />
				<div class="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="delayedModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRMModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">  
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2165"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div class="modal-body"> 
								<div class="reportStatusModal"> 
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconReportGenerationFailed.svg").default} /></span>
									<h6>{delayedMessage && <FormattedMessage id={delayedMessage}/>}</h6>  
								</div> 
							</div>
						<div class="modal-footer textAlignCenter">
							<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0777"/></button>
							</div>
						</div>
					</div>
				</div>
				<div class="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="scheduledModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRMModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">  
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2166"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div class="modal-body"> 
								<div class="reportStatusModal"> 
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconReportInProgress.svg").default} /></span>
									<h6><FormattedMessage id="KC2163"/></h6>  
								</div> 
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0777"/></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			searchVisible,
			searchText
	}, dispatch);
}

const mapStateToProps = state => {
	return {
		isDeleted: state.deleteReport.reportDelete,
		searchData: state.advanceSearch.searchData
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reportList));

