import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import  MediaListPopup  from '../../mediaListPopup';
import http from '../../../../../service/httpService';
import axios from 'axios';
import { UserProfile, MenuUploadAPI, BaseService, MediaManagementAPI, UnitInfo, organizationNode, HACCPManagementAPI } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID, BRAND_ID_VALUE, BASECOUNTRY_ID, RESTRICTED_CHAR, restrictedCharAvl } from '../../../../../utils/appConstants';
import $ from 'jquery';
import { Cropper } from '../../../../controls/cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import Switch from 'react-switch';
import { nanoid } from 'nanoid';
var request = require('request');
var recipeJSON = require('../../Templates/RecipeJSON');
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');
var LZString = require('lz-string');

class RecipeCreationSave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            selectedModel: 0,
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            recipeName: "",
            showCropPopup:false,
            nodeCaptionDetails: "",
            selectedUnitDetails:"",
            importOption: "Library",
            selectedState: "ambient", 
            portion: "1",
            weight: "50",
            cropWidth: 100,
            cropHeight: 100,
            is_active:'false'
        }
        this.onSave = this.onSave.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.recipeNameChange = this.recipeNameChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.closeCropPopup = this.closeCropPopup.bind(this);
        this.cropSubmit = this.cropSubmit.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $('.mCustomScrollbar').mCustomScrollbar();
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });
        if(!localStorage.getItem("isConnexUser")){
			await this.getConnexUnits();
		}
        try {
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            await this.checkRecipeNameInObj(libraryDetails.library_id)
            this.setState({
                recipeName: libraryDetails.recipeName ? libraryDetails.recipeName : "",
                selectedState: libraryDetails.settings ? libraryDetails.settings.state : "ambient",
                portion: libraryDetails.settings ? libraryDetails.settings.portion : "1",
                weight: libraryDetails.settings ? libraryDetails.settings.weight : "50",
                imageData: libraryDetails.imageData ? libraryDetails.imageData : {},
                imageThumbnailb64: libraryDetails.imageData && libraryDetails.imageData.imagefileName !== '' ? localStorage.getItem("libraryImage") : '',
                isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
                isImageChanged: false,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
            if (this.props.history.location.state.getSelectedDynasty) {                
                let node = this.props.history.location.state.getSelectedDynasty;   
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;     
                await this.getNodeCaptionDetails(node,selectedUnitDetails); 
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onSuccessCloseModal = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        if (libraryDetails.recipeType === "ReadyRecipe") {
            this.props.history.push(`/readyRecipeLibraryList`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                success_modal: {
                    open: false
                }
            })
            this.props.history.push(localStorage.getItem("RecipesListPage"));
        }
    }

    closeCropPopup = () => {
		this.setState({
			showCropPopup: false
		});
    }
    
    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    handleInputClick = (e) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#ConfirmationOfImportModal').modal('hide');
        }
    }

    recipeNameChange = (e) => {
        // let restrictedChar = RESTRICTED_CHAR.split('').some(i => restrictedCharAvl(i,e.target.value));
        // if (restrictedChar || e.nativeEvent.data === '\\'){
        //     return;
        // }
        this.setState({
            recipeName: e.target.value
        });
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            // this.setState({
            //     recipeName: e.target.value
            // });
        // }
    }

    getConnexUnits = () => {
        const { formatMessage } = this.props.intl;
		this.setState({
			loaderIconVisible: true
		});
		let url = `${UnitInfo.list}`;
		let pageNumber = '?pageNum=1';
		let pageLimit = '&pageSize=50';

		let apiUrl = url + pageNumber + pageLimit;

		let searchParam = {
			'modelName': 'connex'
		};
		apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
		return new Promise((resolve, reject) => {
			http.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'cgid': localStorage.getItem("custGroupID"),
					'cid': CUSTOMER_ID,
					'brandid': BRAND_ID,
					'model': "",
					'machinetype': "",
					'sortby': "",
					'sorttype': ""
				},
				data: {}
			}).then(response => {
				localStorage.setItem("isConnexUser", response &&  response.data && response.data.units ? response.data.units.length > 0 : false)
                this.setState({
                    loaderIconVisible: false
                });
                resolve(response);
			}).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
				reject(err);
			});
		});
	}

    portionChange = (e) => {
        let portionValue = parseInt(e.target.value);
        if (portionValue > 0 && portionValue < 100) {
            this.setState({
                portion: portionValue
            });
        }
        else {
            this.setState({
                portion: "1",
                error_modal: {
                    open: true,
                    message: "KC2011"
                }
            });
        }
    }

    weightChange = (e) => {
        let weightValue = parseInt(e.target.value);
        if (weightValue > 0 && weightValue < 10000) {
            this.setState({
                weight: weightValue
            });
        }
        else {
            this.setState({
                weight: "50",
                error_modal: {
                    open: true,
                    message: "KC2012"
                }
            });
        }
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }



    async OnImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let md5 = require('md5');
            let imageData = _this.state.imageData ? _this.state.imageData : {};
            imageData.imagefileName = item.FILE_NAME;
            imageData.imagefileext = item.FILE_FORMAT.replace('.','');
            imageData.imagefileNamewithOutExt = item.FILE_NAME.replace(item.FILE_FORMAT, '');
            imageData.imagefileSize = item.FILE_SIZE;
            imageData.imagefilelength = item.FILE_SIZE;
            imageData.imageType = item.TYPE;

            imageData.resolution = item.RESOLUTION;
            imageData.category = 'picture';
            if(item.FILE_FORMAT === '.svg'){
                var res = await fetch(item.thumbUrl);
                var blob = await res.blob();
                let thumbsrc = await new Promise((resolve, reject) => {
                  var reader  = new FileReader();
                  reader.addEventListener("load", function () {
                      resolve(reader.result);
                  }, false);
                  reader.onerror = () => {
                    return reject(this);
                  };
                  reader.readAsDataURL(blob);
                })
                let imgBuffer =  this.base64ToArrayBuffer(thumbsrc.replace("data:multipart/form-data;base64,", ""))
                imageData.crc = md5(blob, "base64");
                _this.setState({
                    imageDataBuffer: imgBuffer,
                    imageDatab64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    imageData: imageData,
                    imageThumbnailBuffer: imgBuffer,
                    imageThumbnailb64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    isImageChanged: true,
                    loaderIconVisible: false
                });
            }else{
                await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'type': imageData.imageType
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                        let src = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                        imageData.crc = md5(body, "base64");
                        Jimp.read(body)
                        .then(image => {
                            image.resize(80, 80, (err, image)  => {
                                image.getBufferAsync('image/png').then(buffer => {
                                    let thumbsrc = buffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                    _this.setState({
                                        imageDataBuffer: body,
                                        imageDatab64: src,
                                        imageData: imageData,
                                        imageThumbnailBuffer: buffer,
                                        imageThumbnailb64: thumbsrc,
                                        isImageChanged: true,
                                        loaderIconVisible: false
                                    });
                                })
                            })
                        })
                        .catch(err => {
                            // Handle an exception.
                        });
                    })
                })
                .catch(ex => {
                    console.log(ex);
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
            }
        }
        catch (ex) {
            console.log(ex);
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            previouspath: "/recipeCreationSave",
        });
    }

    onSave = async (action,e) => {
        this.setState({
            loaderIconVisible: true
        });

        let recipeName = this.state.recipeName.trim();
        const {selectedState, portion, weight} = this.state;
        if (recipeName.length < 1) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2013"
                }
            });
        }
        else if (this.state.isImageChanged && this.state.imageThumbnailb64) {
            let library_id = "";
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            if (this.state.isEdit && libraryDetails && libraryDetails.library_id) {
                library_id = libraryDetails.library_id;
            }
            else {
                // library_id = recipeName + '_' + Date.now();
                library_id = nanoid() + '_' + Date.now();
            }
            let imagefilekey = this.state.imageData && this.state.imageData.imagefileext ? library_id + '.' + this.state.imageData.imagefileext : "";
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': encodeURIComponent(imagefilekey),
                'jsonfilekey': "",
                'audiofilekey': "",
                'isthumbnail': true,
                'encryption' : "true"
            }

            return new Promise((resolve, reject) => {
                let token = localStorage.getItem('Access-Token');
                headerpresigned.Authorization = token;
                axios({
                    url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                    method: "GET",
                    headers: headerpresigned,
                    data: {}
                }).then(response => {
                    let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                    let imageurl = imagePresignedURL;
                    let thumbnailurl = thumbnailPresignedURL;

                    let UploadedImages3url = "";
                    if (imageurl) {
                        UploadedImages3url = imageurl.split('?');
                        UploadedImages3url = UploadedImages3url[0];
                    }
                    var xhrthumb = new XMLHttpRequest();
                    xhrthumb.open("PUT", thumbnailurl);
                    xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                    xhrthumb.onloadend = (res) => {
                        if (res.target.status == 200) {
                            var xhrimage = new XMLHttpRequest();
                            xhrimage.open("PUT", imageurl);
                            xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                            xhrimage.onloadend = async (res) => {
                                if (res.target.status == 200) {
                                    localStorage.setItem("libraryImage", this.state.imageThumbnailb64);
                                    libraryDetails.recipeName = recipeName;
                                    libraryDetails.settings = {};
                                    libraryDetails.settings.portion = portion;
                                    libraryDetails.settings.state = selectedState;
                                    libraryDetails.settings.weight = weight;
                                    libraryDetails.imageData = this.state.imageData;
                                    libraryDetails.library_id = library_id;
                                    libraryDetails.imageData.imageS3Url = UploadedImages3url;
                                    localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                                    if (action === "Test") {
                                        this.props.history.push({
                                            pathname: "/recipeAssignTopology",
                                            previouspath: "/recipeCreationSave",
                                        });
                                    }
                                    else {
                                        if (libraryDetails.recipeType === 'ReadyRecipe') {
                                            this.saveReadyRecipe(e);
                                        } else {
                                            await this.submitAddLibrary();
                                        }
                                    }
                                }
                            };
                            xhrimage.onerror = (err) => {
                                reject(err);
                            };
                            xhrimage.send(this.state.imageDataBuffer);
                        }
                    };
                    xhrthumb.onerror = (err) => {
                        reject(err);
                    };
                    xhrthumb.send(this.state.imageThumbnailBuffer);
                }).catch(err => {
                    reject(err);
                });
            });
        }
        else {
            let libraryDetails;
            libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            if (!this.state.imageThumbnailb64) {
                libraryDetails.imageData = this.state.imageData ? this.state.imageData : {};
                if(this.state.imageData == ""){
                    libraryDetails.imageData= {};
                }
                let md5 = require('md5');
                var jsonStr = beautify(libraryDetails, null, 2, 80)
                const jsonBuffer = Buffer.from(jsonStr);
                libraryDetails.imageData.crc=md5(jsonBuffer);
                libraryDetails.imageData.imagefileName = "";
                libraryDetails.imageData.imagefileNamewithOutExt = "";
                libraryDetails.imageData.imagefileSize = "";
                libraryDetails.imageData.imagefileext = "";
                libraryDetails.imageData.imageS3Url = "";
            }
            
            if (!libraryDetails.library_id) {
                // let library_id = library_id = recipeName + '_' + Date.now();  
                let library_id = nanoid() + '_' + Date.now();                
                libraryDetails.library_id = library_id;
            }
            
            libraryDetails.recipeName = recipeName;
            libraryDetails.settings = {};
            libraryDetails.settings.portion = portion;
            libraryDetails.settings.state = selectedState;
            libraryDetails.settings.weight = weight;
            localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
            if (action === "Test") {
                this.props.history.push({
                    pathname: "/recipeAssignTopology",
                    previouspath: "/recipeCreationSave",
                });
            }
            else {
                if (libraryDetails.recipeType === 'ReadyRecipe') {
                    this.saveReadyRecipe(e);
                } else {
                    await this.submitAddLibrary();                    
                }
            }
        }
    }

    checkRecipeNameInObj = (library_id) => {

        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === 'Press&Go');
        if (selectedCategoryDetailsObj && selectedCategoryDetailsObj.recipe_items) {
            let result = selectedCategoryDetailsObj.recipe_items.find(item => item.LIBRARY_ID === library_id)
            if (result) {
                this.setState({
                    is_active: "true",
                })
            }
        }
    }

   /*If crc is empty While recipe creation, generate test crc*/ 
   generateCrcCode = () => {
		let md5 = require('md5');
		let crcString = "recipe code";
        let crcCode= null;
			try {
				crcCode = md5(crcString, null, true);
                return crcCode;
			}catch (error) {
                console.log("crc generate error",error);
			}
	}

    submitAddLibrary = (e,_customId) => {
        let { previouspath } = this.props.location;
        let { is_active } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        this.setState({
            previouspath: previouspath,
            recipeName: libraryDetails.recipeName ? libraryDetails.recipeName : "",
            imageData: libraryDetails.imageData ? libraryDetails.imageData : "",
            imageThumbnailb64: localStorage.getItem("libraryImage"),
            isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
            isImageChanged: libraryDetails.isImageChanged ? libraryDetails.isImageChanged : false
        },()=>{});
        
        let _this = this;
        let httpMethod;
        let imageS3Url = _this.state.imageData ? _this.state.imageData.imageS3Url : "";
        let crcValue = (_this.state.imageData && _this.state.imageData.crc === "") ? this.generateCrcCode() : _this.state.imageData.crc;
            _this.setState({
                loaderIconVisible: true
            })

            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false',                    
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryId = libraryDetails.library_id;
                if (_this.state.isEdit) {
                    httpMethod = "PUT";
                } else {
                    httpMethod = "POST";
                }
                 
                let jsonTemplateFile = recipeJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let libraryData = localStorage.getItem("libraryData");
                let libraryDataObj = JSON.parse(libraryData);
                jsonTemplateObj.id = _this.state.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                jsonTemplateObj.name.en_GB = _this.state.recipeName;
                jsonTemplateObj.image = _this.state.imageData.imagefileext ? "Resources/Images/" + libraryId + '.' + _this.state.imageData.imagefileext : "";
                
                delete libraryData.Import;
                jsonTemplateObj.settings = {};
                jsonTemplateObj.settings.portion = libraryDetails.settings ? parseInt(libraryDetails.settings.portion) : 1;
                jsonTemplateObj.settings.state = libraryDetails.settings ? libraryDetails.settings.state : "ambient";
                jsonTemplateObj.settings.weight = libraryDetails.settings ? parseInt(libraryDetails.settings.weight) : 50;

                let imageFileSize = _this.state.imageData.imagefileSize ? _this.state.imageData.imagefileSize : 0;
                jsonTemplateObj.ordered_steps = [];
                libraryDataObj.map((item, i) => {
                    jsonTemplateObj.ordered_steps[i] = {};
                    jsonTemplateObj.ordered_steps[i].duration = "PT" + item.time.split(':')[0] + "M" + item.time.split(':')[1] + "S";
                    //Temperature
                    jsonTemplateObj.ordered_steps[i].actions = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point.value = item.temp ? parseInt(item.temp.replace('F', '').replace('C', '')) : 0;
                    jsonTemplateObj.ordered_steps[i].actions.set_point.units = item.temp.includes('C') ? "degC" : "degF";
                    //FanSpeed
                    jsonTemplateObj.ordered_steps[i].actions.fan = item.fan ? parseInt(item.fan): 0;
                    //Power
                    jsonTemplateObj.ordered_steps[i].actions.microwave = item.power ? parseInt(item.power) : 0;
                    //Prompt
                    jsonTemplateObj.ordered_steps[i].actions.prompt = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.image = item.instructionImage ? item.instructionImage : "";
                    imageFileSize = imageFileSize + (item.instructionImageSize ? parseFloat(item.instructionImageSize) : 0);
                    //Prompt Message
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message.en_GB = item.instruction;
                });

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": _this.state.recipeName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + parseFloat(imageFileSize ? imageFileSize : 0)).toFixed(2),
                    "libraryS3Url": "",
                    "type": libraryDetails.recipeType,
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crcValue,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": _this.state.imageData.imagefileext ? libraryId + '.' + _this.state.imageData.imagefileext : "",
                    "imageFileFormat": _this.state.imageData.imagefileext,
                    "imageFileSize": imageFileSize,
                    "imageS3Url": imageS3Url,                    
                    "cgids": [localStorage.getItem("parentCustGroupId") || localStorage.getItem("custGroupID")],
                    "json": JSON.stringify(jsonTemplateObj),
                    "libraryGuid": jsonTemplateObj.id
                };

                if (_this.state.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': '',
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': '',
                    'isthumbnail': false,
                    'encryption' : "true"
                } 

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                if (is_active === "true"){
                    this.addRecipeInPressAndGo(libraryId)
                } else {
                    let library_id = "";
                    let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                    library_id = libraryDetails.library_id;

                    let categoryDetails = localStorage.getItem("resourceDetails");
                    let categoryDetailsObj = JSON.parse(categoryDetails);
                    let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === 'Press&Go');
                    if (selectedCategoryDetailsObj && selectedCategoryDetailsObj.recipe_items){
                        let recipe_Details = selectedCategoryDetailsObj.recipe_items;
                        let result = recipe_Details.filter(item => item.LIBRARY_ID !== library_id)
                        selectedCategoryDetailsObj.recipe_items = result;

                        let categoryObj = categoryDetailsObj.filter(c => c.ID !== 'Press&Go');
                        categoryObj.push(selectedCategoryDetailsObj);
                        localStorage.setItem("resourceDetails", JSON.stringify(categoryObj));
                    }
                    this.updateLatestValues(libraryId);
                }
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "Recipe updated successfully." : "Recipe saved successfully."
                    },
                });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        
    }

    updateLatestValues = async (libraryId) => {
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getLibraryList,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'cgids': localStorage.getItem("custGroupID"),
                        'brandid': localStorage.getItem("selectedbrandID"),
                        'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe",
                        'createdby': loggedinUser
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let libraryList = data && data.libraryList ? data.libraryList : [];
                    libraryList = libraryList.find(c => c.LIBRARY_ID === libraryId)
                    let categoryDetails = localStorage.getItem("resourceDetails")
                    categoryDetails = JSON.parse(categoryDetails);
                    categoryDetails && categoryDetails.map((item, index) => {
                        item.recipe_items && item.recipe_items.map((recipe, index) => {
                            if (recipe.ID === libraryList.ID) {
                                item.recipe_items.splice(index, 1, libraryList);
                            }
                        })
                        item.subCategory && item.subCategory.map((subItem, index) => {
                            subItem.recipe_items && subItem.recipe_items.map((subRecipe, index) => {
                                if (subRecipe.ID === libraryList.ID) {
                                    subItem.recipe_items.splice(index, 1, libraryList);
                                }
                            })
                        })
                    })
                    localStorage.setItem("resourceDetails", JSON.stringify(categoryDetails));
                    let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
					allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
					allRecipeDetails.map((item, index) => {
						if(item.ID === libraryList.ID) {
                            allRecipeDetails.splice(index, 1, libraryList);
						}
					})
                    let result = allRecipeDetails.filter(item => item.ID === libraryList.ID)
                    if(result && result.length === 0) {
                        allRecipeDetails.push(libraryList);
                    }
                    localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(allRecipeDetails)));
                    this.setState({
                        loaderIconVisible: false,
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    addRecipeInPressAndGo = async(libraryId) =>{
        await this.getLibraryList(libraryId)
    }

    getLibraryList = (libraryId) => {
		this.setState({
			loaderIconVisible: true,
            previouspath: this.props.history.location.previouspath,
            setScreenTitle: this.props.location.state
		});
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getLibraryList,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': localStorage.getItem("custGroupID"),
						'brandid': localStorage.getItem("selectedbrandID"),
						'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe",
                        'createdby': loggedinUser
					},
					data: {}
				}).then(response => {
					let { data } = response;
                    let libraryList = data && data.libraryList ? data.libraryList : [];
                    libraryList = libraryList.find(c => c.LIBRARY_ID === libraryId)
                    let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
					allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
					allRecipeDetails.map((item, index) => {
						if(item.ID === libraryList.ID) {
                            allRecipeDetails.splice(index, 1, libraryList);
						}
					})
                    let result = allRecipeDetails.filter(item => item.ID === libraryList.ID)
                    if(result && result.length === 0) {
                        allRecipeDetails.push(libraryList);
                    }
                    localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(allRecipeDetails)));
                    let categoryDetails = localStorage.getItem("resourceDetails");
                    let categoryDetailsObj = JSON.parse(categoryDetails);
                    categoryDetailsObj && categoryDetailsObj.map((item, index) => {
                        item.recipe_items && item.recipe_items.map((recipe, index) => {
                            if (recipe.ID === libraryList.ID) {
                                item.recipe_items.splice(index, 1, libraryList);
                            }
                        })
                        item.subCategory && item.subCategory.map((subItem, index) => {
                            subItem.recipe_items && subItem.recipe_items.map((subRecipe, index) => {
                                if (subRecipe.ID === libraryList.ID) {
                                    subItem.recipe_items.splice(index, 1, libraryList);
                                }
                            })
                        })
                    })
                    let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === 'Press&Go');
                    if (selectedCategoryDetailsObj) {
                        if (selectedCategoryDetailsObj.recipe_items) {
                            // check if the item is already available
                            selectedCategoryDetailsObj.recipe_items.map((item, index) => {
                                if(item.ID === libraryList.ID) {
                                    selectedCategoryDetailsObj.recipe_items.splice(index, 1, libraryList);
                                } else {
                                    selectedCategoryDetailsObj.recipe_items.push(libraryList);
                                }
                            })
                            let uniqueMap = new Map();
                            let uniqueRecipes = [];
                            selectedCategoryDetailsObj.recipe_items.forEach(element => {
                                const keyValue = element['ID'];
                                if(!uniqueMap.has(keyValue)){
                                    uniqueMap.set(keyValue, true);
                                    uniqueRecipes.push(element);
                                }
                            });
                            selectedCategoryDetailsObj.recipe_items = uniqueRecipes;
                            if(selectedCategoryDetailsObj.recipe_items.length === 0) {
                                selectedCategoryDetailsObj.recipe_items.push(libraryList);
                            }
                        } else {
                            let recipeValue = [];
                            recipeValue.push(libraryList);
                            selectedCategoryDetailsObj.recipe_items = recipeValue;
                        }
                        let categoryObj = categoryDetailsObj.filter(c => c.ID !== 'Press&Go');
                        categoryObj.push(selectedCategoryDetailsObj);
                        localStorage.setItem("resourceDetails", JSON.stringify(categoryObj));
                    }
                    else {
                        if (!selectedCategoryDetailsObj) {
                            let pressGoObj = {};
                            pressGoObj.ID = "Press&Go";
                            pressGoObj.categoryName = "Press&Go";
                            pressGoObj.imageFileFormat = "";
                            pressGoObj.imageFileName = "";
                            pressGoObj.imageFileSize = "";
                            pressGoObj.imageS3Url = "";
                            pressGoObj.parentID = "";
                            pressGoObj.selected = false;
                            pressGoObj.thumbnailData = null;
                            pressGoObj.type = "Category";
                            pressGoObj.recipe_items = [libraryList];
                            let categoryObj = categoryDetailsObj.filter(c => c.ID !== 'Press&Go');
                            if(categoryObj === undefined) {
                                categoryObj = {}
                            } 
                            categoryObj.push(pressGoObj);
                            localStorage.setItem("resourceDetails", JSON.stringify(categoryObj));
                        }
                    }

					this.setState({
						loaderIconVisible: false,
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});

    }

    handleActiveCheckboxChange = (e) => {
        this.setState({
            is_active: e.toString()
        });
	};

    saveReadyRecipe = (e) => {
        e.preventDefault();
        let _this = this;
        let httpMethod;
        let imageS3Url = _this.state.imageData ? _this.state.imageData.imageS3Url : "";
        if (!imageS3Url) {
            _this.props.history.push(`/recipeCreation`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })

            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryId = libraryDetails.library_id;
                if (_this.state.isEdit) {
                    httpMethod = "PUT";
                } else {
                    httpMethod = "POST";
                }
                 
                let jsonTemplateFile = recipeJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let libraryData = localStorage.getItem("libraryData");
                let libraryDataObj = JSON.parse(libraryData);
                jsonTemplateObj.id = _this.state.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                jsonTemplateObj.name.en_GB = _this.state.recipeName;
                jsonTemplateObj.image = _this.state.imageData && _this.state.imageData.imagefileext ? "Resources/Images/" + libraryId + '.' + _this.state.imageData.imagefileext : "";
                
                delete libraryData.Import;
                jsonTemplateObj.settings = {};
                jsonTemplateObj.settings.portion = _this.state.portion;
                jsonTemplateObj.settings.state = _this.state.selectedState;
                jsonTemplateObj.settings.weight = _this.state.weight;

                jsonTemplateObj.ordered_steps = [];
                libraryDataObj.map((item, i) => {
                    jsonTemplateObj.ordered_steps[i] = {};
                    jsonTemplateObj.ordered_steps[i].duration = "PT" + item.time.split(':')[0] + "M" + item.time.split(':')[1] + "S";
                    //Temperature
                    jsonTemplateObj.ordered_steps[i].actions = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point.value = item.temp ? parseInt(item.temp.replace('F', '').replace('C', '')) : 0;
                    jsonTemplateObj.ordered_steps[i].actions.set_point.units = item.temp.includes('C') ? "degC" : "degF";
                    //FanSpeed
                    jsonTemplateObj.ordered_steps[i].actions.fan = item.fan ? item.fan: 0;
                    //Power
                    jsonTemplateObj.ordered_steps[i].actions.microwave = item.power ? item.power : 0;
                    //Prompt
                    jsonTemplateObj.ordered_steps[i].actions.prompt = {};
                    //Prompt Message
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message.en_GB = item.instruction;
                });

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": _this.state.recipeName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + parseFloat(_this.state.imageData.imagefileSize)).toFixed(2),
                    "libraryS3Url": "",
                    "type": libraryDetails.recipeType,
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": _this.state.imageData.crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": _this.state.imageData && _this.state.imageData.imagefileext ? libraryId + '.' + _this.state.imageData.imagefileext : '',
                    "imageFileFormat": _this.state.imageData && _this.state.imageData.imagefileext ? _this.state.imageData.imagefileext : "",
                    "imageFileSize": _this.state.imageData && _this.state.imageData.imagefileSize ? _this.state.imageData.imagefileSize : "",
                    "imageS3Url": imageS3Url,
                    "cgids": [parseInt(localStorage.getItem("custGroupID") ? parseInt(localStorage.getItem("custGroupID")) : 0)],
                    "json": JSON.stringify(jsonTemplateObj),
                    "libraryGuid": jsonTemplateObj.id
                };

                if (_this.state.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': '',
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': '',
                    'isthumbnail': false,
                    'encryption' : "true"
                } 

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "KC1222" : "KC1390"
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured',err);
            });
        }
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileext =  fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.'+fileext, "");
            let filelength = fileName.length;
            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            //User Story 86440: MRE - Uploading a picture of minimum 100*100 pixels size in Category/Sub-Category/Recipes - Connex supports all sizes above or below 100*100 pixels.
                            if ((width > 100 || height > 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								this.setState({
									loaderIconVisible: false,
									showCropPopup:true,
									src: reader.result,
                                    cropWidth: width < height ? width : height,
									cropHeight: width < height ? width : height,
									imageData: imageData
								});
								$('#popupTrigger').trigger('click');
							}
                            else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (thumbsrc) => {
                                            thumbsrc = thumbsrc.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(thumbsrc, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 400,
                                                maxHeight: 400,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: thumbsrc,
                                                        isImageChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.showError(err);
                                                }
                                            }).createThumbnail();

                                        },
                                        onError: function (err) {
                                            this.showError(err);
                                        }
                                    }).createThumbnail();
                                }
                            }
                        };
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1225"
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249"
                    }
                });
            }
            event.target.value = '';
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
				
        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                    image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                        let md5 = require('md5');
                        let fileSize = Buffer.byteLength(imagebuffer);
                        fileSize = (fileSize / 1048576).toFixed(2);
                        _this.state.imageData.resolution = "400 x 400";
                        _this.state.imageData.category = "picture";
                        _this.state.imageData.crc = md5(imagebuffer);
                        _this.state.imageData.fileSize = fileSize;
                        Jimp.read(imagebuffer)
                        .then(thumbnail => {
                            thumbnail.resize(80, 80, (err, thumbnail) => {
                                thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                    thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                        _this.setState({
                                            imageDataBuffer: imagebuffer,
                                            imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            imageData: _this.state.imageData,
                                            imageThumbnailBuffer: thumbnailbuffer,
                                            imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            isImageChanged: true,
                                            loaderIconVisible: false
                                        });
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
        .catch(err => {
            _this.showError(err);
        })
    }
    
    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, KC_ERR_500, message } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500 && message) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: message ? message : KC_ERR_500 ? KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    getNodeCaptionDetails = async (val,val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': localStorage.getItem("customerId"),
                'dynasty' : val,
            }, data: {}
        }).then( response  => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length -1) === i){
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })
                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails:val1,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    getThingDetails = async () => {
		this.setState({ loaderIconVisible: true });
		let unitID = localStorage.getItem("unitID");
		const url = HACCPManagementAPI.unitLogDetails + unitID;
		await http.get(url, {
			headers: {
				'Content-Type': 'application/json'
			}, data: {}
		}).then(response => {
			if (response && response.data) {
                let haccpLogDetails = response.data;
				this.setState({
					loaderIconVisible: false, 			
                });
                
                let thingList = [{
                    "thingArn": haccpLogDetails.thingArn,
                    "modelName": haccpLogDetails.modelName,
                    "modelNumber": haccpLogDetails.modelNumber,
                    "cloudUnitName": haccpLogDetails.cloudUnitName,
                    "unitTimeZone": haccpLogDetails.unitTimeZone,
                }];

			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }
    
    selectTestUnitButtonClick = async() => {
        let _this = this;
        let httpMethod;
        let imageS3Url = _this.state.imageData ? _this.state.imageData.imageS3Url : "";
        if (!imageS3Url) {
            _this.props.history.push(`/recipeCreation`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })

            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            await http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then( async response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryId = libraryDetails.library_id;
                if (_this.state.isEdit) {
                    httpMethod = "PUT";
                } else {
                    httpMethod = "POST";
                }
                 
                let jsonTemplateFile = recipeJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let libraryData = localStorage.getItem("libraryData");
                let libraryDataObj = JSON.parse(libraryData);
                
                jsonTemplateObj.id = _this.state.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                jsonTemplateObj.name.en_GB = _this.state.recipeName;
                jsonTemplateObj.image = _this.state.imageData && _this.state.imageData.imagefileext ? "Resources/Images/" + _this.state.recipeName + '.' + _this.state.imageData.imagefileext : '';
                
                delete libraryData.Import;
                jsonTemplateObj.settings = {};
                jsonTemplateObj.settings.portion = _this.state.portion;
                jsonTemplateObj.settings.state = _this.state.selectedState;
                jsonTemplateObj.settings.weight = _this.state.weight;

                jsonTemplateObj.ordered_steps = [];
                libraryDataObj.map((item, i) => {
                    jsonTemplateObj.ordered_steps[i] = {};
                    jsonTemplateObj.ordered_steps[i].duration = "PT" + item.time.split(':')[0] + "M" + item.time.split(':')[1] + "S";
                    //Temperature
                    jsonTemplateObj.ordered_steps[i].actions = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point.value = item.temp ? parseFloat(item.temp.replace('F', '').replace('C', '')) : '0';
                    jsonTemplateObj.ordered_steps[i].actions.set_point.units = item.temp.includes('C') ? "degC" : "degF";
                    //FanSpeed
                    jsonTemplateObj.ordered_steps[i].actions.fan = item.fan ? parseInt(item.fan.replace('%', '')) : '0';
                    //Power
                    jsonTemplateObj.ordered_steps[i].actions.microwave = item.power ? parseInt(item.power.replace('%', '')) : '0';
                    //Prompt
                    jsonTemplateObj.ordered_steps[i].actions.prompt = {};
                    //Prompt Message
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message.en_GB = item.instruction;
                });

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": _this.state.recipeName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + parseFloat(_this.state.imageData.imagefileSize)).toFixed(2),
                    "libraryS3Url": "",
                    "type": libraryDetails.recipeType,
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": _this.state.imageData.crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": _this.state.imageData && _this.state.imageData.imagefileext ? libraryId + '.' + _this.state.imageData.imagefileext : '',
                    "imageFileFormat": _this.state.imageData && _this.state.imageData.imagefileext ? _this.state.imageData.imagefileext : '',
                    "imageFileSize": _this.state.imageData && _this.state.imageData.imagefileSize ? _this.state.imageData.imagefileSize : '',
                    "imageS3Url": imageS3Url,
                    "cgids": [parseInt(localStorage.getItem("custGroupID") ? parseInt(localStorage.getItem("custGroupID")) : 0)],
                    "json": JSON.stringify(jsonTemplateObj),
                    "libraryGuid": jsonTemplateObj.id
                };

                if (_this.state.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                
                if (libraryDetails.initialMethod === "Add") {
                    libraryPostData.status = "DRAFT";
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': '',
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': '',
                    'isthumbnail': false,
                    'encryption' : "true"
                }

               await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then( async response => {

                    let {selectedUnitDetails} = this.state;

                    const unitList = [];
                    unitList.push(
                        {
                            "thingArn": selectedUnitDetails.THING,
                            "productId": selectedUnitDetails.ProductId,
                            "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                            "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                        }
                    )

                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": [libraryId],
                        "country": BASECOUNTRY_ID,
                        "type": libraryDetails.recipeType,
                        "thingList": unitList,
                        "userName": loggedinUser,
				        "isTestPush": true
                    };
            
                    let url = MenuUploadAPI.getLibraryUpdateStatus;
                       await http.post(url, postData,
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                        .then(response => {
                            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                            libraryDetails.isEdit = true;
                            localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                        _this.setState({
                            loaderIconVisible: false,
                            isEdit: true,
                            success_modal: {
                                open: true,
                                message: "KC1227"
                            },
                            nodeCaptionDetails: null,
                            selectedUnitDetails: {},
                        });
                    }).catch(err => {
                        _this.showError(err);
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
            });
        }
    }

    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    cancelImportClick = (event) => {
        $('#ConfirmationOfImportModal').modal('hide');
    }

    selectedState (e) {		
		this.setState({ selectedState: e.target.value });
	}

    onClearImage (){
        localStorage.removeItem("libraryImage");
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        if (libraryDetails.imageData) {
            libraryDetails.imageData.imageS3Url = '';
            libraryDetails.imageData.imagefileName = '';
            libraryDetails.imageData.imagefileNamewithOutExt = '';
            libraryDetails.imageData.imagefileSize = '';
            libraryDetails.imageData.imagefileext = '';
        }
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        this.setState({
            imageDataBuffer: null,
            imageDatab64: null,
            imageData: {},
            imageThumbnailBuffer: null,
            imageThumbnailb64: null,
            isImageChanged: true
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, recipeName, loaderIconVisible, imageThumbnailb64: thumbnailImageData, error_modal, success_modal, imageData, importOption, selectedState, portion, weight, cropWidth, cropHeight,is_active } = this.state;
        thumbnailImageData = imageData && imageData.imagefileName !== '' ? thumbnailImageData : '';
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1211"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div className="rcSaveForm">
                                                    <div className="rcNameDiv">
                                                        <div class="rcSaveFrm">
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1228"/></label>  
                                                                    <input type="text" maxLength="20" className="form-control" value={recipeName} onChange={(e) => { this.recipeNameChange(e) }} />
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id='KC0457' /></label>
                                                                    <select onChange={(e) => this.selectedState(e)} value={selectedState} class="form-control">
                                                                        <option value="ambient" selected>Ambient</option>
                                                                        <option value="chilled">Chilled</option>
                                                                        <option value="frozen">Frozen</option>
                                                                    </select>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id="KC2014"/></label>
                                                                    <input type="text" maxLength={2} className="form-control" value={portion} onChange={(e) => { this.portionChange(e) }} />
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id="KC2015"/></label>
                                                                    <input type="text" maxLength={4} className="form-control" value={weight} onChange={(e) => { this.weightChange(e) }} />
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <label>Add to Press &amp; Go</label>
                                                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                                                        <Switch
                                                                                onChange={(e) => this.handleActiveCheckboxChange(e)}
                                                                                checked={is_active === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            /> 
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="importImageDiv editActive">
                                                        <div className="importImage">
                                                            <button type="button" class="btn cancelButton" onClick={() => this.onClearImage()}>&nbsp;</button>
                                                            <div className="menuBrowse" title={formatMessage({ id: 'KC1251' })}>
                                                               <a href="javascript:void(0)" class="menuBrowse" data-toggle="modal" data-target="#ConfirmationOfImportModal" title={formatMessage({ id: 'KC1251' })}><img src={thumbnailImageData ? `data:${'image/png'};base64,${thumbnailImageData}` : require("../../../../../images/recipecreation/photosPictures.png")}  alt=""/></a></div>
                                                        </div>
                                                        <label><FormattedMessage id="KC1229"/></label>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" onClick={(e) => { this.onSave('Save',e) }} className="btn saveCButton" title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
                                    {/* <button type="button" onClick={(e) => { this.onSave('Test',e) }} class={loginInfo.rolename === "Public Admin" ? "displaynone" : "btn testButton"} title={formatMessage({ id: 'KC1230' })}><FormattedMessage id="KC0698"/></button> */}
                                </div>
                            </div>
                            {/* <div className="footerText"><FormattedMessage id="KC1217"/></div>
                            <div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "testOnUnitTable"}>
                                <ul>
                                    <li><img src={require("../../../../../images/recipecreation/assetD.svg")}  alt=""/></li>
                                    <li>                                        
                                        <input type="text" className="form-control" value={this.state.nodeCaptionDetails ? this.state.selectedUnitDetails.UNITID + "_" + this.state.selectedUnitDetails.BRAND_NAME + "     " +this.state.nodeCaptionDetails:"" } placeholder={formatMessage({ id: 'KC1276' })}></input> 
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-default-text deleteButton" onClick={() => { this.selectTestDeleteButtonClick() }}>&nbsp;</button>
                                    </li>
                                    <li>
                                        <button type="button" className={this.state.nodeCaptionDetails ? "btn btn-default-text" : "btn btn-default-text disabled"} onClick={() => { this.selectTestUnitButtonClick() }}><FormattedMessage id="KC1231"/></button>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Import Image Modal --> */}
                { localStorage.getItem("isConnexUser") && <MediaListPopup mediaType={'image'} OnImportImage={(item) => this.OnImport(item)} />}
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo"> 
                                    <h5><FormattedMessage id="KC1232"/></h5>
                                    <div class="uploadOption">
                                        <div class="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id="KC1924"/></label>
                                        </div> 
                                        <div class="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id="KC0021"/></button>
                                <input type="file" accept="image/png, image/jpeg, image/jpg,image/gif,image/bmp,image/svg+xml" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                <button class="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleInputClick}><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(RecipeCreationSave)
