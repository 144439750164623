import http from '../../../service/httpService';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoadingPopup from '../../controls/loadingPopup';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { ServiceManagement } from '../../../service/api';
import { getAPIUrl, getErrorList } from '../../../service/servicemanagement/serviceManagement';
import { CUSTOMER_ID } from '../../../utils/appConstants';
import moment from '../../../utils/momentHelper.js';
import { getErrorList_ForHistoricError } from '../../../service/servicemanagement/serviceManagement';
import appconstants from '../../../utils/appConstants'
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import NoRecordsFound from '../../controls/noRecordsFound';
import $ from 'jquery';
import _ from 'lodash';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import authService from '../../../service/authService';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../../controls/goToPagination';

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
const modelNameColumnStringIDMap = {
	model: 'KC0064',
	serialNumber: 'KC0841',
	assetNumber: 'KC0845'
};

class allHistoricErrors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			totalPages: null,
			loaderIconVisible: true,
			currentPage: 1,
			pageSize: 25,
			currentErrCount: "",
			sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
			errorType: "",
			IsServerError: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			noRecords: null,
			dataToTrobleShoot: [],
			isSelectAllRoles: false,
			allowToDelete: false,
			type: 'Month',
			brandNames:[],
			hideDateRange:false,
			isCriticalSel: true,
			isNonCriticalSel: true,
			isErrorEventSel: false,
			sortedByName:"model",
			cbGroup : {nCriticalError:true, criticalError:true, errorEvent:false},
			findErrType:"All",
			findErrorEvent:false,
			dateFilter:'Month',
			isIndeterminate: false,
			datapickflag:false,
			searchData: null
		};
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
			sSmartTags: [],
        };
		this.changeErrorList = this.changeErrorList.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
	}

	componentWillMount = () => {
		document.body.style.backgroundColor = "#F2F2F2";
	}

	nextPath(path) {
		this.props.history.push(path);
	}

	componentDidMount = async ()  =>  {
		document.body.style.backgroundColor = "#F2F2F2";
		this.props.searchVisible(true);
		await this.getFilterDetails();
		window.addEventListener('resize', this.updateDimensions());
		await this.getProductList();
		const { currentPage, sortColumn } = this.state;
		this.loadData(currentPage, sortColumn, "All",false);
	}

	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
		clearInterval(this.interval);
	}

	componentDidUpdate(prevState) {
		this.updateDimensions()
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			const { sortColumn } = this.state;
			this.setState({loaderIconVisible: true},()=>{
				this.loadData(1, sortColumn, "All",false);
			});
		}
	  }

	updateDimensions() {
        const windowHeight = $(window).height();
        const $tableBody = $('.allErrorListSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0; 
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

	// Apply filters function
	getFilterDetails = async () => {		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.errorCenterFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
		let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    	let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];
		let selectedST = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
		});

		selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
			return selectedST.push(c.id)
		});

		selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
			return selectedST.push(c.id)
		});

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
			sMachineTypes: selectedMachineTypes,
			sSmartTags:selectedST,
		});
		
		var d = document.getElementById("spannavNotifiStatus");
		if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0 || selectedST.length > 0) {
			d.className = "navNotifiStatus";
		}			
		else {
			d.className = "";
		}
		
	}
	getProductList =  async ()  => {
		let brandName = {
			4: "CREM",
			2: "MERCO",
			1: "MERRYCHEF",
			6: "CONVOTHERM",
			3: "FRYMASTER",
			5: 'MANITOWOC ICE',
			7: "DELFIELD",
			8: "MULTIPLEX",
			9: "GARLAND",
			10: "LINCOLN",
		}		
		
		let branids = localStorage.getItem("brandId");
		branids = branids.replace("[", "");
		branids = branids.replace("]", "");
		branids = branids.split(",");
		branids = _.uniqBy(branids);
		let getBrandName = [];
		branids.map((item, i) => {
			getBrandName.push(brandName[item]);
		})
		this.setState({
			brandNames: getBrandName,
		})
	}

	/**
	 * Fetch server data by On-Demand
	 * Send current page number
	 * Send Sorted details
	 */
	loadData = async (currentPage, sortColumn, errorType,errorEvent, filterType, orderBy) => {
		try {
			this.setState({loaderIconVisible: true})
			const response = await this.requestData(currentPage, sortColumn, { criticalType:errorType, errorEvent}, filterType, orderBy);
			await this.countValue();

			this.setState({
				data: response.rows,
				totalPages: response.pages,
				currentPage: currentPage,
				//filterType
			});

			var rolesList = response.rows;
			rolesList.forEach(r => {
				r.checked = false;
			});

			this.setState({
				data: rolesList,
				loaderIconVisible:false
			});
			

		} catch (error) {
			this.setState({ loaderIconVisible: false});
		}
	}

	countValue = async () => {
		let brandIdOpenConcat = "[";let brandIdEndConcat = "]";
		let filteredBrandId = this.filterstate.sBrandids && this.filterstate.sBrandids.length>0?brandIdOpenConcat + [this.filterstate.sBrandids].join(',') + brandIdEndConcat:localStorage.getItem("brandId");
		let filteredCustGrpId = this.filterstate.sCgids && this.filterstate.sCgids.length>0?this.filterstate.sCgids:localStorage.getItem("custGroupID");
		let reqData = {
			"data": {
				"cid": parseInt(CUSTOMER_ID),
				//"filters": "model",
				"timeFilterType":this.state.dateFilter,
				"brand": filteredBrandId,
				"cgid": filteredCustGrpId,
				'modelId': this.filterstate.sModels.join(',')
			}
		};
		if(this.state.dateFilter == "Date Range") {
			reqData.data.timeFilterType = 'custom';
			reqData.data.startDate = weekDateFormatted;
			reqData.data.endDate = todaysDateFormatted;
		}
		
		let url = ServiceManagement.allCurrErrorCounts;
		let { searchData } = this.state;
		if (searchData) {
			Object.keys(searchData).forEach((key, index) => {
				if (searchData[key]) {
					reqData["data"][key === "searchtext" ? "searchText" : key ] = `${searchData[key].toLowerCase()}`;
				}
			});
		}
		http.post(url, reqData,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
			.then(response => {
				this.setState({ currentErrCount: response.data })
			}).catch(err => {
			});
	}

	/**
   * When navigate new page this event will return current page number
   * @param {*} currentPage 
   */
	onPageChange = (currentPages) => {
		const { sortColumn } = this.state;
		this.loadData(currentPages, sortColumn);
	}

	/**
	 * Business login to fetch units by below constraints
	 * 1. Fetch by Brand specific units
	 * 2. Fetch by Sorted column details
	 * 3. Fetch by page wise
	*/
	requestData = async (currentPage, sortColumn, error, filterTypeVal, orderBy) => {
		try {
			const sortData = sortColumn && sortColumn.path !== "" ? sortColumn : this.state.sortColumn;
			const pageSize = this.state.pageSize;
			const sortBy = orderBy ? orderBy : "error_code";
			const sortType = "asc";
			const page = currentPage - 1;
			let brandIdOpenConcat = "[";let brandIdEndConcat = "]";
			let filteredBrandId = this.filterstate.sBrandids && this.filterstate.sBrandids.length>0?brandIdOpenConcat + this.filterstate.sBrandids.join(',') + brandIdEndConcat:localStorage.getItem("brandId");	
			let filteredCustGrpId = this.filterstate.sCgids && this.filterstate.sCgids.length>0?this.filterstate.sCgids:localStorage.getItem("custGroupID");
			let order = page * pageSize;
			let queryString;
			queryString = error === "No Records"?"-1":`cid=${localStorage.getItem("customerId")}`
			queryString = queryString + `&brandId=${filteredBrandId}`
			queryString = queryString + `&cgid=${filteredCustGrpId}`
			queryString = queryString + `&modelId=${this.filterstate.sModels.join(',')}`
			queryString = queryString + `&sortby=${this.state.sortedByName}`
			queryString = queryString + `&sorttype=${sortType}`;

			if (this.state.findErrType !== "No Need") {
				queryString = queryString + `&errorType=${this.state.findErrType}`;
			}
			if (this.state.findErrorEvent) {
				queryString = queryString + `&errorEvent=${this.state.findErrorEvent}`;
			}	
			if (currentPage) {
				queryString = queryString + `&pageNum=${currentPage}`;
			}
			if (pageSize) {
				queryString = queryString + `&pageSize=${pageSize}`
			}
			if (this.state.dateFilter === "Date Range") {
				queryString = queryString + `&startDate=${weekDateFormatted ? weekDateFormatted : ""}&`
				queryString = queryString + `&endDate=${todaysDateFormatted ? todaysDateFormatted : ""}`
				queryString = queryString + `&timeFilterType=custom`
			} else {
				queryString = queryString + `&timeFilterType=${this.state.dateFilter}`
			}
			
            if(this.filterstate && this.filterstate.sSmartTags.length){
                queryString = queryString + `&tags=${this.filterstate.sSmartTags.join(',')}` 
            }
			let apiUrl = `${ServiceManagement.allHistoricErrorList}?${queryString}`;

			let { searchData } = this.state;

			//searchData_function
			if (searchData) {
				Object.keys(searchData).forEach((key,index) => {
					if (searchData[key]) {
						apiUrl = `${apiUrl}&${key === 'searchtext' ? 'searchText': key}=${searchData[key].toLowerCase()}`
					}
				});
			}

			const response = await getErrorList_ForHistoricError(apiUrl);

			let { data, totalPages } = response.data;
			data.map((item, i) => {
				item.order_id = ++order;
			});

			this.setState({
				noRecords: error === "No Records" ? true : false,
			});

			return {
				rows: data,
				pages: totalPages
			};

		} catch (error) {
			return {
				rows: [],
				pages: 0
			};
		}

	};

	changeErrorList = (event) => {
		let errorType = event.target.value === "All Errors" ? "" : event.target.value === "Warning" ? 'non-critical' : 'critical';
		this.state.errorType = errorType;
		this.setState({
			errorType: errorType,
			loaderIconVisible: true
		});
		let pageNumber = 1;
		let pageLimit = 10;
		let currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		let apiUrl = getAPIUrl(errorType, pageNumber, pageLimit, currentUnit.UNITID, "ErrorList");
		getErrorList(apiUrl).then(response => {
			response.data.map((item, i) => {
				return item.order_id = i + 1;
			});
			this.setState({
				data: response.data,
				totalPages: response.totalPages,
				currentPage: 1,
				loaderIconVisible: false
			});
		})
			.catch(err => {
				this.showError(err);
			})
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			this.setState({
				data: response.data,
				totalPages: response.totalPages,
				currentPage: 1,
				loaderIconVisible: false
			});
			this.setState({ loaderIconVisible: false });
		} else {
			this.setState({
				IsServerError: true
			});
		}
	}

	errorCloseModel = () => {
		localStorage.removeItem("Access-Token");
		this.setState({
			IsServerError: false
		});
		this.props.OnCloseSessionModal();
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		});
	}

	onSuccessCloseModal() {
		this.setState({
			// type: 'Month',
			// dateFilter: 'Month',
			success_modal: {
				open: false
			},
			isIndeterminate: false,
			isSelectAllRoles: false
		});
	}

	onSendEmailHandler() {
		this.setState({
			loaderIconVisible: true
		});

		return new Promise((resolve, reject) => {

			let postData = {
				'customer_id': parseInt(CUSTOMER_ID),
				'brand_id': localStorage.getItem("brandName"),
				'user_email': localStorage.getItem("user"),
				'unit_id': localStorage.getItem("unitID")
			}

			http({
				method: 'POST',
				url: ServiceManagement.emailReport,
				data: postData
			})
				.then(res => {
					let { body } = res.data;
					let { responseCode, message, stringId } = body;
					if (responseCode !== 200) {
						this.setState({
							loaderIconVisible: false,
							error_modal: {
								open: true,
								message: stringId ? stringId :  message 
							}
						});
						reject({
							response: {
								data: res.data
							}
						});
					} else {
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: stringId ? stringId :  message 
							},
							isIndeterminate: false,
							isSelectAllRoles: false
						});
						resolve(res);
					}
				}).catch(err => {
					reject(err);
				});
		});
	}

	errorDetailsForTroubleShoot = (e, selectRow) => {
		let resData = this.state.data;
		this.setState({
			dataToTrobleShoot: resData[selectRow - 1],
		}, () => {
			this.setState({
				dataToTrobleShoot: resData[selectRow - 1],
			})
		})
	}

	handleChange = (e) => {
		var rolesList = [...this.state.data];
		rolesList.forEach(r => {
			r.checked = e.target.checked;
		});
		this.isSelectAll(rolesList);
	};

	validateErrorTypes = (isCritical, isNonCritical, isErrorEvent) => {
		let req = {};
		if( isCritical === false && isNonCritical === false && isErrorEvent === true) {
			req = { errorEvent: true }
		}
		else if( isCritical === false && isNonCritical === true && isErrorEvent === false) {
			req = { criticalType: 'noncritical' }
		}
		else if( isCritical === true && isNonCritical === true && isErrorEvent === false) {
			req = { criticalType: 'critical' }
		}
		else if( isCritical === true && isNonCritical === false && isErrorEvent === false) {
			req = { criticalType: 'critical' }
		}
		else if( isCritical === false && isNonCritical === true && isErrorEvent === true) {
			req = { criticalType: 'noncritical', errorEvent: true }
		}
		else if( isCritical === true && isNonCritical === false && isErrorEvent === true) {
			req = { criticalType: 'critical', errorEvent: true }
		}
		return req;
	}

	//For Event Error Code
	handleCheckBoxClick = ( e, key ) => {
		let { currentPage, sortColumn, isCriticalSel, isNonCriticalSel , isErrorEventSel, cbGroup } = this.state;
		let isChecked = e && e.target && e.target.checked;		 
		let setSelectedCheckbox = {};
		setSelectedCheckbox[key] = isChecked;  

		switch( key ) {
			case 'isCriticalSel':
				isCriticalSel = isChecked;
				break;
			case 'isNonCriticalSel':
				isNonCriticalSel = isChecked;
				break;
			case 'isErrorEventSel':
				isErrorEventSel = isChecked;
				break;
		}
		let errType = false, errorEvent = false;
		cbGroup[e.target.value] = isChecked;
		errorEvent = cbGroup["errorEvent"];
		
		if(cbGroup["criticalError"] && cbGroup["nCriticalError"]){
			errType = "All";
		}else if(cbGroup["criticalError"]){
			errType = true;
		}else if(cbGroup["nCriticalError"]){
			errType = false
		}  

		if(cbGroup["criticalError"] === false && cbGroup["nCriticalError"]===false){
			errType = "No Need";
		}
		WoopraEvents(`${Constants.ERROR_TYPE}-${e.target.title}`);
		this.setState({
			findErrType:errType,
			findErrorEvent:isErrorEventSel,
		}, () => {
			this.loadData(1, sortColumn);
		});  
		let req = this.validateErrorTypes( isCriticalSel, isNonCriticalSel, isErrorEventSel );
		this.setState({...setSelectedCheckbox}); 	
	}

	onSelectRole = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const rolesList = [...this.state.data];
			const index = rolesList.indexOf(item);
			rolesList[index] = { ...rolesList[index] };
			rolesList[index].checked = e.target.checked;
			this.isSelectAll(rolesList);
		}
	}

	isSelectAll = (rolesList) => {
		let selectedRoles = rolesList.filter(item => item.checked === true);
		let isIndeterminate, isSelectAllRoles;

		if(selectedRoles.length === rolesList.length) {
			isSelectAllRoles= true; isIndeterminate = false;
		} else if(selectedRoles.length === 0 && rolesList.length !==0){
			isSelectAllRoles= false; isIndeterminate = false;
		} else if(rolesList.length > selectedRoles.length) {
			isSelectAllRoles= true; isIndeterminate = true;
		} else {
			isSelectAllRoles= false; isIndeterminate = false;
		}

		this.setState({
			data: rolesList,
			isIndeterminate: isIndeterminate,
			isSelectAllRoles: isSelectAllRoles
		});
	}

	clearSelectedError = (e) => {
		const { currentPage, sortColumn } = this.state;
		var rolesList = [...this.state.data];
		let selErrorDetails = [];
		rolesList.forEach(r => {
			if (r.checked) {
				let val = {
					"brand_id": r.brandName,
					"device_id": r.device_id,
					"error_code": r.error_code,
				};
				selErrorDetails.push(val);
			}
		});

		let reqData = {
			"data": selErrorDetails,
		};
		let url = ServiceManagement.allHistoryErrorDelete;
		http.post(url, reqData, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(response => {
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC0855"
				},
				isIndeterminate: false,
				isSelectAllRoles: false
			});
			WoopraEvents(`${Constants.DELETE_ERROR}`);
			this.loadData(currentPage, sortColumn);
		}).catch(err => {
		});
	}

	handleDropDown = (sortBy) => {
		const { currentPage, sortColumn, } = this.state;		
		this.setState({
			sortedByName:sortBy,
		},()=>{
			this.loadData(currentPage, sortColumn)
		})
	}

	handleChangeStart(date, mindate) {
		this.setState({
			startDate: date
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'clickHandler');
		});
	}

	handleChangeEnd(date) {
		this.setState({ endDate: date }
			, () => {
				let selectedtext = 'Date Range';
				var e = { target: { text: selectedtext }, nodeText: selectedtext  };
				this.onDropDownHandler(e, 'clickHandler');
			});
	}

	onDropDownHandler(e, type = '') {
		const { currentPage, sortColumn, isCriticalSel, isNonCriticalSel } = this.state;
		const { nodeText: text} = e;
		// const { text } = e.target;
		if (text === "Date Range") {
			todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
			weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
		}
		else if (text === 'Week') {
			weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			var startDateMonth = getMonth(new Date(weekDateFormatted));
			var endDateMonth = getMonth(new Date(todaysDateFormatted));

			if (startDateMonth !== endDateMonth) {
				weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}
		this.setState({ type: text,dateFilter : text, datapickflag: text === "Date Range" },()=>{
			this.loadData(1, sortColumn);
		});	
	}

	errorHelpSection = (item) => {
		const { brandName, modelFamilyName, error_code, helpLink }= item;
		
		const linkFormation = `${helpLink}?brand=${brandName}&model=${modelFamilyName}&eventCode=${error_code}`;
		window.open(linkFormation, "_blank");
	}

	// Woopra Events
	currentError = () => {
		WoopraEvents(`${Constants.CURRENT_ERROR}`);
	}

	static getDerivedStateFromProps = (props) => ({ ...props });


	render() {
		const { formatMessage } = this.props.intl;
		$(document).ready(function () {
			$(document).on('click', '.ReactTable .rt-table .rt-thead .rt-tr .rt-th', function () {
				console.log($(this).parent().children().removeClass('-sort-desc'));
				$(this).parent().children().removeClass('-sort-desc');
			});

			// Nav Toggle
			$('.collapsed').off('click').on('click', function () {
				$(this).parents(".panel-collapse").toggleClass('show');
			});
		});

		function CellComponent(props) {
			const { datediff, duration } = props;
			if (datediff && datediff <= 100) {
				return (<div class="progress-bar" style={{ width: '0%' }}>{duration}</div>);
			}
			else if (datediff && datediff <= 43200) {
				return (<div class="progress-bar" style={{ width: '25%' }}>{duration}</div>);
			}
			else if (datediff && datediff <= 86400) {
				return (<div class="progress-bar" style={{ width: '50%' }}>{duration}</div>);
			}
			else if (datediff && datediff > 86400 && datediff < 432000) {
				return (<div class="progress-bar" style={{ width: '75%' }}>{duration}</div>);
			}
			else {
				return (<div class="progress-bar" style={{ width: '100%' }}>{duration}</div>)
			}
		}

		let { data, corporateRoles, sortColumn, loaderIconVisible, isSelectAllRoles,noRecords,
			success_modal, error_modal, currentPage, totalPages, pageSize,isCriticalSel,isNonCriticalSel,isErrorEventSel, isIndeterminate, sortedByName } = this.state;

		let orderId = 1;
		let critical = null;
		let nonCritical = null;
		let totalEventsErrorCount = null;
		if (this.state.currentErrCount["ErrHistoryCount"]) {
			critical = this.state.currentErrCount["ErrHistoryCount"]["critical"]
			nonCritical = this.state.currentErrCount["ErrHistoryCount"]["nonCritical"]
			totalEventsErrorCount = this.state.currentErrCount["ErrHistoryCount"]["eventsError"]
		}
		corporateRoles = _.orderBy(corporateRoles, [corporateRoles => corporateRoles.role_name && corporateRoles.role_name.toString().toLowerCase()], [sortColumn.order]);
		corporateRoles.forEach(item => {
			item.order_id = orderId++
		});
		let allowToDelete = false;
		var rolesList = data;
		rolesList.forEach(r => {
			if (allowToDelete === false) {
				allowToDelete = r.checked;
			}
		});
		let rowNumber = (currentPage * pageSize) - pageSize;
		if (this.state.isCriticalSel === false && this.state.isNonCriticalSel === false && this.state.isErrorEventSel === false){
			noRecords = true;
			data=[];
		}
		let userProfileAuthFeature = authService.getFeature("/allhistoricerrors");
		return (
			<div id="adminWrapper">
				<LoadingPopup loading={loaderIconVisible} />
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="reportWrapper"> 
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/allcurrenterrors" title={formatMessage({id:'KC0185'})}  onClick={() => this.currentError()}><FormattedMessage id="KC0185"/></Link>
									<Link to="/allhistoricerrors" title={formatMessage({id:'KC0186'})}  className="active"><FormattedMessage id="KC0186"/></Link>
								</li>
								<li>
									<div className="checkBoxListGrp">
										<div className="customCheckbox crticalCBox">
											<input id="criticalError" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, 'isCriticalSel')} value="criticalError" checked={isCriticalSel}></input>
											<label for="criticalError"><FormattedMessage id="KC0156"/> <span>{critical}</span></label>
										</div>
										<div className="customCheckbox nonCrticalCBox">
											<input id="nCriticalError" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, 'isNonCriticalSel')} value="nCriticalError" checked={isNonCriticalSel}></input>
											<label for="nCriticalError"><FormattedMessage id="KC0157"/> <span>{nonCritical}</span></label>
										</div>
										<div className="customCheckbox">
											<input id="errorEvent" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, "isErrorEventSel")} 
											value="errorEvent" checked={isErrorEventSel}></input>
											<label for="errorEvent"><FormattedMessage id="KC0422"/> <span>{totalEventsErrorCount}</span></label>
										</div>
										<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={todaysDate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={todaysDate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e, "clickHandler")} filterType={this.state.dateFilter} disableDatePicker={this.state.hideDateRange} />
									</div>
									<button type="button" className={allowToDelete && userProfileAuthFeature && userProfileAuthFeature.is_editable === 1 ? "btn btn-secondary clearErrorBtn" : "btn btn-secondary clearErrorBtn disabled"} data-toggle="modal" data-target="#confirmationDeleteModal"><FormattedMessage id="KC0203"/></button>
								</li>
							</ul>
						</div>
						<div className="allErrorListTable allHErrorListTable">
							<ul className="tHead">
								<li>&nbsp;</li>
								<li>#</li>
								<li><FormattedMessage id="KC0102"/></li>
								<li><FormattedMessage id="KC0194"/></li>
								<li><FormattedMessage id="KC0062"/></li>
								<li>
									<div className="dropdown datepickerBtnGroup">
										<button className="btn dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<FormattedMessage id={modelNameColumnStringIDMap[sortedByName]}/>
										</button>
										<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
											<a onClick={() => this.handleDropDown("model")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0064"/></a>
											<a onClick={() => this.handleDropDown("serialNumber")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0841"/></a>
											<a onClick={() => this.handleDropDown("assetNumber")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0845"/></a>
										</div>
									</div>
								</li>									
								<li><FormattedMessage id="KC0846"/></li>
								<li><FormattedMessage id="KC0050"/></li>
								<li><FormattedMessage id="KC0200"/></li>
								<li><FormattedMessage id="KC0201"/></li>
								<li><FormattedMessage id="KC0202"/></li>
								<li><FormattedMessage id="KC0197"/></li>
								<li>
									<div className={isIndeterminate ? "customCheckbox selectAllDiv customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel"}>
										<input id="selectAll" onChange={(e) => this.handleChange(e)} type="checkbox" value="selectAll" checked={isSelectAllRoles} />
										<label for="selectAll">&nbsp;</label>
									</div>
								</li>
							</ul>
						</div> 
						<div class="allErrorListSrlBar">
						<form className="panel panel-default" id="allErrorsAccordion" role="tablist" aria-multiselectable="true">
							{data.map((item, i) => {
								let PRODUCT_NAME_COLUMN = item.model === "eikon® e1s" ? (<span>eikon<sup>®</sup> e1s</span>) : item.model === "eikon® e2s" ? (<span>eikon<sup>®</sup> e2s</span>) : item.model === "eikon® e4s" ? (<span>eikon<sup>®</sup> e4s</span>) : (<span>{item.model}</span>)
								const helpIconDisable = !(item.error_code && item.brandName && item.modelFamilyName);
								const isDisabled = !item.description && helpIconDisable;
								return (
								<Fragment>
									<div className="allErrorListTable allHErrorListTable">
										<ul className="tBody">
											<li><button role="button" disabled={isDisabled} data-toggle="collapse" data-parent="#allErrorsAccordion" href={"#allErrorsAcc" + i} type="button" class="btn collapseExpand collapsed">&nbsp;</button></li>
											<li>{rowNumber + i + 1}</li>
											<li className="errorStausLi">
												{/* <span class={item.is_critical === 1 ? "errorStaus warnLevel" : "errorStaus alertLevel"}>&nbsp;&nbsp;</span> */}
												{item.is_critical === 0 && <span className="errorStaus alertLevel">&nbsp;&nbsp;</span>}
												{item.is_critical === 1 && <span className="errorStaus warnLevel">&nbsp;&nbsp;</span>}
												{item.is_critical === 2 && <span className="errorStaus errorEvent">&nbsp;&nbsp;</span>}
											</li>									
											<li>{item.error_code}</li>
											<li>{item.brandName}</li>
											<li>
												<h4>{PRODUCT_NAME_COLUMN}</h4>
												{item.serialNumber ? <h5><FormattedMessage id="KC1043"/>: {item.serialNumber}</h5> : ""}
												{item.assetNumber ? <h6><FormattedMessage id="KC1044"/>: {item.assetNumber}</h6> : ""}
											</li>
											<li>{(( item.holder_id === null)? "-":item.holder_id)}</li>											
											<li>
												<h4>{JSON.parse(item.locationDetails).location_name ? JSON.parse(item.locationDetails).location_name : <FormattedMessage id="Welbilt Kitchen"/>}</h4> {/*TO DO*/}
												<h5>{item.withinLocation}</h5>	
											</li>
											<li>{item.frequency} </li>
											<li>{item.error_occured_date ? moment.ConvertLocalTimeFromDeviceUTCTime(item.error_occured_date, "MMMM DD, YYYY HH:mm") : ""}</li>
											<li>{item.error_resolved_date ? moment.ConvertLocalTimeFromDeviceUTCTime(item.error_resolved_date, "MMMM DD, YYYY HH:mm") : ""}</li>
											<li className="progress">{CellComponent(item)}</li>
											<li>
												<div className={"customCheckbox customCheckboxWOlabel"}>
													<input id={i} name={i} type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
											</li>
										</ul>
									</div>

									<div id={"allErrorsAcc" + i} className="panel-collapse collapse" role="tabpanel" aria-labelledby="allErrorsAccH6">
										<div className="panel-body allErrorListExpTable">
											<ul className="tBody">
												<li>&nbsp;</li>
												<li>
													<p>{item.description}</p>
													{/* <a href="troubleshooting.html" class="callServiceIcon">Call Service</a>*/}
													<a
  														onClick={() => helpIconDisable ? '' : this.errorHelpSection(item)}
														title={formatMessage({id:'KC0198'})}
														class={`helpIcon cursor-pointer ${helpIconDisable ? 'disabled' : ''}`}><FormattedMessage id="KC0198"/>
 													</a>
												</li>
											</ul>
										</div>
									</div>
								</Fragment>
							)}
							)}
							{((!loaderIconVisible && data.length === 0 ) || (!loaderIconVisible && noRecords)) ? <NoRecordsFound loaderIconVisible={false} length={(data !== undefined || data.length === 0 || noRecords) ? 0 : 1} classname={'norecordsfoundbluetext'} text={(this.state.searchData && data && data.length === 0 || data.length === 0) ? <FormattedMessage id="KC0853"/> : <FormattedMessage id="KC0205"/>}/>:null}
						</form>
						</div>
						{ totalPages && totalPages > 1 && !noRecords ? <GoToPagination
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
						navigateTo={(p) => this.onPageChange(p)} /> : null }
					</div>
					{/* <!-- Delete Confirmation Modal --> */}
					<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-body">
									<div className="deleteConfirmation">
										<FormattedMessage id="KC0854"/>
					            </div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
									<button className="btn btn-secondary deleteYesBtn" onClick={(e) => this.clearSelectedError(e)} id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/></button>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- //Delete Confirmation -->
	            <!-- Delete Confirmation Modal --> */}
					<div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-body">
									<div className="confirmationDiv">
										<FormattedMessage id="KC1086"/>
					            </div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary confirmationOKBtn" onclick="location.href='cremcurrenterrors.html'" type="button"><FormattedMessage id="KC0777"/></button>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- //Delete Confirmation --> */}
				</div>
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} message={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.errorEloseModel()} />
				<UserManagementHeader headerName="Error Center" headerNameStringID="KC0639" activeClass="errorCenterNav" filterKey={appconstants.errorCenterFilter} history={this.props.history} />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData,
        filterlistInformtion: state.filterModel
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
		searchVisible,
		searchText,getSelectedFilterList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(allHistoricErrors));
