import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, smartGroupAPI, UnitInfo } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import {BASECOUNTRY_ID, getRestrictSmartGroupCountValue } from '../../../utils/appConstants';
import $ from 'jquery';
import _ from 'lodash';
import ConfirmModal from '../../modal/confirm-model';
import { BRAND_ID } from '../../../utils/appConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

var bg = require('../../../images/unit/CremCarrera.svg')
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const restrictValue = getRestrictSmartGroupCountValue();

class smartGrpTopology extends Component {

    onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
        this.props.history.push(`/smartgroupslist`);
	}

    closeModel() {
        this.setState({
            info_modal: {
                open: false
            }
        })
    }

    redirectModel() {
        this.setState({
            error_modal: {
                open: false
            }
        });
        const { formatMessage } = this.props.intl;
        const translatedMsg = formatMessage({ id: 'KC1774' }, { count: restrictValue });
        if(this.state.error_modal.message !== translatedMsg){
		    this.props.history.push('/smartGrpTopology');
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            info_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            checkedUnits: [],
            initialload: true,
            nameFromReport: '',
            access_modal: {
                open: false,
                message: "",
                isHtml: false,
                disabledBtn: false,
            },
            purposeValData:[],
            higherLevelUsersUnits:[],
        };
        this.showLinks = this.showLinks.bind(this);
        this.getData = this.getData.bind(this);
        this.submitAddMedia = this.submitAddMedia.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.redirectModel = this.redirectModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onClickListView = this.onClickListView.bind(this);
    }
    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400 } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.message ? data.message : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1065"
                }

            });
        }
    }

    componentWillMount = () => {
        let { url, data, libraryIds, imageBuffer, previouspath, scheduleDateTimeUtc, Type, libraryType } = this.props.location;
        this.setState({
            url: url,
            data: data,
            libraryIds: libraryIds,
            imageBuffer: imageBuffer,
            previouspath: previouspath,
            scheduleDateTimeUtc: scheduleDateTimeUtc,
            Type: Type,
            libraryType: libraryType,
        });

        if (this.props.location && this.props.location.state) {
            this.setState({
                nameFromReport: this.props.location.state.nameFromReport,
                selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport ,
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        this.getCustGroupID();
        this.updateDimensions();
        if (this.props.location && this.props.location.userCheckedData) {
            let unitList = [];
            for (let i = 0; i < this.props.location.userCheckedData.length; i++) {
                const { ID, BRAND_NAME, BRAND_ID } = this.props.location.userCheckedData[i];
                unitList.push({ ID, BRAND_ID, BRAND_NAME });
            }
            this.setState({ checkedUnits: unitList, isFormValidated: unitList.length > 0 })
        }
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    getData = (url, Cgids) => {
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                reject(err)
            })
        });
    }

    getCustGroupID = () => {
        let loggedinUser;
        let custGroupID = localStorage.getItem("custGroupID");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        this.setState({ loaderIconVisible: true, })
        try {
            this.getOrgnanizationView(custGroupID);
        } catch (err) {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        };
    }

    processOrgnanizationJSON(orgJSON) {
		// const length = orgJSON.start_level_id;
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,
            isUnitItemClicked: true
        });
    }

    getOrgnanizationView = async(values) => {
        this.setState({ loaderIconVisible: true });
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': BRAND_ID?BRAND_ID:"",
                "basecountry": BASECOUNTRY_ID,
                'useremail': loggedinUser,
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
				await $.getJSON(response.data.presignedS3URL, (data) => {
					 this.processOrgnanizationJSON(data);
				});
			}
			else {
				this.processOrgnanizationJSON(response.data);
            }
        }).catch(err => {
            this.setState({
                linkListValue: {},
                loaderIconVisible: false,
            })
        });

    }

    sgPurposeValidations = async () => {
        const { formatMessage } = this.props.intl;
        let userCheckedData = this.state.checkedUnits;

        if(userCheckedData.length >restrictValue ){
            this.setState({
                error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1774' }, { count: restrictValue }),
                },
            });
            return;
        }
        this.setState({ loaderIconVisible: true, })
        let unitObj = [];
        userCheckedData.map(item => {
            return unitObj.push(
                {
                    "unitId": item.ID,
                    "brandId": item.BRAND_ID,
                }
            )
        });

        let postdata = {
            "name": this.state.nameFromReport,
            "purposeId": this.state.selectedoptionsFromReport,
            "customerId": + localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
            "unitObj": unitObj,
            "roleName":  localStorage.getItem("roleName"),
			"childUsers":  JSON.parse(localStorage.getItem('childUsersEmail')),
        };
        await http.post(smartGroupAPI.purposeValidations, postdata,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            if (response && response.data) {
                
                if (response.data.invalidPurposeNames && response.data.invalidPurposeNames.length>0){
                    let txtMsg = `<p>${formatMessage({ id: 'KC1777' }, { purposeNames: `<b>[${response.data.invalidPurposeNames}]</b>` })}</p>
                    <p>${formatMessage({ id: 'KC1778' }, { smartGroupName: `<b>[${this.state.nameFromReport}]</b>` })}</p>`

                    let higherLevelUsers = [];
                    if (response.data.higherLevelUsersCreated && response.data.higherLevelUsersCreated.length > 0){
                        higherLevelUsers = response.data.higherLevelUsersCreated;
                        txtMsg = txtMsg + ` <p><b>${formatMessage({ id: 'KC1753' })}:</b> ${formatMessage({ id: 'KC1755' })}</p>`
                    }

                    let nonHigherUnitObj = [];
                    let selecData = this.state.checkedUnits? this.state.checkedUnits :[];                    
                    for(let i=0; i< selecData.length; i++){
                        const item = selecData[i];
                        let filterVal = higherLevelUsers.filter(j => j.unitId === item.ID);
                        if (filterVal.length === 0 ) {
                            nonHigherUnitObj.push(
                            {
                                "unitId": item.ID,
                            })
                        }
                    }                    

                    this.setState({
                        loaderIconVisible: false ,
                        purposeValData : response.data.unitData,
                        higherLevelUsersUnits : higherLevelUsers, 
                        access_modal: {
                            open: true,
                            message: txtMsg,
                            isHtml: true,
                            disabledBtn : nonHigherUnitObj.length > 0 ? false : true ,
                        },
                    });                    
                } else {
                    this.getSmartGroupID();
                }
            }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1110",
                },
            }); 
        });
    }

    accessNowModel = (e) => {
        this.getSmartGroupID();
        this.setState({
            access_modal: {
                open: false
            }
        })
        WoopraEvents(`${Constants.PURPOSE_VALIDATION_CONFIRM}`);
    }

    accessLaterModel = () => {
        this.setState({
            access_modal: {
                open: false
            }
        })
        WoopraEvents(`${Constants.PURPOSE_VALIDATION_CANCELED}`);
    }

    getSmartGroupID = async (e) => {
		this.setState({ loaderIconVisible: true, })	
		let postdata = {
            "name": this.state.nameFromReport,
			"purposeId":  this.state.selectedoptionsFromReport,
			"customerId": + localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
        };

        let replaceUnit=[];
        let purValData =this.state.purposeValData;
        if (purValData.length>0){
            purValData.map(item => {
                if (item.invalidPurpose.length > 0 && item.higherUserCreated === 0) {
                    return replaceUnit.push(
                        {
                            "unitId": item.unitId,
                            "sgId": item.sgId,
                        }
                    )
                }
            });
        }
        if (replaceUnit.length>0){
            postdata.replaceUnit = replaceUnit;
        }

		await http.post(smartGroupAPI.createSmartGroup , postdata,
			{
				headers: {
					'Content-Type': 'application/json',					
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });
				this.submitAddMedia(response.data.smartGrpID)
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

    submitAddMedia = (smartGroupID) => {
        const { formatMessage } = this.props.intl;

        // e.preventDefault();
        let userCheckedData = this.state.checkedUnits;
        if (!userCheckedData.length) { 
            this.setState({
                loaderIconVisible: false,
                info_modal: {
                    open: true,
                    message: formatMessage({id:"KC1154"})
                }
            });
        } else {
            const unitList = [];
            userCheckedData.map(item => {
                return unitList.push(item.ID)
            });

            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;
            
            let unitObj =[];
            userCheckedData.map(item => {
                return unitObj.push(
                    {"unitId":item.ID,
                    "brandId":item.BRAND_ID,}
                    )
            });

            let parantUserSelUnitID = this.state.higherLevelUsersUnits;
            for (let i = 0; i < parantUserSelUnitID.length; i++) {                
                let index = unitObj.findIndex(j => j.unitId === parantUserSelUnitID[i].unitId);
                unitObj.splice(index, 1);
            }

            let postData = {
                "smartGroupId": smartGroupID,
			    "unitObj": unitObj,
            };
            this.setState({ loaderIconVisible: true });
            let url = smartGroupAPI.createSmartGroupWithUnit;
            this.postData(url, postData).then(response => {              
            }).catch(err => {
                this.showError(err);
            });            
        }
    }

    postData = (url, reqData) => {
        const { formatMessage } = this.props.intl;

        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {

            http.post(url, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    let { data } = response;                    
                    this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: formatMessage({id:"KC1574"}),
                        },
                    });
                    WoopraEvents(`${Constants.SMART_GROUP_CREATED}`);
                }).catch(err => {
                    reject(err);
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: err.response.data.message,
                        },
                    });
                });
        });
    }

    getUnitsViaCGID = async (cgid) => {
        this.setState({ loaderIconVisible: true })
        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid': BRAND_ID?BRAND_ID:"",
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID, BRAND_ID, BRAND_NAME } = response.data.units[i];
                    let isExist = unitSelected.filter((i) => i.ID === ID);
                    if (isExist.length === 0) {
                        unitSelected.push({ ID, BRAND_NAME, BRAND_ID })
                    }
                }
                this.setState({ checkedUnits: unitSelected, loaderIconVisible: false })
            }

        }).catch(err => {
            this.setState({ loaderIconVisible: false })
        });
    }

    unselectCGID = async (cgid) => {
        this.setState({loaderIconVisible: true})

        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid': BRAND_ID?BRAND_ID:"",
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID } = response.data.units[i];
                    let index = unitSelected.findIndex((i) => i.ID === ID);
                    unitSelected.splice(index, 1);
                }
                this.setState({ checkedUnits: unitSelected,loaderIconVisible:false })
            }

        }).catch(err => {
        });
    }

    onItemClick = async (e, link, isSelected) => {
        if(link.level_id === 1){
            return;
        }
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        this.setState({
            veryFirstTime: false,
        })
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }
        
        let cgid = [],prevCgid=[];
        for(let i=0; i<Currentselected.length; i++){
            cgid.push(Currentselected[i].cust_gp_id)
        }
        for(let i=0; i<previousselected.length; i++){
            if(!cgid.includes(previousselected[i].cust_gp_id)){
                prevCgid.push(previousselected[i].cust_gp_id)
            }
        }

        if(prevCgid.length || !isSelected){
            await this.unselectCGID(prevCgid.length ? prevCgid.join(',') : link.cust_gp_id)
        }
        if(isSelected){
            this.getUnitsViaCGID(cgid.join(','));
        }

        this.setState({
            link: link, isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false,
            isFormValidated: dynasty.length > 0 ? true : false
        })
    }

    onUnitItemClick(e, unit) {
        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id, BRAND_ID: unit.BRAND_ID, BRAND_NAME: unit.BRAND_NAME });
        }

        this.setState({
            checkedUnits: unitSelected,
            isFormValidated: unitSelected.length > 0,
            isUnitItemClicked: true,
            link: {},
            isAddressAvailable: false,
            unit: unit,
            isLoactionTypeAvailable: false,
            level: 6
        })
    }
    onOrgCheckBoxChange(e, unit) {
        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id, BRAND_ID: unit.BRAND_ID, BRAND_NAME: unit.BRAND_NAME });
        }

        this.setState({
            isFormValidated: unitSelected.length > 0 ? true : false,
            checkedUnits: unitSelected,
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6,
        })
    }

    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit, index) => {
                let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled" : ""
                treeActive = "";
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                let unitInActive = (unit.ACTIVATION_STATUS === "INACTIVE") ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
                // unitInActive = "";
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = this.state.checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false
                treeActive = ifExist ? "treeActive" : "";
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';

                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        {!showCollpaeCount && <div class="customCheckbox">
                            <input id={unit.UNITID} name={unit.UNITID} type="checkbox" checked={ifExist} value={unit.UNITID} onChange={(e) => this.onOrgCheckBoxChange(e, unit)} />
                            <label for={unit.UNITID}></label>
                        </div>}
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                >{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }
    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    onCollapseButtonClick(e, link) {

        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }

        this.setState({ showLink: showLink })
    }
    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }
    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;


                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link, isParentDivActive === '') }}>
                            {/* {!showCollpaeCount && <div class="customCheckbox" style={{ display: 'list-item' }}>
                                <input id={level} name={groupName} type="checkbox" checked={isParentDivActive ? true : false} value={groupName} onChange={(e) => this.onItemClick(e, link)} />
                                <label for={level}></label>
                            </div>} */}
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                {/* <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" /> */}
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList, isParentDivActive === '') }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }
    
    onClickListView = () => {
        this.props.history.push({
            pathname: "/smartGrpAssignUpdateList",
            userCheckedData: this.state.checkedUnits,
            state: {
                nameFromReport: this.state.nameFromReport,
                startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
                endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
                selectedoptionsFromReport: this.state.selectedoptionsFromReport,
            },
            previouspath: this.state.previouspath,
            scheduleDateTimeUtc: this.state.scheduleDateTimeUtc,
            Type: this.state.Type,
            libraryIds: this.state.libraryIds,
            libraryType: this.state.libraryType
        });
    }

    render() {
        const { formatMessage } = this.props.intl;

        let { success_modal, error_modal, info_modal, selectedGroupName } = this.state;
        const { loaderIconVisible, linkList,access_modal } = this.state;

        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="unitRegistrationWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>
                                    <Link to={{
                                        pathname: "/createnewsmartgrp",
                                        state: {
                                            nameFromReport: this.state.nameFromReport,
                                            selectedoptionsFromReport: this.state.selectedoptionsFromReport,
                                        }
                                    }} title={formatMessage({id:"KC0989"})} class="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id='KC0711'/>
					            </li>
                                <li >
                                    <Link id="#btnfinish" to="#" className={`btn btn-default-text nextText ${this.state.checkedUnits.length > 0 ? "activeState" : "disabled"}`} title={formatMessage({id:"KC0056"})} onClick={(e) => this.sgPurposeValidations(e)}><FormattedMessage id='KC0056'/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            <ul>
                                <li>
                                    {
                                    selectedGroupName.map((subitem, j) =>
                                        <span className="selectedVal">
                                        {subitem.group_name} &nbsp; 
                                        </span>
                                    )}
                                </li>
                                <li>
                                    <Link to="#" class="assignunitSite active">&nbsp;</Link>
                                    <button id="btnAssign"  className="border0 assignunitList" onClick={this.onClickListView}>&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                         
                            <div className="collapseExpandHeader">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>

                            <div className="assignOrgTreeOuter">

                                <div className="orgznSiteTree">
                                    {this.state.linkList && this.state.linkList.level1 &&
                                        this.state.linkList.level1.map((item) => this.showLinks(this.state.findTreeLevel))}
                                </div>

                            </div>
                         
                    </div>
                </div>
                <ErrorMoal open={info_modal.open} stringID={info_modal.message} onCloseModel={this.closeModel} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.redirectModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" />
                <ConfirmModal disabled = {access_modal.disabledBtn} open={access_modal.open} message={access_modal.message} isHtml={access_modal.isHtml} onAccessNowModel={(e) =>this.accessNowModel(e)} onAccessLaterModel={(e) =>this.accessLaterModel(e)}/>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(smartGrpTopology));