import React, { Component } from 'react';
import { connect } from 'react-redux';
import  http from '../../service/httpService';
import auth from '../../service/authService';
import {BaseService, UnitInfo, UserProfile, UserManagement, UserMgmtAddUser, DeviceRegistration,chinaGovRegistrationLink} from '../../service/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import FLSuccessMoal from '../modal/fl-success-model';
import FLSuccessMoalBackToHome from '../modal/fl-home-success-model';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';
import { setcustomerId }  from '../../utils/appConstants';
import { setbasecountryId }  from '../../utils/appConstants';
import { setaccessCountryId, bakingPlanCustomer } from '../../utils/appConstants';
import AccessModal from '../modal/access-model';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect';
// Woopra event track
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import SupportButton from '../../components/controls/supportButton'
import { FormattedMessage, injectIntl } from 'react-intl';
import { setLocale } from '../../actions/localeAction/localeAction';
import { bindActionCreators } from 'redux';
import { resetLocale } from "../../actions/localeAction/localeAction";
import {isCaptchaEnabled }  from '../../utils/captcha';
import {footerTextCustom, icpTextCustom} from '../../components/controls/footerComponent';

const HTTP_METHOD ={
	GET:'GET',
	PUT:'PUT',
	POST:'POST',
	DELETE:'DELETE'
}

let formIsValid = true;
var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);

class Login extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	removeSpacesOnChange = (e) => {
		var keyCode = e.which || e.keyCode;
		if (keyCode === 32) {
            e.preventDefault();
            return false;
        }
	}

	componentDidMount() {
		this.props.resetLocale();
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");	
		this.updateLoginDimensions(); 
		$("body").removeClass("modal-open");
		$("body").removeClass("deviceRegtrnBg");
		if(!localStorage.getItem('isCookieAccepted')){
			$("#cookieWrapper").hide();
			$("#cookieWrapperBtn").show();
			$('.supportButtonWrapper').hide();
		}
		//get Maintenance Text during deployment
		this.getMaintenanceText();

		//get Ready Recipe details from brand website
		const search = this.props.location.search;
		const params = new URLSearchParams(search);
		const readyrecipeids = params.get('ready_recipe');
		const name = params.get('name');
		const brand = params.get('brand_id');
		const model = params.get('equipment_model');
		if (readyrecipeids && (brand === "Convotherm" || brand === "Merrychef")) {
			let objbrandsitedata = {};
			objbrandsitedata.readyrecipeids = readyrecipeids;
			objbrandsitedata.name = name;
			objbrandsitedata.brand = brand;
			objbrandsitedata.brandid = brand === "Merrychef" ? 1 : 6;
			objbrandsitedata.model = model;
			localStorage.setItem("kcbrandsitedata", JSON.stringify(objbrandsitedata));
			localStorage.setItem("selectedbrandID", objbrandsitedata.brandid);
			localStorage.setItem("brandName", brand ? brand.toUpperCase() : '');
			localStorage.setItem("selected_cgid", '[]');
			localStorage.setItem("selectedFamilyID", '26,27,36'); // allowing only C4, maxx & maxx pro model families
		}

		if(auth.getJwt()) {
			this.redirect();
		}
		else if(auth.getRememberMe())
		{
			let credentails = auth.getCurrentUser();
			if (credentails!=null)
			{
				this.setState({
					loaderIconVisible: true,
					rememberme: true,
					fields : {
						emailid:credentails.username,
						password:credentails.password
					}
				});
				auth.login(credentails, true).then(response =>{
					this.setState({
						loaderIconVisible: false
					});
					this.redirect();
				}).catch(err => {
					this.showError(err, credentails.username, credentails.password);
					this.setState({
						loaderIconVisible: false
					});
				});
			}
		}

		setTimeout(()=>{
			var width = $('.g-recaptcha').parent().width();
			var scale = width / 304;
			$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('transform-origin', '0 0');
			$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
			$('.g-recaptcha').css('display', 'block');
		}, 1000);
		
	}

	redirect() {
		if (localStorage.getItem("kcbrandsitedata")) {
			let objbrandsitedata = JSON.parse(localStorage.getItem("kcbrandsitedata"));
			if (objbrandsitedata.readyrecipeids) {
				if(objbrandsitedata.brand.toUpperCase() === 'MERRYCHEF'){
					return this.props.history.push(`/assignLegacyRecipeToTopology`);
				} else if (objbrandsitedata.brand.toUpperCase() === 'CONVOTHERM'){
					return this.props.history.push(`/assignLegacyCookbookToTopology`);
				}
			}
		}
		else {
			this.props.history.push('/dashboardLocations');
		}
	}

	updateLoginDimensions() {
		var windowHeight = $(window).height(); 
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25;
		var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
		var cookieWrapperH = $(".cookieWrapper").height() + 10; 
		//Sign In & Sign Up Modal
		var maintenanceTextH = $(".maintenanceText ").height() + 35;
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH) + (cookieWrapperH) + (maintenanceTextH)); 
		var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH)) - 60;   
		$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS);  
	}
	onSuccessCloseModal() {
		this.setState({
			success_modal:{
				open:false				
			}
		})
		this.props.history.push(`/dashboardLocations`);
	}

	onFLSuccessCloseModal() {
		this.setState({
      		fl_home_success_modal:{
				open:false				
			}
    	})
		if (this.state.goToConsumer) {
			this.setState({ goToConsumer: false })
			this.props.history.push('/consumer')
		} else {
			this.props.history.push('/');
		}
	}
	constructor(props){
        super(props);  
		this.state = {
			username:'',
			password:'',
			newpassword: '',
			retypenewpassword: '',
			rememberme: false,
			isVerified: false,
			goToConsumer:false,
			error_modal:{
				open:false,
				message:""
			  },
			  fl_success_modal: {
				  open:false,
				  message:""
			  },
			  fl_home_success_modal: {
				open:false,
				message:""
			  },
			  success_modal: {
				  open:false,
				message:""
			  },
			  access_modal: {
				open:false,
			  message:""
				},
				loaderIconVisible:false,
			fields: {},
        	errors: {},
					isFormValidated:false,
					saveData:{},
					showhidepassword: false,
					showhidenewpassword:false,
				  showhideretypenewpassword:false,
					 type: 'password',
					 newpwdtype: 'password',
					 oldpwdtype: 'password',
					 retypenewpwdtype: 'password',
					 showFirstLogin: false,
					 maintenanceText: '',
					 isCookieAccepted:localStorage.getItem('isCookieAccepted') ? localStorage.getItem('isCookieAccepted') : false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.emailblurevent = this.emailblurevent.bind(this);
		this.passwordblurevent = this.passwordblurevent.bind(this);
		this.newpasswordblurevent = this.newpasswordblurevent.bind(this);
		this.retypenewpasswordblurevent = this.retypenewpasswordblurevent.bind(this);
		this.postComment = this.postComment.bind(this);
		this.closeModel = this.closeModel.bind(this); 
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.onFLSuccessCloseModal = this.onFLSuccessCloseModal.bind(this);
		this.togglePwd = this.togglePwd.bind(this);
		this.toggleOldPwd = this.toggleOldPwd.bind(this);
		this.toggleNewPwd = this.toggleNewPwd.bind(this);
		this.toggleRetypeNewPwd = this.toggleRetypeNewPwd.bind(this);
		this._reCaptchaRef = React.createRef();
		this.verifyCallback = this.verifyCallback.bind(this);
		this.accessNowModel = this.accessNowModel.bind(this);
        this.accessLaterModel = this.accessLaterModel.bind(this);
	}

	getMaintenanceText() {
		this.setState({
			access_modal:{
				open:false				
			  }
		})

		http({
			method: "POST",
			url: UserManagement.getMaintenanceText,
			data: { "message": "MAINTENANCE_TEXT" },											
		}).then(response =>{
			let { status, data } = response;
			if (status === 200)
			{
				this.setState({
					loaderIconVisible: false,
					maintenanceText: data.data === "NA" ? "" : data.data
				}); 
			}
		})
	}

	accessNowModel() {
		this.setState({
			access_modal:{
				open:false				
			  }
		})

		let postData = {
						"type": "request-login-access",
						"user": {
								"email": this.state.username
								}
				   		}

		http({
			method: "POST",
			url: UserManagement.requestAccess,
			data: postData,											
		}).then(response =>{
			let { status } = response;
			if (status === 200)
			{
				this.setState({
				loaderIconVisible: false,
				success_modal:{
					open: true,
					message: "Email notification sent to admin." //MLS:TODO
				},
				}); 
			}
		})
	}

	accessLaterModel() {
		this.setState({
			access_modal:{
				open:false				
			  }
		})
		this.props.history.push('/');
	}

	closeModel() {
		this.setState({
      error_modal:{
				open:false				
			  },
			  fl_success_modal:{
				open:false				
			  },
			   fl_home_success_modal: {
				  open:false,
				  message:""
			  }
    })
	}

	 handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });		
    }	
	
		handleEmailChange(e) {
			let fields = this.state.fields;
			fields[e.target.name] = e.target.value.toLowerCase().trim();
			this.setState({ 
					fields
			});      
	}

	emailblurevent() {
		let fields = {};			  
		let inputdataemailobj = this.state.fields.emailid;
		let errors ={...this.state.errors};
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if(inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {			
			this.setState({
					errors: {
								...this.state.errors,
								emailid: ''										
								
					}
			})												
		} 
		return false;	
	}
	passwordblurevent() {
		let fields = {};
		let inputdatapwdobj = this.state.fields.password

		let errors = { ...this.state.errors };
		var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);
		// remove error condition for pwd id
		if (inputdatapwdobj && inputdatapwdobj !== "undefined" && pwdmatch.test(inputdatapwdobj)) {
			this.setState({
				errors: {
					...this.state.errors,
					password: ''
				}
			})
		}
		return false;
	}
	 
	newpasswordblurevent() {		
		let fields = {};			  				
		let inputdatanewpwdobj = this.state.fields.newpassword

		let errors ={...this.state.errors};		
		var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);		
		// remove error condition for pwd id
		if(inputdatanewpwdobj && inputdatanewpwdobj!== "undefined" && pwdmatch.test(inputdatanewpwdobj)){				
				this.setState({
						errors: {
									...this.state.errors,
									newpassword: ''
						}
				})																		
		}
		return false;							
	}

	retypenewpasswordblurevent() {		
		let fields = {};			  				
		let inputdataretypenewpwdobj = this.state.fields.retypenewpassword

		let errors ={...this.state.errors};		
		var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);		
		// remove error condition for pwd id
		if(inputdataretypenewpwdobj && inputdataretypenewpwdobj!== "undefined" && pwdmatch.test(inputdataretypenewpwdobj)){				
				this.setState({
						errors: {
									...this.state.errors,
									retypenewpassword: ''
						}
				})																		
		}
		return false;							
	}

	togglePwd() {
		this.setState({
			showhidepassword: !this.state.showhidepassword,
			type: this.state.type === 'password' ? 'text' : 'password'
		})
	}
	toggleOldPwd() {		
		this.setState({
			showhideoldpassword: !this.state.showhideoldpassword,
			oldpwdtype: this.state.oldpwdtype === 'password' ? 'text' : 'password'
		})

	}

	toggleNewPwd() {
		this.setState({
			showhidenewpassword: !this.state.showhidenewpassword,
			newpwdtype: this.state.newpwdtype === 'password' ? 'text' : 'password'
		})
	}

	toggleRetypeNewPwd() {
		this.setState({
			showhideretypenewpassword: !this.state.showhideretypenewpassword,
			retypenewpwdtype: this.state.retypenewpwdtype === 'password' ? 'text' : 'password'
		})
	}

	showError= (err, inputdataemailobj, inputdatapwdobj)=>{
		let { response } = err;	
        if(response){
			let { data } = response;			
			if(response && response.status === 500){ 	 
				let { body } = data;
				
				if( data.errorCode === "FIRST-LOGIN") {
					this.setState({
						loaderIconVisible: false,
						fl_success_modal: {
							open: true,
							message: "Since this is a first login, Please create the new Password."
						},
						error_modal: {
							open: false,
						},
						showFirstLogin: true,
						username: inputdataemailobj,
						password: inputdatapwdobj,
			
					});
				}
				else if(data.responseCode === "KC_ERR_500" && data.message === "User is not active.") {  
					this.setState({
						loaderIconVisible: false,
						access_modal:{
							open:true,
							message: "Your account has been disabled."
						},
						username:inputdataemailobj
					}); 
				}
				else{
					this.setState({						
						error_modal: {
							open: true,
							message: 'KC0020'
						},
							fields: {
										...this.state.fields,
										emailid: '',
										password: ''																											
							},
							loaderIconVisible: false
				
						 });
						 let fields = {};	
				}
			}
		} else {
			this.setState({
				fields: {},
				success_modal: {
					open: false,
					message: `You successfully created your personal password! Please Log in` //MLS:TODO
				},
				loaderIconVisible: false,
				showFirstLogin: false
			});
		}	
	}

	
	postComment =()=>{
	
		if (this.validateForm()) {						
			this.setState({loaderIconVisible: true}, ()=> {

			let postData ={
				username:this.state.fields.emailid,
				password:this.state.fields.password,
			}
			
			this.putData(postData, BaseService.login, HTTP_METHOD.POST, this.state.rememberme, this.state.fields.emailid, "login").then((response) =>{
				this.setState({fields: {}});
			}).catch(err => {
				console.log("ERROR in POST = ",err)
				this.setState({fields: { emailid:'', password:'' }});
				this.showError(err, postData.username, postData.password);
				return ;
			});
		});
		}
		else{

		this.setState({
			loaderIconVisible: false
		});
		}

	}
	        
	createPassword = (e) => {
		this.setState({
			loaderIconVisible: true
		});
		let username = this.state.fields.emailid;
		if (this.validateSignupForm()) {
			let fields = {};
			this.setState({ fields: fields });
			let postData = {
				username: this.state.username,
				oldPassword: this.state.password,
				newPassword: this.state.fields.newpassword
			};

		http({
				method: "PUT",
				url: BaseService.first_login,
				data: postData,											
			}).then(res =>{
				let { data } = res;
			   let { statusCode } = data;
			   if(statusCode === 200){ 	 
					//  let { body } = data
					let postDataRes ={
						username: postData.username,
						password: postData.newPassword,
					}
					this.setState({
						// fl_home_success_modal: {
						// 	open: true,
						// 	message: 'You successfully created your personal password!'
						// },
						showFirstLogin:false
					},()=>{
						this.putData(postDataRes, BaseService.login, HTTP_METHOD.POST, this.state.rememberme, postData.username, "createPassword").then((response) =>{
							this.setState({fields: {}});
						})  
					});
				}
			}).catch(err => {

				this.showSignupError(err);
			});
		} else {
			this.setState({
				loaderIconVisible: false
			});
		}
	}

	rememberMeCheckboxChange = (e) => {
		this.setState({
			rememberme: e.currentTarget.checked
		});
	};

	showSignupError= (err)=>{
	let { response } = err;	
	let postData ={
		username:this.state.fields.emailid,
		password:this.state.fields.password,
		newpassword:this.state.fields.newpassword
	}				
			if(response){
		let { data } = response;
		let { statusCode } = data;
		let fields = {};
		let inputdataemailobj = this.state.fields.emailid;
		let inputdatapwdobj = this.state.fields.password;
		let inputdatanewpwdobj = this.state.fields.newpassword;
		if(data){
				this.setState({						
				error_modal: {
					open: true,
					message: 'KC0020'
				},
					fields: {
								...this.state.fields,
								emailid: '',
								password: '',
								newpassword: ''	,
								retypenewpassword:''																										
					},
					loaderIconVisible: false
		
				 });
				 let fields = {};							  					
		}
	}
	else {
		this.setState({
			success_modal: {
				open: false,
				message: `You successfully created your personal password! Please Log in` //MLS:TODO
			},
			loaderIconVisible: false,
			showFirstLogin: false
		});
	}
}

	validateForm() {

		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
  		  
		if (!fields["emailid"]) {			
		  formIsValid = false;
		  errors["emailid"] = 'KC0017';
		}
  
		if (typeof fields["emailid"] !== "undefined") {
		  //regular expression for email validation
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["emailid"])) {
			formIsValid = false;
			errors["emailid"] = 'KC0018';
		  }
		}
      
		if (!fields["password"]) {
		  formIsValid = false;
		  errors["password"] = 'KC0019';
		}
			
		if (isCaptchaEnabled() && !this.state.isVerified && this.state.isVerified != undefined) {
			formIsValid = false;
			errors["recaptcha"] = "Please select reCAPTCHA."; //MLS :TODO
		}
		
		this.setState({
		  errors: errors
		});
		return formIsValid;
		}
		
		ValidateinputField = (e) => {
			let controlid = e.target.name;
			let errorOldPassword="";
			let errorNewPassword="";
			let errorRetypeNewPassword="";
	
	if(e.target.name !=null){
	
					if(controlid === "password"){
							this.setState({ errorOldPassword: errorOldPassword });
					}
					if(controlid === "newpassword"){
							this.setState({ errorNewPassword: errorNewPassword });
					 }
					 if(controlid === "retypenewpassword"){
							this.setState({ errorRetypeNewPassword: errorRetypeNewPassword });
					 }
			}
	}

		validateSignupForm() {

			let fields = this.state.fields;
			let oldPasswordCheck = this.state.password;
			let errorOldPassword="";
			let errorNewPassword="";
			let errorRetypeNewPassword="";
			let formIsValid = true;
	
			if (!fields["newpassword"]) {
				formIsValid = false;
				errorNewPassword = "Please enter your new password."; //MLS:TODO
			}
			else if (!fields["newpassword"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?_+;.,:><|/]).*$/)) {
				formIsValid = false;
				errorNewPassword = "New Password should be minimum of 8 charaters and contain at least one lowercase, uppercase, numeric and special charaters."; //MLS:TODO
				}

			if ((errorNewPassword == "") && (oldPasswordCheck === fields["newpassword"])) {
				formIsValid = false;
				errorNewPassword = "New password should not be same as temporary password."; //MLS:TODO
			}

			if(!fields["retypenewpassword"]) {
				formIsValid = false;
			  errorRetypeNewPassword = "Please enter your retype new password."; //MLS:TODO
			}

			if ((errorNewPassword == "" && errorRetypeNewPassword == "") && (fields["newpassword"] != fields["retypenewpassword"])){
				formIsValid = false;
				errorRetypeNewPassword = "Retype new password should match with new password."; //MLS:TODO
		}
			
			this.setState({
				errorNewPassword : errorNewPassword,
			  errorRetypeNewPassword : errorRetypeNewPassword
			});
			return formIsValid;
			}

			getCustomerID = async (customerID, emailID, baseCountry) => {
				let custGroupIDList = "";
				var setcustGroupIDList="";
			 	await http({
					method: "GET",			
					url: `${UserProfile.get_Cust_GroupID}`,	
					headers: {			
						'customer_id': customerID,
						'email': emailID,	
						'Content-Type': 'application/json',
						'base_country': baseCountry,
						'emulation': false
					},data:{},
				}).then(async response => {
					custGroupIDList=response.data.data;
					custGroupIDList.forEach(p => {
						setcustGroupIDList?setcustGroupIDList=setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList=p.customer_group_id			
					});
					await this.getUnitImage(customerID, setcustGroupIDList);
					localStorage.setItem("custGroupID", setcustGroupIDList);
					await this.getUserStartLevelID(setcustGroupIDList);
					let accessFeatures = JSON.parse(localStorage.getItem("allFeatures"));
					if (accessFeatures[0].feature_category == "Main Menu Apps") {
						this.props.history.push(`/mainmenu`);
					}
					else {
						/** starts:pwp for exporbar csv redirect*/
						const unitBrandId = localStorage.getItem("csvUnitBrandId");
						const unitId = localStorage.getItem("csvUnitID");
						if(unitId !== null && unitBrandId !== null){
							csvExUnitRedirect(unitId, unitBrandId, this.props.history.push)
						}
						else {
							this.redirect();
						}
						/**ends:pwp */
					}
				}).catch(err => {
					
				});
			}

			getUserStartLevelID = async(values) => {
				let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        		let loggedinUser = loginUser.username;
				await http({
					method: "GET",			
					url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,	
					headers: {				
						"Cgids":values,
						'Content-Type': 'application/json',
						'useremail': loggedinUser
					},data:{},
				}).then(async response => {	
					if (response.data.fetchFromS3) {
						await $.getJSON(response.data.presignedS3URL, (data) => {
							localStorage.setItem("custLevelID", data.start_level_name);
							localStorage.setItem("startLevelID", data.start_level_id);
							localStorage.setItem("parentCustGroupId", data.parent_group_id);
						})
					}
					else {
						localStorage.setItem("custLevelID", response.data.start_level_name);
						localStorage.setItem("startLevelID", response.data.start_level_id);
						localStorage.setItem("parentCustGroupId", response.data.parent_group_id);
					}
				}).catch(err => {					 
				});
		}

		getUnitImage = async(customerId, custGroupID) => {
			const urlVal = UnitInfo.unitImage;
			await http({
				method: "GET",
				url: urlVal,
				headers: {
					"brandid" : localStorage.getItem('brandId') ? JSON.parse(localStorage.getItem('brandId')).join(',') : '',
					"cgid": custGroupID,
					"cid":customerId,
					'Content-Type': 'application/json',
				}, data: {},
			}).then(response => {
				if(response && response.data ){						
					localStorage.setItem("modelImage", JSON.stringify(response.data.units));
				}
			}).catch(err => {
				localStorage.setItem('modelImage',JSON.stringify([]));
			});
		}

		getChildUserEmailList = async(customerId,loggedinUser) => {
			const urlVal = UserMgmtAddUser.addUser + "?customerId=" + customerId + "&email=" + loggedinUser;
			await http({
				method: "GET",
				url: urlVal,
				headers: {
					"cgid": '',
					'Content-Type': 'application/json',
				}, data: {},
			}).then(response => {
					let email = [];
					if(response.data && response.data.data && response.data.data !== "No Records Found"){
						for(let i=0; i<response.data.data.length; i++){
							const ele =response.data.data[i];
							email.push(ele.email);
						}
					}
					localStorage.setItem('childUsersEmail',JSON.stringify(email));
			}).catch(err => {
				localStorage.setItem('childUsersEmail',JSON.stringify([]));
			});
		}

		getUserRole = async (customerId, userName) => {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': customerId,
					'email': userName,
					'Content-Type': 'application/json'
				},data:{},
			}).then(response => {
				localStorage.setItem("roleName", response.data.role_name);
				localStorage.setItem("customerName", response.data.customer_name);
				localStorage.setItem("userName", response.data.name ? response.data.name : "");
				this.woopraIndentify(userName, response.data.role_name,customerId);
			}).catch(err => {
			});
		}

		getCustomerGroupdet = async (customerId, userName) => {
			http.get(BaseService.customer_Group_det,
				{headers: {
					'customer_id': customerId,					
					'Content-Type': 'application/json'
				},data:{},
			}).then(async response => {
				const {short_name} = response.data.groupDetails ? response.data.groupDetails : {}
				const showBP = short_name && short_name.toLowerCase() === bakingPlanCustomer ? 1 : 0;
				localStorage.setItem("enableBakingPlan", showBP);  		
				localStorage.setItem("groupDetails", response.data.groupDetails ? JSON.stringify(response.data.groupDetails): {});  		
				localStorage.setItem("subscriptionId", localStorage.getItem('subscriptionId') !== "" ? localStorage.getItem('subscriptionId') : response.data.subscriptionDetails.chargify_subscription_id ? response.data.subscriptionDetails.chargify_subscription_id : "");
				localStorage.setItem("subscriptionPlan", response.data.subscriptionDetails.kc_subscription_plan_id ? response.data.subscriptionDetails.kc_subscription_plan_id : "");
				localStorage.setItem("region", response.data.regionDetails.country_region ? response.data.regionDetails.country_region : "");				
				if (localStorage.getItem('isEAAS')==='false'){
					await this.getCustMngPortalLinkDet(response.data);
				}
			}).catch(err => {
			});
		}

		getCustMngPortalLinkDet = async (data) => {		
			let chargCustId;
			if (data.eaas_flag){
				localStorage.setItem("subscriptionId", data.chargify_subscription_id ? data.chargify_subscription_id : "");
				if (data.chargify_customer_attributes && data.chargify_customer_attributes.subscription && data.chargify_customer_attributes.subscription.customer) {
					chargCustId = data.chargify_customer_attributes.subscription.customer.id
					localStorage.setItem("chargifyCustID", chargCustId);
				}

				if (data.billing_Portal_url && data.billing_Portal_url.url) {
					localStorage.setItem("billingPortalUrl", data.billing_Portal_url.url);
				} else {
					const subscID = data.chargify_subscription_id ? data.chargify_subscription_id : "";
					if (chargCustId && subscID) {
						await this.updatePortalURL(chargCustId, subscID);
					}
				}
			} else {		
				localStorage.setItem("subscriptionId", data.subscriptionDetails && data.subscriptionDetails.chargify_subscription_id ? data.subscriptionDetails.chargify_subscription_id : "");	
				if (data.subscriptionDetails.chargify_customer_attributes && data.subscriptionDetails.chargify_customer_attributes.subscription && data.subscriptionDetails.chargify_customer_attributes.subscription.customer) {
					chargCustId = data.subscriptionDetails.chargify_customer_attributes.subscription.customer.id
					localStorage.setItem("chargifyCustID", chargCustId);
				}

				if (data.subscriptionDetails.billing_Portal_url && data.subscriptionDetails.billing_Portal_url.url) {
					localStorage.setItem("billingPortalUrl", data.subscriptionDetails.billing_Portal_url.url);
				} else {
					const subscID = data.subscriptionDetails && data.subscriptionDetails.chargify_subscription_id ? data.subscriptionDetails.chargify_subscription_id : "";
					if (chargCustId && subscID) {
						await this.updatePortalURL(chargCustId, subscID);
					}
				}
			}
		};		

		updatePortalURL = async (chargifyCustId, subscrId) => {
			this.setState({
				access_modal: {
					open: false
				}
			})
			await http({
				method: "POST",
				url: BaseService.updateSubscription,
				data: {
					"isTestType": "billingPortalMgmt",
					"chargifyCustId": chargifyCustId,
					"subscriptionId": subscrId,
					"isEaas": localStorage.getItem('isEAAS') === 'true' ? true : false,
				},
			}).then(response => {
				let { status, data } = response;
				if (status === 200) {
					localStorage.setItem('billingPortalUrl', data.url);
					this.setState({
						loaderIconVisible: false,
					});
				}
			})
		};

		// Woopra Initial Call
		woopraIndentify = (username, roleName,id) => {
			window.woopra.identify({
				email: username,
				customerId: id,
				rolename: roleName,
				childUser:localStorage.getItem('childUsersEmail') || [],
				baseCountry:localStorage.getItem('basecountry'),
			});
			window.woopra.identify("email", username).push();
			WoopraEvents(`${Constants.USER_LOGIN}`);
		}

		putData =(postData, url, method, rememberme, username, from) =>{
			return new Promise((resolve, reject)=>{
			  auth.login(postData, rememberme).then(async res => {

				let { status, data:loginData } = res;
				   if(status === 200) {
					//API call to check the customer is EAAS user or not.
					http.get(DeviceRegistration.eaasThankyou, {
						headers: {
							'Content-Type': 'application/json',
							username 
						},
						data: {}
					}).then(async response => {
						let { status, data } = response;
						//user is eaas customer and not subscribed
						if (data && data.eaas_flag && !data.isSubscribed) {
							if(from === "createPassword"){
								this.setState({ goToConsumer:true })
							}

							if(localStorage.getItem('isCookieAccepted')){
								localStorage.clear();
								localStorage.setItem('isCookieAccepted', true);
							}else{
								localStorage.clear();
							}
							
							localStorage.setItem("isEAAS", data.eaas_flag);
							localStorage.setItem("Access-Token", loginData.accessToken);
							localStorage.setItem("consumerData", JSON.stringify(data));
							resolve(this.props.history.push('/consumer'))
						}else if(data && data.eaas_flag && data.isSubscribed && data.showUnits){
							if(localStorage.getItem('isCookieAccepted')){
								localStorage.clear();
								localStorage.setItem('isCookieAccepted', true);
							}else{
								localStorage.clear();
							}
							localStorage.setItem("consumerData", JSON.stringify(data));
							localStorage.setItem("isEAAS", data.eaas_flag);
							localStorage.setItem("Access-Token", loginData.accessToken);
							const loginResponse = {
								username, loginData
							}
							await localStorage.setItem("loginConsumserResponse", JSON.stringify(loginResponse));							
							await this.getCustMngPortalLinkDet(data);
							resolve(this.props.history.push('/list-units'))
						}else if(data && data.eaas_flag && data.isSubscribed && !data.showUnits){
							localStorage.setItem("isEAAS", data.eaas_flag);
							await this.getCustMngPortalLinkDet(data);
							await this.loginResponseAction(username, res);														
						} else {
							localStorage.setItem("isEAAS", false);							
							await this.loginResponseAction(username, res);	
						}
					}).catch(async (err) => {
						// this catch block contains normal login process because the above http api is created to check the customer is eaas user and its newly created api, for better handle puprpose this code is written. Once stable api in all ENV, need to update with appropiate error.
						await this.loginResponseAction(username, res);	
					})			   
				}
				   else{
					   reject({ res : {
						   data : res.data
					   }});					 					 			
				   }  				
			  }).catch(err=>{
				  reject(err);
			  });
			});  
			}

		verifyCallback = (recaptchaToken) => {
			if (recaptchaToken) {
				this.setState({
					isVerified: true,
					errors: {
						...this.state.errors,
						recaptcha: ''
					}
				});
			}
		}

		handleEnterButton = (e) => {
			if(e.charCode === 13 || e.key === 'Enter'){
				this.postComment();
			}
		}
		
	// Woopra Events

	trackEvent = (e) => {
		WoopraEvents(`${Constants.CREATE_ACCOUNT}`);
	}
	forgotPass = () => {
		WoopraEvents(`${Constants.FORGOT_PASSWORD}`);
	}

	acceptCookie = () =>{
		this.setState({
			isCookieAccepted: true
		});
		localStorage.setItem('isCookieAccepted',true);
		$("#cookieWrapper").show(); 
		$("#cookieWrapperBtn").hide(); 
		$('.supportButtonWrapper').show();
	}

	loginResponseAction = async (username, res) => {
		localStorage.setItem("customerId", res.data.customerId);
		localStorage.setItem("basecountry", res.data.basecountry);
		localStorage.setItem("accesscountry", res.data.accesscountry);
		localStorage.setItem("roleId", res.data.role);
		localStorage.setItem("timeFormat", res.data.userTimeNumberPreferences.length > 0 && res.data.userTimeNumberPreferences[0].preference_value ? parseInt(res.data.userTimeNumberPreferences[0].preference_value) : parseInt("12"));
		localStorage.setItem("thousandSeparator", res.data.userTimeNumberPreferences.length > 1 && res.data.userTimeNumberPreferences[1].preference_value ? res.data.userTimeNumberPreferences[1].preference_value : ",");
		setcustomerId(res.data.customerId ? res.data.customerId : 1);
		setbasecountryId(res.data.basecountry ? res.data.basecountry : "");
		setaccessCountryId(res.data.accesscountry ? res.data.accesscountry : "");
		localStorage.setItem("user", username);
		await this.getChildUserEmailList(res.data.customerId, username);
		await this.getUserRole(res.data.customerId, username);
		await this.getCustomerGroupdet(res.data.customerId);		
		this.props.setLocale(res.data.language_preference.language_code);
		await this.getCustomerID(res.data.customerId, username, res.data.basecountry);
		this.setState({
			isFormValidated: true,
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: `You have successfully logged in "${username}".` //MLS:TODO
			},
		});
	}

	render() {
	const { error_modal, success_modal, fl_success_modal,  fl_home_success_modal, loaderIconVisible, showFirstLogin, access_modal, isFormValidated, maintenanceText} = this.state;	
	const { formatMessage } = this.props.intl;
	let icpNumber = (icpTextCustom() ?  <a target="_blank" href={'https://beian.miit.gov.cn/#/Integrated/recordQuery'} class="footerCookiePolicy" title="浙ICP备2022028829号">{icpTextCustom()}</a> :"");
	return (
		<React.Fragment>
			<div className="loginBg">
			<div id="adminWrapper">        
				<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress --> 
				
				<!-- Fixed Header navbar --> */}
				<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">   
					<Link className="navbar-brand welbiltLogo" title={formatMessage({id: 'KC0038'})} to="#">&nbsp;</Link>  
				</nav>
				{/* <!-- //Fixed Header navbar -->
				
				<!-- //Fixed Header navbar --> */}
				<div id="page-wrapper"> 
				<div className="welcomText">
					<FormattedMessage id='KC0006' />
				</div>
				<div className="maintenanceText">{maintenanceText}</div>
					{/* <!-- Login Form --> */}
						<div className="LoginBtnWrapper">

							{!showFirstLogin && <div id="loginBtnFormID">
								<div className="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className={showFirstLogin ? "loginIWrapper hideForFirstLogin" : "loginIWrapper"}>
												<div className="existingLoginForm">
													<ul>
														<li className="form-group">
															<div className="input-group">
																<input className="form-control" placeholder={formatMessage({ id: 'KC0007' })} type="text" name="emailid" value={this.state.fields.emailid} onChange={this.handleEmailChange} onBlur={this.emailblurevent} onKeyPress={this.handleEnterButton} autoFocus />
															</div>
															{this.state.errors["emailid"] && <div className="errorMsg"><FormattedMessage id={this.state.errors.emailid} /></div>}
														</li>

														<li className="form-group pass">
															<div className="input-group">
																<input className="form-control pwd" name="password" placeholder={formatMessage({ id: 'KC0008' })} value={this.state.fields.password} type={this.state.type} onChange={this.handleChange} onBlur={this.passwordblurevent} onKeyPress={this.handleEnterButton} />

																{this.state.errors["password"] && <div className="errorMsg"><FormattedMessage id={this.state.errors.password} /></div>}
																<span className="input-group-btn" onClick={this.togglePwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={this.state.showhidepassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>
														<li className="form-group pass">
															<div className="customCheckbox customCheckboxWlabel">
																<input id="RememberMe" name="RememberMe" type="checkbox" value={this.state.rememberme} defaultChecked={this.state.rememberme}
																	onChange={(e) => this.rememberMeCheckboxChange(e)} />
																<label htmlFor="RememberMe">
																	<FormattedMessage id='KC0009' />
																</label>

															</div>

															<Link className="forgotpassword" id="ForgotPassword" to="/ForgotPassword" title={formatMessage({ id: 'KC0002' })} onClick={() => this.forgotPass()}><FormattedMessage id='KC0002' /></Link>

														</li>
														{isCaptchaEnabled() ? <li>
															<div className="g-recaptcha">
																<ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
															</div>
															<div className="errorMsg">{this.state.errors.recaptcha}</div>
														</li> : null}

														<li className={isFormValidated ? "disabled" : "form-group pass"}>
															<button className="btn btn-secondary existingSingINBtn" type="submit" id="existingSignInButton" onClick={() => this.postComment()}><FormattedMessage id='KC0013' /> </button>
														</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul>
										<li>
											{/* <Link to="#" title="Create Account">Create Account</Link> */}
										</li>
										<li>
											<div className="loginIWrapper">
												<Link to="/register-unit" title={formatMessage({ id: 'KC0003' })} onClick={(e) => this.trackEvent(e)}><FormattedMessage id='KC0003' /></Link>
												{chinaGovRegistrationLink.govLink ? <a className="forChinese" href="#" onClick={() => window.open(`//${chinaGovRegistrationLink.govLink}`)}>浙ICP备19012273号-3</a> : ""}
												{/* <Link to="/" title="Language" className="languageSel"></Link> */}
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>}

							{showFirstLogin && <div id="loginBtnChangePassword">
								<div className="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className="loginIWrapper">
												<div className="existingLoginForm">
													<ul>
														<li className="form-group marginBottom10px">
															<input type="text" name="emailid" className="form-control disabled" placeholder="Enter your email" defaultValue={this.state.username} value={this.state.username} onChange={this.handleChange} onBlur={this.emailblurevent} />
															<div className="errorMsg">{this.state.errors.emailid}</div>
															{/* <div className="errorMsg"><FormattedMessage id={this.state.errors.emailid}/></div> */}
														</li>
														<li className="form-group pass">
															<div className="input-group">
																<input className="form-control pwd disabled" name="password" placeholder="Old password" defaultValue={this.state.password} value={this.state.password} type={this.state.oldpwdtype} onChange={this.handleChange} onBlur={this.passwordblurevent} />
																<div className="errorMsg">{this.state.errors.password}</div>
																{/* <div className="errorMsg"><FormattedMessage id={this.state.errors.password}/></div> */}
																<span className="input-group-btn" onClick={this.toggleOldPwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={this.state.showhideoldpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>

														<li className="form-group pass">
															<div className="input-group">
																<input className="form-control pwd" id="password2" name="newpassword" placeholder="New password" onKeyPress={(e) => this.removeSpacesOnChange(e)} value={this.state.fields.newpassword} type={this.state.newpwdtype} onChange={this.handleChange} onBlur={(e) => this.ValidateinputField(e)} />
																<div className="errorMsg">{this.state.errorNewPassword}</div>
																<span className="input-group-btn" onClick={this.toggleNewPwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={this.state.showhidenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>
														<li className="form-group pass">
															<div className="input-group">
																<input className="form-control pwd" id="password2" name="retypenewpassword" onKeyPress={(e) => this.removeSpacesOnChange(e)} placeholder="Retype new password" value={this.state.fields.retypenewpassword} type={this.state.retypenewpwdtype} onChange={this.handleChange} onBlur={(e) => this.ValidateinputField(e)} />
																<div className="errorMsg">{this.state.errorRetypeNewPassword}</div>
																<span className="input-group-btn" onClick={this.toggleRetypeNewPwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={this.state.showhideretypenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>
														<li className="form-group pass">
															<div className="input-group">
																<div className="customCheckbox customCheckboxWlabel">
																	<input id="RememberMe" name="RememberMe" type="checkbox" value={this.state.rememberme} defaultChecked={this.state.rememberme}
																		onChange={(e) => this.rememberMeCheckboxChange(e)} />
																	<label htmlFor="RememberMe">
																		<FormattedMessage id='KC0009' />
																	</label>
																</div>
															</div>
														</li>
														<li>&nbsp;</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul>
										<li>
											{/* <Link to="#" title="Create Account">Create Account</Link> */}
										</li>
										<li>
											<div className="loginIWrapper">
												<button className="btn btn-secondary singINBtn" type="button" id="SignInButton" onClick={(e) => this.createPassword(e)}><FormattedMessage id='KC0023' /></button>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>}
						</div> 
						{/* Cookie Banner implementation */}
						<div class="cookieWrapper" id="cookieWrapper">
							<div class="footerDiv">{footerTextCustom()} <a class="footerPrivacyNotice" href={'https://www.welbilt.com/privacy-and-legal-notices#Privacy-Policy'} target="_blank"  title="Privacy Notice">Privacy Notice</a> | <a target="_blank" href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} class="footerCookiePolicy" title="Cookie Policy">Cookie Policy</a> {icpTextCustom()? " | " :""} {icpNumber}</div>
						</div>
						<div class="cookieWrapperBtn" id="cookieWrapperBtn">
							<ul>
								<li>
									<p>This website uses essential cookies. We would like to use non-essential cookies so that you are able to use certain features of the web site included but not limited to see
										personalized content, switch between site cultures. It’s recommended that you allow all cookies by clicking Accept. Further details on cookies can be found <a href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} target="_blank" title="Privacy Notice">here</a>.</p>
								</li>
								<li>
									<button class="btn essentialOnlyBtn" type="button" onClick={()=>this.acceptCookie()} id="essentialOnlyBtn">Essential Only</button>
									<button class="btn acceptBtn" type="button" onClick={()=>this.acceptCookie()} id="acceptBtn">Accept</button>
								</li>
							</ul>
						</div>
						{/* Cookie Banner implementation */}
					<ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel}/>
					<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
					<FLSuccessMoal open={fl_success_modal.open} message={fl_success_modal.message} onCloseModel={this.closeModel}/>
					<AccessModal open={access_modal.open} message={access_modal.message} onAccessNowModel={this.accessNowModel} onAccessLaterModel={this.accessLaterModel}/>
					<FLSuccessMoalBackToHome open={fl_home_success_modal.open} message={fl_home_success_modal.message} onFLSuccessModel={this.onFLSuccessCloseModal}/>

					{/* <!-- //Login Form -->   */}
				</div>
			</div> 
			
			{/*
			<!-- SignIn Confirmation Modal --> */}
			<div className="modal fade" data-keyboard="false" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
				<div className="modal-dialog modal-md loginModal">
					<div className="modal-content"> 
						<div className="modal-body"> 
							<div className="singINConfirmation"> 
							You successfully created your personal password!
							</div>
								
						
						</div>
						<div className="modal-footer textAlignCenter">
						<button className="btn btn-secondary signINconfirmationOKBtn" type="button"><FormattedMessage id='KC0777'/></button>
						</div>
					</div>
				</div>
			</div>
			
			</div>
			<SupportButton subject="KitchenConnect Support Request - Log in" body="Dear User,%0D%0A%0D%0AIf your organization already has a KitchenConnect Account, please contact your account administrator to get access.%0D%0A%0D%0AIf you are facing issues during login, please send us your comments and attach any screenshots that could help our team to better understand your issue and we´ll come back to you.%0D%0A%0D%0A Sincerely,%0D%0AKitchenConnect Team."/>
		</React.Fragment>
        
		)
	}
};

function mapStateToProps(state) {
	return {
			filterKey: [],
			selectedCountries: [],
			selectedRegions: [],
			selectedLocations: [],
			selectedCustomers: [],
			selectedBrands: [],
			selectedModels: []
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setLocale, resetLocale }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
