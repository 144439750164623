import http from '../../../service/httpService';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoadingPopup from '../../controls/loadingPopup';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { ServiceManagement } from '../../../service/api';
import { getErrorList } from '../../../service/servicemanagement/serviceManagement';
import { CUSTOMER_ID } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import NoRecordsFound from '../../controls/noRecordsFound';
import $ from 'jquery';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import authService from '../../../service/authService';
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../../controls/goToPagination';
const modelNameColumnStringIDMap = {
	Model: 'KC0064',
	model: 'KC0064',
	serialNo: 'KC0841',
	idNo: 'KC0845'
};
class allCurrentErrors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			loaderIconVisible: false,
			totalPages: null,
			loading: true,
			currentPage: 1,
			currentErrCount: "",
			pageSize: 25,
			sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
			errorType: "",
			IsServerError: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			noRecords: null,
			filters: "Model",
			criticalBox: false,
			nonCriticalBox: false,
			brand: "",
			dataToTrobleShoot: [],
			isSelectAllRoles: false,
			allowToDelete: false,
			isCriticalSel: true,
			isNonCriticalSel: true,
			isErrorEventSel: false,
			brandNames:[],
			cbGroup : {nCriticalError:true, criticalError:true, errorEvent:false},
			findErrType:"All",
			findErrorEvent:false,
			isIndeterminate: false,
			searchData: null
		};
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
        };
		this.changeErrorList = this.changeErrorList.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
	}

	componentWillMount = () => {
		document.body.style.backgroundColor = "#F2F2F2";
	}

	nextPath(path) {
		this.props.history.push(path);
	}

	updateStateValues = async ()  =>  {
		this.setState({

		})

	}

	componentDidMount = async ()  =>  {
		document.body.style.backgroundColor = "#F2F2F2";
		this.props.searchVisible(true);
		await this.getFilterDetails();
		window.addEventListener('resize', this.updateDimensions());
		this.updateDimensions();
		await this.getProductList();
		const { currentPage, sortColumn } = this.state;		
		this.countValue();
		this.loadErrorData(currentPage, sortColumn, "All",false);
	}


	componentDidUpdate(prevState) {
		this.updateDimensions()
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
		  	const { sortColumn } = this.state;		
			this.setState({
				loaderIconVisible:true,
			},()=>{
				this.countValue();
				this.loadErrorData(1, sortColumn, "All",false);
			})
			
		}
	  }
	  // Apply filters function
	  getFilterDetails = async () => {		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.errorCenterFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    	let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];
		let selectedST = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			 selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
		});

		selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
			  return selectedST.push(c.id)
		});

		selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
			  return selectedST.push(c.id)
		});

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
			sMachineTypes: selectedMachineTypes,
            sSmartTags:selectedST,
        });
        
        var d = document.getElementById("spannavNotifiStatus");
        if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0 || selectedST.length > 0) {
            d.className = "navNotifiStatus";
        }			
        else {
            d.className = "";
        }
		
	}
	getProductList =  async ()  => {
		let brandName = {
			4: "CREM",
			2: "MERCO",
			1: "MERRYCHEF",
			6: "CONVOTHERM",
			3: "FRYMASTER",
			5: 'MANITOWOC ICE',
			7: "DELFIELD",
			8: "MULTIPLEX",
			9: "GARLAND",
			10: "LINCOLN",
		}		
		
		let branids = localStorage.getItem("brandId");
		branids = branids.replace("[", "");
		branids = branids.replace("]", "");
		branids = branids.split(",");
		branids = _.uniqBy(branids);
		let getBrandName = [];
		branids.map((item, i) => {
			getBrandName.push(brandName[item]);
		})
		this.setState({
			brandNames: getBrandName,
		})
	}

	loadErrorData = async (currentPage, sortColumn, errType, errorEvent) => {	
		this.setState({ loaderIconVisible: true });
		let { searchData } = this.state;
		let brandIdOpenConcat = "[";let brandIdEndConcat = "]";
		let filteredBrandId = this.filterstate.sBrandids && this.filterstate.sBrandids.length>0?brandIdOpenConcat + [this.filterstate.sBrandids].join(',') + brandIdEndConcat:localStorage.getItem("brandId");
		let filteredCustGrpId = this.filterstate.sCgids && this.filterstate.sCgids.length>0?this.filterstate.sCgids:localStorage.getItem("custGroupID");
		let filteredTags = this.filterstate.sSmartTags && this.filterstate.sSmartTags.length>0?this.filterstate.sSmartTags.join(','):'';
		let pageTotalVal = this.state.pageSize;
		let sortBy = sortColumn;// this.state.filter;
		let reqData = {
			"data": {
				cid: parseInt(CUSTOMER_ID),
				"filters": this.state.filters,
				"brand": filteredBrandId,
				"cgid": filteredCustGrpId,
				'modelId': this.filterstate.sModels.join(','),
				"criticalType": this.state.findErrType,
				"errorEvent": this.state.findErrorEvent,
				"pageSize": this.state.pageSize,
				"pageNum": currentPage ? currentPage : this.state.currentPage,
				"tags": filteredTags
			}
		};
		
		if (this.state.findErrType === "No Need") {
			delete reqData.data.criticalType;
		}
		if (this.state.findErrorEvent === false) {
			delete reqData.data.errorEvent;
		}
		if(filteredTags === ""){
			delete reqData.data.tags;
		}

		let url = ServiceManagement.allCurrErrorList;

		//searchData_function
		if (searchData) {
			Object.keys(searchData).forEach((key,index) => {
				if (searchData[key]) {
					reqData["data"][key === "searchtext" ? "searchText" : key ] = `${searchData[key].toLowerCase()}`;
				}
			});
		}

		http.post(url, reqData, {			
			headers: {
				'Content-Type': 'application/json',				
			}
		}
		
		).then(response => {
			let rolesList = response.data.data; 
			rolesList.forEach(r => {
				r.checked = false;
			});
			this.setState({
				data: rolesList,
				loaderIconVisible: false,
				currentPage: currentPage ? currentPage : 1,
				totalPages: response.data.totalPages,
			});
		}).catch(err => {
			this.setState({ loaderIconVisible: false })
		});
		
	}

	countValue = (errType) => {
		let { searchData } = this.state;
		let brandIdOpenConcat = "[";let brandIdEndConcat = "]";
		let filteredBrandId = this.filterstate.sBrandids && this.filterstate.sBrandids.length>0?brandIdOpenConcat + [this.filterstate.sBrandids].join(',') + brandIdEndConcat:localStorage.getItem("brandId");
		let filteredCustGrpId = this.filterstate.sCgids && this.filterstate.sCgids.length>0?this.filterstate.sCgids:localStorage.getItem("custGroupID");
		let filteredTags = this.filterstate.sSmartTags && this.filterstate.sSmartTags.length>0?this.filterstate.sSmartTags.join(','):'';

		let reqData = {
			"data": {
				"cid": parseInt(CUSTOMER_ID),
				"filters": "model",				
				"brand": filteredBrandId,
				'modelId': this.filterstate.sModels.join(','),
				"criticalType": errType,
				"cgid": filteredCustGrpId,
				"tags": filteredTags,
			}
		};

		if(filteredTags === ""){
			delete reqData.data['tags'];
		}
		let url = ServiceManagement.allCurrErrorCounts;
		//searchData_function
		if (searchData) {
			Object.keys(searchData).forEach((key,index) => {
				if (searchData[key]) {
					reqData["data"][key === "searchtext" ? "searchText" : key ] = `${searchData[key].toLowerCase()}`;
				}
			});
		}
		http.post(url, reqData,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)
			.then(response => {
				this.setState({ currentErrCount: response.data })
			}).catch(err => {
			});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.props.searchText({});
		this.props.searchVisible(false);
	}
	
	updateDimensions() {
        const windowHeight = $(window).height();
        const $tableBody = $('.allErrorListSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0; 
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }


	/**
	 * Fetch server data by On-Demand
	 * Send current page number
	 * Send Sorted details
	 */
	loadData = async (currentPage, sortColumn) => {
	 
		try {
			const response = await this.requestData(currentPage, sortColumn);
			this.setState({
				data: response.rows,
				totalPages: response.pages,
				currentPage: currentPage,
				loading: false
			});

			var rolesList = response.rows;
			rolesList.forEach(r => {
				r.checked = false;
			});

			this.setState({
				data: rolesList
			});

		} catch (error) {
			this.setState({ loading: false });
		}
	}

	/**
   * When navigate new page this event will return current page number
   * @param {*} currentPage 
   */
	onPageChange = (currentPage) => {		
		let {sortColumn} = this.state;
		this.loadErrorData(currentPage,sortColumn);
	}

	/**
	 * Business login to fetch units by below constraints
	 * 1. Fetch by Brand specific units
	 * 2. Fetch by Sorted column details
	 * 3. Fetch by page wise
	 */
	requestData = async (currentPage, sortColumn) => {
		try {
			const sortData = sortColumn && sortColumn.path !== "" ? sortColumn : this.state.sortColumn;
			const pageSize = this.state.pageSize;
			const sortType = sortData.order;
			const sortBy = sortData.path;
			const page = currentPage - 1;

			let pageNumber = (page + 1);
			let pageLimit = pageSize;

			let order = page * pageSize;
			let apiUrl = ServiceManagement.allCurrErrorList;
			let response = await getErrorList(apiUrl, sortBy, sortType);			 

			let { data, totalPages } = response;

			
			data.map((item, i) => {
				return item.order_id = ++order;
			});

			this.setState({
				noRecords: data.length > 0 ? false : true,
			});


			return {
				rows: data,
				pages: totalPages
			};

		} catch (error) {
			return {
				rows: [],
				pages: 0
			};
		}

	};

	changeErrorList = (event) => {		
		let errorType = event.target.value === "All Errors" ? "" : event.target.value === "Warning" ? 'non-critical' : 'critical';
		this.state.errorType = errorType;
		this.setState({
			errorType: errorType,
			loading: true
		});
		let pageNumber = 1;
		let pageLimit = 10;
		let currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		let apiUrl = ServiceManagement.allCurrErrorList;
		getErrorList(apiUrl).then(response => {
			response.data.map((item, i) => {
				return item.order_id = i + 1;
			});
			this.setState({
				data: response.data,
				totalPages: response.totalPages,
				currentPage: 1,
				loading: false
			});
			
		})
			.catch(err => {
				this.showError(err);
			})
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			this.setState({
				data: response.data,
				totalPages: response.totalPages,
				currentPage: 1,
				loading: false
			});
			this.setState({ loading: false });
		} else {
			this.setState({
				IsServerError: true
			});
		}
	}

	errorCloseModel = () => {
		localStorage.removeItem("Access-Token");
		this.setState({
			IsServerError: false
		});
		this.props.OnCloseSessionModal();
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		});
	}

	onSuccessCloseModal() {
		this.setState({			
			// isCriticalSel: false,	
			// isNonCriticalSel:false,		
			success_modal: {
				open: false
			},
			isIndeterminate: false,
			isSelectAllRoles: false
		});
	}

	onSendEmailHandler() {
		this.setState({
			loaderIconVisible: true
		});

		return new Promise((resolve, reject) => {
			let postData = {
				'customer_id': parseInt(CUSTOMER_ID),
				'brand_id': localStorage.getItem("brandName"),
				'user_email': localStorage.getItem("user"),
				'unit_id': localStorage.getItem("unitID")
			}

			http({
				method: 'POST',
				url: ServiceManagement.emailReport,
				data: postData
			})
				.then(res => {
					let { body } = res.data;
					let { responseCode, message, stringID } = body;
					if (responseCode !== 200) {
						this.setState({
							loaderIconVisible: false,
							error_modal: {
								open: true,
								message: stringID ? stringID : message
							}
						});
						reject({
							response: {
								data: res.data
							}
						});
					} else {
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: stringID ? stringID : message
							},
							isIndeterminate: false,
							isSelectAllRoles: false
						});
						resolve(res);
					}
				}).catch(err => {
					reject(err);
				});
		});
	}

	errorDetailsForTroubleShoot = (e, selectRow) => {
		let resData = this.state.data;
		this.setState({
			dataToTrobleShoot: resData[selectRow - 1],
		}, () => {
			this.setState({
				dataToTrobleShoot: resData[selectRow - 1],
			})
		})
	}

	handleChange = (e) => {
		var rolesList = [...this.state.data];
		rolesList.forEach(r => {
			r.checked = e.target.checked;
		});
		this.isSelectAll(rolesList);
	};

	onSelectRole = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const rolesList = [...this.state.data];
			const index = rolesList.indexOf(item);
			rolesList[index] = { ...rolesList[index] };
			rolesList[index].checked = e.target.checked;
			this.isSelectAll(rolesList);
		}
	}

	isSelectAll = (rolesList) => {
		let selectedRoles = rolesList.filter(item => item.checked === true);
		let isIndeterminate, isSelectAllRoles;
		
		if(selectedRoles.length === rolesList.length) {
			isSelectAllRoles= true; isIndeterminate = false;
		} else if(selectedRoles.length === 0 && rolesList.length !==0){
			isSelectAllRoles= false; isIndeterminate = false;
		} else if(rolesList.length > selectedRoles.length) {
			isSelectAllRoles= true; isIndeterminate = true;
		} else {
			isSelectAllRoles= false; isIndeterminate = false;
		}

		this.setState({
			data: rolesList,
			isIndeterminate: isIndeterminate,
			isSelectAllRoles: isSelectAllRoles
		});
	}

	validateErrorTypes = (isCritical, isNonCritical, isErrorEvent) => {
		let req = {};
		if( isCritical === false && isNonCritical === false && isErrorEvent === true) {
			req = { errorEvent: true }
		}
		else if( isCritical === false && isNonCritical === true && isErrorEvent === false) {
			req = { criticalType: false }
		}
		else if( isCritical === true && isNonCritical === true && isErrorEvent === false) {
			req = { criticalType: true }
		}
		else if( isCritical === true && isNonCritical === false && isErrorEvent === false) {
			req = { criticalType: true }
		}
		else if( isCritical === false && isNonCritical === true && isErrorEvent === true) {
			req = { criticalType: false, errorEvent: true }
		}
		else if( isCritical === true && isNonCritical === false && isErrorEvent === true) {
			req = { criticalType: true, errorEvent: true }
		}
		return req;
	}

	//For Event Error Code
	handleCheckBoxClick = ( e, key ) => {
		
		let { currentPage, sortColumn, isCriticalSel, isNonCriticalSel , isErrorEventSel, cbGroup } = this.state;
		let isChecked = e && e.target && e.target.checked;		 
		let setSelectedCheckbox = {};
		setSelectedCheckbox[key] = isChecked;  

		switch( key ) {
			case 'isCriticalSel':
				isCriticalSel = isChecked;
				break;
			case 'isNonCriticalSel':
				isNonCriticalSel = isChecked;
				break;
			case 'isErrorEventSel':
				isErrorEventSel = isChecked;
				break;
		}
		let errType = false, errorEvent = false;
		cbGroup[e.target.value] = isChecked;
		errorEvent = cbGroup["errorEvent"];
		
		if(cbGroup["criticalError"] && cbGroup["nCriticalError"]){
			errType = "All";
		}else if(cbGroup["criticalError"]){
			errType = true;
		}else if(cbGroup["nCriticalError"]){
			errType = false
		}  

		if(cbGroup["criticalError"] === false && cbGroup["nCriticalError"]===false){
			errType = "No Need";
		}
		WoopraEvents(`${Constants.ERROR_TYPE}-${e.target.title}`);
		this.setState({
			findErrType:errType,
			findErrorEvent:isErrorEventSel,
		}, () => {
			this.loadErrorData(1, sortColumn);
		});    


		let req = this.validateErrorTypes( isCriticalSel, isNonCriticalSel, isErrorEventSel );
		//let req = {criticalType : errType,errorEvent: errorEvent}
		
		this.setState({...setSelectedCheckbox});    
	}
  
	handleDropDown = (filter) => {
		const { currentPage, sortColumn } = this.state;
		this.setState({
			filters:filter,
		},() => {
			this.loadErrorData(1, sortColumn);
		})
	}

	clearSelectedError = (e) => {
		
		const { currentPage, sortColumn } = this.state;
		var rolesList = [...this.state.data];
		let selErrorDetails = [];
		rolesList.forEach(r => {
			if (r.checked) {
				let val = {
					"error_code": r.error_code,
					"device_id": r.device_id,
					"brand_id": r.brand,
					"THING": "arn:aws:iot:eu-west-1:197058240894:thing/530109876_CREM",
					"current_start_date": r.current_start_date,
					"error_code": r.error_code,
					"timezone":r.timeZone,
				};
				selErrorDetails.push(val);
			}
		});

		let reqData = {
			"data": selErrorDetails,
		};
		let url = ServiceManagement.allCurrErrorClear;
		http.post(url, reqData, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(response => {
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC0848"
				},
				isIndeterminate: false,
				isSelectAllRoles: false
			});
			WoopraEvents(`${Constants.CLEAR_ERROR}`);
			this.loadErrorData(currentPage, sortColumn);
			this.countValue();

		}).catch(err => {
		});
	}

	errorHelpSection = (item) => {
		const { brand, modelFamilyName, error_code, helpLink }= item;
		
		const linkFormation = `${helpLink}?brand=${brand}&model=${modelFamilyName}&eventCode=${error_code}`;
		window.open(linkFormation, "_blank");
	}

	// Woopra Events
	historicError = () => {
		WoopraEvents(`${Constants.HISTORIC_ERROR}`);
	}
	
	static getDerivedStateFromProps = (props) => ({ ...props });

	render() { 
        const { formatMessage } = this.props.intl;
		$(document).ready(function () {
			$(document).on('click', '.ReactTable .rt-table .rt-thead .rt-tr .rt-th', function () {
				console.log($(this).parent().children().removeClass('-sort-desc'));
				$(this).parent().children().removeClass('-sort-desc');
			});

			// Nav Toggle
			$('.collapsed').off('click').on('click', function () {
				$(this).parents(".panel-collapse").toggleClass('show');
			});
		});

		function CellComponent(props) {
			const { datediff, duration } = props;
			if (datediff && datediff <= 100) {
				return (<div className="progress-bar" style={{ width: '0%' }}>{duration}</div>);
			}
			else if (datediff && datediff <= 43200) {
				return (<div className="progress-bar" style={{ width: '25%' }}>{duration}</div>);
			}
			else if (datediff && datediff <= 86400) {
				return (<div className="progress-bar" style={{ width: '50%' }}>{duration}</div>);
			}
			else if (datediff && datediff > 86400 && datediff < 432000) {
				return (<div className="progress-bar" style={{ width: '75%' }}>{duration}</div>);
			}
			else {
				return (<div className="progress-bar" style={{ width: '100%' }}>{duration}</div>)
			}
		}

		let { data, corporateRoles, sortColumn, loaderIconVisible, isSelectAllRoles,noRecords,
			success_modal, error_modal, currentPage, totalPages, pageSize,isCriticalSel,isNonCriticalSel, isErrorEventSel, isIndeterminate, filters } = this.state;
		let orderId = 1;
		let critical = null;
		let nonCritical = null;
		let totalEventsErrorCount = null;
		if (this.state.currentErrCount["currentErrCount"]) {
			critical = this.state.currentErrCount["currentErrCount"]["critical"]
			nonCritical = this.state.currentErrCount["currentErrCount"]["nonCritical"]
			totalEventsErrorCount = this.state.currentErrCount["currentErrCount"]["eventsError"]
		}
		corporateRoles = _.orderBy(corporateRoles, [corporateRoles => corporateRoles.role_name && corporateRoles.role_name.toString().toLowerCase()], [sortColumn.order]);
		corporateRoles.forEach(item => {
			item.order_id = orderId++
		});

		let allowToDelete = false;
		var rolesList = data;
		
		rolesList.forEach(r => {
			if (allowToDelete === false) {
				allowToDelete = r.checked;
			}
		});
		if (this.state.isCriticalSel === false && this.state.isNonCriticalSel === false && this.state.isErrorEventSel === false){
			noRecords = true;
			data=[];
		}

		let rowNumber = (currentPage * pageSize) - pageSize;
		let userProfileAuthFeature = authService.getFeature("/allcurrenterrors");
		return (
			<div id="adminWrapper">
				<LoadingPopup loading={loaderIconVisible} />
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="reportWrapper"> 
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/allcurrenterrors" title={ formatMessage({ id: 'KC0185' }) } className="active" ><FormattedMessage id="KC0185"/></Link>
									<Link to="/allhistoricerrors" title={ formatMessage({ id: 'KC0186' }) } onClick={() => this.historicError()}><FormattedMessage id="KC0186"/></Link>
								</li>
								<li>
									<div className="checkBoxListGrp">
										<div className="customCheckbox crticalCBox">
											<input id="criticalError" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, "isCriticalSel")} 
											value="criticalError" checked={isCriticalSel}></input>
											<label for="criticalError"><FormattedMessage id="KC0156"/> <span>{critical}</span></label>
										</div>
										<div className="customCheckbox nonCrticalCBox">
											<input id="nCriticalError" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, "isNonCriticalSel")} value="nCriticalError" checked={isNonCriticalSel}></input>
											{/* <input id="nCriticalError" type="checkbox" onClick={(e) => this.handleNonCriticalCheckBox(e)} value="nCriticalError" checked={isNonCriticalSel}></input> */}
											<label for="nCriticalError"><FormattedMessage id="KC0157"/> <span>{nonCritical}</span></label>
										</div>
										<div className="customCheckbox">
											<input id="errorEvent" type="checkbox" onClick={(e) => this.handleCheckBoxClick(e, "isErrorEventSel")} 
											value="errorEvent" checked={isErrorEventSel}></input>
											<label for="errorEvent"><FormattedMessage id="KC0422"/> <span>{totalEventsErrorCount}</span></label>
										</div>
									</div>
									<button type="button" className={allowToDelete && userProfileAuthFeature && userProfileAuthFeature.is_editable === 1? "btn btn-secondary clearErrorBtn" : "btn btn-secondary clearErrorBtn disabled"} data-toggle="modal" data-target="#confirmationDeleteModal"><FormattedMessage id="KC0193"/></button>
								</li>
							</ul>
						</div>
						<div className="allErrorListTable">
							<ul className="tHead">
								<li>&nbsp;</li>
								<li>#</li>
								<li><FormattedMessage id="KC0102"/></li>
								<li><FormattedMessage id="KC0194"/></li>
								<li><FormattedMessage id="KC0062"/></li>
								<li>
									<div className="dropdown datepickerBtnGroup">
										<button className="btn dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<FormattedMessage id={modelNameColumnStringIDMap[filters]}/>
										</button>
										<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
											<a onClick={() => this.handleDropDown("model")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0064"/></a>
											<a onClick={() => this.handleDropDown("serialNo")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0841"/></a>
											<a onClick={() => this.handleDropDown("idNo")} className="dropdown-item" href="javascript:void(0);"><FormattedMessage id="KC0845"/></a>
										</div>
									</div>
								</li>
								<li><FormattedMessage id="KC0846"/></li>
								<li><FormattedMessage id="KC0050"/></li>
								<li><FormattedMessage id="KC0196"/></li>
								<li><FormattedMessage id="KC0197"/></li>
								<li>
									<div className={isIndeterminate ? "customCheckbox selectAllDiv customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel"}>
										<input id="selectAll" onChange={(e) => this.handleChange(e)} type="checkbox" value="selectAll" checked={isSelectAllRoles} />
										<label for="selectAll">&nbsp;</label>
									</div>
								</li>
							</ul>
						</div> 
						<div class="allErrorListSrlBar">
						<form className="panel panel-default" id="allErrorsAccordion" role="tablist" aria-multiselectable="true">
							{data.map((item, i) => {
								const helpIconDisable = !(item.error_code && item.brand && item.modelFamilyName);
								const isDisabled = !item.description && helpIconDisable;
								return (
								<Fragment>
									<div className="allErrorListTable">
										<ul className="tBody" key={i}>
											<li><button role="button" disabled={isDisabled} data-toggle="collapse" data-parent="#allErrorsAccordion" href={"#allErrorsAcc" + i} type="button" className="btn collapseExpand collapsed">&nbsp;</button></li>
											<li>{rowNumber + i + 1}</li>
											<li className="errorStausLi">
												{item.is_critical === 0 && <span className="errorStaus alertLevel">&nbsp;&nbsp;</span>}
												{item.is_critical === 1 && <span className="errorStaus warnLevel">&nbsp;&nbsp;</span>}
												{item.is_critical === 2 && <span className="errorStaus errorEvent">&nbsp;&nbsp;</span>}												
												{/* <span class={(item.is_critical === 1 ? "errorStaus warnLevel" : "errorStaus alertLevel"}>&nbsp;&nbsp;</span> */}
											</li>
											<li>{item.error_code}</li>
											<li>{item.brand}</li>
											<li>
												<h4>{item.model_name}</h4>
												{item.device_id ? <h5><FormattedMessage id="KC1043"/>: {item.device_id}</h5> : ""}
												{item.unit_number ? <h6><FormattedMessage id="KC1044"/>: {item.unit_number}</h6> : ""}
											</li>
											<li>{((item.holder_id === null)? "-":item.holder_id)}</li>
											<li>
												<h4>{item.dynastyAttributes && item.dynastyAttributes.location_name}</h4>
												<h5>{item.group_name}</h5>												
											</li>											
											<li>{item.start_date ?item.start_date : ""}</li>
											<li className="progress">{CellComponent(item)}</li>

											<li>
												<div className={"customCheckbox customCheckboxWOlabel"}>
													<input id={i} name={i} type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
											</li>
										</ul>
									</div>
									<div id={"allErrorsAcc" + i} className="panel-collapse collapse" role="tabpanel" aria-labelledby="allErrorsAccH6">
										<div className="panel-body allErrorListExpTable">
											<ul className="tBody">
												<li>&nbsp;</li>
												<li>
													<p>{item.description}</p>
														<a
															onClick={() => helpIconDisable ? '' : this.errorHelpSection(item)}
															title={formatMessage({id:'KC0198'})}
															class={`helpIcon cursor-pointer ${helpIconDisable ? 'disabled' : ''}`}><FormattedMessage id="KC0198"/>
														</a>
												</li>
											</ul>
										</div>
									</div>
								</Fragment>
							)})}

							{/* <div class="tablePagination">
								<ul>
									<li class="prevPageLi">
										<Link to ="#" onClick={(e) => this.onPageChange(currentPage)} href="javascript:void(0)" title="Prev" class="prevPage">&nbsp;</Link>
									</li>
									<li class="prevPageCountLi">Page 1</li>
									<li> Page 1 0f {totalPages}</li>
									<li class="nextPageCountLi">Page 3</li>
									<li class="nextPageLi">
										<Link to ="#" onClick={(e) => this.onPageChange(currentPage)} href="javascript:void(0)" title="Next" class="nextPage">&nbsp;</Link>
									</li>
								</ul>
							</div>  */}

							{((!loaderIconVisible && data.length === 0 ) || (!loaderIconVisible && noRecords)) ? <NoRecordsFound loaderIconVisible={false} length={(data !== undefined || data.length === 0 || noRecords) ? 0 : 1} classname={'norecordsfoundbluetext'} text={(this.state.searchData && data && data.length === 0 || data.length===0) ? <FormattedMessage id="KC0853"/> : <FormattedMessage id="KC0205"/>} />:null}
						</form>
						</div>
						{totalPages && totalPages > 1 && !noRecords ? <GoToPagination
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
						navigateTo={(p) => this.onPageChange(p)} /> : null }
					</div>
					{/* <!-- Delete Confirmation Modal --> */}
					<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-body">
									<div className="deleteConfirmation">
										<FormattedMessage id="KC0847"/>
					            	</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
									<button className="btn btn-secondary deleteYesBtn" onClick={(e) => this.clearSelectedError(e)} id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/></button>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- //Delete Confirmation -->
	            <!-- Delete Confirmation Modal --> */}
					{/* <div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
						<div class="modal-dialog modal-md">
							<div class="modal-content">
								<div class="modal-body">
									<div class="confirmationDiv">
										Selected errors cleared successfully.
					            </div>
								</div>
								<div class="modal-footer textAlignCenter">
									<button class="btn btn-secondary confirmationOKBtn" onclick="location.href='cremcurrenterrors.html'" type="button">OK</button>
								</div>
							</div>
						</div>
					</div> */}
					{/* <!-- //Delete Confirmation --> */}
				</div>
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} message={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.errorEloseModel()} />
				<UserManagementHeader headerName="Error Center" headerNameStringID="KC0639" activeClass="errorCenterNav" filterKey={appconstants.errorCenterFilter} history={this.props.history} />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData,
        filterlistInformtion: state.filterModel
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
		searchVisible,
		searchText,getSelectedFilterList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(allCurrentErrors));