import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import {isCaptchaEnabled }  from '../../utils/captcha'
import { FormattedMessage, injectIntl } from 'react-intl';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			isCaptchaVerified: false,
			isEmailVerified: false,
			errors: {
				emailid: '',
				recaptcha: ''
			},
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			loaderIconVisible: false,
			userData: {}
		};
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSignInSubmitAction = this.handleSignInSubmitAction.bind(this);
		this.onSigninclickHandler = this.onSigninclickHandler.bind(this);
		this.isValideEmailorNot = this.isValideEmailorNot.bind(this);
		this._reCaptchaRef = React.createRef();
		this.verifyCallback = this.verifyCallback.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentWillMount = () => {
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		this.updateLoginDimensions();
	}
	setLoader = (status) => {
		this.setState({
			loaderIconVisible: status
		});
	}

	onDeleteClickHandler() {
		this.props.history.push('/');
	}


	handleEmailChange(event) {
		this.setState({ email: event.target.value });
		event.preventDefault();
	}

	handleSignInSubmitAction(event) {
		console.log('Your email is: ' + this.state.email);
		event.preventDefault();
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	emailblurevent(event) {
		let inputdataemailobj = event.target.value;
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(inputdataemailobj)) {
			this.setState({
				errors: {
					emailid: 'KC0018'
				}
			})
		}
		return false;
	}

	validateForm() {
		this.setLoader(true);

		let fields = this.state;
		let errors = {};
		let formIsValid = true;

		if (!fields["email"]) {
			formIsValid = false;
			errors["emailid"] = 'KC0017';
		}

		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["email"])) {
				formIsValid = false;
				errors["emailid"] = 'KC0018';
			}
		}

		if (isCaptchaEnabled() && !this.state.isCaptchaVerified && this.state.isCaptchaVerified !== undefined) {
			formIsValid = false;
			errors["recaptcha"] = "Please select reCAPTCHA."; // MLS:TODO
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
	}

	isValideEmailorNot(e) {
		let email = this.state.email;
		if (email) {
			let forgetPswUrl = `${BaseService.check_valid_email}`

			this.setState({
				loaderIconVisible:true,
				errors: {
					emailid: ''
				}
			})
			http.get(forgetPswUrl,
				{
					headers: {
						'Content-Type': 'application/json',
						'username' : email
					}, data: {},
				}).then(response => {
					let { data } = response;
					if (data.responseCode === 200 && data.message)
						this.setState({
							isEmailVerified: true,
							loaderIconVisible:false
						});
						else{
							this.setState({
								loaderIconVisible:false
							});
						}
				}).catch(err => {
					if (err.response && err.response.data && err.response.data.responseCode !== null && err.response.data.responseCode !== undefined && err.response.data.responseCode === "KC_ERR_400") {
						this.setState({
							isEmailVerified: false,
							loaderIconVisible:false,
							errors: {
								emailid: err.response && err.response.data && err.response.data.message
							}
						});
					}
					else {

						this.setState({
							isEmailVerified: false,
							loaderIconVisible:false,
							errors: {
								emailid: err.response && err.response.data && err.response.data.message
							}
						})

						this.setState({
							error_modal: {
								open: true,
								message: err.response && err.response.data && err.response.data.message || "KC1069" 
							},
						});
					}
				});
		}
	}

	onSigninclickHandler(e) {
		let postData = {
			"username": this.state.email
		}
		http({
			method: "POST",
			url: BaseService.submit_forget_password,
			data: postData,
		}).then(response => {
			let { status, data } = response;
			if (status === 200) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: data.message
					},
				});
				WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST}`);
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response.data.message
				}
			})
			WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST_FAIL}`);
		})
		this.setLoader(false);
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			}
		});
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/`);
	}

	goBack = () => {
		this.props.history.push('/');
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");		
		this.updateLoginDimensions();
		if (auth.getJwt())
			return this.props.history.push('/dashBoardLocations');
		$("body").removeClass("modal-open");
		setTimeout(()=>{
			var width = $('.g-recaptcha').parent().width();
			var scale = width / 304;
			$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('transform-origin', '0 0');
			$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
			$('.g-recaptcha').css('display', 'block');
		}, 100);
	}

	updateLoginDimensions() {
		var windowHeight = $(window).height(); 
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25; 
		var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
		//Sign In & Sign Up Modal
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH));
		var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH)) - 60;   
		$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS);  
	}

	verifyCallback = (recaptchaToken) => {
		if (recaptchaToken) {
			this.setState({
				isCaptchaVerified: true,
				errors: {
					...this.state.errors,
					recaptcha: ''
				}
			});
		}
	}

	render() {
		const { error_modal, success_modal, loaderIconVisible } = this.state;
		const { formatMessage } = this.props.intl;
		return (
			<div className="loginBg">
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">   
                		<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>  
        			</nav>
					{/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
					<div id="page-wrapper">
						<div className="welcomText"><FormattedMessage id='KC0006' /></div>
						{/* <!-- Login Form --> */}
						<div className="LoginBtnWrapper">
							<div id="loginBtnFormID">
								<div className="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className="loginIWrapper">
												<div className="existingLoginForm">
													<ul>
														<li className="form-group">
															 
															<div className="input-group">
																	<input className="form-control" placeholder={formatMessage({ id: 'KC0007'})} type="text" name="emailid" onBlur={this.isValideEmailorNot} value={this.state.email} onChange={this.handleEmailChange} /></div>
																{ this.state.errors["emailid"] && <div className="errorMsg"><FormattedMessage id={this.state.errors.emailid} /></div> }
														</li>
														{isCaptchaEnabled() ? <li className="form-group">
														   <div className="g-recaptcha">
										                      <ReCAPTCHA  ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
										                   </div>
															{ this.state.errors["recaptcha"] && <div className="errorMsg">{this.state.errors.recaptcha}</div> }
														</li>: null}
														
														<li className="form-group">
															<button className="btn btn-secondary cancelBtn" type="button" onClick={() => { this.onDeleteClickHandler() }}><FormattedMessage id='KC0021' /></button>
															<button disabled={this.state.isEmailVerified && this.state.isCaptchaVerified ?  false : isCaptchaEnabled() ? true : false } class="btn btn-secondary submitBtn" type="submit" id="existingSignInButton" onClick={this.onSigninclickHandler}><FormattedMessage id='KC0022'/></button>	 
														</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul>
										<li>
										</li>
										<li>
											<div className="loginIWrapper">
												<Link to="/" title="Login"><FormattedMessage id='KC0001' /></Link>
												<Link to="/" title="Language" className="languageSel"></Link>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- //Login Form -->   */}
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />

				<div className="modal fade SignInSuccessModal" data-keyboard="true" data-backdrop="false" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
					<div className="modal-dialog modal-md loginModal">
						<div className="modal-content">
							<div className="modal-body">
								<div className="singINConfirmation">
									You successfully created your personal password!
					</div>

							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" onClick={() => this.nextPath('/dashBoardLocations')} type="button"><FormattedMessage id='KC0777' /></button>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

export default injectIntl(ForgotPassword);
