const moment = require('moment');

/**
 * Pass server date string and formate of the local time
 * @param {*} sererTimeString 
 * @param {*} dateFormat 
 */
const ConvertLocalTimeFromUTCTime = (sererTimeString, dateFormat) =>{
   const localTime = new Date(sererTimeString);
   const dateTime = moment(localTime).local().format(dateFormat);
   return dateTime;
}

const ConvertLocalTimeFromDeviceUTCTime = (sererTimeString, dateFormat) =>{
   const localTime = new Date(sererTimeString);
   const dateTime = moment(localTime).utc().format(dateFormat);
   return dateTime;
}


/**
 * Get Date time by given format
 * @param {*} date 
 * @param {*} dateFormat 
 */
const GetDateTimeByFormat = (date, dateFormat) =>{
   const dateTime = moment(date).format(dateFormat);
   return dateTime;
}

export default {
    ConvertLocalTimeFromUTCTime,
    GetDateTimeByFormat,
    ConvertLocalTimeFromDeviceUTCTime,
}