import React, { Component } from 'react';
import { connect } from 'react-redux';
import http from '../../service/httpService';
import { LocationInfo } from '../../service/api';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { brandLocations } from '../../utils/imgContants';
import appconstants from '../../utils/appConstants';
import { CUSTOMER_ID, BRAND_ID } from '../../utils/appConstants';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';

const brandsLogo = brandLocations;

am4core.useTheme(am4themes_animated);

class unitsMapView extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      map_locations: []
    };

    this.renderMap = this.renderMap.bind(this);
  }

  renderMap = (mapData) => {

    let chart = am4core.create("mapdiv", am4maps.MapChart);

    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Exclude Antartica
    polygonSeries.exclude = ["AQ"];

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Series for United States map
    var usaSeries = chart.series.push(new am4maps.MapPolygonSeries());
    usaSeries.geodata = am4geodata_usaLow
    var polygonTemplate = usaSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#c9d9d3");
    polygonTemplate.stroke = am4core.color("#2d3034");

    // Add image series
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitute";
    imageSeries.mapImages.template.propertyFields.textContent = "location_id";
    imageSeries.mapImages.template.propertyFields.title = "location_name";
    imageSeries.data = mapData ? mapData : this.state.map_locations;

    //let locations = this.state.map_locations;
    //localStorage.setItem('map_units', JSON.stringify(this.state.map_locations));
    chart.events.on("mappositionchanged", updateCustomMarkers);
    chart.events.on( "ready", updateCustomMarkers );
    
    // Zoom control
    chart.zoomControl = new am4maps.ZoomControl();
    chart.zoomControl.plusButton.disabled = true;
    chart.zoomControl.minusButton.disabled = true;

    var customZoomFactor = 2; // default: 2

    var plusButton = chart.zoomControl.createChild(am4core.Button);
    plusButton.shouldClone = false;
    plusButton.id = "mapPlusButton";
    plusButton.setClassName();
    plusButton.label.text = "+";
    plusButton.label.fill = "#fff";
    plusButton.background.fill = "#000";
    plusButton.width = am4core.percent(100);
    plusButton.strokeWidth = 0;
    plusButton.padding(5, 5, 5, 5);
    plusButton.margmarginBottominTop = 2;
    plusButton.events.on("hit", function () {
      chart.zoomToGeoPoint(
        chart.zoomGeoPoint,
        chart.zoomLevel * customZoomFactor,
        false
      );
    });
    plusButton.insertBefore(chart.zoomControl.slider);

    var minusButton = chart.zoomControl.createChild(am4core.Button);
    minusButton.shouldClone = false;
    minusButton.id = "mapMinusButton";
    minusButton.setClassName();
    minusButton.align = "left";
    minusButton.valign = "top";
    minusButton.label.text = "-";
    minusButton.label.fill = "#fff";
    minusButton.background.fill = "#000";
    minusButton.width = am4core.percent(100);
    minusButton.strokeWidth = 0;
    minusButton.padding(5, 5, 5, 5);
    minusButton.marginTop = 6;
    minusButton.marginBottom = 10; 
    minusButton.events.on("hit", function () {
      chart.zoomToGeoPoint(
        chart.zoomGeoPoint,
        chart.zoomLevel / customZoomFactor,
        false
      );
    });
    minusButton.insertAfter(chart.zoomControl.slider);


    function updateCustomMarkers(event) {
      // go through all of the images
      imageSeries.mapImages.each(function (image) {
        // check if it has corresponding HTML element
        if (!image.dummyData || !image.dummyData.externalElement) {
          // create onex
          image.dummyData = {
            externalElement: createCustomMarker(image)
          };
        }

        // reposition the element accoridng to coordinates
        let xy = chart.geoPointToSVG({ longitude: image.longitude, latitude: image.latitude });
        image.dummyData.externalElement.style.top = xy.y + 'px';
        image.dummyData.externalElement.style.left = xy.x + 'px';
      });

    }

    function createCustomMarker(image) {

      let chart = image.dataItem.component.chart;
      let deviceCount = "0";
      let errorClass = "dot disappear";

      if (image.dataItem.dataContext !== undefined) {
        if (image.dataItem.dataContext.CriticalOffline > 0 || image.dataItem.dataContext.NonCriticalOffline > 0 || image.dataItem.dataContext.NoErrorOffline > 0) {
          deviceCount = image.dataItem.dataContext.CriticalOffline + image.dataItem.dataContext.NonCriticalOffline + image.dataItem.dataContext.NoErrorOffline;
          errorClass = "dot greyColor";
        }
        else if (image.dataItem.dataContext.CriticalOnline > 0) {
          deviceCount = image.dataItem.dataContext.CriticalOnline;
          errorClass = "dot redColor";
        }
        else if (image.dataItem.dataContext.NonCriticalOnline > 0) {
          deviceCount = image.dataItem.dataContext.NonCriticalOnline;
          errorClass = "dot yellowColor";
        }
        else {
          deviceCount = "0";
          errorClass = "dot disappear";
        }
      }

      let total_Units_var = "0";
      if (image.dataItem.dataContext !== undefined) {
        total_Units_var = image.dataItem.dataContext.total_Units;
      }

      // create holder
      let holder = document.createElement('div');
      holder.className = 'map-marker';
      holder.title = image.dataItem.dataContext.location_name + " - " + image.dataItem.dataContext.street + ", " + image.dataItem.dataContext.city + ", " + image.dataItem.dataContext.country;
      holder.style.position = 'absolute'; 

      // holder.innerHTML = total_Units_var;

      let imageurl = "/dashBoardLocations/specificLocation?groupId=" + image.dataItem.dataContext.location_id;
      // image.url = "/dashBoardLocations/specificLocation?groupId=" + image.dataItem.dataContext.location_id;
      if (undefined !== imageurl) {
        holder.onclick = function () {
          localStorage.setItem("currentLocation", JSON.stringify(image.dataItem.dataContext));
          // this.props.setCurrentLocation(image.dataItem.dataContext);
          WoopraEvents(`${Constants.MAP_VIEW_UNIT}`);
          window.location.href = imageurl;
          //window.location.href = image.url;
          //this.props.history.push(`/dashBoardLocations/specificLocation?groupId=` + image.dataItem.dataContext.location_id);
        };
      }

      holder.className += ' map-clickable';

      //create link router
      let linkelement = document.createElement('div');
      //linkelement.href = imageurl;
      linkelement.className = 'map-anchor';
      linkelement.innerHTML = total_Units_var;
      holder.appendChild(linkelement);

      // create dot
      let dot = document.createElement('div');
      dot.className = errorClass;
      dot.textContent = deviceCount;
      linkelement.appendChild(dot);

      // create pulse
      let pulse = document.createElement('div');
      pulse.className = 'pulse';
      linkelement.appendChild(pulse);

      // append the marker to the map container
      chart.svgContainer.htmlElement.appendChild(holder);

      return holder;
    }

  }

  componentDidMount() {
    let url = `${LocationInfo.list}`;

    let filterModel = this.props.filterlistInformtion.filterModel;
    const ffModel = localStorage.getItem(appconstants.filterKey);
    const filterModel1 = ffModel && JSON.parse(ffModel); 
    if (filterModel1)
    {
        filterModel=filterModel1;
    }

    let selectedCountryList = filterModel.find(el => el.field_name === 'country');
    let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
    let selectedLocationList = filterModel.find(el => el.field_name === 'location');
    let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
    let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
    let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
    let selectedModelList = filterModel.find(el => el.field_name === 'model');
    let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
    let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

    let selectedCgids = [];
    let selectedCids = [];
    let selectedBrands = [];
    let selectedModels = [];
    let selectedMachineTypes = [];
    let selectedST = [];

    selectedCountryList.dataSource.map(c => {
        return selectedCgids.push(c.id)
    });
    
    if (selectedRegionList.dataSource.length > 0)
    {
        selectedCgids = [];
    }

    selectedRegionList.dataSource.map(c => {
      return selectedCgids.push(c.cust_gp_id)
    });

    if (selectedLocationList.dataSource.length > 0)
    {
        selectedCgids = [];
    }

    selectedLocationList.dataSource.map(c => {
      return selectedCgids.push(c.cust_gp_id)
    });

    if (selectedWithinLocationList.dataSource.length > 0) {
      selectedCgids = [];
    }

    selectedWithinLocationList.dataSource.map(c => {
      return selectedCgids.push(c.cust_gp_id)
    });

    selectedCustomerList.dataSource.map(c => {
      return selectedCids.push(c.ID)
    });

    selectedBrandList.dataSource.map(c => {
      return selectedBrands.push(c.ID)
    });

    selectedModelList.dataSource.map(c => {
      return selectedModels.push(c.ID)
    });

    selectedMachineTypeList.dataSource.map(c => {
      return selectedMachineTypes.push(c.ID)
    });

    selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
      return selectedST.push(c.id)
    });

    selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
      return selectedST.push(c.id)
    });

    var d = document.getElementById("spannavNotifiStatus");
		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else
		{
			cgids = localStorage.getItem("custGroupID");
		}

		if (selectedCgids.length > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else
		{
		  d.className = "";
		}
    let headers = {
      'Content-Type': 'application/json',
      'cgid': cgids,
      'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,          
      'brandid': selectedBrands.length>0?selectedBrands.join(','):BRAND_ID,
      'model': selectedModels.join(','),
      'machinetype': selectedMachineTypes.join(',')
    }
    console.log("selectedST", selectedST);
    if(selectedST && selectedST.length){
        headers['tags'] = selectedST.join(',')
    }

    http.get(url,
      {
        headers, data: {}
      }
    ).then(res => {

      res.data.locations.map((item, i) => {
        let pickBrand = brandsLogo.find(fruit => fruit.name === item.location_type);
        if (pickBrand && pickBrand != null) {
          item.Brand_Logo = pickBrand.img;
        } else {
          item.Brand_Logo = require('../../images/store/FastCasual.svg').default;
        }
      });

      this.setState({
        isLoaded: true,
        map_locations: res.data.locations,
      });
      this.renderMap(res.data.units);
    }).catch(err => {
    });
  }

  render() {
    return (
      <div id="mapdiv"></div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterlistInformtion: state.filterModel,
    selectedCountries: state.filterInformation.selectedCountries,
    selectedRegions: state.filterInformation.selectedRegions,
    selectedLocations: state.filterInformation.selectedLocations,
    selectedCustomers: state.filterInformation.selectedCustomers,
    selectedBrands: state.filterInformation.selectedBrands,
    selectedModels: state.filterInformation.selectedModels
  }
}

export default connect(mapStateToProps)(unitsMapView);
