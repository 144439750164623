import $ from 'jquery';
import _ from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { unitAPI, DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isCaptchaEnabled } from '../../utils/captcha'

let headers = {
    'Content-Type': 'application/json',
};

class RegisterDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            isCaptchaVerified: false,
            serialNo: '',
            registrationCode: '',
            brand: '',
            errors: {
                serialNo: '',
                registrationCode: '',
                recaptcha: ''
            },
            brandList: [],
            modelMessage: {
                header: '',
                firstLine: '',
                secondLine: '',
                thirdLine: ''
            }
        }
        this._reCaptchaRef = React.createRef();
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        $('body').addClass("deviceRegtrnBg");
        this.getBrands();
        setTimeout(() => {
            var width = $('.g-recaptcha').parent().width();
            var scale = width / 304;
            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
            $('.g-recaptcha').css('display', 'block');
        }, 100);
    }

    getBrands = () => {
        http.get(`${unitAPI.getBrands}`, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({
                    brandList: _.orderBy(response.data, ['BRAND_NAME'], ['asc']),
                    loaderIconVisible: false
                });
            }
        }).catch(err => {
        });
    }

    verifyCallback = (recaptchaToken) => {
        if (recaptchaToken) {
            this.setState({
                isCaptchaVerified: true,
                errors: {
                    ...this.state.errors,
                    recaptcha: ''
                }
            });
        }
    }

    setValues = (value, state) => {
        this.setState({
            [state]: value,
            errors: {
                registrationCode: ''
            }
        })
    }

    handleErrorMessage = (response) => {
        let modelMessage = {
            header: 'Not Found',
            firstLine: 'The combination of Serial Number and Registration Code provided is not registered in our Database.',
            secondLine: 'Please verify that the information you entered is correct.',
            thirdLine: 'If you need support send us an email to DigitalSupport@welbilt.com'
        }
        let modelMessageAlreadyRegistered = {
            header: 'Already Registered',
            firstLine: 'The Device is already registered into KitchenConnect.',
            secondLine: 'If you purchased this unit from another user or you consider that somebody did an unauthorized registration, please send us an email to DigitalSupport@welbilt.com.',
            thirdLine: ''
        }

        let modelMessageError = {
            header: 'Oops!!',
            firstLine: response && response.data && response.data.body && response.data.body.message ? response.data.body.message : 'Something went wrong',
            secondLine: '',
            thirdLine: ''
        };

        $('#confirmationDeniedModal').modal('show');
        if (response && response.data && response.data.body && response.data.httpCode === 500) {
            modelMessageError['header'] = 'Not Found';
            modelMessageError['firstLine'] = response.data.body.message
        }
        this.setState({
            loaderIconVisible: false,
            modelMessage: response && response.data && response.data.body && response.data.body.message ? response.data.body.message === 'Unit is not available in IMS database' ? modelMessage : response.data.body.message === 'Unit already registered' ? modelMessageAlreadyRegistered : modelMessage : modelMessageError
        });
    }

    validateDevice = async () => {
        const { serialNo: serialNumber, registrationCode, brand: brandName } = this.state;
        let url = DeviceRegistration.validateDeviceRegistration;
        const stringified = queryString.stringify({
            brandName,
            serialNumber,
            registrationCode,
            'flow_initiated_from': 'KC_REG_UNIT'
        });

        url = url + (stringified ? `?${stringified}` : '');

        await http.get(url, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data.httpCode === 200) {
                    if (response.data.body && response.data.body.length) {
                        if (response.data.body[0].jwtToken) {
                            // Sending token in params because if anyone comes from diff site(brand diagnostic), then localstorage is not possible
                            this.props.history.push('/subscriber?auth=' + response.data.body[0].jwtToken);
                        } else {
                            // If JWT token not present in the response
                            let modelMessage = {
                                header: 'Oops!!',
                                firstLine: response.data.body.message || 'Something went wrong',
                                secondLine: '',
                                thirdLine: ''
                            }
                            this.setState({
                                loaderIconVisible: false,
                                modelMessage,
                                serialNo: '',
                                registrationCode: '',
                                brand: '',
                            });
                        }
                    }
                } else {
                    this.handleErrorMessage(response)
                }
                this.setState({
                    loaderIconVisible: false
                });
            }
        }).catch(err => {
            this.handleErrorMessage(err.response)
        });
    }


    registerDevice = async () => {
        this.setState({
            loaderIconVisible: true
        });
        await this.validateDevice();
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible, brandList, modelMessage, errors, isCaptchaVerified, brand, registrationCode, serialNo } = this.state;
        return (
            <div id="adminWrapper">
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <div className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table">&nbsp;</div>
                </nav>
                <div id="page-wrapper">
                    <div className="deviceRegisterWrapper">
                        <div className="welcomText reLoginText"><FormattedMessage id='KC0881' /></div>
                        <div id="loginBtnFormID">
                            <div className="loginButton">
                                <ul>
                                    <li>&nbsp;</li>
                                    <li>
                                        <div className="loginIWrapper">
                                            <div className="existingLoginForm">
                                                <ul>
                                                    <li className="form-group">
                                                        <p><FormattedMessage id='KC0882' /></p>
                                                    </li>
                                                    <li className="form-group">
                                                        <div className="input-group">
                                                            <select className="form-control" onChange={(e) => this.setValues(e.target.value, 'brand')}>
                                                                <option value="">{formatMessage({ id: 'KC0651' })}</option>
                                                                {brandList && brandList.length ? brandList.map((brand, index) => {
                                                                    return (
                                                                        <option key={index} value={brand.BRAND_NAME}>{brand.BRAND_NAME}</option>
                                                                    )
                                                                }) : null}
                                                            </select>
                                                        </div>
                                                    </li>
                                                    <li className="form-group">
                                                        <div className="input-group"><input type="text" className="form-control" maxlength="30" placeholder={formatMessage({ id: 'KC0885' })} value={this.state.serialNo} onChange={(e) => this.setValues(e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, ''), 'serialNo')} />

                                                            <span className="requiredField">*</span>
                                                            {this.state.errors["serialNo"] && <div className="errorMsg">{this.state.errors.serialNo}</div>}
                                                        </div>
                                                    </li>
                                                    <li className="form-group">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" maxlength="21" placeholder={formatMessage({ id: 'KC0886' })} value={this.state.registrationCode} onChange={(e) => this.setValues(e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, ''), 'registrationCode')} />

                                                            <span className="requiredField">*</span>
                                                            {errors["registrationCode"] && <div className="errorMsg">{errors.registrationCode}</div>}
                                                        </div>
                                                    </li>
                                                    {isCaptchaEnabled() ? <li className="form-group">
                                                        <div className="g-recaptcha">
                                                            <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
                                                        </div>
                                                        {errors["recaptcha"] && <div className="errorMsg">{errors.recaptcha}</div>}
                                                    </li> : null}
                                                    <li className="form-group pass">
                                                        <button className="btn btn-secondary registerBtn" onClick={() => this.registerDevice()} disabled={isCaptchaEnabled() ? isCaptchaVerified && brand !== '' && registrationCode.trim() !== '' && serialNo.trim() !== '' ? false : true : brand !== '' && registrationCode.trim() !== '' && serialNo.trim() !== '' ? false : true} type="button"><FormattedMessage id='KC0888' /></button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                                <ul>
                                    <li>&nbsp;</li>
                                    <li>
                                        <div className="loginIWrapper">
                                            &nbsp;
                                        </div>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for Error Message */}
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationDeniedModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeniedModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationContentDiv">
                                    <h5>{modelMessage.header || ''}</h5>
                                    <p>{modelMessage.firstLine || ''}</p>
                                    {modelMessage.secondLine && <p className="modelDeviceRegistration">{modelMessage.secondLine || ''}</p>}
                                    {modelMessage.thirdLine && <p className="modelDeviceRegistration">{modelMessage.thirdLine || ''}</p>}
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary errorModalContentOKBtn" data-dismiss="modal" type="button">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for Error Message End */}
            </div>
        )
    }
};

export default injectIntl(RegisterDevice);