import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HACCPManagementAPI } from '../../../service/api';
import http from '../../../service/httpService';
import SuccessMoal from '../../modal/success-model';
import authService from '../../../service/authService';
import ErrorMoal from '../../modal/error-model';
import $ from 'jquery';
import DocumentViewers from '../../modal/docViewer'
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import { mappingStringTranslationID } from '../../../locale/constant';

const moment = require('moment');

const readAgentFileStringID = {
	"Agent log failed": "KC1844",
	"No Agent log found": "KC1845",
}

$(window).on('beforeunload', function () {
	if ($("#RetriveDownload").not(".disabled")) {
		$(".loadingProgress").css("display", "none");
	}
});

class SupportWidget extends Component {
	constructor(props) {
		super(props);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.state = {
			haccpUnitLogDetails: [],
			loaderIconVisible: false,
			haccpViewerUrl: [],
			requestLogDetails: [],
			allowDownload: false,
			allowRetrive: false,
			errorInRetrive: false,
			errMessage: "",
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: "",
				loaderIconVisible: false,
			},
			findStatus: "Retrieve Log Files",
			typeOfLock: "MachineLogs",
			readAgentFile : "",
			openFileViewer : false,
		};
	}

	componentDidMount = async () => {
		await this.getCloudsDetailsForThink();
		await this.getUnitLogStatusDetails();
	}

	triggerTimeout=()=>{
		this.supportinterval = setInterval(() => this.getUnitLogStatusDetails(), 30000);		
	}

	getCloudsDetailsForThink = async () => {
		let unitID = localStorage.getItem("unitID");
		const url = HACCPManagementAPI.unitLogDetails + unitID;
		await http.get(url, {
			headers: {
				'Content-Type': 'application/json'
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					haccpUnitLogDetails: response.data,
				});

				localStorage.setItem('cloudUnitName', response.data.cloudUnitName ? response.data.cloudUnitName : "");
			}
		}).catch(err => {

		});
	}

	getUnitLogStatusDetails = async () => {		
		if (window.location.pathname !== "/unitManagement"){
			return
		}
		let custGroupID = localStorage.getItem("custGroupID");
		let storageCustomerId = localStorage.getItem("customerId");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let haccpLogDetails = this.state.haccpUnitLogDetails;
		const url = HACCPManagementAPI.downloadFile + "?unitid=" + haccpLogDetails.cloudUnitName;
		let type = [];
		type.push('agentLog');
		type.push('machineLog');

		await http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'basecountry': localStorage.getItem("basecountry"),
				'brandid': localStorage.getItem("unitBrandId"),
				'mediatypes': "iotLog,sysLog",
				'customerid': storageCustomerId,
				'cgids': cgids
			}, data: {}
		}).then(response => {
			if (response && response.data) {		
				this.setState({
					haccpViewerUrl: response.data.devices ? response.data.devices : [],
				});
				
				if (response.data.devices.lenth > 1 && (response.data.devices[0].status.toLowerCase() === "completed" || response.data.devices[0].status.toLowerCase() === "succeeded") && (response.data.devices[1].status.toLowerCase() === "completed" || response.data.devices[1].status.toLowerCase() === "succeeded")) {
					if(this.supportinterval){
						clearInterval(this.supportinterval);
					}
				}
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false, errorInRetrive: false });
			//Allow retrieve ,if No device log found
			if (err.response && err.response.data.responseCode === "HACCP_LG_4004") {
				this.setState({ loaderIconVisible: false, allowRetrive: true, findStatus: err.response.data.message });
			}
		});
	}

	requestRetrivalLog = async () => {
		await this.postRetriveLog("iotLog");
		await this.postRetriveLog("sysLog");
		await this.getUnitLogStatusDetails();
		WoopraEvents(`${Constants.RETRIEVE_MACHING_AND_AGENT_LOG}`);
	}

	postRetriveLog = async (type) => {
		this.setState({ loaderIconVisible: true, findStatus: "Open Log Files" });
		let haccpLogDetails = this.state.haccpUnitLogDetails;
		let thingList = [{
			"thingArn": haccpLogDetails.thingArn,
			"modelName": haccpLogDetails.MODEL_NAME,
			"modelNumber": haccpLogDetails.MODEL_NAME,
			"cloudUnitName": haccpLogDetails.cloudUnitName,
			"unitTimeZone": haccpLogDetails.unitTimeZone,
		}];

		let postdata = {
			thingList: thingList,
			brandid: localStorage.getItem("unitBrandId"),
			logType: type,
		}
		let storageCustomerId = localStorage.getItem("customerId");
		const url = HACCPManagementAPI.requestLog;
		await http.post(url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
					basecountry: localStorage.getItem("basecountry"),
					user: localStorage.getItem("user"),
					customerid: storageCustomerId,
				}
			}
		).then(response => {
			if (response && response.data) {
				
				this.setState({
					loaderIconVisible: false,
					requestLogDetails: response.data,
					success_modal: {
						open: true,
						message: "KC1841" 
					},
				},()=>{
					this.triggerTimeout();
				});				
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					message: "KC1337" 
				},
			});
		});
	}

	downloadLogViewer = (status) => {
		// this.setState({ loaderIconVisible: false,openFileViewer : false });
		let { haccpViewerUrl, typeOfLock } = this.state;

		if (status === "Try Again") {
			this.setState({ openFileViewer : false }, ()=>{
				this.requestRetrivalLog();
			});			
		} else {
			if (this.state.haccpViewerUrl !== '#') {
				let urls = this.state.haccpViewerUrl[0].extractFileS3Url;
				if (typeOfLock === "MachineLogs") {
					urls = this.state.haccpViewerUrl[1].extractFileS3Url;
					WoopraEvents(Constants.DOWNLOAD_MACHINE_LOG);
				} else if (typeOfLock === "AgentLogs") {
					urls = this.state.haccpViewerUrl[0].extractFileS3Url;
					WoopraEvents(Constants.DOWNLOAD_AGENT_LOG)
				}
				if (urls){
					window.location = urls;
				}				
				// this.setState({ loaderIconVisible: false });
			}
		}
	}

	callCloseClick = () =>{
		this.setState({ openFileViewer: false })
	}

	onSuccessCloseModal() {
		this.setState({
			success_modal: {
				open: false,
				message: "KC1841"
			},
		}, () => {
			this.getUnitLogStatusDetails();
		});
	}

	errorCloseModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	findTheTab = (type) => {
		this.setState({
			typeOfLock: type,
		},() => {
			if (type === "AgentLogs" && this.state.haccpViewerUrl.length > 0) {
				this.setState({
					openFileViewer: false,
				})
				if (this.state.haccpViewerUrl[0].status.toLowerCase() === "completed" || this.state.haccpViewerUrl[0].status.toLowerCase() === "succeeded") {
					this.fetchTextFile(type);
				} else if (this.state.haccpViewerUrl[0].status.toLowerCase() === "failed"){
					this.setState({ readAgentFile: "Agent log failed" })
				} else {
					this.setState({ readAgentFile: "No Agent log found" })
				}
			} else if (type === "MachineLogs" && this.state.haccpViewerUrl.length > 1) {
				this.callOpenLogBook();
			}			
		})		
	}

	fetchTextFile = () => {
		let fileUrl = this.state.haccpViewerUrl;
		if (fileUrl[0].hasOwnProperty('extractFileS3Url')) {
			fileUrl = fileUrl[0].extractFileS3Url;
			fetch(fileUrl)
				.then(res => res.text())
				.then(t => {
					this.setState({ readAgentFile: t })
				});
		} else {
			this.setState({ readAgentFile: "Agent log failed" })
		}
	}

	callOpenLogBook = () => {		
		let logDetails = this.state.haccpViewerUrl;
		//File view will open based on the machine log array ex. logDetails[1]
		if (logDetails[1].hasOwnProperty('extractFileS3Url')){
			WoopraEvents(`${Constants.OPEN_MACHINE_AND_AGENT_LOG}`)
			if (logDetails[1].extractFileS3Url) {
				this.setState({
					openFileViewer: true,
				})
			}
		}
	}

	setLogStatus = (status1,status2) => {
		let status;
		if ((status1 === "queued" && status2 === "failed") || (status2 === "queued" && status1 === "failed")) {
			status = "failed";
		} else if ((status1 === "queued" && status2 === "succeeded") || (status2 === "queued" && status1 === "succeeded")) {
			status = "queued";
		} else if ((status1 === "queued" && status2 === "completed") || (status2 === "queued" && status1 === "completed")) {
			status = "queued";
		} else if ((status1 === "queued" && status2 === "pending") || (status2 === "queued" && status1 === "pending")) {
			status = "pending";
		} else if ((status1 === "queued" && status2 === "in_progress") || (status2 === "queued" && status1 === "in_progress")) {
			status = "in_progress";
		}
		
		else if ((status1 === "succeeded" && status2 === "failed") || (status2 === "succeeded" && status1 === "failed")) {
			status = "failed";
		} else if ((status1 === "succeeded" && status2 === "queued") || (status2 === "succeeded" && status1 === "queued")) {
			status = "queued";
		} else if ((status1 === "succeeded" && status2 === "pending") || (status2 === "succeeded" && status1 === "pending")) {
			status = "pending";
		} else if ((status1 === "succeeded" && status2 === "in_progress") || (status2 === "succeeded" && status1 === "in_progress")) {
			status = "in_progress";
		}
		
		else if ((status1 === "completed" && status2 === "failed") || (status2 === "completed" && status1 === "failed")) {
			status = "failed";
		} else if ((status1 === "completed" && status2 === "queued") || (status2 === "completed" && status1 === "queued")) {
			status = "queued";
		} else if ((status1 === "completed" && status2 === "pending") || (status2 === "completed" && status1 === "pending")) {
			status = "pending";
		} else if ((status1 === "completed" && status2 === "in_progress") || (status2 === "completed" && status1 === "in_progress")) {
			status = "in_progress";
		}

		else if ((status1 === "failed" && status2 === "pending") || (status2 === "failed" && status1 === "pending")) {
			status = "failed";
		} else if ((status1 === "failed" && status2 === "in_progress") || (status2 === "failed" && status1 === "in_progress")) {
			status = "failed";
		} 

		else if ((status1 === "canceled" && status2 === "failed") || (status2 === "canceled" && status1 === "failed")) {
			status = "failed";
		} else if ((status1 === "canceled" && status2 === "queued") || (status2 === "canceled" && status1 === "queued")) {
			status = "queued";
		} else if ((status1 === "canceled" && status2 === "pending") || (status2 === "canceled" && status1 === "pending")) {
			status = "pending";
		} else if ((status1 === "canceled" && status2 === "in_progress") || (status2 === "canceled" && status1 === "in_progress")) {
			status = "in_progress";
		} else if ((status1 === "canceled" && status2 === "succeeded") || (status2 === "canceled" && status1 === "succeeded")) {
			status = "succeeded";
		} else if ((status1 === "canceled" && status2 === "completed") || (status2 === "canceled" && status1 === "completed")) {
			status = "succeeded";
		} else {
			status = status1;
		}

		return status;
	}

	componentWillUnmount = () => {
		if (this.supportinterval){
			clearInterval(this.supportinterval);
		}
	}

	componentDidUpdate = () => {
		if (this.state.typeOfLock === "MachineLogs") {
			$('.pg-viewer-wrapper').css("overflow-y", "hidden")
			$('.modelscroll .modal-body, .modelscroll .openLogFilesModalDiv').css("overflow", "hidden")
		} else {
			$('.modelscroll .modal-body').css("overflow", "hidden")
			$('.openLogFilesModalDiv').css("overflow-y", "scroll")
		}
	}
	
	render() {
		let viewerUrl;
		let { haccpUnitLogDetails, findStatus, success_modal, loaderIconVisible, error_modal, openFileViewer,
			allowRetrive, errorInRetrive, haccpViewerUrl, errMessage, typeOfLock, readAgentFile } = this.state;

		let status = "No Records";
		let isvisible = false;
		let requestDate = "";
		let exportCaption = "Export";		
		if (haccpViewerUrl.length > 0) {
			status = haccpViewerUrl[0].status.toLowerCase();
			isvisible = haccpViewerUrl[0].IS_VISIBLE ? 1 : haccpViewerUrl[1].IS_VISIBLE ? 1 : 0;
			if (haccpViewerUrl[0] && haccpViewerUrl[0].REQUEST_DATE){
				requestDate = moment(haccpViewerUrl[0].REQUEST_DATE).format('LL') + " - " + moment(haccpViewerUrl[0].REQUEST_DATE).format('LT');
			}
			if (typeOfLock === "MachineLogs") {
				exportCaption = haccpViewerUrl[1].status.toLowerCase() === "failed" || haccpViewerUrl[1].status.toLowerCase() === "canceled" ? "Try Again" : "Export";
				viewerUrl = haccpViewerUrl[1] && haccpViewerUrl[1].extractFileS3Url ? haccpViewerUrl[1].extractFileS3Url : "";
			} else if (typeOfLock === "AgentLogs") {
				exportCaption = haccpViewerUrl[0].status.toLowerCase() === "failed" || haccpViewerUrl[0].status.toLowerCase() === "canceled" ? "Try Again" : "Export";
				viewerUrl = haccpViewerUrl[0] && haccpViewerUrl[0].extractFileS3Url ? haccpViewerUrl[0].extractFileS3Url : "";
			}
		}

		let status1;
		let status2;
		if (haccpViewerUrl.length > 1) {
			status1 = haccpViewerUrl[0].status.toLowerCase();
			status2 = haccpViewerUrl[1].status.toLowerCase();
			if (status1 !== status2){
				status = this.setLogStatus(status1,status2)
			}
		}

		let showRetriveLog;
		let showOpenLog;
		const brandName = localStorage.getItem("brandName")
		if (brandName === "CREM") {
			if (haccpUnitLogDetails && haccpUnitLogDetails.CONNECT_STATE === "Offline" || haccpUnitLogDetails && haccpUnitLogDetails.thingArn === null) {								
				showRetriveLog = (
				<li>
					<button type="button" class="btn btn-default retrieveBtn disabled">
						<span class="btnIcon retrivalLogBtn"></span> 
						<span class="btnText"><FormattedMessage id="KC0250"/> <b><FormattedMessage id="KC0247"/></b></span> 
						<span class="btnProgress retrieving"></span>
					</button>
				</li>)

				showOpenLog = (
				<li>
					<button type="button" class="btn btn-default disabled">
						<span class="btnIcon OpenLogbook"></span>
						<span class="btnText"><FormattedMessage id="KC0251"/><b><FormattedMessage id="KC0249"/></b></span>
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>);	
				if ((status === "completed" || status.toLowerCase() === "succeeded" || status === "failed") && isvisible === 1 ) {
					showOpenLog = (						
					<li>
						<button type="button" class="btn btn-info" onClick={(e) => { this.callOpenLogBook(e) }} data-toggle="modal" data-target="#openLogFilesModal">
							<span class="btnIcon OpenLogbook"></span>
							<span class="btnText"><FormattedMessage id="KC0251"/><b>{requestDate}</b></span>
							<span class="btnProgress retrieving" ></span>
						</button>
					</li>)
				}				
			} else if (haccpUnitLogDetails && haccpUnitLogDetails.CONNECT_STATE === "Online") {
				showRetriveLog = (
				<li>
					<button onClick={(e) => { this.requestRetrivalLog(e) }} type="button" class="btn btn-default retrieveBtn">
						<span class="btnIcon retrivalLogBtn"></span>
						<span class="btnText"><FormattedMessage id="KC0250"/> <b><FormattedMessage id="KC0247"/></b></span>
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>);

				showOpenLog = (
				<li>
					<button type="button" class="btn btn-default disabled">
						<span class="btnIcon OpenLogbook"></span>
						<span class="btnText"><FormattedMessage id="KC0251"/><b><FormattedMessage id="KC0249"/></b></span>						
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>)

				if (status === "queued" || status === "pending" || status === "in_progress") {
					showRetriveLog = (
					<li>
						<button type="button" class="btn btn-default retrieveBtn disabled">
							<span class="btnIcon retrivalLogBtn"></span> 
							<span class="btnText"><FormattedMessage id="KC0250"/> <b><FormattedMessage id="KC0247"/></b></span> 
							<span class="btnProgress retrieving" style={{ width: `${status === "queued" ? 15 : 65}%` }}></span>
						</button>
					</li>);
				} else if (status === "failed") {
					showRetriveLog = (
					<li>
						<button onClick={(e) => { this.requestRetrivalLog(e) }} type="button" class="btn btn-default retrieveBtn">
							<span class="btnIcon retrivalLogBtn"></span> 
							<span class="btnText"><FormattedMessage id="KC0250"/> <b><FormattedMessage id="KC0247"/></b></span> 
							<span class="btnProgress retrievFailed" style={{ width: `${100}%` }}></span>
						</button>
					</li>);
				}

				if ((status === "completed" || status.toLowerCase() === "succeeded" || status === "failed") && isvisible === 1 ) {
					showOpenLog = (						
					<li>
						<button type="button" class="btn btn-info" onClick={(e) => { this.callOpenLogBook(e) }} data-toggle="modal" data-target="#openLogFilesModal">
							<span class="btnIcon OpenLogbook"></span>
							<span class="btnText"><FormattedMessage id="KC0251"/><b>{requestDate}</b></span>
							<span class="btnProgress retrieving" ></span>
						</button>
					</li>)
				}
			}
		}

		let features = {};
		features = authService.getFeatureByFeatureName("Device Log Downloads");
		let pageRender;
		if (features && features.featureAvailable && (brandName === "CREM")) {
			pageRender = (
				<div class="colm3D colm3D1">
					<div class="colmDi">
						<h4><FormattedMessage id="KC0004"/></h4>
						<div className="colmDiVal">
							<div className="btnListView">
								<ul>
									<>
										{showRetriveLog}
										{showOpenLog}
									</>
								</ul>
							</div>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={() => this.errorCloseModel()} /> 
					<SuccessMoal open={success_modal.open} message={success_modal.message}  stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} /> 
				</div>
			);
		}
		return (
			<React.Fragment>
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress --> */}
				{pageRender}

				{/* <!-- Open Log Files Modal --> */}
				<div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="openLogFilesModal" tabindex="-1" role="dialog" aria-labelledby="openLogFilesModalLabel">
					<div class="modal-dialog modal-lg modelscroll">
						<div class="modal-content" id="agedUGrpsAccordion">
							<div class="modal-header modal-header-danger padding0px">
								<ul class="nav nav-tabs openLogFilesTab" id="myTab" role="tablist">
									<li class="nav-item">
										<a class="nav-link active" id="MachineLogs-tab" data-toggle="tab" href="#MachineLogs" role="tab" aria-controls="MachineLogs" aria-selected="true" onClick={(e) => { this.findTheTab("MachineLogs") }}><FormattedMessage id="KC1846"/></a> 
									</li>									
									<li class="nav-item">
										<a class="nav-link" id="AgentLogs-tab" data-toggle="tab" href="#AgentLogs" role="tab" aria-controls="AgentLogs" aria-selected="false" onClick={(e) => { this.findTheTab("AgentLogs") }}><FormattedMessage id="KC1908"/></a> 
									</li>
								</ul>
							</div>
							<div class="modal-body">
								<div class="openLogFilesModalDiv">
									<div class="tab-content" id="myTabContent">
										<div class="tab-pane fade show active" id="MachineLogs" role="tabpanel" aria-labelledby="MachineLogs-tab">										
											{openFileViewer ? <DocumentViewers docType="xlsx" docsPath={viewerUrl} /> : <FormattedMessage id="KC1907"/>} 
										</div>
										<div class="tab-pane fade" id="AgentLogs" role="tabpanel" aria-labelledby="AgentLogs-tab">
											<pre class="pretext">{readAgentFileStringID[readAgentFile] ? <FormattedMessage id={readAgentFileStringID[readAgentFile]}/> : readAgentFile}</pre>											
										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e)=>{this.callCloseClick()}} ><FormattedMessage id="KC1679"/></button>
								{exportCaption === "Try Again" ?
									<button onClick={(e) => { this.downloadLogViewer(exportCaption) }} class="btn btn-secondary yesBtn" data-dismiss="modal" type="button"> {mappingStringTranslationID[exportCaption] ? <FormattedMessage id={mappingStringTranslationID[exportCaption]}/> : exportCaption}</button> :
									<button onClick={(e) => { this.downloadLogViewer(exportCaption) }} class="btn btn-secondary yesBtn" type="button"> {mappingStringTranslationID[exportCaption] ? <FormattedMessage id={mappingStringTranslationID[exportCaption]}/> : exportCaption}</button>
								}
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Open Log Files --> */}
			</React.Fragment>
		)
	}
};
export default injectIntl(SupportWidget);
