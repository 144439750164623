import http from '../../../service/httpService';
import authService from '../../../service/authService';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userRoleURI } from '../../../service/api';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { FeaturesList } from '../../controls/featureList';
import { BrandList } from '../../controls/brandList';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { BRAND_ID } from '../../../utils/appConstants';
import authConstants  from '../../../utils/authConstants';
import  { unitAPI } from '../../../service/api';
import _ from 'lodash';

import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';

const { allFeaturesKey }  = authConstants;
const FeaturesCategory = {
	GF: { title: "Global Functions", stringID: "KC0622" },
	FP: { title: "Unit Data", stringID: "KC0625" },
	MMA: { title: "Main Menu Apps", stringID: "KC0587" }
}


 class UserRole extends Component {


  state = {
    roleId: 0,
    customerId: localStorage.getItem("customerId"),
    roleName : '',
    isRoleFeatureUpdated : false,
    loaderIconVisible: true,
    isRoleCreatedCompleted: false,
    allDefaultBrandsList: [],
    corborateRolesDropDownSource: [],
    roleFeaturesList: [],
    allFeaturesData: [],
    featureChkBoxDisabled: false,
    FeaturesCategoryList : [FeaturesCategory.GF, FeaturesCategory.FP, FeaturesCategory.MMA],
    errors: {},
    isFormValidated:false,
    isBrandSelected:false,
    responseMessage:"",
    success_modal: {
      open:false,
      message:""
    },
    error_modal:{
      open:false,
      message:""
    },
    isEditFormActive:false,
    viewBrandsList: [],
    readyRecipeFeature: {}
  };

  /*
  * React Life Cycle Events 
  * componentWillMount
  * componentDidMount
  * componentWillUnmount
  */
  componentWillMount = () => {
    window.addEventListener('resize', this.updateDimensions());
    document.body.style.backgroundColor = "#F2F2F2";
    let { match } = this.props;
    const { params }  = match;
    if(params.roleId && !isNaN(parseInt(params.roleId))){
      this.setRolesForUpdateForm(params.roleId);
    }
		else {
			this.setDefaultBrandsAndFeatures()
		}
  }

 /**Fixed header*/
   updateDimensions = () => {
     const windowHeight = $(window).height();
     const tableBody = $('.pageScrollbar');
     const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
     tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
   }

   componentDidUpdate = () => {
     this.updateDimensions();
   }
  /**Methods*/

  selectBrand = (e) => {
    let { match } = this.props;
    const { params }  = match;
		if (e.target.value || params.roleId) {
      const selectedRoleId = e.target.value ? e.target.value : params.roleId;
			this.setRolesAndBrand(selectedRoleId);
		}	else {
			this.setDefaultBrandsAndFeatures()
		}
  }
  
  setRolesForUpdateForm = (roleId) => {
    
		this.loadingPopupShowHide(true);
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    let loginFeatureInfo = JSON.parse(localStorage.getItem(allFeaturesKey));
    let loggedinUser = loginUser.username;
    let loggedinUserRoleId = loginFeatureInfo[0].role_id;
    let rolesBrandsFeatures = [this.getData(userRoleURI.roleBaseAPI + '/' + roleId + "/features?customer=" + localStorage.getItem("customerId")), 
    this.getData(unitAPI.getBrands), this.getData(userRoleURI.getAllFeatures, {BRAND_ID}), 
    this.getData(userRoleURI.getRolesByCustomer + localStorage.getItem("customerId")+"&user_email="+loggedinUser+"&loginUserRoleid="+loggedinUserRoleId +"&brandIds="+BRAND_ID), 
    this.getData(userRoleURI.roleBaseAPI + "/" + roleId ),
    this.getData(userRoleURI.getRolesBrand, {roleId})] 

		Promise.all(rolesBrandsFeatures)
			.then(responses => {
				let featuresData =  responses && responses.length >=1 ? responses[0].data : [];
				let brands = responses && responses.length >= 2 ? responses[1].data : this.state.allDefaultBrandsList;			
				let allFeaturesData = responses && responses.length >=3 ? responses[2].data.data.filter((c)=> c.feature_name!="Ready Recipe") : this.state.allFeaturesData.filter((c)=> c.feature_name!="Ready Recipe");
        let readyReadyRecipeObj = responses && responses.length >=3 ? responses[2].data.data.find((c)=> c.feature_name=="Ready Recipe") : this.state.allFeaturesData.find((c)=> c.feature_name=="Ready Recipe");
				let featureList = featuresData && featuresData.data && featuresData.data.features.length > 0 ? featuresData.data.features : [];
        let rolesList = responses && responses.length >=4 ? responses[3].data.data : this.state.corborateRolesDropDownSource;
        let role = responses && responses.length >=5 ? responses[4].data.data : {};
        let userBrands = responses && responses.length >=6 ? responses[5].data.data : [];
        let enableDisableFeature;
        // Remove role from template role list which is being modified
        rolesList = _.filter(rolesList, function(currentObject) {
          return currentObject.Id !== +roleId;
        });      
        allFeaturesData.forEach(f => {
          // f.feature_active = false;
          enableDisableFeature = this.setAllowedFeature(f,loginFeatureInfo,false);
          f.isViewDefaultDisable = enableDisableFeature.isViewDefaultDisable;
          f.isEditDefaultDisable = enableDisableFeature.isEditDefaultDisable;
        });
        let viewBrandsList = this.getBrandsByUserRoleSelection(this.getLoginUserBrands(brands), userBrands);
        let selectedBrandCount = viewBrandsList.filter(item => item.isChecked === true);
				this.setState({
          roleId : roleId,
          isEditFormActive: true,
          roleName : role.role_name,
          isRoleFeatureUpdated : true,
          allDefaultBrandsList : brands,
					viewBrandsList : viewBrandsList,
          isBrandSelected : selectedBrandCount.length > 0 ? true : false, 
					roleFeaturesList: featureList,
					allFeaturesData: allFeaturesData,
          corborateRolesDropDownSource: rolesList,
          isFormValidated : true,
          readyRecipeFeature: readyReadyRecipeObj
        });
				this.loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				this.loadingPopupShowHide(false);
			});
  }

  setRolesAndBrand = (roleId) => {

		this.loadingPopupShowHide(true);
		 
    let rolesBrandsFeatures = [this.getData(userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + localStorage.getItem("customerId")), 
    this.getData(userRoleURI.getRolesBrand, {roleId})]

		Promise.all(rolesBrandsFeatures)
			.then(responses => {
        let featuresData =  responses && responses.length >=1 ? responses[0].data : [];
        let userBrands = responses && responses.length >=2 ? responses[1].data.data : [];
        let featureList = featuresData && featuresData.data && featuresData.data.features.length > 0 ? featuresData.data.features : [];
				let brands =  [...this.state.allDefaultBrandsList];	
        let viewBrandsList = this.getBrandsByUserRoleSelection(this.getLoginUserBrands(brands), userBrands);
        let selectedBrandCount = viewBrandsList.filter(item => item.isChecked === true);

				this.setState({
          isRoleFeatureUpdated : true,
					viewBrandsList: viewBrandsList,
          isBrandSelected : selectedBrandCount.length > 0 ? true : false, 
					roleFeaturesList: featureList
				});
				this.loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				this.loadingPopupShowHide(false);
			});
  }
  
  setDefaultBrandsAndFeatures = () => {
    
    this.loadingPopupShowHide(true);
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    let loginFeatureInfo = JSON.parse(localStorage.getItem(allFeaturesKey));
    let loggedinUser = loginUser.username;
    let loggedinUserRoleId = loginFeatureInfo[0].role_id;
    return Promise.all([this.getData(userRoleURI.getAllFeatures, {BRAND_ID}), 
      this.getData(unitAPI.getBrands), 
      this.getData(userRoleURI.getRolesByCustomer + localStorage.getItem("customerId")+"&user_email="+loggedinUser+"&loginUserRoleid="+loggedinUserRoleId +"&brandIds="+BRAND_ID )])
      .then(responses => {

        let featuresData = responses[0].data.data.filter((value)=> value.feature_name!="Ready Recipe");
        let readyReadyRecipeObj = responses[0].data.data.find((value)=> value.feature_name=="Ready Recipe");
        let brands = responses[1].data;
        let rolesList = responses && responses.length >=2 ? responses[2].data.data : [];
        let enableDisableFeature
        featuresData.forEach(f => {
          f.feature_active = false;
          enableDisableFeature = this.setAllowedFeature(f,loginFeatureInfo,false);
          f.isViewDefaultDisable = enableDisableFeature.isViewDefaultDisable;
          f.isEditDefaultDisable = enableDisableFeature.isEditDefaultDisable;
        });

        this.setState({
          roleFeaturesList: [],
          allFeaturesData: featuresData,
          allDefaultBrandsList : brands,
          viewBrandsList: this.getLoginUserBrands(brands),
          corborateRolesDropDownSource: rolesList,
          readyRecipeFeature: readyReadyRecipeObj
        });
        this.loadingPopupShowHide(false);
      })
      .catch(err => {
        //Error
        this.loadingPopupShowHide(false);
      });
  }

  //Template role feature for create role
  setAllowedFeature = (feature, loggedinUserFeatures,isRoleFeatureUpdated) => {
    let hasFeature;
    if(isRoleFeatureUpdated){
      hasFeature = _.find(loggedinUserFeatures,['feature_id', feature.feature_id]);
    }
    else {
      hasFeature = _.find(loggedinUserFeatures,['feature_id', feature.Id]);
    }
    
    let isViewDefaultDisable = true;
    let isEditDefaultDisable = true;
    if(hasFeature){
      if(hasFeature.is_view_only){
        isViewDefaultDisable = false;
      }
      if(hasFeature.is_editable){
        isEditDefaultDisable = false;
      }
    }
    
    return { isViewDefaultDisable, isEditDefaultDisable }
    }

  addOrUpdateRole = () =>{

    if(this.state.roleName.trim().length <3 || this.state.roleName.trim().length > 50 )
    {
      this.setState({
        error_modal : {
          message: `KC1079`,
          open : true
        }
       });
       return false
    }

    if(this.state.roleName.indexOf("  ") !=-1 )
    {
      this.setState({
        error_modal : {
          message: `KC1080`,
          open : true
        }
       });
       return false
    }

    const brandIds = _.map(_.filter(this.state.viewBrandsList, {isChecked: true}), 'ID');
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
    let requestObject = {
      role: {
        role_name: this.state.roleName.trim(),
        is_active: true,
        customer_id: this.state.customerId
      },
      features: this.setFeatureStatus(this.state.allFeaturesData, brandIds),
      brandIds,
      user_email: loggedinUser
    }
    if(this.state.isEditFormActive){
      requestObject.role.role_id = this.state.roleId;
      this.submitRole(requestObject, 'PUT');
    }
    else{
        this.submitRole(requestObject, 'POST');    
    }
  }

  setFeatureStatus = (featuresList, brandIds) => {
    let checkBrands;
    featuresList.forEach(f => {
      checkBrands = _.intersection(brandIds, f.brand_id);
      if (checkBrands.length > 0) {
        if (f.is_view_only === 0 && f.is_editable === 0) {
          f.feature_active = 0;
        }
        else{
          f.feature_active = 1;
        }
      } else {
        f.is_view_only = 0;
        f.is_editable = 0;
        f.feature_active = 0;
      }
    })

    this.setState({
      readyRecipeFeature: {
        ...this.state.readyRecipeFeature,
        isViewDefaultDisable: false,
        isEditDefaultDisable: false,
        is_view_only: 0,
        is_editable: 0
      }
    }, () => {
      featuresList.push(this.state.readyRecipeFeature);
    });

    return featuresList;
  }

  submitRole = (requestObject, http_method) => 
  {
    this.loadingPopupShowHide(true);
    http({
      method: http_method,
      url: userRoleURI.roleBaseAPI,
      data: requestObject
    }).then(response => {
      let { data } = response;
      this.setState({
        loaderIconVisible: false,
        success_modal : {
          message: data.data,
          open : true
        }
      });
      if (http_method == 'PUT') {
        WoopraEvents(`${Constants.UPDATE_USER_ROLE}`);
      } else {
        WoopraEvents(`${Constants.CREATE_NEW_USER_ROLE}`);
      }
    }).catch(error =>{
       let errorResponse = error.response;
       if(errorResponse.status === 400 || errorResponse.status === 500){
        let responseMessage = errorResponse.data["KC_ERR_400"] ? errorResponse.data["KC_ERR_400"] : errorResponse.data["KC_ERR_500"] ? errorResponse.data["KC_ERR_500"] : "";
         this.setState({
          loaderIconVisible : false,
          error_modal : {
            message: responseMessage,
            open : true
          }
         });      
       }
       else{
        this.loadingPopupShowHide(false);
       }
    });
  }

  goToRoleList = () => {
    this.props.history.push('/userManagementRoles');
  }

  loadingPopupShowHide = (isActive) => {
    this.setState({
      loaderIconVisible: isActive
    });
  }

  successCloseModel = () => {
    this.setState({
      success_modal : {
        message:"",
        open : false
      }
    });
    this.goToRoleList();
  }

  errorEloseModel = () => {
		this.setState({
			error_modal: {
        message:"",
				open: false
			}
		})
	}

  /**
   * HTTP Events
   */
  getData = (url, args={}) => {
		let headers = {};
		const {roleId, BRAND_ID} = args;
		headers["Content-Type"] = 'application/json';
		if (roleId) {
			headers["role_id"] = roleId;
		} else if (BRAND_ID) {
			headers["brandid"] = BRAND_ID;
		}
    return new Promise((resolve, reject) => {
      http.get(url, {
        headers: headers,
        data: {}
      }).then(response => resolve(this.responseSuccessParser(response)))
        .catch(err => {
          this.loadingPopupShowHide(false);
        });
    });
  }

  responseSuccessParser = (response) => {
    return new Promise((resolve, reject) => {
      const { data } = response;
      if (data) {
        resolve(response);
      } else {
        let { data } = response;
        if (data) {
          let { body } = data;
          if (body != null)
            reject(body);
        }
      }
    });
  }

  onTextBoxChange = (e) =>{
    let roleName = e.target.name === "roleName" && e.target.value;
    let isFormValidated;
     if(!roleName || roleName.trim().length<1){
        isFormValidated = false;
    }
    else {
        isFormValidated = true;
    }
    this.setState({
      roleName : roleName,
      isFormValidated : isFormValidated
    });	
  }

  onHandleCheckBoxChange = (e, item) =>{
     
    let { allFeaturesData : features } = this.state;
    const featureindex = features.findIndex(f => f.Id === item.Id);
    if(featureindex !== -1)
    {
      let newFeature = {...features[featureindex]};
      if(e.target.name.endsWith('is_view'))
        newFeature.is_view_only =  e.target.checked ? 1 : 0;  
      if(e.target.name.endsWith('is_edit'))
        newFeature.is_editable =  e.target.checked ? 1 : 0 ;
     
     newFeature.feature_active = newFeature.is_view_only || newFeature.is_editable ? 1 : 0;
     features[featureindex] = newFeature;
    
    this.setState({
      isRoleFeatureUpdated : false,
      allFeaturesData : features
    });
     }
  }

  onHandleAllCheckBoxChange = (e, featureTile) => {
    let { allFeaturesData  } = this.state;
    let features = [...allFeaturesData]
    let selectedFeatures = features.filter(item => item.feature_category === featureTile);
    if(selectedFeatures && selectedFeatures.length>0){
    if(e.target.name.endsWith('ViewOnly'))
    {
          selectedFeatures.forEach(f => {
            if(!f.isViewDefaultDisable){
              f.is_view_only = e.target.checked ? 1 : 0;
              f.feature_active = e.target.checked ? 1 : 0;
            }
          });
    }
    if(e.target.name.endsWith('EditOnly')) 
    {
      selectedFeatures.forEach(f => {
        if(!f.isEditDefaultDisable){
          f.is_editable = e.target.checked ? 1 : 0;
          f.feature_active = e.target.checked ? 1 : 0; 
        }
      });
    }
    this.setState({
      isRoleFeatureUpdated : false,
      allFeaturesData : features
    });
  }
  }

  validateForm() {

		let { roleName } = this.state;
		let errors = {};
		if (!roleName) {			
		  errors["roleName"] = "KC1081";
		}
    
		this.setState({
      errors: errors
    });
  
  }
  
  /**
   * Get Default brands which was subscription by customer
   */
  getLoginUserBrands = (brands) =>{
   
    let loginUserBrands = authService.getLoginUserBrands();
    let viewBrandsList = [], allBrandsList = [...brands];
    loginUserBrands.forEach(bId => {
      let selectedBrans = allBrandsList.filter(item => item.ID === bId);
      if(selectedBrans && selectedBrans.length>0){
        let brand = {...selectedBrans[0]};
        brand.isChecked = false;
        viewBrandsList.push(brand);
      }
    });

    return viewBrandsList;
  }

  /**
   * Get Brands which was chosen by selected role
   */
  getBrandsByUserRoleSelection = (viewBrandsList, selectedBrands) =>{

    for (let index = 0, len = selectedBrands.length; index < len; index++) {
        let selectedBrans = viewBrandsList.filter(item => item.ID === selectedBrands[index]);
        if(selectedBrans && selectedBrans.length>0){
          selectedBrans[0].isChecked = true;
        } 
    }

    return viewBrandsList;
  }

  /**
   * Iterate the feature list and filter based on the brandid selection
   */

  onFilterFeatureList = () => {
    const { viewBrandsList, allFeaturesData } = this.state;
    const brandIdList = _.map(_.filter(viewBrandsList, {isChecked: true}), 'ID');
    const featureList = allFeaturesData.filter(el => {
      for (const id of brandIdList) {
        if (el && _.includes(el.brand_id, id)) {
          return true;
        }
      }
    });
    return featureList;
  };

  /**
   * Feature Select or Un-Select based on brands selection
   */
  updateFeatureSelection = () => {
    const {viewBrandsList, allFeaturesData} = this.state;
    let brandsList = [...viewBrandsList];
    let allFeatureList = [...allFeaturesData];
    const brandIdList = _.map(_.filter(brandsList, {isChecked: true}), 'ID');
    let ChkBoxDisabled = true;

    allFeatureList = allFeatureList.map(el => {
      let selectedRec = false;
      for (let bId of brandIdList) {
        if (el.brand_id.includes(bId)) {
          selectedRec = true;
          return el;
        }
      }

      if(!selectedRec) {
        return Object.assign({}, el, { is_view_only: 0, is_editable: 0 });
      }
      
    });

    if(brandIdList && brandIdList.length) {
      ChkBoxDisabled = false;
    } else {
      allFeatureList = allFeatureList.map(el => Object.assign({}, el, {is_view_only : 0 , is_editable : 0}));
    }
    return {allFeatureList,ChkBoxDisabled};
  }

  /**
   * Select or Un-Select all brands
   */
  isAllBrandSelectionChange = (e) => {
    const {viewBrandsList} = this.state;
    let brandsList = [...viewBrandsList];
    brandsList.forEach(b => {
      b.isChecked = e.target.checked;
    });
    const {allFeatureList, ChkBoxDisabled} = this.updateFeatureSelection();

    this.setState({
      viewBrandsList: brandsList,
      isBrandSelected : e.target.checked ? true: false,
      allFeaturesData: allFeatureList,
      featureChkBoxDisabled: ChkBoxDisabled
    });
  }

  /**
   * Select or Un-Select brands
   */
  isBrandSelectationChange = (e, item) => {
    const {viewBrandsList} = this.state;
    let brandsList = [...viewBrandsList];
    let index = brandsList.indexOf(item);
    brandsList[index].isChecked = e.target.checked;
    let {allFeatureList, ChkBoxDisabled} = this.updateFeatureSelection();
    let selectedBrandCount = brandsList.filter(item => item.isChecked === true);
  
    this.setState({ 
      viewBrandsList: brandsList, 
      allFeaturesData: allFeatureList, 
      isBrandSelected : selectedBrandCount.length > 0 ? true : false, 
      featureChkBoxDisabled: ChkBoxDisabled});
  }

  render() {

    const { formatMessage } = this.props.intl;

    let { corborateRolesDropDownSource, 
          allDefaultBrandsList: brandDropDownSource, 
          loaderIconVisible, 
          roleFeaturesList, 
          allFeaturesData, 
          FeaturesCategoryList, 
          isRoleFeatureUpdated, 
          roleName, 
          isFormValidated,
          isBrandSelected, 
          success_modal, 
          error_modal,
          viewBrandsList, featureChkBoxDisabled } = this.state;

    let { match } = this.props;
    const { params }  = match;
    const viewTitle = params && params.roleId ? formatMessage({ id: 'KC1082' }) : formatMessage({ id: "KC0586"});
    let featureList = this.onFilterFeatureList();

    if(_.isArray(featureList) && featureList.length === 0 ) {
      featureList = [...allFeaturesData];
    }
    
    corborateRolesDropDownSource = _.orderBy(corborateRolesDropDownSource, [corborateRolesDropDownSource => corborateRolesDropDownSource.role_name && corborateRolesDropDownSource.role_name.toString().toLowerCase()]);

    return (
      <div id="adminWrapper">
        <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
          <div className="loadingProgressIn"></div>
        </div>
        <UserManagementHeader  headerName="Role Management" headerNameStringID="KC1764" activeClass="UserManagementNav" /> 
        <div id="page-content-wrapper">
          <div className="userManagementWrapper">
            <div className="addUserHeader">
              <ul>
                <li>
                <Link to="/userManagementRoles" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link> 
					       </li>
                <li>
                  { viewTitle }
					      </li>
                <li>
                  <Link id="btnnext" to="#" className={isFormValidated && isBrandSelected ? "btn-default-text activeState" : "btn-default-text disabled"} onClick={() => this.addOrUpdateRole()} title={formatMessage({ id: "KC0056"})}><FormattedMessage id={'KC0056'} /></Link>
                </li>
              </ul>
            </div>
          
          <div className="pageScrollbar">
          <div className="chooseUserFormWrapper">
            <div className="chooseUserForm">
              <ul>
                <li>
                  <div className="userNameDP">
                    <span className="userDPImg">
                      <img src={require("../../../images/icons/neutral.svg").default} />
                      {/* <a href="javascript:void(0)" className="ChangePicture">Change Picture</a> */}
                    </span> <input type="text" name="roleName" className="form-control" placeholder={formatMessage({ id: "KC0619"})} value = { roleName }  onChange = {(e) => this.onTextBoxChange(e)}/>
                  </div>
                  <div className="errorMsg">{this.state.errors.roleName? <FormattedMessage id={this.state.errors.roleName} />: ''}</div> 
                </li>
              </ul>
            </div>
            
            <BrandList 
            brandList = {viewBrandsList}
            isBrandEditable = { true }
            isAllBrandSelectionChange = {(e) => this.isAllBrandSelectionChange(e)} 
            isBrandSelectationChange = {(e, item) => this.isBrandSelectationChange(e, item)}
            />

            <form className="exitingUserRolesSelection" style={{ display: corborateRolesDropDownSource.length > 0 ? "block" : " none" }}>
								<select className="form-control exitingRolesSelect" onChange={(e) => this.selectBrand(e)}>
										<option value="">{formatMessage({ id: "KC0621"})}</option>
										{corborateRolesDropDownSource.map((item, i) =>
									  	<option key={i} value={item.Id} selected={item.isSelected}>{item.role_name}</option>
										)}
								</select>
						</form>

            {
              FeaturesCategoryList.map((item,i) => 
               <FeaturesList key={item}
                isRoleFeatureUpdated = { isRoleFeatureUpdated}
                isRoleEditable ={true}
                onAllCheckBoxChange = {(e, fg) => this.onHandleAllCheckBoxChange(e, fg)} 
                onCheckBoxChange = {(e, item) => this.onHandleCheckBoxChange(e, item)}
                featureTitle={item.title}
                featureTitleStringID={item.stringID}
								roleFeaturesList={roleFeaturesList}
                allFeaturesData={featureList}
                disabled = {featureChkBoxDisabled} />
              )
            }
        
          </div>
          </div>
          </div>
          <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={()=>this.successCloseModel()}/>
          <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.errorEloseModel()}/>
        </div>
      </div>
    );
  }
}

export default injectIntl(UserRole)
