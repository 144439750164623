import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import { UserProfile } from '../../service/api';
import http from '../../service/httpService';
import qs from 'query-string';
import fConsts from '../../utils/featureConstants';
import authService from '../../service/authService';
import { BRAND_ID } from '../../utils/appConstants';  
import $ from 'jquery';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { FormattedMessage, injectIntl } from 'react-intl';

var index=1;
class AssignedUnitGroups extends Component {

	constructor(props) {
		super(props);
		this.toggleHidden = this.toggleHidden.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.getOrgnanizationView=this.getOrgnanizationView.bind(this);	
		this.showLinks = this.showLinks.bind(this);	
		this.state = {
			isHidden: false,
			loaderIconVisible: false,
			findTreeLevel:"",
			userData:"",
		};
	}

	toggleHidden(event) {
		event.preventDefault();

		if (!this.state.isHidden) {
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);

		}
		this.setState({
			isHidden: !this.state.isHidden
		})
	}

	handleOutsideClick(event) {
		// ignore clicks on the component itself             
		if (this.pop) {
			//const domNode = ReactDOM.findDOMNode(this.pop)
			if (this.pop.contains(event.target)) {
				return;
			}
			this.toggleHidden(event);
		}
	}

	getUserProfileInformation = async () => {		
		let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		this.setState({loaderIconVisible: true,})

		const values = qs.parse(this.props.location.search);
        if (values.user !== undefined)
        {
			loggedinUser = values.user;
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			loggedinUser = setEmailValue;				
			this.setState({ queryString: values.user });
		}

		http.get(UserProfile.getUserProfileDetails,
			{headers: {
				'customer_id': localStorage.getItem("customerId"),
				'email': loggedinUser,
				'Content-Type': 'application/json',
				'base_country': localStorage.getItem("basecountry"),
				'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
			},data:{},
		}).then(response => {
		this.setState({
				userData: response.data,		
			}, () => {
				this.getCustGroupID();				
			});

		}).catch(err => {
			console.log('catch exception occured');
			this.setState({loaderIconVisible: false,})
		});
	}

	getCustGroupID = () => {
		let custGroupIDList = "";
		var setcustGroupIDList="";
		let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;

		const values = qs.parse(this.props.location.search);
        if (values.user !== undefined)
        {
			loggedinUser = values.user;
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			loggedinUser = setEmailValue;				
			this.setState({ queryString: values.user });
		}		

		http({
			method: "GET",			
			url: `${UserProfile.get_Cust_GroupID}`,	
			headers: {			
				'customer_id': localStorage.getItem("customerId"),
				'email': loggedinUser,	
				'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
			},data:{},
		}).then(response => {
			custGroupIDList=response.data.data;
			custGroupIDList.forEach(p => {
				setcustGroupIDList?setcustGroupIDList=setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList=p.customer_group_id			
			});

			this.setState({				
				linkList: response.data,				
			})
			
			if  (setcustGroupIDList !=="")
			{
				this.getOrgnanizationView(setcustGroupIDList);
			}			
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({
				linkList: [],	
				loaderIconVisible: false,			 
			})
		});
	}

	processOrgnanizationJSON(orgJSON) {
		let findLeveltoShow = orgJSON.start_level_name
		if (findLeveltoShow==="level1")
		{
			findLeveltoShow = orgJSON.level1[0]
		}
		else if (findLeveltoShow==="level2")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0]
		}
		else if (findLeveltoShow==="level3")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0]
		}
		else if (findLeveltoShow==="level4")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0].level4[0]
		}
		else if (findLeveltoShow==="level5")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0].level4[0].level5[0]
		}
		findLeveltoShow = orgJSON.level1[0]
        this.setState({
			linkList: orgJSON,
			findTreeLevel: findLeveltoShow, 
			loaderIconVisible: false,				 
		})
	}

	getOrgnanizationView = async(values) => {		
		let brandIDs;
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		if (this.state.userData && this.state.userData.brandIds ){
			brandIDs =this.state.userData.brandIds;
		}
		await http({
			method: "GET",			
			url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,	
			headers: {				
				"Cgids":values,
				'Content-Type': 'application/json',	
				'brandids': brandIDs.length>0 ? brandIDs : BRAND_ID,
				'useremail': loggedinUser,
				'basecountry': localStorage.getItem('basecountry')
			},data:{},
		}).then(async response => {
			if (response.data.fetchFromS3) {
				await $.getJSON(response.data.presignedS3URL, (data) => {
					this.processOrgnanizationJSON(data);
				});
			}
			else {
				this.processOrgnanizationJSON(response.data);
			}
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({
				linkList: [],	
				loaderIconVisible: false,			 
			})
		});
	}

	showLinks(linkList) {
		if (!linkList) return null;
		if (linkList) {
			index++;									
			return <React.Fragment>
					{linkList.nextLevelAccess ? <div class="assignedUnitGrpsTable"> 
						<ul class="tBody"> 
							<li><a role="button" data-toggle="collapse" data-parent="#agedUGrpsAccordion" href={`#agedUGrpsAcc` + index} aria-expanded="false" aria-controls="agedUGrpsAcc23333" class="btn collapseExpand">&nbsp;</a> {linkList.group_name}</li>
							<li>{linkList.child_count}</li>
						</ul>
					</div> : ""}
					{/* <!-- Header 02 Content --> */}
					
					<div id={`agedUGrpsAcc` + index} class="panel-collapse show collapse" role="tabpanel" aria-labelledby="agedUGrpsAccH2">
						<div class="panel-body assignedUnitGrpsTable">
							{linkList["level" + parseInt(linkList.level_id + 1)] && linkList["level" + parseInt(linkList.level_id + 1)].map((item, i) =>
							 	<div className="treeSecondNode">
									{this.showLinks(item)}	
								</div>		 
							)}

							{linkList["units"] && linkList["units"].map((item, i) =>
								<ul class="tBody">
									<li class="assignedUnitGrpsTableUnitLi">{item.UNITID}</li>
									<li>&nbsp;</li>									
								</ul>
							)}
						</div>						
					</div>  
				</React.Fragment>
		}

	}

	
	componentDidMount= async () => {
		await this.getUserProfileInformation();
		//this.getCustGroupID();
		document.body.style.backgroundColor = "#F2F2F2";
	}

	componentWillMount= async () => {
		await this.getUserProfileInformation();
        //this.getCustGroupID()
    } 

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e.trackTitle}`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let {loaderIconVisible, queryString}= this.state;
		let userNotificationAuthFeature = authService.getFeature("/notificationManagement");
        let notificationLink="/NotificationManagementView";
        
		if (queryString !== undefined )
		{
			notificationLink="/NotificationManagementView?user="+`${queryString}` ;			 
		}
		else
		{
			notificationLink ="/NotificationManagementView";
		}

		let userNotificationFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let IsNotificationActive = false;
		IsNotificationActive =  userNotificationFeature  && userNotificationFeature.feature_active;
		IsNotificationActive = queryString ? true : IsNotificationActive;
        
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>

					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={queryString !== undefined ? "/myinformation?user=" + `${queryString}` : "/myinformation"} title={formatMessage({id: 'KC1916'})} onClick={(e) => {e.trackTitle="My Information";this.trackEvent(e)}} ><FormattedMessage id='KC1916' /></Link>
										<Link to={queryString !== undefined ? "/assignedunitgroups?user="+`${queryString}` : "/assignedunitgroups"} title={formatMessage({id: 'KC1917'})} class="active"><FormattedMessage id='KC1917' /></Link>
										{ IsNotificationActive && <Link to={notificationLink} title={formatMessage({id:'KC0109'})} onClick={(e) => {e.trackTitle="Notification Management";this.trackEvent(e)}} ><FormattedMessage id='KC0109' /></Link>}
										{/* <Link to="/mySubscriptionView" title="Subscription Management">Subscription Management</Link> */}
									</li>
								</ul>
							</div>
							<div class="assignedUnitGroupsTableDiv">
								<div class="assignedUnitGrpsTableOuter">
									<div class="panel panel-default" id="agedUGrpsAccordion" role="tablist" aria-multiselectable="true">										
										{this.state.linkList && this.state.linkList.level1 && this.showLinks(this.state.findTreeLevel)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage={queryString !== undefined  ? "User List" : "User Profile"} activeClass="UserProfileNav" />
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignedUnitGroups));
