import React, { Component } from 'react';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelList } from '../../../../../controls/modelList';
import * as moment from 'moment';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
require('jquery-mousewheel')

let startDate = moment(Date.now()).format('MMMM DD, yyyy') + "T00:00:00";
let endDate = moment(Date.now()).format('MMMM DD, yyyy') + "T23:59:59";

class FrymasterMenuLimitedOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            timeOffer: 'Scheduled',
            limitedTimeOffer: {
                'Scheduled': [],
                'Unscheduled': []
            }
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);

            let libraryDetails = localStorage.getItem("libraryDetails");
            let libraryDetailsObj = JSON.parse(libraryDetails);

            let limitedTimeOfferValue = {
                'Scheduled': [],
                'Unscheduled': []
            };

            if (libraryDetailsObj && libraryDetailsObj.limitedTimeOffer) {
               
                libraryDetailsObj.limitedTimeOffer.scheduled.map((value,index) => {
                    limitedTimeOfferValue['Scheduled'].push({
                        categoryId: value.id,
                        categoryName: this.getCategoryName(value.id, categoryDetailsObj),
                        mode: value.mode === "Off" ? "true" : "false",
                        startDate: value.startDate,
                        endDate: value.endDate
                    })
                })
                libraryDetailsObj.limitedTimeOffer.unscheduled.map((value) => {
                    limitedTimeOfferValue['Unscheduled'].push({
                        categoryId: value.id,
                        categoryName: this.getCategoryName(value.id, categoryDetailsObj),
                        mode: value.mode === "Off" ? "true" : "false",
                        startDate: value.startDate,
                        endDate: value.endDate
                    })
                })

            } else {
                categoryDetailsObj.map((value) => {
                    limitedTimeOfferValue['Scheduled'].push({
                        categoryId: value.ID,
                        categoryName: value.categoryName,
                        mode: "false",
                        startDate: '',
                        endDate: ''
                    })
                    limitedTimeOfferValue['Unscheduled'].push({
                        categoryId: value.ID,
                        categoryName: value.categoryName,
                        mode: "false",
                        startDate: '',
                        endDate: ''
                    })
                })

            }
            this.setState({
                limitedTimeOffer:limitedTimeOfferValue,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });


        } catch (error) {
            //Error Goes Here
        }
    }

    getCategoryName = (id, categoryDetailsObj) => {
        const filterdCategory = categoryDetailsObj.filter((value) => value.ID === id);
        return filterdCategory.length ? filterdCategory[0].categoryName : ''
    }

    onConfirmButtonClick = (page, item = '') => {
        let { limitedTimeOffer, timeOffer } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryDetails");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        if (!ObjSettingsFile.limitedTimeOffer) {
            ObjSettingsFile.limitedTimeOffer = {}
        }

        let limitedTimeOfferObjSetting = {
            'scheduled': [],
            'unscheduled': []
        };
        limitedTimeOffer['Scheduled'].map((i,index) => {
            limitedTimeOfferObjSetting['scheduled'].push({
                "id": i.categoryId,
                "mode": i.mode === "true" ? "Off" : "On",
                "startDate": ObjSettingsFile.limitedTimeOffer ? ObjSettingsFile.limitedTimeOffer['scheduled'] && ObjSettingsFile.limitedTimeOffer['scheduled'].length  ? ObjSettingsFile.limitedTimeOffer['scheduled'][index]['startDate'] || startDate : startDate : startDate,
                "endDate": ObjSettingsFile.limitedTimeOffer ? ObjSettingsFile.limitedTimeOffer['scheduled'] && ObjSettingsFile.limitedTimeOffer['scheduled'].length ? ObjSettingsFile.limitedTimeOffer['scheduled'][index]['endDate'] || endDate : endDate : endDate
            })
            limitedTimeOfferObjSetting['unscheduled'].push({
                "id": i.categoryId,
                "mode": i.mode === "true" ? "Off" : "On",
                "startDate": ObjSettingsFile.limitedTimeOffer ? ObjSettingsFile.limitedTimeOffer['unscheduled'] && ObjSettingsFile.limitedTimeOffer['unscheduled'].length ? ObjSettingsFile.limitedTimeOffer['unscheduled'][index]['startDate'] || startDate : startDate : startDate,
                "endDate": ObjSettingsFile.limitedTimeOffer ? ObjSettingsFile.limitedTimeOffer['unscheduled'] && ObjSettingsFile.limitedTimeOffer['unscheduled'].length ? ObjSettingsFile.limitedTimeOffer['unscheduled'][index]['endDate'] || endDate : endDate : endDate
            })
        })

        ObjSettingsFile.limitedTimeOffer = limitedTimeOfferObjSetting;

        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryDetails", strSettingsFile);

        if (page === "Time") {
            this.props.history.push('/frymasterMenuLimitedOfferTime', { item, timeOffer });
        }
        else {
            this.props.history.push(`/frymasterMenuEditor`);
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterMenuEditor`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    updateValues = (value, key, id) => {
        const { limitedTimeOffer, timeOffer } = this.state;
        let updateValue = limitedTimeOffer;
        const findIndex = limitedTimeOffer[timeOffer].findIndex(i => i.categoryId === id);
        updateValue[timeOffer][findIndex][key] = value;

        this.setState({ limitedTimeOffer: updateValue })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { timeOffer, limitedTimeOffer } = this.state;
        return (
            <React.Fragment>
                {/* FrymasterMenu Auto Change Header Page */}
                <UserManagementHeader headerName="Food & Beverage Management" headerNameStringID="KC1974" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelList brandid={brandid} isModelEditable={true} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Menu Auto Change Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt=""/>
                            </div>

                            {/* Frymaster Menu Auto Change Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1396"/>
                                </div>



                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <div class="reWrapperTab">
                                                    <ul>
                                                        <li>
                                                            <button onClick={() => this.setState({ timeOffer: 'Scheduled' })} title={formatMessage({ id: 'KC0573' })} class={timeOffer === "Scheduled" ? "border0" : "active "}><FormattedMessage id="KC0573"/></button>
                                                            <button onClick={() => this.setState({ timeOffer: 'Unscheduled' })} title={formatMessage({ id: 'KC1408' })} class={timeOffer === "Unscheduled" ? "" : "active border0"}><FormattedMessage id="KC1408"/></button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    {
                                                        limitedTimeOffer[timeOffer].map((item, i) => {
                                                            return (
                                                                <ul key={i} className={limitedTimeOffer[timeOffer].length === i + 1 ? "borderNone" : ""}>
                                                                    <li>{item.categoryName}</li>
                                                                    <li class="arrowBtn">
                                                                        <div class="btn-group-toggle floatLeft" data-toggle="buttons">
                                                                            <span class={item.mode === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span> 
                                                                            <Switch
                                                                                onChange={(e) => this.updateValues(e.toString(), 'mode', item.categoryId)}
                                                                                checked={item.mode === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            /> <span class={item.mode === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                        </div>

                                                                        <button type="button" onClick={() => this.onConfirmButtonClick('Time', item)} class="btn nextButton">&nbsp;</button>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => this.onConfirmButtonClick('Save')} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Menu Auto Change Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuLimitedOffers)
