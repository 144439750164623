import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter} from 'react-router';
import { Link } from "react-router-dom";
import SideBar from '../layoutSection/sidebar';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import LogoutForm from "../logout/logoutForm";
import ErrorMoal from '../modal/error-model';
import auth from '../../service/authService';
import broadCastTokenEvent from '../../events/tokenAuthrizer';
import authHelper from '../../utils/authHelper';
import messageConfig from '../../utils/messageConfig';
import MainLayout from "../../layout/mainLayout";
import GlobalSearchBar from '../../components/globalSearchBar/globalSearchBar';
import $ from 'jquery';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { FormattedMessage, injectIntl } from 'react-intl';

class GlobalHeaderLayout extends Component {

  constructor(props) {
    super(props);
    this.nextPathNav = this.nextPathNav.bind(this);
    this.state = {
      isHidden: false,
    };

  } 

  componentDidMount = () => {
    this.subscription = broadCastTokenEvent.IsReceiveJwtTokenExpired().subscribe(isJwtExpired => {
      if (isJwtExpired)
        this.setState({ IsTokenExpired: isJwtExpired });
    });
  }

  componentWillMount() {
    this.props.getCurrentUnit("currentUnit");
    this.props.getCurrentLocation("currentLocation");
  }

  nextPathNav(path) {
    this.props.history.push("/");
  }

  showLoginForm = (event, isHidden) => {	
    this.setState({
        isHidden: !isHidden
    });
    if(this.state.isHidden){
      $('#backdropElement').click()
    }
    event.preventDefault();  
  }

  hideLoginForm = (event) =>  { 
    event.preventDefault();  
    this.setState({
        isHidden: false
     });         
   }

  errorCloseModel = () => {
    authHelper.deleteLocalSession();
    window.location.href = '/';
  }


  render() {
    const { currentLocation, currentUnit, activeClass , match } = this.props;
    const { formatMessage } = this.props.intl;
    let {path}  = match;
    let unitInformation = currentUnit;
    let location = currentLocation
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    let loggedinUser = loginUser.username;

    let location_ID = location && location.location_id !== null ? location.location_id : "";
    let locationBrand_Logo = location && location.Brand_Logo != null ? location.Brand_Logo : "";
    let locationBrand_Alt = location && location.Brand_Alt != null ? location.Brand_Alt : "";
    let locationBrand_Title = location && location.Brand_Title != null ? location.Brand_Title : "";
    let locationlocation_name = location && location.location_name != null ? location.location_name : "";
    let locationcity = location && location.city != null ? location.city : "";

    let logoutForm = null
    if (this.state.isHidden) {
      logoutForm = (<LogoutForm hideFlag={this.state.isHidden} hideProfileEditBtn={false} editinfo={this.editInformation} nextPath={this.nextPathNav} hideLoginForm ={(e)=> this.hideLoginForm(e)}  />)
    }

    var strCamelValueForBrandType = "";
    if (unitInformation.BRAND_TYPE !== undefined && unitInformation.PRODUCT_ID === 4) {
      strCamelValueForBrandType = unitInformation.BRAND_TYPE
      var splitStr = strCamelValueForBrandType.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        if (splitStr[i].length > 0) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
      }
      strCamelValueForBrandType = splitStr.join(' ');
    }

    if (unitInformation.BRAND_NAME !== undefined) {
      var strCamelValueForBrandName = unitInformation.BRAND_NAME
      if (strCamelValueForBrandName === "FRYMASTER-UHCTHD") {
				  strCamelValueForBrandName = "Frymaster-UHCTHD";
			}
			else {
        var splitStr = strCamelValueForBrandName.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          if (splitStr[i].length > 0) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
        }
        strCamelValueForBrandName = splitStr.join(' ');
      }
    }

    let IsTokenExpired = !this.state.IsTokenExpired ? auth.IsTokenTimeExipired() : this.state.IsTokenExpired;

    return (
      <React.Fragment>
        <ErrorMoal open={IsTokenExpired ? true : false} message={messageConfig.SessionExpire} stringID={messageConfig.SessionExpire} onCloseModel={() => this.errorCloseModel()} /> 
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt"> 
            <div className="navbar-header">
              <Link className="navbar-brand welbiltRLogo" title={formatMessage({id:"KC0038"})} to="/dashBoardLocations">&nbsp;</Link>
              <Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
            </div>
            <ul className="navbar-nav mr-auto navbarLeft fivePillarsHeader">
              <li className="nav-item">
                {/* <Link className="nav-link" to="#" > */}
                <Link className="nav-link" to={`/dashBoardLocations/specificLocation?groupId=` + location_ID}>
                  <span className="navStoreIcon"><img src={locationBrand_Logo} alt={locationBrand_Alt} title={locationBrand_Title} /></span>
                  <span className="navStoreText"><span title={formatMessage({id: "KC1040"})}>{locationlocation_name}</span> <b><span title={formatMessage({id:"KC0098"})}>{locationcity}</span></b></span>
                </Link>
                {/* </Link> */}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span className="navStoreIcon cursorDefault"><img src={unitInformation.Brand_Logo} alt={unitInformation.Brand_Alt} title={unitInformation.Brand_Title} /></span>
                  <span className="navStoreText cursorDefault"><span title={formatMessage({id:"KC0775"})}>{unitInformation.MODEL_NAME}</span> <b><span title={formatMessage({id:"KC0873"})} >{strCamelValueForBrandName}</span></b></span>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav navbarRight">
                {/* Global Search */}
                {path && < GlobalSearchBar  path ={ path} />} 
            </ul>
            <ul className="navbar-nav navbarLRight timeZoneSNheader">
              <li className="nav-item">
                  <a className="nav-link" href="#" title={formatMessage({id:"KC0104"})}>
                      <span className="deviceSNText" title={formatMessage({ id: 'KC0983' }) +" " + unitInformation.UNITID}><FormattedMessage id="KC0983"/> <b>{unitInformation.UNITID}</b></span>
                      <span className="UTCText" title={formatMessage({ id: 'KC0256' })+":"+" " + unitInformation.TimeZone_Display}><FormattedMessage id="KC0256"/>: <b> {unitInformation.TimeZone_Display} </b></span> 
                  </a>
              </li>
            </ul>
            <ul className="navbar-nav mr-auto navbarRight">
              <li className="nav-item">
                <a className="nav-link navFilter" style={{ display: 'none' }} title={formatMessage({id: "KC0996"})} href="#">&nbsp;</a>
              </li>
              <li className="nav-item">
                <a className="nav-link navSearch" style={{ display: 'none' }} title={formatMessage({id:"KC0037"})} href="#">&nbsp;</a>
              </li>
              <li className="nav-item">
                <a className="nav-link navAlarm" style={{ display: 'none' }} title={formatMessage({id:"KC0997"})} href="#">&nbsp;</a>
              </li>
              <li className="nav-item">
                <LanguagePreferenceCommon />
              </li>
              <li className={this.state.isHidden ? "nav-item activeNavUser": "nav-item"}>
                {/* <a className="nav-link navUser" title="User Name" href="#">&nbsp;</a> */}
                <Link style={{ cursor: 'pointer' }} to='' className={this.state.isHidden ? "nav-link navUser active":"nav-link navUser"} onClick={(e) => this.showLoginForm(e, this.state.isHidden)} title={loggedinUser}><span id="spannavNotifiStatus" className=""></span>&nbsp;</Link>
              </li>
            </ul>
        </nav>
        <div className="sideNavToggleTransparent"></div>
        <MainLayout flagstate={this.state.isHidden} />
        <div ref={node => { this.pop = node }}>
          {logoutForm}
        </div>
        <SideBar activeClass={activeClass} />
      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    currentLocation: state.entityReducer.currentLocation,
    currentUnit: state.entityReducer.currentUnit
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCurrentLocation, getCurrentUnit }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(GlobalHeaderLayout)));
