import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { unitAPI } from '../../service/api';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import fConsts from '../../utils/featureConstants';
import authService from '../../service/authService';
import { BrandList } from '../controls/brandList';
import http from '../../service/httpService';
import qs from 'query-string';
import { PayButton } from '../controls/PayButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class mySubscriptionView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isValidate: false,
			loaderIconVisible: false,
			linkList: {},
			findTreeLevel: "",
			viewBrandsList: [],
			amount: 0,
			isPayVisible: false
		};
	}

	/**
   * Select or Un-Select all brands
   */
	isAllBrandSelectionChange = (e) => {
		const {viewBrandsList} = this.state;
		let brandsList = [...viewBrandsList];
		brandsList.forEach(b => {
		b.isChecked = e.target.checked;
		});
		let amount = 0;
		if (e.target.checked) {
			amount = 200 * viewBrandsList.length;
		}
		
		this.setState({
			viewBrandsList: brandsList,
			amount: amount,
			isPayVisible: amount ? true : false
		});
	  }

	  /**
	 * Select or Un-Select brands
	 */
	isBrandSelectationChange = (e, item) => {
		const {viewBrandsList} = this.state;
		let brandsList = [...viewBrandsList];
		let index = brandsList.indexOf(item);
		brandsList[index].isChecked = e.target.checked;
		let amount;
		if (e.target.checked) {
			amount = this.state.amount + 200;
		}
		else {
			amount = this.state.amount ? this.state.amount - 200 : 0;
		}
		this.setState({ 
			amount: amount,
			isPayVisible: amount ? true : false
		});
	}

	loadingPopupShowHide = (isActive) => {
		this.setState({
			loaderIconVisible: isActive
		});
	}

	setDefaultBrandsAndFeatures = async() => {
		this.loadingPopupShowHide(true);
		await http.get(unitAPI.getBrands, {
			data: {}
			}).then(response => {
				let brands = response.data;
				this.setState({
					viewBrandsList: brands ? brands.filter(b => b.BRAND_NAME !== 'Sample1') : []
				});
				this.loadingPopupShowHide(false);
			}).catch(err => {
				this.loadingPopupShowHide(false);
			});
	}

	componentDidMount = async () => {
		document.body.style.backgroundColor = "#F2F2F2";
		const values = qs.parse(this.props.location.search); 
		if (values.user !== undefined)
        {
			this.setState({ queryString: values.user });
		}
		this.setDefaultBrandsAndFeatures();
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { loaderIconVisible, queryString, viewBrandsList, amount, isPayVisible } = this.state;
		let notificationLink = "/NotificationManagementView";

		if (queryString !== undefined) {
			notificationLink = "/NotificationManagementView?user=" + `${queryString}`;
		}
		else {
			notificationLink = "/NotificationManagementView";
		}

		let userNotificationFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let IsNotificationActive = false;
		IsNotificationActive = userNotificationFeature && userNotificationFeature.feature_active;
		IsNotificationActive = queryString ? true : IsNotificationActive;

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>

					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={queryString !== undefined ? "/myinformation?user=" + `${queryString}` : "/myinformation"} title={formatMessage({id: 'KC1916'})} ><FormattedMessage id='KC1916' /></Link>
										<Link to={queryString !== undefined ? "/assignedunitgroups?user=" + `${queryString}` : "/assignedunitgroups"} title={formatMessage({id: 'KC1917'})}><FormattedMessage id='KC1917' /></Link>
										{IsNotificationActive && <Link to={notificationLink} title={formatMessage({id:'KC0109'})} ><FormattedMessage id='KC0109' /></Link>}
										<Link to="/mySubscriptionView" title={formatMessage({id: 'KC1668'})} class="active"><FormattedMessage id='KC1668' /></Link>
									</li>
								</ul>
							</div>
							<div class="assigntoBrandsForm">
								<div class="assigntoBrands">
									<BrandList 
										brandList = { viewBrandsList }
										isBrandEditable = { true }
										isAllBrandSelectionChange = {(e) => this.isAllBrandSelectionChange(e)} 
										isBrandSelectationChange = {(e, item) => this.isBrandSelectationChange(e, item)}
										header={'KC1670'}
									/>
								</div>
								<div className="subscribeButton">
									<div><FormattedMessage id='KC1669' /> $ {(amount/100).toFixed(2)}</div>
									{isPayVisible && <PayButton amount={amount} billingAddress={true} />}
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage={queryString !== undefined ? "User List" : "User Profile"} activeClass="UserProfileNav" />
			</React.Fragment>
		)
	}
};


function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(mySubscriptionView));
