import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import UnitDashboard from '../components/unitDashboard/unitDashboard';
import CsvExpoRedirect from '../components/unitDashboard/csvExpoRedirect'; 
import MediaLibrary from '../components/menuManagement/mediaLibrary';
import UnitQualityPerformance from '../components/qualityManagement/UnitQualityPerformance';
import HistoricErrors from '../components/serviceManagement/historicErrors';
import Production from '../components/reportManagement/production';
import ResourceUse from '../components/reportManagement/resourceUse';
import Utilization from '../components/reportManagement/utilization';
import Clean from '../components/reportManagement/clean';
import Filtration from '../components/reportManagement/filtration';
import OilUsage from '../components/reportManagement/oilUsage';
import TPMValue from '../components/reportManagement/tpmValue';
import CycleRates from '../components/reportManagement/cycleRates';
import UnitManagement from '../components/unitManagement/unitManagement';
import TimeLine from '../components/serviceManagement/timeLine';
import DashBoardLocations from '../components/allLocations/dashBoardLocations';
import LocationsView from '../components/listView/locationsView';
import UnitsView from '../components/listView/unitsView';
import SpecificLocationUnitView from '../components/listView/specificunitsView';
import LocationsMap from '../components/mapView/locationsMap';
import DashboardUnits from '../components/allLocations/dashBoardUnits';
import UnitsMap from '../components/mapView/unitsMap';
import SiteTree from '../components/mapView/siteTree';
import SpecificLocation from '../components/allLocations/specificLocation';
import ErrorDescription from '../components/serviceManagement/errorDescription';
import ServiceManagement from '../components/serviceManagement/serviceManagement';
import TroubleShooting from '../components/serviceManagement/troubleShooting';
import ServiceNotes from '../components/serviceManagement/serviceNotes';
import ErrorReport from '../components/serviceManagement/errorReport';
import MainMenu from '../components/navigation/mainMenu';
import UserManagementList from '../components/navigation/userManagement/userManagementList';
import AllCurrentErrors from '../components/navigation/errorCenter/allcurrenterrors';
import AllHistoricErrors from '../components/navigation/errorCenter/allhistoricerrors';
import UserManagementRoles from '../components/navigation/userManagement/userManagementRoles';
import AddUser from '../components/navigation/userManagement/addUser';
import EditUser from '../components/navigation/userManagement/editUser';
import UnitRegistration from '../components/navigation/unitRegisterAndAssignment/unitRegistration';
import BulkUnitOnboarding from '../components/navigation/unitRegisterAndAssignment/bulkUnitOnboarding';
import BulkUnitOnboardingConfirmation from '../components/navigation/unitRegisterAndAssignment/bulkUnitOnboardingConfirmation';
import AssignUnitToTopology from '../components/navigation/unitRegisterAndAssignment/assignUnitToTopology';
import AddUnit from '../components/navigation/unitRegisterAndAssignment/addUnit';
import LogoutForm from '../components/logout/logoutForm';
import ChooseUserRole from '../components/navigation/userManagement/chooseUserRole';
import UserRole from '../components/navigation/userManagement/userRole';
import AssignUserToTopology from '../components/navigation/userManagement/assignUserToTopology';
import FilterBy from '../components/listView/filter/filterBy';
import FilterByCountry from '../components/listView/filter/filterByCountry';
import FilterByRegion from '../components/listView/filter/filterByRegion';
import FilterByLocation from '../components/listView/filter/filterByLocation';
import FilterByCustomer from '../components/listView/filter/filterByCustomer';
import NewLogin from '../components/login/NewUserLogin';
import Login from '../components/login/Login';
import ForgotPassword from '../components/login/ForgotPassword';
import CreateAccount from '../components/login/createAccount';
import LoginChangeUser from '../components/login/loginchangeuser';
import ForgetPassword from '../components/loginFail/forgetPassword';
import resetpassword from '../components/loginFail/resetpassword';
import FilterByBrand from '../components/listView/filter/filterByBrand';
import FilterByModel from '../components/listView/filter/filterByModel';
import OrganizationManagement from '../components/navigation/organizationManagement/organizationManagement';
import SignUp from '../components/login/landingPage';
import menuBrands from '../components/navigation/menus/menuBrands';
import menuUpdateStatus from '../components/navigation/menus/menuUpdateStatus';
import MenuUploadStatus from '../components/navigation/menus/menuUploadStatus';
import MenuApps from '../components/navigation/menus/menuApps';
import MenuConvolink from '../components/navigation/menus/menuConvolink';
import menuScheduleUpdateNow from '../components/navigation/menus/menuScheduleUpdateNow';
import menuLibrary from '../components/navigation/menus/menuLibrary';
import MenuAddgroupsList from '../components/navigation/menus/menuAddgroupsList';
import MenuTransfergroupsList from '../components/navigation/menus/menuTransfergroupsList';
import MenuAssigngroupTopology from '../components/navigation/menus/menuAssigngroupTopology';
import MenuTransferUnits from '../components/navigation/menus/menuTransferUnits';
import MenuSmartGroupsList from '../components/navigation/menus/menuSmartGroupsList';
import addNewMenuLibrary from '../components/navigation/menus/addNewMenuLibrary';
import menuAssignUpdateList from '../components/navigation/menus/menuAssignUpdateList';
import mediaLibraryList from '../components/navigation/media/mediaLibraryList';
import mediaPreparation from '../components/navigation/media/mediaPreparation';
import mediaScreenSaver from '../components/navigation/media/mediaScreenSaver';
import mediaCompilation from '../components/navigation/media/mediaCompilation';
import mediaPackage from '../components/navigation/media/mediaPackage';
import mediaUploadHistory from '../components/navigation/media/mediaUploadHistory';
import assignMediaList from '../components/navigation/media/assignMediaList';
import assignMedia from '../components/navigation/media/assignMedia';
import assignMediaUpdateList from '../components/navigation/media/assignMediaUpdateList';
import AssignmediaTopology from '../components/navigation/media/assignmediatopology';
import AssignmedialistView from '../components/navigation/media/assignmedialistView';
import AssignmediaConform from '../components/navigation/media/assignmediaconform';
import MediaImageLibrary from '../components/navigation/media/mediaImageLibrary';
import MediaAudioLibrary from '../components/navigation/media/mediaAudioLibrary';
import MediaTransferUnits from '../components/navigation/media/mediaTransferUnits';
import OrganizationChartView from '../components/navigation/media/organizationChartView';
import OrganizationChartListView from '../components/navigation/media/organizationChartListView';
import MediaAssignSmartGroupsList from '../components/navigation/media/mediaAssignSmartGroupsList';
import myInformation from '../components/userInformation/myInformation';
import myInformationEdit from '../components/userInformation/myInformationEdit';
import assignedUnitGroups from '../components/userInformation/assignedUnitGroups';
import notificationManagement from '../components/userInformation/notificationManagement';
import notificationManagementView from '../components/userInformation/notificationManagementView';
import assignedUnitGroupsEdit from '../components/userInformation/assignedUnitGroupsEdit';
import SoftwareAvailableUpdates from '../components/navigation/software/softwareAvailableUpdate';
import BakingPlan from '../components/navigation/software/bakingPlan';
import SoftwareUpdatesStatus from '../components/navigation/software/softwareUpdateStatus';
import SoftwareStatus from '../components/navigation/software/softwareStatus';
import softwareSmartGroupStatus from '../components/navigation/software/softwareSmartGroupStatus';
import SoftwareLibraryList from '../components/navigation/software/softwareLibraryList';
import SoftwareLibraryUpdate from '../components/navigation/software/softwareLibraryUpdate';
import SoftwareSmartGroupsList from '../components/navigation/software/softwareSmartGroupsList';
import SoftwareTransferSmartgroupsList from '../components/navigation/software/softwareTransferSmartgroupsList';
import SoftwareAssignSmartGroupsList from '../components/navigation/software/softwareAssignSmartGroupsList';
import SoftwareAssignmedialistView from '../components/navigation/software/softwareAssignmedialistView';
import SoftwareAssignlistView from '../components/navigation/software/softwareAssignlistView';
import SoftwareAssigngroupTopology from '../components/navigation/software/softwareAssigngroupTopology';
import SoftwareTransferUnits from '../components/navigation/software/softwareTransferUnits';
import AssignUpdate from '../components/navigation/software/assignUpdate';
import AssignUpdateList from '../components/navigation/software/assignUpdateList';
import softwareScheduleUpdate from '../components/navigation/software/softwareScheduleUpdate';
import HACCPLogLibrary from '../components/navigation/haccpManagement/haccpLogLibrary';
import HACCPRetrieveStatus from '../components/navigation/haccpManagement/haccpRetrieveStatus';
import HACCPAssignUpdateList from '../components/navigation/haccpManagement/haccpAssignUpdateList';
import HACCPBrands from '../components/navigation/haccpManagement/haccpBrands';
import reportList from '../components/navigation/reports/reportList';
import scheduledReportList from '../components/navigation/reports/scheduledReportList';
import brandReportList from '../components/navigation/reports/brandReportList';
import newReport from '../components/navigation/reports/newReport';
import newReportWithOptions from '../components/navigation/reports/newReportWithOptions';
import reportAssignUnitToTopology from '../components/navigation/reports/assignReportUnitToTopology';
import reportAssignUpdateList from '../components/navigation/reports/reportAssignUpdateList';
import FryerReport from '../components/navigation/reports/fryerReport';
import reportAssignStoreList from '../components/navigation/reports/reportAssignStoreList';
import GarlandReport from '../components/navigation/reports/garlandReport';
import c2cSwaggerListView from '../components/navigation/c2cApiDeveloper/c2cSwaggerListView';
import SwaggerInterface from '../components/navigation/c2cApiDeveloper/SwaggerInterface';
import SwaggerUIComponent from '../components/navigation/c2cApiDeveloper/SwaggerUIComponent';
import ManagePassword from '../components/userInformation/managePassword';
import underConstruction from '../components/login/underConstruction';
import UnAuthorized from '../components/login/unAuthorized';
import ProtectedRoute from './productedRoute';
import mySubscriptionEdit from '../components/userInformation/mySubscriptionEdit';
import mySubscriptionView from '../components/userInformation/mySubscriptionView';
import LibraryList from '../components/navigation/menus/libraryList';
import LegacyLibraryList from '../components/navigation/menus/legacyReadyRecipe/legacyLibraryList';
import AssignLegacyCookbookToTopology from '../components/navigation/menus/legacyReadyRecipe/assignLegacyCookbookToTopology';
import AssignLegacyRecipeToTopology from '../components/navigation/menus/legacyReadyRecipe/assignLegacyRecipeToTopology';
import UploadLegacyCookbookToTopology from '../components/navigation/menus/legacyReadyRecipe/uploadLegacyCookbookToTopology';
import LibraryScheduleUpdateNow from '../components/navigation/menus/libraryScheduleUpdateNow';
import LegacyScheduleUpdateNow from '../components/navigation/menus/legacyReadyRecipe/legacyScheduleUpdateNow';
import LibrarySelectDevice from '../components/navigation/menus/librarySelectDevice';
import LegacySelectDevice from '../components/navigation/menus/legacyReadyRecipe/legacySelectDevice';
import UploadLegacySelectDevice from '../components/navigation/menus/legacyReadyRecipe/uploadLegacySelectDevice';
import RecipeCreation from '../components/navigation/menus/Merrychef/Recipe/recipeCreation';
import RecipeEditor from '../components/navigation/menus/Merrychef/Recipe/recipeEditor';
import StageInformation from '../components/navigation/menus/Merrychef/Recipe/stageInformation';
import RecipeCreationSave from '../components/navigation/menus/Merrychef/Recipe/recipeCreationSave';
import AssignLibraryToTopology from '../components/navigation/menus/Merrychef/Recipe/assignLibraryToTopology';
import LibraryAssignUpdateList from '../components/navigation/menus/libraryAssignUpdateList';
import FileRetrievalList from '../components/navigation/menus/fileRetrievalList';
import LegacyAssignUpdateList from '../components/navigation/menus/legacyReadyRecipe/legacyAssignUpdateList';
import CookbookEditor from '../components/navigation/menus/Merrychef/Cookbook/cookbookEditor';
import CookbookAddCategory from '../components/navigation/menus/Merrychef/Cookbook/cookbookAddCategory';
import CookbookRecipeList from '../components/navigation/menus/Merrychef/Cookbook/cookbookRecipeList';
import CookbookAddRecipes from '../components/navigation/menus/Merrychef/Cookbook/cookbookAddRecipes';
import CookbookPressAndGo from '../components/navigation/menus/Merrychef/Cookbook/cookbookPressAndGo';
import CookbookParameters from '../components/navigation/menus/Merrychef/Cookbook/cookbookParameters';
import MenuRCPressandGo from '../components/navigation/menus/Merrychef/Cookbook/menurcPressAndGo';
import MenuAllRecipes from '../components/navigation/menus/Merrychef/Cookbook/menuAllRecipes';
import CookbookAllRecipes from '../components/navigation/menus/Merrychef/Cookbook/cookbookAllRecipes';
import CookbookFavouritesList from '../components/navigation/menus/Merrychef/Cookbook/cookbookFavouritesList';
import CookbookSubCategoryList from '../components/navigation/menus/Merrychef/Cookbook/cookbookSubCategoryList';
import CookbookAddSubCategory from '../components/navigation/menus/Merrychef/Cookbook/cookbookAddSubCategory';
import AssignCookbookToTopology from '../components/navigation/menus/Merrychef/Cookbook/assignCookbookToTopology';
import SettingsEditor from '../components/navigation/menus/Merrychef/Settings/settingsEditor';
import SettingsModes from '../components/navigation/menus/Merrychef/Settings/settingsModes';
import PreHeat from '../components/navigation/menus/Merrychef/Settings/preHeat';
import TemperatureBand from '../components/navigation/menus/Merrychef/Settings/temperatureBand';
import TimerForTemperature from '../components/navigation/menus/Merrychef/Settings/timerForTemperature';
import Sound from '../components/navigation/menus/Merrychef/Settings/sound';
import Units from '../components/navigation/menus/Merrychef/Settings/units';
import Password from '../components/navigation/menus/Merrychef/Settings/password';
import ManagerPassword from '../components/navigation/menus/Merrychef/Settings/managerPassword';
import ServicePassword from '../components/navigation/menus/Merrychef/Settings/servicePassword';
import Languages from '../components/navigation/menus/Merrychef/Settings/languages';
import Cleaning from '../components/navigation/menus/Merrychef/Settings/cleaning';
import Screensaver from '../components/navigation/menus/Merrychef/Settings/screensaver';
import ChangeScreensaver from '../components/navigation/menus/Merrychef/Settings/changeScreensaver';
import DateAndTime from '../components/navigation/menus/Merrychef/Settings/dateAndTime';
import DateAndTimeSettings from '../components/navigation/menus/Merrychef/Settings/dateAndTimeSettings';
import Timezone from '../components/navigation/menus/Merrychef/Settings/timezone';
import Scheduler from '../components/navigation/menus/Merrychef/Settings/scheduler';
import SchedulerAdd from '../components/navigation/menus/Merrychef/Settings/schedulerAdd';
import AssignSettingsToTopology from '../components/navigation/menus/Merrychef/Settings/assignSettingsToTopology';
import RetrieveLibraryToTopology from '../components/navigation/menus/retrieveLibraryToTopology';
import LibraryAssignTopology from '../components/navigation/menus/libraryAssignTopology';
import LegacyAssignTopology from '../components/navigation/menus/legacyReadyRecipe/legacyAssignTopology';
import RecipeAssignTopology from '../components/navigation/menus/recipeAssignTopology';
import LibraryUploadTopology from '../components/navigation/menus/libraryUploadTopology';
import FrymasterRecipeEditor from '../components/navigation/menus/Frymaster/Recipe/frymasterRecipeEditor';
import FrymasterRecipeCreation from '../components/navigation/menus/Frymaster/Recipe/frymasterRecipeCreation';
import FrymasterRecipeTypeSelection from '../components/navigation/menus/Frymaster/Recipe/frymasterRecipeTypeSelection';
import FrymasterMultiSegment from '../components/navigation/menus/Frymaster/Recipe/frymasterMultiSegment';
import FrymasterActionPrompt from '../components/navigation/menus/Frymaster/Recipe/frymasterActionPrompt';
import FrymasterRecipeSchedule from '../components/navigation/menus/Frymaster/Recipe/frymasterRecipeSchedule';
import FrymasterRecipeReview from '../components/navigation/menus/Frymaster/Recipe/frymasterRecipeReview';
import AssignFrymasterRecipeToTopology from '../components/navigation/menus/Frymaster/Recipe/assignFrymasterRecipeToTopology';
import DisplayProduct from '../components/navigation/productSelection/displayedproducts';
import ProductSelection from '../components/navigation/productSelection/productselection';
import SmartGroupsList from '../components/navigation/smartgroup/smartgroupslist';
import CreateNewSmartGrp from '../components/navigation/smartgroup/createnewsmartgrp';
import EditSmartGrp from '../components/navigation/smartgroup/editsmartgrp';
import PurposesList from '../components/navigation/smartgroup/purposesList';
import SmartGrpTopology from '../components/navigation/smartgroup/smartGrpTopology';
import SmartGrpAssignUpdateList from '../components/navigation/smartgroup/smartGrpAssignUpdateList';
import EditSmartGrpTopology from '../components/navigation/smartgroup/editsmartGrpTopology';
import EditSmartGrpAssignUpdateList from '../components/navigation/smartgroup/editsmartGrpAssignUpdateList';
import FrymasterMenuEditor from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuEditor';
import FrymasterMenuAdd from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuAdd';
import FrymasterMenuList from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuList';
import FrymasterMenuRecipeList from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuRecipeList';
import FrymasterMenuAddRecipes from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuAddRecipes';
import FrymasterMenuShareFrypot from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuShareFrypot';
import FrymasterMenuSelectFrypots from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuSelectFrypots';
import FrymasterMenuAddFinalReview from '../components/navigation/menus/Frymaster/Cookbook/frymasterMenuAddFinalReview';
import AssignFrymasterMenuToTopology from '../components/navigation/menus/Frymaster/Cookbook/assignFrymasterMenuToTopology';
import FrymasterSettings from '../components/navigation/menus/Frymaster/Settings/frymasterSettingsEditor'
import FrymasterGeneralSettings from '../components/navigation/menus/Frymaster/Settings/General/frymasterGeneralSettings'
import FrymasterTimeDate from '../components/navigation/menus/Frymaster/Settings/General/frymasterTimeDate';
import FrymasterLocale from '../components/navigation/menus/Frymaster/Settings/General/frymasterLocale';
import FrymasterScreen from '../components/navigation/menus/Frymaster/Settings/General/frymasterScreen';
import FrymasterSound from '../components/navigation/menus/Frymaster/Settings/General/frymasterSound';
import FrymasterLanguage from '../components/navigation/menus/Frymaster/Settings/General/frymasterLanguage';
import FrymasterTemperature from '../components/navigation/menus/Frymaster/Settings/General/frymasterTemperature';
import FrymasterConf from '../components/navigation/menus/Frymaster/Settings/General/frymasterConf';
import FrymasterLanguageSelection from '../components/navigation/menus/Frymaster/Settings/General/frymasterLanguageSelection';
import FrymasterHeating from '../components/navigation/menus/Frymaster/Settings/Heating/frymasterHeating';
import FrymasterPerformanceSetting from '../components/navigation/menus/Frymaster/Settings/Heating/frymasterPerformanceTest';
import FrymasterCookingSetting from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingSetting';
import FrymasterCookingMenu from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingMenu';
import FrymasterCookingLane from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingLane';
import FrymasterCookingProduct from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingProduct';
import FrymasterCookingInstant from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingInstant';
import FrymasterCookingSmarkCook from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingSmartCook';
import FrymasterCookingBasket from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingBasket';
import FrymasterCookingOilCondition from '../components/navigation/menus/Frymaster/Settings/Cooking/frymasterCookingOilCondition';
import FrymasterUserAccess from '../components/navigation/menus/Frymaster/Settings/UserAccess/frymasterUserAccess';
import FrymasterFiltration from '../components/navigation/menus/Frymaster/Settings/Filtration/frymasterFiltration';
import FrymasterFiltrationGeneral from '../components/navigation/menus/Frymaster/Settings/Filtration/frymasterFiltrationGeneral';
import FrymasterFiltrationAfterCook from '../components/navigation/menus/Frymaster/Settings/Filtration/frymasterFiltrationAfterCook';
import AssignFrymasterSettingsToTopology from '../components/navigation/menus/Frymaster/Settings/assignFrymasterSettingsToTopology';
import FrymasterOQS from '../components/navigation/menus/Frymaster/Settings/OQS/frymasterOQS';
import FrymasterOQSOilType from '../components/navigation/menus/Frymaster/Settings/OQS/frymasterOQSOilType';
import FrymasterOQSReadingDisplay from '../components/navigation/menus/Frymaster/Settings/OQS/frymasterOQSReadingDisplay';
import FrymasterAutomaticEcoMode from '../components/navigation/menus/Frymaster/Settings/EcoMode/frymasterAutomaticEcoMode';
import FrymasterEcoMode from '../components/navigation/menus/Frymaster/Settings/EcoMode/frymasterEcoMode';
import FrymasterFryerFreshOilType from '../components/navigation/menus/Frymaster/Settings/General/frymasterFryerFreshOilType';
import FrymasterFryerDisposeOilType from '../components/navigation/menus/Frymaster/Settings/General/frymasterFryerDisposeOilType';
import FrymasterFryerPotCoverPrompt from '../components/navigation/menus/Frymaster/Settings/General/frymasterFryerPotCoverPrompt';
import FrymasterDaylightSavingSelection from '../components/navigation/menus/Frymaster/Settings/General/frymasterDaylightSavingSelection';
import FrymasterDaylightSavingTime from '../components/navigation/menus/Frymaster/Settings/General/frymasterDaylightSavingTime';
import FrymasterEcoModeSchedule from '../components/navigation/menus/Frymaster/Settings/EcoMode/frymasterEcoModeSchedule';
import FrymasterMenuAutoChange from '../components/navigation/menus/Frymaster/Cookbook/AutoMenuChange/frymasterMenuAutoChange';
import FrymasterMenuLimitedOffers from '../components/navigation/menus/Frymaster/Cookbook/LimitedTimeOffer/frymasterMenuLimitedOffer';
import FrymasterMenuLimitedOffersTime from '../components/navigation/menus/Frymaster/Cookbook/LimitedTimeOffer/frymasterMenuLimitedOfferTime';
import UploadSelectDevice from '../components/navigation/menus/uploadSelectDevice';
import MenuLibraryUpload from '../components/navigation/menus/menuLibraryUpload';
import AssignLegacyMenuToTopology from '../components/navigation/menus/assignLegacyMenuToTopology';
import ReadyRecipeLibraryList from '../components/navigation/menus/readyRecipeLibraryList';
import LegacyReadyRecipeLibraryList from '../components/navigation/menus/legacyReadyRecipe/legacyReadyRecipeLibraryList';
import ReadyRecipeSelectDevice from '../components/navigation/menus/readyRecipeSelectDevice';
import menuStatus from '../components/navigation/menus/menuStatus';
import menuSmartGroupStatus from '../components/navigation/menus/menuSmartGroupStatus';
import mediaStatus from '../components/navigation/media/mediaStatus';
import mediaSmartGroupStatus from '../components/navigation/media/mediaSmartGroupStatus';
import automatedEmailSettings from '../components/navigation/reports/automatedEmailSettingConfig';
import RegisterAccount from '../components/login/registerAccount';
import VerifyOTP from '../components/login/verifyOTP';
import EmulationLogin from '../components/login/emulationLogin';
import assignPackageMedia from '../components/navigation/media/assignPackageMedia';
import CremMediaPreperation from '../components/menuManagement/cremMediaPreperation';
import TimeNumberFormatedit from '../components/userInformation/timeNumberFormatedit';
import EventLibrary from '../components/EventCenter/eventLibrary';


import RegisterDevice from '../components/RegisterDevice/registerDevice';
import AddUnitSuccess from '../components/RegisterDevice/addUnitSuccess';
import SubscriptionPlan from '../components/RegisterDevice/subscriptionPlan';
import ProfileCreation from '../components/RegisterDevice/profileCreation';
import ThankyouPage from '../components/EaasConsumer/thankYouPage';
import ConsumerProfileCreation from '../components/EaasConsumer/consumerProfileCreation';
import SaasRegistrationForm from '../components/SubscriptionForm/saasRegistrationForm';
import EaasRegistrationForm from '../components/SubscriptionForm/eaasRegistrationForm';
import ListEaasUnits from '../components/EaasConsumer/listEaasUnits';
import HowToPage from '../components/HowToPage/howToPage';

// Connectivity test
import ConnectivityPageView from '../components/connectivityTest/ConnectivityPageView';
import pdfViewer from '../components/HowToPage/pdfViewer';

import copyLibrary from '../components/navigation/menus/copyLibrary';
import assignCopyLibraryToTopology from '../components/navigation/menus/assignCopyLibraryToTopology';

// Smart Tag Import
import TagList from '../components/navigation/smartTag/tagList';
import CreateOrgSTag from '../components/navigation/smartTag/createOrgTag';
import CreateUnitSTag from '../components/navigation/smartTag/createUnitTag';
import SmartTagOrgList from '../components/navigation/smartTag/smartTagTopology';
import SmartTagUnitList from '../components/navigation/smartTag/smartTagUnitList';
import SmartTagConfirmation from '../components/navigation/smartTag/smartTagConfirmation';


class Routes extends Component {
    render() {
        return (
            <div>
                <div>
                    <Switch>
                        {/* User Story 53372: KC Portal - Landing Page with Sign In expanded */}
                        <Route exact path='/' component={Login} /> {/* DeploymentInProgress, NewLogin */}
                        <Route exact path='/verifyOTP' component={VerifyOTP} />
                        <Route exact path='/emulationLogin' component={EmulationLogin} />
                        <Route exact path='/csv-expo-redirect/:brand/:unit' component={CsvExpoRedirect} />
                        <Route exact path='/signUp' component={SignUp} />
                        <Route path='/Login' component={Login} />
                        <Route exact path='/ForgotPassword' component={ForgotPassword} />                        
                        <ProtectedRoute exact path='/createAccount' component={CreateAccount} />

                        <Route exact path='/registerAccount' component={RegisterAccount} />
                        <Route exact path='/howTo' component={HowToPage} />

                        {/* Register Device Functionality  */}
                        <Route exact path='/register-unit' component={RegisterDevice} />
                        <Route exact path='/subscriber' component={AddUnitSuccess} />
                        <Route exact path='/select-plan' component={SubscriptionPlan} />
                        <Route exact path='/profile-creation' component={ProfileCreation}/>

                        <Route exact path='/consumer' component={ThankyouPage}/>
                        <Route exact path='/consumer-profile-creation' component={ConsumerProfileCreation}/>
                        <Route exact path='/register-customer' component={SaasRegistrationForm}/>
                        <Route exact path='/subscribe-customer' component={EaasRegistrationForm}/>
                        <Route exact path='/list-units' component={ListEaasUnits}/>
                        <Route exact path='/viewer' component={pdfViewer}/>

                        <Route exact path='/loginchangeuser' component={LoginChangeUser} />
                        <Route exact path='/forgetPassword' component={ForgetPassword} />
                        <Route exact path='/resetpassword' component={resetpassword} />
                        <ProtectedRoute exact path='/unitDashboard' component={UnitDashboard} />

                        <ProtectedRoute exact path='/reportManagement' component={Production} />
                        <ProtectedRoute exact path='/reportManagement/production' component={Production} />
                        <ProtectedRoute exact path='/reportManagement/resourceUse' component={ResourceUse} />
                        <ProtectedRoute exact path='/reportManagement/cycleRates' component={CycleRates} />
                        <ProtectedRoute exact path='/reportManagement/utilization' component={Utilization} />
                        <ProtectedRoute exact path='/reportManagement/clean' component={Clean} />
                        <ProtectedRoute exact path='/reportManagement/filtration' component={Filtration} />
                        <ProtectedRoute exact path='/reportManagement/oilUsage' component={OilUsage} />
                        <ProtectedRoute exact path='/reportManagement/tpmValue' component={TPMValue} />

                        <ProtectedRoute exact path='/serviceManagement' component={ServiceManagement} />                        
                        <ProtectedRoute exact path='/troubleShooting' component={TroubleShooting} />
                        <ProtectedRoute exact path='/serviceManagement/currentErrors' component={ServiceManagement} />
                        <ProtectedRoute exact path='/serviceManagement/historicErrors' component={HistoricErrors} />
                        <ProtectedRoute exact path='/serviceManagement/timeLine' component={TimeLine} />
                        <ProtectedRoute exact path='/errorDescription' component={ErrorDescription} />
                        <ProtectedRoute exact path='/ServiceNotes' component={ServiceNotes} />
                        <ProtectedRoute exact path='/errorReport' component={ErrorReport} />
                        {/* .....Unit Quality Performance..... */}
                        <ProtectedRoute exact path='/unitQualityPerformance' component={UnitQualityPerformance} />

                        <ProtectedRoute exact path='/mediaLibrary' component={MediaLibrary} />
                        <ProtectedRoute exact path='/cremMediaPreperation' component={CremMediaPreperation} />

                        <ProtectedRoute exact path='/unitManagement' component={UnitManagement} />

                        <ProtectedRoute exact path='/mainLayout' component={DashBoardLocations} />

                        <ProtectedRoute exact path='/dashBoardLocations' component={DashBoardLocations} />
                        <ProtectedRoute exact path='/dashBoardLocations/specificLocation' component={SpecificLocation} />
                        <ProtectedRoute exact path='/dashBoardLocations/unitsView' component={SpecificLocationUnitView} />
                        <ProtectedRoute exact path='/dashBoardUnits' component={DashboardUnits} />

                        <ProtectedRoute exact path='/locationsView' component={LocationsView} />
                        <ProtectedRoute exact path='/locationsView/filterBy' component={FilterBy} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByCountry' component={FilterByCountry} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByRegion' component={FilterByRegion} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByLocation' component={FilterByLocation} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByCustomer' component={FilterByCustomer} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByBrand' component={FilterByBrand} />
                        <ProtectedRoute exact path='/locationsView/filterBy/filterByModel' component={FilterByModel} />
                        <ProtectedRoute exact path='/unitsView' component={UnitsView} />
                        <ProtectedRoute exact path='/locationsMap' component={LocationsMap} />
                        <ProtectedRoute exact path='/unitsMap' component={UnitsMap} />
                        <ProtectedRoute exact path='/siteTree' component={SiteTree} />
                        <ProtectedRoute exact path='/logoutForm' component={LogoutForm} />
                        {/* .....Navigation .....*/}
                        <Route exact path='/mainMenu' component={MainMenu} />
                        {/* ......User Management ...... */}
                        <ProtectedRoute exact path='/userManagementList' component={UserManagementList} />
                        <ProtectedRoute exact path='/userManagementRoles' component={UserManagementRoles} />
                        <ProtectedRoute exact path='/addUser' component={AddUser} />
                        <ProtectedRoute exact path='/editUser' component={EditUser} />
                        <ProtectedRoute exact path='/chooseUserRole' component={ChooseUserRole} />
                        <ProtectedRoute exact path='/userrole' component={UserRole} />
                        <ProtectedRoute exact path="/userrole/:roleId" component={UserRole} />
                        <ProtectedRoute path='/assignUserToTopology' component={AssignUserToTopology} />
                        {/* ......Error Center ...... */}
                        <ProtectedRoute exact path='/allcurrenterrors' component={AllCurrentErrors} />
                        <ProtectedRoute exact path='/allhistoricerrors' component={AllHistoricErrors} />
                        {/* .....UnitRegistration..... */}
                        <ProtectedRoute exact path='/unitRegistration' component={UnitRegistration} />
                        <ProtectedRoute exact path='/bulkUnitOnboarding' component={BulkUnitOnboarding} />
                        <ProtectedRoute exact path='/bulkUnitOnboardingConfirmation' component={BulkUnitOnboardingConfirmation} />
                        <ProtectedRoute exact path='/assignUnitToTopology' component={AssignUnitToTopology} />
                        <ProtectedRoute exact path='/addUnit' component={AddUnit} />
                        {/* ...... OrganizationManagement ..... */}
                        <ProtectedRoute exact path='/organizationManagement' component={OrganizationManagement} />
                        {/* ...... Menus ..... */}
                        <ProtectedRoute exact path='/menuBrands' component={menuBrands} />
                        <ProtectedRoute exact path='/menuUpdateStatus' component={menuUpdateStatus} />
                        <ProtectedRoute exact path='/menuUploadStatus' component={MenuUploadStatus} />
                        <ProtectedRoute exact path='/menuApps' component={MenuApps} />
                        <ProtectedRoute exact path='/menuConvolink' component={MenuConvolink} />
                        <ProtectedRoute exact path='/menuScheduleUpdateNow' component={menuScheduleUpdateNow} />
                        <ProtectedRoute exact path='/menuLibrary' component={menuLibrary} />
                        <ProtectedRoute exact path='/menuAddgroupsList' component={MenuAddgroupsList} />
                        <ProtectedRoute exact path='/menuTransfergroupsList' component={MenuTransfergroupsList} />
                        <ProtectedRoute exact path='/menuAssigngroupTopology' component={MenuAssigngroupTopology} />
                        <ProtectedRoute exact path='/menuTransferUnits' component={MenuTransferUnits} /> 
                        <ProtectedRoute exact path='/menuSmartGroupsList' component={MenuSmartGroupsList} /> 
                        <ProtectedRoute exact path='/addNewMenuLibrary' component={addNewMenuLibrary} />
                        <ProtectedRoute exact path='/menuAssignUpdateList' component={menuAssignUpdateList} />
                        <ProtectedRoute exact path='/libraryList' component={LibraryList} />
                        <ProtectedRoute exact path='/legacyLibraryList' component={LegacyLibraryList} />
                        <ProtectedRoute exact path='/assignLegacyCookbookToTopology' component={AssignLegacyCookbookToTopology} />
                        <ProtectedRoute exact path='/assignLegacyRecipeToTopology' component={AssignLegacyRecipeToTopology} />
                        <ProtectedRoute exact path='/uploadLegacyCookbookToTopology' component={UploadLegacyCookbookToTopology} />
                        <ProtectedRoute exact path='/librarySelectDevice' component={LibrarySelectDevice} />
                        <ProtectedRoute exact path='/legacySelectDevice' component={LegacySelectDevice} />
                        <ProtectedRoute exact path='/uploadLegacySelectDevice' component={UploadLegacySelectDevice} />
                        <ProtectedRoute exact path='/recipeCreation' component={RecipeCreation} />
                        <ProtectedRoute exact path='/recipeEditor' component={RecipeEditor} /> 
                        <ProtectedRoute exact path='/stageInformation' component={StageInformation} />
                        <ProtectedRoute exact path='/recipeCreationSave' component={RecipeCreationSave} /> 
                        <ProtectedRoute exact path='/assignLibraryToTopology' component={AssignLibraryToTopology} />                         
                        <ProtectedRoute exact path='/libraryScheduleUpdateNow' component={LibraryScheduleUpdateNow} />
                        <ProtectedRoute exact path='/legacyScheduleUpdateNow' component={LegacyScheduleUpdateNow} />
                        <ProtectedRoute exact path='/fileRetrievalList' component={FileRetrievalList} />
                        <ProtectedRoute exact path='/libraryAssignUpdateList' component={LibraryAssignUpdateList} />
                        <ProtectedRoute exact path='/legacyAssignUpdateList' component={LegacyAssignUpdateList} />
                        <ProtectedRoute exact path='/cookbookEditor' component={CookbookEditor} />
                        <ProtectedRoute exact path='/cookbookAddCategory' component={CookbookAddCategory} />
                        <ProtectedRoute exact path='/cookbookRecipeList' component={CookbookRecipeList} />
                        <ProtectedRoute exact path='/cookbookAddRecipes' component={CookbookAddRecipes} />
                        <ProtectedRoute exact path='/cookbookPressAndGo' component={CookbookPressAndGo} />
                        <ProtectedRoute exact path='/cookbookParameters' component={CookbookParameters} />
                        <ProtectedRoute exact path='/menurcPressAndGo' component={MenuRCPressandGo} />
                        <ProtectedRoute exact path='/cookbookAllRecipes' component={CookbookAllRecipes} />
                        <ProtectedRoute exact path='/menuAllRecipes' component={MenuAllRecipes} />
                        <ProtectedRoute exact path='/cookbookFavouritesList' component={CookbookFavouritesList} />
                        <ProtectedRoute exact path='/cookbookSubCategoryList' component={CookbookSubCategoryList} />
                        <ProtectedRoute exact path='/cookbookAddSubCategory' component={CookbookAddSubCategory} />
                        <ProtectedRoute exact path='/assignCookbookToTopology' component={AssignCookbookToTopology} />
                        <ProtectedRoute exact path='/settingsEditor' component={SettingsEditor} />
                        <ProtectedRoute exact path='/settingsModes' component={SettingsModes} />
                        <ProtectedRoute exact path='/preHeat' component={PreHeat} />
                        <ProtectedRoute exact path='/temperatureBand' component={TemperatureBand} />
                        <ProtectedRoute exact path='/timerForTemperature' component={TimerForTemperature} />
                        <ProtectedRoute exact path='/sound' component={Sound} />
                        <ProtectedRoute exact path='/units' component={Units} />
                        <ProtectedRoute exact path='/password' component={Password} />
                        <ProtectedRoute exact path='/managerPassword' component={ManagerPassword} />
                        <ProtectedRoute exact path='/servicePassword' component={ServicePassword} />
                        <ProtectedRoute exact path='/languages' component={Languages} />
                        <ProtectedRoute exact path='/cleaning' component={Cleaning} />
                        <ProtectedRoute exact path='/screensaver' component={Screensaver} />
                        <ProtectedRoute exact path='/changeScreensaver' component={ChangeScreensaver} />
                        <ProtectedRoute exact path='/dateAndTime' component={DateAndTime} />
                        <ProtectedRoute exact path='/dateAndTimeSettings' component={DateAndTimeSettings} />
                        <ProtectedRoute exact path='/timezone' component={Timezone} />
                        <ProtectedRoute exact path='/scheduler' component={Scheduler} />
                        <ProtectedRoute exact path='/schedulerAdd' component={SchedulerAdd} />
                        <ProtectedRoute exact path='/assignSettingsToTopology' component={AssignSettingsToTopology} />
                        <ProtectedRoute exact path='/retrieveLibraryToTopology' component={RetrieveLibraryToTopology} />
                        <ProtectedRoute exact path='/libraryAssignTopology' component={LibraryAssignTopology} />
                        <ProtectedRoute exact path='/legacyAssignTopology' component={LegacyAssignTopology} />
                        <ProtectedRoute exact path='/recipeAssignTopology' component={RecipeAssignTopology} />
                        <ProtectedRoute exact path='/libraryUploadTopology' component={LibraryUploadTopology} />

                        <ProtectedRoute exact path='/frymasterRecipeEditor' component={FrymasterRecipeEditor} />
                        <ProtectedRoute exact path='/frymasterRecipeCreation' component={FrymasterRecipeCreation} />
                        <ProtectedRoute exact path='/frymasterRecipeTypeSelection' component={FrymasterRecipeTypeSelection} />
                        <ProtectedRoute exact path='/frymasterMultiSegment' component={FrymasterMultiSegment} />
                        <ProtectedRoute exact path='/frymasterActionPrompt' component={FrymasterActionPrompt} />
                        <ProtectedRoute exact path='/frymasterRecipeSchedule' component={FrymasterRecipeSchedule} />
                        <ProtectedRoute exact path='/frymasterRecipeReview' component={FrymasterRecipeReview} />
                        <ProtectedRoute exact path='/assignFrymasterRecipeToTopology' component={AssignFrymasterRecipeToTopology} />

                        <ProtectedRoute exact path='/frymasterMenuEditor' component={FrymasterMenuEditor} />
                        <ProtectedRoute exact path='/frymasterMenuAdd' component={FrymasterMenuAdd} />
                        <ProtectedRoute exact path='/frymasterMenuList' component={FrymasterMenuList} />
                        <ProtectedRoute exact path='/frymasterMenuRecipeList' component={FrymasterMenuRecipeList} />
                        <ProtectedRoute exact path='/frymasterMenuAddRecipes' component={FrymasterMenuAddRecipes} />
                        <ProtectedRoute exact path='/frymasterMenuShareFrypot' component={FrymasterMenuShareFrypot} />
                        <ProtectedRoute exact path='/frymasterMenuSelectFrypots' component={FrymasterMenuSelectFrypots} />
                        <ProtectedRoute exact path='/frymasterMenuAddFinalReview' component={FrymasterMenuAddFinalReview} />
                        <ProtectedRoute exact path='/assignFrymasterMenuToTopology' component={AssignFrymasterMenuToTopology} />

                        <ProtectedRoute exact path='/frymasterMenuAutoChange' component={FrymasterMenuAutoChange} /> 
                        <ProtectedRoute exact path='/frymasterMenuLimitedOffer' component={FrymasterMenuLimitedOffers} />
                        <ProtectedRoute exact path='/frymasterMenuLimitedOfferTime' component={FrymasterMenuLimitedOffersTime} />

                        {/* Frymaster Settings */}
                        <ProtectedRoute exact path='/frymasterSettings' component={FrymasterSettings} />
                        <ProtectedRoute exact path='/frymasterGeneralSettings' component={FrymasterGeneralSettings}/>
                        <ProtectedRoute exact path='/frymasterTimeDate' component={FrymasterTimeDate}/>
                        <ProtectedRoute exact path='/frymasterLocale' component={FrymasterLocale}/>
                        <ProtectedRoute exact path='/frymasterScreen' component={FrymasterScreen}/>
                        <ProtectedRoute exact path='/frymasterTemperature' component={FrymasterTemperature}/>
                        <ProtectedRoute exact path='/frymasterLanguage' component={FrymasterLanguage}/>
                        <ProtectedRoute exact path='/frymasterSound' component={FrymasterSound}/>
                        <ProtectedRoute exact path='/frymasterConfig' component={FrymasterConf}/>
                        <ProtectedRoute exact path='/frymasterLanguageSelection' component={FrymasterLanguageSelection}/>
                        <ProtectedRoute exact path='/frymasterFryerFreshOilType' component={FrymasterFryerFreshOilType}/>
                        <ProtectedRoute exact path='/frymasterFryerDisposeOilType' component={FrymasterFryerDisposeOilType}/>
                        <ProtectedRoute exact path='/frymasterFryerPotCoverPrompt' component={FrymasterFryerPotCoverPrompt}/> 

                        <ProtectedRoute exact path='/frymasterDaylightSavingSelection' component={FrymasterDaylightSavingSelection} />
                        <ProtectedRoute exact path='/frymasterDaylightSaving' component={FrymasterDaylightSavingTime} />

                        <ProtectedRoute exact path='/frymasterHeating' component={FrymasterHeating} />
                        <ProtectedRoute exact path='/frymasterPerformanceTest' component={FrymasterPerformanceSetting} />
                        <ProtectedRoute exact path='/frymasterCooking' component={FrymasterCookingSetting} /> 

                        <ProtectedRoute exact path='/frymasterUserAccess' component={FrymasterUserAccess} /> 
                        <ProtectedRoute exact path='/frymasterFiltration' component={FrymasterFiltration} /> 
                        <ProtectedRoute exact path='/frymasterFiltrationGeneral' component={FrymasterFiltrationGeneral} /> 
                        <ProtectedRoute exact path='/frymasterFiltrationAfterCook' component={FrymasterFiltrationAfterCook} /> 

                        <ProtectedRoute exact path='/frymasterCookingMenu' component={FrymasterCookingMenu} />  
                        <ProtectedRoute exact path='/frymasterCookingLaneConfiguration' component={FrymasterCookingLane} /> 
                        <ProtectedRoute exact path='/frymasterCookingProductDisplay' component={FrymasterCookingProduct} /> 
                        <ProtectedRoute exact path='/frymasterCookingInstantOn' component={FrymasterCookingInstant} /> 
                        <ProtectedRoute exact path='/frymasterCookingSmartCook' component={FrymasterCookingSmarkCook} /> 
                        <ProtectedRoute exact path='/frymasterCookingBasketLift' component={FrymasterCookingBasket} /> 
                        <ProtectedRoute exact path='/frymasterCookingOilCondition' component={FrymasterCookingOilCondition} /> 

                        <ProtectedRoute exact path='/assignFrymasterSettingsToTopology' component={AssignFrymasterSettingsToTopology} />
                        <ProtectedRoute exact path='/frymasterOQS' component={FrymasterOQS} />  
                        <ProtectedRoute exact path='/frymasterOQSOilType' component={FrymasterOQSOilType} /> 
                        <ProtectedRoute exact path='/frymasterOQSReadingDisplay' component={FrymasterOQSReadingDisplay} /> 
                        <ProtectedRoute exact path='/frymasterEcoMode' component={FrymasterEcoMode} /> 
                        <ProtectedRoute exact path='/frymasterAutomaticEcoMode' component={FrymasterAutomaticEcoMode} />
                        <ProtectedRoute exact path='/frymasterEcoModeScheduled' component={FrymasterEcoModeSchedule}/>   
                        
                        <ProtectedRoute exact path='/frymasterMultiSegment' component={FrymasterMultiSegment} />
                        <ProtectedRoute exact path='/frymasterActionPrompt' component={FrymasterActionPrompt} />
                        <ProtectedRoute exact path='/frymasterRecipeSchedule' component={FrymasterRecipeSchedule} />
                        <ProtectedRoute exact path='/frymasterRecipeReview' component={FrymasterRecipeReview} />
                        <ProtectedRoute exact path='/assignFrymasterRecipeToTopology' component={AssignFrymasterRecipeToTopology} />
                        <ProtectedRoute exact path='/uploadSelectDevice' component={UploadSelectDevice} />
                        <ProtectedRoute exact path='/menuLibraryUpload' component={MenuLibraryUpload} />
                        <ProtectedRoute exact path='/assignLegacyMenuToTopology' component={AssignLegacyMenuToTopology} />
                        <ProtectedRoute exact path='/readyRecipeLibraryList' component={ReadyRecipeLibraryList} />
                        <ProtectedRoute exact path='/legacyReadyRecipeLibraryList' component={LegacyReadyRecipeLibraryList} />
                        <ProtectedRoute exact path='/readyRecipeSelectDevice' component={ReadyRecipeSelectDevice} /> 
                        <ProtectedRoute exact path='/copyLibrary' component={copyLibrary} />                       
                        <ProtectedRoute exact path='/assignCopyLibraryToTopology' component={assignCopyLibraryToTopology} />                       

                        <ProtectedRoute exact path='/menuStatus' component={menuStatus} />
                        <ProtectedRoute exact path='/menuSmartGroupStatus' component={menuSmartGroupStatus} />                        

                        {/* ......Media...... */}
                        <ProtectedRoute exact path='/mediaLibraryList' component={mediaLibraryList} />
                        <ProtectedRoute exact path='/mediaPreparation' component={mediaPreparation} />
                        <ProtectedRoute exact path='/mediaScreenSaver' component={mediaScreenSaver} />
                        <ProtectedRoute exact path='/mediaCompilation' component={mediaCompilation} />
                        <ProtectedRoute exact path='/mediaPackage' component={mediaPackage} />
                        <ProtectedRoute exact path='/assignMedia' component={assignMedia} />
                        <ProtectedRoute exact path='/assignMediaUpdateList'  component={assignMediaUpdateList}/>
                        <ProtectedRoute exact path='/mediaUploadHistory' component={mediaUploadHistory} />
                        <ProtectedRoute path='/assignMediaList' component={assignMediaList} />
                        <ProtectedRoute path='/assignPackageMedia' component={assignPackageMedia}/>
                        <ProtectedRoute exact path='/mediaImageLibrary' component={MediaImageLibrary} />
                        <ProtectedRoute exact path='/mediaAudioLibrary' component={MediaAudioLibrary} /> 
                        <ProtectedRoute exact path='/assignmediatopology' component={AssignmediaTopology} /> 
                        <ProtectedRoute exact path='/assignmedialistView' component={AssignmedialistView} />
                        <ProtectedRoute exact path='/assignmediaconform' component={AssignmediaConform} />    
                        <ProtectedRoute exact path='/mediaStatus' component={mediaStatus} />
                        <ProtectedRoute exact path='/mediaSmartGroupStatus' component={mediaSmartGroupStatus} />
                        <ProtectedRoute exact path='/mediaSmartGroupStatus' component={mediaSmartGroupStatus} />
                        <ProtectedRoute exact path='/mediaAssignSmartGroupsList' component={MediaAssignSmartGroupsList} />
                        <ProtectedRoute exact path='/mediaTransferUnits' component={MediaTransferUnits} />
                        <ProtectedRoute exact path='/organizationChartView' component={OrganizationChartView} />
                        <ProtectedRoute exact path='/organizationChartListView' component={OrganizationChartListView} />

                        {/* .....Software.... */}
                        <ProtectedRoute exact path='/softwareAvailableUpdates' component={SoftwareAvailableUpdates} />
                        <ProtectedRoute exact path='/softwareUpdatesStatus' component={SoftwareUpdatesStatus} />
                        <ProtectedRoute exact path='/softwareStatus' component={SoftwareStatus} />
                        <ProtectedRoute exact path='/softwareSmartGroupStatus' component={softwareSmartGroupStatus} />                        
                        <ProtectedRoute exact path='/assignUpdate' component={AssignUpdate} />
                        <ProtectedRoute exact path='/bakingPlan' component={BakingPlan} />
                        <ProtectedRoute path='/assignUpdateList' component={AssignUpdateList} />
                        <ProtectedRoute path='/softwareScheduleUpdate' component={softwareScheduleUpdate} />
                        <ProtectedRoute exact path='/softwareLibraryList' component={SoftwareLibraryList} />
                        <ProtectedRoute exact path='/softwareLibraryUpdate' component={SoftwareLibraryUpdate} />
                        <ProtectedRoute exact path='/softwareSmartGroupsList' component={SoftwareSmartGroupsList} />
                        <ProtectedRoute exact path='/softwareTransferSmartgroupsList' component={SoftwareTransferSmartgroupsList} />                        
                        <ProtectedRoute exact path='/softwareAssignSmartgroupsList' component={SoftwareAssignSmartGroupsList} />
                        <ProtectedRoute exact path='/softwareAssignlistView' component={SoftwareAssignlistView} />
                        <ProtectedRoute exact path='/softwareAssigngroupTopology' component={SoftwareAssigngroupTopology} />
                        <ProtectedRoute exact path='/softwareAssignmedialistView' component={SoftwareAssignmedialistView} />
                        <ProtectedRoute exact path='/softwareTransferUnits' component={SoftwareTransferUnits} />

                        {/* ----- HACCP Management ----  */}
                        <ProtectedRoute exact path='/haccpLogLibrary' component={HACCPLogLibrary} />
                        <ProtectedRoute exact path='/haccpRetrieveStatus' component={HACCPRetrieveStatus} />
			            <ProtectedRoute exact path='/haccpAssignUpdateList' component={HACCPAssignUpdateList} />
                        <ProtectedRoute exact path='/haccpBrands' component={HACCPBrands} />
                        
                        {/* Report */}
                        <ProtectedRoute exact path='/reportList' component={reportList} />
                        <ProtectedRoute exact path='/scheduledReportList' component={scheduledReportList} />
                        <ProtectedRoute exact path='/newReport' component={newReport} />
                        <ProtectedRoute exact path='/newReportWithOptions' component={newReportWithOptions} />                        
                        <ProtectedRoute exact path='/reportAssignUpdateList' component={reportAssignUpdateList} />
                        <ProtectedRoute exact path='/brandReportList' component={brandReportList} />
                        <ProtectedRoute exact path='/assignReportUnitToTopology' component={reportAssignUnitToTopology} />
                        <ProtectedRoute exact path='/fryerReport' component={FryerReport} />
                        <ProtectedRoute exact path='/reportAssignStoreList' component={reportAssignStoreList} />
                        <ProtectedRoute exact path='/garlandReport' component={GarlandReport} />
                        <ProtectedRoute exact path='/automatedEmailSettings' component={automatedEmailSettings} />

                        {/* ----- KC API Portal ----- */}
                        <ProtectedRoute exact path='/c2cSwaggerListView' component={c2cSwaggerListView} />
                        <ProtectedRoute exact path='/swaggerInterface' component={SwaggerInterface} />
                        <ProtectedRoute exact path='/swaggerUiComponent' component={SwaggerUIComponent} />

                        {/* ----- Products Selection ----- */}
                        <ProtectedRoute exact path='/displayedproducts' component={DisplayProduct} />                        
                        <ProtectedRoute exact path='/productsselection' component={ProductSelection} />

                        {/* ----- Smart Groups ----- */}
                        <ProtectedRoute exact path='/smartgroupslist' component={SmartGroupsList} />                        
                        <ProtectedRoute exact path='/createnewsmartgrp' component={CreateNewSmartGrp} />
                        <ProtectedRoute exact path='/editsmartgrp' component={EditSmartGrp} />
                        <ProtectedRoute exact path='/purposesList' component={PurposesList} />                        
                        <ProtectedRoute exact path='/smartGrpTopology' component={SmartGrpTopology} />
                        <ProtectedRoute exact path='/smartGrpAssignUpdateList' component={SmartGrpAssignUpdateList} />
                        <ProtectedRoute exact path='/editsmartGrpTopology' component={EditSmartGrpTopology} />
                        <ProtectedRoute exact path='/editsmartGrpAssignUpdateList' component={EditSmartGrpAssignUpdateList} />
                        
                        {/* .....User Information.... */}
                        <ProtectedRoute exact path='/myInformation' component={myInformation} />
                        <ProtectedRoute exact path='/assignedUnitGroups' component={assignedUnitGroups} />
                        <ProtectedRoute exact path='/notificationManagement' component={notificationManagement} />
                        <ProtectedRoute exact path='/notificationManagementView' component={notificationManagementView} />
                        <ProtectedRoute exact path='/myinformationedit' component={myInformationEdit} />
                        <ProtectedRoute exact path='/assignedUnitGroupsEdit' component={assignedUnitGroupsEdit} />
                        <ProtectedRoute exact path='/managePassword' component={ManagePassword} />
                        <ProtectedRoute exact path='/mySubscriptionEdit' component={mySubscriptionEdit} />
                        <ProtectedRoute exact path='/mySubscriptionView' component={mySubscriptionView} />
                        <ProtectedRoute exact path='/timeNumberFormatedit' component={TimeNumberFormatedit} />

                        {/* ..... Smart Tag.....*/}
                        <ProtectedRoute exact path='/smartTag/tagList' component={TagList} />
                        <ProtectedRoute exact path='/smartTag/createOrgTag' component={CreateOrgSTag} />
                        <ProtectedRoute exact path='/smartTag/createUnitTag' component={CreateUnitSTag} />
                        <ProtectedRoute exact path='/smartTag/smartTagTopology' component={SmartTagOrgList} />
                        <ProtectedRoute exact path='/smartTag/smartTagUnitList' component={SmartTagUnitList} />
                        <ProtectedRoute exact path='/smartTag/smartTagConfirmation' component={SmartTagConfirmation} />
                        
                        {/* Event center */}
                        <ProtectedRoute exact path='/eventLibrary' component={EventLibrary}/>  

                        {/* Connectivity Test  */}
                        <Route exact path='/connectest' component={ConnectivityPageView} />

                        <Route exact path="/un-authorized" component={UnAuthorized} />
                        <Route component={underConstruction} />
                    </Switch>
                </div>
            </div>
        )

    }
};

export default Routes;
