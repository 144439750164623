import { GET_SEARCH_DATA, GET_SEARCH_DATA_LOADING, SEARCH_VISIBLE, GET_SEARCH_BY_CATEGORY} from '../../../actions/stateActionsData/type';
const initialState = {
    searchtext: null,
    isLoading: false,
    isVisible: false,
    searchByCategory: null,
}
export default function(state = initialState, action){
    switch(action.type) {

        // Reducer for search loading
        case GET_SEARCH_DATA_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }

        // Reducer for global search visible on page
        case SEARCH_VISIBLE:
        return{
            ...state,
            isVisible : action.payload
        }

        // Reducer for search text
        case GET_SEARCH_DATA:
        return {
            ...state,
            searchData: action.payload
        }

        // // Reducer for search by category
        case GET_SEARCH_BY_CATEGORY:
            return {
                ...state,
                searchByCategory: action.payload
            }
        default:
        return state;


    }
}

