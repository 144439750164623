import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { UnitInfo, SoftwareManagementAPI, smartTagMangement } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import authHelper from '../../../utils/authHelper';
import _ from 'lodash';
import $ from 'jquery';
import appconstants, { BRAND_ID, unitSelectionAllowOnTransfer } from '../../../utils/appConstants';
import { BRAND_ID_VALUE,BASECOUNTRY_ID, bakingPlanDescription, bakingPlanType } from '../../../utils/appConstants';
import Tooltip from 'react-tooltip-lite';
import OfflineMediaImg from '../../../images/icons/offlineT.svg';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import SmartTagDrop from '../../controls/smartTagDragDrop';
import moment from '../../../utils/momentHelper.js';
import TagOrgModal from '../smartTag/tagOrgModel';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import GenericModal from '../../modal/generic-model';

class AssignUpdateList extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(probs) {
		super(probs);
		this.state = {
			units_list: [],
			total_unit_count: 0,
			prev_page: 0,
			next_page: 2,
			data: [],
			initialUnit: [],
			pages: null,
			loading: true,
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			storeInstalledItemInfo: {},
			locationInfo:{},
			isIndeterminate: false,
			showpopup: [],
			showOrgPopup: false,
			type: "orgList",
			showStPopUp:false,
			selUnitDetails:{},
			isSelectionHappen: false,
			generic_modal: { 
                open: false,
                message: "",
                messagetype: ""
            },
		}

		this.onSortedChange = this.onSortedChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.postData = this.postData.bind(this);
		this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/softwareUpdatesStatus`);
	}

	closeModel = () => {
		this.setState({ error_modal: { open: false } })
	}

	successCloseModel = () => {
		this.setState({ error_modal: { open: false } })
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	onSortedChange = (a, b, desc) => {
		this.setState({
			prev_page: 0,
			next_page: 2
		});
		return 0;
	};

	onSuccessCloseModal = () => {
		this.props.history.push(`/softwareUpdatesStatus`);
	}

	onBackClickHandler = () => {
		let { ScheduleUpdate, MODEL_IDS, MODEL_NAMES, FILE_NAME, VERSION, UPLOADED_ON, TYPE, ID } = this.state.locationInfo;
		/*Garland brand software implementation code added by kamal **/
		if (MODEL_IDS === undefined || MODEL_NAMES === undefined || localStorage.getItem("brandName") === "CREM" || localStorage.getItem("brandName") === "GARLAND") {
			this.props.history.push(`/softwareAvailableUpdates`);
		} else {
			this.props.history.push({
				pathname: "/softwareScheduleUpdate",
				Type: ScheduleUpdate,
				MODEL_IDS: MODEL_IDS,
				MODEL_NAMES: MODEL_NAMES,
				FILE_NAME: FILE_NAME,
				VERSION: VERSION,
				UPLOADED_ON: UPLOADED_ON,
				TYPE: TYPE,
				ID: ID
			});
		}
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
		this.props.history.push(`/softwareUpdatesStatus`);
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	componentWillMount = () => {
		let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
		localStorage.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

		let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(localStorage.getItem('locationInfo'));
		localStorage.setItem('locationInfo', JSON.stringify(locationInfo))

		this.setState({
			storeInstalledItemInfo: storeInstalledItemInfo,
			locationInfo: locationInfo
		});
	} 
	componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }
	updateDimensions() {
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	}
	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions); 
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			await this.requestData();
		} catch (error) {
			//Error Goes Here
			let {message,errorCode} = error.response.data;
			if(errorCode == '403100' && message=='Token Expired Or Invalid'){
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1678"
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false
				});
			}
		}
		
	}


	pageChange = (arg, next, prev) => {
		if (arg > -1) {
			this.setState({
				prev_page: arg,
				next_page: (arg + 1) + 1
			});
		}
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			}
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1168"
				}
			});
		}
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];

		units && units.length > 0 && units.map(item => {
			item.checked = item.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? userSelectAll : false;
			checkedCopy.push(item.checked);
		});

		this.isSelectAssign(units);
		let unitsOfflineCount = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.CONNECT_STATE === "Offline");
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

		// this.checkUnitsCount(unitsSelected)
		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: unitsSelected.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: unitsSelected,
				userSelectAll: true,
				isIndeterminate: false,
				isSelectionHappen: false
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
				userSelectAll: false,
				isIndeterminate: false,
				isSelectionHappen: false
			});
		}
	};

	getCheckBoxstate(ListCount,unselectedCount) {
		if (ListCount == unselectedCount) {
		  return { userSelectAll: true, isIndeterminate: false };
		} else if (unselectedCount == 0) {
		  return { userSelectAll: false, isIndeterminate: false };
		} else if (ListCount > unselectedCount) {
		  return { userSelectAll: true, isIndeterminate: true };
		}
	}


	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && (c.checked === false || c.checked === undefined));
		let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");
		let unitRegistedCount = units.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length

		// this.checkUnitsCount(checked);
		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			isSelectionHappen: false,
			...this.getCheckBoxstate(parseInt(units.length) -parseInt(unitRegistedCount) 
			,checkAll.length)
		});

		this.isSelectAssign(checked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c.checked === true);

		if (checkAllTrue.length > 0 && (units.length >= 1)) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}


	requestData = async () => {

		let { TYPE, ID } = this.state.locationInfo;
		let brandId = this.getBrandId();
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.softwareFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else if (selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypeList.length > 0) {
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}

		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let url = `${UnitInfo.list}`;
		let apiUrl = url;
		let res = {
			rows: [],
			pages: 0
		};
		this.setState({ loaderIconVisible: true });
		let softDetails = this.state.storeInstalledItemInfo;
		let headerData = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': selectedCids.join(','),
			'brandid': selectedBrands.join(',') || brandId,
			'model': selectedModels.join(',') || localStorage.getItem("modelIdValue"),
			'machinetype': selectedMachineTypes.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			// 'basecountry': BASECOUNTRY_ID,
			'unitrange': softDetails && softDetails.unitRange ? JSON.stringify(softDetails.unitRange) : [],
			'provisionedunits': 'true'
		}
		if(TYPE == bakingPlanType) {
			delete headerData.model
			headerData.marketId = ID
		}
		await http.get(apiUrl,
			{
				headers: headerData,
				data: {}
			}).then(response => {
				let { units, numberOfPages } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					initialUnit: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll
				});

			}).catch(err => {
				this.showError(err);
			});
	};

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	onFinishClickHandler() {
		const { storeInstalledItemInfo, locationInfo } = this.state;
		if (!storeInstalledItemInfo.SOFTWARE_ID) {
			this.props.history.push(`/softwareAvailableUpdates`);
		}
		else {
			let userCheckedData = this.state.userCheckedData;

			const unitList = [];
			userCheckedData.map(item => {
				return unitList.push(
					{
						"thingArn": item.THING,
						"productId": item.PRODUCT_ID,
						"cloudUnitName": item.CLOUD_UNIT_NAME,
						"unitTimeZone": item.TimeZone_Standard
					}
				)
			});

			let req = {
				"thingList": unitList,
				"softwareId": storeInstalledItemInfo.SOFTWARE_ID,
				"description": storeInstalledItemInfo.DESCRIPTION,
				"softwareType": storeInstalledItemInfo.TYPE
			}
			let url = SoftwareManagementAPI.softwareUpdateStatusUrl
			let headerData = {
				'Content-Type': 'application/json',
				'customer': localStorage.getItem("customerId"),
				'user': localStorage.getItem("user"),
				// 'basecountry': BASECOUNTRY_ID
			}
			if(storeInstalledItemInfo.TYPE == bakingPlanType) {
				url = SoftwareManagementAPI.bakingPlanUpdateStatus
				req = {
					"store_baking_plan_ids":[storeInstalledItemInfo.SOFTWARE_ID],
					"cust_id": localStorage.getItem("customerId"),
					"unit_ids": userCheckedData.map(item => item.UNITID),
					"brand_id": localStorage.getItem("unitBrandId"),
					"user": localStorage.getItem("user"),
					"otaDescription": bakingPlanDescription,
					"offline": false
				}
				headerData = {
					'Content-Type': 'application/json'
				}
			}

			if (locationInfo.Type) {
				req.scheduleDateTimeUtc = locationInfo.scheduleDateTimeUtc
			}
			this.setState({ loaderIconVisible: true });
			this.postData(url, req, headerData).then(response => {
				this.props.history.push(`/softwareUpdatesStatus`);
			}).catch(err => {
				//Error Goes Here
				this.showError(err);
			});
		}
	}

	showError = (err) => {
		let { response } = err;
		let { data } = response;
		let { message, body, errorCode } = data;
		if (response && response.status === 400) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: body && body.message ? body.message : ""
				}
			});
		}
		else if (response && response.status === 500) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: message ? message : ""
				},
			});
		}
		else if (errorCode == '403100' && message == 'Token Expired Or Invalid') {
			debugger
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1678"
				}
			});
		}
		else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: 'KC1069'
				}
			});
		}
	}

	postData = (url, reqData, headerData) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {

			http.post(url
				, reqData,
				{
					headers: headerData
				}
			)
				.then(response => {
					let { data } = response;
					let { httpCode, responseCode, stringID, message } = data;
					if (response && response.status === 200) {
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (stringID || reqData.thingList.length > 100 ? message : "Item distributed successfully")
							},
						});
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	applyChanges = (postData) => {
		return new Promise((res, rej) => {
			let custGroupID = localStorage.getItem("custGroupID");
			let cgids = "";
			if (custGroupID !== "" && custGroupID !== undefined) {
				cgids = custGroupID;
			}
			http({
				method: "POST",
				url: smartTagMangement.transferTagDetails,
				headers: {
					'Content-Type': 'application/json',
					'customerid': localStorage.getItem("customerId"),
					'brandid': localStorage.getItem("selectedbrandID"),
					'cgid': cgids
				},
				data: postData
			}).then(async response => {
				if(response && response.data){
					res(response.data.data || [] );
				}
			}).catch(err => {
				res(err);
			});

		})
	}

	applyFilterChanges = (values) => {
		if (values && values.length > 0) {
			this.setState({ loaderIconVisible: true, initialUnit: this.state.data })
			this.applyChanges(values).then((res)=>{
				const numberType = res && res.length ? res.map(Number) : [];
				let units = [];
				for (let i = 0; i < this.state.data.length; i++) {
					const ele = this.state.data[i];
					const isInactive = ele.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED";
					units.push({ ...this.state.data[i], checked: numberType.includes(ele.ID)? isInactive ? false :  true :  false });
				}

				let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

				// this.checkUnitsCount(checked);
				this.setState({
					data: units,
					loaderIconVisible: false,
					userCheckedData: checked,
					isSelectionHappen: true,
					...this.getCheckBoxstate(units.length, units.filter((i) => i.checked === true).length)
				},()=>{
					this.isSelectAssign(checked);
					$('#transferDetailsModal').modal('hide');
				});
			})			
		}else{
			let checked = this.state.initialUnit.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

			// this.checkUnitsCount(checked);
			this.setState({ 
				data: this.state.initialUnit, 
				unitsSelected: checked.length,
				userCheckedData: checked,
				isSelectionHappen: false,
				...this.getCheckBoxstate(this.state.initialUnit.length, checked.length) 
			},()=>{
				this.isSelectAssign(checked);
			})
		}
	}

	getInfoView = (value, type) => {
		let smartTagObj = {
			mode: 'isView',
			type,
			selectedRow: value
		}
		localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
		let showpopup = [];
		showpopup.push(value);
		this.setState({ showpopup, showOrgPopup: true , type}, () => {
			$('#transferDetailsModal').modal('hide');
			$('#stUnitDetailsModal').modal('show');
		});
	}

	showSTagInfo = (unitDet,type="org") =>{
		let unitObj = unitDet;
		unitObj.id = unitDet.ID;
        this.setState({
            showStPopUp:true,
			selUnitDetails:unitObj
        },()=>{
            $('#unitSmartTagsDetails').modal('show')
        })

    }

	downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

	onGenericCloseModal = () => {
		this.setState({
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			}
		});
	}

	checkUnitsCount = (units) => {
		const totalUnits = parseInt(unitSelectionAllowOnTransfer);
		if (units && units.length > totalUnits) {
			this.setState({
				generic_modal: {
					open: true,
					message: "Please select maximum of 300 units",
					messagetype: "Maximum number of units",
					count: units.length,
					total: totalUnits
				}
			})
		}
	};

	render() {
		const { formatMessage } = this.props.intl;
		const { data, sortColumn, error_modal, success_modal, loaderIconVisible, isIndeterminate, showpopup, type, isSelectionHappen, userCheckedData, generic_modal } = this.state;
		let userProfileAuthFeature = authService.getFeature("/softwareAvailableUpdates");
		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0050" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "", labelStringID: "KC2317" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">
					<div class="unitRegistrationWrapper">
						<div class="addUnitHeader">
							<ul>
								<li>
									<a href="javascript:void(0)" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</a>
								</li>
								<li className="txtboldmsg">
									<FormattedMessage id="KC1169"/>
					</li>
								<li>
									<a id="btnfinish" to="#" className="disabled" onClick={() => this.onFinishClickHandler()} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} title={formatMessage({ id: 'KC0056' })}><FormattedMessage id="KC0056"/></a>
								</li>
							</ul>
						</div>
						<div class="selectedUnitHeader">
							<ul>
								<li>{`${unitList.length || 0} listed units`}{(userCheckedData && userCheckedData.length ) || isSelectionHappen ? ` / ${userCheckedData.length} units selected` : null}</li> 
								<li>
									<button type="button" id="transferDetails" onClick={() => $('#transferDetailsModal').modal('show')} class="btn btn-default useTagsforunit" data-toggle="popover" data-content={formatMessage({ id: 'KC2356' })}><FormattedMessage id="KC2360" /></button>
									<Link to="/assignUpdateList" class="btn btn-default-text assignunitList active" >&nbsp;</Link>
								</li>
							</ul>
						</div>
						<div class="SWlistUnits">
							<div class="SWlistUnitsTable">
								<TableHeader
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isSelectAll={this.state.userSelectAll}
									isIndeterminate= {isIndeterminate} />
							</div>
							<div className="assignUListSrlBar">
							<div className="SWlistUnitsTable">
								{
									unitList.map((item, i) => {
										return (
											<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody "} key={i}>
												<li>{i + 1}</li>
												<li><img src={item.Brand_Logo} /></li>
												<li>{item.BRAND_NAME}
													<div class="unitsListSM">{item.PRODUCT_TYPE},{item.MODEL_NAME}</div>
													<div class="unitsListSML">{item.location_name}</div></li>
												<li>{item.PRODUCT_TYPE}</li>
												<li>{item.MODEL_NAME}</li>
												<li>{item.UNITID} <span class="unitsListAN"><FormattedMessage id="KC0674"/> <b>{item.UNIT_NUMBER}</b></span></li>
												<li>{item.UNIT_NUMBER}</li>
												<li>{item.location_name}</li>
												<li>{item.within_location}</li>
												<li>
													{item.orgTags && item.orgTags.length ? <button className="btn orgTagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
													{item.unitTags && item.unitTags.length ? <button className="btn tagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
												</li>
												<li>
													{ item.CONNECT_STATE === 'Offline'  && <img src = { OfflineMediaImg} />}
												</li>
												<li>
													{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
														<div className="customCheckbox customCheckboxWOlabel disabled">
															<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																checked={item.checked}
																onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id-1)} />
															<label for={item.order_id - 1} ></label>
														</div> </Tooltip> 
														: <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
															<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																checked={item.checked}
																onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id-1)} />
															<label for={item.order_id - 1} ></label>
														</div>}
												</li>
											</ul>
										)
									}
									)
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Transfer Modal --> */}
				<div class="modal fade transferDetailsModal" data-keyboard="true" data-backdrop="static" id="transferDetailsModal" tabindex="-1" role="dialog">
					<SmartTagDrop applyFilterChanges={this.applyFilterChanges} getInfoView={this.getInfoView} />
				</div>
					{/* <!-- Transfer Modal --> */ }
					<div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-header modal-header-danger">
									<FormattedMessage id="KC2312" />
								</div>
								<div className="modal-body">
									{showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
												</li>
											</ul>
										</div>
										<h6><FormattedMessage id="KC0195" /></h6>
										<p>{item.description ? item.description : 'NA'}</p>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn"><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
												</li>
												{type === 'orgList' ? "" :
													<li>
														<Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
													</li>}
											</ul>
										</div>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li><FormattedMessage id="KC0589" /></li>
												<li><FormattedMessage id="KC1278" /></li>
											</ul>
											<ul className="tBody">
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.createdByName ? item.createdByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.createdBy ? item.createdBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.updatedByName ? item.updatedByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.updatedBy ? item.updatedBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>)}
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false }, () => $('#transferDetailsModal').modal('show'))} type="button"><FormattedMessage id="KC0777" /></button>
								</div>
							</div>
						</div>
					</div >
				{this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
				{this.state.showStPopUp ? <OrgTagsDetail closeSTModel = {()=>this.setState({showStPopUp:false})} unitDet = {this.state.selUnitDetails} isUnit = {true}/>: ""}
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onSuccessModel={this.successCloseModel} onCloseModel={this.closeModel} />
				<GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} values={{ count: generic_modal.count ? generic_modal.count : 0, total: generic_modal.total ? generic_modal.total : 0 }} onGenericCloseModal={(e) => this.onGenericCloseModal()} />
				<UserManagementHeader headerNameStringID="KC2358" headerName="Transfer Management" activeClass="transferNav" filterKey={appconstants.softwareFilters} history={this.props.history}/>
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignUpdateList));