import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from '../../service/api';
import http from '../../service/httpService';
import { brandLocations, brandUnits } from '../../utils/imgContants';
import { FormattedMessage } from 'react-intl';

var bg = require('../../images/unit/orgDefault.png')
const brandsLogo = brandUnits;
const brandsLocationLogo = brandLocations;

const formUnits = (units, colorCode) => {
    const unitStyle = { 'background': colorCode, 'borderRadius':'4px' };
    const unitLinkStyle = { 'background': colorCode, 'border': '2px solid #0aaeff'};
    return units.map((unit, index) => {
        // let pickUnit = brandsLogo.find(b => b.name.toUpperCase() === unit.BRAND_NAME.toUpperCase());
        let pickUnit = {};
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
            pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
            pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
            pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
        }else{
            pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
        }
        return <li key={index}>
            <div className='orgznSiteTreeLiDiv' style={unitStyle}>
                <Link to="#"b style={unitLinkStyle}>
                    <div>
                        <span className="treeImg">
                            <img src={pickUnit ? pickUnit.img : bg} alt="" />
                        </span>
                        <span className="treeText deviceModel"><b>{unit.UNITID || ''}</b><b><FormattedMessage id="KC0863"/></b></span>
                        <span className="treeCountDispaly" >{units.length || ''} </span>
                    </div>
                </Link>
            </div>
        </li>
    });
}

const formOrgStructure = (linkedList, isInitial, ID = '', colorCode) => {
    if (!linkedList || !linkedList.length) return null;
    return (
        <ul class={isInitial ? '' : "collapse show"} id={isInitial ? '' : ID}>
            {linkedList.length && linkedList.map((linkedLevelValue, levelIndex) => {
                let level = linkedLevelValue.level_id;
                const nextLevel = level + 1;
                const id = linkedLevelValue.cust_gp_id + linkedLevelValue.parent_group_id + linkedLevelValue.category_id + linkedLevelValue.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                let picLocation;
                const location_type_Select = JSON.parse(linkedLevelValue.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return (
                    <li key={linkedLevelValue.group_name + levelIndex}>
                        <div class="orgznSiteTreeLiDiv">
                            <Link to="#" className={(linkedLevelValue.level_id === 5 && linkedLevelValue.units && linkedLevelValue.units.length > 0)
                                || (linkedLevelValue['level' + nextLevel] && linkedLevelValue['level' + nextLevel].length > 0)
                                ? 'treeNodeAvailable treeActive' : 'treeNodeAvailableNoLast treeActive'}>
                                <div>
                                    <span className="treeImg">
                                        <img src={linkedLevelValue.imagePath ? linkedLevelValue.imagePath : picLocation ? picLocation.img : bg} alt="" />
                                    </span>
                                    <span className="treeText deviceModel">
                                        <b>{linkedLevelValue.group_name || ''}</b>
                                    </span>
                                    {/* <span className="treeCountDispaly" >{linkedList.length || ''} </span> */}
                                </div>
                            </Link>
                            {linkedLevelValue && linkedLevelValue['level' + nextLevel] ?
                                <React.Fragment>
                                    <button class="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                    {formOrgStructure(linkedLevelValue['level' + nextLevel], false, leveClassName, colorCode)}
                                </React.Fragment>
                                : level === 5 && linkedLevelValue.units && linkedLevelValue.units.length ?
                                    <React.Fragment>
                                        <button class="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                        <ul className="collapse show" id={leveClassName}>
                                            {formUnits(linkedLevelValue.units, colorCode)}
                                        </ul>
                                    </React.Fragment>
                                    : null}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

const EventOrg = ({ selectedNotification, getBrandId, workflowDropdown, selectedWorkflowId }) => {
    const [linkList, setLinkList] = useState({});
    const [loader, setLoader] = useState(true);
    const currentWorkflow = workflowDropdown.filter((i) => i.id === selectedWorkflowId);
    const colorCode = currentWorkflow.length ? currentWorkflow[0].defaultColorCode ? currentWorkflow[0].defaultColorCode : '#aac8e3' : '#aac8e3';

    useEffect(() => {
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        const brandId = getBrandId(selectedNotification.brand)
        let headerVal = {
            "Cgids": localStorage.getItem('custGroupID'),
            'Content-Type': 'application/json',
            'useremail': loggedinUser,
            'basecountry': localStorage.getItem('basecountry'),
            'requestType': 'ANM_ORG_CHART',
            'modelFamilyName': selectedNotification.model || '',
            'brandids': brandId
        }
        http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: headerVal, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, async (data) => {
                    await setLinkList(data);
                    setLoader(false);
                });
            }
            else {
                await setLinkList(response.data);
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false);
        });
    }, [selectedNotification.model]);

    return (
        <React.Fragment>
            <div id="page-loader" className={loader ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div class="orgznSiteTreeOuter1">
                <div class="orgznSiteTree">
                    {linkList && linkList.level1 && formOrgStructure(linkList.level1, true, '', colorCode)}
                </div>
            </div>
        </React.Fragment>);
}

export default EventOrg;