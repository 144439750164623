import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { BaseService, ReportManagement, UnitInfo } from '../../../service/api';
import { format, addDays } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import NumberFormat from 'react-number-format';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { CUSTOMER_ID } from '../../../utils/appConstants';
import { UserBrandList } from '../../controls/userBrandList';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;

class ProductionLineChart extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      productionData: [],
      totalProduction: 0,
      trend: -1,
      filterType: "Month",
      sd: '',
      ed: '',
      startDate: '',
	  brandsAvailable: false,
      endDate: '',
      sdmindate: todaysDate,
      sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      edmaxdate: todaysDate,
      edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      legendText: "",
      showBlueDot: false,
      datapickflag: false,
      loading: true
    }
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  //Productions    
  handleChangeStart(date) {
    this.setState({
      startDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText: selectedtext };
      this.onDropDownHandler(e);
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText: selectedtext };
      this.onDropDownHandler(e);
    });
  }

  async componentDidMount() {
    const brandListArr = await JSON.parse(localStorage.getItem('availableUnitsBrand'));
    if (brandListArr && brandListArr.length > 0) {
      this.setState({
        brandsAvailable: true
      });
    }
  }

  onBrandSelection(e, type = '') {
    let brandid = 0;
    let legendText = "";
    brandid = e.target.text === "CONVOTHERM" ? 6 : e.target.text === "FRYMASTER" ? 3 : e.target.text === "GARLAND" ? 9
      : e.target.text === "MERCO" ? 2 : e.target.text === "MERRYCHEF" ? 1 : e.target.text === "FRYMASTER-UHCTHD" ? 11 : 4;
    legendText = e.target.text === "CONVOTHERM" ? 'KC0093' : e.target.text === "CREM" ? "KC0176"
      : (e.target.text === "MERCO" || e.target.text === "FRYMASTER-UHCTHD") ? "KC0096" : "KC0095";
    this.setState({
      brand_id: brandid,
      brand_name: e.target.text,
      legendText: legendText
    });
    this.fetchData(brandid, type);
    if (e.target.text === "CREM") {
      this.getExcludeProductDetails(brandid);
    } else {
      localStorage.setItem('excludedProductList', false);
    }
    this.setState({
      brand_id: brandid,
    })
  }

  getExcludeProductDetails = async (brandid) => {
    const response = await http.get(UnitInfo.excludeProduct,
      {
        headers: {
          'Content-Type': 'application/json',
          'cid': CUSTOMER_ID,
          'brandid': brandid,
        },
        data: {}
      })
    let excludeProduct = response.data && response.data[0]
    let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;
    if (checklength > 0) {
      this.setState({
        showBlueDot: true,
      })
    }
  }

  getReportData(text, startDate, date, brand_id) {
    this.setState({ loading: true })
    const header = this.getHeaders(text, startDate, date, brand_id);
    let widgetId = null;
    let url;
    if (brand_id === 1) {
      widgetId = 75;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 6) {
      widgetId = 76;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 3) {
      widgetId = 74;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 2) {
      widgetId = 77;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 11) {
      widgetId = 103;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if(brand_id === 9)
    {
        widgetId = 113;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else {
      url = `${ReportManagement.countroot}`
    }

    this.getData(url, header, brand_id).then(response => {
      this.setState({
        totalProduction: response.totalProduction,
        trend: response.trend,
        loading: false
      }, () => {
        this.renderChartData(response.productionData, response.trend, this.state.legendText, text);
      });
    }).catch(err => {
      //this.renderChartData([]);
    });
  }

  fetchData(brand_id, text) {
    this.setState({ filterType: text })
    if (text === "Date Range") {
      todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
      weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
    }
    else {
      todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
      weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
    }

    if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date")) {
      this.getReportData(text, weekDateFormatted, todaysDateFormatted, brand_id);
    }
  }

  onDropDownHandler(e) {
    const filterType = e.nodeText;
    this.setState({
      filterType,
      datapickflag: filterType === 'Date Range',
    });
    this.fetchData(this.state.brand_id, filterType);
  }

  getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', brand_id = '') {
    let filterType;
    let groupBy;
    switch (type) {
      case 'Week':
        filterType = brand_id === 4 ? 'custom' : 'weekly';
        groupBy = 'date_created';
        break;
      case 'Year':
        filterType = 'yearly';
        groupBy = 'month';
        break;
      case 'Day':
        filterType = 'daily';
        groupBy = 'date_created';
        break;
      case 'Quarter':
        filterType = 'quarterly';
        groupBy = 'month';
        break;
      case 'Date Range':
        filterType = 'custom';
        groupBy = 'date_created';
        break;
      default:
        filterType = 'monthly';
        groupBy = 'date_created';
    }

    let header;

    let cgids = "";

    if (this.props.orgid && this.props.orgid.length > 0) {
      cgids = this.props.orgid;
    }
    if (this.props.cgid && this.props.cgid.length > 0) {
      cgids = this.props.cgid;
    }

    if (filterType === 'custom' || filterType === 'weekly') {
      startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
      endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'groupby': groupBy
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'showtrend': filterType === 'weekly' ? 'true' : 'false'
        };
      }
    }
    else {
      startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'groupby': groupBy
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'showtrend': 'true'
        };
      }
    }
    if (this.props.tags && this.props.tags.length > 0) {
      header['tags'] = this.props.tags.join(',');
    }
    return header;
  }

  getData(url, header, brand_id) {
    return new Promise((resolve, reject) => {
      http.get(url,
        {
          headers: header,
          data: {}
        })
        .then(response => {
          if (brand_id === 4) {
            resolve({
              productionData: response.data.productionData ? response.data.productionData : [],
              totalProduction: response.data.totalProduction ? response.data.totalProduction : 0,
              trend: response.data.trend ? response.data.trend : -1,
            })
          }
          else {
            resolve({
              productionData: response.data.productionData ? response.data.productionData : [],
              totalProduction: response.data.content ? response.data.content[0] ? response.data.content[0].value : 0 : 0,
              trend: response.data.content ? response.data.content[2] ? response.data.content[2].value : -1 : -1,
            })
          }
        })
        .catch(error => {
          reject({
            productionData: [],
            totalProduction: 0,
            trend: -1
          })
        });
    });
  }

  renderChartData = (chartData, trend, legendText, type) => {
    if (chartData && chartData.length > 0) {
      let chart = am4core.create("revenueChartdivAllLocations", am4charts.XYChart);
      let separator = localStorage.getItem("thousandSeparator");
      chartData.forEach((item) => {
        // Tooltip to show data with . as thousand separator
        item.tooltipNum = separator === "." ? item.count.toString().replace(',', separator) : item.count;
        item.dateText = (type === "Quarter" || type === "Year") ? format(item.date, 'MMMM YYYY')
          : format(item.date, 'M/D/YYYY');
      });

      chart.data = chartData && chartData.length > 0 ? chartData : [];
      this.chart = chart;
      chart.zoomOutButton.disabled = true;
      chart.cursor = new am4charts.XYCursor();
      chart.hiddenState.properties.opacity = 1; // this creates initial fade-in

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = (type === "Day" || type === "Month") ? 1 : 6;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.inside = false;
      categoryAxis.renderer.line.strokeOpacity = 0;
      categoryAxis.renderer.line.strokeWidth = 0;
      categoryAxis.renderer.line.stroke = am4core.color("#FFF");
      categoryAxis.tooltipText = "{dateText}";
      categoryAxis.renderer.tooltipLocation = 0;
      // categoryAxis.tooltip.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.min = 0;
      valueAxis.tooltip.disabled = true;

      //Configure Ticks
      if (chart.data.length > 0) {
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.length = 5;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
        categoryAxis.renderer.ticks.template.strokeWidth = 1;
      }

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = "date";
      series.dataFields.valueY = "count";
      series.dataFields.tootltipY = "tooltipNum";
      series.name = reactIntl.formatMessage({id: legendText}); 
      series.tooltipText = reactIntl.formatMessage({id: legendText})+ ": [bold]{tootltipY}[/]"; 

      series.tooltip.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem.dataContext && target.dataItem.dataContext.tooltipNum) {
          return reactIntl.formatMessage({ id: legendText }) + `: [bold]${target.dataItem.dataContext.tooltipNum}[/]`;
        } else {
          return reactIntl.formatMessage({ id: legendText }) + `: `;
        }
      });
      // series.name = legendText;
      // series.tooltipText = legendText + ": [bold]{tootltipY}[/]";

      let gradient = new am4core.LinearGradient();
      gradient.rotation = 90;
      if (trend === '1') {
        gradient.addColor(am4core.color("#1f6387"));
        gradient.addColor(am4core.color("#293b47"));
        series.fill = gradient;
        series.stroke = am4core.color("#0ea1e9");
      }
      else if (trend === '0') {
        gradient.addColor(am4core.color("#616366"));
        gradient.addColor(am4core.color("#424447"));
        series.fill = gradient;
        series.stroke = am4core.color("#919396");
      }
      series.strokeWidth = 1;
      series.fillOpacity = 0.8;

      // Series Tooltip Test and bg color
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#FFFFFF");
      series.tooltip.label.fill = am4core.color("#000000");
    }
    else {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    let { totalProduction, trend, loading, brandsAvailable } = this.state;
    if (totalProduction === undefined) {
      totalProduction = 0;
    }

    let newNumberFormat =  <NumberFormat value={totalProduction} displayType={'text'} thousandSeparator={true} />
    if (localStorage.getItem("thousandSeparator") === "."){
      newNumberFormat =  <NumberFormat value={totalProduction} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
    }
    return (
      <div className="colmDi brandDropdown">
        <h4><FormattedMessage id='KC0043' />
        &nbsp;{this.state.showBlueDot ? <span class="blueDot" title={reactIntl.formatMessage({id:"KC1037"})}></span> : ""}
          <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} maxDaysRequired={30}/>
          { brandsAvailable && <UserBrandList onBrandSelection={(e) => this.onBrandSelection(e, this.state.filterType)} brandLabelRequired={true}/> }
        </h4>
        <div className="colmDiVal">
          { brandsAvailable && loading && <li className="spinner"></li> } 
          { !loading && <div className="valH1Div">           
            {newNumberFormat} &nbsp;
          <span className={trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}><FormattedMessage id={this.state.legendText}/></span></div>}
          {/* <div className={this.state.filterType === "Date Range" ? "displaynone" : "valH5Div"}>
              prior {this.state.filterType.toLowerCase()}: 50
          </div> */}
        </div>
        <div id="revenueChartdivAllLocations" ></div>
      </div>
    );
  }
}

export default ProductionLineChart;
