import React, { Component } from 'react';
import { Draggable, Droppable } from 'react-drag-and-drop'
import $ from 'jquery';
import { smartTagMangement } from '../../service/api';
import http from '../../service/httpService';
import ErrorMoal from '../modal/error-model';
import { FormattedMessage } from 'react-intl';


class SmartTagDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            orgSTagList: [],
            unitTagList: [],
            dragOrgList: [],
            dragUnitList: [],
            searchText: '',
            numberOfSteps: [{ org: [], unit: [] }],
            error_modal:{
                open: false,
                message: ''
            }
        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.getOrgSTagDetails();
        const windowHeight = $(window).height();
        const $tableBody = $('.orgChartUnitBoxO');
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + 180));
    }

    getOrgSTagDetails = async () => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.filterUnitDetails;
        let custGroupID = localStorage.getItem("selected_cgid") ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID.length > 0 ? Array.prototype.map.call(custGroupID, m => m.cust_gp_id) : localStorage.getItem("custGroupID");
		}
        let headers =  {
            'Content-Type': 'application/json',
            'customerid': localStorage.getItem("customerId"),
            'cgid': cgids,
            'brandid': Number(localStorage.getItem('selectedbrandID')),
            'provisionedunits': true,
        }
        let selectedFamilyIDs = localStorage.getItem("selectedFamilyID") ? localStorage.getItem("selectedFamilyID") : "";
        if (selectedFamilyIDs){
            if (localStorage.getItem("selectedbrandID")=== '6'){
                selectedFamilyIDs=JSON.parse(localStorage.getItem("selectedFamilyID"));
                headers['modelfamilyids'] = Array.prototype.map.call(selectedFamilyIDs, m => m.ID).toString(); 
            } else {
                headers['modelfamilyids']= selectedFamilyIDs;
            }
        }
        if(localStorage.getItem("modelIdValue")){
            headers['model']= localStorage.getItem("modelIdValue");
        }
        http.get(url, {
            headers, data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({ 
                    orgSTagList: response.data.data ? response.data.data.orgSmartTags : [],
                    unitTagList: response.data.data ? response.data.data.unitSmartTags : [], 
                    loaderIconVisible: false 
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onDrop = (data, index) => {
        let numberOfSteps = this.state.numberOfSteps;
        if (numberOfSteps[index]['org'].length < 5) {
            if(numberOfSteps[index]['org'].includes(data.org)){
                return;
            }
            numberOfSteps[index]['org'].push(data.org);
            this.setState({ numberOfSteps, dragOrgList: [...this.state.dragOrgList, data.org] })
        }else{
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Maximum 5 tags only allowed for selection.'
                }
            })
        }
    }

    onDropUnit = (data, index) => {
        let numberOfSteps = this.state.numberOfSteps;
        if (numberOfSteps[index]['unit'].length < 5) {
            if(numberOfSteps[index]['unit'].includes(data.unit)){
                return;
            }
            numberOfSteps[index]['unit'].push(data.unit);
            this.setState({ numberOfSteps, dragUnitList: [...this.state.dragUnitList, data.unit] })
        }else{
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Maximum 5 tags only allowed for selection.'
                }
            })
        }
    }

    addStep = () => {
        let numberOfSteps = this.state.numberOfSteps;
        if (numberOfSteps.length < 5) {
            numberOfSteps.push({ org: [], unit: [] });
            this.setState({ numberOfSteps })
        }
    }

    deleteStep = (index) => {
        let numberOfSteps = this.state.numberOfSteps;
        if (numberOfSteps.length === 1) {
            return;
        }
        let dragOrgList = this.state.dragOrgList;
        let dragUnitList = this.state.dragUnitList;
        let orgTags = numberOfSteps[index]['org'];
        let unitTags = numberOfSteps[index]['unit'];
        for (let index = 0; index < orgTags.length; index++) {
            const element = orgTags[index];
            const indexValue = dragOrgList.findIndex((i) => Number(i) === Number(element));
            dragOrgList.splice(indexValue, 1);
        }
        for (let index = 0; index < unitTags.length; index++) {
            const element = unitTags[index];
            const indexValue = dragUnitList.findIndex((i) => Number(i) === Number(element));
            dragUnitList.splice(indexValue, 1);
        }
        numberOfSteps.splice(index, 1);
        this.setState({ numberOfSteps, dragOrgList, dragUnitList })
    }

    reset = () => {
        // the popup control alone will reset
        this.setState({
            dragOrgList: [],
            dragUnitList: [],
            searchText: '',
            numberOfSteps: [{ org: [], unit: [] }]
        })
    }

    accept = () => {
        const { numberOfSteps } = this.state;
        if (numberOfSteps && numberOfSteps.length) {
            this.props.applyFilterChanges(numberOfSteps);
        }
    }

    deleteItem = (tag, stepNumber, type) => {
        let steps = this.state.numberOfSteps;
        const findIndex = steps[stepNumber][type].findIndex((i) => Number(i) === Number(tag.id));
        steps[stepNumber][type].splice(findIndex, 1);

        let stateKey = type === "org" ? "dragOrgList" : "dragUnitList";
        let dataValue = this.state[stateKey];
        const indexValue = dataValue.findIndex((i) => Number(i) === Number(tag.id));
        dataValue.splice(indexValue, 1);

        this.setState({ numberOfSteps: steps, [stateKey]: dataValue });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onClose = () => {
        this.setState({
            searchText: ""
        });
    };

    render() {
        const { dragOrgList, dragUnitList, numberOfSteps, orgSTagList, unitTagList, searchText, error_modal } = this.state;
        let orgListValue = this.state.orgSTagList;
        orgListValue = orgListValue && orgListValue.length > 0 ? orgListValue : [];
        const searchOrgListValue = orgListValue && orgListValue.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            }
        );
        orgListValue = searchOrgListValue;

        let unitListValue = this.state.unitTagList;
        unitListValue = unitListValue && unitListValue.length > 0 ? unitListValue : [];
        const searchUnitListValue = unitListValue && unitListValue.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            }
        );
        unitListValue = searchUnitListValue;
        return (
            <React.Fragment>
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header modal-header-danger">
                            <FormattedMessage id="KC2356" /> <button type="button" onClick={() => this.onClose()} class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                        </div>
                        <div class="modal-body">
                            <div className="transferModalW">
                                <div className="transferModalL">
                                    <div className="ToggleSerarchBar">
                                        <input className="form-control tagsSearch" type="text" value={searchText} onChange={(e) => this.setState({ searchText: e.target.value })} placeholder="Search Smart Tags..." />
                                    </div>

                                    <h4>Select OrgChart Smart Tags</h4>
                                    <div className="transferMTableBar">
                                        {orgListValue && orgListValue.length ? orgListValue.map((org, index) => {
                                            if (org.tagName.toUpperCase() && !org.tagName.toUpperCase().includes(searchText.toUpperCase())) {
                                                return;
                                            }
                                            return (
                                                <div className="transferMTable">
                                                    <ul key={index} className="tBody">
                                                        <li>
                                                            <Draggable type="org" data={org.id} className={`tagTypeLocation ui-draggable ui-draggable-handle ${dragOrgList.includes(org.id.toString()) ? "dragDiv" : ""}`}>
                                                                <span className={dragOrgList.includes(org.id.toString()) ? "dragged" : "draggable"}>{org.tagName}</span>
                                                            </Draggable>
                                                        </li>
                                                        <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                            this.props.getInfoView(org, "orgList")
                                                        }}>&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            )
                                        }) : <div className='alignmentCenter'>{`No Smart Tag Found`}</div>}
                                    </div>

                                    <h4>Select Unit Smart Tags</h4>
                                    <div className="transferMTableBar">
                                        {unitListValue && unitListValue.length ? unitListValue.map((unit, index) => {
                                            if (unit.tagName.toUpperCase() && !unit.tagName.toUpperCase().includes(searchText.toUpperCase())) {
                                                return;
                                            }

                                            return (
                                                <div className="transferMTable">
                                                    <ul key={index} className="tBody">
                                                        <li>
                                                            <Draggable type="unit" data={unit.id} className={`tagTypeUnit ui-draggable ui-draggable-handle ${dragUnitList.includes(unit.id.toString()) ? "dragDiv" : ""}`}>
                                                                <span className={dragUnitList.includes(unit.id.toString()) ? "dragged" : "draggable"}>{unit.tagName}</span>
                                                            </Draggable>
                                                        </li>
                                                        <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                            this.props.getInfoView(unit, "unitList")
                                                        }}>&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            )
                                        }) : <div className='alignmentCenter'>{`No Smart Tag Found`}</div>}
                                    </div>

                                </div>
                                <div className="transferModalR">
                                    <div className="orgChartUnitBoxO">
                                        {numberOfSteps && numberOfSteps.length ? numberOfSteps.map((step, index) => {
                                            const stepNumber = index;
                                            return (
                                                <div key={index} className="orgChartUnitBox">
                                                    {numberOfSteps.length === 1 ? null : <button type="button" onClick={() => this.deleteStep(index)} className="btn deleteButton deleteActive">&nbsp;</button>}
                                                    <div className="orgChartUnitRow">
                                                        <Droppable
                                                            types={['org']} // <= allowed drop types
                                                            onDrop={(event) => this.onDrop(event, index)} className="orgChartSTBox ui-droppable ui-sortable" id={"orgChartSTBox" + index}>
                                                            {step['org'] && step['org'].length ? step['org'].map((org, index) => {
                                                                const tagName = this.state.orgSTagList.filter((i) => Number(i.id) === Number(org));
                                                                return (
                                                                    <div key={index} className="tagTypeLocation">
                                                                        <span>{tagName && tagName.length ? tagName[0].tagName : ''}</span>
                                                                        <button type="button" class="btn dropedItemDelete" onClick={() => this.deleteItem(tagName[0], stepNumber, 'org')}>&nbsp;</button>
                                                                    </div>
                                                                );
                                                            }) :
                                                                <>
                                                                    {index === 0 ? <div class="placeholderD">
                                                                        <h5>Drag and drop OrgChart Smart Tag here.</h5>
                                                                    </div> : null}
                                                                </>
                                                            }
                                                        </Droppable>
                                                        <Droppable
                                                            types={['unit']} // <= allowed drop types
                                                            onDrop={(event) => this.onDropUnit(event, index)} className="unitsSTBox ui-droppable ui-sortable" id={"unitsSTBox" + index}>
                                                            {step['unit'] && step['unit'].length ? step['unit'].map((unit, index) => {
                                                                const tagName = this.state.unitTagList.filter((i) => Number(i.id) === Number(unit));
                                                                return (
                                                                    <div key={index} className="tagTypeUnit">
                                                                        <span>{tagName && tagName.length ? tagName[0].tagName : ''}</span>
                                                                        <button type="button" class="btn dropedItemDelete" onClick={() => this.deleteItem(tagName[0], stepNumber, 'unit')}>&nbsp;</button>
                                                                    </div>
                                                                );
                                                            }) :
                                                                <>
                                                                    {index === 0 ? <div class="placeholderD">
                                                                        <h5>Drag and drop Unit Smart Tag here.</h5>
                                                                    </div> : null
                                                                    }
                                                                </>
                                                            }
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            )
                                        }) : null}

                                        {numberOfSteps.length <= 4 && <button type="button" onClick={() => this.addStep()} className={numberOfSteps.length > 5 ? "btn addButton disabled" : "btn addButton"}>&nbsp;</button>}

                                    </div>
                                    <button type="button" onClick={this.accept} className={`btn applyBtn ${dragOrgList.length === 0 && dragUnitList.length === 0 ? "disabled" : ""}`}>Apply</button>
                                    <button type="button" onClick={this.reset} className={`btn resetBtn ${dragOrgList.length === 0 && dragUnitList.length === 0 ? "disabled" : ""}`}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}
export default SmartTagDrop;