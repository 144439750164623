import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = null;
let currentUnit;

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

class TotalCooksPerPlatenChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      errorStatus: [],
      filterType: 'Month',
      startDate: '',
      endDate: '',
      widgetInformation: {},
      currentValue: 0,
      priorValue: 0,
      trend: -1,
      classificationValue: null,
      unitTime: '',
      sdmindate: todaysDate,
      sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      edmaxdate: todaysDate,
      edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      datapickflag: false
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  componentDidMount() {
    this.setState({
      filterType: "Month",
    });
    this.getReportData("Month", todaysDateFormatted, weekDateFormatted);
  }
  getReportData(type, fromdate, todate) {
    currentUnit = this.props.properties.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
    unitID = currentUnit.UNITID;console.log("currentUnit.PRODUCT_NUMBER===>",currentUnit.PRODUCT_NUMBER);
    
    brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
    let url = '';
    let filterType = '';
    let header = {};
    switch (type) {
      case 'Week':
        filterType = 'weekly';
        break;
      case 'Year':
        filterType = 'yearly';
        break;
      case 'Day':
        filterType = 'daily';
        break;
      case 'Quarter':
        filterType = 'quarterly';
        break;
      case 'Date Range':
        filterType = 'custom';
        break;
      default:
        filterType = 'monthly';
    }

    if (brandID.toString() === "9" && this.props.widgetID === "90") {
      url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;

      if (filterType === 'weekly' || filterType === 'custom') {
        header = {
          'Content-Type': 'application/json',
          'unitid': unitID,
          'startdate': todate,
          'enddate': fromdate,
          'filtertype': filterType,
          'showtrend': "false"
        };
      }
      else {
        header = {
          'Content-Type': 'application/json',
          'unitid': unitID,
          'reqDate': fromdate,
          'filtertype': filterType,
          'showtrend': "false"
        };
      }
    }
    else if (brandID.toString() === "9" && this.props.widgetID === "108") {
      url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;

      if (filterType === 'weekly' || filterType === 'custom') {
        header = {
          'Content-Type': 'application/json',
          'unitid': unitID,
          'startdate': todate,
          'enddate': fromdate,
          'filtertype': filterType,
          'showtrend': "false"
        };
      }
      else {
        header = {
          'Content-Type': 'application/json',
          'unitid': unitID,
          'reqdate': fromdate,
          'filtertype': filterType,
          'showtrend': "false"
        };
      }
    }

    this.getData(url, header);
}

  getData(url, header) {
    return new Promise((resolve, reject) => {
      http
        .get(url,
          {
            headers: header,
            data: {}
          })
        .then(response => {
          resolve({
            widgetInformation: response.data,
            filtertypeInfo: response.filtertype
          });
          this.setState({
            isLoaded: true,
            errorStatus: response.data
            }, ()=>{
              this.renderChart(response.data);
            });
        })
        .catch(error => {
          reject({
            widgetInformation: {}
          })
        });
    });
  }

  handleChangeStart(date, mindate) {
    this.setState({
      startDate: date,
      datapickflag: true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText:selectedtext };
      this.onDropDownHandler(e, 'PerPlaten');
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      datapickflag: true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText:selectedtext };
      this.onDropDownHandler(e, 'PerPlaten');
    });
  }

  fetchData(text) {
   
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else if (text === 'Week') {
            weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

            var startDateMonth = getMonth(new Date(weekDateFormatted));
            var endDateMonth = getMonth(new Date(todaysDateFormatted));

            if (startDateMonth !== endDateMonth)
            {
                weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }
    
    this.getReportData(text, todaysDateFormatted, weekDateFormatted);
}

  onDropDownHandler(e, type = '') {
    this.fetchData(e.nodeText);
    this.setState({
      filterType: e.nodeText,
      datapickflag: e.nodeText === 'Date Range'
    });
  }

  renderChart = (data) => {
    if (data && data.content && data.content.length > 0) {
      let chart = am4core.create("garlandPlatenchartdiv", am4charts.PieChart);
      chart.data = data.content.map((e) => {
          e.count = parseInt(Math.round(e.count));
          e.lane_id = `Platen ${e.lane_id}`
          return e;
      });
      this.chart = chart;
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "count";
      pieSeries.dataFields.category = "lane_id";
      pieSeries.slices.template.propertyFields = "pulled";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.colors.list = [
        am4core.color("#3a92df"),
        am4core.color("#91c2ed"),
        am4core.color("#d3e7f8")
      ];

	  var legend = document.getElementById("platenCountlegend");
      chart.events.on("ready", function(event) {
		      legend.innerHTML="";
          pieSeries.dataItems.each(function(row, i) {
          var item = document.createElement("div");
          var title = document.createElement("div");
          title.className = "title";
          title.innerHTML = row.category;
          item.appendChild(title); 
          var value = document.createElement("div");
          value.className = "value";
          value.innerHTML = row.value;
          item.appendChild(value);
          legend.appendChild(item);
        });
      });
    }
    else {
      if (this.chart) {
        this.chart.data = data;
      }
    }

  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { errorStatus  } = this.state;
    const errorStatusList = errorStatus &&  errorStatus.content &&  errorStatus.content.length > 0 ? errorStatus.content : [];
    let objCount = [];
    objCount = [...errorStatusList].find(function (element) {
      return element.count > 0 && element.lane_id !== "No Platen(s)";
    });
    let displayChartClass = "colmDiVal";
    let displayClass = "colmDiVal displaynone";
    if (objCount === [] || objCount === undefined) {
      displayClass = "colmDiVal";
      displayChartClass = "colmDiVal displaynone";
    }
    return (
      <React.Fragment>
      <div className={this.props.classValue}>
          <div className="colmDi">
              <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                  &nbsp; <span className="red-txt">{/*errormsgTotalDrinks*/}</span><span className="cornerIcon"></span>                            
                  <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} 
                  maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} 
                  endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} 
                  handleChangeStartProps={(date) => this.handleChangeStart(date)} 
                  handleChangeEndProps={(date) => this.handleChangeEnd(date)} 
                  OnChange={(e) => this.onDropDownHandler(e, "PerPlaten")} 
                  filterType={this.state.filterType} />
              </h4>
          <div className={displayChartClass}>
            <div id="garlandPlatenchartdiv"></div>
            <div class="platenCount">
				<div id="platenCountlegend" class="platenCountTable">
				</div>
            </div>
          </div>
          { errorStatusList && errorStatusList.length === 0 ? <div className={displayClass}>
            <div class="valH1WStatusDiv noErrorStatus">
              <FormattedMessage id="KC1817"/>
              <span class="valH1WStatusSpan">
                  &nbsp;
              </span>
              </div>
            </div>: null
          }
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default TotalCooksPerPlatenChart;
