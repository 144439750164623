import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery'
import ConnectionFailedMsg from './messages/ConnectionFailedMsg';
import ConnectionSuccessMsg from './messages/ConnectionSuccessMsg';
import UnitNotRegisterMsg from './messages/UnitNotRegisterMsg';
import { getBrandsList } from '../../actions/connectivityTest/getBrandListAction';
import { getDeviceConnectivityStatus, serialNumberChanged, brandChanged, verifyOtherDevice } from '../../actions/connectivityTest/getDeviceConnectivityStatusAction';


class ConnectivityPageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            errors: {
                serialNumber: '',
                selectedBrand: '',
                recaptcha: ''
            },
            isCaptchaVerified: false,
        };
        this._reCaptchaRef = React.createRef();
    }

    updateDimensions = () => {
        $('.mCustomScrollbar').mCustomScrollbar();
    }

    componentDidUpdate() {
        this.updateDimensions();
    }
    componentDidMount() {
        $('body').removeClass("deviceRegtrnBg");
        $('body').removeClass("loginBg");
        $('body').addClass("subscriptionBg");
        this.props.getBrandsList();
        this.updateDimensions();
        setTimeout(() => {
            var width = $('.g-recaptcha').parent().width();
            var scale = width / 304;
            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
            $('.g-recaptcha').css('display', 'block');
        }, 100);
    }



    checkConnectivityStatus = () => {
        const { brandName, serialNumber } = this.props;
        this.props.getDeviceConnectivityStatus(brandName, serialNumber)
    }

    verifyCallback = (recaptchaToken) => {
        if (recaptchaToken) {
            this.setState({
                isCaptchaVerified: true,
                errors: {
                    ...this.state.errors,
                    recaptcha: ''
                }
            });
        }
    }

    onExpired = () => {
        this.setState({ isCaptchaVerified: false })
    }

    verifyOtherDevice = () => {
        this.props.verifyOtherDevice();
    }

    renderStatus = (deviceStatus) => {
        switch (deviceStatus) {
            case "IMS2001":
                return <ConnectionSuccessMsg onClick={() =>this.verifyOtherDevice() } />
                break;
            case "IMS2002":
                return <ConnectionFailedMsg />
                break;
            case "IMS4004":
                return <ConnectionFailedMsg />
                break;
            case "IMS4013":
                return <UnitNotRegisterMsg />
                break;
            default:
                return;
                break;
        }
    }


    render() {
        const { errors, isCaptchaVerified } = this.state;
        const { brandList, loading, serialNumber, brandName, isLoading, deviceStatus, showCaptcha,showButton, connectionSuccess } = this.props;
        return (
            <div>
                <div className={loading ? "loadingProgress showloader" : isLoading ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href="/">&nbsp;</a>
                </nav>
                <div id="page-content-wrapper">
                    <div className="conectivityWrapper">
                        <div className="conectivityWrapperL">
                            <h2>Device Connectivity Test</h2>
                            <div className="connectivityForm">
                                <ul>
                                    <li>
                                        <label>Brand</label>
                                        {!connectionSuccess ? <select className="form-control" value={brandName} onChange={(e) => this.props.brandChanged(e.target.value)}>
                                            <option value="">Select</option>
                                            {brandList && brandList.length ? brandList.map((brand, index) => {
                                                return (
                                                    <option key={index} value={brand.ID}>{brand.BRAND_NAME}</option>
                                                )
                                            }) : null}
                                        </select>:
                                        <div className="valueLabel">{brandList.filter(e => e.ID == brandName).map(e => e.BRAND_NAME)}</div>}
                                    </li>
                                    <li>
                                        <label>Serial Number</label>
                                        {!connectionSuccess ? <input type="text" maxLength={30} className="form-control" placeholder="Enter Value" value={serialNumber} onChange={(e) => this.props.serialNumberChanged(e.target.value)} />
                                            : <div className="valueLabel">{serialNumber}</div>}
                                    </li>
                                    {showButton ? <li>
                                        <button className="btn submitBtn" type="button" disabled={isCaptchaVerified && brandName !== '' && serialNumber.trim() !== '' ? false : true} onClick={() => this.checkConnectivityStatus()}>Test Connectivity</button>
                                    </li> : <li />}
                                    {
                                        showCaptcha ?
                                            <li className="form-group">
                                                <div className="g-recaptcha">
                                                    <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} onExpired={this.onExpired} />
                                                </div>
                                                {errors["recaptcha"] && <div className="errorMsg">{errors.recaptcha}</div>}
                                            </li> : <li />
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="conectivityWrapperR">
                            {deviceStatus ? this.renderStatus(deviceStatus)
                                :
                                <div id="resultWillbeD">
                                    <h2>&nbsp;</h2>
                                    <div className="connectivitySrlBar">
                                        <div className="resultWillbeD">Result will be displayed in this area.</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        brandList: state.brandList.data,
        loading: state.brandList.isFetching,
        serialNumber: state.connectivityState.serialNumber,
        brandName: state.connectivityState.brandName,
        isLoading: state.connectivityState.loading,
        deviceStatus: state.connectivityState.deviceStatus,
        showCaptcha: state.connectivityState.showCaptcha,
        showButton: state.connectivityState.showButton,
        connectionSuccess: state.connectivityState.connectionSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBrandsList,
        serialNumberChanged,
        brandChanged,
        getDeviceConnectivityStatus,
        verifyOtherDevice
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectivityPageView);
