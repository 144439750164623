import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactDragListView from "react-drag-listview";
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class CookbookRecipeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedCategoryName: "Category",
            recipeList: []
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        //this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let selectedCategory = localStorage.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
            let recipeDetails = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID === selectedCategoryObj.subCategory.ID);
            let finalRecipeList = recipeDetails ? recipeDetails[0].recipe_items && recipeDetails[0].recipe_items.filter(item => item.length == undefined) : [] ;
            this.setState({
                selectedCategoryName: selectedCategoryObj.subCategory.categoryName,
                recipeList: finalRecipeList
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    // onDragEnd(result) {
        // // dropped outside the list
        // if (!result.destination) {
            // return;
        // }

        // const items = reorder(
            // this.state.recipeList,
            // result.source.index,
            // result.destination.index
        // );

        // this.setState({
            // recipeList: items,
        // });

        // let selectedCategory = localStorage.getItem("selectedCategory");
        // let selectedCategoryObj = JSON.parse(selectedCategory);

        // let categoryDetails = localStorage.getItem("resourceDetails");
        // let categoryDetailsObj = JSON.parse(categoryDetails);
        // var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
        // var subFoundIndex = categoryDetailsObj[foundIndex].subCategory.findIndex(c => c.ID === selectedCategoryObj.subCategory.ID);
        // categoryDetailsObj[foundIndex].subCategory[subFoundIndex]['recipe_items'] = items;

        // localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
    // }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/cookbookSubCategoryList",
            isEdit: false,
            previouspath: "/cookbookRecipeList"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { recipeList, selectedCategoryName } = this.state;
        recipeList = recipeList && recipeList.length > 0 ? recipeList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;
		const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.recipeList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ recipeList: data });

                let selectedCategory = localStorage.getItem("selectedCategory");
                let selectedCategoryObj = JSON.parse(selectedCategory);

                let categoryDetails = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetails);
                var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
                var subFoundIndex = categoryDetailsObj[foundIndex].subCategory.findIndex(c => c.ID === selectedCategoryObj.subCategory.ID);
                categoryDetailsObj[foundIndex].subCategory[subFoundIndex]['recipe_items'] = data;

                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
            },
            nodeSelector: 'li',
            handleSelector: 'div .cbcategoryBox',
            lineClassName: 'global-drag-line',
        };

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    {selectedCategoryName}
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
											{/* Bug 86256: MCF All recipe re-ordering not working */}
                                                    <div className="cbdnd">
                                                        <div className="rcListD actionIcon">
                                                            <Link to="/cookbookAddRecipes" title="Add/Remove Recipe" onClick={this.addSubCategory}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/menuEditButton.png")} /></span>
                                                                <span class="navTitle">Add/Remove <p>Recipe</p></span>
                                                            </Link>
                                                        </div>
                                                        <ReactDragListView {...dragProps}>
                                                            {this.state.recipeList && this.state.recipeList.length > 0 && this.state.recipeList.map((item, index) => (
                                                                <li>
                                                                    <div className='rcListD'>
                                                                        <div class="cbcategoryBox">
                                                                            <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")} alt="" /></span>
                                                                            <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ReactDragListView>
                                                    </div>
                                                 {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                                     <Droppable droppableId="droppable" direction="horizontal">
                                                         {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                class="cbcategory"
                                                            >
                                                                <ul>
                                                                    <li class="actionIcon"> 
                                                                        <Link to="/cookbookAddRecipes" title="Add/Remove Recipe" onClick={this.addSubCategory}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/menuEditButton.png")} /></span>
                                                                            <span class="navTitle">Add/Remove <p>Recipe</p></span>
                                                                        </Link>
                                                                    </li> 
                                                                    {recipeList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.LIBRARY_NAME} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")}  alt=""/></span>
                                                                                        <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext> */}
                                                {/* <div class="cbcategory">
                                                    <ul>
                                                        <li className="addCategoryLi">
                                                            <div class="cbcategoryBox">
                                                                <Link to="/cookbookAddRecipes" class="navIcon" onClick={this.addSubCategory}><img src={require("../../../../../images/recipecreation/menuEditButton.png")}  alt=""/></Link>
                                                                <Link to="/cookbookAddRecipes" class="navTitle" onClick={this.addSubCategory}>Add/Remove Recipe</Link>
                                                            </div>
                                                        </li>
                                                        {
                                                            recipeList.map((item, i) => {
                                                                return (
                                                                    <li>
                                                                        <div class="cbcategoryBox">
                                                                            <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL}  alt=""/></span>
                                                                            <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    {/* <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>*/}
                                      <button type="button" onClick={() => { this.onBackButtonClick() }} className={"btn saveCButton"} title={formatMessage({ id: 'KC1243' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div >
                </div >
            </React.Fragment >
        )
    }
}

export default injectIntl(CookbookRecipeList);
