import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as _ from 'lodash';
import { smartTagMangement } from '../../../service/api';
import http from '../../../service/httpService';
import ErrorMoal from '../../modal/error-model';

const SmartTagConfirmation = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [preAssinedUrlDetails, setPreAssinedUrlDetails] = useState("");
    const [sortColumn, setSortColumn] = useState({ path: "", order: "", sort_color: "#63778a" });
    const [confirmationData, setConfirmationData] = useState([]);    
    const [mode, setMode] = useState("");
    const [smartTagDetails, setSmartTagDetails] = useState(JSON.parse(localStorage.getItem("smartTagInfo")));
    const [error_modal, setError_modal] = useState({ open: false, message: "" });
    const [fileUploadData, setFileUploadData] = useState([]); 

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.STComfmtListSrlBar');
        $tableBody.css('height', windowHeight - ($tableBody.offset().top));
    }

    useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        if (props && props.history && props.history.location && props.history.location.state) {
            let result = props.history.location.state.dataList;            
            setMode(props.history.location.state.modeDetails)
            setConfirmationData(result.finalList);
            setFileUploadData(result);
        }
        updateDimensions();
    }, []);

    const onSort = sortColumn => {
        // setSortColumn(sortColumn);
    }

    const onFinishClick = async() => {
        let response = await proceedExcelValidation()
        if (response && response.url !== '#') {
            window.location = response.url;
        };
        props.history.push({
            pathname: "/smartTag/tagList",
        });
    }

    const proceedExcelValidation = () => {
        return new Promise(async (res, reject) => {
		$('.validatingFile').show();
		let tagDetails = JSON.parse(localStorage.getItem("smartTagInfo"));
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let userName = localStorage.getItem("userName");
		let value = tagDetails.unitTagDetails;  
        let result = fileUploadData;
		let postdata = {
			"fileName": result.fileName,
			"tagName": value.TagName,
			"description": value.TagDescription,
			"createdBy": loggedinUser,
			"createdByName": userName,
			"updatedBy": loggedinUser,
			"updatedByName": userName,
			"customerId": + localStorage.getItem("customerId"),
			"tagType": "UNIT",
            "unitId":result.unitId,
		};
		let url  = smartTagMangement.bulkUnit;
		let method = "post";
		if (mode === 'edit'){
			postdata = {
				"fileName": result.fileName,
				"tagName": value.TagName,
				"description": value.TagDescription,
				"updatedBy": loggedinUser,
				"updatedByName": userName,
				"customerId": + localStorage.getItem("customerId"),
				"tagType": "UNIT",
                yesUnitIds:result.yesUnitIds,
                noUnitIds:result.noUnitIds
			};
			const id = tagDetails.selectedRow.id;
			url  = smartTagMangement.createOrgTag +`/${id}`+"/bulk-update";
			method = "put";
		}
		await http[method](url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
                res(response.data.data)
			}
		}).catch(err => {
			setError_modal({ open: true, message: "No valid data found in Excel" });			
            res({});
		});
    })
	}

    const closeModel = () => {
		setError_modal({ open: false });
	}

    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            'placement': 'top'
        });
    })

    let columns = [
        { path: "", label: "#" },
        { path: "UNITID", labelStringID: "KC0104" },
        { path: "UNITNUMBER", labelStringID: "KC0105" },
        { path: "BRAND_NAME", labelStringID: "Brand" },
        { path: "MODEL_NAME", labelStringID: "Model Name" },
        { path: "location_name", labelStringID: "KC0050" },
        { path: "Status", labelStringID: "KC0523" },
    ];

    let smartTagName;
    if (smartTagDetails.mode === "edit"){
        smartTagName = smartTagDetails.selectedRow.tagName;
    } else {
        smartTagName = smartTagDetails.unitTagDetails.TagName;   
    }
    return (
        <React.Fragment>
            <div id="adminWrapper">
                <div class="toggleTransparent"></div>
                <div class="sideNavToggleTransparent"></div>
                <div class="userToggleTransparent"></div>
                <div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div class="unitRegistrationWrapper">
                        <div class="addUnitHeader">
                            <ul>
                                <li>
                                    <Link to="/smartTag/createUnitTag" title="Back" className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    {smartTagName} Smart Tag - Confirmation
                                </li>
                                <li>
                                    <Link to={"#"} onClick={() => onFinishClick()} title="Finish" className="Finish"><FormattedMessage id="KC0056" /></Link>
                                </li>
                            </ul>
                        </div>
                        <div class="stConfirmBox">
                            <ul>
                                <li>
                                    <div class="unitsWithST">
                                        <h5>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag added" || i.Status.toLowerCase() === "smart tag already exist").length : 0}</h5>
                                        <h6>Units with Smart Tag</h6>
                                    </div>

                                    {mode === "edit" ?
                                        <div class="stDataD unitsWithSTH">
                                            <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag added").length : 0} Added</h6>
                                            <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag already exist").length : 0} Already Existing</h6>
                                        </div>
                                    : ""}
                                </li>
                                <li>
                                    <div class="unitsWithoutST">
                                        <h5>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag not added" || i.Status.toLowerCase() === "smart tag removed").length : 0}</h5>
                                        <h6>Units without Smart Tag</h6>
                                    </div>
                                    {mode === "edit" ?
                                        <div class="stDataD unitsWithoutSTH">
                                            <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag removed").length : 0} Removed</h6>
                                            <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status.toLowerCase() === "smart tag not added").length : 0} No Tag</h6>
                                        </div>
                                    : ""}
                                </li>
                                <li>
                                    <div class="errorsST">
                                        <h5>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status === "Duplicate Entry" || i.Status === "Wrong Entry").length : 0}</h5>
                                        <h6>Errors</h6>
                                    </div>
                                    <div class="stDataD errorsSTH">
                                    <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status === "Duplicate Entry").length : 0} Duplicate 
                                            {confirmationData && confirmationData.length && confirmationData.filter((i) => i.Status === "Duplicate Entry").length ? 
                                                <button type="button" class="btn duplicateDetails" data-toggle="popover" data-content="In case of duplicate entries,
                                                    only the first entry for each serial
                                                    number will be considered to
                                                    apply the Smart Tag">&nbsp;</button> : <div/>
                                             }
                                        </h6>
                                        <h6>{confirmationData && confirmationData.length ? confirmationData.filter((i) => i.Status === "Wrong Entry").length : 0} Wrong</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <form class="unitRegCListForm">
                            <div class="STComfmtList">
                                <TableHeader
                                    sortColumn={sortColumn}
                                    onSort={onSort}
                                    columns={columns} />
                            </div>
                            <div class="STComfmtListSrlBar">
                                <div class="STComfmtList">
                                    {confirmationData && confirmationData.length ? confirmationData.map((item, i) =>
                                        <ul className='tBody'>
                                            <li>{i + 1}</li>
                                            <li>{item.UNITID}
                                                <span class="unitsListSM">{item.UNITNUMBER}</span>
                                                <span class="STstatusSM registeredDevices">Smart Tag added</span>
                                            </li>
                                            <li>{item.UNITNUMBER}</li>
                                            <li>{item.BRAND_NAME}</li>
                                            <li>{item.MODEL_NAME}</li>
                                            <li>{item.location_name}</li>
                                            <li><span class={
                                                item.Status === "Wrong Entry" ? "wrongEntry" :
                                                    item.Status === "Duplicate Entry" ? "duplicateEntry" :
                                                        item.Status === "Smart Tag Not Added" || item.Status === "Smart Tag Removed" ? "notAdded" :
                                                            item.Status === "Smart Tag Added" || item.Status === "Smart Tag Already Exist" ? "registeredDevices" : "registeredDevices"}
                                                > {item.Status}
                                                </span>
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <UserManagementHeader headerName={formatMessage({ id: 'KC2318' })} headerNameStringID="KC2318" activeClass="smartTagNav" />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={closeModel} />
            </div>
        </React.Fragment >
    )
}
export default injectIntl(SmartTagConfirmation); 