import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import SuccessMoal from '../modal/success-model';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { UserProfile } from '../../service/api';
import authService from '../../service/authService';
import { staticNotificationTypeData } from '../../utils/messageConfig';
import fConsts from '../../utils/featureConstants';
import _ from 'lodash';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

class NotificationManagement extends Component {
	constructor(props) {
		super(props);
		this.handleSelectedItems = this.handleSelectedItems.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.toggleHidden = this.toggleHidden.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.state = {
			notificationData: [],
			isHidden: false,
			success_modal: {
				open: false,
				message: "",
				isAllTEXTChkboxSelected: false,
				isAllEMAILChkboxSelected: false,
				loaderIconVisible: false,				
			},
			isTextIndeterminateSelect:false,
			isEmailIndeterminateSelect:false,
			staticNotificationType: "",
			selectedFrequency:24,
		};
	}

	userNotificationSubmit = (e) => {
		let user_preferences = [...this.state.notificationData];
		const { formatMessage } = this.props.intl;

		let _preferenceList = [];
		user_preferences.forEach(p => {
			let items = [];
			if (p.isTextChecked === true) {
				items.push("Text")
			}
			if (p.isEmailChecked === true) {
				items.push("Email")
			}
			p.preference_value = items && items.length > 0 ? items.join(",") : "";
			if (p.preference_name === "Unit Offline" || p.preference_name === "offlineDeviceNotify" ) {
				if(p.isEmailChecked) {
					_preferenceList.push({ "preference_name": "offlineDeviceNotify", "preference_value": this.state.selectedFrequency });
				} else {
					_preferenceList.push({ "preference_name": "offlineDeviceNotify", "preference_value":0 });
				}
			} else {
				_preferenceList.push({ "preference_name": p.preference_name, "preference_value": p.preference_value });
			}
		});

		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		this.setState({ loaderIconVisible: true, })
		http({
			method: "PUT",
			// url: userUpdateManagementAPI.updateUser,
			url: `${UserProfile.getNotificationDetails}`,
			headers: { 'Content-Type': 'application/json' },
			data: {
				user: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loggedinUser,
					user_preferences: _preferenceList,
				}
			},
		}).then(function (response) {
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: response.stringID || response.data
				},
			});
			WoopraEvents(`${Constants.UPDATE_NOTIFICATION}`);
		}).catch(err => {
			console.log('catch exception occured');

			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: formatMessage({id: 'KC0977'})
				},
			});
		});
	}

	toggleHidden(event) {
		event.preventDefault();

		if (!this.state.isHidden) {
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);

		}
		this.setState({
			isHidden: !this.state.isHidden
		})
	}

	handleOutsideClick(event) {
		// ignore clicks on the component itself             
		if (this.pop) {
			//const domNode = ReactDOM.findDOMNode(this.pop)
			if (this.pop.contains(event.target)) {
				return;
			}
			this.toggleHidden(event);
		}
	}

	onSuccessCloseModal() {
		const { formatMessage } = this.props.intl;
		this.setState({
			success_modal: {
				open: false,
				message: formatMessage({id: 'KC0977'})
			},
		});
	}

	selectedFrequency (e, item) {		
		this.setState({ selectedFrequency: e.target.value });
	}

	handleSelectedItems(e, item) {
		let isChecked = e.target.checked;
		let preferenceList;
		if (this.state.notificationData.length === 0) {
			preferenceList = staticNotificationTypeData;
		} else {
			preferenceList = [...this.state.notificationData];
		}
		let index = preferenceList.indexOf(item);
		if (index !== -1) {
			preferenceList[index].isTextChecked = e.target.value === "TEXT" ? e.target.checked : preferenceList[index].isTextChecked;
			preferenceList[index].isEmailChecked = e.target.value === "EMAIL" ? e.target.checked : preferenceList[index].isEmailChecked;
		}

		let checked = preferenceList.filter(c => e.target.value === "TEXT"? c.isTextChecked === true: c.isEmailChecked === true);

		this.setState({ notificationData: preferenceList,...this.getCheckBoxstate(preferenceList.length,checked.length,e.target.value) });
		
	}

	getCheckBoxstate(ListCount,SelectedCount,option) {
		if(option == 'TEXT'){
			if (SelectedCount == ListCount) {
				return { isAllTEXTChkboxSelected: true, isTextIndeterminateSelect: false };
			} else if (SelectedCount == 0 && ListCount != 0) {
				return { isAllTEXTChkboxSelected: false, isTextIndeterminateSelect: false };
			} else if (ListCount >= SelectedCount) {
				return { isAllTEXTChkboxSelected: true, isTextIndeterminateSelect: true };
			}

		}else if(option == 'EMAIL'){
			if (SelectedCount == ListCount) {
				return { isAllEMAILChkboxSelected: true, isEmailIndeterminateSelect: false };
			} else if (SelectedCount == 0 && ListCount != 0) {
				return { isAllEMAILChkboxSelected: false, isEmailIndeterminateSelect: false };
			} else if (ListCount >= SelectedCount) {
				return { isAllEMAILChkboxSelected: true, isEmailIndeterminateSelect: true };
			}

		}
       
    }

	handleALLSelectedItems(e, item) {
		let isChecked = e.target.checked;
		let preferenceList;
		if (this.state.notificationData.length === 0) {
			preferenceList = staticNotificationTypeData;
		} else {
			preferenceList = [...this.state.notificationData];
		}
		preferenceList.forEach(p => {
			e.target.value === "TEXT" ? p.isTextChecked = isChecked : p.isEmailChecked = isChecked;
			p.preference_value = e.target.value === "TEXT" ? "TEXT" : "EMAIL";
		});
		e.target.value === "TEXT" ? this.setState({ isAllTEXTChkboxSelected: isChecked,  isTextIndeterminateSelect: false}) : this.setState({ isAllEMAILChkboxSelected: isChecked,isEmailIndeterminateSelect: false })
		this.setState({ notificationData: preferenceList });
	}

	getUserNotificationInformation = () => {
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		this.setState({ loaderIconVisible: true, })
		http({
			method: "get",
			//url: userManagementAPI.getNotificationDetails,
			url: `${UserProfile.getNotificationDetails}`,
			headers: {
				//'customer_id': customerid,
				'customer_id': localStorage.getItem("customerId"),
				'email': loggedinUser,
				'Content-Type': 'application/json'
			}, data: {},

		}).then(response => {
			 let preferenceData = [...response.data.data];
			//let preferenceData ;
			preferenceData.forEach(p => {
				if (p.preference_value && p.preference_value.indexOf("Text") !== -1) {
					p.isTextChecked = true
				}
				if (p.preference_value && p.preference_value.indexOf("Email") !== -1) {
					p.isEmailChecked = true
				}
				if (p.preference_name === "offlineDeviceNotify" && p.preference_value>0) {
					p.isEmailChecked = true;
					p.value=p.preference_value;
					p.preference_value="Email";					
					this.setState({ selectedFrequency: p.value })
				}
			});

			['EMAIL','TEXT'].map(e => {
				let checked = preferenceData.filter(c => e === "TEXT"? c.isTextChecked === true: c.isEmailChecked === true);
				this.setState({...this.getCheckBoxstate(preferenceData.length,checked.length,e) });
			})
			this.setState({ notificationData: preferenceData, loaderIconVisible: false })
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			console.log('catch exception occured');
		});
	}

	componentDidMount() {
		this.getUserNotificationInformation();
		document.body.style.backgroundColor = "#F2F2F2";
	}

	viewNotificationInfo = (notificationList) => {
		const { formatMessage } = this.props.intl;

		if (!notificationList) {
			return (<div></div>)
		}

		return (<form class="notificationTypeForm">
			<div class="NotifnMgmtTable">
				<ul class="tHead">
					<li><FormattedMessage id='KC0133' /></li>
					<li><FormattedMessage id='KC0134' /></li>
					<li><FormattedMessage id='KC0136' /></li>
					<li>
						<div class="customCheckbox selectAllDiv disabled">
							<input disabled="true" onChange={(e) => this.handleALLSelectedItems(e, "EMAIL")} id="selectEditAll" type="checkbox" value={"EMAIL"} checked={this.state.isAllEMAILChkboxSelected} />
							<label for="selectEditAll">&nbsp;</label>
						</div>
					</li>
				</ul>
				{notificationList.map((item, i) => (
					<ul class="tBody">
						{/* <li>{notificationList[i].preference_name}</li> */}

						<li>{ notificationList[i].preference_name === "offlineDeviceNotify" ? <FormattedMessage id="KC1663"/>  : notificationList[i].preference_name}</li>																						
											{notificationList[i].preference_name === "Unit Offline" || notificationList[i].preference_name === "offlineDeviceNotify" ? <li>
													<div class="input-group">
														{notificationList[i].value}
													</div>
													<span class="inputGroupLabel"><FormattedMessage id="KC0280"/></span>
												</li> : <li style={{color:'#96a8bb'}}><FormattedMessage id="KC1747"/></li>}
						<li>&nbsp;</li>
						<li>
							<div class="customCheckbox customCheckboxWOlabel disabled">
								<input disabled="true" id={notificationList[i].Id + "Email"} name={notificationList[i].Id + "Email"} checked={notificationList[i].isEmailChecked} type="checkbox" value={"EMAIL"} />
								<label for={notificationList[i].Id + "Email"} >&nbsp;</label>
							</div>
						</li>
					</ul>
				))}
			</div>
		</form>)
	}

	// Woopra Events
	trackEvent = (e) => {
		let title = e.trackTitle === "Time & Number Format" ? "Time and Number Format" : e.trackTitle;
		WoopraEvents(`${title}`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { notificationData, success_modal, loaderIconVisible,selectedFrequency } = this.state;
		let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let staticNotificationTypeDataReceive = staticNotificationTypeData;
		let notificationList = notificationData.length > 0 ? notificationData : staticNotificationTypeDataReceive;

		let ErrorsAlreadyExits = false,NoncriticalErrorsAlreadyExits = false,InformationAlreadyExits = false;
		let unitOfflineAlreadyExits = false, OperationalErrorsAlreadyExits = false, ResolvedErrorsAlreadyExist = false;
		
		notificationList.forEach(p => {
			if (p.preference_name === "offlineDeviceNotify" || p.preference_name === "Unit Offline"){
				unitOfflineAlreadyExits = true;
			}
		});

		notificationList.forEach(p => {
			if (p.preference_name === "Critical Errors"){
				ErrorsAlreadyExits = true;
			}
		});

		notificationList.forEach(p => {
			if (p.preference_name === "Non-critical Errors"){
				NoncriticalErrorsAlreadyExits = true;
			}
		});

		notificationList.forEach(p => {
			if (p.preference_name === "Operational Events"){
				OperationalErrorsAlreadyExits = true;
			}
		});

		notificationList.forEach(p => {
			if (p.preference_name === "Informative Events"){
				InformationAlreadyExits = true;
			}
		});

		notificationList.forEach(p => {
			if (p.preference_name === "Resolved Errors"){
				ResolvedErrorsAlreadyExist = true;
			}
		});

		if (notificationData.length > 0 && unitOfflineAlreadyExits === false) {
			notificationList.push({ Id: 960,"preference_name": "offlineDeviceNotify", "preference_value":0 });
		}
		if (notificationData.length > 0 && ErrorsAlreadyExits === false) {
			notificationList.push({ Id: 961,"preference_name": "Critical Errors", "preference_value":"Text,Email" });
		}
		if (notificationData.length > 0 && NoncriticalErrorsAlreadyExits === false) {
			notificationList.push({ Id: 962,"preference_name": "Non-critical Errors", "preference_value":"Text,Email" });
		}
		if (notificationData.length > 0 && OperationalErrorsAlreadyExits === false) {
			notificationList.push({ Id: 963,"preference_name": "Operational Events", "preference_value":"Text,Email" });
		}
		if (notificationData.length > 0 && InformationAlreadyExits === false) {
			notificationList.push({ Id: 964,"preference_name": "Informative Events", "preference_value":"Text,Email" });
		}
		if (notificationData.length > 0 && ResolvedErrorsAlreadyExist === false) {
			notificationList.push({ Id: 965,"preference_name": "Resolved Errors", "preference_value":"Text,Email" });
		}
		notificationList=_.orderBy(notificationList, 'orderId'); 

		let myinformationEditOrViewLink;
		let assignedunitgroupsEditOrViewLink;
		let NotificationManagementEditOrViewLink;
		let isUserNotificationFormViewStatus;

		myinformationEditOrViewLink = "/myinformationEdit";
		assignedunitgroupsEditOrViewLink = "/assignedunitgroupsEdit";
		if (userNotificationAuthFeature && userNotificationAuthFeature.feature_active) {
			isUserNotificationFormViewStatus = userNotificationAuthFeature.is_editable ? false : true;
			NotificationManagementEditOrViewLink = "/NotificationManagement";
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress --> */}

					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={myinformationEditOrViewLink} title={formatMessage({id: 'KC1916'})} onClick={(e)=> {e.trackTitle="My Information";this.trackEvent(e)}}><FormattedMessage id='KC1916' /></Link>
										<Link to={assignedunitgroupsEditOrViewLink} title={formatMessage({id: 'KC1917'})} onClick={(e)=> {e.trackTitle="Assigned Unit Groups";this.trackEvent(e)}}><FormattedMessage id='KC1917' /></Link>
										{NotificationManagementEditOrViewLink && <Link to={{ pathname: NotificationManagementEditOrViewLink, state: { fromViewAccountpageValue: false } }} title={formatMessage({id:'KC0109'})} class="active"><FormattedMessage id='KC0109' /></Link>}
										<Link to="/timeNumberFormatedit" title={formatMessage({id: 'KC0110'})} onClick={(e)=> {e.trackTitle="Time & Number Format";this.trackEvent(e)}}><FormattedMessage id='KC0110' /></Link>
										{/* <Link to="/mySubscriptionEdit" title="Subscription Management">Subscription Management</Link> */}
									</li>
								</ul>
							</div>
							{isUserNotificationFormViewStatus > 0 ?
								this.viewNotificationInfo(notificationList) :
								<form class="notificationTypeForm">
									<div class="NotifnMgmtTable">
										<ul class="tHead">
										<li><FormattedMessage id='KC0133' /></li>
										<li><FormattedMessage id='KC0134' /></li>
										<li><FormattedMessage id='KC0136' /></li>
											<li>
												<div class={`customCheckbox customCheckboxWOlabel ${this.state.isEmailIndeterminateSelect ? "selectAllDiv" : "" } `}>
													<input onChange={(e) => this.handleALLSelectedItems(e, "EMAIL")} id="selectEditAll" type="checkbox" value={"EMAIL"} checked={this.state.isAllEMAILChkboxSelected} />
													<label for="selectEditAll">&nbsp;</label>
												</div>
											</li>
										</ul>
										{Object.keys(notificationList).map((item, i) => (
											<ul class="tBody">
												<li>{ notificationList[i].preference_name === "offlineDeviceNotify" ? <FormattedMessage id="KC1663"/>  : notificationList[i].preference_name}</li>
												{notificationList[i].preference_name === "Unit Offline" || notificationList[i].preference_name === "offlineDeviceNotify" ? <li>
													<div class="input-group">
														<select onChange={(e) => this.selectedFrequency(e)} value = {selectedFrequency} class="form-control" tabindex=" ">
															<option value="24" selected>24</option>
															<option value="22">22</option>
															<option value="20">20</option>
															<option value="18">18</option>
															<option value="16">16</option>
															<option value="14">14</option>
															<option value="12">12</option>
															<option value="10">10</option>
															<option value="8">8</option>
															<option value="6">6</option>
															<option value="4">4</option>
															<option value="2">2</option>															
														</select>
													</div>
													<span class="inputGroupLabel"><FormattedMessage id='KC0280' /></span>
												</li> : <li style={{color:'#96a8bb'}}><FormattedMessage id='KC1747' /></li>}
												<li>&nbsp;</li>
												<li>
													<div class="customCheckbox customCheckboxWOlabel">														
														<input onChange={(e) => this.handleSelectedItems(e, notificationList[i])} id={notificationList[i].Id + "Email"} name={notificationList[i].Id + "Email"} checked={notificationList[i].isEmailChecked} type="checkbox" value={"EMAIL"} />
														<label for={notificationList[i].Id + "Email"} >&nbsp;</label>
													</div>
												</li>
											</ul>
										))}										
										<button type="button" class="btn ManagePassword" onClick={(e) => this.userNotificationSubmit(e)}><FormattedMessage id='KC0123' /></button>
									</div>
								</form>}
						</div>
					</div>
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage="User Profile" activeClass="UserProfileNav" />
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	// debugger;
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationManagement));
