import React, { Component } from 'react';
import http from '../../service/httpService';
import { setJwt, getJwt, setLocalStorage } from '../../service/authService';
import axios from 'axios';
import authConstants from '../../utils/authConstants';
import authHelper from '../../utils/authHelper';
import { UserProfile, BaseService, UserMgmtAddUser,UnitInfo } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { setcustomerId, setbasecountryId, setaccessCountryId, setBrandId }  from '../../utils/appConstants';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect';
import { FormattedMessage } from 'react-intl';
class EmulationLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailid: '', 
			isVerified: false,
			errors: {
				message: ''
			},
			error_modal:{
				open:false,
				message:""
			},
			loaderIconVisible:false
		};
	}

	componentWillMount = () =>{
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		this.updateLoginDimensions(); 
		const search = this.props.location.search;
        const params = new URLSearchParams(search);
		this.setState({
			emailid: params.get('em')
		});
	}

	updateLoginDimensions() {
		var windowHeight = $(window).height(); 
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25; 
		//Sign In & Sign Up Modal
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH)); 
		var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH));   
		$(".LoginBtnWrapper").css("max-height", loginScrollbarHeightS); 
	}

	setLoader = (status) => {
		this.setState({
			loaderIconVisible: status
		});
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	validateForm() {
		this.setLoader(true);

		let { emailid } = this.state;
		let formIsValid = true;
  		  
		if (!emailid) {			
		  formIsValid = false;
		  this.setState({
			errors : {
				message : "Invalid email-ID."
			}
		  })
		}
  
		if (emailid !== "undefined") {
		  //regular expression for emailid validation
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(emailid)) {
			formIsValid = false;
			this.setState({
				errors : {
					message : "Invalid email-ID."
				}
			})
		  }
		}
      
		return formIsValid;
	}

	getCustomerID = async (customerID, emailID, baseCountry) => {
		let custGroupIDList = "";
		var setcustGroupIDList="";
		await http({
			method: "GET",			
			url: `${UserProfile.get_Cust_GroupID}`,	
			headers: {			
				'customer_id': customerID,
				'email': emailID,	
				'Content-Type': 'application/json',
				'base_country': baseCountry,
				'emulation': true
			},data:{},
		}).then(async response => {
			custGroupIDList=response.data.data;
			custGroupIDList.forEach(p => {
				setcustGroupIDList?setcustGroupIDList=setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList=p.customer_group_id			
			});

			localStorage.setItem("custGroupID", setcustGroupIDList);
			await this.getUnitImage(customerID, setcustGroupIDList);	
			this.getUserStartLevelID(setcustGroupIDList);
			let accessFeatures = JSON.parse(localStorage.getItem("allFeatures"));
			if (accessFeatures[0].feature_category == "Main Menu Apps") {
				this.props.history.push(`/mainmenu`);
			}
			else {
				/** starts:pwp for exporbar csv redirect*/
				const unitBrandId = localStorage.getItem("csvUnitBrandId");
				const unitId = localStorage.getItem("csvUnitID");
				if(unitId !== null && unitBrandId !== null){
					csvExUnitRedirect(unitId, unitBrandId, this.props.history.push)
				}
				else {
					this.props.history.push(`/dashboardLocations`);
				}
				/**ends:pwp */
			}
		}).catch(err => {
			console.log(err);
		});
	}

	getUserStartLevelID = async(values) => {
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		await http({
			method: "GET",			
			url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,	
			headers: {				
				"Cgids":values,
				'Content-Type': 'application/json',
				'useremail': loggedinUser
			},data:{},
		}).then(async response => {	
			if (response.data.fetchFromS3) {
				await $.getJSON(response.data.presignedS3URL, (data) => {
					localStorage.setItem("custLevelID", data.start_level_name);
					localStorage.setItem("startLevelID", data.start_level_id);
					localStorage.setItem("parentCustGroupId", data.parent_group_id);
				})
			}
			else {
				localStorage.setItem("custLevelID", response.data.start_level_name);
				localStorage.setItem("startLevelID", response.data.start_level_id);
				localStorage.setItem("parentCustGroupId", response.data.parent_group_id);
			}	
		}).catch(err => {	
			console.log(err);
		});
	}

	getChildUserEmailList = async(customerId,loggedinUser, baseCountry) => {
		const urlVal = UserMgmtAddUser.addUser + "?customerId=" + customerId + "&email=" + loggedinUser;
		await http({
			method: "GET",
			url: urlVal,
			headers: {
				"cgid": '',
				'Content-Type': 'application/json',
				'base_country': baseCountry,
				'emulation': true
			}, data: {},
		}).then(response => {
			console.log("getChildUserEmailList = ",response);
				let email = [];
				if(response.data && response.data.data && response.data.data !== "No Records Found"){
					for(let i=0; i<response.data.data.length; i++){
						const ele =response.data.data[i];
						email.push(ele.email);
					}
				}
				localStorage.setItem('childUsersEmail',JSON.stringify(email));
		}).catch(err => {
			localStorage.setItem('childUsersEmail',JSON.stringify([]));
		});
	}

	getUserRole = async (customerId, userName, baseCountry) => {
		http.get(UserProfile.getUserProfileDetails,
			{headers: {
				'customer_id': customerId,
				'email': userName,
				'Content-Type': 'application/json',
				'base_country': baseCountry,
				'emulation': true
			},data:{},
		}).then(response => {
			console.log("getUserRole = ",response);
			localStorage.setItem("roleName", response.data.role_name);
			localStorage.setItem("customerName", response.data.customer_name);
			localStorage.setItem("userName", response.data.name ? response.data.name : "");
		}).catch(err => {
		});
	}

	getCustomerGroupdet = async (customerId, userName) => {
		http.get(BaseService.customer_Group_det,
			{headers: {
				'customer_id': customerId,					
				'Content-Type': 'application/json'
			},data:{},
		}).then(async response => {
			localStorage.setItem("groupDetails", response.data.groupDetails ? JSON.stringify(response.data.groupDetails): {});  
			localStorage.setItem("region", response.data.regionDetails.country_region ? response.data.regionDetails.country_region : "");							
			await this.getCustMngPortalLinkDet(response.data);			
		}).catch(err => {
		});
	}		

	getCustMngPortalLinkDet = async (data) => {		
		let chargCustId;
		if (data.eaas_flag){
			localStorage.setItem("subscriptionId", data.chargify_subscription_id ? data.chargify_subscription_id : "");
			if (data.chargify_customer_attributes && data.chargify_customer_attributes.subscription && data.chargify_customer_attributes.subscription.customer) {
				chargCustId = data.chargify_customer_attributes.subscription.customer.id
				localStorage.setItem("chargifyCustID", chargCustId);
			}

			if (data.billing_Portal_url && data.billing_Portal_url.url) {
				localStorage.setItem("billingPortalUrl", data.billing_Portal_url.url);
			} else {
				const subscID = data.chargify_subscription_id ? data.chargify_subscription_id : "";
				if (chargCustId && subscID) {
					await this.updatePortalURL(chargCustId, subscID);
				}
			}
		} else {		
			localStorage.setItem("subscriptionId", data.subscriptionDetails && data.subscriptionDetails.chargify_subscription_id ? data.subscriptionDetails.chargify_subscription_id : "");	
			if (data.subscriptionDetails.chargify_customer_attributes && data.subscriptionDetails.chargify_customer_attributes.subscription && data.subscriptionDetails.chargify_customer_attributes.subscription.customer) {
				chargCustId = data.subscriptionDetails.chargify_customer_attributes.subscription.customer.id
				localStorage.setItem("chargifyCustID", chargCustId);
			}

			if (data.subscriptionDetails.billing_Portal_url && data.subscriptionDetails.billing_Portal_url.url) {
				localStorage.setItem("billingPortalUrl", data.subscriptionDetails.billing_Portal_url.url);
			} else {
				const subscID = data.subscriptionDetails && data.subscriptionDetails.chargify_subscription_id ? data.subscriptionDetails.chargify_subscription_id : "";
				if (chargCustId && subscID) {
					await this.updatePortalURL(chargCustId, subscID);
				}
			}
		}
	};		

	updatePortalURL = async (chargifyCustId, subscrId) => {
		this.setState({
			access_modal: {
				open: false
			}
		})
		http({
			method: "POST",
			url: BaseService.updateSubscription,
			data: {
				"isTestType": "billingPortalMgmt",
				"chargifyCustId": chargifyCustId,
				"subscriptionId": subscrId,
				"isEaas": localStorage.getItem('isEAAS') === 'true' ? true : false,
			},
		}).then(response => {
			let { status, data } = response;
			if (status === 200) {
				localStorage.setItem('billingPortalUrl', data.url);
				this.setState({
					loaderIconVisible: false,
				});
			}
		})
	};

	getUnitImage = async(customerId, custGroupID) => {
		const urlVal = UnitInfo.unitImage;
		await http({
			method: "GET",
			url: urlVal,
			headers: {
				"brandid" : localStorage.getItem('brandId') ? JSON.parse(localStorage.getItem('brandId')).join(',') : '',
				"cgid": custGroupID,
				"cid":customerId,
				'Content-Type': 'application/json',
			}, data: {},
		}).then(response => {
			if(response.data && response.data ){						
				localStorage.setItem("modelImage", JSON.stringify(response.data.units));
			}
		}).catch(err => {
			localStorage.setItem('modelImage',JSON.stringify([]));
		});
	}

	closeModel = () => {
		this.setState({
			loaderIconVisible : false,
			error_modal:{
				open:false,
				message:""
			}
	   });
	   window.close();
	}
		 
	componentDidMount = () => {
		$("body").removeClass("modal-open");
		const search = this.props.location.search;
        const params = new URLSearchParams(search);
		if (this.validateForm()) {
			const nameValue = params.get('name');
			const splittedName =nameValue.split(' ');
			const first_name = splittedName.length ? splittedName.slice(0, -1).join(' ') : '';
			const last_name = splittedName.length ? splittedName.slice(-1).join(' ') : '';

			let postData = {
				"email": params.get('em'),
				"customer_id": params.get('id'),
				"customer_email": params.get('cem'),
				"emulation": params.get('emul'),
				"phone_number": params.get('ph'),
				"base_country": params.get('base'),
				"access_countries": params.get('access'),
				"customer_group_id": params.get('cgid'),
				"log_id": params.get('logid'),
				"first_name": last_name === nameValue ? last_name : first_name ,
				"last_name": last_name === nameValue ? "." : last_name 
			}

			return new Promise(async(resolve, reject)=>{
			await axios({
				method: "POST",
				url: BaseService.root + BaseService.emulationLogin,	
				headers: {
					'Content-Type': 'application/json',
					'Authorization': params.get('token'),
				},
				data: postData
			}).then(async response => {	
				this.setState({
					isFormValidated: true
				});
				const { data : responseData } = response;
				if (responseData.httpCode === 500) {
					let { body } = responseData;
					let { message } = body;
					this.setState({
						loaderIconVisible: false,
						error_modal:{
							open: true,
							message: message
						}
					});
					resolve(response);
				}
				else {
					const { loginInfo, userBrands, rememberMe, allFeaturesKey, currentFeaturesKey }  = authConstants;

					let encode_postData = {
						username:postData.email, 
						password:''
					}	
					localStorage.setItem(loginInfo, JSON.stringify(encode_postData));  
					setJwt(responseData.accessToken);
					setLocalStorage(responseData,true);
	
					let rolefeatures = responseData.features;
					localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));
	
					// Store BrandIds of the User
					localStorage.setItem(userBrands, JSON.stringify(responseData.brandIds));
					setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
					http.setJwt(getJwt());
					
					// Set Features
					let currentFeatures = authHelper.applyPermissions(rolefeatures);
					localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));
	
					// rememberMe set to false by default for emulation role
					localStorage.setItem(rememberMe, false);
	
					localStorage.setItem("customerId", responseData.customerId);
					localStorage.setItem("basecountry", responseData.basecountry);
					localStorage.setItem("accesscountry", responseData.accesscountry);
					localStorage.setItem("roleId",responseData.role);
					localStorage.setItem("timeFormat", responseData.userTimeNumberPreferences.length > 0 && responseData.userTimeNumberPreferences[0].preference_value ? parseInt(responseData.userTimeNumberPreferences[0].preference_value) : parseInt("12"));
                    localStorage.setItem("thousandSeparator", responseData.userTimeNumberPreferences.length > 1 && responseData.userTimeNumberPreferences[1].preference_value ? responseData.userTimeNumberPreferences[1].preference_value : ",");
					setcustomerId(responseData.customerId ? responseData.customerId : 1);
					setbasecountryId(responseData.basecountry ? responseData.basecountry : "");
					setaccessCountryId(responseData.accesscountry ? responseData.accesscountry : "");					
					localStorage.setItem("user", postData.email);
					localStorage.setItem("emulation", true);
					localStorage.setItem("logid", postData.log_id);
					await this.getChildUserEmailList(responseData.customerId,postData.email, postData.base_country);
					await this.getUserRole(responseData.customerId,postData.email, postData.base_country);
					await this.getCustomerGroupdet(responseData.customerId);
					await this.getCustomerID(responseData.customerId, postData.email, postData.base_country);
					resolve(response);
				}
			}).catch(err => {	
				reject(err);
			});
			}).catch(err=>{
				console.log(err);
				this.setLoader(false);
			});
		}
		else {
			this.setState({
				loaderIconVisible: false,
				error_modal:{
					open: true,
					message: 'Invalid email-ID.'
				}
			});
		}
	}

	render() {
		const { error_modal, loaderIconVisible } = this.state;	
		return (
			<div className="loginBg">
				<div id="adminWrapper">   
					{/* <!-- Loading Progress --> */}
					{loaderIconVisible && <div id="loader" className={"loadingProgress showloader"}>
						<div className="loadingProgressIn"></div>
					</div>}
					{/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">   
                		<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>  
        			</nav>
					{/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
					<div id="page-wrapper">
					<div className="welcomText"><FormattedMessage id='KC0006'/></div>
						{/* <!-- Login Form --> */}
						<div class="LoginBtnWrapper">
							<div id="loginBtnFormID">
								<div class="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className="loginIWrapper">
												<div className="existingLoginForm">
													<ul>
														<li className="form-group">
															<div className="input-group">
																<FormattedMessage id='KC0007'>
																	{placeholder => <input className="form-control disabled" placeholder={placeholder} type="text" name="emailid" value={this.state.emailid} />}
																</FormattedMessage>
															</div>
															<div className="errorMsg">{this.state.errors.message}</div>
														</li>
														<li className="form-group pass">
															<button className="btn btn-secondary existingSingINBtn" type="submit" id="existingSignInButton">Auto Validating. Please wait...</button>
														</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- //Login Form -->   */}
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel}/>
			</div>
		)
	}
};

export default EmulationLogin;
