import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { getSoftwareUpdateStatus } from '../../../actions/navigation/softwareManagement/softwareUpdateStatusActions'
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import http from '../../../service/httpService';
import { SoftwareManagementAPI } from '../../../service/api';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import momentHelper from '../../../utils/momentHelper.js';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import appconstants from '../../../utils/appConstants';
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import NoRecordsFound from '../../controls/noRecordsFound';
import LoadingPopup from '../../controls/loadingPopup';
import { Paginator } from '../../controls/paginator';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { HOVER_TEXT_SCHEDULED,HOVER_TEXT_QUEUED,HOVER_TEXT_UPDATING,HOVER_TEXT_EXPIRED,HOVER_TEXT_COMPLETED,HOVER_TEXT_CANCELED } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import GoToPagination from '../../controls/goToPagination';
import $ from 'jquery';

var moment = require('moment');
let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate()));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
sixMonthsFromNow = sixMonthsFromNow.setDate(sixMonthsFromNow.getDate()-1);
let savestartDate = new Date(todaysDate.setMonth(todaysDate.getMonth() +5));
let firstTimeDate = savestartDate;
firstTimeDate = firstTimeDate.setDate(firstTimeDate.getDate()-1);
let saveendDate = Yesterdaydate;


class SoftwareStatus extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			sortColumn: { path: "name", order: "asc", sort_color: "#63778a" },
            success_modal: {
                open: false,
                message: ""
            },
			error_modal: {
				open: false,
				message: ""
			},
			unitModelDetails:[],
			unitLocationDetails:[],
			unitSoftwareDetails:[],
			Scheduled : true,
			Queued : true,
			Updating : true,
			Pending : false,
			Completed : true,
			Canceled : true,
			Failed : true,
			distributionCounts:[],
			deleteIcon:false,
			cancelIcon:false,
			retryIcon:false,
			currentPage : 1,
			pageSize: 25,	
			totalPages:null,
			startDate: localStorage.getItem('startDate')? moment(localStorage.getItem('startDate')).toDate():firstTimeDate,
			endDate: localStorage.getItem('endDate')? moment(localStorage.getItem('endDate')).toDate():Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
		};
		this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
            sSmartTags:[],
        };
	}

	componentWillMount = async () => {
		await this.getStatusDetails();
	}	

	getStatusDetails  = async () => {
		let statusType = localStorage.getItem("statusType");
		if (statusType) {
			this.setState({
				Scheduled : false,
				Queued : false,
				Updating : false,
				Pending : false,
				Completed : false,
				Canceled : false,
				Failed : false,
			})

      if (statusType === "IN_PROGRESS"){
				this.setState({					
					Queued : true,
					Updating : true,
					Pending : true,					
				})

			} else if (statusType === "SUCCEEDED") {
				this.setState({					
					Completed : true,
				})

			} else if (statusType === "CANCELED") {
				this.setState({					
					Canceled : true,
				})

			} else if (statusType === "REJECTED,FAILED") {
				this.setState({					
					Failed : true,				
				})
			}
		}
	}

	// updateDimension() {
	// 	var windowHeight = $(window).height();
	// 	var tableHNav = $(".tableHNav").height();
	// 	var selectedUnitHeader = $(".selectedUnitHeader").height();
	// 	var statusTableH = $(".statusTable .tHead").height();
	// 	var statusTableSrlBarH = ((windowHeight) - (tableHNav + selectedUnitHeader + statusTableH)) - 70;
	// 	$(".statusTableSrlBar").css("height", statusTableSrlBarH);
	// }

	updateDimension(){
		const windowHeight = $(window).height();
        const $tableBody = $('.statusTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
	}

	componentDidUpdate = () => {
		this.updateDimension();
	}

	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimension);
		this.updateDimension();
		document.body.style.backgroundColor = "#f2f2f2";
		//this.props.getSoftwareUpdateStatus();
		await this.getCount();	
		await this.getSoftwareUpdateStatus().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					//To avoid the two popup at the same time
					if (data.errorCode && data.errorCode === '403100'){
						this.setState({
							loaderIconVisible: false,
						},() => {					
						});
						return;
					}
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: (data.stringID || data.message) || ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		this.interval = setInterval(() => { this.getCount(); this.getSoftwareUpdateStatus()}, 30000);
	}
	
	getUnitPopUpDetails  = async (item) => {		
		this.setState({
			loaderIconVisible: true
		});
		await this.getUnitModelDetails(item);
		await this.getUnitLocationDetails(item);
		await this.getUnitSoftwareDetails(item);		
		this.setState({
			loaderIconVisible: false
		});
	}

	getUnitModelDetails = async (item) => {
		let url = SoftwareManagementAPI.unitModelInfo;
		url = url + item.UNITID + "/" + item.BRAND + "/device-info";
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(response => {
				let val = response.data;
				val.FILE_NAME = item.FILE_NAME ? item.FILE_NAME : "";
				this.setState({
					unitModelDetails: val										
				});
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	getUnitLocationDetails = async (item) => {
		let url = SoftwareManagementAPI.unitLocationInfo;
		url = url + item.UNITID + "/" + item.BRAND;
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(response => {			
				let details = response.data.units;				
				let unitDetails=[];
				let val = {
					"BRAND_NAME" : details.BRAND_NAME,
					"MODEL_NAME" : details.MODEL_NAME
				}
				unitDetails.push (val);				
				let logoDetails = UnitBuilder(unitDetails);
				details.Brand_Alt= logoDetails[0].Brand_Alt;
				details.Brand_Logo= logoDetails[0].Brand_Logo;
				details.Brand_Title= logoDetails[0].Brand_Title;				

				this.setState({
					unitLocationDetails: details,//response.data.units,
				});

				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	getUnitSoftwareDetails = async (item) => {
		let url = SoftwareManagementAPI.unitSoftwareInfo;		
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'cloudUnitName':item.CLOUD_UNIT_NAME,
				},
				data: {}
			}).then(response => {
				this.setState({
					unitSoftwareDetails: response.data
				});
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	checkDeleteAccess = async (mediaValue) => {
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.DISTRIBUTED_BY && mediaValue.DISTRIBUTED_BY.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || username === mediaValue.DISTRIBUTED_BY ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		
		return isDelete;
	}

	getSoftwareUpdateStatus = async (currentPage = this.state.currentPage) => {
		let { sortColumn } = this.state;
		if( localStorage.getItem('softwareSortColumn') && localStorage.getItem('softwareSortColumn') != sortColumn.path) {
			sortColumn.path = localStorage.getItem('softwareSortColumn');
		}
		await this.getFilterDetails();
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}

		let {Scheduled,Queued,Updating,Pending,Completed,Failed,Canceled} =  this.state;
		let filtertypeVa="";
		if (Scheduled){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa + "," + "Scheduled" ;
			} else {
				filtertypeVa = "Scheduled";
			}
		}

		if (Queued){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "Queued";
			} else {
				filtertypeVa = "Queued";
			}
		}

		if (Updating || Pending){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "IN_PROGRESS";
			} else {
				filtertypeVa = "IN_PROGRESS";
			}
		}
		
		if (Completed){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "SUCCEEDED";
			} else {
				filtertypeVa = "SUCCEEDED";
			}
		}

		if (Canceled){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "CANCELED";
			} else {
				filtertypeVa = "CANCELED";
			}
		}

		
		if (Failed){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "REJECTED,FAILED";
			} else {
				filtertypeVa = "REJECTED,FAILED";
			}
		}

		// let sgidVal;
		// if (this.filterstate.sSmartTags.length>0){
        //     sgidVal = this.filterstate.sSmartTags;
        // }

		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			'user': localStorage.getItem("user"),                    
			'customerId': localStorage.getItem("customerId"),
			'brandId': localStorage.getItem("unitBrandId"),
			'cgids': cgids,	
			'filtertype': filtertypeVa? filtertypeVa : "UNSELECTALL",
			'startdate':this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate':this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};

		let url = SoftwareManagementAPI.softwareUpdateStatusUrl;

		if (sortColumn && sortColumn.path) {
			url = `${url}?sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
		}

		let pageNumber = '&pageNum=' + currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;
		
		url = url + pageNumber + pageLimit;

		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: headerVal,				
				data: {}
			}).then(async response => {
				let arr = [];
				let isDelete = false;
                for (let i = 0; i <  response.data.Software_Distribute_List.softwareDistributeList.length; i++) { 
                    const ele =  response.data.Software_Distribute_List.softwareDistributeList[i];                                  
                    isDelete = await this.checkDeleteAccess(ele);
					arr.push({...ele,isAuthorized: isDelete})
                };
				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation: arr,
					totalPages: response.data.Software_Distribute_List && response.data.Software_Distribute_List.numberOfPages ? response.data.Software_Distribute_List.numberOfPages : 0,
					currentPage

				});
				WoopraEvents(`${Constants.STATUS_FILTER_APPLIED}`,filtertypeVa);
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation:[],
					totalPages:0
				});
				reject(err);
			});
		});
	}


	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
		},() =>{
			localStorage.setItem('startDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1581"
					}
				});
			} else {
				this.getCount()
				this.getSoftwareUpdateStatus(1)
			}
		});
		
	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date,
		},()=>{
			localStorage.setItem('endDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1120"
					}
				});
			} else {
				WoopraEvents(`${Constants.DATE_APPLIED}`);
				this.getCount();
				this.getSoftwareUpdateStatus(1);
			}
		});
	}

	getCount = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}

		let filtertypeVa="Scheduled,Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";
		
		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			'user': localStorage.getItem("user"),                    
			'customerId': localStorage.getItem("customerId"),
			'brandId': localStorage.getItem("unitBrandId"),
			'cgids': cgids,	
			'filtertype': filtertypeVa? filtertypeVa : "UNSELECTALL",	
			'startdate':this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate':this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",		
		};

        let url = SoftwareManagementAPI.softwareUpdateStatusUrl;
        http.get(url, {
			headers: headerVal, 
			data: {}
        }).then(response => {
            if (response && response.data) {
				this.setState({
					distributionCounts: response.data.Software_Distribute_List.statusCount,
				});
            }
        }).catch(err => {
            this.setState({ 
				distributionCounts:[],
			 });
        });
	}

	static getDerivedStateFromProps = (props) => ({...props});

	componentWillUnmount() {
		localStorage.removeItem("statusType");
		localStorage.removeItem('startDate');
		localStorage.removeItem('endDate');
		localStorage.removeItem('softwareSortColumn');
	}

	onSort = sortColumn => {
		localStorage.setItem('softwareSortColumn', sortColumn && sortColumn.path);
		this.setState({
			sortColumn: sortColumn,
		});
		this.getSoftwareUpdateStatus(1);
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			deleteIcon:false, 
			cancelIcon:false,
			retryIcon:false
		});
    }
    
    onSmartGroupClick() {
		WoopraEvents(`${Constants.SMARTGROUP_STATUS}`);
		this.props.history.push({
            pathname: "/softwareSmartGroupStatus",
        })
    }

	onSuccessCloseModal = async (e) => {
        this.setState({
			distributionCounts:[],
            success_modal: {
                open: false
            },
			deleteIcon:false, 
			cancelIcon:false,
			retryIcon:false
        })
		await this.getCount();
        this.getSoftwareUpdateStatus();
	}  
	
	onRetry  = () => {
        this.setState({ loaderIconVisible: true });
		let selSoftwareId=[];
		let selId=[];
		const unitList = [];
		let req = {};
		let reqData;		
		var rolesList = [...this.state.softwareUpdateStatusInformation];
		rolesList = rolesList.filter(item => item.checked === true);
		rolesList.map(item => {
			unitList.push(
				{
					"thingArn": item.THING_ARN,
					"productId ": item.PRODUCT_ID,
					"cloudUnitName": item.CLOUD_UNIT_NAME,
					"unitTimeZone": item.UNIT_TIME_ZONE,
					"distributionId":item.ID,
					"softwareId":item.SOFTWARE_ID,
				}
			)
			selSoftwareId.push(
				item.SOFTWARE_ID
			)
			req =
			{
				"thingList": unitList,				
				"softwareId": selSoftwareId,
				"description": item.DESCRIPTION,
				"softwareType": item.TYPE,
				"transferType": item.TRANSFER_TYPE,
				"isMultiretry" : true,
			}
		});				
	
        let url = SoftwareManagementAPI.softwareUpdateStatusUrl;
        http.post(url,req,{
            headers: {
				'Content-Type': 'application/json',
				'customer': localStorage.getItem("customerId"),
				'user': localStorage.getItem("user"),
				'basecountry': BASECOUNTRY_ID,
				'brandid':localStorage.getItem("unitBrandId"),
			},
            data: reqData,
        }).then(response => {
            let { data } = response;                       
			this.setState({
				curr_Prod_ID: "",
				curr_Prod_Name: "",
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC1500"
				},
			}); 
			WoopraEvents(`${Constants.RETRY_DISTRIBUTION}`,req);           
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response.data.message,
                },
            });
        });
	}

	onDelete = (oprType) => {
        this.setState({ loaderIconVisible: true });
		let selSoftwareId=[];
		let selId=[];
		let reqData;		
        var rolesList = [...this.state.softwareUpdateStatusInformation];
        rolesList.forEach(i => {
            if (i.checked === true) { 			
				selSoftwareId.push(i.SOFTWARE_FK);
				selId.push(i.ID);
            }
        });
			
        reqData = {			
			'softwareId' : selSoftwareId,
			'id' : selId,
        }
        let url = SoftwareManagementAPI.softwareListStatusDelete;
        http.delete(url,{
            headers: {
				'Content-Type': 'application/json',
				'Operation': oprType,
			},
            data: reqData,
        }).then(response => {
			let { data } = response;                       
			this.setState({
				curr_Prod_ID: "",
				curr_Prod_Name: "",
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: oprType === "delete" ?"KC1501" : "KC1502",
				},
			});  
			if (oprType === "delete"){
				WoopraEvents(`${Constants.DELETE_DISTRIBUTION}`,reqData);
			} else {				
				WoopraEvents(`${Constants.CANCEL_DISTRIBUTION}`,reqData);
			}           
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data ?err.response.data.message:"KC1725",
                },
            });
        });
	}
	
	onSelectRole = (e, item) => {
        if (this.state.softwareUpdateStatusInformation && this.state.softwareUpdateStatusInformation.length > 0) {
            const rolesList = [...this.state.softwareUpdateStatusInformation];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;
            this.setState({
                softwareUpdateStatusInformation: rolesList,                
			},() => {
				this.controlTheButtonClick(rolesList);
			});			
		}            
	}	 

	controlTheButtonClick = (SelData) => {	
		this.setState({
			cancelIcon:false,
			deleteIcon:false,
			retryIcon:false,
		});	
		let selectedData = SelData.filter(item => item.checked === true && item.isAuthorized);
		let selScheduledCtr = 0,selQueuedCtr= 0,selUpdatingCtr= 0,selPendingCtr= 0,selCompletedCtr= 0,selFailedCtr= 0, selCanceledCtr=0;
		selectedData && selectedData.forEach(item => {
			selQueuedCtr += item.DISTRIBUTION_STATE === 'Queued' ? 1 : 0;
			selUpdatingCtr += item.DISTRIBUTION_STATE === 'IN_PROGRESS'  ? 1 : 0;
			selPendingCtr += item.DISTRIBUTION_STATE === 'IN_PROGRESS'  ? 1 : 0;
			selScheduledCtr += item.DISTRIBUTION_STATE === 'Scheduled' ? 1 : 0;
			selCompletedCtr += item.DISTRIBUTION_STATE === 'SUCCEEDED' ? 1 : 0;
			selCanceledCtr += item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? 1 : 0;
			selFailedCtr += item.DISTRIBUTION_STATE === 'FAILED' ? 1 : item.DISTRIBUTION_STATE === 'REJECTED' ? 1 : 0;			
		});

		//Enable Cancel Button		
		if ((selScheduledCtr>0 || selQueuedCtr>0 ) && (selCompletedCtr === 0 && selFailedCtr===0 && selCanceledCtr === 0)) {
			this.setState({
				cancelIcon:true,
			});
		}		
		//Enable Delete Button
		if ((selCompletedCtr>0 ||  selFailedCtr>0 || selCanceledCtr>0) && (selScheduledCtr===0 && selQueuedCtr===0 && selUpdatingCtr ===0 && selPendingCtr ===0)){
			this.setState({
				deleteIcon:true,
			});
		}		
		//Enable Retry Button
		if ((selFailedCtr > 0) && (selCompletedCtr===0 && selScheduledCtr===0 && selQueuedCtr===0 && selUpdatingCtr ===0 && selPendingCtr ===0)){
			this.setState({
				retryIcon:true,
			});
		}
	}

	checkBoxHandler = (e) =>{
		let isChecked = e && e.target && e.target.checked;	
		let selCheckboxName=e && e.target && e.target.value;	
		this.setState({
			[e.target.value]:isChecked,
		},() =>{
			this.getSoftwareUpdateStatus(1);
		})
	}

	getFilterDetails = async () =>{		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.softwareFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
        }

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
        let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    	let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
        let selectedMachineTypes = [];
        let selectedST = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			 selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
        });        
        
        selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
        });
        
        selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });

        selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
            sMachineTypes: selectedMachineTypes,
            sSmartTags:selectedST,            
		});

		var d = document.getElementById("spannavNotifiStatus");
        if (selectedST.length > 0 || selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
            d.className = "navNotifiStatus";
        }			
        else {
            d.className = "";
        }
		
	}

		/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
		 onPageChange = (currentPage) => {
			this.setState({ loaderIconVisible: true },()=>{
				this.getSoftwareUpdateStatus(currentPage);
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			});
		}

	// Woopra Events
    trackEvent = (e, title = '') => {
        WoopraEvents(`${Constants.SOFTWARE}-${(title || e.target.title)}`);
    }

	// Woopra Events
    trackEvent_For_Cancel = (e) => {
        WoopraEvents(e);
    }

	render() {
		const { formatMessage } = this.props.intl;
		let { loaderIconVisible, sortColumn, softwareUpdateStatusInformation, distributionCounts,
			deleteIcon, cancelIcon,retryIcon,currentPage, pageSize,
			unitModelDetails,unitLocationDetails,unitSoftwareDetails, error_modal,success_modal } = this.state;
		let updatequeued = 0;
		let updating = 0;
		let updatecomplete = 0;
		let updatefailed = 0;
		let scheduled = 0;
		let pending = 0;
		let updatecanceled = 0;
		const { softwareUpdateStatusInfo } =  this.props;
		let {Scheduled,Queued,Updating,Pending,Completed,Failed, Canceled} =  this.state;
		let userProfileAuthFeature = authService.getFeature("/softwareAvailableUpdates");

		let columns = [
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "#" },
			{ path: "BRAND", labelStringID: "KC0841" },
			{ path: "MODEL", labelStringID: "KC0647" },
			{ path: "MODEL_NO", labelStringID: "KC0050" },
			{ path: "UNITID", labelStringID: "KC0737" },
			{ path: "locationName", labelStringID: "KC0834" },
			{ path: "within_location", labelStringID: "KC0576" },
			{ path: "Start", labelStringID: "KC0577" },
			{ path: "End", labelStringID: "KC0578" },
			{ path: "Status", labelStringID: "KC0523" },
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "", isNbSp: true },			
		];

		if (distributionCounts) {
			scheduled = distributionCounts.Scheduled? distributionCounts.Scheduled :0;
			updatequeued = distributionCounts.Queued ? distributionCounts.Queued : 0;
			updating = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			updatecomplete = distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;			
			updatefailed = distributionCounts.FAILED ? distributionCounts.FAILED : 0;
			updatecanceled = distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;			
		}

		let softwareUpdateStatusList = softwareUpdateStatusInformation && softwareUpdateStatusInformation.length > 0 ? softwareUpdateStatusInformation : [];
		let selectedCount=0;
		softwareUpdateStatusList && softwareUpdateStatusList.forEach(item => {
			item.status = item.DISTRIBUTION_STATE === 'FAILED' ? "failed" : item.DISTRIBUTION_STATE === 'REJECTED' ? "failed" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "completed" : item.DISTRIBUTION_STATE === 'Scheduled' ? "scheduled" : item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? item.DISTRIBUTION_PROGRESS >= 50 ? "pending" : "updating" : "queued";
			selectedCount += item.checked ? 1 : 0;
		});

		softwareUpdateStatusList = _.orderBy(softwareUpdateStatusList, [sortColumn.path], [sortColumn.order]);		
		let rowNumber = (currentPage * pageSize) - pageSize;

		//Model And Software info for moal popup
		let CtrlSoftFileName=unitModelDetails && unitModelDetails.stum_ver ? unitModelDetails.stum_ver:"";
		let CtrlSoftVersion="";
		let CtrlSoftUDate="";

		let MDPFileName=unitModelDetails && unitModelDetails.mpd_filename ? unitModelDetails.mpd_filename :"";
		let MDPVersion="";
		let MDPUDate="";

		let IPDFileName=unitModelDetails && unitModelDetails.ipd_filename ? unitModelDetails.ipd_filename :"";
		let IPDVersion="";
		let IPDUDate="";

		let MachinSetFileName=unitModelDetails && unitModelDetails.machine_setup ? unitModelDetails.machine_setup:"";
		let MachinSetVersion="";
		let MachinSetUDate="";

		let MenuFileName=unitModelDetails && unitModelDetails.FILE_NAME ? unitModelDetails.FILE_NAME:"";
		let MenuVersion="";
		let MenuUDate="";

		let CompilationFileName=unitModelDetails && unitModelDetails.compilation_ver ? unitModelDetails.compilation_ver:"";
		let CompilationVersion="";
		let CompilationUDate="";

		let IOTFileName=unitModelDetails && unitModelDetails.iot_version ? unitModelDetails.iot_version:"";
		let IOTVersion="";
		let IOTUDate="";

		let value="";
		if (unitSoftwareDetails){
			if (unitSoftwareDetails.constrolSoftware){
				value = unitSoftwareDetails.constrolSoftware;				
				CtrlSoftUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
				
			}

			if (unitSoftwareDetails.mdp){
				value = unitSoftwareDetails.mdp;				
				 MDPUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			if (unitSoftwareDetails.ipd){
				value = unitSoftwareDetails.ipd;				
				 IPDUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			
			if (unitSoftwareDetails.machine){
				value = unitSoftwareDetails.machine;				
				 MachinSetUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			if (unitSoftwareDetails.menu){
				value = unitSoftwareDetails.menu;				
				MachinSetUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}			

			if (unitSoftwareDetails.compilation){
				value = unitSoftwareDetails.compilation;				
				CompilationUDate=value.lastUpdatedAt?momentHelper.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}
			//Model And Software info for moal popup End

		}		

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<LoadingPopup loading={loaderIconVisible} />
					{/* <!-- //Loading Progress -->*/}
					<div id="page-content-wrapper">
						<div className="softwareUpdateWrapper">
								<div className="tableHNav">
									<ul>
										<li>
											<Link to="/softwareLibraryUpdate" title={formatMessage({ id: 'KC0837' })} onClick={(e) => this.trackEvent(e, 'Software Library')}><FormattedMessage id="KC0837"/></Link>
											<Link to="#" title={formatMessage({ id: 'KC0523' })} className="active" onClick={(e) => this.trackEvent(e, 'Status')}><FormattedMessage id="KC0523"/></Link>
										</li>
										<li>    
											{/* <button onClick={() => this.onSmartGroupClick()} type="button" className="btn gridSG">&nbsp;</button> */}
											<button type="button" className="btn listView active">&nbsp;</button>
											<button type="button" class={retryIcon && userProfileAuthFeature.is_editable === 1 ?"btn referesh":"btn referesh disabled"} data-toggle="modal" data-target="#RetryConfirmationModal">&nbsp;</button>
											<button type="button" className= {cancelIcon && userProfileAuthFeature.is_editable === 1 ?"btn cancelTransfer":"btn cancelTransfer disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal">&nbsp;</button>
											<button type="button" className={deleteIcon && userProfileAuthFeature.is_editable === 1 ?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
										</li>
									</ul>
								</div>
							
								<div class="selectedUnitHeader">
									<ul>
										<li> 
											<div class="checkBoxListGrp">
												<div class="customCheckbox crticalCBox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Scheduled" type="checkbox" value="Scheduled" checked = {Scheduled} />
													<label for="Scheduled" title={formatMessage({ id: 'KC1727' })}><FormattedMessage id="KC0573"/> <span> {scheduled}</span></label>
												</div>
												<div class="customCheckbox nonCrticalCBox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Queued" type="checkbox" value="Queued" checked = {Queued}/>
													<label for="Queued" title={formatMessage({ id: 'KC1728' })}><FormattedMessage id="KC0691"/> <span>{updatequeued}</span></label>
												</div>
												<div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Updating" type="checkbox" value="Updating" checked = {Updating}/>
													<label for="Updating" title={formatMessage({ id: 'KC1729' })}><FormattedMessage id="KC0692"/> <span>{updating}</span></label>
												</div>
												{/* <div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Pending" type="checkbox" value="Pending" checked = {Pending}/>
													<label for="Pending">Operator Install <span>{pending}</span></label>
												</div> */}
												<div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Failed" type="checkbox" value="Failed" checked = {Failed}/>
													<label for="Failed" title={formatMessage({ id: 'KC1730' })}><FormattedMessage id="KC0359"/> <span>{updatefailed}</span></label>
												</div>
												<div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Completed" type="checkbox" value="Completed" checked = {Completed}/>
													<label for="Completed" title={formatMessage({ id: 'KC1731' })}><FormattedMessage id="KC0324"/> <span>{updatecomplete}</span></label>
												</div>
												<div class="customCheckbox">
													<input onClick={(e) => this.checkBoxHandler(e)} id="Canceled" type="checkbox" value="Canceled" checked = {Canceled}/>
													<label for="Canceled" title={formatMessage({ id: 'KC1732' })}><FormattedMessage id="KC0694"/> <span>{updatecanceled}</span></label>
												</div>
											</div> 
											
										</li> 
										<li>
											<div class="dateRangeForm">
												<span><FormattedMessage id="KC0695"/>:</span>
												<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
												<span><FormattedMessage id="KC0696"/>:</span>
												<DatePicker locale={datePickerLocale} popperModifiers={{preventOverflow: {enabled: true,},}} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
									</ul>
								</div>
								<div className="updateStatusTableOuter">
								<div className="statusTable">
									<TableHeader
										userMgmtListScreen={true}
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns} />
								</div>

							<div className="statusTableSrlBar">
									<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
										{/* <!-- Header 01 --> */}
										{/* softwareUpdateStatusList */}
										{softwareUpdateStatusList.map((item, i) => {
											return (
												<React.Fragment key={i}>
													<div className="statusTable">
														<ul className="tBody">
															<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc` + item.ID} aria-expanded="false" aria-controls="availableUpdatesAcc1" className="btn collapseExpand collapsed">&nbsp;</a></li>
															<li>{rowNumber + i + 1}</li>
															<li>{item.UNITID}</li>
															<li>{item.assest_number}</li>
															<li>{item.locationName}</li>
															<li>{item.FILE_NAME?item.FILE_NAME:""} </li>
															<li>{item.VERSION}</li>
															<li>{item.DISTRIBUTED_BY}</li>
															<li>{item.START_TIME !== "NULL" && item.START_TIME !== null? momentHelper.GetDateTimeByFormat(item.START_TIME, 'LLL') : ""}</li>
															<li>{item.END_TIME !== "NULL" && item.END_TIME !== null? momentHelper.GetDateTimeByFormat(item.END_TIME,  'LLL') : ""}</li>
															<li>
																<img src={
																	item.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default 
																	: item.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default 
																	: item.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default
																	: item.DISTRIBUTION_STATE === 'Operator-Install' || item.TRANSFER_TYPE ===  "operator-install" ? require('../../../images/icons/usersD.svg').default 
																	: item.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default 
																	: item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png') 
																	: require('../../../images/icons/UpdateD.svg').default} />
															</li>
															<li>
																<div className="progress">
																	<div className={item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? "progress-bar cancelStatus" : item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'Scheduled' || item.DISTRIBUTION_STATE === 'Queued' ? "progress-bar updatingSW" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "progress-bar completedStatus" : "progress-bar updatingSW"} style={{ width: item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? "100%" : item.DISTRIBUTION_PROGRESS + '%'  }}></div>
																</div>
															</li>
															<li>
																{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1733' })} direction="left" className="targetdisabled">
																	<div className={"customCheckbox customCheckboxWOlabel disabled"}>
																		<input id={i} name={i} type="checkbox"
																			checked={item.checked}
																			disabled
																			onChange={(e) => this.onSelectRole(e, item)} />
																		<label htmlFor={i}></label>
																	</div>
																</Tooltip> : <div className={"customCheckbox customCheckboxWOlabel"}>
																	<input id={i} name={i} type="checkbox"
																		checked={item.checked}
																		onChange={(e) => this.onSelectRole(e, item)} />
																	<label htmlFor={i}></label>
																</div>}
                                                                
															</li>
														</ul>
													</div>
													{/* <!-- Header 01 Content --> */}
													<div id={`availableUpdatesAcc` + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`availableUpdatesAccH` + item.ID}>
														<div className="panel-body updateStatusExpTable statusExpTableW">
															<div class="statusExpTable">
																<ul>
																	<li>
																		<h5><FormattedMessage id="KC1554"/></h5>
																		<div class="packageDetails">
																			<ul>
																				<li>
																					{/* <span class="mediaFileSpan"><img src="images/unit/Crem-PSL50ES.svg" /></span> */}
																				</li> 
																				<li>
																					{item.SOFTWARE_EXIST && item.SOFTWARE_EXIST === "true" && <h6 className="installedText"><FormattedMessage id="KC2299" /></h6>}
																					<h6><span><FormattedMessage id="KC1504"/>: </span> {item.FILE_NAME?item.FILE_NAME:""}</h6>
																					<h6><span><FormattedMessage id="KC0834"/>: </span> {item.VERSION?item.VERSION:""} </h6>
																					<h6><span><FormattedMessage id="KC0102"/>: </span> {item.TYPE?item.TYPE:""}</h6>
																					<h6><span><FormattedMessage id="KC1144"/>: </span> <FormattedMessage id="KC1734" values={{ size: item.FILE_SIZE?item.FILE_SIZE:0 }}/></h6> 
																				</li> 
																			</ul>
																		</div>
																	</li>
																	<li>
																		<h5><FormattedMessage id="KC1781"/></h5>
																		<div class="updateHistoryT">
																			{item.lastStatus && item.lastStatus.map((itemStatus, i) =>(<Fragment key= {i}>
																				<ul>
																					<li>{i + 1}</li> 
																					<li><img src={itemStatus.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default : itemStatus.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default : itemStatus.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default : itemStatus.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default : require('../../../images/icons/UpdateD.svg').default} /></li>
																					<li><FormattedMessage id="KC1555"/></li>
																					<li>{itemStatus.END_TIME ? momentHelper.GetDateTimeByFormat(itemStatus.END_TIME,  'LLL') : ""}</li> 
																				</ul>
																			</Fragment>))}
																		</div> 
																	</li>
																	<li>
																		<button type="button" onClick={(e) => this.getUnitPopUpDetails(item)} class="btn btn-default unitDetailsBtn" data-toggle="modal" data-target="#modelInfoModal"><FormattedMessage id="KC1505"/></button>
																	</li>
																</ul>
															</div> 
														</div>
													</div>

													{/* Info Modal */}
													<div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="modelInfoModal" tabindex="-1" role="dialog" aria-labelledby="modelInfoModalLabel">
														<div class="modal-dialog modal-md infoModal sgModal">
															<div class="modal-content">  
																<div class="modal-header modal-header-danger">
																	<FormattedMessage id="KC1782"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
																</div>
																<div class="modal-body"> 
																	<div class="modelInfoModal">  
																		<div class="modelInfoModalL"> 
																			<div class="modelInfoModalT"><FormattedMessage id="KC0673"/></div>
																			<div class="modelInfoDetails">
																				<ul>
																					{/* <li class="modelImgView"><img src="images/unit/Crem-PSL50ES.svg" /></li>  */}
																					<li class="modelImgView"><img alt={unitLocationDetails.Brand_Alt} src={unitLocationDetails.Brand_Logo} /></li> 
																					<li>
																						<h6><span><FormattedMessage id="KC0064"/>: </span> {unitModelDetails?unitModelDetails.MODEL_NAME:""}</h6>
																						<h6><span><FormattedMessage id="KC0841"/>: </span> {unitModelDetails?unitModelDetails.equip_id:""}</h6>
																						<h6><span><FormattedMessage id="KC0845"/>: </span> {unitModelDetails?unitModelDetails.assetNumber:""}</h6>
																						<h6><span><FormattedMessage id="KC1506"/>: </span>{unitModelDetails?unitModelDetails.network_type:""} </h6>
																						<h6><span><FormattedMessage id="KC1507"/>: </span>{unitModelDetails?unitModelDetails.signal_level:""}</h6> 
																					</li> 
																				</ul>   
																			</div>
																		</div>
																		<div class="modelInfoModalR">   
																		<div class="modelInfoModalT"><FormattedMessage id="KC0050"/></div>
																		<div class="modelInfoDetails">
																			<ul> 
																				<li>
																					<h6><span><FormattedMessage id="KC0675"/> </span> {unitLocationDetails?unitLocationDetails.location:""}</h6>
																					<h6><span><FormattedMessage id="KC0099"/>: </span> {unitLocationDetails?unitLocationDetails.street:""}</h6>
																					<h6><span><FormattedMessage id="KC0098"/>: </span> {unitLocationDetails?unitLocationDetails.city:""}</h6>
																					<h6><span><FormattedMessage id="KC1508"/>: </span>{unitLocationDetails?unitLocationDetails.zipcode:""} </h6> 
																				</li> 
																			</ul>   
																		</div>
																		</div>
																		<div class="modelInfoModalT"><FormattedMessage id="KC0546"/></div>
																		<div class="packageStatusTable">
																			<ul class="tHead">
																				<li>&nbsp;</li>
																				<li><FormattedMessage id="KC0737"/></li>   
																				<li><FormattedMessage id="KC0834"/></li> 
																				<li><FormattedMessage id="KC1557"/></li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC1558"/></li>
																				<li>{CtrlSoftFileName}</li>
																				<li>{CtrlSoftVersion}</li> 
																				<li>{CtrlSoftUDate}</li> 
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC0233"/></li>
																				<li>{MDPFileName}</li>
																				<li>{MDPVersion}</li>
																				<li>{MDPUDate}</li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC0234"/></li>
																				<li>{IPDFileName}</li>
																				<li>{IPDVersion}</li>
																				<li>{IPDUDate}</li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC0235"/></li>
																				<li>{MachinSetFileName}</li>
																				<li>{MachinSetVersion}</li>
																				<li>{MachinSetUDate}</li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC0546"/></li>
																				<li>{MenuFileName}</li>
																				<li>{MenuVersion}</li>
																				<li>{MenuUDate}</li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC0736"/></li>
																				<li>{CompilationFileName}</li>
																				<li>{CompilationVersion}</li>
																				<li>{CompilationUDate}</li>
																			</ul>
																			<ul class="tBody">    
																				<li><FormattedMessage id="KC1559"/></li>
																				<li>{IOTFileName}</li>
																				<li>{IOTVersion}</li>
																				<li>{IOTUDate}</li>
																			</ul>
																		</div>
																		
																		
																	</div> 
																</div> 
															</div>
														</div>
													</div>
													{/* Info Moda */}
												</React.Fragment>
											)
										})
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={softwareUpdateStatusList !== undefined ? softwareUpdateStatusList.length : 0} classname={'norecordsfoundbluetext'} />
									</div>
								{ this.state.totalPages && this.state.totalPages > 1 ? <GoToPagination
									totalPageNumber={this.state.totalPages}
									pageNumber={this.state.currentPage}
									navigateTo={(p) => this.onPageChange(p)} /> : null }
								</div> 

						</div>
						<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					</div>
					{/* Modal for cancel */}
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >
								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC1509"/>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button"  onClick={() => this.trackEvent_For_Cancel("Canceled Clear Distributions")}><FormattedMessage id="KC0580"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onDelete('clear')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
								</div>
							</div>
						</div>
					</div>		

					{/* Modal for delete */}
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >
								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC0579"/>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button"  onClick={() => this.trackEvent_For_Cancel("Canceled Delete Distributions")}><FormattedMessage id="KC0580"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onDelete('delete')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
								</div>
							</div>
						</div>
					</div>	
					{/* Modal for Retry */}
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="RetryConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >
								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC0757"/>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Canceled Retry Distributions")}><FormattedMessage id="KC0580"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onRetry('delete')} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
								</div>
							</div>
						</div>
					</div>		
					<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" previousPage="Software List" activeClass="softwareNav" history={this.props.history} />			
					{/* <UserManagementHeader headerName="Software Management" activeClass="softwareNav" /> */}
                    <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />					
				</div>
			</React.Fragment>

		)
	}
};

function mapStateToProps(state) {
	return {
		softwareUpdateStatusInfo: state.softwareUpdateStatusInformation.softwareUpdateStatusInformation,
		filterlistInformtion: state.filterModel,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareUpdateStatus,
		getSelectedFilterList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwareStatus));