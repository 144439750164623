import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import TotalProductCookedChart from './merrychefWidgets/totalProductsCookedChart';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { getAverageDrinksList } from '../../actions/reportManagement/averageDrinksAction';
import TotalOnPeriodTimeWidget from './mercoUtilizationWidgets/totalOnPeriodTime';
import TotalOnTimePercentageWidget from './mercoUtilizationWidgets/totalOnTimePercentage';
import GaugeChart from './globalWidgets/gaugeChart';
import SingleDataTrendFilterWidget from '../unitDashboard/globalWidgets/singledatatrendfilterwidget';
import CleaningTable from './globalWidgets/cleaningTable';
import AverageCookCount from '../unitDashboard/merrychefWidgets/averageCookCount';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import TotalCooksLineChart from '../reportManagement/garlandWidgets/TotalCooksLineChart';
import TotalCooksPerPlatenChart from '../reportManagement/garlandWidgets/TotalCooksPerPlatenChart';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

am4core.useTheme(am4themes_animated);

var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class Clean extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			avgType: 'Month',
			topTenType: 'Month',
			peakHourType: 'Month',
			noOfDrinksType: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sd: '',
			ed: '',
			startDate: todaysDate,
			endDate: todaysDate,
			sdAvgDrinks: '',
			edAvgDrinks: '',
			startDateAvgDrinks: todaysDate,
			endDateAvgDrinks: todaysDate,
			loaderIconVisible: true,
			hideDateRange: true,
			datapickflag: false,
			isGlobalDropdownChange:false,
			globalDropdownFilter: 'Month',
			globalDropdownFilterStartDate: '',
			globalDropdownFilterEndDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);

		this.handleChangeStartAvgDrinks = this.handleChangeStartAvgDrinks.bind(this);
		this.handleChangeEndAvgDrinks = this.handleChangeEndAvgDrinks.bind(this);

	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		brandID = "CREM";
		this.props.getAverageDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	handleChangeStart(date, mindate) {
		this.setState({ startDate: date, sd: date, endDate: date, ed: moment(date).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEnd(date) {
		let selectedtext = 'Date Range';
		this.setState({ endDate: date });
		var e = { target: { text: selectedtext } };
		this.onDropDownHandler(e, 'TotalDrinks');
	}

	//Average Drinks Produced

	handleChangeStartAvgDrinks(dateAvgDrinks, mindate) {
		this.setState({ startDateAvgDrinks: dateAvgDrinks, sdAvgDrinks: dateAvgDrinks, endDateAvgDrinks: dateAvgDrinks, edAvgDrinks: moment(dateAvgDrinks).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEndAvgDrinks(dateAvgDrinks) {
		let selectedtext = 'Date Range';
		this.setState({ endDateAvgDrinks: dateAvgDrinks });
		var e = { target: { text: selectedtext } };
		this.onDropDownHandler(e, 'AverageDrinks');
	}

	onDropDownHandler(e, type = '') {
		const { text } = e.target;
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = "CREM";
		if (text === "Date Range") {
			if (type === "TotalDrinks") {
				todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
			}
			else if (type === "AverageDrinks") {
				todaysDateFormatted = format(this.state.endDateAvgDrinks, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateAvgDrinks, 'YYYY-MM-DD');
			}
			else if (type === "PeakHourDrinks") {
				todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
			}
			else if (type === "TopTenDrinks") {
				todaysDateFormatted = format(this.state.endDateTopTen, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateTopTen, 'YYYY-MM-DD');
			}
			else {
				todaysDateFormatted = format(this.state.endDateHeatMap, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateHeatMap, 'YYYY-MM-DD');
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}

		if (type === "TotalDrinks") {
			this.setState({ type: text });
			this.props.getTotalDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else if (type === "AverageDrinks") {
			this.setState({ avgType: text });
			this.props.getAverageDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else if (type === "PeakHourDrinks") {
			this.setState({ peakHourType: text });
			this.props.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "TopTenDrinks") {
			this.setState({ topTenType: text });
			this.props.getTopTenTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "HeatMapTable") {
			this.setState({ noOfDrinksType: text });
			this.props.getheatMapTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
	}

	handleRangeChange(which, payload) {
		console.log(which, payload);
		this.setState({
			[which]: {
				...this.state[which],
				...payload,
			},
		});
	}

	handleChangeGlobalStart(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermTotalCleaning.handleChangeStart(date);
				this.refs.convothermAvgCleaning.handleChangeStart(date);
				// this.refs.convothermAvgCleaningPerDay.handleChangeStart(date);
			} else if (currentUnit.BRAND_NAME === "GARLAND") {
				this.refs.garlandAvgPlatenCyclePerDay.handleChangeStart(date);
				if(this.refs.garlandAvgPlatenCycle){
					this.refs.garlandAvgPlatenCycle.handleChangeStart(date);
				}
				this.refs.garlandAvgNoOfPlaten.handleChangeStart(date)
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermTotalCleaning.handleChangeEnd(date);
				this.refs.convothermAvgCleaning.handleChangeEnd(date);
				// this.refs.convothermAvgCleaningPerDay.handleChangeEnd(date);
			} else if (currentUnit.BRAND_NAME === "GARLAND") {
				this.refs.garlandAvgPlatenCyclePerDay.handleChangeEnd(date);
				if(this.refs.garlandAvgPlatenCycle){
					this.refs.garlandAvgPlatenCycle.handleChangeEnd(date);
				}
				this.refs.garlandAvgNoOfPlaten.handleChangeEnd(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const { currentUnit } = this.props;
		const filterType = e.nodeText;

		this.setState({
			globalDropdownFilter: filterType,
			isGlobalDropdownChange:true,
			datapickflag : filterType === 'Date Range'
		});
		if (currentUnit.BRAND_NAME === "CONVOTHERM") {

			this.refs.convothermTotalCleaning.fetchData(this.refs.convothermTotalCleaning.state.holder_id, filterType)
			this.refs.convothermTotalCleaning.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.convothermAvgCleaning.fetchData(this.refs.convothermAvgCleaning.state.holder_id, filterType)
			this.refs.convothermAvgCleaning.setState({ filterType, datapickflag: filterType === 'Date Range' });
			if(filterType !== 'Date Range'){
				this.refs.convothermAvgCleaningPerDay.handleTotalProductCooked(filterType)
				this.refs.convothermAvgCleaningPerDay.setState({ filterType, datapickflag: filterType === 'Date Range' });
			}
		} else if (currentUnit.BRAND_NAME === "GARLAND") {
			this.refs.garlandAvgPlatenCyclePerDay.fetchData(this.refs.garlandAvgPlatenCyclePerDay.state.holder_id, filterType)
			this.refs.garlandAvgPlatenCyclePerDay.setState({ filterType, datapickflag: filterType === 'Date Range' });

			if(this.refs.garlandAvgPlatenCycle){
			this.refs.garlandAvgPlatenCycle.fetchData(filterType);
			this.refs.garlandAvgPlatenCycle.setState({ filterType, datapickflag: filterType === 'Date Range' });
			}

			this.refs.garlandAvgNoOfPlaten.handleTotalProductCooked(filterType);
			this.refs.garlandAvgNoOfPlaten.setState({ filterType, datapickflag: filterType === "Date Range" })

		}

	}

	//  woopra event
	reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackTitle}`);
	};

	render() {
		let { loaderIconVisible, isGlobalDropdownChange, globalDropdownFilter, globalDropdownFilterStartDate, globalDropdownFilterEndDate } = this.state;
		const { formatMessage } = this.props.intl;
		currentUnit = this.props.currentUnit;
		let productNumber = ((currentUnit.MODEL_NAME === "XPE12") || (currentUnit.MODEL_NAME === "XPG12") || (currentUnit.MODEL_NAME === "ME-12") || (currentUnit.MODEL_NAME === "MG-12"));
		let cleanTabHeader = null;
		// MERCO and MERRYCHEF Tab header display
		if (currentUnit.BRAND_NAME === 'MERCO' || currentUnit.BRAND_NAME === 'MERRYCHEF') {
			cleanTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} onClick={(e)=>{e.trackTitle="Utilization";this.reportEvent(e)}}><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}

		// GARLAND Platen Cycles Tab header display
		if (currentUnit.BRAND_NAME === 'GARLAND') {
			cleanTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}} ><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({ id: 'KC1799' })} className="active"><FormattedMessage id="KC1799"/></Link> 
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} onClick={(e)=>{e.trackTitle="Utilization";this.reportEvent(e)}}><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}

		// CONVOTHERM Clean Tab header display
		else {
			cleanTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({ id: 'KC0405' })} className="active"><FormattedMessage id="KC0405"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} onClick={(e)=>{e.trackTitle="Utilization";this.reportEvent(e)}}><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="reportNav" />
					<div id="page-content-wrapper">
						{/* Reports and Analytics Clean tab header */}
						{cleanTabHeader}
						{/* Level 1 | Widgets - 2 (Merco) | Widgets - 3 (Merrychef) | Widgets - 2 (Convotherm) */}
						<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<SingleDataTrendFilterWidget ref="convothermTotalCleaning" properties={this.props} widgetID="33" widgetName="TOTAL CLEANINGS" widgetNameStringID="KC0394" classValue="colm6D colm6D1" isTimeDisplay="false" />
								<SingleDataTrendFilterWidget ref="convothermAvgCleaning" properties={this.props} widgetID="45" widgetName="AVG. CLEANINGS PER DAY" widgetNameStringID="KC0987" classValue="colm6D colm6D2" isTimeDisplay="false" /> 
							</div>
						</div>
						{/* Level 1 | 2 Widgets (Garland) TOTAL PLATEN CYCLES | AVG. PLATEN CYCLES PER LANE | AVG. PLATEN CYCLES PER DAY */}
						<div class={localStorage.getItem("brandName") === "GARLAND" ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<AverageCookCount widgetname="TOTAL PLATEN CYCLES" widgetnameStringID="KC0326" ProductsProduced={this.props} classValue="colm4D colm4D1" isTimeDisplay="false" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
								{productNumber ? "" :<TotalCooksPerPlatenChart ref="garlandAvgPlatenCycle" properties={this.props} widgetID="108" widgetName="AVG. PLATEN CYCLES PER LANE" widgetNameStringID="KC0327" classValue="colm4D colm4D2" isTimeDisplay="false" />}
								<SingleDataTrendFilterWidget ref="garlandAvgPlatenCyclePerDay" properties={this.props} widgetID="107" widgetName="AVG. PLATEN CYCLES PER DAY" widgetNameStringID="KC0328" classValue="colm4D colm4D3" isTimeDisplay="false" />
							</div>
						</div>
						{/* Level 2 | Widgets - 2 (Merco) | Widgets - 3 (Merrychef) */}
						<div class={localStorage.getItem("brandName") === "MERCO" ? "gridViewTable" : "displaynone"}>
							<div class="gridView">
								<TotalOnPeriodTimeWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
								<TotalOnTimePercentageWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
							</div>
						</div>
						<div class={localStorage.getItem("brandName") === "MERRYCHEF" ? "gridViewTable gridViewcolm4DSM" : "displaynone"}>
							<div class="gridView">
								<GaugeChart properties={this.props} widgetID="28" widgetName="Hi-Limit, Cavity Overheat" widgetNameStringID="KC1801" gaugeName="Oven On Time" gaugeNameStringID="KC0271"/>
								<GaugeChart properties={this.props} widgetID="27" widgetName="Left Magnetron" widgetNameStringID="KC0278" gaugeName="Magnetron On Time" gaugeNameStringID="KC0282" /> 
								<GaugeChart properties={this.props} widgetID="26" widgetName="Right Magnetron" widgetNameStringID="KC0279"  gaugeName="Magnetron On Time" gaugeNameStringID="KC0282" /> 
							</div>
						</div>
						<div class={(localStorage.getItem("brandName") === "CONVOTHERM" || localStorage.getItem("brandName") === "GARLAND") ? "gridViewTable" : "displaynone"}>
							<div class="gridView">
								<div class="colm12D">
									{localStorage.getItem("brandName") === "GARLAND" ? <TotalCooksLineChart ref="garlandAvgNoOfPlaten" properties={this.props} widgetID="109" widgetName="PLATEN CYCLES" widgetNameStringID="KC0329" chartTitle="Avg. Number of Platen Cycles per day" chartTitleStringID="KC0330" disableDatePicker={false} /> :
										<TotalProductCookedChart properties={this.props} ref="convothermAvgCleaningPerDay" widgetID="48" widgetName="Cleanings" widgetNameStringID="KC0407"  chartTitle="Avg. cleanings per day" chartTitleStringID="KC1888" disableDatePicker={true} />}
								</div>
							</div>
						</div>
						{/* Level 3 | Widgets - 1 | Cleaning Summary Table (Convotherm) */}
						{localStorage.getItem("brandName") === "CONVOTHERM" ? <CleaningTable properties={this.props} widgetName="CLEANING DATA" widgetNameStringID="KC0408" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} /> : ""}
					</div>
				</div>
			</React.Fragment>
		)
	}
};
function mapStateToProps(state) {
	return {
		averageDrinksInfo: state.averageDrinksInformation,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getAverageDrinksList,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Clean));
