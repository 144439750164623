import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UnitsMapView from './unitsMapView';
import AllLocationHeaderLayout  from '../layoutSection/allLocationHeaderLayout';
import appconstants from '../../utils/appConstants'
import { FormattedMessage, injectIntl } from 'react-intl';
import $ from 'jquery';

class UnitsMap extends Component {
    state = {
        loaderIconVisible: true
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
          }, 2000)
     this.updateDimensions();        
    }
    updateDimensions() {
        var windowHeight = $(window).height();
        var pageTabHeader = $(".pageTabHeader").height();  
        var mapWrapperHeight = ((windowHeight) - (pageTabHeader)) - 70; 
        $("#mapdiv").css("height", mapWrapperHeight);
    } 

	render() {
        const { loaderIconVisible } = this.state;
        const { formatMessage } = this.props.intl;
		return (
			<React.Fragment>
                <div id="wrapper">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <AllLocationHeaderLayout activeClass="MapViewA" filterKey={appconstants.filterKey} history={this.props.history} />
                    <div id="page-content-wrapper">
                        <div className="pageTabHeader marginTop2px">
                        <ul>
                            {/* <li class="siteTreeIcon">
                                <Link to="/siteTree" title="Site Tree" id="sitetreeInfoToggle" class="sitetreeInfoShow">&nbsp;</Link>
                            </li> */}
                            <li>
                                <Link to="/locationsMap" title={formatMessage({id: 'KC0044'})} class="locationsView"><FormattedMessage id='KC0044' /></Link>
                            </li>
                            <li>
                                <Link to="/unitsMap" title={formatMessage({id: 'KC0045'})} class="unitsView active"><FormattedMessage id='KC0045' /></Link>
                            </li>
                            
                        </ul>
                        </div>
    
                                <div id="googleMap" style={{backgroundColor:'#595959'}}>
                                <div><UnitsMapView/></div>
                                </div>
                            </div>	
                        </div>
                    </React.Fragment>
        )
    }
};

export default injectIntl(UnitsMap);
