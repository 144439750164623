import React, { Component } from 'react';
import http from '../../../service/httpService';
import { UserManagement, UserMgmtAddUser} from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { injectIntl,FormattedMessage } from 'react-intl';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            user: {
                firstName: "",
                lastName: "",
                email: "",
                retypeEmail: "",
                countryCode: "",
                phoneNumber: "",
                customerId: localStorage.getItem("customerId")
            },
            roleId: props.location.role_id,
            email: props.location.email,
            userGroup: [],
            userPreference: [],
            fields: {},
            errors: {},
            countryCodeList: [],
            loaderIconVisible:false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.submitAddUserForm = this.submitAddUserForm.bind(this);
        this.setUserToState = this.setUserToState.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2";
        $("#btnnext").addClass("btn-default-text activeState");
        this.setState({
            loaderIconVisible: true
        });
        let customerid= localStorage.getItem("customerId");
		let customeremail= this.state.email;
        let { userModel } = this.props;
        
        if (this.props.userModel.previousPage === null || this.props.userModel.previousPage === undefined || this.props.userModel.previousPage === null)
        {
        await http({
                method: "GET",
                    url: UserManagement.geteditUserDetails,            
                    headers: {'customer_id':customerid,
                    'email':customeremail,
                    'Content-Type': 'application/json',
                    'base_country': localStorage.getItem("basecountry"),
                    'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                    },
                    data:{}
            }).then(response => {			
                let resdata = {};
                resdata = response.data;
                this.setState({
                    fields: {
                         firstName: resdata && resdata.first_name && resdata.first_name !== "" ? resdata.first_name : resdata.name,
                        lastName: resdata && resdata.last_name ? resdata.last_name : "",
                        email: customeremail,
                        retypeEmail: customeremail,
                        country_code: resdata.country_code.replace("+",""),
                        phoneNumber: resdata.phone_number,
                        customerId: localStorage.getItem("customerId"),
                    },
                    countryCodeList: [] 
                })
                this.setState({ loaderIconVisible: false });    
                this.setState({ catchSelCountryCode: response.data.data.country_code.replace("+","") })
                $("#countrycode").val(this.state.catchSelCountryCode);
            }).catch(err => {this.setState({ loaderIconVisible: false }); console.log('catch exception occured');         
            }); 
        }
        else
        {
            this.setState({
                fields: userModel.user,
                roleId: userModel.roleId,
                countryCodeList: userModel.countryCodeList
            });
        }
        try {
            if (userModel.countryCodeList.length === 0) {
                await this.getCountryCodeList();
            }
        } catch (error) {
            // Errors Goes Here
        }
        this.setState({ loaderIconVisible: false });
    }

    responseSuccessParser = (response) => {
        return new Promise((resolve, reject) => {
            const { data } = response;
            if (data) {
                resolve(response);
            } else {
                let { data } = response;
                if (data) {
                    let { body } = data;
                    if (body != null)
                        reject(body);
                }
            }
        });
    }

    selectCountryCode = (e) => {
        if (e.target.value) {
            this.state.fields.country_code = e.target.value;
            this.state.fields["country_code"] = e.target.value;
        }
    }

    getCountryCodeList = async () => {
        let result = await http.get(UserMgmtAddUser.getCountryCodeApi,{
            data:{}});
        let { data } = result;
        if (data) {
            let selectedCountry = data.data.filter(item => item.code !== "");
            this.setState({
                countryCodeList: selectedCountry
            });
        }
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    submitAddUserForm(e) {
        if (this.validateForm()) {
            let fields = {};
            fields["firstName"] = "";
            fields["lastName"] = "";
            fields["email"] = "";
            fields["retypeEmail"] = "";
            fields["phoneNumber"] = "";
            this.setState({ fields: fields });
        }
    }


    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "First Name";
        }

        if (typeof fields["firstName"] !== "undefined") {
            if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["firstName"] = "First Name";
            }
        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Last Name";
        }

        if (typeof fields["lastName"] !== "undefined") {
            if (!fields["lastName"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["lastName"] = "Last Name";
            }
        }

        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = "KC1077";
        }

        if (typeof fields["phoneNumber"] !== "undefined") {
            if (!fields["phoneNumber"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["phoneNumber"] = "KC1078";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    setUserToState = (e) => {
        let { userModel } = this.props;
        userModel.user = this.state.fields;
        userModel.roleId = this.state.roleId;
        userModel.previousPage = "/editUser";
        this.state.countryCodeList.forEach(item => {
            if (item.code === this.state.fields["country_code"]) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        userModel.countryCodeList = this.state.countryCodeList;
        this.props.setUserDataModel(userModel);
    }

    render() {
        const { loaderIconVisible } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            
                <div id="adminWrapper">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>
                    <div id="page-content-wrapper">
                    <div class="userManagementWrapper">
                        <div class="addUserHeader">
                            <ul>
                                <li>
                                    <Link to="/userManagementList" title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC0583"/>
					                    </li>
                                <li>
                                    < Link id="btnnext" to={{
                                        pathname: "/chooseUserRole",
                                        data: this.state.fields
                                    }} title={formatMessage({ id: "KC0543"})} onClick={(e) => this.setUserToState(e)}><FormattedMessage id={'KC0543'} /></Link>
                                </li>
                            </ul>
                        </div>

                        <div class="addUserFormWrapper">
                            <div class="addUserForm">
                                <ul>
                                    <li>
                                        <div class="input-group">
                                            <input type="text" name="name" class="form-control disabled" placeholder={formatMessage({ id: "Enter First Name"})} value={this.state.fields.firstName} />
                                            <span class="requiredField">*</span>
                                        </div> 
                                        <div className="errorMsg">{this.state.errors.firstName}</div>
                                    </li>
                                    <li>
                                        <div className="input-group">
                                            <input type="text" name="lastName" className="form-control disabled" placeholder={formatMessage({ id: "Enter Last Name"})} value={this.state.fields.lastName} />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errors.lastName}</div>
                                    </li>
                                    <li>
                                        <div class="input-group">
                                            <input type="email" name="email" class="form-control disabled" placeholder={formatMessage({ id: "KC0136"})} value={this.state.fields.email} />
                                            <span class="requiredField">*</span>
                                        </div> 
                                    </li>
                                    <li>
                                        <div class="input-group">
                                            <input type="text" name="phoneNumber" class="form-control phoneNumber disabled" value={ `+${this.state.fields.country_code} ${this.state.fields.phoneNumber}` }  />
                                            <span class="requiredField">*</span>
                                        </div>
                                    </li>
                                    <li class="infoLi">
                                    <p className="formInfoIcon"><FormattedMessage id={'KC0599'} /></p>
                                    </li>
                                </ul>
                                <div className="requiredNoteDiv"><FormattedMessage id={'KC0654'} /></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <UserManagementHeader  headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav"/>
            </div>
        )
    }
};

function mapStateToProps(state) {

    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditUser));
