import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
export const ORG_CATEGORY_DETAILS_REQUEST = 'ORG_CATEGORY_DETAILS_REQUEST';
export const ORG_CATEGORY_DETAILS_SUCCESS = 'ORG_CATEGORY_DETAILS_SUCCESS';
export const ORG_CATEGORY_DETAILS_FAIL = 'ORG_CATEGORY_DETAILS_FAIL';

export function getOrganizationCategoryDetails(req) {
    let customerIdValue = localStorage.getItem("customerId");
    return dispatch => {
        dispatch({
            type: ORG_CATEGORY_DETAILS_REQUEST
        })
        http.get(api.organizaionCategoryDetailsUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                     customerid: customerIdValue
                },
                data:{}
            })
        .then(response => {
            console.log('ORG_VIEWInfo' ,response.data);
            dispatch({
                type: ORG_CATEGORY_DETAILS_SUCCESS,
                OrganizationCategoryDetailsInformation: response.data,
             })
        })            
        .catch(error => {
                dispatch({
                    type: ORG_CATEGORY_DETAILS_FAIL,
                    error
                })
            })
    }
}


