import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import _ from 'lodash';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactDragListView from "react-drag-listview";
import { UserProfile, MenuUploadAPI, organizationNode } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID_VALUE, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import http from '../../../../../service/httpService';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import SuccessMoal from '../../../../modal/success-model';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

var LZString = require('lz-string');
var AdmZip = require('adm-zip');
var request = require('request');
var cookbookJSON = require('../../Templates/CookbookJSON');
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class CookbookSubCategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryList: [],
            deleteCategoryVisible: false,
            editEnabled: false,
            isChanged: false,
            isEdit: false,
            isImport: false,
            showDiscardPopup: false,
            isDeleteCategory: false,
            selectedCategory: {},
            selectedCategoryName: "",
            cookbook_name: '',
            errorCookbookName: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSaveCookbookButtonClick = this.onSaveCookbookButtonClick.bind(this);
        this.addSubCategory = this.addSubCategory.bind(this);
        this.onclickEditCookbook = this.onclickEditCookbook.bind(this);
        this.onDeleteCategory = this.onDeleteCategory.bind(this);
        this.onCategorySelect = this.onCategorySelect.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onCookbookNameChange = this.onCookbookNameChange.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        // this.onDragEnd = this.onDragEnd.bind(this);
        // this.onDragReceipeEnd = this.onDragReceipeEnd.bind(this)
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let selectedCategory = localStorage.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);    
            let finalRecipeList = selectedCategoryDetailsObj ? selectedCategoryDetailsObj.recipe_items && selectedCategoryDetailsObj.recipe_items.filter(item => item.length == undefined) : [] ;
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            this.setState({
                categoryList: selectedCategoryDetailsObj.subCategory,
                selectedCategoryName: selectedCategoryObj.categoryName,
                isChanged: (selectedCategoryDetailsObj.subCategory && selectedCategoryDetailsObj.subCategory.length > 0) || (selectedCategoryDetailsObj.recipe_items && selectedCategoryDetailsObj.recipe_items.length > 0) ? true : false,
                recipeList: finalRecipeList,
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.Import,
                cookbook_name: libraryDetails.cookbookName,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    // onDragEnd(result) {
        // // dropped outside the list
        // if (!result.destination) {
            // return;
        // }

        // const items = reorder(
            // this.state.categoryList,
            // result.source.index,
            // result.destination.index
        // );

        // this.setState({
            // categoryList: items,
        // });

        // let selectedCategory = localStorage.getItem("selectedCategory");
        // let selectedCategoryObj = JSON.parse(selectedCategory);

        // let categoryDetails = localStorage.getItem("resourceDetails");
        // let categoryDetailsObj = JSON.parse(categoryDetails);
        // var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

        // categoryDetailsObj[foundIndex]['subCategory'] = items;

        // localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
    // }

    // onDragReceipeEnd(result) {
        // // dropped outside the list
        // if (!result.destination) {
            // return;
        // }

        // const items = reorder(
            // this.state.recipeList,
            // result.source.index,
            // result.destination.index
        // );

        // this.setState({
            // recipeList: items,
        // });
       
        // let selectedCategory = localStorage.getItem("selectedCategory");
        // let selectedCategoryObj = JSON.parse(selectedCategory);

        // let categoryDetails = localStorage.getItem("resourceDetails");
        // let categoryDetailsObj = JSON.parse(categoryDetails);
        // var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

        // categoryDetailsObj[foundIndex]['recipe_items'] = items;

        // localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
    // }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onDeleteConfirm = () => {
        this.setState({
            loaderIconVisible: true
        })
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
        //categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID === selectedCategoryObj.subCategory.ID);
        //let changedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);
        let changedSubCategoryDetailsObj = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID !== this.state.selectedCategory.ID);
        categoryDetailsObj[0].subCategory && categoryDetailsObj[0].subCategory.filter(c => c.ID !== this.state.selectedCategory.ID);
        categoryDetailsObj[foundIndex].subCategory = changedSubCategoryDetailsObj;
        localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
        this.setState({
            loaderIconVisible: false,
            categoryList: changedSubCategoryDetailsObj,
            isChanged: changedSubCategoryDetailsObj.length > 0 ? true : false,
            isDeleteCategory: false
        });
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        let path = "/recipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onclickEditCookbook = () => {
        this.setState({
            deleteCategoryVisible: !this.state.deleteCategoryVisible,
            editEnabled: !this.state.editEnabled,
        });
    }

    addSubCategory = () => {
        localStorage.setItem("isEditSubCategory", "");
        this.props.history.push({
            pathname: "/cookbookAddSubCategory",
            isEditSubCategory: false,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    editSubCategory = (e, item) => {
        localStorage.setItem("isEditSubCategory", true);
        localStorage.setItem("EditSubCategoryData",JSON.stringify(item));
        this.props.history.push({
            pathname: "/cookbookAddSubCategory",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    addRecipes = () => {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        if (!selectedCategoryObj.subCategory) {
            selectedCategoryObj.subCategory = {};
        }
        else {
            delete selectedCategoryObj.subCategory.imageData;
            delete selectedCategoryObj.subCategory.thumbnailData;
        }
        selectedCategoryObj.subCategory.selected = false;
        localStorage.setItem("selectedCategory", JSON.stringify(selectedCategoryObj));
        this.props.history.push({
            pathname: "/cookbookAddRecipes",
            isEdit: false,
            previouspath: "/cookbookSubCategoryList"
        });
    }

    onCategorySelect = (e, item) => {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        item.selected = true;
        delete item.imageData;
        delete item.thumbnailData;
        selectedCategoryObj.subCategory = item;
        localStorage.setItem("selectedCategory", JSON.stringify(selectedCategoryObj));
        this.props.history.push({
            pathname: "/cookbookRecipeList",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onSaveButtonClick = async () => {
        $('#saveNameModal').modal('hide');
        if (this.state.isChanged) {
            let cookbookName = this.state.cookbook_name ? this.state.cookbook_name.trim() : localStorage.getItem("MerryChefCookBookFileName");
            if (cookbookName.length < 1 || cookbookName.length > 20) {
                this.setState({
                    errorCookbookName: "KC1240"
                })
            }
            else {
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryDetails.cookbookName = cookbookName;
                libraryDetails.settingName = cookbookName;
                localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                let parentCustGrpId = localStorage.getItem("parentCustGroupId") || localStorage.getItem("custGroupID");
                console.log("Settings parentCustGrpId==>",parentCustGrpId);
                let cgid = [];
                cgid.push(parentCustGrpId);
                await this.submitSettingAddLibrary(cgid);
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1241"
                }
            });
        }
    }

    onDeleteCategory = (e, item) => {
        this.setState({
            selectedCategory: item,
            loaderIconVisible: false,
            isDeleteCategory: true
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/cookbookSubCategoryList"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    //Cookbook creation
    submitCookbookAddLibrary = async (_customId) => {
        // e.preventDefault();
        let _this = this;
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;
        
        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/cookbookEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;
            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
                    'base_country': localStorage.getItem("basecountry"),
                    'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let _selectedCookbook = localStorage.getItem("Selected_cookbook_item");
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    //libraryId = libraryDetails.library_id;
                    libraryId = _selectedCookbook;
                } else {
                    httpMethod = "POST";
                    // libraryId = libraryDetails.cookbookName.replace(/[^a-zA-Z0-9\n\r.]+/g, '') + '_' + Date.now();                    
                    // libraryId = libraryId.replace(/\s+/g, '');
                    libraryId = libraryDetails.cookbookName + '_' + Date.now();
                }
                
                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                
                let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
                allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
			    if(allRecipeDetails.length > 0)
                {
                    jsonTemplateObj.recipes = allRecipeDetails.map(({ ID, JSON, LIBRARY_ID }) => ({ ID, JSON, LIBRARY_ID }));
                }

                let categoryDetailsData = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                let PressandGoObj = categoryDetailsObj.find(c => c.ID === "Press&Go");
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_GB = item.categoryName;
                    jsonTemplateObj.menus[i].image = item.imageFileName ? "Resources/Images/" + item.imageFileName : "";
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = item.imageS3Url;
                    jsonTemplateObj.menus[i].menu_level = "top";
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            if (j === 0 ) {
                                jsonTemplateObj.menus[i].menu_items = [];
                            }
                            jsonTemplateObj.menus[i].menu_items[j] = {};
                            jsonTemplateObj.menus[i].menu_items[j].id = subitem.ID;
                            jsonTemplateObj.menus[i].menu_items[j].edit_date = todaysDateUTCFormatted;
                            jsonTemplateObj.menus[i].menu_items[j].author = response.data.name;
                            jsonTemplateObj.menus[i].menu_items[j].email = loginUser.username;
                            jsonTemplateObj.menus[i].menu_items[j].name = {};
                            jsonTemplateObj.menus[i].menu_items[j].name.en_GB = subitem.categoryName;
                            jsonTemplateObj.menus[i].menu_items[j].image = subitem.imageFileName ? "Resources/Images/" + subitem.imageFileName : "";
                            jsonTemplateObj.menus[i].menu_items[j].recipe_items = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            jsonTemplateObj.menus[i].menu_items[j].imageS3Url = subitem.imageS3Url;
                            jsonTemplateObj.menus[i].menu_items[j].menu_level = "sub";
                            imageSize = imageSize + (subitem.imageFileSize ? parseFloat(subitem.imageFileSize) : 0.00);
                        })
                    }
                    imageSize = imageSize + (item.imageFileSize ? parseFloat(item.imageFileSize) : 0.00);
                })
                jsonTemplateObj.PressAndGoItems = PressandGoObj && PressandGoObj.recipe_items ? PressandGoObj.recipe_items.map(r => r.LIBRARY_GUID) : [];
                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);
                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");
                let categorys = categoryDetailsObj;
                delete categorys.Import;
                categorys && categorys.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                    let recipes = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    item.recipe_items && item.recipe_items.map((c, i) => {
                        imageSize = imageSize + parseFloat(c.LIBRARY_FILE_SIZE ? c.LIBRARY_FILE_SIZE : 0);
                    })
                    delete item.recipe_items;
                    categorys[i].recipe_items = recipes ? recipes : [];
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            delete subitem.imageData;
                            delete subitem.thumbnailData;
                            let recipes = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            subitem.recipe_items && subitem.recipe_items.map((s, i) => {
                                imageSize = imageSize + parseFloat(s.LIBRARY_FILE_SIZE ? s.LIBRARY_FILE_SIZE : 0);
                            })
                            delete subitem.recipe_items;
                            categorys[i].subCategory[j].recipe_items = recipes ? recipes : [];
                        })
                    }
                });
                categorys && categorys.map((item, i) => {
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            categorys.push(subitem);
                            subitem = null;
                        })
                    }
                });
                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize ? fileSize + imageSize : 0).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "librarySettingsId": localStorage.getItem('librarySettingsId') ? localStorage.getItem('librarySettingsId') : '',
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": imageSize ? (imageSize).toFixed(2) : "",
                    "imageS3Url": "",
                    "cgids": _customId,
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categorys ? categorys : [],
                    "libraryGuid": jsonTemplateObj.id
                };
                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }
                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "Cookbook updated successfully." : "Cookbook saved successfully."
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    //Settings submit
    submitSettingAddLibrary = async (_customId) => {
        // e.preventDefault();
        let _this = this;
        let httpMethod;
        _this.setState({
            loaderIconVisible: true
        })
        let brandName = localStorage.getItem("brandName");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem("basecountry"),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(response => {
            let libraryId;
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            if (libraryDetails.isEdit) {
                httpMethod = "PUT";
                libraryId = localStorage.getItem('librarySettingsId') ? localStorage.getItem('librarySettingsId') : '';
            } else {
                httpMethod = "POST";
                // libraryId = libraryDetails.settingName.replace(/[^a-zA-Z0-9\n\r.]+/g, '') + '_' + Date.now();
                // libraryId = libraryId.replace(/\s+/g, '');
                libraryId = libraryDetails.settingName + '_' + Date.now();
            }
            
            let libraryData = localStorage.getItem("librarySettingsData");
            let libraryDataObj = JSON.parse(libraryData);
            libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
            libraryDataObj.edit_date = todaysDateUTCFormatted;
            libraryDataObj.author = response.data.name;
            libraryDataObj.email = loginUser.username;
            delete libraryDataObj.Import;
            libraryDataObj.scheduler.map((parent, i) => {
                parent.configurations.map((item, j) => {
                    delete item.id;
                })
            })
            if (libraryDataObj.scheduler && libraryDataObj.scheduler.length === 0)  {
                delete libraryDataObj.scheduler;
            }
            if (libraryDataObj.preheat_list && libraryDataObj.preheat_list.length === 0)  {
                delete libraryDataObj.preheat_list;
            }
            if (libraryDataObj.preheat_times && libraryDataObj.preheat_times.length === 0)  {
                delete libraryDataObj.preheat_times;
            }
            // convert JSON object to beautify String
            var jsonStr = beautify(libraryDataObj, null, 2, 80)
            // read json string to Buffer
            const jsonBuffer = Buffer.from(jsonStr);
            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);
            let settings = libraryDataObj.sounds;
            settings.map((item, i) => {
                fileSize = fileSize + parseFloat(item.fileSize ? item.fileSize : 0);
            })
            
            let md5 = require('md5');
            let crc = md5(jsonBuffer, "base64");
            let imagefile = settings && settings.find(a => a.fileType);
            let convertFloat;
            if(imagefile !== undefined){
                convertFloat = parseFloat(imagefile.fileSize ? imagefile.fileSize : 0);
            }
            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": libraryDetails.settingName,
                "libraryFileName": libraryId + ".json",
                "libraryFileFormat": "json",
                "libraryFileSize": (fileSize + convertFloat).toFixed(2),
                "libraryS3Url": "",
                "type": "Settings",
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": "",
                "imageFileFormat": "",
                "imageFileSize": imagefile.fileSize ? imagefile.fileSize : 0,
                "imageS3Url": "",
                "cgids": _customId,
                "json": JSON.stringify(libraryDataObj),
                "settings": settings ? settings : [],
                "libraryGuid": libraryDataObj.id
            };
            if (libraryDetails.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response && response.data && response.data.name + ' (' + loggedinUser + ')';
            }
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': jsonBuffer ? encodeURIComponent(libraryId) + '.json' : "",                
                'audiofilekey': '',
                'isthumbnail': false
            }
            uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                let parentCustGrpId = localStorage.getItem("parentCustGroupId") || localStorage.getItem("custGroupID");
                console.log("Cookbook parentCustGrpId==>",parentCustGrpId);
                let cgid = [];
                cgid.push(parentCustGrpId);
                this.submitCookbookAddLibrary(cgid);
            }).catch(err => {
                _this.showError(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
        });
    }

    onSaveCookbookButtonClick = () => {
        let { cookbook_name } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        $('#saveNameModal').modal('show');
        if(!libraryDetails.isEdit) {
            document.getElementById('cookBookText').value = "";
        } else {
            document.getElementById('cookBookText').value = cookbook_name ? cookbook_name : localStorage.getItem("MerryChefCookBookFileName");
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        this.props.history.push({
            pathname: "/libraryList",
            previouspath: "/cookbookSubCategoryList",
        });
    }

    onCookbookNameChange = (e) => {
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        // }
    }

    render() {
        let { loaderIconVisible, categoryList, recipeList, deleteCategoryVisible, isChanged, showDiscardPopup, editEnabled, success_modal, error_modal, isDeleteCategory, selectedCategoryName, cookbook_name, errorCookbookName, isEdit, isImport, selectedModel } = this.state;
        categoryList = _.orderBy(categoryList);
        recipeList = recipeList && recipeList.length > 0 ? recipeList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;
		const that = this;
        const dragProps1 = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.categoryList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ categoryList: data });

                let selectedCategory = localStorage.getItem("selectedCategory");
                let selectedCategoryObj = JSON.parse(selectedCategory);

                let categoryDetails = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetails);
                var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

                categoryDetailsObj[foundIndex]['subCategory'] = data;

                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
            },
            nodeSelector: 'li',
            handleSelector: 'a',
            lineClassName: 'global-drag-line',
        };

        const dragProps2 = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.recipeList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ recipeList: data });

                let selectedCategory = localStorage.getItem("selectedCategory");
                let selectedCategoryObj = JSON.parse(selectedCategory);

                let categoryDetails = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetails);
                var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

                categoryDetailsObj[foundIndex]['recipe_items'] = data;

                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
            },
            nodeSelector: 'li',
            handleSelector: 'div .cbcategoryBox',
            lineClassName: 'global-drag-line',
        };

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    {selectedCategoryName} <button type="button" class={(isChanged || isEdit || isImport) ? "btn btn-default-text editButton" : "displaynone"} onClick={this.onclickEditCookbook}>&nbsp;</button>
                                    <Link to={{pathname: '/settingsEditor', state: { prevPath: '/cookbookSubCategoryList' }}} title="Settings" class="btn rcSettings">&nbsp;</Link>
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
											{/* Bug 86256: MCF All recipe re-ordering not working */}
                                                    <div className="cbdnd">
                                                        <div className="rcListD actionIcon">
                                                            <Link to="#" title={formatMessage({ id: 'KC1259' })} onClick={this.addSubCategory}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                <span class="navTitle"><FormattedMessage id="KC1259" /></span>
                                                            </Link>
                                                        </div>
                                                        <div className="rcListD actionIcon">
                                                            <Link to="#" title={formatMessage({ id: 'KC1253' })} onClick={this.addRecipes}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/menuEditButton.png")} /></span>
                                                                <span class="navTitle"><FormattedMessage id="KC1253" /></span>
                                                            </Link>
                                                        </div>
                                                        <ReactDragListView {...dragProps1}>
                                                            {this.state.categoryList && this.state.categoryList.length > 0 && this.state.categoryList.map((item, index) => (
                                                                <li>
                                                                    <div className='rcListD'>
                                                                        <div class="cbcategoryBox">
                                                                            <button type="button" class={deleteCategoryVisible ? "btn cancelButton" : "displaynone"} onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                            <div class={deleteCategoryVisible ? "cbcategoryAction" : "displaynone"}>
                                                                                <button type="button" class={deleteCategoryVisible ? "btn cbEditButton" : "displaynone"} onClick={(e) => this.editSubCategory(e, item)}>&nbsp;</button>
                                                                            </div>
                                                                            <span class="cbCategoryicon"></span>
                                                                            <Link to="#" class="navIcon" onClick={(e) => this.onCategorySelect(e, item)}> <img src={item.thumbnailData ? `data:${'image/png'};base64,${item.thumbnailData}` : require("../../../../../images/recipecreation/photosPictures.png")} /></Link>
                                                                            <Link to="#" class="navTitle" onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</Link>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ReactDragListView>
                                                    </div>

                                                    <div className="cbdnd">
                                                        <ReactDragListView {...dragProps2}>
                                                            {this.state.recipeList && this.state.recipeList.length > 0 && this.state.recipeList.map((item, index) => (
                                                                <li>
                                                                    <div className='rcListD'>
                                                                        <div class="cbcategoryBox">
                                                                            <span class="cbRCicon"></span>
                                                                            <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")} alt="" /></span>
                                                                            <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ReactDragListView>
                                                    </div>
                                                 {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                                     <Droppable droppableId="droppable" direction="horizontal">
                                                         {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                class="cbcategory menuEditActive"
                                                            >
                                                                <ul>
                                                                    <li class="actionIcon"> 
                                                                        <Link to="#" title={formatMessage({ id: 'KC1259' })} onClick={this.addSubCategory}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                            <span class="navTitle"><FormattedMessage id="KC1259"/></span>
                                                                        </Link>
                                                                    </li> 
                                                                    <li className='actionIcon'> 
                                                                        <Link to="#" title={formatMessage({ id: 'KC1253' })} onClick={this.addRecipes}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/menuEditButton.png")} /></span>
                                                                            <span class="navTitle"><FormattedMessage id="KC1253"/></span>
                                                                        </Link>
                                                                    </li> 
                                                                    {categoryList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.categoryName} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <button type="button" class={deleteCategoryVisible ? "btn cancelButton" : "displaynone"} onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                                        <div class={deleteCategoryVisible ? "cbcategoryAction" : "displaynone"}> 
                                                                                            <button type="button" class={deleteCategoryVisible ? "btn cbEditButton" : "displaynone"} onClick={(e) => this.editSubCategory(e, item)}>&nbsp;</button>
                                                                                        </div>
                                                                                        <span class="cbCategoryicon"></span>
                                                                                        <Link to="#" class="navIcon" onClick={(e) => this.onCategorySelect(e, item)}> <img src={item.thumbnailData ? `data:${'image/png'};base64,${item.thumbnailData}` : require("../../../../../images/recipecreation/photosPictures.png")} /></Link>
                                                                                        <Link to="#" class="navTitle" onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</Link>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext> */}


                                                 {/* <DragDropContext onDragEnd={this.onDragReceipeEnd}>
                                                     <Droppable droppableId="droppable" direction="horizontal">
                                                         {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                class="cbcategory menuEditActive"
                                                            >
                                                                <ul>
                                                                    {recipeList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.LIBRARY_NAME} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <span class="cbRCicon"></span>
                                                                                        <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")}  alt=""/></span>
                                                                                        <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    {/* <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> */}
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className={isChanged ? "btn saveCButton" : "btn saveCButton"} title={formatMessage({ id: 'KC1243' })}>&nbsp;</button>
                                    
                                </div>
                            </div>
                            {/* <div class="footerText"><FormattedMessage id="KC1217"/></div> */}
                        </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                {/* <!-- Delete Confirmation Modal --> */}
                <div className={isDeleteCategory ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isDeleteCategory ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1245"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.onDeleteConfirm()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Delete Confirmation Modal --> */}
                {/* <!-- Save Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">  
                        <div class="modal-body"> 
                            <div class="confirmationYesNo"> 
                            <h5><FormattedMessage id='KC0686' /></h5>  
                            <div class="input-group"> 
                                <input class="form-control" type="text" id="cookBookText" maxLength="20" placeholder={formatMessage({ id: "KC0686"})} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                <div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName}/> : ''}</div>
                            </div>
                            </div> 
                        </div>
                        <div class="modal-footer textAlignCenter">
                        <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                        <button onClick={() => { this.onSaveButtonClick() }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Save Modal --> */}
            <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(CookbookSubCategoryList)
