import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import LocationsMapView from './locationsMapView';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import appconstants from '../../utils/appConstants'
import $ from 'jquery';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

class SiteTree extends Component {

    state = {
        loaderIconVisible: true
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
          }, 2000); 
          this.updateDimensions();        
    }
    updateDimensions() {
        var windowHeight = $(window).height();
        var pageTabHeader = $(".pageTabHeader").height();  
        var mapWrapperHeight = ((windowHeight) - (pageTabHeader)) - 70; 
        $("#mapdiv").css("height", mapWrapperHeight);
    } 

    // woopra events
    locationEvent = () => {
        WoopraEvents(`${constant.MAP_VIEW_LOCATION}`);
    }

    unitEvent = () => {
        WoopraEvents(`${constant.MAP_VIEW_UNIT}`);
    }


    render() {
        const { loaderIconVisible } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <AllLocationHeaderLayout activeClass="MapViewA" filterKey={appconstants.filterKey} history={this.props.history} />
                    <div id="page-content-wrapper">
                        <div className="pageTabHeader marginTop2px">
                            <ul>
                                {/* <li class="siteTreeIcon" onClick = {()=> this.onSiteIconClickHandler}>
                               <Link to="/siteTree" title="Site Tree" 
                                id="sitetreeInfoToggle" class="sitetreeInfoShow  ">&nbsp;</Link> 
                            </li> */}
                                <li>
                                    <Link to="/locationsMap" title={formatMessage({id: 'KC0044'})} class="locationsView active" onClick={()=> this.locationEvent()}><FormattedMessage id='KC0044' /></Link>
                                </li>
                                <li>
                                    <Link to="/unitsMap" title={formatMessage({id: 'KC0045'})} class="unitsView" onClick={()=> this.unitEvent()}><FormattedMessage id='KC0045' /></Link>
                                </li>

                            </ul>
                        </div>

                        {/*  <div className={showSiteTree ? '' : "storeMapWrapper"}>
                         {showSiteTree && <div id="mapSidebarWrapper">
                                <ul className="mapSidebarList">
                                    <li className="mapSidebarListBrand">
                                        <Link to="#">
                                            
                                        </Link>
                                        <Link to="#"><span className="countryName">Countries</span><span className="numberOfCounts">26</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><span className="countryFlag"><img src={require('../../images/flag/usa.png')} /></span><span className="countryName">USA</span><span className="numberOfCounts">26</span></Link>
                                        <ul className="collapse list-unstyled" id="pageSubmenu">
                                            <li>
                                                <Link to="#"><span className="countryName">Regions</span><span className="numberOfCounts">26</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/germany.png')} /></span><span className="countryName">Germany</span><span className="numberOfCounts">4</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/sweden.png')} /></span><span className="countryName">Sweden</span><span className="numberOfCounts">10</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/england.png')} /></span><span className="countryName">Great Britain</span><span className="numberOfCounts">4</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/france.png')} /></span><span className="countryName">France</span><span className="numberOfCounts">2</span></Link>
                                    </li>
                                </ul>
                        </div> }
                            <div className={showSiteTree ? '' : "mapContentWrapper"}>*/}
                        <div id="googleMap" style={{ backgroundColor: '#595959' }}>
                            <LocationsMapView />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

export default injectIntl(SiteTree);
