import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash';
import { UserProfile, UnitInfo } from '../../service/api';
import http from '../../service/httpService';
import { BRAND_ID, BASECOUNTRY_ID } from '../../utils/appConstants';
import { brandLocations, unitModels } from '../../utils/imgContants';
import { injectIntl,FormattedMessage } from 'react-intl';
import { searchVisible, searchText, clearSearchText } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj} from '../globalSearchBar/selectOption';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

var bg = require('../../images/unit/CremCarrera.svg').default;
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;



class ViewTopology extends Component {

    constructor(props) {
        super(props);
        this.state = {
            level: 1,
            link: {},
            linkList: {},
            isFormValidated: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedGroupName: [],
            findTreeLevel: "",
            checkedUnits: [],
            initialload: true,
            veryFirstTime: true,
            cgIds:[],
            pageLoad: true,
            searchData:null
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.props.searchVisible(true);
        this.getCustGroupID();
        this.updateDimensions();
    }

    static getDerivedStateFromProps = (props) => ({...props});

    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    // clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

     /**
     * Convert Encoding UTF8 to binary
     */
     convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    componentDidUpdate(prevState) { 
        this.updateDimensions();
        let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getCustGroupID();
		}
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader )) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    getData = (url, Cgids) => {
        const { showError, changeLoadingStatus } = this.props;

        changeLoadingStatus(true)
        return new Promise((resolve) => {
            let headers = { 'Content-Type': 'application/json', 'brandids': localStorage.getItem("selectedbrandID") ? localStorage.getItem("selectedbrandID") : BRAND_ID };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);
            }).catch(err => {
                showError(err);
            })
        });
    }

    getCustGroupID = () => {
        const { changeLoadingStatus } = this.props;
        let cgids = localStorage.getItem("custGroupID");
        if (this.props.selected_cgid) {
            let custGroupID = localStorage.getItem("selected_cgid") && localStorage.getItem("selected_cgid") !== '[]' ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
            if (custGroupID !== "" && custGroupID !== undefined) {
                cgids = custGroupID.length > 0 ? Array.prototype.map.call(custGroupID, m => m.cust_gp_id) : localStorage.getItem("custGroupID");
            }
        }
        changeLoadingStatus(true);
        try {
            this.getOrgnanizationView(cgids);
            return;
        } catch (err) {
            console.log('catch exception occured');
            changeLoadingStatus(false)
            this.setState({
                linkList: [],
            })
        };
    }

    processOrgnanizationJSON(orgJSON) {
        const { changeLoadingStatus } = this.props;
        const length = 1;//User Story 64133: High Priority : Recipe Creation is supporting single node selection only
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            isUnitItemClicked: true
        })
        changeLoadingStatus(false);
    }

    getOrgnanizationView = async(values) => {
        const { changeLoadingStatus, modelFamilyIds } = this.props;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        changeLoadingStatus(true);
        let url = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`;
        let { searchData } = this.state;
        if (searchData && searchData.searchtext) {
            url = `${url}?searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                url = `${url}?searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: url,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': localStorage.getItem("selectedbrandID") ? localStorage.getItem("selectedbrandID") : BRAND_ID,
                'useremail': loggedinUser,
                'modelFamilyIds': modelFamilyIds ? modelFamilyIds : ''
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, (data) => {
                    this.processOrgnanizationJSON(data);
                });
             }
             else {
                 this.processOrgnanizationJSON(response.data);
             }
        }).catch(err => {
            this.setState({
                linkListValue: {},

            })
            changeLoadingStatus(false);
        });
    }

    getUnitsViaCGID = async (cgid) => {
        this.setState({ loaderIconVisible: true })
        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid':localStorage.getItem("selectedbrandID"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let item = response.data.units[i];
                    let isExist = unitSelected.filter((i) => i.ID === item.ID);
                    if (isExist.length === 0) {
                        unitSelected.push(item)
                    }
                }
                this.setState({ checkedUnits: unitSelected, loaderIconVisible: false })
            }

        }).catch(err => {
            this.setState({ loaderIconVisible: false })
        });
    }

    unselectCGID = async (cgid) => {
        this.setState({loaderIconVisible: true})

        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid':localStorage.getItem("selectedbrandID"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID } = response.data.units[i];
                    let index = unitSelected.findIndex((i) => i.ID === ID);
                    unitSelected.splice(index, 1);
                }
                this.setState({ checkedUnits: unitSelected,loaderIconVisible:false })
            }

        }).catch(err => {
        });
    }

    onItemClick = async (e, link, isSelected) => {
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        this.setState({
            veryFirstTime: false,
        })
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        let cgid = [],prevCgid=[];
        for(let i=0; i<Currentselected.length; i++){
            cgid.push(Currentselected[i].cust_gp_id)
        }
        for(let i=0; i<previousselected.length; i++){
            if(!cgid.includes(previousselected[i].cust_gp_id)){
                prevCgid.push(previousselected[i].cust_gp_id)
            }
        }

        if(prevCgid.length || !isSelected){
            await this.unselectCGID(prevCgid.length ? prevCgid.join(',') : link.cust_gp_id)
        }
        if(isSelected){
            this.getUnitsViaCGID(cgid.join(','));
        }

        this.setState({
            cgIds : cgid,
            link: link,
            isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false,
            isFormValidated: dynasty.length > 0 ? true : false,
            pageLoad: false
        })
    }

    onUnitItemClick(e, unit) {
        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id,...unit });
        }

        this.setState({
            checkedUnits: this.props.restrictUnitSelect ? [] : unitSelected,
            isFormValidated: this.state.selectedGroupName.length > 0,
            isUnitItemClicked: true,
            link: {},
            isAddressAvailable: false,
            unit: unit,
            isLoactionTypeAvailable: false,
            level: 6
        })
    }

    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit, index) => {
                let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled" : ""
                treeActive = "";
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                let unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
                unitInActive = "";
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = this.state.checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false;
                treeActive = ifExist ? "treeActive" : "";
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';

                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={bg} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                ><b>{unit.UNITID}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }

    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        let selectedGroupID = [];
        selectedGroupID = localStorage.getItem("selectedGroupID") ? localStorage.getItem("selectedGroupID").split(',') : [];
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;

                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) || (selectedGroupID.filter(a => a === link.cust_gp_id.toString()).length > 0 && this.state.pageLoad && this.props.preSelect) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) || (selectedGroupID.filter(a => a === link.cust_gp_id.toString()).length > 0 && this.state.pageLoad && this.props.preSelect) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link, isParentDivActive === '') }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={bg}  alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt=""  />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])}
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) || (selectedGroupID.filter(a => a === linkList.cust_gp_id.toString()).length > 0 && this.state.pageLoad && this.props.preSelect) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty || (selectedGroupID.filter(a => a === linkList.cust_gp_id.toString()).length > 0 && this.state.pageLoad && this.props.preSelect) ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList, isParentDivActive === '') }}>
                        <span className="treeImg">
                            <img src={bg}  alt=""/>
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }

    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    
    onCollapseButtonClick(e, link) {
        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }
        this.setState({ showLink })
    }
    
    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }

    

    render() {
        const { title, onBackClickHandler, submitAddLibrary, hideBackButton} = this.props;
        const { linkList, findTreeLevel, link, selectedGroupName } = this.state;
        const { formatMessage } = this.props.intl;

        if (selectedGroupName.length > 0) {
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        }
        else {
            $("#btnfinish").removeClass("activeState");
            $("#btnfinish").addClass("disabled");
        }

        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map((item, index) => <li key={index}>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1573' }) + item.level_id} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div>
                <div id="page-content-wrapper">
                    <div className="unitRegistrationWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li className={hideBackButton ? "displaynone" : ""}>
                                    {/* KCCN */}
                                    <button onClick={onBackClickHandler} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</button> 
                                </li>
                                <li>

                                    <FormattedMessage id={title}/>
                                </li>
                                <li>
                                    <button className="btn finishBtn" type="button" id="btnfinish" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} to="/assignSettingsToTopology" title={formatMessage({ id: "KC0056"})} onClick={(e) => submitAddLibrary(e, this.state.cgIds)}><FormattedMessage id='KC0056' /></button>
                                </li>
                            </ul>
                        </div>
                        <div className="collapseExpandHeader">
                            <ul>
                                {levelHeader}
                            </ul>
                        </div>
                        <div className="assignOrgTreeOuter">
                            <div className="orgznSiteTree">
                                {linkList && linkList.level1 &&
                                    linkList.level1.map(() => this.showLinks(findTreeLevel))}
                            </div>
                        </div>                         
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        searchVisible, searchText, clearSearchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ViewTopology)); 
