import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import auth from '../../service/authService';
import  backDrop from '../../events/backdropEvent';
import $ from 'jquery';
import LogoutForm from "../logout/logoutForm";
import  pointerDevice from '../../utils/pointerDeviceFinder';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import SupportButton from '../../components/controls/supportButton'
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class MainMenu extends Component {
	state = {
		isHidden: false
	}
	
	componentDidMount() {
		$("div").removeClass("modal-backdrop fade show");
		document.body.style.backgroundColor = "#042546";
		//zendesk help script
		// const script = document.createElement("script");
		// script.id = "ze-snippet";
		// script.src = "https://static.zdassets.com/ekr/snippet.js?key=a65cb604-0462-4b49-92dc-ead6f4f92ded";
		// script.async = true;
		// document.body.appendChild(script);
		// //zendesk help iframe enabled
		// $('iframe').removeClass();
		$("body").removeClass("loginBg");		
		if (!auth.getJwt())
			return this.props.history.push('/');
		var sideNavBar = document.querySelector('.sideNavBar');
		sideNavBar && sideNavBar.addEventListener('click', this.showSideNavebar);
		sideNavBar && sideNavBar.addEventListener('touchend', this.showSideNavebar);
		if(document.getElementById("backdropElement")){
			backDrop.hideBackdrop();
		}
		localStorage.removeItem("smartTagInfo");
	}

	showLoginForm = (event, isHidden) => {	
		this.setState({
			isHidden: !isHidden
		});
		if(this.state.isHidden){
			$('#backdropElement').click()
	}
		event.preventDefault();  
	  }

		showLoginbg = (event, isHidden, isHiddenforFilters) => {
			if(this.state.isHidden === true){
					this.setState({
							isHidden: !isHidden
					});
					$('#backdropElement').click()
			}
		if(this.state.isHiddenforFilters === true){
				this.setState({
						isHiddenforFilters: !isHiddenforFilters
				});
				$('#backdropElement').click()
		}
		event.preventDefault();  
	}

	componentWillUnmount() {
		let zendeskscript = document.getElementById('ze-snippet');
		if (zendeskscript) {
			zendeskscript.remove();
		}
		//zendesk help iframe disabled
		$('iframe').addClass('displaynone');
	}

	/**
	 * Active side nav bar in small devices
	 */
	showSideNavebar = (e) =>{
		$(document).find('#wrapper').first().addClass('navActive');
		if(!document.getElementById("backdropElement")){
		var backElem = backDrop.showBackDrop();	
		backElem.style.left = '230px';
		backElem.style.zIndex = 1031;
		backElem.addEventListener('click', this.hideSideNavBar);
		backElem.addEventListener('touchend', this.hideSideNavBar);
		}
		e.preventDefault();
	}

	/**
	 * Hide side navigation bar
	 */
	hideSideNavBar = (e) =>{
		$(document).find('#wrapper').first().removeClass('navActive');	
		backDrop.hideBackdrop();
		e.preventDefault();
	}

	hideLoginForm = (event) =>  { 
		event.preventDefault();  
		this.setState({
			isHidden: false
		});   
		if(pointerDevice.IsTouchDevice() && event.target) {
			let targetClass = event.target.getAttribute('class');
			if(targetClass && targetClass.indexOf('navFilter')!==-1){
				this.showFilter(event, false);
			}
		}      
	}

	nextPath(path) {		
		this.props.history.push("/mainMenu");
	}

	editInformation() {
		this.props.history.push('/myinformationedit');
	}

	trackMenuItems = (title) => {
		let concat = `${constant.MAIN_MENU}${title}`;
		WoopraEvents(concat);
	}
	trackEvent = (e, title = '') => {
		WoopraEvents(title || e.target.title)
	}

	openBillingPortal = () => {
		let link = localStorage.getItem('billingPortalUrl') ? localStorage.getItem('billingPortalUrl') : "";
		if (link && link !== "undefined") {
			window.open(link, "_blank");
		}
	}

	render() {
		
		let features = auth.getAllFeatures();
		const { formatMessage } = this.props.intl;
		features = features ? features : [];
		let isPublicUser = false;
		if (features[0].feature_category === "Main Menu Apps") {
			isPublicUser = true;
		}
		let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;

		let logoutForm = null
        if(this.state.isHidden) {
            logoutForm = (<LogoutForm hideFlag={this.state.isHidden} hideProfileEditBtn={false} editinfo={this.editInformation} nextPath={this.nextPath} hideLoginForm = {(e) => this.hideLoginForm(e)} />)
		}
		
		let billingPortalIcon = <>
			<li>
				<span>
					<Link to="#" onClick={(e) => this.openBillingPortal(e, 'Site Map')}>
						<img src={require('../../images/mainmenu/BillingPortal.svg').default} alt="Billing Portal" title="Billing Portal" />
						<h5>Billing Portal</h5>
					</Link>
				</span>
			</li>
		</>;
		
		let isCheckPassed = false
		if(localStorage.getItem("subscriptionId") !== null && localStorage.getItem("subscriptionId")){
			if(localStorage.getItem("isEAAS") === 'true'){
				isCheckPassed = true;
			}else{
				if(localStorage.getItem('roleName') === "Customer Admin"){
					isCheckPassed = true;
				}
			}
		}

		return (
			<React.Fragment>
				<div id="wrapper">
					<div className="loadingProgress">
						<div className="loadingProgressIn"></div>
					</div>
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt"  onClick={(e) => {this.showLoginbg(e, this.state.isHidden)}}>  
							<div className="navbar-header"> 
								<Link className="navbar-brand welbiltRLogo" to={isPublicUser ? "/mainMenu" :"/"} title={formatMessage({id:'KC1061'})} onClick={(e)=> this.trackEvent(e, 'Main Menu')}>&nbsp;</Link>
								{/* <a className="navbar-brand welbiltRLogo" title="Welbilt - Bringing innovation to the table" href="mainmenu.html">&nbsp;</a> */}
								<button className="navbar-brand sideNavBar border0">&nbsp;</button>
							</div>
							<ul className="navbar-nav mr-auto titlebarLeft">
								<li className="nav-item">
									<Link className="nav-link" to={isPublicUser ? "/mainMenu" :"/"} title={formatMessage({id:'KC1061'})} onClick={(e)=> this.trackEvent(e, 'Main Menu')}><FormattedMessage id='KC1061' /></Link>
								</li> 
							</ul> 
							<ul className="navbar-nav mr-auto navbarRight">
								{/*  Multilingual dropdown*/}
								<li className="nav-item">
									<LanguagePreferenceCommon />
								</li>
								{/*  Multilingual dropdown*/}
								<li className="nav-item">
									<Link className={this.state.isHidden ? "nav-link navUser active":"nav-link navUser"} title={loggedinUser} to="#" onClick={(e) => {this.showLoginForm(e, this.state.isHidden)}}><span id="spannavNotifiStatus"></span>&nbsp;</Link>
								</li>
							</ul>  
					</nav>
					<div className="sideNavToggleTransparent"></div>
					<div id="sidebar-wrapper">
						<ul className="sidebarNav">
							<li className="brandName">
								<Link to={isPublicUser ? "/mainMenu" :"/"} title={formatMessage({ id: 'KC0999' })} className="homeNav" onClick={(e)=> this.trackEvent(e, 'Welbilt - Kitchen Connect')}>&nbsp;</Link>
							</li>
							<li className={isPublicUser? "displaynone" : ""}>
								<Link to="/dashBoardLocations" title={formatMessage({id:'KC0046'})} className="dashboardNav " onClick={(e)=> this.trackEvent(e, 'All Locations')}><FormattedMessage id='KC0046' /></Link>
							</li>
							<li className={isPublicUser? "displaynone" : ""}>
								<Link to="/LocationsView" title={formatMessage({id:'KC1047'})} className="ListviewA" onClick={(e)=> this.trackEvent(e, 'List View')}><FormattedMessage id='KC1047' /></Link>
							</li>
							<li className={isPublicUser? "displaynone" : ""}>
								<Link to="/locationsMap" title={formatMessage({id:'KC1048'})} className="MapViewA" onClick={(e)=> this.trackEvent(e, 'Map View')}><FormattedMessage id='KC1048' /></Link>
							</li>
							<li>
								<Link to="#" title={formatMessage({id:'KC1049'})} /*className="SidetreeA"*/onClick={(e)=> this.trackEvent(e, 'Site Map')} >&nbsp;</Link>
							</li>
						</ul>
						<ul className="sidebarNav bottomNav">
							{/* <li>
                    <Link to="#" className="uploadNav" title="Upload">&nbsp;</Link>
                </li> */}
							<li>
								<Link to="/mainMenu" className="menuNav active" title={formatMessage({id:'KC1061'})} onClick={(e)=> this.trackEvent(e, 'Main Menu')}><FormattedMessage id='KC1061' /></Link>
							</li>
						</ul>
					</div>
					<div id="page-content-wrapper">
					<div className="mainMenuGrid" style={{ background: '#042546' }}>
						<ul>
							{features.map((item, i) => {
								return item.isMainMenu && item.featureAvailable && <li key={i}>
									<span>
										<Link
											onClick={()=>this.trackMenuItems(item.linkTitle)} 
											to={item.navigateUrl}>
											<img src={item.featuteIcon} alt={item.linkTitle || ""} title={item.stringID? formatMessage({id:item.stringID }): item.linkTitle} />
											<h5>{item.stringID? <FormattedMessage id={item.stringID}/>: item.linkTitle}</h5>
										</Link>
									</span>
								</li>
							})}
							{isCheckPassed ? billingPortalIcon : null}
	
							{/* <li>
						<span>
							<Link to='/userManagementList'>
                            <img src={require('../../images/mainmenu/users.svg')} alt="User Management" title="User Management" />  
							 <h5>User Management</h5>  
							 </Link> 
                        </span>  
					</li>
                    
					<li> 
						<span> 
							<Link to='/unitRegistration'>
							<img src={require('../../images/mainmenu/UnitRegistrationAssignment.svg')} alt="Unit Registration &amp; Assignment" title="Unit Registration &amp; Assignment" />    
							<h5>Unit Registration &amp; <br/>Assignment</h5> 
							</Link>
						</span>
					</li> 
					<li>
						<span>
							<Link to ='/organizationManagement'>
							<img src={require('../../images/mainmenu/OrganizationMgmt.svg')} alt="Organization Management" title="Organization Management" />  
							<h5>Organization <br/> Management</h5> 
							</Link> 
						</span>
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/warranty.svg')} alt="Subscription &amp; Warranty" title="Subscription &amp; Warranty"/></span>  
						<h5>Subscription &amp; Warranty</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/menu.svg')} alt="Menus" title="Menus" /></span>  
						<h5>Menus</h5> 
					</li>
					<li>
						<span>
						<Link to ='/mediaLibraryList'>
							<img src={require('../../images/mainmenu/cloud.svg')} alt="Media" title="Media" />
						<h5>Media</h5> 
						</Link> 
						</span>
					</li> 
					<li>
						<span>
						<Link to ='/softwareAvailableUpdates'>
							<img src={require('../../images/mainmenu/plugins.svg')} alt="Software" title="Software" />  
						<h5>Software</h5>
						</Link> 
						</span> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/errors.svg')} alt="Error Center" title="Error Center" /></span>  
						<h5>Error Center</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/notifications.svg')} alt="Notification Center" title="Notification Center" /></span>  
						<h5>Notification Center</h5> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/haccp.svg')} alt="HACCP" title="HACCP" /></span>  
						<h5>HACCP</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/report.svg')} alt="Reports" title="Reports" /></span>  
						<h5>Reports</h5> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/training.svg')} alt="Trainings" title="Trainings" /></span>  
						<h5>Trainings</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/Logbook.svg')} alt="Log Book" title="Log Book" /></span>  
						<h5>Log Book</h5> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/LeaseAction.svg')} alt="Leaser Action" title="Leaser Action" /></span>  
						<h5>Leaser Action</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/DiagnosticPreventative.svg')} alt="Diagnostics &amp; Preventive Maintenance" title="Diagnostics &amp; Preventive Maintenance" /></span>  
						<h5>Diagnostics &amp; Preventive <br/> Maintenance</h5> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/settings.svg')} alt="Settings" title="Settings" /></span>  
						<h5>Settings</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/Help.svg')} alt="Help" title="Help" /></span>  
						<h5>Help</h5> 
					</li>
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/Language.svg')} alt="Language" title="Language" /></span>  
						<h5>Language</h5> 
					</li> 
					<li style={{display:'none'}}>
						<span><img src={require('../../images/mainmenu/ServiceTickets.svg')} alt="Service Tickets" title="Service Tickets" /></span>  
						<h5>Service Tickets</h5> 
					</li>  */}
						</ul>
					</div>
					</div>
				</div>
				<div ref={node => { this.pop = node }}>                    
                    {logoutForm}
                </div>
				<SupportButton subject="KitchenConnect Support Request - User" body="Dear User,%0D%0A%0D%0APlease send us your comments and attach any screenshots that could help our team to better understand your request, we´ll come back to you as soon as possible..%0D%0A%0D%0A Sincerely,%0D%0AKitchenConnect Team."/>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainMenu));
