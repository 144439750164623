import React, { Component } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import http from '../../service/httpService'
import {  UnitInfo } from '../../service/api'
import { setunitBrandId } from '../../utils/appConstants'

import { CUSTOMER_ID  } from '../../utils/appConstants'
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';

export class UnitCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,

      data: [],
      totalPages: null,
      loading: true,
      currentPage: 1
    }
    this.setLocatioStorage = this.setLocatioStorage.bind(this)
  }

  setLocatioStorage = item => {
    this.props.setCurrentUnit(item)
    localStorage.setItem('unitID', item.UNITID)
    localStorage.setItem('brandName', item.BRAND_NAME)
    localStorage.setItem('unitBrandId', item.BRAND_ID)
    localStorage.setItem('modelName', item.MODEL_NAME)
    localStorage.setItem('modelFamilyName', item.MODEL_FAMILY_NAME);
    localStorage.setItem('cloudUnitName', item.CLOUD_UNIT_NAME);
    setunitBrandId(item.BRAND_ID ? item.BRAND_ID : '', item.UNITID ? item.UNITID : '', item.MODEL_NAME ? item.MODEL_NAME : '')
    this.getExcludeProductDetails(item.BRAND_ID)
  }

  getExcludeProductDetails = async brandid => {
    const response = await http.get(UnitInfo.excludeProduct, {
      headers: {
        'Content-Type': 'application/json',
        cid: CUSTOMER_ID,
        brandid: brandid
      },
      data: {}
    })
    let excludeProduct = response.data && response.data[0]
    let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;

    localStorage.setItem('excludedProductList', checklength > 0 ? true : false)
    console.log('antony', excludeProduct)
  }

  // Woopra events
  getUnitAction =(unit)  => {
    let unitDetails = `${constant.UNIT_DETAILS}${unit.UNITID}`;
    WoopraEvents(unitDetails);
  }

  render() {

    $(document).ready(() => {
      $('.statusDetailsTab > ul > li')
        .off('click')
        .on('click', function (e) {
          e.stopPropagation()
          $(this)
            .parents('ul')
            .toggleClass('descExp')
        })
      $('.offlineStaus > ul > li')
        .off('click')
        .on('click', function (e) {
          e.stopPropagation()
          return
        })
    })

    let { unit: Unit } = this.props
      let unitsGridErrorStaus = Unit.currentError ? 'statusDetailsTab errorStaus ':'statusDetailsTab',
      unitsGridwarningStaus = Unit.currentError ? 'statusDetailsTab warningStaus':'statusDetailsTab',
      unitsGridofflineStaus = 'statusDetailsTab offlineStaus',
      unitsGridNoErrorStaus = 'statusDetailsTab '

    let online = 'Online',
      offline = 'Offline',
      nonCritical = 'Non-Critical Errors',
      errors = 'Errors'

    return (
      <React.Fragment>        
        <div class={`allUnitsGridLI ${Unit.CONNECT_STATE === offline ? 'offlineStaus' : ''}`} >
          <Link to={this.props.features.length > 0 ? this.props.features[0].navigateUrl : '/un-authorized'} onClick={() => {this.props.setLocatioStorage(this.props.unit); this.getUnitAction(this.props.unit)} }>
            <div class='unitsDetailsTab'>
              <ul>
                <li><h4>{Unit.BRAND_NAME} <span>{Unit.MODEL_NAME}</span></h4></li>
              </ul>
            </div>
            <div class='unitsDetailsTab'>
              <ul>
                <li>
                  <h5>{Unit.location_name}</h5>
                  <h6>{Unit.group_name}</h6>
                  <h5>SN: {Unit.UNITID}</h5>
                  {Unit.UNIT_NUMBER && Unit.UNIT_NUMBER !== "null" ? <h5 title = {Unit.UNIT_NUMBER}>ID: {Unit.UNIT_NUMBER}</h5>:""}
                </li>
                <li>
                   <img src={Unit.Brand_Logo} alt={Unit.Brand_Alt} title={Unit.Brand_Title} />
                </li>
              </ul>
            </div>
          </Link>

          {Unit.ERROR_STATE === errors || Unit.CONNECT_STATE === offline || Unit.ERROR_STATE === nonCritical ? (
            <div className={Unit.CONNECT_STATE === offline ? unitsGridofflineStaus : Unit.ERROR_STATE === errors && Unit.CONNECT_STATE === online ? unitsGridErrorStaus : Unit.ERROR_STATE === nonCritical && Unit.CONNECT_STATE === online ? unitsGridwarningStaus : unitsGridNoErrorStaus}>
              <ul>
                {Unit.CONNECT_STATE !== offline && Unit.currentError ? <li> <button type='button' class='btn descCollapse'> &nbsp;</button></li> : ""}
                {Unit.CONNECT_STATE !== offline && (Unit.ERROR_STATE === errors || Unit.ERROR_STATE === nonCritical) ? <li className="errorCode">{Unit.currentError && Unit.currentError.error_code ? Unit.currentError.error_code : ""}</li> : ""}
                <li>{Unit.CONNECT_STATE !== offline ? <> <h6>{Unit.currentError && Unit.currentError.error_code ? Unit.currentError.error_code : ''} </h6> <p class='mCustomScrollbar'>{Unit.currentError && Unit.currentError.description ? Unit.currentError.description : ''}</p></> : Unit.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? Unit.unitLastOnline : "" } </li>
                {/* <li>{Unit.CONNECT_STATE !== offline ? <> <h6>{Unit.currentError && Unit.currentError.error_code ? Unit.currentError.error_code : ''} </h6> <p class='mCustomScrollbar'>{Unit.currentError && Unit.currentError.description ? Unit.currentError.description : ''}</p></> : Unit.unitLastOnline} </li> */}
                </ul>
            </div>
          ) : ''}
        </div>
      </React.Fragment>
    )
  }
}


UnitCard.propTypes = {
    unit : PropTypes.object.isRequired
}