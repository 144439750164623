import React, { Component } from 'react';
import http from '../../../../../service/httpService';
import { MenuUploadAPI } from '../../../../../service/api';
import authService from '../../../../../service/authService';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import SuccessMoal from '../../../../modal/success-model'
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import moment from '../../../../../utils/momentHelper.js';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { CommonDetailViewEdit } from '../../../../controls/commonDetailViewEdit'
import Jimp from 'jimp';
require('jquery-mousewheel')
var recipeJSON = require('../../Templates/RecipeJSON');

let md5 = require('md5');
require('jquery-mousewheel')
var AdmZip = require('adm-zip');
var request = require('request');
var recipeJSON = require('../../Templates/RecipeJSON');
var LZString = require('lz-string');

const HTTP_METHOD = {
	DELETE: 'DELETE'
}

class CookbookAddRecipes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedRecipeDetails: [],
            selectedlibraries: [],
            search: '',
            previouspath: '',
            selectedCategoryName: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onAddRecipes = this.onAddRecipes.bind(this);
        this.reciperedirect = this.reciperedirect.bind(this);
        this.OnToggleDetails = this.OnToggleDetails.bind(this);
        this.recipeSearchResult = this.recipeSearchResult.bind(this);
    }

    async componentDidMount() {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            await this.getLibraryList().catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    getLibraryList () {
		this.setState({
			loaderIconVisible: true,
            previouspath: this.props.history.location.previouspath
		});
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getLibraryList,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': localStorage.getItem("custGroupID"),
						'brandid': localStorage.getItem("selectedbrandID"),
						'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe",
                        'createdby': loggedinUser
					},
					data: {}
				}).then(response => {
					let { data } = response;
                    let libraryList = data && data.libraryList ? data.libraryList : [];
                    let selectedCategory = localStorage.getItem("selectedCategory");
                    let selectedCategoryObj = JSON.parse(selectedCategory);
                    let categoryDetails = localStorage.getItem("resourceDetails");
                    let categoryDetailsObj = JSON.parse(categoryDetails);

                    let recipeDetails = [];
                    let categoryName = '';
                    if (selectedCategoryObj.subCategory.selected && selectedCategoryObj.subCategory !== undefined && selectedCategoryObj.subCategory !== []) {
                        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
                        recipeDetails = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID === selectedCategoryObj.subCategory.ID);
                        categoryName = recipeDetails ? recipeDetails[0].categoryName : "";
                        recipeDetails = recipeDetails ? recipeDetails[0].recipe_items ? recipeDetails[0].recipe_items : [] : [];
                        
                    }
                    else {
                        recipeDetails = categoryDetailsObj.filter(c => c.ID === selectedCategoryObj.ID);
                        categoryName = recipeDetails ? recipeDetails[0].categoryName : "";
                        recipeDetails = recipeDetails ? recipeDetails[0].recipe_items ? recipeDetails[0].recipe_items : [] : [];
                    }
                    
                    libraryList.length > 0 && libraryList.map(item => {
                        //item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
                        item.checked = false;
                        recipeDetails.length > 0 && recipeDetails.map(recipeitem => {
                            if (item.ID === recipeitem.ID) {
                                item.checked = true;
                            }
                        });
                    });

                    let recipeDetails_new = recipeDetails;
                    recipeDetails_new = recipeDetails_new.reverse();
                    let libraryList_new = libraryList;

                    recipeDetails_new.map((item) => {
                        libraryList_new.forEach(function(data,i){
                            if(data.ID === item.ID){
                                libraryList_new.splice(i, 1);
                                libraryList_new.unshift(data);
                            }
                          });
                    })

					this.setState({
						loaderIconVisible: false,
                        data: libraryList_new,
                        selectedCategoryName: categoryName,
                        selectedlibraries: libraryList.filter(c => c.checked === true)
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});

    }
    
    redirect() {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    onBackButtonClick(){
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        let path = "/cookbookRecipeList";
        if(!selectedCategoryObj.subCategory.selected) {
            path = "/cookbookSubCategoryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/cookbookAddRecipes"
        });
    }

    onAddRecipes() {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        
        if(this.state.selectedlibraries && this.state.selectedlibraries.length >= 1024) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2240" 
                }
            });
        }
        else {
            //Push recipes to All recipes
			let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
			let updatedSelectedRecipes;
			
			allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
            if (selectedCategoryObj.subCategory.selected && selectedCategoryObj.subCategory !== undefined && selectedCategoryObj.subCategory !== []) {
				updatedSelectedRecipes = this.state.selectedlibraries.map(v => ({...v, allRecipeType: 'subcategory'}));
				allRecipeDetails = allRecipeDetails.filter(x=>x.allRecipeType != 'subcategory' );
                var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
                let selectedSubcategoryObj = categoryDetailsObj[foundIndex].subCategory.find(c => c.ID === selectedCategoryObj.subCategory.ID);
                let subcategoryObj = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID !== selectedCategoryObj.subCategory.ID);
                selectedSubcategoryObj.recipe_items = this.state.selectedlibraries;
                subcategoryObj.push(selectedSubcategoryObj);
                categoryDetailsObj[foundIndex].subCategory = subcategoryObj;

                // selectedCategoryObj.subCategory.selected = false;
                // localStorage.setItem("selectedCategory", JSON.stringify(selectedCategoryObj));
                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                this.props.history.push({
                    pathname: "/cookbookRecipeList",
                    isEdit: false,
                    previouspath: "/cookbookAddRecipes"
                });
            }
            else {
				updatedSelectedRecipes = this.state.selectedlibraries.map(v => ({...v, allRecipeType: 'category'}));
				allRecipeDetails = allRecipeDetails.filter(x=>x.allRecipeType != 'category' );
                let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);
                let categoryObj = categoryDetailsObj.filter(c => c.ID !== selectedCategoryObj.ID);
                selectedCategoryDetailsObj.recipe_items = this.state.selectedlibraries;
                categoryObj.push(selectedCategoryDetailsObj);
                localStorage.setItem("resourceDetails", JSON.stringify(categoryObj));
                this.props.history.push({
                    pathname: "/cookbookSubCategoryList",
                    isEdit: false,
                    previouspath: "/cookbookAddRecipes"
                });
            }
			let newAllRecipes = [...allRecipeDetails, ...updatedSelectedRecipes];
			const uniqueIds = [];
            const uniqueItems = newAllRecipes.filter(element => {
                const isDuplicate = uniqueIds.includes(element.ID);
                if (!isDuplicate) {
                    uniqueIds.push(element.ID);
                    return true;
                }
                return false;
            });
			localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(uniqueItems)));
        }
    }

    onSelectLibrary(e, item) {
		if (this.state.data && this.state.data.length > 0) {
			const libraries = [...this.state.data];
			const index = libraries.indexOf(item);
			libraries[index] = { ...libraries[index] };
			libraries[index].checked = e.target.checked;

			let selectedlibraries = libraries.filter(c => c.checked === true);

			this.setState({
				data: libraries,
				isSelectAllLibrary: selectedlibraries.length === libraries.length,
				selectedlibraries: selectedlibraries
			});
		}
    }
    
    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    recipeSearchResult(e) {
        this.setState({ search: e.target.value });
    }

    OnToggleDetails = (assignedTopologyDetails) => {
		const { ASSIGNED_CUSTOMER_GROUP, ...rest } = assignedTopologyDetails;
		if (localStorage.getItem("brandName") !== "MERRYCHEF") {
			// initialize a Set object
			var newArray = ASSIGNED_CUSTOMER_GROUP.filter(value => Object.keys(value).length !== 0);
			const uniqueValuesSet = new Set();
			// array of objects with duplicate values 
			const filteredArr = newArray.filter((obj) => {
				// check if name property value is already in the set 
				const isPresentInSet = uniqueValuesSet.has(obj.cust_gp_id);
				uniqueValuesSet.add(obj.cust_gp_id)
				return !isPresentInSet
			});
			this.setState({
				assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: filteredArr, ...rest },
			}, () => {
				$('#detailsInfoModal').modal('show')
			});
		}
		else{
			this.setState({
				assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: [], ...rest },
			}, () => {
				$('#detailsInfoModal').modal('show')
			});
		}
	}

    reciperedirect = (e,type) => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.initialMethod = "Add";
        libraryDetails.recipeType = type;
        localStorage.setItem("libraryImage", "");
		localStorage.setItem("libraryData", "{}");
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("RecipesListPage", "/cookbookAddRecipes");
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/cookbookAddRecipes"
        });
    }

    OnEditLibrary = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let { data, getLibraryDetails, selected_item } = this.state;

		if (data && getLibraryDetails) {
            if (data.length != getLibraryDetails.length) {
                let difference = this.getArrayDifferences(data, getLibraryDetails);
                if (difference && difference.length > 0) {
                    data.splice(0, data.length, ...getLibraryDetails);
                }
            }
        }

		localStorage.setItem("selectedbrandID", selected_item.BRAND_ID);
		localStorage.setItem("brandName", selected_item.BRAND_NAME);

		await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
			headers: {
				'Content-Type': 'application/json',
				'library_id': encodeURIComponent(selected_item.LIBRARY_ID),
				'library_isedit': true,
				'encryption' : "true"
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let file_path = response.data.presignedURL;
				console.log("file_path", file_path)
				var libraryFiles = [];
				var b64 = "";
				var imageBuffer = null;
				localStorage.setItem("selectedGroupID", selected_item.ASSIGNED_CUSTOMER_GROUP ? Array.prototype.map.call(selected_item.ASSIGNED_CUSTOMER_GROUP, m => m.cust_gp_id).toString() : 0);
				try {
					request.get({ url: file_path, encoding: null }, async (err, res, body) => {
						var zip = new AdmZip(body);
						var zipEntries = zip.getEntries();
						let UpdatedJSONObj = "";
						console.log("zip", zip)
						console.log("zipEntries", zipEntries)

						let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
						console.log("validzipEntries", validzipEntries)

						validzipEntries.forEach((entry, i) => {
							if (selected_item.TYPE === "CookBook" || (localStorage.getItem("brandName") === "MERRYCHEF" && selected_item.TYPE === "Recipe") || (localStorage.getItem("brandName") === "GARLAND" && selected_item.TYPE === "Recipe")) {

								libraryFiles[i] = {};
								libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
								imageBuffer = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									if (selected_item.TYPE === "Recipe") {
										libraryFiles[i].fileBuffer = imageBuffer;
										if (selected_item.IMAGE_FILE_NAME && UpdatedJSONObj && UpdatedJSONObj.image && selected_item.IMAGE_FILE_NAME === UpdatedJSONObj.image.replace('Resources/Images/', '')) {
											libraryFiles[i].fileType = "RecipeImage";
										}
										else {
											if (localStorage.getItem("brandName") === "GARLAND") {
												// libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
												libraryFiles[i].fileType = "Image";
											} else {
												libraryFiles[i].fileType = "StepImage";
											}
										}
									}
									else {
										libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
										libraryFiles[i].fileType = "Image";
									}
								}
								else {
									libraryFiles[i].fileBuffer = imageBuffer;
									UpdatedJSONObj = JSON.parse(imageBuffer);
									b64 = imageBuffer.toString('base64');
									libraryFiles[i].fileType = "JSON";
								}
							}
							else if (selected_item.TYPE === "Settings" && localStorage.getItem("brandName") === "MERRYCHEF") {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json') && !entry.entryName.includes('.wav')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
									let fileScreenSaverExt = entry.entryName.substring(entry.entryName.lastIndexOf('.') + 1);
									localStorage.setItem("libraryScreenSaverExt", fileScreenSaverExt);
								}
							}
							else {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
								}
							}
						});

						localStorage.setItem("selectedFamilyID", selected_item.MODEL_FAMILY ? Array.prototype.map.call(selected_item.MODEL_FAMILY, m => m.ID).toString() : '');
						if (selected_item.BRAND_NAME === "MERRYCHEF") {
							if (libraryFiles[0] && (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe" || selected_item.TYPE === "Settings")) {
								let libraryData = [];
								if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
									UpdatedJSONObj && UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
										libraryData[i] = {};
										libraryData[i].index = i;
										libraryData[i].temp = item.actions.set_point.value + item.actions.set_point.units.replace('deg', '');
										let total = 0;
										if (item.duration) {
											let duration = item.duration.replace('PT', '');
											let minutes, seconds = 0;
											if (duration.includes('M')) {
												minutes = duration.substr(0, duration.indexOf('M'))
											}
											if (duration.includes('S')) {
												seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
											}
											total = minutes + ':' + seconds;
										}
										libraryData[i].time = total;
										libraryData[i].fan = item.actions.fan;
										libraryData[i].power = item.actions.microwave;
										libraryData[i].paramDelete = '';
										libraryData[i].instruction = (item.actions.prompt && item.actions.prompt.message) ? item.actions.prompt.message.en_GB : '';
										libraryData[i].instructionShowid = '';
										libraryData[i].instructionDelete = '';
										libraryData[i].instructionVisible = '';
										if (item.actions.prompt && item.actions.prompt.image) {
											libraryData[i].instructionImage = item.actions.prompt.image;
											libraryData[i].instructionImageS3Url = "";

											libraryFiles && libraryFiles.map((libraryitem, j) => {
												if (!libraryitem.fileName.toLowerCase().includes('.json')) {
													if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.actions.prompt.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
														libraryData[i].instructionImageb64 = libraryitem.fileBuffer.toString('base64');
														let imageSize = Buffer.byteLength(libraryitem.fileBuffer);
														libraryData[i].instructionImageSize = (imageSize / 1048576).toFixed(2);
													}
												}
											});
										}
									})

									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.settings = {};
									libraryDetails.settings.portion = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.portion : 1;
									libraryDetails.settings.state = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.state : "ambient";
									libraryDetails.settings.weight = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.weight : 50;
									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								}
								else {
									libraryData = JSON.parse(libraryFiles[0]);
									let schedulers = libraryData.scheduler;
									if (!Array.isArray(schedulers)) {
										libraryData.scheduler = []
									}
									else {
										schedulers.map((parent, i) => {
											parent.configurations.map((item, j) => {
												if (item.type !== "Cold") {
													item.id = j + 1;
												}
												else {
													item.id = 12;
												}
											})
										})
									}

									localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))
								}
								localStorage.setItem("libraryData", JSON.stringify(libraryData));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(libraryData)));
								}
							}

							if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "RecipeImage");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								if (imageBuffer !== null) {
									Jimp.read(imageBuffer)
										.then(image => {
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = selected_item.LIBRARY_ID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;
													localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
													localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/recipeCreation",
														previouspath: "/cookbookAddRecipes"
													});
												});
											})
										})
								} else {
									let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;
									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/recipeCreation",
										previouspath: "/cookbookAddRecipes"
									});
								}
							}
							else if (selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;

									let imageData = null;
									let thumbnailData = null;
									let imageFileFormat = "";
									let imageFileName = "";
									let imageFileSize = "";

									let fileext = item.image.substring(item.image.lastIndexOf('.') + 1);
									let itemFileName = item.image.replace(fileext, "png");

									libraryFiles && libraryFiles.map((libraryitem, j) => {
										if (libraryitem.fileName.includes('tmb_')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
												thumbnailData = libraryitem.fileBuffer;
											}
										}
										else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
												imageData = libraryitem.fileBuffer;
												let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
												let fileextSplit = fileName.split('.');
												let fileExt = fileextSplit[1];
												imageFileFormat = "." + fileExt;
												imageFileName = libraryitem.fileName;
												// read json string to Buffer
												let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
												imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
									});

									category.imageFileName = imageFileName;
									category.imageFileFormat = imageFileFormat;
									category.imageFileSize = imageFileSize;
									category.imageS3Url = item.imageS3Url ? item.imageS3Url : "";
									category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
									category.imageData = "";
									category.thumbnailData = thumbnailData;
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									let _checkRecipeExist = data.filter(o1 => category.recipe_items.some(o2 => o1.LIBRARY_ID === o2.LIBRARY_ID));
									category.recipe_items = category.recipe_items && category.recipe_items.filter(item => item.length == undefined);
									category.subCategory = [];
									item.menu_items && item.menu_items.map((subitem, l) => {
										let subCategory = {};
										subCategory.ID = subitem.id;

										let thumbnailData = null;
										let imageFileFormat = "";
										let imageFileName = "";
										let imageFileSize = "";

										let fileext = subitem.image.substring(subitem.image.lastIndexOf('.') + 1);
										let itemFileName = subitem.image.replace(fileext, "png");

										libraryFiles && libraryFiles.map((libraryitem, m) => {
											if (libraryitem.fileName.includes('tmb_')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
													thumbnailData = libraryitem.fileBuffer;
												}
											}
											else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === subitem.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
													imageData = libraryitem.fileBuffer;
													let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
													let fileextSplit = fileName.split('.');
													let fileExt = fileextSplit[1];
													imageFileFormat = "." + fileExt;
													imageFileName = libraryitem.fileName;
													// read json string to Buffer
													let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
													imageFileSize = (fileSize / 1048576).toFixed(2);
												}
											}
										});

										subCategory.imageFileName = imageFileName;
										subCategory.imageFileFormat = imageFileFormat;
										subCategory.imageFileSize = imageFileSize;
										subCategory.imageS3Url = subitem.imageS3Url ? subitem.imageS3Url : "";
										subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
										subCategory.imageData = "";
										subCategory.thumbnailData = thumbnailData;
										subCategory.recipe_items = [];
										subitem.recipe_items && subitem.recipe_items.map((recipeitem, n) => {
											let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
											subCategory.recipe_items[n] = recipe && recipe[0] ? recipe[0] : [];
										});
										subCategory.recipe_items = subCategory.recipe_items && subCategory.recipe_items.filter(item => item.length == undefined);
										category.subCategory.push(subCategory);
									});
									categoryDetailsObj.push(category);
								});

								localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								if (selected_item.SETTINGS_LIBRARY_ID !== null || selected_item.SETTINGS_LIBRARY_ID !== 0 ){
									await this.OnEdit_For_Setting_Library();
								} else {
									this.props.history.push({
										pathname: "/cookbookEditor",
										previouspath: "/libraryList"
									});
								}
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.settings = UpdatedJSONObj.sounds;
								libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
								libraryDetails.initialMethod = "Edit"

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryImage", b64);

								this.props.history.push({
									pathname: "/settingsEditor",
									previouspath: "/libraryList"
								});
							}
						}
						else if (this.state.selected_item.BRAND_NAME === "FRYMASTER") {
							if (libraryFiles[0] && this.state.selected_item.TYPE === "Recipe") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let quality_timer = UpdatedJSONObj.settings.quality_timer;
								UpdatedJSONObj.settings.quality_timer = quality_timer ? this.getFormatedTime(quality_timer) : '';
								let drain_timer = UpdatedJSONObj.settings.drain_timer;
								UpdatedJSONObj.settings.drain_timer = drain_timer ? this.getFormatedTime(drain_timer) : '';
								let hold_timer = UpdatedJSONObj.settings.hold_timer;
								UpdatedJSONObj.settings.hold_timer = hold_timer ? this.getFormatedTime(hold_timer) : '';
								let instant_on = UpdatedJSONObj.settings.instant_on;
								UpdatedJSONObj.settings.instant_on = instant_on ? this.getFormatedTime(instant_on) : '';

								UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
									UpdatedJSONObj.ordered_steps[i].duration = item.duration ? this.getFormatedTime(item.duration) : '';
								});

								UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.map((item, i) => {
									let duration = item.actions.prompt.duration;
									UpdatedJSONObj.conditional_steps[i].actions.prompt.duration = duration ? this.getFormatedTime(duration) : '';
									UpdatedJSONObj.conditional_steps[i].condition.property_value = duration ? this.getFormatedTime(duration) : '';
								});

								localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));

								let resourceDetails = UpdatedJSONObj.recipeResources;
								if (UpdatedJSONObj.recipeResources.length > 0) {
									resourceDetails[0].imageData = '';
									resourceDetails[0].thumbnailData = b64;
								}
								localStorage.setItem("resourceDetails", JSON.stringify(resourceDetails));

								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = this.state.selected_item.LIBRARY_ID;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/frymasterRecipeCreation",
									previouspath: "/libraryList"
								});
							}
							else if (libraryFiles[0] && selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.autoMenuChange = UpdatedJSONObj.autoMenuChange;
								libraryDetails.limitedTimeOffer = UpdatedJSONObj.limitedTimeOffer;
								libraryDetails.initialMethod = "Edit";

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;
									category.categoryName = item.name ? item.name.en_US ? item.name.en_US : "" : "";
									category.type = "Category";
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									categoryDetailsObj.push(category);
								});

								localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/FrymasterMenuEditor",
									previouspath: "/libraryList"
								});
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit";

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(UpdatedJSONObj));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(UpdatedJSONObj)));
								}

								this.props.history.push({
									pathname: "/frymasterSettings",
									previouspath: "/libraryList"
								});
							}
						} else if (this.state.selected_item.BRAND_NAME === "GARLAND") {
							console.log("libraryFiles", libraryFiles)							
							const temp_units = UpdatedJSONObj && UpdatedJSONObj.temp_units ? JSON.parse(UpdatedJSONObj.temp_units) : JSON.parse("{\"unit_cooking_method\":\"°C\",\"unit_cooking_steps\":\"°C\"}");

							if (libraryFiles[0] && (selected_item.TYPE === "Recipe")) {
								let libraryData = {};
								if (selected_item.TYPE === "Recipe") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									libraryData = UpdatedJSONObj;
									let unitCooking = temp_units['unit_cooking_method'] ? temp_units['unit_cooking_method'] : "°C";
                                    libraryData['unit'] = unitCooking ;
                                    let too_cold_limit = Number(libraryData['too_cold_limit']) !== 0 ? Number(libraryData['too_cold_limit']) / 10 : 0;
                                    let too_hot_limit = Number(libraryData['too_hot_limit']) !== 0 ? Number(libraryData['too_hot_limit']) / 10 : 0;
									too_cold_limit = too_cold_limit === 0 ? too_cold_limit : unitCooking === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit ;
									too_hot_limit = too_hot_limit === 0 ? too_hot_limit : unitCooking === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit ;
                                    libraryData['too_cold_limit'] = too_cold_limit > 100 ? 100 : too_cold_limit ;
                                    libraryData['too_hot_limit'] = too_hot_limit > 100 ? 100 : too_hot_limit ;									
									libraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;
									libraryData['cooking_methodology'] = cookingMethodologyMapping[UpdatedJSONObj.cooking_methodology];
									let cookObj = [];
									const promptMapping = {
										'sear': 'Sear',
										'remove in time': 'Remove in time',
										'flip (must ack)': 'Flip (Must Ack)',
										'flip (run time)': 'Flip (Run time)',
										'default done': 'Default Done',
										'platen closing': 'Platen Closing',
										'none': 'None'
									}
									const unitCookingSteps = temp_units['unit_cooking_steps'] ? temp_units['unit_cooking_steps'] : "°C" ;
									for (let index = 0; index < UpdatedJSONObj.cookingSteps.length; index++) {
										const element = UpdatedJSONObj.cookingSteps[index];
										let obj = {
											cookTime: element.CookTime,
											promptSelection: promptMapping[element.PromptSelection] ? promptMapping[element.PromptSelection] : element.PromptSelection,
											prompt: element.Prompt,
											uniqueId: (new Date()).getTime(),
											flatCooking: element.isFlat === 1 ? 'Yes' : 'No',
											cookingGap: element && element.Gap ? element.Gap / 100000 : 0,
											unit: unitCookingSteps,
											upperPlattenTemp: element.tempUpperPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,
											upperPlattenCheck: element.tempUpperPlaten === 0 ? false : true,
											upperPlattenValue: element.tempUpperPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,

											grillPlattenTemp: element.tempLowerPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,
											grillPlattenCheck: element.tempLowerPlaten === 0 ? false : true,
											grillPlattenValue: element.tempLowerPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,
											
											grillFrontTemp: element.tempLowerFront === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
											grillFrontCheck: element.tempLowerFront === 0 ? false : true,
											grillFrontValue: element.tempLowerFront === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
										}
										cookObj.push(obj)
									}
									libraryData['steps'] = cookObj;
								}

								localStorage.setItem("garlandRecipe", JSON.stringify(libraryData));
							}

							if (selected_item.TYPE === "Recipe") {
								console.log("libraryFiles", libraryFiles)
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "Image");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								console.log("imageBuffer", imageBuffer)

								if (imageBuffer !== null) {
									return Jimp.read(imageBuffer)
										.then(image => {
											console.log("image", image)
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3_SIGNED_URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = UpdatedJSONObj.recipeID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;
													libraryDetails.imageThumbnailb64 = thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, "");

													localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
													localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/garlandRecipeCreation",
														previouspath: "/libraryList"
													});
												});
											})
										}).catch((err) => {
											console.log("catch 1", err)

											let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
											libraryDetails = libraryDetails ? libraryDetails : {};
											let imageData = {};
											libraryDetails.imageData = imageData;
											libraryDetails.id = selected_item.LIBRARY_GUID;
											libraryDetails.recipeName = selected_item.LIBRARY_NAME;
											libraryDetails.isEdit = true;
											libraryDetails.library_id = selected_item.LIBRARY_ID;
											libraryDetails.isImageChanged = false;
											libraryDetails.initialMethod = "Edit";
											libraryDetails.recipeType = selected_item.TYPE;
											libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

											localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
											this.props.history.push({
												pathname: "/garlandRecipeCreation",
												previouspath: "/libraryList"
											});
										})
									// }).catch((err) => {
									// 	console.log("catch ", err)
									// 	let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
									// 	libraryDetails = libraryDetails ? libraryDetails : {};
									// 	let imageData = {};
									// 	libraryDetails.imageData = imageData;
									// 	libraryDetails.id = selected_item.LIBRARY_GUID;
									// 	libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									// 	libraryDetails.isEdit = true;
									// 	libraryDetails.library_id = selected_item.LIBRARY_ID;
									// 	libraryDetails.isImageChanged = false;
									// 	libraryDetails.initialMethod = "Edit";
									// 	libraryDetails.recipeType = selected_item.TYPE;
									// 	libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									// 	localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
									// 	this.props.history.push({
									// 		pathname: "/garlandRecipeCreation",
									// 		previouspath: "/libraryList"
									// 	});
									// })

								} else {
									let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
									console.log("libraryDetails parse", libraryDetails)

									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;
									libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/garlandRecipeCreation",
										previouspath: "/libraryList"
									});
								}
							}

							//Garland Cookbook
							if (libraryFiles[0] && (selected_item.TYPE === "CookBook")) {
								localStorage.setItem("cookBookFileName", selected_item.LIBRARY_NAME);
								await this.getGarlandRecipeDetails()
								let cookBooklibraryData = {};

								if (selected_item.TYPE === "CookBook") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									cookBooklibraryData = UpdatedJSONObj;
									cookBooklibraryData['unit'] = "°C";
									cookBooklibraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									let garlandMenus = [];
									for (let intCtr = 0; intCtr < UpdatedJSONObj.menus.length; intCtr++) {
										let element = UpdatedJSONObj.menus[intCtr];
										let objs = {
											imageFileName: element.icon ? element.icon : "",
											imageFileFormat: ".png",
											imageFileSize: "",
											// 	imageS3Url: UploadedImages3url,
											imageData: "",
											thumbnailData: "",
											categoryName: element.menuName ? element.menuName : '',
											ID:element.menuID,
											type: "Category",
											parentID: "",
											selected: false,
											unit: element.unit ? element.unit : "°C",

											grill_Plate_limit: element.preHeatLowerPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,
											grillPlateLimitCheck: element.preHeatLowerPlaten === 0 ? false : true,
											grillPlateLimitValue: element.preHeatLowerPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,

											grill_Front_limit: element.preHeatLowerFront === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,
											grillFrontLimitCheck: element.preHeatLowerFront === 0 ? false : true,
											grillFrontLimitValue: element.preHeatLowerFront === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,

											upper_Platen_limit: element.preHeatUpperPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											upperPlatenLimitCheck: element.preHeatUpperPlaten === 0 ? false : true,
											upperPlatenLimitValue: element.preHeatUpperPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											flat_Cooking: element.is_flat === 1 ? 'Yes' : 'No',
										}
										if (element.icon) {
											let RecipeImage = libraryFiles.find(l => l.fileType === "Image" && l.fileName.includes(element.icon));
											imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
											if (imageBuffer !== null) {
												objs.thumbnailData = imageBuffer.replace(/^data:image\/[A-Za-z]+;base64,/, "");
												let fileSize = Buffer.byteLength(RecipeImage.fileBuffer);
												objs.imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
										objs.recipe_items = [];
										const prObjToFindRecipe = UpdatedJSONObj && UpdatedJSONObj.product_recognition ? UpdatedJSONObj.product_recognition : {};
										for (let rcCtr = 0; rcCtr < element.recipeList.length; rcCtr++) {
											const isAuto = prObjToFindRecipe && prObjToFindRecipe.recipeID ?
															 prObjToFindRecipe.recipeID === element.recipeList[rcCtr] 
															: false;
											let recipe = isAuto ? [{
												ID: 0,
												recipeId: prObjToFindRecipe.recipeID,
												LIBRARY_GUID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												LIBRARY_ID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												recipeName: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												LIBRARY_NAME: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												CREATED_ON:  selected_item.UPDATED_ON || "",
												UPDATED_ON:  selected_item.UPDATED_ON || ""
											}] : this.getRecipeByID(element.recipeList[rcCtr])
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												objs.recipe_items.push(regObj)
											}
										}
										garlandMenus.push(objs)
									}
									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
									libraryDetails.crc = md5(b64, "base64");
									libraryDetails.isEdit = true;
									libraryDetails.initialMethod = "Edit";


									localStorage.setItem("resourceDetails", JSON.stringify(garlandMenus));
									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

									//Product Recognition			
									if (UpdatedJSONObj.product_recognition) {
										let productRecognitionObj = {};
										let prElement = UpdatedJSONObj.product_recognition;
										productRecognitionObj.unit = prElement.unit ? prElement.unit : "°C";

										let too_cold_limit = Number(prElement.too_cold_limit) !== 0 ? Number(prElement.too_cold_limit) / 10 : 0;
										let too_hot_limit = Number(prElement.too_hot_limit) !== 0 ? Number(prElement.too_hot_limit) / 10 : 0;
										too_cold_limit = too_cold_limit === 0 ? too_cold_limit : prElement.unit === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit ;
										too_hot_limit = too_hot_limit === 0 ? too_hot_limit : prElement.unit === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit;
										productRecognitionObj.tooColdLimitValue = too_cold_limit > 100 ? 100 : too_cold_limit ;
										productRecognitionObj.tooHotLimitValue = too_hot_limit > 100 ? 100 : too_hot_limit;

										let prCooking = prElement.cookingSteps ? prElement.cookingSteps[0] : {}
										productRecognitionObj.grill_Plate_limit = prCooking.tempLowerPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;
										productRecognitionObj.grillPlateLimitCheck = prCooking.tempLowerPlaten === 0 ? false : true;
										productRecognitionObj.grillPlateLimitValue = prCooking.tempLowerPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;

										productRecognitionObj.grill_Front_limit = prCooking.tempLowerFront === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;
										productRecognitionObj.grillFrontLimitCheck = prCooking.tempLowerFront === 0 ? false : true;
										productRecognitionObj.grillFrontLimitValue = prCooking.tempLowerFront === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;

										productRecognitionObj.upper_Platen_limit = prCooking.tempUpperPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;
										productRecognitionObj.upperPlatenLimitCheck = prCooking.tempUpperPlaten === 0 ? false : true;
										productRecognitionObj.upperPlatenLimitValue = prCooking.tempUpperPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;

										productRecognitionObj.cookingMethodology = prCooking.cooking_methodology ? cookingMethodologyMapping[prElement.cooking_methodology] : 'Steel';
										productRecognitionObj.recipeID = prElement.recipeID;
										productRecognitionObj.recipeName = prElement.recipeName ? prElement.recipeName : 'Auto';
										productRecognitionObj.ID = 0;
										productRecognitionObj.recipe_items = [];

										let prCookingRegions = prElement.cookingRegions ? prElement.cookingRegions : [];
										for (let prCtr = 0; prCtr < prCookingRegions.length; prCtr++) {
											let recipe = this.getRecipeByID(prCookingRegions[prCtr].regionRecipeID)
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												regObj.minGap = prCookingRegions[prCtr].gapLowerLimit / 100000;
												regObj.maxGap = prCookingRegions[prCtr].gapUpperLimit / 100000;
												productRecognitionObj.recipe_items.push(regObj)
											}
										}
										localStorage.setItem("selectedPRRecipes", JSON.stringify(productRecognitionObj.recipe_items));
										localStorage.setItem("ProductRecgDetails", JSON.stringify(productRecognitionObj));
									}

								}
								this.props.history.push({
									pathname: "/garlandCookbookCreation",
									previouspath: "/libraryList"
								});
							}
							
						}
						this.setState({
							loaderIconVisible: false
						});
					});
				}
				catch (ex) {
					this.setState({
						loaderIconVisible: false
					});
				}
			}
		})
			.catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}

    editRecipeRedirect = (e) => {
        localStorage.setItem("RecipesListPage", "/cookbookAddRecipes");
        this.setState({ selected_item: e }
            , () => {
                this.OnEditLibrary()
            })
    }

    onSaveDetailsPopup = (event) => {
		const { data } = this.state;
		data.forEach((item) => {
			if (item.ID === event.ID) {
				item.DESCRIPTION = event.DESCRIPTION;
				item.ASSIGNED_CUSTOMER_GROUP = event.ASSIGNED_CUSTOMER_GROUP;
				item.UPDATED_ON = event.UPDATED_ON;
				item.UPDATED_BY = event.UPDATED_BY
			}
		})
		this.setState({
			data
		})
	}

    onCloseDetailsPopup = () => {
		this.setState({
			assignedTopologyDetails: null,
		})
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
	}

    OnCancelLibrary = () => {
		$('#deleteModal').modal('hide');
	}

    OnSelectRecipe = (item) => {
        $('#deleteModal').modal('show');
        this.setState({
			selectedRecipeDetails: item && item.ID
		})
    }

    OnDeleteLibrary = async () => {
        let { selectedRecipeDetails } = this.state;
		const { formatMessage } = this.props.intl;
        this.setState({
            loaderIconVisible: true
        })
        let postData = {
            libraryIds: selectedRecipeDetails
        }

        this.putData(postData, MenuUploadAPI.getLibraryList, HTTP_METHOD.DELETE).then(async (response) => {
            if (response && response.data && response.data.message) {
                if (response.data.httpCode === "HTTP_200") {
                    await this.getLibraryList();
					let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
					allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
					allRecipeDetails.map((item, index) => {
						if(item.ID === selectedRecipeDetails) {
							allRecipeDetails.splice(index, 1);
						}
					})
					localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(allRecipeDetails)));
					let categoryDetails = localStorage.getItem("resourceDetails")
					categoryDetails = JSON.parse(categoryDetails);
					categoryDetails && categoryDetails.map((item, index) => {
						item.recipe_items && item.recipe_items.map((recipe, index) => {
							if (recipe.ID === selectedRecipeDetails) {
								item.recipe_items.splice(index, 1);
							}
						})
						item.subCategory && item.subCategory.map((subItem, index) => {
							subItem.recipe_items && subItem.recipe_items.map((subRecipe, index) => {
								if (subRecipe.ID === selectedRecipeDetails) {
									subItem.recipe_items.splice(index, 1);
								}
							})
						})
					})
					localStorage.setItem("resourceDetails", JSON.stringify(categoryDetails));
					let message = formatMessage({ id: 'KC2380' });
                    this.setState({
                        success_modal: {
                            open: true,
                            message: message
                        },
                        loaderIconVisible: false,
                    });
                }
            }
        }).catch(err => {
            this.showError(err);
        });
    }

    putData = (postData, url, method) => {
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            http({
                method: method,
                url: url,
                headers: {},
                data: postData
            }).then(res => {
                let { data } = res;
                let { httpCode } = data;
                if (httpCode !== "HTTP_200") {
                    reject({
                        response: {
                            data: res.data
                        }
                    });
                } else {
                    resolve(res);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
		let _seperator = ' - ';
        let { data: dataList, loaderIconVisible, selectedCategoryName, search, assignedTopologyDetails, success_modal } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");

        const searchedItemInFilter = dataList && dataList.filter(
            (recipe) => {
                return recipe.LIBRARY_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;

        let libraryAuthFeature = authService.getFeature("/libraryList");
        return (
            <React.Fragment>
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
						<div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">						
                                  {selectedCategoryName}  { selectedCategoryName && _seperator + formatMessage({ id: "KC1189"}) }
                				</div>
                                <div class="cbSearchForm"> 
                                    <ul>
                                        <li>
                                            <div class="cbSearchField">
                                                <input type="text" class="form-control" onChange={(e) => this.recipeSearchResult(e)} placeholder={formatMessage({ id: 'KC1971' })}></input>
                                            </div>
                                        </li>
                                        <li>
                                            <button type="button" class="btn cbSearchButton">&nbsp;</button>
                                        </li>
                                    </ul> 
                                </div>	
                                <div class="reBodyWrapper mCustomScrollbar withSearchBar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                        <div class="reWrapperColmn alignTop">
                                                {
                                                    dataList.map((item, i) => {
                                                        return (
                                                            <div class="cbAddRecipe">
                                                                <ul>
                                                                    <li><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")} alt="" /></li>
                                                                    <li>{item.LIBRARY_NAME}</li>
                                                                    <li>
																		<button type="button" className="btn menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button>
                                                                        <a href="#" className="btn menuEditV" onClick={(e) => { this.editRecipeRedirect(item, 'Recipe') }}>&nbsp;</a>
                                                                        <button type="button" className="btn menuDelete" data-toggle="modal" onClick={() => this.OnSelectRecipe(item)}>&nbsp;</button>
                                                                    </li>
                                                                    <li>
                                                                        <div className={libraryAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
                                                                            <input id={item.ID} name="RecipesList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
                                                                            <label htmlFor={item.ID}></label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <a href="#" onClick={(e) => { this.reciperedirect(e,'Recipe') }} class="btn rcChefHat" title="Recipe Creation">&nbsp;</a>
                                    <button type="button" onClick={() => { this.onAddRecipes() }} className="btn btn-default-text saveCButton" title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
                                </div>
                            </div>
                            {/* <div class="footerText"><FormattedMessage id="KC1217"/></div> */}
                        </div>
						</div>
                    </div>
                </div>
                {/* <!-- Info Modal --> */}
				{assignedTopologyDetails ? <CommonDetailViewEdit detailsProps={assignedTopologyDetails} onSave={this.onSaveDetailsPopup} onClose={this.onCloseDetailsPopup} thumbnail={assignedTopologyDetails.TYPE === "Settings" ? "" : assignedTopologyDetails.IMAGE_S3_SIGNED_URL ? assignedTopologyDetails.IMAGE_S3_SIGNED_URL : ""} /> : null}
				{/* <!-- //Info Modal --> */}
                {/* <!-- Delete Confirmation Modal --> */}
				<div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="contentDiv">
									<p>Are you sure you want to delete this Recipe?</p>
									<p>Once deleted, it will be permanently removed from your account and will no longer be available for use in any other cookbook.</p>
									<p>This action cannot be undone.</p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
							</div>
						</div>
					</div>
				</div>
                {/* <!-- //Delete Confirmation --> */}
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(CookbookAddRecipes)
