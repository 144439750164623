import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReportManagement } from '../../../service/api';
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';

class ProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            loaderIconVisible: false,
            responseData: [],
            excludedProductList: [],
            oldProdID: "",
            oldProdName: "",
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            allSelect: false,
            isDelete: false,
            isEdit: false,
            isIndeterminate: false,
            sortColumn: { path: "", order: "", sort_color: "#63778a" }
        };
    }

    componentDidMount = () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";
        this.getProductsDetails();
    }

    getProductsDetails = () => {
        this.setState({ loaderIconVisible: true });
        let url = ReportManagement.excludeProductDetails;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                brandid: localStorage.getItem("selectedbrandID"),
                cid: localStorage.getItem("customerId"),
                userid: localStorage.getItem("user"),
            }, data: {}
        }).then(async response => {
            if (response && response.data) {

                let arr = [];
				let isDelete = false;
                for (let i = 0; i <  response.data[0].excludedProductDetails.length; i++) { 
                    const ele =  response.data[0].excludedProductDetails[i];                                  
                    isDelete = await this.checkDeleteAccess(ele);
					arr.push({...ele,isAuthorized: isDelete})
                };
                this.setState({
                    excludedProductList: arr,
                    loaderIconVisible: false,
                    isEdit: false,
                    isDelete: false,
                    allSelect: false,
                    isIndeterminate: false
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onSelectRole = (e, item) => {
      this.setState({
        oldProdID: item.product_id,
        oldProdName: item.product_name,
        curr_Prod_ID: item.product_id,
        curr_Prod_Name: item.product_name,
        add_mode: false,
        update_mode: true,
    })

      if (this.state.excludedProductList && this.state.excludedProductList.length > 0) {
        const rolesList = [...this.state.excludedProductList];
        const index = rolesList.indexOf(item);
        rolesList[index] = { ...rolesList[index] };
        rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;
        this.isSelectEditDownload(rolesList);
      }
    }


    handleChange = (e) => {
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            r.checked = r.isAuthorized ? e.target.checked : false;
        });
        this.isSelectEditDownload(rolesList);
    };

    isSelectEditDownload = async (rolesList) => {
      let selectedRoles = rolesList.filter(item => item.checked === true);
      let isDelete, isEdit, isSelectAllRoles, isIndeterminate;

      isDelete = selectedRoles.length > 0 ? true : false;
      isEdit = selectedRoles.length === 1 ? true : false;

      if(selectedRoles.length === rolesList.length) {
        isSelectAllRoles= true; isIndeterminate = false;
      } else if(selectedRoles.length === 0 && rolesList.length !==0){
        isSelectAllRoles= false; isIndeterminate = false;
      } else if(rolesList.length > selectedRoles.length) {
        isSelectAllRoles= true; isIndeterminate = true;
      } else {
        isSelectAllRoles= false; isIndeterminate = false;
      }

      this.setState({
          excludedProductList: rolesList,
          allSelect: isSelectAllRoles,
          isDelete: isDelete,
          isEdit: isEdit,
          isMultiDelete: true,
          isIndeterminate: isIndeterminate
      });
    }

    onEditClick = (e, item) => {
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(i => {
            if (i.checked === true || i === true) {
                this.setState({
                    add_mode: false,
                    update_mode: true,
                    curr_Prod_ID: i.product_id,
                    curr_Prod_Name: i.product_name,
                })
            }

        });
    }

    onAddClick = (e, item) => {
        this.setState({
            oldProdID: "",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            isEdit: false,
            isDelete: false,
            allSelect: false,
            isIndeterminate: false
        })
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            r.checked = false;
        });
    }

    findAddtOrUpdateProduct = () => {
        this.setState({ loaderIconVisible: true })
        if (this.state.add_mode) {
            this.addProductDetails();
            this.setState({ curr_Prod_ID: "", curr_Prod_Name: "" })
        } else if (this.state.update_mode) {
            this.updateProductDetails();
        }
    }

    addProductDetails = (e, item) => {
        let reqData = {
            "brand_id": localStorage.getItem("selectedbrandID"),
            "brand_name": localStorage.getItem("brandName"),
            "product_id": this.state.curr_Prod_ID,
            "product_name": this.state.curr_Prod_Name,
            "customer_id": localStorage.getItem("customerId"),
            "user_id": localStorage.getItem("user"),
        };
        let url = ReportManagement.excludeProductDetails;
        http.post(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                let { data } = response;
                let message;

                if (response.data.message) {
                    if (response.data.message === "product name and id already exist in exclude product list") {
                        message = "KC0778";
                    } else {
                        message = "KC0779";
                    }
                    WoopraEvents(`${Constants.EXCLUDE_PRODUCT_CREATED}`);
                }
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: message,
                    },

                });
            }).catch(err => {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.response.data.message,
                    },
                });
            });

    }

    updateProductDetails = (e, item) => {
        let reqData = {
            "brand_id": localStorage.getItem("selectedbrandID"),
            "brand_name": localStorage.getItem("brandName"),
            "product_id": this.state.curr_Prod_ID,
            "product_name": this.state.curr_Prod_Name,
            "customer_id": localStorage.getItem("customerId"),
            "user_id": localStorage.getItem("user"),
            "old_product_name": this.state.oldProdName,
            "old_product_id": this.state.oldProdID,
        };
        let url = ReportManagement.excludeProductDetails;
        http.put(url, reqData,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            let { data } = response;
            let { httpCode} = data;
            let message;
            if (response.data.message) {
                if (response.data.message === "product name and id already exist in exclude product list") {                                               
                    message = "KC0778";
                } else {
                    message = "KC0779";
                }
                WoopraEvents(`${Constants.EXCLUDE_PRODUCT_UPDATED}`);
            }            
            if (httpCode === "HTTP_200") {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: message,
                    },
                });
            }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response.data.message,
                },
            });
        });
    }

    deleteProductDetails = (e, item) => {
        this.setState({ loaderIconVisible: true });
        let reqData = [];
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(i => {
            if (i.checked === true) {
                let val = {
                    "product_id": i.product_id,
                    "product_name": i.product_name,
                }
                reqData.push(val);
            }
        });
        let url = ReportManagement.excludeProductDetails;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',
                "brandid": localStorage.getItem("selectedbrandID"),
                "cid": localStorage.getItem("customerId"),
                "userid": localStorage.getItem("user"),
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            let { httpCode } = data;
            if (httpCode === "HTTP_200") {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: this.state.isMultiDelete ? "KC0781" : "KC1721"
                    },
                });
                WoopraEvents(`${Constants.EXCLUDE_PRODUCT_DELETED}`);
            }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    prodId_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_ID: rName,
        })
    }

    prodName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_Name: rName,
        })
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        })
        this.getProductsDetails();
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    onSort = sortColumn => {
      this.setState({
        sortColumn: sortColumn
      });
    }

    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.createdBy && mediaValue.createdBy.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.createdBy) || username === mediaValue.createdBy ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		
		return isDelete;
	}


    render() {
        const { formatMessage } = this.props.intl;
        let { excludedProductList, loaderIconVisible, error_modal, success_modal, isEdit, isDelete, allSelect, isIndeterminate, sortColumn } = this.state;
        let { curr_Prod_ID, curr_Prod_Name } = this.state;
        let isValidate = false;
        if (curr_Prod_ID && curr_Prod_Name){
            isValidate = true;
        }
        let columns = [];
        let orderId = 1;
        columns = [
          { path: "", labelStringID: "KC0774" },
				  { path: "", labelStringID: "KC0775" },
				  { path: "", label: "", isCheckBox: true, isCheckBoxActive: true }
        ];

        excludedProductList = _.orderBy(excludedProductList, [sortColumn.path], [sortColumn.order]);

        excludedProductList.forEach(item => {
          item.order_id = orderId++
        });

        let errorCenterAuthFeature = {};
        errorCenterAuthFeature = authService.getFeatureByFeatureName("Exclude Products");

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div className="stickyTableHeader">
                        <div className="menuUpdateWrapper">
                            <div className="unitRegHeader">
                                <ul>
                                    <li>
                                        <FormattedMessage id="KC1722" values={{ brand: localStorage.getItem("brandName") }}/>
                                    </li>
                                    <li>
                                        <button type="button" onClick={(e) => this.onEditClick(e)} data-toggle="modal" data-target="#excludeProductModal" class={isEdit && errorCenterAuthFeature.is_editable === 1 ? "btn unitEdit" : "btn unitEdit disabled"} >&nbsp;</button>
                                        <button type="button" class={isDelete && errorCenterAuthFeature.is_editable === 1? "btn unitDelete" : "btn unitDelete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" >&nbsp;</button>
                                        <button onClick={(e) => this.onAddClick(e)} onChange={(e) => this.onAddClick(e)} type="button" className={errorCenterAuthFeature.is_editable === 1 ? "btn unitAdd" : "btn unitAdd disabled"} data-toggle="modal" data-target="#excludeProductModal" >&nbsp;</button>
                                    </li>
                                </ul>
                            </div>

                            <form className="dspdPrdtsListForm">
                                <div className="dspdPrdtsListTable">
                                    <TableHeader
                                      sortColumn={sortColumn}
                                      onSort={this.onSort}
                                      selectAllChange={this.handleChange}
                                      columns={columns}
                                      isSelectAll={allSelect}
                                      isIndeterminate={isIndeterminate} />

                                    {excludedProductList && excludedProductList.map((item, i) =>
                                      <Fragment key={i}>
                                        <ul className="tBody">
                                            <li>{item.product_id} </li>
                                            <li>{item.product_name} </li>
                                            <li>
                                                {!item.isAuthorized ? <Tooltip content={ formatMessage({ id: 'KC1748' }) } direction="left" className="targetdisabled">
                                                    <div className={"customCheckbox customCheckboxWOlabel disabled"}>
                                                        <input id={i} name={i} type="checkbox"
                                                            checked={item.checked}
                                                            disabled
                                                            onChange={(e) => this.onSelectRole(e, item)} />
                                                        <label htmlFor={i}></label>
                                                    </div>
                                                </Tooltip> : <div className={"customCheckbox customCheckboxWOlabel"}>
                                                    <input id={i} name={i} type="checkbox"
                                                        checked={item.checked}
                                                        onChange={(e) => this.onSelectRole(e, item)} />
                                                    <label htmlFor={i}></label>
                                                </div>}
                                            </li>
                                        </ul>
                                      </Fragment>
                                    )}
                                </div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={excludedProductList !== undefined ? excludedProductList.length : 0} classname={'norecordsfoundbluetext'} />
                            </form>

                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Settings - Product Selection" headerNameStringID="KC0770" activeClass="settingsNav" />

                {/* <!-- Exclude Product Confirmation Modal -->  */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="excludeProductModal" tabindex="-1" role="dialog" aria-labelledby="excludeProductModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo">
                                    <h5><FormattedMessage id="KC0776"/></h5>
                                    <div className="modalFormWrapper">
                                        <div className="modalForm">
                                            <ul>
                                                <li>
                                                    <label><FormattedMessage id="KC0774"/>*</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => this.prodId_Input_Value(e)} value={this.state.curr_Prod_ID} type="number" className="form-control" placeholder={ formatMessage({ id: 'KC0774' }) } />
                                                    </div>
                                                </li>
                                                <li>
                                                    <label><FormattedMessage id="KC0775"/>*</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => this.prodName_Input_Value(e)} value={this.state.curr_Prod_Name} type="text" className="form-control" placeholder={ formatMessage({ id: 'KC0775' }) } />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                                <button onClick={(e) => this.findAddtOrUpdateProduct(e)} className={isValidate?"btn btn-secondary yesBtn":"btn btn-secondary yesBtn disabled"} id="timeZoneAssign" data-dismiss="modal" type="button"><FormattedMessage id="KC0777"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Exclude Product -->
                <!-- //Clear List Confirmation -->
                <!-- Clear List Confirmation Modal --> */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC0780"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                                <button onClick={(e) => this.deleteProductDetails(e)} className="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/> </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Clear List Confirmation -->
	            <!-- Clear List Confirmation Modal --> */}
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationDiv">
                                    <FormattedMessage id="KC1650"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary confirmationOKBtn" onclick="location.href='cremproductsslectn.html'" type="button"><FormattedMessage id="KC0777"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
export default injectIntl(ProductSelection); 