import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI, smartTagMangement } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { BRAND_ID_VALUE, CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID, unitSelectionAllowOnTransfer } from '../../../utils/appConstants';
import $ from 'jquery';
import _ from 'lodash';
import { injectIntl,FormattedMessage } from 'react-intl';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import SmartTagDrop from '../../controls/smartTagDragDrop';
import moment from '../../../utils/momentHelper.js';
import TagOrgModal from '../smartTag/tagOrgModel';
import authService from '../../../service/authService';
import GenericModal from '../../modal/generic-model';


var bg = require('../../../images/unit/CremCarrera.svg').default
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;

class LibraryAssignTopology extends Component {

    onSuccessCloseModal = () => {
        if (this.state.retrieveType) {
            this.props.history.push('/libraryList');
        }
        else {
            this.props.history.push('/menuUpdateStatus');
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            checkedUnits: [],
            initialload: true,
            units: [],
            showStPopUp:false,
			showpopup: [],
			showOrgPopup: false,
			type: "orgList",
            isSelectionHappen: true,
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            generic_modal: {
                open: false,
                message: "",
                messagetype: ""
            },
        };
        this.showLinks = this.showLinks.bind(this);
        this.getData = this.getData.bind(this);
        this.onFinishClickHandler = this.onFinishClickHandler.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onClickListView = this.onClickListView.bind(this);
        this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
    }
    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400 } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.message ? (data.stringID || data.message) : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1065"
                }

            });
        }
    }

    componentWillMount = () => {
        let { url, data, libraryIds, imageBuffer, previouspath, scheduleDateTimeUtc, Type, libraryType, retrieveType } = this.props.location;
        this.setState({
            url: url,
            data: data,
            libraryIds: libraryIds,
            imageBuffer: imageBuffer,
            previouspath: previouspath,
            scheduleDateTimeUtc: scheduleDateTimeUtc,
            Type: Type,
            libraryType: libraryType,
            retrieveType: retrieveType
        });
    }

    componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        this.getCustGroupID();
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeOuter');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top); 
    }

    getData = (url, Cgids) => { 
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                reject(err)
            })
        });
    }

    getCustGroupID = async () => {
        let loggedinUser;
        let custGroupID = localStorage.getItem("selected_cgid") ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
        let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID.length > 0 ? Array.prototype.map.call(custGroupID, m => m.cust_gp_id) : localStorage.getItem("custGroupID");
		}
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        this.setState({ loaderIconVisible: true, })
        try {
           await this.getOrgnanizationView(cgids);
        } catch (err) {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        };
    }

    /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
 * Convert hierarchy data structure into flatten array
 * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
 * @param {*} nodeLevel Node level No
 * @param {*} currentRowIndex Current row index for Tree Data
 * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
 * @param {*} parentRowIndex Parent Node row index
 * @param {*} parentColIndex Parent Node column index
 * @returns Array<Array>
 */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
		const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,
            isUnitItemClicked: true
        });
    }

    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true });
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': localStorage.getItem("selectedbrandID") ? localStorage.getItem("selectedbrandID") : BRAND_ID,
                // "basecountry": BASECOUNTRY_ID,
                'useremail': loggedinUser,
                'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                'provisionedunits': true,
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
				await $.getJSON(response.data.presignedS3URL, (data) => {
					 this.processOrgnanizationJSON(data);
				});
			}
			else {
				this.processOrgnanizationJSON(response.data);
            }
        }).catch(err => {
            this.setState({
                linkListValue: {},
                loaderIconVisible: false,
            })
        });
    }

    onFinishClickHandler = async(e) => {
        e.preventDefault();
        // let userCheckedData = this.state.checkedUnits.filter(unit => unit.checked === true);
        let userCheckedData = this.state.checkedUnits;
        if (!userCheckedData.length) { 
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1154"
                }
            });
        } else {
            const unitList = [];
            userCheckedData.map(item => {
                return unitList.push(
                    {
                        "thingArn": item.THING,
                        "cloudUnitName": item.CLOUD_UNIT_NAME,
                        "unitTimeZone": item.TimeZone_Standard,
                        "productId": item.ProductId,
                        "cgid": item.cgid
                    }
                )
            });

            if (this.state.retrieveType) {
                this.setState({ loaderIconVisible: true });
                let postdata = {
                    thingList: unitList,
                    brandid: localStorage.getItem("selectedbrandID"),
                    fileType: this.state.libraryType
                }
        
                const url = MenuUploadAPI.retrieveMenuFromDevice;
                await http.post(url, postdata,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            // basecountry: localStorage.getItem("basecountry"),
                            user: localStorage.getItem("user")
                        }
                    }
                ).then(response => {
                    if (response && response.data) {
                        this.setState({
                            loaderIconVisible: false,
                            requestLogDetails: response.data,
                            success_modal: {
                                open: true,
                                message: "KC1237"
                            },
                        });
                    }
                }).catch(err => {
                    this.setState({ loaderIconVisible: false });
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1337"
                        },
                    });
                });
            }
            else {
                if (!this.state.libraryIds.length) {
                    this.props.history.push(`/libraryList`);
                } else {
                    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
                    let loggedinUser = loginUser.username;
                    let brandName = localStorage.getItem("brandName");
        
                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": this.state.libraryIds,
                        "country": BASECOUNTRY_ID,
                        "type": this.state.libraryType,
                        "thingList": unitList,
                        'userName': loggedinUser
                    };
        
                    if (this.state.Type) {
                        postData.scheduleDateTimeUtc = this.state.scheduleDateTimeUtc
                    }
                    this.setState({ loaderIconVisible: true });
        
                    let url = MenuUploadAPI.getLibraryUpdateStatus;
        
                    await this.postData(url, postData).then(response => {
                        this.setState({
                            loaderIconVisible: false
                        });
                        this.state.isFlagUpdated && this.props.history.push(`/libraryUpdateStatus`);
                    }).catch(err => {
                        this.showError(err);
                    });
                    // this.setState({ loaderIconVisible: false });
                }
            }
        }
    }

    postData = (url, reqData) => {
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {

            http.post(url, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    let { data } = response;
                    let { httpCode, message } = data;
                    if (httpCode === "HTTP_200" || httpCode === "HTTP_201") {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: (data.stringID || reqData.thingList.length > 100 ? data.message : "Item distributed successfully")
                            },
                        });
                    }
                }).catch(err => {
                    reject(err);
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: err.response.data.stringID || err.response.data.message ,
                        },
                    });
                });
        });
    }

    onItemClick(e, link) {

        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

         const unitsArr = Currentselected.flatMap(e=>e.units || [])
        //  this.checkUnitsCount(unitsArr); // check unit counts
        this.setState({
            link: link, isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false,
            isSelectionHappen: false,
            isFormValidated: dynasty.length > 0 ? true : false
        })
    }
    onUnitItemClick(e, unit) { 
        this.setState({
            isFormValidated: false, isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
    }
    onOrgCheckBoxChange(e, unit) {
        let checkedUnits = this.state.checkedUnits ? this.state.checkedUnits : [];
        // if (e.target.checked) {
        //     checkedUnits.push(unit);
        // }
        // else {
        //     checkedUnits = this.state.checkedUnits.filter(item => item !== unit);
        // }
        if (e.target.checked) {
            unit.checked = true;
        }
        else {
            unit.checked = false;
        }
        if (!checkedUnits.find(item => item === unit)) {
            checkedUnits.push(unit);
        }
        else {
            const findIndex = checkedUnits.findIndex(item => item.id === unit.id);
            checkedUnits[findIndex] = unit;
        }

        let userCheckedData = checkedUnits.filter(unit => unit.checked === true);
        // this.checkUnitsCount(userCheckedData);

        this.setState({
            isFormValidated: checkedUnits.length > 0 ? true : false, checkedUnits: checkedUnits, isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
    }
    showUnits(link) {
        let { initialload, selectedGroupName } = this.state;
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit, index) => {
                const treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled"  : ""
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                const unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned"  : ""
                let checkedUnits = this.state.checkedUnits;
                
                if (selectedGroupName.length > 0) {
                    selectedGroupName.map((selecteditem, index) => {
                        if (link.dynasty.includes(selecteditem.dynasty)) {
                            unit.dynasty = selecteditem.dynasty;
                            unit.cgid = selecteditem.cust_gp_id;
                            if (!initialload && (unit.ACTIVATION_STATUS === "INACTIVE" || !unit.THING)) { 
                                unit.checked = false;
                                if (checkedUnits.find(item => item === unit)) {
                                    checkedUnits = checkedUnits.filter(item => item !== unit);
                                }
                            }
                            else if (!initialload && !showCollpaeCount && unit.ACTIVATION_STATUS !== "INACTIVE" && unit.THING) {
                                if (checkedUnits.find(item => item.UNITID === unit.UNITID && item.checked === false)) {
                                    unit.checked = false;
                                }
                                else {
                                    unit.checked = true;
                                    if (!checkedUnits.find(item => item === unit)) {
                                        checkedUnits.push(unit);
                                    }
                                }
                            }
                        }
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = this.state.checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false;
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';
                unit.checked = ifExist;
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        {!showCollpaeCount && unit.ACTIVATION_STATUS !== "INACTIVE" && unit.THING && <div class="customCheckbox">
                            <input id={unit.UNITID} name={unit.UNITID} type="checkbox" checked={unit.checked} value={unit.UNITID} onChange={(e) => this.onOrgCheckBoxChange(e, unit)} />
                            <label for={unit.UNITID}></label>
                        </div>}
                        <Link to="#" className={treeActive}
                            onClick={(e) => { 
                                if (e.defaultPrevented) return
                                e.preventDefault(); 
                                this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                >{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                >{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                            </div>}
                            {unit.isUnitTagExist && <button className="btn tagedUnits" type="button" onClick={(e) => {
                                if (e.defaultPrevented) return
                                e.preventDefault()
                                this.showSTagInfo(unit,"unit")
                        }}>&nbsp;</button>}
                        </Link>
                    </div>
                </li>
            });
        }
    }
    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    onCollapseButtonClick(e, link) {

        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }

        this.setState({ showLink: showLink })
    }
    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }

    showSTagInfo = (node,type="org") =>{
        this.setState({
            showStPopUp:true,
            isUnitItemClicked:type === "unit" ? true:false,
            unit : type === "unit" ? node:{},
            link : type === "org" ? node:{},
        },()=>{
            $('#unitSmartTagsDetails').modal('show')
        })

    }

    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;


                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { 
                                if (e.defaultPrevented) return
                                e.preventDefault(); 
                                this.onItemClick(e, link) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                {/* <img src={require("../../../images/unit/CremCarrera.svg")}/> */}
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                                    {link.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
                                    if (e.defaultPrevented) return
                                    e.preventDefault()
                                    this.showSTagInfo(link,"org")
                            }}>&nbsp;</button>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { 
                            if (e.defaultPrevented) return
                            e.preventDefault(); 
                            this.onItemClick(e, linkList) }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg")} alt="" />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                        {linkList.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
                                    if (e.defaultPrevented) return
                                    e.preventDefault()
                                    this.showSTagInfo(linkList,"org")
                            }}>&nbsp;</button>}
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }

    onClickListView = () => {
        this.props.history.push({
            pathname: "/libraryAssignUpdateList",
            previouspath: this.state.previouspath,
            scheduleDateTimeUtc: this.state.scheduleDateTimeUtc,
            Type: this.state.Type,
            libraryIds: this.state.libraryIds,
            libraryType: this.state.libraryType,
            retrieveType: this.state.retrieveType
        });
    }

    getInfoView = (value, type) => {
		let smartTagObj = {
			mode: 'isView',
			type,
			selectedRow: value
		}
		localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
		let showpopup = [];
		showpopup.push(value);
		this.setState({ showpopup, showOrgPopup: true, type }, () => {
			$('#transferDetailsModal').modal('hide');
			$('#stUnitDetailsModal').modal('show');
		});
	}

    applyChanges = (postData) => {
		return new Promise((res, rej) => {
            let custGroupID = localStorage.getItem("selected_cgid") ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
            let cgids = "";
            if (custGroupID !== "" && custGroupID !== undefined) {
                cgids = custGroupID.length > 0 ? Array.prototype.map.call(custGroupID, m => m.cust_gp_id) : localStorage.getItem("custGroupID");
            }
			http({
				method: "POST",
				url: smartTagMangement.transferTagDetails,
				headers: {
					'Content-Type': 'application/json',
					'customerid': localStorage.getItem("customerId"),
                    'brandid': localStorage.getItem("selectedbrandID"),
					'cgid': cgids
				},
				data: postData
			}).then(async response => {
				if(response && response.data){
					res(response.data.data || [] );
				}
			}).catch(err => {
				res(err);
			});

		})
	}

	applyFilterChanges = (values) => {
		if (values && values.length > 0) {
			this.setState({ loaderIconVisible: true, initialUnit: this.state.data })
			this.applyChanges(values).then((res)=>{
				const numberType = res && res.length ? res.map(Number) : [];
                let unitSelected = [];
				for (let i = 0; i < this.state.units.length; i++) {
                    const unit = this.state.units[i];
                    if(numberType.includes(unit.id || unit.ID)){
                        unitSelected.push({ID: unit.id,...unit, checked: true, PRODUCT_ID: unit.ProductId})
                    }
				}
                // this.checkUnitsCount(unitSelected)
                this.setState({
                    checkedUnits: unitSelected,
                    isFormValidated: unitSelected.length > 0,
                    isUnitItemClicked: true,
                    link: {},
                    isAddressAvailable: false,
                    isLoactionTypeAvailable: false,
                    level: 6,
                    loaderIconVisible: false,
                    isSelectionHappen: true
                },()=>{
                    $('#transferDetailsModal').modal('hide');
                })
			})			
		}else{
			this.setState({ 
				checkedUnits: [], 
                isSelectionHappen: false,
				isFormValidated: [].length > 0
			})
		}
	}

    formBreadCrum = (dynasty) => {
        const { flattenTreeData } = this.state;
        let crum = [];
        const dynastyArr = dynasty.split(',');
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.cust_gp_id) {
                    if (dynastyArr.includes(nodeEle.cust_gp_id.toString())) {
                        crum.push(nodeEle.group_name);
                    }
                }
            }
        }
        // return crum && crum.length ? crum.join(' &gt; ') : '';
        return crum && crum.length ? crum.join(' > ') : '';
    }

    downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

	onGenericCloseModal = () => {
		this.setState({
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			}
		});
	}

	checkUnitsCount = (units) => {
		const totalUnits = parseInt(unitSelectionAllowOnTransfer);
		if (units && units.length > totalUnits) {
			this.setState({
				generic_modal: {
					open: true,
					message: "Please select maximum of 300 units",
					messagetype: "Maximum number of units",
					count: units.length,
					total: totalUnits
				}
			})
		}
	};

    render() {
        let { success_modal, error_modal,  isFormValidated, selectedGroupName, libraryIds, showpopup, type, isSelectionHappen } = this.state;
        const { loaderIconVisible, linkList, retrieveType,generic_modal } = this.state;
        const { formatMessage } = this.props.intl;
        // isFormValidated = link.category_name;
        let checkedUnits = this.state.checkedUnits.filter(unit => unit.checked === true)
        const unitArr = selectedGroupName.flatMap(e=>e.units || []);
        if ((unitArr.length >= 1) || (checkedUnits.length >= 1)) {
            isFormValidated = true;
        // if (selectedGroupName.length > 0 || this.state.checkedUnits.length) {
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        }
        else {
            isFormValidated = false;
            $("#btnfinish").removeClass("activeState");
            $("#btnfinish").addClass("disabled");
        }
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1573' }) + item.level_id} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="orgznManagementWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>&nbsp;</li>
                                <li>
                                    {retrieveType ? <FormattedMessage id="KC1338"/> : <FormattedMessage id="KC1169"/>}
					            </li>
                                <li >
                                    <Link id="#btnfinish" to="/" className={`btn btn-default-text ${isFormValidated ? "activeState" : "disabled"}`} title={formatMessage({ id: 'KC0056' })} onClick={(e) => this.onFinishClickHandler(e)}><FormattedMessage id="KC0056"/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            <ul>
                                <li>{(selectedGroupName && selectedGroupName.length) || isSelectionHappen ? `${selectedGroupName.length} node selected` : null}</li>
                                <li>
                                    <button type="button" id="transferDetails" onClick={() => $('#transferDetailsModal').modal('show')} class="btn btn-default useTagsforunit" data-toggle="popover" data-content={formatMessage({ id: 'KC2356' })}><FormattedMessage id="KC2360" /></button>
                                    <Link to="#" class="btn assignunitSite active">&nbsp;</Link>
                                    {libraryIds && libraryIds.length && <button id="btnAssign" className="btn assignunitList border0" onClick={this.onClickListView}>&nbsp;</button>}
                                </li>
                            </ul>
                        </div>
                         
                            <div className="collapseExpandHeader">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>

                            <div className="assignOrgTreeOuter">

                                <div className="orgznSiteTree">
                                    {this.state.linkList && this.state.linkList.level1 &&
                                        this.state.linkList.level1.map((item) => this.showLinks(this.state.findTreeLevel))}
                                </div>

                            </div>
                         
                    </div>
                </div>
                {/* <!-- Transfer Modal --> */}
                <div class="modal fade transferDetailsModal" data-keyboard="true" data-backdrop="static" id="transferDetailsModal" tabindex="-1" role="dialog">
                    <SmartTagDrop applyFilterChanges={this.applyFilterChanges} getInfoView={this.getInfoView} />
                </div>
					{/* <!-- Transfer Modal --> */ }
                    <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-header modal-header-danger">
									<FormattedMessage id="KC2312" />
								</div>
								<div className="modal-body">
									{showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
												</li>
											</ul>
										</div>
										<h6><FormattedMessage id="KC0195" /></h6>
										<p>{item.description ? item.description : 'NA'}</p>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn"><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
												</li>
												{type === 'orgList' ? "" :
													<li>
														<Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
													</li>}
											</ul>
										</div>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li><FormattedMessage id="KC0589" /></li>
												<li><FormattedMessage id="KC1278" /></li>
											</ul>
											<ul className="tBody">
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.createdByName ? item.createdByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.createdBy ? item.createdBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.updatedByName ? item.updatedByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.updatedBy ? item.updatedBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>)}
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false }, () => $('#transferDetailsModal').modal('show'))} type="button"><FormattedMessage id="KC0777" /></button>
								</div>
							</div>
						</div>
					</div >
                	{this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
					{this.state.showStPopUp ? <OrgTagsDetail closeSTModel = {()=>this.setState({showStPopUp:false})} nodeDet = {this.state.link} unitDet = {this.state.unit} isUnit = {this.state.isUnitItemClicked} formBreadCrum={this.formBreadCrum}/>: ""}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} values={{ count: generic_modal.count ? generic_modal.count : 0, total: generic_modal.total ? generic_modal.total : 0 }} onGenericCloseModal={(e) => this.onGenericCloseModal()} />
                <UserManagementHeader headerNameStringID="KC2358" headerName="Transfer Management" activeClass="transferNav" />
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LibraryAssignTopology));
