import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import LoadingPopup from '../../controls/loadingPopup';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import http from '../../../service/httpService';
import $ from 'jquery';
import { BaseService, C2CSwaggerAPI } from '../../../service/api';

class c2cSwaggerListView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			currentStartDate: "",
			currentEndDate: "",
			previousStartDate: "",
			previousEndDate: "",
			previousPercentageUsed: 0,
			currentPercentageUsed: 0,
			previousPercentageUsed_width: { width: "0%" },
			currentPercentageUsed_width: { width: "0%" },
			previousTotalQuota: 0,
			currentTotalQuota: 0,
			previoustotalUsages: 0,
			currenttotalUsages: 0,
			showC2CAPIDetails: false,
			apiKeyValue: 'XXXXXXXXXXXXX',
			apiKeyHideAndShow: 'Display Key'
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
	}

	updateDimensions() {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

	componentDidMount = async () => {
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener('resize', this.updateDimensions());
		const cid = localStorage.getItem("customerId");
		await this.getC2CAPIUsageDetails(cid);
	}

	componentDidUpdate = () => {
		this.updateDimensions();
	}

	getC2CAPIUsageDetails = async (cid) => {
		this.setState({ loaderIconVisible: true });
		await http({
			method: 'get',
			url: `${BaseService.root}${C2CSwaggerAPI.C2CAPIUsageDetails}${cid}`,
			data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,

					previousTotalQuota: response.data.data[0].totalQuota,
					previoustotalUsages: response.data.data[0].totalUsages,
					previousPercentageUsed: response.data.data[0].percentageUsed,
					previousPercentageUsed_width: { width: response.data.data[0].percentageUsed !== 0 ? response.data.data[0].percentageUsed.toFixed(1) + "%" : "0%" },
					previousStartDate: response.data.data[0].startDateString,
					previousEndDate: response.data.data[0].endDateString,

					currentTotalQuota: response.data.data[1].totalQuota,
					currenttotalUsages: response.data.data[1].totalUsages,
					currentPercentageUsed: response.data.data[1].percentageUsed,
					currentPercentageUsed_width: { width: response.data.data[1].percentageUsed !== 0 ? response.data.data[1].percentageUsed.toFixed(1) + "%" : "0%" },
					currentStartDate: response.data.data[1].startDateString,
					currentEndDate: response.data.data[1].endDateString,
				});
				if (response.data.message === "Success") {
					this.setState({ showC2CAPIDetails: true })
				}
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	onSelectHandler = (fileVal) => {
		localStorage.setItem('ymlFileName', fileVal);
	}

	/** Display Key key function implementation code to kc api portal feature*/
	showApiKey = async () => {
		if (this.state.apiKeyHideAndShow === "Hide Key") {
			this.setState({ apiKeyValue: 'XXXXXXXXXXXXXX', apiKeyHideAndShow: 'Display Key' });
			return;
		}
		this.setState({ loaderIconVisible: true })
		const cid = localStorage.getItem("customerId");
		let headers = {};

		await http({
			method: 'get',
			url: `${BaseService.root}${C2CSwaggerAPI.getApiKey}?cid=${cid}`,
			headers,
			data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState((prevState)=>({ apiKeyValue: response.data.apiKey, loaderIconVisible: false,apiKeyHideAndShow: prevState.apiKeyHideAndShow === "Display Key" ? "Hide Key" : "Display Key" }))
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	render() {
		let { success_modal, error_modal, loaderIconVisible, currentStartDate, currentEndDate, previousStartDate, previousEndDate, previousPercentageUsed,
			currentPercentageUsed, previousTotalQuota, currentTotalQuota, previoustotalUsages,
			currenttotalUsages, showC2CAPIDetails, currentPercentageUsed_width, previousPercentageUsed_width, apiKeyValue, apiKeyHideAndShow } = this.state;

		let currAPICalls = `API Calls: ${currenttotalUsages}/${currentTotalQuota}`;
		let prevAPICalls = `API Calls: ${previoustotalUsages}/${previousTotalQuota}`;

		let C2CAPIDetails;
		if (showC2CAPIDetails) {
			C2CAPIDetails = (
				<React.Fragment>
					<div class="C2CAPITitle">API Usage</div>
					<div class="viewSubscriptionList">
						<ul class="tHead">
							<li>Cycle</li>
							<li>Duration</li>
							<li>Usage</li>
						</ul>
						<ul class="tBody">
							<li>Current Cycle</li>
							{currentStartDate && currentEndDate ? <li> {currentStartDate} to {currentEndDate} </li> : <li></li>}
							<li>
								<div class="progress" title={currAPICalls}>
									<div class="progress-bar bg-info" role="progressbar" title={currAPICalls} style={currentPercentageUsed_width} aria-valuenow={currentPercentageUsed} aria-valuemin="0" aria-valuemax="100">{currentPercentageUsed_width.width}</div>
								</div>
							</li>
						</ul>
						<ul class="tBody">
							<li>Previous Cycle</li>
							{previousStartDate && previousEndDate ? <li> {previousStartDate} to {previousEndDate} </li> : <li></li>}
							<li>
								<div class="progress" title={prevAPICalls}>
									<div class="progress-bar bg-info" role="progressbar" title={prevAPICalls} style={previousPercentageUsed_width} aria-valuenow={previousPercentageUsed} aria-valuemin="0" aria-valuemax="100">{previousPercentageUsed_width.width}</div>
								</div>
							</li>
						</ul>
					</div>
				</React.Fragment>
			);
		}
		return (
			<div id="adminWrapper">
				<LoadingPopup loading={loaderIconVisible} />
				<div id="page-content-wrapper">
					<div className="adminDivWrapper">
						<div className="softwareUpdateHeader">
							<ul>
								<li>
									<p>The KitchenConnect (KC) API is organized around REST. Our API has resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes and authentication. You can use the KC API in this developer portal, which uses your actual equipment data and allows you to quickly manipulate and test the KC API without the need to do any work on your end. You will need to use the same KC API key that was provided to you in order to test the API.</p>
								</li>
							</ul>
						</div>
						<div class="apiKeyView">
							<div class="fromLable">API Key:</div> <div class="fromValue">{apiKeyValue}</div>  <button class="btn btn-default apiViewBtn" onClick={this.showApiKey} type="button">{apiKeyHideAndShow}</button>
						</div>
						{C2CAPIDetails}
						<div className="swaggerAPITable">
							<ul className="tHead">
								<li>#</li>
								<li>Kitchen Connect API</li>
								<li>&nbsp;</li>
								<li>&nbsp;</li>
							</ul>
						</div>
						<div className="pageScrollbar">
						<div className="swaggerAPITable">
							<ul className="tBody">
								<li>1</li>
								<li>Device Management</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "deviceManagement" }} onClick={() => this.onSelectHandler("device-management-swagger.yaml")} className="btn btn-default" title="Device Management">View</Link></li>
							</ul>
							<ul className="tBody">
								<li>2</li>
								<li>Organization Management</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "organizationManagement" }} onClick={() => this.onSelectHandler("organization-management-swagger.yaml")} className="btn btn-default" title="Node Organization Management">View</Link></li>
							</ul>
							<ul className="tBody">
								<li>3</li>
								<li>File Management</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "filesManagement" }} onClick={() => this.onSelectHandler("files-management-swagger.yaml")} className="btn btn-default" title="File Management">View</Link></li>
							</ul>
							<ul className="tBody">
								<li>4</li>
								<li>Production Management</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "productionManagement" }} onClick={() => this.onSelectHandler("production-management-swagger.yaml")} className="btn btn-default" title="Production Report Management">View</Link></li>
							</ul>
							<ul className="tBody">
								<li>5</li>
								<li>Push Data Service Management</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "pushDataManagement" }} onClick={() => this.onSelectHandler("push-data-configuration-management-swagger.yaml")} className="btn btn-default" title="Push Data Service Management">View</Link></li>
							</ul>
							{/* <ul className="tBody">
								<li>6</li>
								<li>Lights API</li>
								<li>&nbsp;</li>
								<li><Link to={{ pathname: '/swaggerInterface', fileName: "huePhilipsApi" }} onClick={() => this.onSelectHandler("lights-api-swagger.yaml")} className="btn btn-default" title="Lights Api Managment">View</Link></li>
							</ul> */}
						</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="KC API Portal" activeClass="swaggerNav" />
			</div>
		)
	}
};

export default c2cSwaggerListView;

