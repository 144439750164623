import React, { Component } from 'react';
import http from '../../../service/httpService';
import { UserMgmtAddUser } from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
var pattern = new RegExp(/\\/)

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            user: {
                firstName: "",
                lastName: "",
                email: "",
                retypeEmail: "",
                countryCode: "",
                phoneNumber: "",
                customerId: localStorage.getItem("customerId")
            },
            roleId: 0,
            userGroup: [],
            userPreference: [],
            fields: {},
            countryCodeList: [],
            errorFirstName: "",
            errorlastName: "",
            errorEmail: "",
            errorretypeEmail: "",
            errorphoneNumber: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.setUserToState = this.setUserToState.bind(this);
        this.ValidateinputField = this.ValidateinputField.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2";

        let { userModel } = this.props;

        let prevPath = "";
        
        if (this.props.location.state !== undefined)
        {
            prevPath = this.props.location.state.prevPath;
        }
        
        if (prevPath === "userManagementList")
        {
            let initialUserModel ={
                user: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    retypeEmail: "",
                    country_code: "",
                    phoneNumber: "",
                    customerId: localStorage.getItem("customerId")
                }
            };

            this.setState({
                fields: initialUserModel.user,
                roleId: 0,
                countryCodeList: []
            });
            userModel.countryCodeList = "";

        }
        else
        {
            this.setState({
                fields: userModel.user,
                countryCodeList: userModel.countryCodeList
            });

            $("#btnnext").addClass("activeState");
            $("#btnnext").removeClass("disabled");
        }

        if (userModel.countryCodeList.length === 0) {
            await this.getCountryCodeList(userModel.user.country_code);
        }
        
    }

    responseSuccessParser = (response) => {
        return new Promise((resolve, reject) => {
            const { data } = response;
            if (data) {
                resolve(response);
            } else {
                let { data } = response;
                if (data) {
                    let { body } = data;
                    if (body !== null)
                        reject(body);
                }
            }
        });
    }

    selectCountryCode = (e) => {
        if (e.target.value) {
            this.state.user.countryCode = e.target.value;
            this.state.fields["country_code"] = e.target.value;
        }
    }

    getCountryCodeList = async (code) => {
        let result = await http.get(UserMgmtAddUser.getCountryCodeApi,{
            headers: {'Content-Type':'application/json'},
            data:{}
          });
        let { data } = result;
        if (data) {
            
            this.setState({         
                countryCodeList: data.data
            });

        }
    }

    handleChange(e) {
        let fields = this.state.fields;
        if((e.target.name === 'firstName' || e.target.name === 'lastName') && pattern.test(e.target.value.trim())){
            return
        }
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

        if(this.state.fields.firstName && this.state.fields.lastName && this.state.fields.email && this.state.fields.retypeEmail && this.state.fields.phoneNumber){
            $("#btnnext").addClass("activeState");
            $("#btnnext").removeClass("disabled");
        }
        else{
            $("#btnnext").removeClass("activeState");
            $("#btnnext").addClass("disabled");
        }
    }
     handleEmailChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value.toLowerCase();
        this.setState({ 
            fields
        });  
        
        if(this.state.fields.firstName && this.state.fields.lastName && this.state.fields.email && this.state.fields.retypeEmail && this.state.fields.phoneNumber){
            $("#btnnext").addClass("activeState");
            $("#btnnext").removeClass("disabled");
        }
        else{
            $("#btnnext").removeClass("activeState");
            $("#btnnext").addClass("disabled");
        }
    }

    ValidateinputField = (e) => {
        let controlid = e.target.name;
        let errorFirstName = "";
        let errorlastName = "";
        let errorEmail = "";
        let errorretypeEmail = "";
        let errorphoneNumber = "";

        if (e.target.name != null) {

            if(controlid === "firstName"){
                this.setState({ errorFirstName: errorFirstName });
            }
            if(controlid === "lastName"){
                this.setState({ errorlastName: errorlastName });
            }
            if(controlid === "email"){
                this.setState({ errorEmail: errorEmail });
             }
             if(controlid === "retypeEmail"){
                this.setState({ errorretypeEmail: errorretypeEmail });
             }
             if(controlid === "phoneNumber"){
                this.setState({ errorphoneNumber: errorphoneNumber });
             }
        }
	}

    validateForm() {

        let fields = this.state.fields;
        let formIsValid = true;
        let errorFirstName = "";
        let errorlastName = "";
        let errorEmail = "";
        let errorretypeEmail = "";
        let errorphoneNumber = "";

        if (!fields["firstName"].trim()) {
            formIsValid = false;
            errorFirstName = "First Name";
        }
        if (!fields["lastName"].trim()) {
            formIsValid = false;
            errorlastName = "Last Name";
        }

        if (!fields["email"].trim()) {
            formIsValid = false;
            errorEmail = "KC1675";
        }
        else if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errorEmail = "KC0600";
            }
        }

        if (!fields["retypeEmail"].trim()) {
            formIsValid = false;
            errorretypeEmail = "KC0601";
        }
        else if (typeof fields["retypeEmail"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["retypeEmail"])) {
                formIsValid = false;
                errorretypeEmail = "KC0601";
            }
        }
        
        if ((errorEmail === "" && errorretypeEmail === "") && (fields["email"] !== fields["retypeEmail"])){
            formIsValid = false;
            errorretypeEmail = "KC1075";
        }

        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errorphoneNumber = "KC0929";
        }

        else if (typeof fields["phoneNumber"] !== "undefined") {
            if (!fields["phoneNumber"].match(/^[0-9\b]{7,15}$/)) {
                formIsValid = false;
                errorphoneNumber = "KC0602"; 
            }
        }

       

        this.setState({
            errorFirstName:errorFirstName,
            errorlastName:errorlastName,
            errorEmail:errorEmail,
            errorretypeEmail:errorretypeEmail,
            errorphoneNumber:errorphoneNumber

        });
        return formIsValid;
    }

    setUserToState = (e) => {
        if (this.validateForm()) {
            let { userModel } = this.props;
        userModel.user = this.state.fields;
        userModel.previousPage = "/addUser";
        userModel.assignUserBackPage = "/chooseUserRole";
        this.state.countryCodeList.forEach(item => {
            if (item.code === this.state.fields["country_code"]) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        userModel.user.country_code = this.state.fields["country_code"]; 
        userModel.user.country_code = userModel.user.country_code ?  userModel.user.country_code :  this.state.countryCodeList.length >0 && this.state.countryCodeList[0].code
        userModel.countryCodeList = this.state.countryCodeList;
        this.props.setUserDataModel(userModel);
        }
        else {
            e.preventDefault();
        }
    }

    render() {
        let { userData } = this.state.fields;
        let { countryCodeList } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            
                <div id="adminWrapper">
                    <div className="loadingProgress">
                        <div className="loadingProgressIn"></div>
                    </div>
                    <div id="page-content-wrapper">
                    <div className="userManagementWrapper">
                        <div className="addUserHeader">
                            <ul>
                                <li>
                                    <Link to="/userManagementList" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC0596"/>
                                </li>
                                <li>
                                    <Link id="btnnext" className="btn-default-text disabled" to={{
                                        pathname: "/chooseUserRole",
                                        data: this.state.fields
                                    }} title={formatMessage({ id: "KC0543"})} onClick={(e) => this.setUserToState(e)}><FormattedMessage id={'KC0543'} /></Link>
                                </li>
                            </ul>
                        </div>

                        <div className="addUserFormWrapper">
                            <div className="addUserForm">
                                <ul>
                                    <li>
                                        <div className="input-group">
                                            <input type="text" maxLength={50} name="firstName" className="form-control" placeholder={formatMessage({ id: "KC2219"})} onChange={this.handleChange} onBlur = {(e) => this.ValidateinputField(e)} value={this.state.fields.firstName} />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errorFirstName? <FormattedMessage id={this.state.errorFirstName}/>: ''}</div>
                                    </li>
                                    <li>
                                        <div className="input-group">
                                            <input type="text"  maxLength={50} name="lastName" className="form-control" placeholder={formatMessage({ id: "KC2220"})} onChange={this.handleChange} onBlur = {(e) => this.ValidateinputField(e)} value={this.state.fields.lastName} />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errorlastName? <FormattedMessage id={this.state.errorlastName}/>: ''}</div>
                                    </li>
                                    <li>
                                        <div className="input-group">
                                            <input type="email" name="email" className="form-control" placeholder={formatMessage({ id: "KC0136"})} onChange={this.handleEmailChange} onBlur = {(e) => this.ValidateinputField(e)} value={this.state.fields.email} />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errorEmail? <FormattedMessage id={this.state.errorEmail}/>: ''} </div>
                                    </li>
                                    <li>
                                        <div className="input-group">
                                            <input type="email" name="retypeEmail" className="form-control" placeholder={formatMessage({ id: "KC0598"})} onChange={this.handleEmailChange} onBlur = {(e) => this.ValidateinputField(e)} value={this.state.fields.retypeEmail} />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errorretypeEmail? <FormattedMessage id={this.state.errorretypeEmail}/>: ''} </div>
                                    </li>
                                    <li>
                                        <div className="input-group">
                                            <select id="countrycodeselect" className="form-control countryCode" onChange={(e) => this.selectCountryCode(e)}>
                                                {
                                                    countryCodeList.map((item, i) =>
                                                        <option key={i} value={item.code} selected={item.isSelected}>{item.code}</option>
                                                    )

                                                }
                                            </select>
                                            <input type="number" name="phoneNumber" className="form-control phoneNumber" onChange={this.handleChange} onBlur = {(e) => this.ValidateinputField(e)} value={this.state.fields.phoneNumber} placeholder="1234567890" />
                                            <span className="requiredField">*</span>
                                        </div><div className="errorMsg">{this.state.errorphoneNumber? <FormattedMessage id={this.state.errorphoneNumber}/>: ''} </div>
                                    </li>
                                    <li className="infoLi">
                                        <p className="formInfoIcon"><FormattedMessage id={'KC0599'} /></p>
                                    </li>
                                </ul>
                            <div className="requiredNoteDiv"><FormattedMessage id={'KC0654'} /></div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav"/>
                </div>
        )
    }
};

function mapStateToProps(state) {

    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddUser));
