import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ErrorMoal from '../../modal/error-model';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { getMediaImageLibraryList } from '../../../actions/navigation/mediaManagement/mediaImageLibraryListAction'
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BRAND_ID, MEDIA_NO_RESULT_MSG,BASECOUNTRY_ID, } from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { MediaManagementAPI, UserProfile, UnitInfo } from '../../../service/api';
import * as _ from 'lodash';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

class MediaLibraryList extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			mediaSummaryList: [],
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
		};
	}

	async componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		localStorage.setItem("selectedFamilyID", '');
		localStorage.removeItem('modelIdValue');
		localStorage.removeItem('softwareModelId');
		this.props.getMediaImageLibraryList('Reset');
		this.getUserInfo();
		if(!localStorage.getItem("isConnexUser")){
            await this.getConnexUnits();
        }
		this.getMediaLibrary().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: 'KC1930'
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		if(this.props.mediaDraggedItemInfo){
			this.props.mediaDraggedItemInfo.prepartions = [];
			this.props.mediaDraggedItemInfo.other = [];
			this.props.mediaDraggedItemInfo.size = 0;
		}
	}

	getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
			}).catch(err => {
			});
		}
	}

	getMediaLibrary = () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let responseResult = {
			data: []
		}
		return new Promise((resolve, reject) => {
			http.get(MediaManagementAPI.getMediaLibraryAPI, {
				headers: {
					'Content-Type': 'application/json',
					'customerId': CUSTOMER_ID,
					'brandId': BRAND_ID,
					'cgids': cgids,
					'basecountry': BASECOUNTRY_ID,
                    'defaultlibrary' : localStorage.getItem("isConnexUser") || false
				},
				data: {}
			}).then(response => {
				this.setState({
					loaderIconVisible: false,
					mediaLibraryListInformation: response.data
				});
				resolve(responseResult);
			}).catch(err => {
				reject(err);
			});
		});
	}
	
	getConnexUnits = () => {
        this.setState({
            loaderIconVisible: true
        });
        let url = `${UnitInfo.list}`;
        let pageNumber = '?pageNum=1';
        let pageLimit = '&pageSize=50';
        let apiUrl = url + pageNumber + pageLimit;
        let searchParam = {
            'modelName': 'connex'
        };
        apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
        return new Promise((resolve, reject) => {
            http.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'cgid': localStorage.getItem("custGroupID"),
                    'cid': CUSTOMER_ID,
                    'brandid': BRAND_ID,
                    'model': "",
                    'machinetype': "",
                    'sortby': "",
                    'sorttype': ""
                },
                data: {}
            }).then(response => {
                localStorage.setItem("isConnexUser", response &&  response.data && response.data.units ? response.data.units.length > 0 : false)
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    }

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	onButtonClickHandler = (item) => {
		localStorage.setItem("brandName", item.brand);
		localStorage.setItem("unitBrandId", item.brandId);
		localStorage.setItem("selectedbrandID", item.brandId);
		WoopraEvents(`${Constants.MEDIA_BRAND_SELECTED} ${item.brand}`);
		if (item.brand === "CREM") {
			this.props.history.push(`/mediapreparation`);
		}
		else if (item.brand === "MERRYCHEF" || item.brand === "FRYMASTER") {
			this.props.history.push(`/mediaImageLibrary`);
		}
	}

	// Woopra Events
	trackEvent =(e) => {
		WoopraEvents(`${Constants.MEDIA}-${e.trackTitle}`)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { mediaLibraryListInfo } = this.props;
		const { mediaLibraryListInformation, loaderIconVisible, error_modal } = this.state;

		let mediaSummaryList = mediaLibraryListInformation && mediaLibraryListInformation.mediaSummaryList
			&& mediaLibraryListInformation.mediaSummaryList.length > 0 ? mediaLibraryListInformation.mediaSummaryList : [];

		mediaSummaryList = _.orderBy(mediaSummaryList, "brand", "asc");
		return (

			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress -->
        <!-- Fixed Header navbar --> */}

				{/* <!-- //Fixed Header navbar --> */}

				<div id="page-content-wrapper">

					<div className="mediaWrapper">

						<div className="tableHNav">
							<ul>
								<li>
									<Link to="/mediaLibraryList" title={formatMessage({ id: 'KC0714' })} className="active" onClick={(e)=> {e.trackTitle="Media Libraries";this.trackEvent(e)}}><FormattedMessage id="KC0714"/></Link>
									<Link to="/mediaUploadHistory" title={formatMessage({ id: 'KC1757' })} onClick={(e)=> {e.trackTitle="Media Upload History";this.trackEvent(e)}}><FormattedMessage id="KC0713"/></Link>
									<Link to="/mediaStatus" title={formatMessage({ id: 'KC0523' })} onClick={(e)=> {e.trackTitle="Status";this.trackEvent(e)}}><FormattedMessage id="KC0523"/></Link>
								</li>
							</ul>
						</div>
						<form className="mediaAllForm">
							<div className="mediaAllTable">
							<ul className="tHead">
									<li>#</li>
									<li><FormattedMessage id="KC0873"/></li>
									<li><FormattedMessage id="KC2332"/></li>
									<li><FormattedMessage id="KC0715"/></li>
									<li><FormattedMessage id="KC0716"/></li>
									<li><FormattedMessage id="KC0717"/></li>
									<li><FormattedMessage id="KC0718"/></li>
									<li></li>
								</ul>

								{mediaSummaryList.map((item, i) =>
									<ul className="tBody" key={i}  onClick={() => this.onButtonClickHandler(item)}>
										<li>{i + 1}</li>
										<li>{item.brand}</li>
										<li>{<img alt="" src={item.brandId === 1? require("../../../images/logos/Merrychef_Color.svg").default : item.brandId === 3 ? require("../../../images/logos/Frymaster_Color.svg").default : require("../../../images/logos/Crem_Color.svg").default} />}
										    <div className="mediaLibrarySMFC">
												<div><FormattedMessage id="KC0715"/> : {item.mediaFilesCount}</div>  
												<div><FormattedMessage id="KC0716"/> : {item.compilationFilesCount}</div> 
											</div>
										</li>
										<li>{item.brandId === 1 || item.brandId === 3 ? <FormattedMessage id="KC1919"/> : item.mediaFilesCount}</li>
										<li>{item.brandId === 1 || item.brandId === 3 ? <FormattedMessage id="KC1919"/> : item.compilationFilesCount}</li>
										<li>{item.brandId === 1 || item.brandId === 3 ? item.imageLibraryCount : <FormattedMessage id="KC1919"/>}</li>
										<li>{item.brandId === 1 ? item.audioLibraryCount : <FormattedMessage id="KC1919"/>}</li>
										<li><button className="btn forwardNext">&nbsp;</button></li>
									</ul>

								)}

							</div>
						</form>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				</div>
				<UserManagementHeader headerName="Media Management" headerNameStringID="KC0632" activeClass="mediaNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {

	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
		mediaImageLibraryListInfo: state.mediaImageLibraryListInformation.mediaImageLibraryListInformation,
		mediaDraggedItemInfo: state.mediaDraggedItem.mediaDraggedItem,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
		getMediaImageLibraryList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaLibraryList));