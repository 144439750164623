import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService, UserManagement } from '../../service/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import AddToHomeScreenModal from '../controls/addToHomeScreen';
import SupportButton from '../../components/controls/supportButton'

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

class NewLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			pwd: '',
			retypepwd: '',
			value: '',
			userData: {},
			maintenanceText: ''
		};
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handlePasswordRetypeChange = this.handlePasswordRetypeChange.bind(this);
		this.handleSignInSubmitAction = this.handleSignInSubmitAction.bind(this);
		this.onSigninclickHandler = this.onSigninclickHandler.bind(this);
	}

	componentDidMount() {
		const search = this.props.location.search;
        const params = new URLSearchParams(search);
		const readyrecipeids = params.get('ready_recipe');
		const name = params.get('name');
		const brand = params.get('brand_id');
		const model = params.get('equipment_model');
		this.getMaintenanceText();
		if (readyrecipeids && (brand === "Convotherm" || brand === "Merrychef")) {
			let objbrandsitedata = {};
			objbrandsitedata.readyrecipeids = readyrecipeids;
			objbrandsitedata.name = name;
			objbrandsitedata.brand = brand;
			objbrandsitedata.brandid = brand === "Merrychef" ? 1 : 6;
			objbrandsitedata.model = model;
			localStorage.setItem("kcbrandsitedata", JSON.stringify(objbrandsitedata));
        	localStorage.setItem("selectedbrandID", objbrandsitedata.brandid);
        	localStorage.setItem("brandName", brand ? brand.toUpperCase() : '');
			localStorage.setItem("selected_cgid", '[]');
			localStorage.setItem("selectedFamilyID", '26,27,36'); // allowing only C4, maxx & maxx pro model families
			if (auth.getJwt()) {
				if(brand && brand.toUpperCase() === 'MERRYCHEF'){
					return this.props.history.push(`/assignLegacyRecipeToTopology`);
				} else if (brand && brand.toUpperCase() === 'CONVOTHERM'){
					return this.props.history.push(`/assignLegacyCookbookToTopology`);
				}
			}
			else {
				return this.props.history.push('/Login');
			}
		}
		else {
			if (auth.getJwt())
				return this.props.history.push('/dashBoardLocations');
			$("body").removeClass("modal-open");
			$('body').removeClass('mainMenuBg');
			document.body.style.backgroundColor = "#0d3968";
			$("body").addClass("loginBg");
			//zendesk help script
			// const script = document.createElement("script");
			// script.id = "ze-snippet";
			// script.src = "https://static.zdassets.com/ekr/snippet.js?key=a65cb604-0462-4b49-92dc-ead6f4f92ded";
			// script.async = true;
			// document.body.appendChild(script);
			//zendesk help iframe enabled
			// $('iframe').removeClass();
			this.updateLoginDimensions();
		}
	}

	componentWillUnmount() {
		// let zendeskscript = document.getElementById('ze-snippet');
		// if (zendeskscript) {
		// 	zendeskscript.remove();
		// }
		// //zendesk help iframe disabled
		// $('iframe').addClass('displaynone');
	}

	getMaintenanceText() {
		this.setState({
			access_modal:{
				open:false				
			  }
		})

		http({
			method: "POST",
			url: UserManagement.getMaintenanceText,
			data: { "message": "MAINTENANCE_TEXT" },											
		}).then(response =>{
			let { status, data } = response;
			if (status === 200)
			{
				this.setState({
					loaderIconVisible: false,
					maintenanceText: data.data === "NA" ? "" : data.data
				}); 
			}
		})
	}

	updateLoginDimensions() {
		var windowHeight = $(window).height();
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25;
		//Sign In & Sign Up Modal
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH));
		var loginScrollbarHeightS = ((windowHeight) - (welcomTextHbgAdminH));
		$(".LoginBtnWrapper").css("max-height", loginScrollbarHeightS);
	}
	componentWillMount = () => {
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
	}
	handleEmailChange(event) {
		this.setState({ email: event.target.value });
		event.preventDefault();
	}

	handlePasswordChange(event) {
		this.setState({ pwd: event.target.value });
	}

	handlePasswordRetypeChange(event) {
		this.setState({ retypepwd: event.target.value });
		event.preventDefault();
	}
	handleSignInSubmitAction(event) {

		event.preventDefault();
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	onSigninclickHandler(e) {
		e.preventDefault();
		let putData = {
			username: this.state.email,
			oldPassword: this.state.pwd,
			newPassword: this.state.retypepwd
		}
		http({
			method: HTTP_METHOD.PUT,
			url: BaseService.first_login,
			data: putData,
			headers: { 'Content-Type': 'application/json' }
		}).then(function (response) {
			if (response.data.statusCode === 200) {
				this.props.history.push(`/dashboardLocations`);
			}
		}).catch(err => {
			console.log("error response ");
		});
	}

	render() {
		const { loaderIconVisible, maintenanceText } = this.state;	
		return (
			<div id="adminWrapper">
				<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
            		<div className="loadingProgressIn"></div>
        		</div>
				<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
					<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="/">&nbsp;</Link>
					<Link className="maintenanceText" title="" to="/">{maintenanceText}</Link>
				</nav>
				<div id="page-wrapper">
					<div className="welcomText">Welcome</div>
					<div className="LoginBtnWrapper">
						<div id="loginBtnFormID">
							<div className="loginButton">
								<ul>
									<li>&nbsp;</li>
									<li>
										<div className="loginIWrapper">
											< Link to="/Login" title="Sign In" className="btn btn-secondary singINBtn"> Sign In</Link>
										</div>
									</li>
									<li>&nbsp;</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" data-keyboard="true" data-backdrop="static" id="SignInModal" tabIndex="-1" role="dialog" aria-labelledby="SignInModalLabel">
					<div className="modal-dialog modal-md loginModal">
						<div className="modal-content">
							<div className="modal-header loginModalHeader modal-header-danger">
								Sign In <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div className="modal-body paddingTop0px">
								<div className="loginForm">
									<ul>
										<li className="form-group marginBottom10px">
											<input type="text" name="Email" className="form-control" placeholder="Enter your Email" value={this.state.email} onChange={this.handleEmailChange} />
										</li>
										<li className="form-group pass">
											<div className="input-group">
												<input className="form-control " name="PasswordHash" placeholder="Create a password" type="password" value={this.state.pwd} onChange={this.handlePasswordChange} />
												<span className="input-group-btn">
													<button className="btn input-group-addon reveal" type="button"><i className="glyphicon glyphicon-eye-open"></i></button>
												</span>
											</div>
										</li>
										<li className="form-group pass">
											<div className="input-group">
												<input className="form-control " id="password2" name="ConfirmPasswordHash" placeholder="Retype password" type="password" value={this.state.retypepwd} onChange={this.handlePasswordRetypeChange} />
												<span className="input-group-btn">
													<button className="btn input-group-addon reveal" type="button"><i className="glyphicon glyphicon-eye-open"></i></button>
												</span>
											</div>
										</li>
										<li className="form-group pass">
											<div className="customCheckbox customCheckboxWlabel">
												<input id="RememberMe" name="RememberMe" type="checkbox" value="RememberMe" />
												<label htmlFor="RememberMe">Remember Me</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="modal-footer textAlignCenter firstLoginBtn">
								<button className="btn btn-secondary singINBtn" type="button" id="SignInButton" onClick={this.handleSignInSubmitAction}>Sign In</button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Add to Home Screen Modal --> */}
				<AddToHomeScreenModal />
				<div className="modal fade SignInSuccessModal" data-keyboard="true" data-backdrop="false" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
					<div className="modal-dialog modal-md loginModal">
						<div className="modal-content">
							<div className="modal-body">
								<div className="singINConfirmation">
									You successfully created your personal password!
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" onClick={() => this.nextPath('/dashBoardLocations')} type="button">OK</button>
							</div>
						</div>
					</div>
				</div>
				<SupportButton subject="KitchenConnect Support Request - Log in" body="Dear User,%0D%0A%0D%0AIf your organization already has a KitchenConnect Account, please contact your account administrator to get access.%0D%0A%0D%0AIf you are facing issues during login, please send us your comments and attach any screenshots that could help our team to better understand your issue and we´ll come back to you.%0D%0A%0D%0A Sincerely,%0D%0AKitchenConnect Team."/>
			</div>
		)
	}

};

export default NewLogin;
