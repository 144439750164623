import React, { Component } from 'react';
import $ from 'jquery';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';

class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.chargifyForm = React.createRef();
    this.state = {
      token: '',
    };
  }

  handleSubmit = () => {
    return new Promise(async (res, rej) => {
      await this.chargify.token(
        this.chargifyForm.current,

        (token) => {
          $('.cfy-container--inline.cfy-container--invalid').css('border-color', '#ddd')
          res(this.setState({ token: token }));
        },

        (error) => {
          rej(error);
        }
      );
    })
  }

  getChargifyApiKey = () => {
    return new Promise((res, rej) => {
      let url = DeviceRegistration.getChargifyApiKey;

      http.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {}
      }).then(async response => {
        if (response.data && response.data.length) {
          await this.loadKCUI(response.data[0]); 
          res(true);
        }
      }).catch(err => {
        res(false);
      });
    })
  }

  loadKCUI(response) {
    const { isEuro = false } = this.props;

    this.chargify.load({
      selector: '#chargify-form',
      publicKey: response.public_key,
      type: 'card',
      "gatewayHandle": "stripe_uk",      
      "current_vault":"stripe_connect",
      currency: isEuro ? "GBP" : response.default_currency,
      serverHost: response.server_host,
      fields: {
        number: {
          selector: '#chargify1',
          label: 'Card Number *',
          placeholder: 'xxxx xxxx xxxx xxxx',
          message: 'This field is not valid. Please update it.',
          style: {
            field: {
              width: `100%`
            },
            input: {
              width: '100%',
              padding: '5px 30px 10px 52px',
              lineHeight: 'inherit',
              background: 'transparent',
              color: '#0D3968',
              fontSize: '18px',
              border: '0px',
              borderBottom: '1px solid #0D3968',
              borderRadius: '0px',
            }, 
            label: {
              float: 'left',
              clear: 'both',
              color: '#0D3968',
              fontSize: '18px',
              fontFamily: 'sans-serif',
              fontWeight: 'unset'
            },
            message: { paddingTop: '2px', paddingBottom: '1px' }
          }
        },
        month: {
          selector: '#chargify2',
          label: 'Expiration Month *',
          placeholder: 'MM',
          message: 'This field is not valid. Please update it.',
          style: {
            field: {
              width: `100%`
            },
            input: {
              width: '100%',
              padding: '5px 30px 10px 0px',
              lineHeight: 'inherit',
              background: 'transparent',
              color: '#0D3968',
              fontSize: '18px',
              border: '0px',
              borderBottom: '1px solid #0D3968',
              borderRadius: '0px',
            }, 
            label: {
              float: 'left',
              clear: 'both',
              color: '#0D3968',
              fontSize: '18px',
              fontFamily: 'sans-serif',
              fontWeight: 'unset'
            },
            message: { paddingTop: '2px', paddingBottom: '1px' }
          }
        },
        year: {
          selector: '#chargify3',
          label: 'Expiration Year *',
          placeholder: 'YYYY',
          message: 'This field is not valid. Please update it.',
          style: {
            field: {
              width: `100%`
            },
            input: {
              width: '100%',
              padding: '5px 30px 10px 0px',
              lineHeight: 'inherit',
              background: 'transparent',
              color: '#0D3968',
              fontSize: '18px',
              border: '0px',
              borderBottom: '1px solid #0D3968',
              borderRadius: '0px',
            }, 
            label: {
              float: 'left',
              clear: 'both',
              color: '#0D3968',
              fontSize: '18px',
              fontFamily: 'sans-serif',
              fontWeight: 'unset'
            },
            message: { paddingTop: '2px', paddingBottom: '1px' }
          }
        },
      }
    });
  }

  async componentDidMount() {
    this.chargify = new window.Chargify();
    await this.getChargifyApiKey();
  }

  componentWillUnmount() {
    this.chargify.unload();
  }

  render() {
    return (
      <form ref={this.chargifyForm}>
        <div id="chargify-form"></div>
        <div id="chargify1"></div>
        <div className="billingForm">
          <ul>
            <li className="colm50">
              <div id="chargify2"></div>
            </li>
            <li className="colm50">
              <div id="chargify3"></div>
            </li>
          </ul>
        </div>
      </form>
    )
  }
}

export default CreditCard;