import * as api_china from './api_china.js';
import * as api_london from './api_london.js';

let cn = false;
const hostname = window && window.location && window.location.hostname;
if (hostname === 'www.welbiltconnect.cn' || hostname === 'welbiltconnect.cn') {
    cn = true;
}

let config_path = cn ? api_china : api_london;

export default {
    //organization structure
    organizaionCategoryDetailsUrl: "groupmanagement/group-management/v1/customer-group-category",
    organizaionLocationTypesUrl: "groupmanagement/group-management/v1/customer-group-types",
    organizaionSubElementCountUrl: "groupmanagement/group-management/v1/customer-groups/sub-customer-groups/",
    organizaionUnitsDetailsUrl: "unitmanagement/unit-management/unit-customergroup/v1/customergroups/units/",
    delete_Organization_Element: "groupmanagement/group-management/v1/customer-groups/",
}

export const { BaseService,
    ReportManagement,
    ServiceManagement,
    LocationInfo,
    UnitInfo,
    MediaMenuManagement,
    UserManagement,
    userRoleURI,
    UserMgmtAddUser,
    unitAPI,
    UserProfile,
    unitRegister,
    OrganizationViewAPI,
    managePasswordAPI,
    SoftwareManagementAPI,
    MediaManagementAPI,
    MenuUploadAPI,
    HACCPManagementAPI,
    WarrantyAPI,
    C2CSwaggerAPI,
    organizationNode,
    smartGroupAPI,
    smartGroupStaus,
    NotificationCenter,
    DeviceRegistration,
    HelpDocumentation,
    LanguagePreference,
    chinaGovRegistrationLink,
    connectivityStatus,smartTagMangement } = config_path;
