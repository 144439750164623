import { Link } from "react-router-dom";
import React, { PureComponent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { getErrorList_ForHistoricError } from '../../service/servicemanagement/serviceManagement';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import $ from 'jquery';
import { format } from 'date-fns';
import { CUSTOMER_ID } from '../../utils/appConstants';
import http from '../../service/httpService';
import { ServiceManagement } from '../../service/api';
import authService from '../../service/authService';
import { TableHeader } from '../controls/tableHeader';
import NoRecordsFound from '../controls/noRecordsFound';
import moment from '../../utils/momentHelper.js';
import { isEqualObj } from '../globalSearchBar/selectOption';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from "../controls/goToPagination";

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');

class ErrorListForHistoric extends PureComponent {

  constructor(probs) {
    super(probs);
    this.state = {
      data: [],
      totalPages: null,
      loading: true,
      currentPage: 1,
      pageSize: 25,
      sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
      errorType: "",
      IsServerError: false,
      error_modal: {
        open: false,
        message: ""
      },
      success_modal: {
        open: false,
        message: ""
      },
      noRecords: null,
      dataToTrobleShoot: [],
      isSelectAllRoles: false,
      allowToDelete: false,
      isCriticalSel: true,
      isNonCriticalSel: true,
      isErrorEventSel: false,
      type: 'Month',
      currentErrCount: "",
      cbGroup: { nCriticalError: true, criticalError: true, errorEvent: false },
      findErrType: "All",
      findErrorEvent: false,
      dateFilter: 'Month',
      searchData: null,
      loaderIconVisible: false
    }

    this.onSortedChange = this.onSortedChange.bind(this);
    this.changeErrorList = this.changeErrorList.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000000";
    this.updateDimensions();
    const { currentPage, sortColumn } = this.state;
    this.loadData(currentPage, sortColumn);
    this.countValue();
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  async componentDidUpdate(prevState) {
    let { searchData } = prevState;
    let curSearchData = this.state && this.state.searchData;
    if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
      const { currentPage, sortColumn } = this.state;
      this.loadData(currentPage, sortColumn);
    } 
  }

  static getDerivedStateFromProps = (props) => ({ ...props });

  updateDimensions() {
    var windowHeight = $(window).height();
    var pageTabHeader = $(".pageTabHeader").height();
    var mediaHeader = $(".mediaHeader").height();
    var pageWrapperHeight = ((windowHeight) - (pageTabHeader)) - 114;
    var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader)) - 155;
    var gidviewListHeight = ((windowHeight) - 440);
    $(".setMinHeight01").css("min-height", pageWrapperHeight);
    $(".mediaTableListForm").css("min-height", mediaPageWrapperHeight);
    $(".allLocationsGrid, .allUnitsGrid").css("min-height", gidviewListHeight);
  }


  /**
   * Fetch server data by On-Demand
   * Send current page number
   * Send Sorted details
   */
  loadData = async (currentPage, sortColumn) => {
    try {
      const response = await this.requestData(currentPage, sortColumn);
      this.setState({
        data: response.rows,
        totalPages: response.pages,
        currentPage: currentPage,
        loading: false,
      });

      var rolesList = response.rows;
      rolesList.forEach(r => {
        r.checked = false;
      });


      this.setState({
        data: rolesList
      });

      this.countValue();

    } catch (error) {
      this.setState({ loading: false });
    }
  }

  countValue = async () => {
    let reqData = {
      "data": {
        "filters": "model",
        "brand": localStorage.getItem("brandId"),
        'device_id': localStorage.getItem("unitID"),
        "timeFilterType": this.state.dateFilter,

      }
    };
    if (this.state.dateFilter === "Date Range") {
      reqData.data.timeFilterType = 'custom';
      reqData.data.startDate = weekDateFormatted;
      reqData.data.endDate = todaysDateFormatted;
    }

    let url = ServiceManagement.allCurrErrorCounts;
    http.post(url, reqData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        this.setState({ currentErrCount: response.data })
      }).catch(err => {
      });
  }


  /**
 * When navigate new page this event will return current page number
 * @param {*} currentPage 
 */
  onPageChange = (currentPage) => {
    this.loadData(currentPage);
  }

  /**
   * When sort the record by On-Demand
   * This event will return the sorted column details
   */
  onSortedChange = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
      currentPage: 1
    });
    this.loadData(1, sortColumn);
  }

  /**
   * Business login to fetch units by below constraints
   * 1. Fetch by Brand specific units
   * 2. Fetch by Sorted column details
   * 3. Fetch by page wise
   */
  requestData = async (currentPage, sortColumn) => {
    try {
      const sortData = sortColumn && sortColumn.path !== "" ? sortColumn : this.state.sortColumn;
      const pageSize = this.state.pageSize;
      const sortType = sortData.order;
      const sortBy = sortData.path;
      const page = currentPage - 1;

      let pageNumber = (page + 1);
      let pageLimit = pageSize;

      let order = page * pageSize;
      let currentUnit = this.props.currentUnit;
      currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");


      let queryString;
      //queryString =`cid=${localStorage.getItem("customerId")}`
      //queryString = queryString + `&cgid=${localStorage.getItem("custGroupID")}` 
      queryString = `&brandId=${localStorage.getItem("brandName")}`
      if (this.state.findErrType !== "No Need") {
        queryString = queryString + `&errorType=${this.state.findErrType}`;
      }
      if (this.state.findErrorEvent) {
        queryString = queryString + `&errorEvent=${this.state.findErrorEvent}`;
      }
      if (currentPage) {
        queryString = queryString + `&pageNum=${currentPage}`;
      }
      if (pageSize) {
        queryString = queryString + `&pageSize=${pageSize}`
      }
      if (this.state.dateFilter === "Date Range") {
        queryString = queryString + `&startDate=${weekDateFormatted ? weekDateFormatted : ""}&`
        queryString = queryString + `&endDate=${todaysDateFormatted ? todaysDateFormatted : ""}`
        queryString = queryString + `&timeFilterType=custom`
      } else {
        queryString = queryString + `&timeFilterType=${this.state.dateFilter}`
      }

      let { searchData } = this.state;
      let apiUrl = `${ServiceManagement.historicalError}`;

      apiUrl = apiUrl.replace("X0", localStorage.getItem("unitID"));
      apiUrl = apiUrl + "?" + queryString
      // search data 
      this.setState({ loaderIconVisible: true }, () => {
      })
      const response = await getErrorList_ForHistoricError(apiUrl, this.state.errorType, "", pageNumber, pageLimit, sortBy, sortType, this.state.filterType, searchData);
      let { data, totalPages } = response.data;
      data.map((item, i) => {
        item.order_id = ++order;
      });
      if (this.state.findErrType === "No Need" && !this.state.findErrorEvent) {
        data = [];
      }

      this.setState({
        noRecords: data.length > 0 ? false : true,
        loaderIconVisible: false
      });


      return {
        rows: data,
        pages: totalPages
      };

    } catch (error) {
      return {
        rows: [],
        pages: 0
      };
    }

  };

  changeErrorList = (event) => {
    let errorType = event.target.value === "All Errors" ? "" : event.target.value === "Warning" ? 'non-critical' : 'critical';

    this.state.errorType = errorType;
    this.setState({
      errorType: errorType,
      loading: true
    });
    let pageNumber = 1;
    let pageLimit = 25;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");


    let queryString;
    queryString = `brandId=${localStorage.getItem("brandName")}&`
    queryString = queryString + `errorType=${errorType}&`
    queryString = queryString + `pageNum=${pageNumber ? pageNumber : 1}&`
    queryString = queryString + `pageSize=${pageLimit ? pageLimit : 25}&`
    queryString = queryString + `timeFilterType=${this.state.filterType ? this.state.filterType : "Month"}`


    let apiUrl = `${ServiceManagement.historicalError}`;
    apiUrl = apiUrl.replace("X0", localStorage.getItem("unitID"));
    apiUrl = apiUrl + "?" + queryString

    getErrorList_ForHistoricError(apiUrl, errorType, pageNumber, pageLimit, 'asc', '', this.state.filterType)
      .then(response => {
        response.data.map((item, i) => {
          item.order_id = i + 1;
        });
        this.setState({
          data: response.data,
          totalPages: response.totalPages,
          currentPage: 1,
          loading: false
        });
      })
      .catch(err => {
        this.showError(err);
      })
  }

  //For Event Error Code
  handleCheckBoxClick = (e, key) => {
    let { sortColumn, isCriticalSel, isNonCriticalSel, isErrorEventSel, cbGroup } = this.state;
    let isChecked = e && e.target && e.target.checked;
    let setSelectedCheckbox = {};
    setSelectedCheckbox[key] = isChecked;

    switch (key) {
      case 'isCriticalSel':
        isCriticalSel = isChecked;
        break;
      case 'isNonCriticalSel':
        isNonCriticalSel = isChecked;
        break;
      case 'isErrorEventSel':
        isErrorEventSel = isChecked;
        break;
    }
    let errType = false, errorEvent = false;
    cbGroup[e.target.value] = isChecked;
    errorEvent = cbGroup["errorEvent"];

    if (cbGroup["criticalError"] && cbGroup["nCriticalError"]) {
      errType = "All";
    } else if (cbGroup["criticalError"]) {
      errType = "critical";
    } else if (cbGroup["nCriticalError"]) {
      errType = "non-critical";
    }

    if (cbGroup["criticalError"] === false && cbGroup["nCriticalError"] === false) {
      errType = "No Need";
    }

    this.setState({
      findErrType: errType,
      findErrorEvent: isErrorEventSel,
    }, () => {
      this.loadData(1, sortColumn);
    });
    
    this.setState({ ...setSelectedCheckbox });
  }

  validateErrorTypes = (isCritical, isNonCritical, isErrorEvent) => {
    let req = {};
    if (isCritical === false && isNonCritical === false && isErrorEvent === true) {
      req = { errorEvent: true }
    }
    else if (isCritical === false && isNonCritical === true && isErrorEvent === false) {
      req = { criticalType: 'noncritical' }
    }
    else if (isCritical === true && isNonCritical === true && isErrorEvent === false) {
      req = { criticalType: 'critical' }
    }
    else if (isCritical === true && isNonCritical === false && isErrorEvent === false) {
      req = { criticalType: 'critical' }
    }
    else if (isCritical === false && isNonCritical === true && isErrorEvent === true) {
      req = { criticalType: 'noncritical', errorEvent: true }
    }
    else if (isCritical === true && isNonCritical === false && isErrorEvent === true) {
      req = { criticalType: 'critical', errorEvent: true }
    }
    return req;
  }

  checkBoxChange = (event, key) => {
    let { currentPage, sortColumn, isCriticalSel, isNonCriticalSel, isErrorEventSel, cbGroup } = this.state;
    let isChecked = event && event.target && event.target.checked;
    this.state.errorType = errorType;
    this.setState({
      errorType: errorType,
      loading: true
    });
    let pageNumber = 1;
    let pageLimit = 25;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");

    let errorType = "All", eventType = false;
    cbGroup[event.target.value] = isChecked;
    eventType = cbGroup["eventsError"];

    if (cbGroup["Warning"] && cbGroup["Error"]) {
      errorType = "All";
    } else if (!cbGroup["Warning"] && !cbGroup["Error"]) {
      errorType = "";
    } else if (cbGroup["Warning"]) {
      errorType = 'non-critical';
    } else if (cbGroup["Error"]) {
      errorType = 'critical';
    }

    if (!cbGroup["Warning"] && !cbGroup["Error"] && !cbGroup['eventsError']) {
      errorType = "none";
    }

    if (errorType === "" && eventType) {
      eventType = true;
    }
    
    let setSelectedCheckbox = {};
    setSelectedCheckbox[key] = isChecked;

    switch (key) {
      case 'isCriticalSel':
        isCriticalSel = isChecked;
        break;
      case 'isNonCriticalSel':
        isNonCriticalSel = isChecked;
        break;
      case 'isErrorEventSel':
        isErrorEventSel = isChecked;
        break;
    }
    this.setState({ ...setSelectedCheckbox });


    let queryString;
    queryString = `brandId=${localStorage.getItem("brandName")}&`
    if (errorType !== "") {
      queryString = queryString + `errorType=${errorType}&`
    }
    if (eventType) {
      queryString = queryString + `errorEvent=${eventType}&`
    }
    queryString = queryString + `pageNum=${pageNumber ? pageNumber : 1}&`
    queryString = queryString + `pageSize=${pageLimit ? pageLimit : 25}&`
    queryString = queryString + `timeFilterType=${this.state.filterType ? this.state.filterType : "Month"}`


    let apiUrl = `${ServiceManagement.historicalError}`;
    apiUrl = apiUrl.replace("X0", localStorage.getItem("unitID"));
    apiUrl = apiUrl + "?" + queryString;






    getErrorList_ForHistoricError(apiUrl, errorType, pageNumber, pageLimit, 'asc', '', this.state.filterType).then(response => {
      response.data.map((item, i) => {
        item.order_id = i + 1;
      });
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
    })
      .catch(err => {
        this.showError(err);
      })


  }

  checkRecordAfterFilterChange = async (filterTypeVal) => {
    let errorType = this.state.errorType ? this.state.errorType : "";
    this.setState({
      loading: true
    });
    let pageNumber = 1;
    let pageLimit = 25;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");


    let queryString;
    queryString = `brandId=${localStorage.getItem("brandName")}&`
    queryString = queryString + `errorType=${this.state.errorType ? this.state.errorType : ""}&`
    queryString = queryString + `pageNum=${pageNumber ? pageNumber : 1}&`
    queryString = queryString + `pageSize=${pageLimit ? pageLimit : 25}&`

    if (filterTypeVal === "Date Range") {
      queryString = queryString + `startDate=${weekDateFormatted ? weekDateFormatted : ""}&`
      queryString = queryString + `endDate=${todaysDateFormatted ? todaysDateFormatted : ""}`
      queryString = queryString + `&timeFilterType=custom`
    } else {
      queryString = queryString + `timeFilterType=${filterTypeVal ? filterTypeVal : "Month"}`
    }





    let apiUrl = `${ServiceManagement.historicalError}`;
    apiUrl = apiUrl.replace("X0", localStorage.getItem("unitID"));
    apiUrl = apiUrl + "?" + queryString

    getErrorList_ForHistoricError(apiUrl, errorType, pageNumber, pageLimit, 'asc', '', filterTypeVal).then(response => {
      response.data.map((item, i) => {
        item.order_id = i + 1;
      });
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
    })
      .catch(err => {
        this.showError(err);
      })
  }

  showError = (err) => {
    let { response } = err;
    if (response) {
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
      this.setState({ loading: false });
    } else {
      this.setState({
        IsServerError: true
      });
    }
  }

  errorCloseModel = () => {
    localStorage.removeItem("Access-Token");
    this.setState({
      IsServerError: false
    });
    this.props.OnCloseSessionModal();
  }

  closeModel() {
    this.setState({
      error_modal: {
        open: false
      }
    });
  }

  onSuccessCloseModal() {
    this.setState({
      success_modal: {
        open: false
      }
    });
  }

  onSendEmailHandler() {
    this.setState({
      loaderIconVisible: true
    });

    return new Promise((resolve, reject) => {

      let postData = {
        'customer_id': parseInt(CUSTOMER_ID),
        'brand_id': localStorage.getItem("brandName"),
        'user_email': localStorage.getItem("user"),
        'unit_id': localStorage.getItem("unitID")
      }

      http({
        method: 'POST',
        url: ServiceManagement.emailReport,
        data: postData
      })
        .then(res => {
          let { body } = res.data;
          let { responseCode, message, stringID } = body;
          if (responseCode !== 200) {
            this.setState({
              loaderIconVisible: false,
              error_modal: {
                open: true,
                message: message
              }
            });
            reject({
              response: {
                data: res.data
              }
            });
          } else {
            this.setState({
              loaderIconVisible: false,
              success_modal: {
                open: true,
                message: stringID ? stringID : message
              }
            });
            resolve(res);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  errorDetailsForTroubleShoot = (e, selectRow) => {
    let resData = this.state.data;
    this.setState({
      dataToTrobleShoot: resData[selectRow - 1],
    }, () => {
      this.setState({
        dataToTrobleShoot: resData[selectRow - 1],
      })
    })
  }

  handleChange = (e) => {
    var rolesList = [...this.state.data];
    rolesList.forEach(r => {
      r.checked = e.target.checked;
    });
    this.setState({
      data: rolesList,
      isSelectAllRoles: e.target.checked,
    })
  };

  onSelectRole = (e, item) => {
    if (this.state.data && this.state.data.length > 0) {
      const rolesList = [...this.state.data];
      const index = rolesList.indexOf(item);
      rolesList[index] = { ...rolesList[index] };
      rolesList[index].checked = e.target.checked;
      this.setState({
        data: rolesList,
      })
    }
  }

  handleChangeStart(date, mindate) {
    this.setState({
      startDate: date,
      datapickflag: true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText: selectedtext };
      this.onDropDownHandler(e, 'clickHandler');
    });
  }

  handleChangeEnd(date) {
    this.setState({ 
      endDate: date,
      datapickflag: true 
    }
      , () => {
        let selectedtext = 'Date Range';
        var e = { target: { text: selectedtext }, nodeText: selectedtext };
        this.onDropDownHandler(e, 'clickHandler');
      });
  }

  onDropDownHandler(e) {
    const { nodeText:text } = e;
    this.handleHistoricErrorDropdown(text);
  }


  handleHistoricErrorDropdown = (text) => {
    const { sortColumn } = this.state;
    if (text === "Date Range") {
      todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
      weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
    }
    else if (text === 'Week') {
      weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
      todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

      var startDateMonth = getMonth(new Date(weekDateFormatted));
      var endDateMonth = getMonth(new Date(todaysDateFormatted));

      if (startDateMonth !== endDateMonth) {
        weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
      }
    }
    else {
      todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
      weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
    }
    this.setState({ type: text, dateFilter: text, datapickflag: text === 'Date Range' }, () => {
      this.loadData(1, sortColumn);
    });
  }

  errorHelpSection = (item) => {
    const currentUnit = JSON.parse(localStorage.getItem('currentUnit'))
    const brandName = currentUnit.BRAND_NAME;
    const modelName = item.model_family_name;
    const errorCode = item.error_code;
    const linkFormation = `${item.helpLink}?brand=${brandName}&model=${modelName}&eventCode=${errorCode}`;
    window.open(linkFormation, "_blank");
  }


  clearSelectedError = async (e) => {
    const { currentPage, sortColumn } = this.state;
    var rolesList = [...this.state.data];
    let selErrorListCode = [];
    rolesList.forEach(r => {
      if (r.checked) {
        let val = { "error_code": r.error_code };
        selErrorListCode.push(val);
      }
    });

    let apiUrl = ServiceManagement.deleteError
    apiUrl = apiUrl.replace("{deviceId}", localStorage.getItem("unitID"));
    http.delete(apiUrl,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          'brandId': localStorage.getItem("brandName"),
          'errors': selErrorListCode,
        }
      })

      .then(response => {
        console.log('delete_Info', response.data);
        this.setState({
          loaderIconVisible: false,
          success_modal: {
            open: true,
            message: "KC0855"
          }
        });
        this.loadData(currentPage, sortColumn);
      })
      .catch(error => {
        this.setState({
          loaderIconVisible: false,
          error_modal: {
            open: true,
            message: error.response.data.message ? error.response.data.message : "KC1900", 
          }
        });
      })
  }


  render() {
    const currentUnit = JSON.parse(localStorage.getItem('currentUnit'))
    const brandName = currentUnit.BRAND_NAME;
    const modelName = currentUnit.PRODUCT_NAME;

    const { formatMessage } = this.props.intl;

    //$('.ReactTable .rt-table .rt-thead .rt-tr .rt-th:nth-child(2)').toggleClass('-sort-desc');
    $(document).ready(function () {
      $(document).on('click', '.ReactTable .rt-table .rt-thead .rt-tr .rt-th', function () {
        console.log($(this).parent().children().removeClass('-sort-desc'));
        $(this).parent().children().removeClass('-sort-desc');
      });

      // Nav Toggle
      $('.descCollapse').off('click').on('click', function () {
        $(this).parents(".tBody").toggleClass('descExp');
      });
    });

    const { data, totalPages, currentPage, sortColumn, IsServerError, success_modal, error_modal, noRecords, isSelectAllRoles, isCriticalSel, isNonCriticalSel, isErrorEventSel } = this.state;
    const { loaderIconVisible } = this.props;
    let emailErrorReportAuthFeature = {};
    emailErrorReportAuthFeature = authService.getFeatureByFeatureName("Email Error Report");
    let errorCenterAuthFeature = {};
    errorCenterAuthFeature = authService.getFeatureByFeatureName("Error Center");

    let critical = ''
    let nonCritical = ""
    let totalEventsErrorCount = null;
    if (this.state.currentErrCount["ErrHistoryCount"]) {
      critical = this.state.currentErrCount["ErrHistoryCount"]["critical"]
      nonCritical = this.state.currentErrCount["ErrHistoryCount"]["nonCritical"]
      totalEventsErrorCount = this.state.currentErrCount["ErrHistoryCount"]["eventsError"]
    }

    //Columns Schema
    let columns = [];

    if (localStorage.getItem("brandName") === "CREM") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "description", labelStringID: "KC0195" },
        { path: "frequency", labelStringID: "KC0852" },
        { path: "current_start_date", labelStringID: "KC0201" },
        { path: "current_start_date", labelStringID: "KC0202" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "help", label: "" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else if (localStorage.getItem("brandName") === "GARLAND") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "holder_id", labelStringID: "KC0336" },
        { path: "description", labelStringID: "KC0195" },
        { path: "frequency", labelStringID: "KC0200" },
        { path: "current_start_date", labelStringID: "KC0201" },
        { path: "current_start_date", labelStringID: "KC0202" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "help", label: "" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else if (localStorage.getItem("brandName") === "FRYMASTER") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "holder_id", labelStringID: "Frypot" },
        { path: "description", labelStringID: "KC0195" },
        { path: "frequency", labelStringID: "KC0200" },
        { path: "current_start_date", labelStringID: "KC0201" },
        { path: "current_start_date", labelStringID: "KC0202" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "help", label: "" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "description", labelStringID: "KC0195" },
        { path: "frequency", labelStringID: "KC0200" },
        { path: "current_start_date", labelStringID: "KC0201" },
        { path: "current_start_date", labelStringID: "KC0202" },
        { path: "duration", labelStringID: "KC0197" },
        // { path: "help", label: "Help" },        
        // { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    }

    function CellComponent(props) {
      const { datediff, duration } = props;
      if (datediff && datediff <= 100) {
        return (<div class="progress-bar" style={{ width: '0%' }}>{duration}</div>);
      }
      else if (datediff && datediff <= 43200) {
        return (<div class="progress-bar" style={{ width: '25%' }}>{duration}</div>);
      }
      else if (datediff && datediff <= 86400) {
        return (<div class="progress-bar" style={{ width: '50%' }}>{duration}</div>);
      }
      else if (datediff && datediff > 86400 && datediff < 432000) {
        return (<div class="progress-bar" style={{ width: '75%' }}>{duration}</div>);
      }
      else {
        return (<div class="progress-bar" style={{ width: '100%' }}>{duration}</div>)
      }
    }

    let allowToDelete = false;
    var rolesList = data;
    rolesList.forEach(r => {
      if (allowToDelete === false) {
        allowToDelete = r.checked;
      }
    });

    return (
      <React.Fragment>
        <ErrorMoal open={IsServerError ? true : false}  stringID='KC1678' onCloseModel={() => this.errorCloseModel()} /> 
        <div class="pageTabHeader">
          <ul>
            <li>
              <Link to="/serviceManagement" title={formatMessage({ id: "List" })} class="serviceMgtHeader active"><FormattedMessage id="KC0199"/></Link>
            </li>
            <li class="selectedBox">
              {/* <div class="selectedValBox">
                <span class="errorBox"></span>
                <span class="warningBox"></span>
              </div> */}
              {/* <select class="form-control selectedDuration" onChange={this.changeErrorList}>
                <option value="All Errors">All Errors</option>
                <option value="Error">Critical</option>
                <option value="Warning">Non-Critical</option>
              </select> */}
            </li>
          </ul>
        </div>
        <div class="gridViewTable">
          <div class="gridView">
            <div class="colm12D">
              <div class="colmDi">
                <h4 className="errorHistoricH4"><FormattedMessage id="KC0189"/>
                  <div class="sendEmaiReport">
                    <button class={allowToDelete && errorCenterAuthFeature && errorCenterAuthFeature.is_editable === 1 ? "btn btn-secondary clearErrorBtn" : "btn btn-secondary clearErrorBtn disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" type="button"><FormattedMessage id="KC0203"/></button>
                  </div>
                  <NewDropDownListLink translation={true} startDatepara={this.state.startDate} datapickflag={this.state.datapickflag} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e, "clickHandler")} filterType={this.state.type} disableDatePicker={this.state.hideDateRange} />
                  <div className="sendEmaiReport">
                    <div className="checkBoxListGrp">
                      <div className="customCheckbox crticalCBox">
                        <input id="criticalError" type="checkbox" value="criticalError" onClick={(e) => this.handleCheckBoxClick(e, "isCriticalSel")} checked={isCriticalSel} />
                        <label for="criticalError"><FormattedMessage id="KC0156"/> <span>{critical}</span></label>
                      </div>
                      <div className="customCheckbox nonCrticalCBox">
                        <input id="nCriticalError" type="checkbox" value="nCriticalError" onClick={(e) => this.handleCheckBoxClick(e, "isNonCriticalSel")} checked={isNonCriticalSel} />
                        <label for="nCriticalError"><FormattedMessage id="KC0157"/> <span>{nonCritical}</span></label>
                      </div>
                      <div className="customCheckbox eventsCBox">
                        <input id="eventsError" type="checkbox" value="errorEvent" onClick={(e) => this.handleCheckBoxClick(e, "isErrorEventSel")} checked={isErrorEventSel} />
                        <label for="eventsError"><FormattedMessage id="KC0422"/> <span>{totalEventsErrorCount}</span></label>
                      </div>
                    </div>
                  </div>
                </h4>
              </div>
              <div class="errorListTab setMinHeight01">
                {noRecords === false ?
                  <div class= {(localStorage.getItem("brandName") === "FRYMASTER") ? "fErrorListTable historicErrorsTable" : "errorListTable historicErrorsTable"}>
                    <TableHeader
                      sortColumn={sortColumn}
                      onSort={this.onSortedChange}
                      columns={columns}
                      selectAllChange={this.handleChange}
                      isSelectAll={isSelectAllRoles} />



                    {data.map((item, i) => {
                      const isDisabled = !(item.error_code && item.description && brandName && item.model_family_name);
                      return (
                        <ul class="tBody">
                          <li></li>
                          <li>{item.order_id}</li>
                          <li className="errorStausLi">
                            {/* <span class={item.is_critical === 1 ? "errorStaus warnLevel" : "errorStaus alertLevel"}>&nbsp;&nbsp;</span> */}
                            {item.is_critical === 0 && <span class="errorStaus alertLevel">&nbsp;&nbsp;</span>}
                            {item.is_critical === 1 && <span class="errorStaus warnLevel">&nbsp;&nbsp;</span>}
                            {item.is_critical === 2 && <span class="errorStaus errorEvent">&nbsp;&nbsp;</span>}
                          </li>
                          <li>{item.error_code}</li>
                          {localStorage.getItem("brandName") === "FRYMASTER" ? <li>{formatMessage({ id: 'Frypot' })}   {item.holder_id}</li> : ""} {/* MLS:TODO */}
                          {localStorage.getItem("brandName") === "GARLAND" ? <li>{((item.holder_id === "1" || item.holder_id === null)? "--":item.holder_id)}</li> : ""}
                          <li>
                            {/* <h6><span class="errorTypeSM">{item.error_code} -</span> Error Title</h6> */}
                            <p>{item.description}</p>
                          </li>
                          <li>{item.frequency} </li>
                          <li>{item.error_occured_date ? moment.ConvertLocalTimeFromDeviceUTCTime(item.error_occured_date, "MMMM DD, YYYY HH:mm") : ""}</li> 
                          <li>{((item.error_resolved_date) && item.is_critical !== 2) ? moment.ConvertLocalTimeFromDeviceUTCTime(item.error_resolved_date, "MMMM DD, YYYY HH:mm") : ""}</li> 
                          <li className="progress">{CellComponent(item)}</li>
                          <li>
                              <button
                                onClick={() => isDisabled ? '' : this.errorHelpSection(item)}
                                title={formatMessage({ id: 'KC0198' })}
                                class={`helpIcon cursor-pointer ${isDisabled ? 'disabled' : ''}`}>
                              </button>  
                            </li>
                          {
                            <li>
                              <div className={"customCheckbox customCheckboxWOlabel"}>
                                <input id={i} name={i} type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => this.onSelectRole(e, item)} />
                                <label htmlFor={i}></label>
                              </div>
                            </li>
                          }
                        </ul>
                      )
                    })
                    }
                  </div> : ""}
                {noRecords === true ? <NoRecordsFound loaderIconVisible={loaderIconVisible} length={data !== undefined ? data.length : 0} classname={'norecordsfound'} /> : ""}
              </div>

              {/* <!-- Delete Confirmation Modal --> */}
              <div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                <div class="modal-dialog modal-md">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="deleteConfirmation">
                        <FormattedMessage id="KC0854"/>
					            </div>
                    </div>
                    <div class="modal-footer textAlignCenter">
                      <button class="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                      <button onClick={(e) => this.clearSelectedError()} class="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0203"/></button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- //Delete Confirmation -->
	            <!-- Delete Confirmation Modal --> */}
              <div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
                <div class="modal-dialog modal-md">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="confirmationDiv">
                        <FormattedMessage id="KC1823"/>
					            </div>
                    </div>
                    <div class="modal-footer textAlignCenter">
                      <button class="btn btn-secondary confirmationOKBtn" onclick="location.href='cremcurrenterrors.html'" type="button"><FormattedMessage id="KC0777"/></button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- //Delete Confirmation --> */}

              
              { totalPages && totalPages > 1 ? <GoToPagination
                isBlackBackground={true}
                totalPageNumber={totalPages}
                pageNumber={currentPage}
                navigateTo={(p) => this.onPageChange(p)} /> : null }
            </div>
          </div>
        </div>
        <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />  
        <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} /> 
      </React.Fragment>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    searchData: state.advanceSearch.searchData,
  }
}

export default connect(mapStateToProps, null)(injectIntl(ErrorListForHistoric));