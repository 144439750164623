import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import UtilizationSummaryTable from './mercoUtilizationWidgets/UtilizationSummaryTable';
import PeakHoursLineChart from './globalWidgets/peakHoursLineChart';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { getAverageDrinksList } from '../../actions/reportManagement/averageDrinksAction';
import TotalOnTimeWidget from './mercoUtilizationWidgets/totalOnTime';
import TotalOffTimeWidget from './mercoUtilizationWidgets/totalOffTime';
import TotalOnPeriodTimeWidget from './mercoUtilizationWidgets/totalOnPeriodTime';
import TotalOnTimePercentageWidget from './mercoUtilizationWidgets/totalOnTimePercentage';
import GaugeChart from './globalWidgets/gaugeChart';
import BarChartWithoutAxisValues from './globalWidgets/barChartWithoutAxisValues';
import TrayUtilizationLineChart from './globalWidgets/trayUtilizationLineChart';
import SingleDataTrendFilterWidget from '../unitDashboard/globalWidgets/singledatatrendfilterwidget';
import EquipmentEfficiencyChart from './globalWidgets/equipmentEfficiencyChart';
import OvenUtilizationLineChart from './globalWidgets/ovenUtilizationLineChart';
import SingleDataWidget from '../unitDashboard/globalWidgets/singledatawidget';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import TotalOnTimeIdleTimeUtilization from './garlandWidgets/TotalOnTimeIdleTimeUtilization';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import ConvothermOvenStatusPieChart from './globalWidgets/ConvothermOvenStatusPieChart';

am4core.useTheme(am4themes_animated);

var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class Utilization extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			avgType: 'Month',
			topTenType: 'Month',
			peakHourType: 'Month',
			noOfDrinksType: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sd: '',
			ed: '',
			startDate: todaysDate,
			endDate: todaysDate,
			sdAvgDrinks: '',
			edAvgDrinks: '',
			startDateAvgDrinks: todaysDate,
			endDateAvgDrinks: todaysDate,
			loaderIconVisible: true,
			hideDateRange: true,
			datapickflag: false,
			globalDropdownFilter: 'Month',
			isGlobalDropdownChange:false,
			globalDropdownFilterStartDate: '',
			globalDropdownFilterEndDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);

		this.handleChangeStartAvgDrinks = this.handleChangeStartAvgDrinks.bind(this);
		this.handleChangeEndAvgDrinks = this.handleChangeEndAvgDrinks.bind(this);
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		brandID = "CREM";
		this.props.getAverageDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	handleChangeStart(date, mindate) {
		this.setState({ startDate: date, sd: date, endDate: date, ed: moment(date).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEnd(date) {
		let selectedtext = 'Date Range';
		this.setState({ endDate: date });
		var e = { target: { text: selectedtext }, nodeText:selectedtext };
		this.onDropDownHandler(e, 'TotalDrinks');
	}

	//Average Drinks Produced
	handleChangeStartAvgDrinks(dateAvgDrinks, mindate) {
		this.setState({ startDateAvgDrinks: dateAvgDrinks, sdAvgDrinks: dateAvgDrinks, endDateAvgDrinks: dateAvgDrinks, edAvgDrinks: moment(dateAvgDrinks).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEndAvgDrinks(dateAvgDrinks) {
		let selectedtext = 'Date Range';
		this.setState({ endDateAvgDrinks: dateAvgDrinks });
		var e = { target: { text: selectedtext }, nodeText:selectedtext };
		this.onDropDownHandler(e, 'AverageDrinks');
	}

	onDropDownHandler(e, type = '') {
		const { nodeText:text } = e;
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = "CREM";
		if (text === "Date Range") {
			if (type === "TotalDrinks") {
				todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
			}
			else if (type === "AverageDrinks") {
				todaysDateFormatted = format(this.state.endDateAvgDrinks, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateAvgDrinks, 'YYYY-MM-DD');
			}
			else if (type === "PeakHourDrinks") {
				todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
			}
			else if (type === "TopTenDrinks") {
				todaysDateFormatted = format(this.state.endDateTopTen, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateTopTen, 'YYYY-MM-DD');
			}
			else {
				todaysDateFormatted = format(this.state.endDateHeatMap, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateHeatMap, 'YYYY-MM-DD');
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}

		if (type === "TotalDrinks") {
			this.setState({ type: text });
			this.props.getTotalDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else if (type === "AverageDrinks") {
			this.setState({ avgType: text });
			this.props.getAverageDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else if (type === "PeakHourDrinks") {
			this.setState({ peakHourType: text });
			this.props.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "TopTenDrinks") {
			this.setState({ topTenType: text });
			this.props.getTopTenTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "HeatMapTable") {
			this.setState({ noOfDrinksType: text });
			this.props.getheatMapTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
	}

	handleRangeChange(which, payload) {
		console.log(which, payload);
		this.setState({
			[which]: {
				...this.state[which],
				...payload,
			},
		});
	}

	handleChangeGlobalStart(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermBadTrays.handleChangeStart(date);
			}else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
				this.refs.merrychefPeakHourLineChart.handleChangeStart(date);
			}else if ( currentUnit.BRAND_NAME === "GARLAND"){
				this.refs.garlandTotalOnTimeUtilization.handleChangeStart(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermBadTrays.handleChangeEnd(date);
			} else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
				this.refs.merrychefPeakHourLineChart.handleChangeEnd(date);
			}else if ( currentUnit.BRAND_NAME === "GARLAND"){
				this.refs.garlandTotalOnTimeUtilization.handleChangeEnd(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const { currentUnit } = this.props;
		const filterType = e.nodeText;

		this.setState({
			globalDropdownFilter: filterType,
			isGlobalDropdownChange: true,
			datapickflag: filterType === 'Date Range'
		});
		if (currentUnit.BRAND_NAME === "CONVOTHERM") {
			if(filterType !== 'Date Range'){
				this.refs.convothermTrayUtilization.handleTrayUtilization(filterType)
				this.refs.convothermTrayUtilization.setState({ filterType, datapickflag: filterType === 'Date Range' });

				this.refs.convothermOvenUtilization.handleOvenUtilization(filterType)
				this.refs.convothermOvenUtilization.setState({ filterType, datapickflag: filterType === 'Date Range' });

				// convosense
				this.refs.convothermOvenStatusUtilization.handleOvenStatusUtilization(filterType);
				this.refs.convothermOvenStatusUtilization.setState({ filterType, datapickflag: filterType === 'Date Range'});
			}
			

			this.refs.convothermBadTrays.fetchData(this.refs.convothermBadTrays.state.holder_id, filterType)
			this.refs.convothermBadTrays.setState({ filterType, datapickflag: filterType === 'Date Range' });
			
		} else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
			this.refs.merrychefPeakHourLineChart.handlePeakHourLine(filterType)
			this.refs.merrychefPeakHourLineChart.setState({ filterType, datapickflag: filterType === 'Date Range' });

		}else if ( currentUnit.BRAND_NAME === "GARLAND"){
			this.refs.garlandTotalOnTimeUtilization.fetchData(filterType)
			this.refs.garlandTotalOnTimeUtilization.setState({ type:filterType, datapickflag: filterType === 'Date Range' });
		}

	}

	//  woopra event
	reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackTitle}`);
	};

	render() {
		let { loaderIconVisible,isGlobalDropdownChange, globalDropdownFilter, globalDropdownFilterStartDate, globalDropdownFilterEndDate } = this.state;
		const { formatMessage } = this.props.intl;
		currentUnit = this.props.currentUnit;
		let utilizationTabHeader = null;
		// MERCO and MERRYCHEF Utilization Tab header display
		if (currentUnit.BRAND_NAME === 'MERCO' || currentUnit.BRAND_NAME === 'MERRYCHEF' || currentUnit.BRAND_NAME === 'FRYMASTER-UHCTHD') {
			utilizationTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} className="active"><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		// GARLAND Utilization Tab header display
		else if (currentUnit.BRAND_NAME === 'GARLAND') {
			utilizationTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({ id: 'KC1799' })} onClick={(e)=>{e.trackTitle="Platen Cycles";this.reportEvent(e)}}><FormattedMessage id="KC1799"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} className="active"><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate}  handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		// CONVOTHERM Clean Tab header display
		else {
			utilizationTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({ id: 'KC0405' })} onClick={(e)=>{e.trackTitle="Clean";this.reportEvent(e)}}><FormattedMessage id="KC0405"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({ id: 'KC0270' })} className="active"><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}

		let displayTrayUtilization = null;
		// CONVOTHERM Tray Utilization Graphical View
		displayTrayUtilization = (<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable" : "displaynone"}>
			<div class="gridView">
					<TrayUtilizationLineChart ref="convothermTrayUtilization" properties={this.props} widgetID="49" widgetName="Tray Utilization" widgetNameStringID="KC1804" chartTitle="Avg. number of trays used" chartTitleStringID="KC1805" disableDatePicker={true} />  
			</div>
		</div>)

		let displayOvenUtilization = null;
		// CONVOTHERM Oven Utilization(Graphical View)
		displayOvenUtilization = (<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable" : "displaynone"}>
		<div class="gridView">
			<div class="colm12D">
			<OvenUtilizationLineChart ref="convothermOvenUtilization" properties={this.props} widgetID="50" widgetName="Oven Utilization" widgetNameStringID='KC0417' chartTitle="Avg. utilization per day" chartTitleStringID="KC1806" disableDatePicker={true} />
			</div>
		</div>
	</div>) 

		let displayOvenStatusChart = null;
		displayOvenStatusChart = (<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable" : "displaynone"}>
			<div class="gridView">
				<div class="colm12D">
					<ConvothermOvenStatusPieChart ref="convothermOvenStatusUtilization" properties={this.props} widgetID="116" widgetName="Convotherm Oven Status" widgetNameStringID="KC2241" disableDatePicker={true} />
				</div>
			</div>
		</div>)

		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="reportNav" />
					<div id="page-content-wrapper">
						{/* Reports and Analytics utilization tab header */}
						{utilizationTabHeader}

						{/* Level 1 | Widgets - 2 (Merco) | Widgets - 3 (Merrychef) | Widgets - 2 (Convotherm) */}
						<div class={(localStorage.getItem("brandName") === "MERCO" || localStorage.getItem("brandName") === "FRYMASTER-UHCTHD") ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<TotalOnTimeWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={this.state.globalDropdownFilter} globalDropdownFilterEndDate={this.state.globalDropdownFilterEndDate} globalDropdownFilterStartDate={this.state.globalDropdownFilterStartDate} />
								<TotalOffTimeWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={this.state.globalDropdownFilter} globalDropdownFilterEndDate={this.state.globalDropdownFilterEndDate} globalDropdownFilterStartDate={this.state.globalDropdownFilterStartDate}/>
							</div>
						</div>
						<div class={localStorage.getItem("brandName") === "MERRYCHEF" ? "gridViewTable gridViewcolm4DSM" : "displaynone"}>
							<div class="gridView">
								<GaugeChart properties={this.props} widgetID="24" widgetName="Convection Motor" widgetNameStringID="KC0274" gaugeName="Oven On Time" gaugeNameStringID="KC0271" />
								<GaugeChart properties={this.props} widgetID="25" widgetName="Thermocouple" widgetNameStringID="KC0275" gaugeName="Heater On Time" gaugeNameStringID="KC0272" />
								<GaugeChart properties={this.props} widgetID="23" widgetName="Door Hinge" widgetNameStringID="KC0276"  gaugeName="Door Openings" gaugeNameStringID="KC0273" />
							</div>
						</div>
						<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<TotalOnTimeWidget page={"Dashboard"} widgetID="115" widgetName="TOTAL ON TIME" cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
								{/* <SingleDataWidget properties={this.props} widgetID="34" widgetName="TOTAL ON TIME" widgetNameStringID="KC0395" classValue="colm6D colm4D1" isTimeDisplay="false"/> */}
								<SingleDataTrendFilterWidget ref="convothermBadTrays" properties={this.props} widgetID="41" widgetName="BAD TRAYS" widgetNameStringID="KC0411" infoTextStringID="KC2236" classValue="colm6D colm6D2" isTimeDisplay="false" />
							</div>
						</div>
						{/* Level 2 | Widgets - 2 (Merco) | Widgets - 3 (Merrychef) */}
						<div class={(localStorage.getItem("brandName") === "MERCO" || localStorage.getItem("brandName") === "FRYMASTER-UHCTHD") ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<TotalOnPeriodTimeWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}   globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
								<TotalOnTimePercentageWidget cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}   globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
							</div>
						</div>
						<div class={localStorage.getItem("brandName") === "MERRYCHEF" ? "gridViewTable gridViewcolm4DSM" : "displaynone"}>
							<div class="gridView">
								<GaugeChart properties={this.props} widgetID="28" widgetName="Hi-Limit, Cavity Overheat" widgetNameStringID="KC1801"  gaugeName="Oven On Time" gaugeNameStringID="KC0271" />
								<GaugeChart properties={this.props} widgetID="27" widgetName="Left Magnetron" widgetNameStringID="KC0278" gaugeName="Magnetron On Time" gaugeNameStringID="KC0282" />
								<GaugeChart properties={this.props} widgetID="26" widgetName="Right Magnetron" widgetNameStringID="KC0279" gaugeName="Magnetron On Time" gaugeNameStringID="KC0282" />
							</div>
						</div>
						<div class={localStorage.getItem("brandName") === "CONVOTHERM" ? "gridViewTable gridViewcolm6DSM" : "displaynone"}>
							<div class="gridView">
								<EquipmentEfficiencyChart properties={this.props} widgetID="51" widgetName="Equipment Efficiency" widgetNameStringID="KC1807" classValue="colm6D colm6D1" chartTitle="Good" chartTitleStringID="KC1808" disableDatePicker={true} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/> 
								<BarChartWithoutAxisValues properties={this.props} widgetID="43" widgetName="BAD TRAYS" widgetNameStringID="KC0411" classValue="colm6D colm6D2" chartTitle="Avg. bad trays" chartTitleStringID="KC1892" disableDatePicker={true} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/>  
							</div>
						</div>
						{/* Level 3 | Widgets - 1 | Peah Hour (Merrychef) | Utilization Summary (Merco) */}
						{localStorage.getItem("brandName") === "MERRYCHEF" ? <PeakHoursLineChart ref="merrychefPeakHourLineChart" properties={this.props} /> :
							localStorage.getItem("brandName") === "MERCO" ? <UtilizationSummaryTable currentUnit={this.props} holdtype={'Pan'} holdtypeStringID="KC0375" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/> :
							localStorage.getItem("brandName") === "FRYMASTER-UHCTHD" ? <UtilizationSummaryTable currentUnit={this.props} holdtype={'Slot'} holdtypeStringID="KC1893" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/> :      
								displayTrayUtilization}
						{/* Level 4 | Widgets - 1 | Oven Utilization (Convotherm) | */}
							{displayOvenUtilization}

						{ /* Convotherm Chart */}
							{displayOvenStatusChart}
						{/* GARLAND Widgets Total On Time | Total Idle | Utilization*/}
						{localStorage.getItem("brandName") === "GARLAND" ? <TotalOnTimeIdleTimeUtilization ref="garlandTotalOnTimeUtilization" properties={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/> : ""}
					</div>
				</div>
			</React.Fragment>
		)
	}
};
function mapStateToProps(state) {
	return {
		averageDrinksInfo: state.averageDrinksInformation,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getAverageDrinksList,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Utilization));
