export const isCaptchaEnabled = () =>{ 
    const hostname = window && window.location && window.location.hostname;
    let recaptcha = "";
    if (hostname === "www.welbiltdigitaldev.com" 
    || hostname === "welbiltdigitaldev.com" 
    || hostname === "www.welbiltdigitalintegration.com" 
    || hostname === "welbiltdigitalintegration.com" 
    || hostname === "www.welbiltdigitalqa.com" 
    || hostname === "welbiltdigitalqa.com" 
    || hostname === "www.welbiltconnect.cn" 
    || hostname === "welbiltconnect.cn") {
        recaptcha = false;
    } else {
        recaptcha = true;
    }
    return recaptcha;
};
