import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SmartTagWidget from './smartTagWidget';
import { ServiceManagement } from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import { FormattedMessage } from 'react-intl';
 
class ErrorSolvedWidget extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			errorResponseData: [],
		};

	}

	componentDidMount = async () => {
		const unitID = localStorage.getItem("unitID")
		const brandID = localStorage.getItem("unitBrandId");
		this.getErrorinformations(unitID, brandID);
	}

	getErrorinformations = (unitVal, brandVal) => {
		let apiUrl = `${ServiceManagement.historicalError}`;
      	apiUrl = apiUrl.replace("X0", unitVal);
		const unitId = unitVal, brandId = brandVal;		
		let header = {
			'Content-Type': 'application/json',
			'errorstatus' : "resoved",
		}
		http({
			method: "GET",
			url: apiUrl,
			headers: header,
			data: {},
		}).then(response => {
			this.setState({ errorResponseData: response.data, loaderIconVisible: false });			
		}).catch(err => {
			this.setState({ loaderIconVisible: false });			
		});
	};

	render() { 		
		let { errorResponseData } = this.state;
		return (
			<React.Fragment>    
				{this.props.withTag === true ?					
						<div class="colm6D colm6D2">
							<div class="colmDi">
								<h4><FormattedMessage id="KC0388"/></h4>
								<div className="customPara">
									<ul>
										<li>
											<p>{errorResponseData.length>0 ? moment.GetDateTimeByFormat(errorResponseData[0].createdAt, 'LLL'):""}</p>
											<p>{errorResponseData.length>0? errorResponseData[0].error_code + " : " + errorResponseData[0].description :""}</p>
											</li>
										<li>
											<p>{errorResponseData.length>0 && errorResponseData[1] && errorResponseData[1].createdAt? moment.GetDateTimeByFormat(errorResponseData[1].createdAt, 'LLL'):""}</p>
											<p>{errorResponseData.length>0 && errorResponseData[1] ? errorResponseData[1].error_code + " : " + errorResponseData[1].description :""}</p>
										</li>
									</ul>
								</div>
							</div>
							<SmartTagWidget   currentUnit= {this.props}  currentLocation  = {this.props}/>
						</div>
				:		
					<div class="colm6D colm6D2 disabled">
						<div class="colmDi">
							<h4>{localStorage.getItem("brandName") !== "CREM" ? <FormattedMessage id="KC0388"/> : ""}</h4>
						</div>					
					</div>
				}
            </React.Fragment>
		)		
	}
}; 
export default  ErrorSolvedWidget;
