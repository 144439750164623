import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { MediaManagementAPI } from '../../../service/api';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import appconstants from '../../../utils/appConstants';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { createPackageZip, setCreatePackageAction } from '../../../actions/navigation/mediaManagement/mediaCreatePackageAction';
import { UserProfile } from '../../../service/api';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import uploadMediaToCloud from '../media/uploadMedia';
import $ from 'jquery';
import qs from 'query-string';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
var bg = require('../../../images/unit/CremCarrera.svg').default
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;


class assignPackageMedia extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }

    constructor(props) {
        super(props);
        this.state = {
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            selectedFiles: null,
            data: [],

            pages: null,
            loading: true,
            unitsSelected: 0,
            unitsOffline: 0,
            userSelectAll: false,
            isIndeterminateSelect: false,
            userChecked: [],
            userCheckedData: [],
            sortColumn: { path: "", order: "", sort_color: "#fff" },
            unitSelectFinishBtn: false,
            mediaSelectedItemsInfo: [],
            isCreateZip: localStorage.getItem("zipFileName") || null,
            isSuccessCreatePackage: null,
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            units: []
        }
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.postData = this.postData.bind(this);
        this.onCreateZipPackage = this.onCreateZipPackage.bind(this);
    }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        });
        let { isSuccessCreatePackage } = this.state;
        if (isSuccessCreatePackage) {
            this.props.setCreatePackageAction();
            this.props.history.push(`/mediapackage`);
        }
    }

    closeModel() {
        this.setState({ error_modal: { open: false } })
    }

    componentWillMount = () => {
        let mediaSelectedItemsInfo = this.props.mediaSelectedItemsInfo && this.props.mediaSelectedItemsInfo.length > 0 ? this.props.mediaSelectedItemsInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
        localStorage.setItem('selectedItemsInfo', JSON.stringify(mediaSelectedItemsInfo))
        this.setState({
            mediaSelectedItemsInfo: mediaSelectedItemsInfo
        });
    }

    componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2";
        this.getCustGroupID();
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeOuter');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top);
    }

    onCreateZipPackage() {
        let { selectedFiles, isCreateZip, selectedGroupName } = this.state;
        let { prepartions, other } = selectedFiles;
        let req = {};
        if (prepartions && other) {
            req = { files: [...prepartions, ...other] };
        }

        if (selectedGroupName && Array.isArray(selectedGroupName)) {
            let cgid = [];
            for(let i=0; i < selectedGroupName.length;i++){
                const ele = selectedGroupName[i];
                cgid.push(ele.cust_gp_id || '');
            }
            req = { ...req, cgid, selectedBrand: "" };
        }
        if (isCreateZip) {
            req = { ...req, zipFileName: isCreateZip };
        }
        this.setState({ loaderIconVisible: true });
        this.props.createPackageZip(req);
    }

    onFinishClickHandler() {

        let userCheckedData = this.state.selectedGroupName;
        const { mediaSelectedItemsInfo } = this.state;
        const unitList = [];

        for (let i = 0; i > userCheckedData.length; i++) {
            const selectedMaas = userCheckedData[i];
            for (let j = 0; j > selectedMaas.units.length; j++) {
                const item = selectedMaas.units[j];
                unitList.push(
                    {
                        "thingArn": item.THING,
                        "productId": item.ProductId,
                        "cloudUnitName": item.CLOUD_UNIT_NAME,
                        "unitTimeZone": item.TimeZone_Standard,
                    }
                )
            }
        }

        const req = {
            "thingList": unitList,
            "mediaId": mediaSelectedItemsInfo
        }

        this.postData(MediaManagementAPI.getUploadHistory, req).then(response => {
            this.props.history.push(`/mediaUploadHistory`);
        }).catch(err => {
            this.showError(err);
        });

    }

    showError = (err) => {
        let { response } = err;
        let { data } = response;
        let { message, body } = data;
        if (response && response.status === 400) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: body.message ? body.message : ""
                }
            });
        }
        else if (response && response.status === 500) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: message ? message : ""
                },
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: message ? message : ""
                }
            });
        }
    }

    postData = (url, reqData) => {
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let userName = loginUser.username;
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {

            http.post(url
                , reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'user': userName,
                        'basecountry': BASECOUNTRY_ID
                    }

                }
            ).then(response => {
                let { data } = response;
                let { message } = data;
                if (response && response.status === 200) {
                    this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: message
                        },
                    });
                }

            }).catch(err => {
                reject(err);
            });
        });
    }


    getCustGroupID = () => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true, })

        const values = qs.parse(this.props.location.search);
        if (values.user !== undefined) {
            loggedinUser = values.user;
        }
        this.setState({ queryString: loggedinUser });

        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });

            if (setcustGroupIDList !== "") {
                this.getOrgnanizationView(setcustGroupIDList);
            }
        }).catch(err => {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    /**
    * Get Unit Smart Group names for display at Unit Node
    * @param {*} unitNode Unit Node object
    * @returns Array<String> Smart Group names
    */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
    * Convert hierarchy data structure into flatten array
    * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
    * @param {*} nodeLevel Node level No
    * @param {*} currentRowIndex Current row index for Tree Data
    * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
    * @param {*} parentRowIndex Parent Node row index
    * @param {*} parentColIndex Parent Node column index
    * @returns Array<Array>
    */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false
        })
    }

    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}?removeCache=${new Date().getTime()}`,
            headers: {
                "Cgids": values,
                'brandids': localStorage.getItem("unitBrandId"),
                'Content-Type': 'application/json',
                'useremail': loggedinUser,
                "basecountry": BASECOUNTRY_ID,
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, (data) => {
                    this.processOrgnanizationJSON(data);
                });
            }
            else {
                this.processOrgnanizationJSON(response.data);
            }

            this.setState({
                cgIds: values
            })
        }).catch(err => {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });

    }
    submitAddMedia = (e) => {
        e.preventDefault();
        this.setState({
            loaderIconVisible: true
        });

        let _this = this;
        if (this.state.url != null && this.state.data != null && this.state.srcfile != null) {

            const Finalselecteditems = [...this.state.selectedGroupName];
            let Currentselected = [];
            if (Finalselecteditems !== undefined && Finalselecteditems.length > 0) {
                Finalselecteditems.forEach(function (item, index) {
                    Currentselected.push(item.cust_gp_id);
                })
            }
            this.state.data.cgids = Currentselected;
            uploadMediaToCloud(this.state.url, this.state.data, this.state.srcfile, this.state.imageBuffer).then(response => {
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: "KC1515"
                    },
                });
            }).catch(err => {
                _this.showError(err);
            });
        }
        else {
            this.props.history.push(`/MediaPreparation`);
        }
    }
    onItemClick(e, link) {
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        this.setState({
            link,
            isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            unitSelectFinishBtn: Currentselected.length > 0 || false
        })
    }
    onUnitItemClick(e, unit) {
        this.setState({
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
    }
    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit) => {
                const treeActive = unit.UNITID === this.state.unit.UNITID && this.state.isUnitItemClicked ? 'treeActive' : '';
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                const unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : "";
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                return <li key={'unit_'+unit.UNITID}>
                    <div className='orgznSiteTreeLiDiv'>
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt=""  />
                            </span>
                                <span className="treeText deviceModel"
                                ><b>{unit.UNITID}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt=""  />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }
    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    onCollapseButtonClick(e, link) {

        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }

        this.setState({ showLink: showLink })
    }
    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }
    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;


                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li key={'link_node_' + link.cust_gp_id}>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList) }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }


    onClickListView = () => {
        this.props.history.push({
            pathname: "/assignMediaList"
        });
    }

    static getDerivedStateFromProps = (props) => ({ ...props });

    render() {
        const { formatMessage } = this.props.intl;
        const { unitSelectFinishBtn,  isCreateZip, isSuccessCreatePackage, linkList, selectedGroupName } = this.state;
        let { success_modal, error_modal, loaderIconVisible } = this.state;
        if (isSuccessCreatePackage) {
            success_modal = { open: true, message: isSuccessCreatePackage };
            loaderIconVisible = false;
        }

        const finishBtnClass = ['btn-default-text btn forwardNext'];

        if (unitSelectFinishBtn) {
            finishBtnClass.push('activeState');
        } else {
            finishBtnClass.push('disabled');
        }


        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })}>{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                {/* <!-- //Fixed Header navbar -->  style={{padding:'64px 0px 0px'}}*/}
                <div id="page-content-wrapper">
                    <div className="orgznManagementWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>
                                    <Link to="/mediaLibraryList" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC1526"/>
                                </li>
                                <li>
                                    {isCreateZip && <button className={finishBtnClass.join(' ')} onClick={() => this.onCreateZipPackage()} title={formatMessage({ id: 'KC0056' })}>&nbsp;<FormattedMessage id="KC0056"/></button>}
                                    {!isCreateZip && <button type="button" className={finishBtnClass.join(' ')} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}  onClick={() => this.onFinishClickHandler()} title={formatMessage({ id: 'KC0056' })}>&nbsp;<FormattedMessage id="KC0056"/></button>}

                                </li>
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            {
                                <ul>
                                    <li>
                                        {
                                            selectedGroupName.map((subitem, j) =>
                                                <span className="selectedVal">
                                                    {subitem.group_name} &nbsp;
                                                </span>
                                            )}
                                    </li>
                                    <li>
                                        <Link to="#" className="assignunitSite active">&nbsp;</Link>
                                        <button id="btnAssign" className="assignunitList border0" onClick={() => this.onClickListView()}>&nbsp;</button>
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="collapseExpandHeader">
                            <ul>
                                {levelHeader}
                            </ul>
                        </div>

                        <div className="assignOrgTreeOuter">

                            <div className="orgznSiteTree">
                                {this.state.linkList && this.state.linkList.level1 &&
                                    this.state.linkList.level1.map((item) => this.showLinks(this.state.findTreeLevel))}
                            </div>

                        </div>


                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" previousPage="mediaLibraryList" activeClass="mediaNav" filterKey={appconstants.mediaFilters} history={this.props.history} />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        filterlistInformtion: state.filterModel,
        selectedFiles: state.mediaDraggedItem.mediaDraggedItem,
        mediaSelectedItemsInfo: state.mediaPreparationListInformation.mediaSelectedItems,
        isSuccessCreatePackage: state.packageList.createPackageZip
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createPackageZip,
        setCreatePackageAction
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(assignPackageMedia));