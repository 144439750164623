import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import fp from 'lodash/fp';
import { UserProfile } from '../../service/api';
import http from '../../service/httpService';
import { BRAND_ID } from '../../utils/appConstants';
import { brandLocations, unitModels } from '../../utils/imgContants';
import { injectIntl,FormattedMessage } from 'react-intl';
import ErrorMoal from '../modal/error-model';
var bg = require('../../../src/images/unit/CremCarrera.svg').default;

const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;

class ViewIndividualTopology extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            checkedUnits: [],
            getSelectedDynasty: [],
            selectedUnitDetails: "",
            units: [],
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            error_modal: {
                open: false,
                message: ""
            }
        };
        this.getData = this.getData.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentWillMount = () => {
        let { url, data, libraryIds, imageBuffer, previouspath, scheduleDateTimeUtc, Type, libraryType } = this.props.location;

        this.setState({
            url: url,
            data: data,
            libraryIds: libraryIds,
            imageBuffer: imageBuffer,
            previouspath: previouspath,
            scheduleDateTimeUtc: scheduleDateTimeUtc,
            Type: Type,
            libraryType: libraryType
        });
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        this.props.changeLoadingStatus(true)
        this.getCustGroupID();
        this.updateDimensions();
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);    
    }

    getData = (url, Cgids) => {
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                reject(err)
            })
        });
    }

    getCustGroupID = () => {
        let custGroupID = localStorage.getItem("custGroupID");
        this.props.changeLoadingStatus(true)
        try {
            this.getOrgnanizationView(custGroupID);
        } catch (err) {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
            })
            this.props.changeLoadingStatus(false)
        };
    }

     /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
     getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
 * Convert hierarchy data structure into flatten array
 * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
 * @param {*} nodeLevel Node level No
 * @param {*} currentRowIndex Current row index for Tree Data
 * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
 * @param {*} parentRowIndex Parent Node row index
 * @param {*} parentColIndex Parent Node column index
 * @returns Array<Array>
 */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
        const length = orgJSON.start_level_id;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            isUnitItemClicked: true
        })
        this.props.changeLoadingStatus(false)
    }
    
    getOrgnanizationView = async(values) => {
        this.props.changeLoadingStatus(true)
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': localStorage.getItem("selectedbrandID") ? localStorage.getItem("selectedbrandID") : BRAND_ID,
                'useremail': loggedinUser,
                'modelfamilyids' : localStorage.getItem("selectedFamilyID")
            }, data: {},
        }).then(async response => {
            if (response.data.fetchFromS3) {
                await $.getJSON(response.data.presignedS3URL, (data) => {
                    this.processOrgnanizationJSON(data);
                });
             }
             else {
                 this.processOrgnanizationJSON(response.data);
             }
        }).catch(err => {
            this.setState({
                linkListValue: {},
            })
            this.props.changeLoadingStatus(false)
        });
    }

    postData = (url, reqData) => {
        this.props.changeLoadingStatus(true)

        return new Promise((resolve, reject) => {

            http.post(url, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    let { data } = response;
                    let { httpCode, responseCode, message } = data;
                    if (httpCode === "HTTP_201") {
                        this.setState({
                            success_modal: {
                                open: true,
                                message: data.stringID || data.message
                            },
                        });
                    }
                    this.props.changeLoadingStatus(false)
                }).catch(err => {
                    reject(err);
                    this.setState({
                        error_modal: {
                            open: true,
                            message: err.response.data.stringID || err.response.data.message,
                        },
                    });
                    this.props.changeLoadingStatus(false)
                });
        });
    }

    onItemClick(e, link) {

        if (link.level_id === 6) {
            const copydynasty = [...this.state.selectedDynasty];
            let dynasty = [];
            let checkselectedcopy = true;
            let levelofcountry = 2;
            const previousselected = [...this.state.selectedGroupName];
            let Currentselected = [];
            let matchedornot = false;
            if (previousselected != undefined && previousselected.length > 0) {
                previousselected.forEach(function (item, index) {
                    if ((item.category_id == link.category_id) && (item.level_id == levelofcountry && link.level_id == levelofcountry) && (item.cust_gp_id != link.cust_gp_id) && (item.dynasty != link.dynasty) && (item.group_name != link.group_name)) {
                        matchedornot = true;
                    }
                })
            }

            if (copydynasty.length) {
                copydynasty.forEach(el => {
                    if (el === link.dynasty) {
                        dynasty = copydynasty.filter(el => el !== link.dynasty);
                        Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                        checkselectedcopy = false;
                    }
                    else if (el && el.includes(link.dynasty)) {
                        dynasty = copydynasty.filter(fl => fl !== el);
                        dynasty = [...dynasty, link.dynasty];

                        Currentselected = previousselected.filter(s => s.dynasty !== el);
                        Currentselected = [...Currentselected, link];
                    }
                    else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                        dynasty = copydynasty.filter(fl => fl !== el);
                        dynasty = [...dynasty, link.dynasty];

                        Currentselected = previousselected.filter(s => s.dynasty !== el);
                        Currentselected = [...Currentselected, link];

                        checkselectedcopy = false;
                    }
                    else if (checkselectedcopy) {
                        dynasty = [...copydynasty, link.dynasty];
                        Currentselected = [...previousselected, link]
                    }
                })
            }
            else {
                dynasty = [...copydynasty, link.dynasty];
                Currentselected = [...previousselected, link]
            }

            this.setState({
                link: link,
                isUnitItemClicked: false,
                selectedDynasty: dynasty,
                selectedGroupName: Currentselected
            })
        }
    }

    onUnitItemClick(e, unit, link) {
        if (unit.THING) {
            this.setState({
                isFormValidated: true, isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
                isLoactionTypeAvailable: false, level: 6,
                getSelectedDynasty: link.dynasty,
                selectedUnitDetails: unit,
            })
        }
        else {
            this.setState({ 
                error_modal: {
                    open: true,
                    message: "KC1339"
                }
            })
        }
    }

    onOrgCheckBoxChange(e, unit) {
        let checkedUnits = this.state.checkedUnits ? this.state.checkedUnits : [];
        if (e.target.checked) {
            checkedUnits.push(unit);
        }
        else {
            checkedUnits = this.state.checkedUnits.filter(item => item !== unit);
        }

        this.setState({
            isFormValidated: true, checkedUnits: checkedUnits, isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
    }

    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit, index) => {
                const treeActive = unit.UNITID === this.state.unit.UNITID && this.state.isUnitItemClicked ? 'treeActive' : '';
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }

                const unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : "";
                
                if (unit.ACTIVATION_STATUS === "INACTIVE") {
                    unit.checked = false;
                }

                return <li key={`showUnit-${index}`}>
                    <div className='orgznSiteTreeLiDiv'>
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit, link) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require('../../../src/images/unit/CremCarrera.svg').default} />
                            </span>
                                <span className="treeText deviceModel"
                                ><b>{unit.UNITID}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} />
                            </span>
                            <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                            <span className={unitInActive}></span>
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }

    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    
    onCollapseButtonClick(e, link) {

        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }

        this.setState({ showLink: showLink })
    }

    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }

    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;

                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require('../../../src/images/unit/CremCarrera.svg').default} />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList) }}>
                        <span className="treeImg">
                            <img src={require('../../../src/images/unit/CremCarrera.svg').default} />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }

    onClickListView = () => {
        this.props.history.push({
            pathname: "/libraryAssignUpdateList",
            previouspath: this.state.previouspath,
            scheduleDateTimeUtc: this.state.scheduleDateTimeUtc,
            Type: this.state.Type,
            libraryIds: this.state.libraryIds,
            libraryType: this.state.libraryType
        });
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: this.state.previouspath,
            previouspath: "/recipeAssignTopology"
        });
	}

    closeModel = () => {
        this.setState({ 
            error_modal: {
                open: false 
            } 
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { title, location } = this.props;
        let { link, isFormValidated, selectedGroupName, error_modal } = this.state;
        const { linkList } = this.state;

        if (selectedGroupName.length > 0) {
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        }
        else {
            $("#btnfinish").removeClass("activeState");
            $("#btnfinish").addClass("disabled");
        }
        let levelHeader = null;
        if (fp.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= linkList.start_level_id).map(item => <li>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1573' }) + item.level_id} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div>
                <div id="page-content-wrapper">
                    <div className="unitRegistrationWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>
                                <button onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</button>
                                </li>
                                <li>
                                    <FormattedMessage id={title}/>
                                </li>
                                <li >
                                    <Link id="#btnfinish" to={{ pathname: this.state.previouspath, state: { getSelectedDynasty: this.state.getSelectedDynasty, getSelectedUnitDetails: this.state.selectedUnitDetails } }} className={`btn btn-default-text ${isFormValidated ? "activeState" : "disabled"}`} title={formatMessage({ id: 'KC0056' })}><FormattedMessage id="KC0056"/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            <ul>
                                <li>
                                    {
                                        selectedGroupName.map((subitem, j) =>
                                            <span className="selectedVal">
                                                {subitem.group_name}
                                            </span>
                                        )
                                    }
                                </li>
                                <li>
                                    <Link to="#" class="assignunitSite active">&nbsp;</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="collapseExpandHeader assignMediaFixedMargin">
                            <ul>
                                {levelHeader}
                            </ul>
                        </div>
                        <div className="assignOrgTreeOuter">
                                <div className="orgznSiteTree">
                                    {this.state.linkList && this.state.linkList.level1 &&
                                        this.state.linkList.level1.map((item) => this.showLinks(this.state.findTreeLevel))}
                                </div>
                            </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open}  stringID={error_modal.message} onCloseModel={this.closeModel} />
            </div>
        );
    }
}

export default injectIntl(ViewIndividualTopology);