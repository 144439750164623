import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { getMediaUploadHistory } from '../../../actions/navigation/mediaManagement/mediaUploadHistoryActions';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import ErrorMoal from '../../modal/error-model';
import { CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { MediaManagementAPI } from '../../../service/api';
import _ from 'lodash';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import NoRecordsFound from '../../controls/noRecordsFound';
import { Paginator } from '../../controls/paginator';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { HOVER_TEXT_QUEUED,HOVER_TEXT_UPDATING,HOVER_TEXT_EXPIRED,HOVER_TEXT_COMPLETED,HOVER_TEXT_CANCELED } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import $ from 'jquery';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import GoToPagination from '../../controls/goToPagination';
let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate()));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
sixMonthsFromNow = sixMonthsFromNow.setDate(sixMonthsFromNow.getDate()-1);
let savestartDate = new Date(todaysDate.setMonth(todaysDate.getMonth() +5));
let firstTimeDate = savestartDate;
firstTimeDate = firstTimeDate.setDate(firstTimeDate.getDate()-1);
let saveendDate = Yesterdaydate;

class MediaUploadHistory extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			sortColumn:{path:"", order:"", sort_color:"#63778a"},
			error_modal: {
				open: false,
				message: ""
			},
			startDate: localStorage.getItem('startDate')? moment(localStorage.getItem('startDate')).toDate():firstTimeDate,
			endDate: localStorage.getItem('endDate')? moment(localStorage.getItem('endDate')).toDate():Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			currentPage : 1,
			pageSize: 25,	
			totalPages:null,
			distributionCounts : [],
			Queued : true,
			Updating : true,
			Completed : true,
			Canceled : true,
			Failed : true,
			searchData: null,
		};
	}
	updateDimension() { 
		const windowHeight = $(window).height();
		const $tableBody = $('.statusTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
		$tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom)); 
	}
	componentDidUpdate(prevState) { 
		this.updateDimension();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.setState({
				currentPage: 1
			},() =>{
				this.getMediaUploadHistory(1);
				this.getCount();
			})
		}
	}
	static getDerivedStateFromProps = (props) => ({...props});
	componentDidMount() {
		window.addEventListener('resize', this.updateDimension);
		document.body.style.backgroundColor = "#F2F2F2";
		this.props.searchVisible(true);
		this.props.getMediaUploadHistory(this.state.startDate,this.state.endDate,this.state.currentPage, this.state.pageSize);
		this.getMediaUploadHistory().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					//To avoid the two popup at the same time
					if (data.errorCode && data.errorCode === '403100'){
						this.setState({
							loaderIconVisible: false,
						},() => {					
						});
						return;
					}
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.message : ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		this.interval = setInterval(() => this.props.getMediaUploadHistory(this.state.startDate,this.state.endDate, this.state.currentPage, this.state.pageSize), 30000);
		this.getCount();
	}

	getMediaUploadHistory = () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}

		// User Story 48542 - Added this to show the selected status items
		let {Queued,Updating,Completed,Failed,Canceled} =  this.state;
		let filtertypeVa="";

		if (Queued){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "Queued";
			} else {
				filtertypeVa = "Queued";
			}
		}

		if (Updating){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "IN_PROGRESS";
			} else {
				filtertypeVa = "IN_PROGRESS";
			}
		}
		
		if (Completed){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "SUCCEEDED";
			} else {
				filtertypeVa = "SUCCEEDED";
			}
		}

		if (Canceled){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "CANCELED";
			} else {
				filtertypeVa = "CANCELED";
			}
		}
		
		if (Failed){
			if (filtertypeVa){
				filtertypeVa = filtertypeVa  + "," + "REJECTED,FAILED";
			} else {
				filtertypeVa = "REJECTED,FAILED";
			}
		}

		let url = `${MediaManagementAPI.getUploadHistory}`;
		let pageNumber = '?pageNum=' + this.state.currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;
		
		url = url + pageNumber + pageLimit;
		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if( _search ){
			url = `${url}${_search}`;
		}
		
			return new Promise((resolve, reject) => {
			http.get(url,
            {
				headers: {
					'Content-Type': 'application/json',
					'customerId': CUSTOMER_ID,
					'brandId': BRAND_ID,
					'cgids': cgids,
					'basecountry': BASECOUNTRY_ID,
					'filtertype': filtertypeVa? filtertypeVa : "UNSELECTALL",
					'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
					'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
				},
				data: {}
			}).then(response => {
				this.setState({
					loaderIconVisible: false,
					mediaUploadHistoryResponse: response.data.mediaDistributeResponse,
					totalPages: response.data.mediaDistributeResponse && response.data.mediaDistributeResponse.numberOfPages ? response.data.mediaDistributeResponse.numberOfPages : 0,

				});
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					mediaUploadHistoryResponse:[],
					totalPages:0
				})
				reject(err);
			});
		});
	}

	// User Story 48542 - Added getcount to update the count of items per status of all pages
	getCount = async () => {
		this.setState({ loaderIconVisible: true });
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		
		let filtertypeVa="Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";

		let headerVal = {
			'Content-Type': 'application/json',
			'basecountry': BASECOUNTRY_ID,
			'customerId': CUSTOMER_ID,
			'brandId': BRAND_ID,
			'cgids': cgids,	
			"filtertype" : 	filtertypeVa,
			'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};
        
		let url = `${MediaManagementAPI.getUploadHistory}`;
		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if( _search ){
			url = `${url}?${_search}`;
		}
		http.get(url, {
			headers: headerVal, 
			data: {}
			}).then(response => {
				if (response && response.data) {
					this.setState({
						distributionCounts: response.data.mediaDistributeResponse.statusCount
					});
				}
		}).catch(err => {
				this.setState({ 
					distributionCounts: []
				});
		});
	}
	
	componentWillUnmount() {
		clearInterval(this.interval);
		localStorage.removeItem('startDate');
		localStorage.removeItem('endDate');
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	// clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	closeModel= (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
			currentPage: 1
		},()=>{
			localStorage.setItem('startDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1581"
					}
				});
			} else {
				this.getMediaUploadHistory();
				this.props.getMediaUploadHistory(date,this.state.endDate, this.state.currentPage, this.state.pageSize)
				this.getCount();
			}
		});
	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date,
			currentPage:1
		},()=>{
			localStorage.setItem('endDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1120"
					}
				});
			} else {
				WoopraEvents(`${Constants.DATE_APPLIED}`);
				this.getMediaUploadHistory();
				this.props.getMediaUploadHistory(this.state.startDate,date, this.state.currentPage, this.state.pageSize);
				this.getCount();
			}
		});
		
	}

	
	/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
	 onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true, currentPage },async()=>{
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			this.getMediaUploadHistory()
			await this.props.getMediaUploadHistory(this.state.startDate,this.state.endDate, this.state.currentPage, this.state.pageSize);
			this.setState({loaderIconVisible: false})
		});
	}

	// Woopra Events
	trackEvent =(e, title = '') => {
		WoopraEvents(`${Constants.MEDIA}-${title || e.target.title}`)
	}

	// User Story 48542 - Added this to show the selected status items
	checkBoxHandler = (e) =>{
		let isChecked = e && e.target && e.target.checked;	
		let selCheckboxName=e && e.target && e.target.value;	
		this.setState({
			[e.target.value]:isChecked,
			currentPage: 1
		},() =>{
			this.getMediaUploadHistory()
		})
	}

	render() {
		const { mediaUploadHistoryInfo } = this.props;
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible, mediaUploadHistoryResponse, error_modal, currentPage, pageSize } = this.state;
		let { sortColumn } = this.state;
		let updatequeued = 0;
		let updating = 0;
		let updatecomplete = 0;
		let updatefailed = 0;
		let canceled = 0;

		let {Queued,Updating,Completed,Failed,Canceled,distributionCounts} =  this.state;

		let columns = [
			{ path:"", label:"#"},
			{ path:"BRAND", labelStringID:"KC0062"},
			{ path:"ModelName", labelStringID: "KC0064" },
			{ path:"UNITID", labelStringID:"KC0104"},
			{ path:"DISTRIBUTION_STATE", labelStringID:"KC0523"},
			{ path:"", label:"", isNbSp:true},
			{ path:"", label:"", isNbSp:true},
			{ path:"", label:"", isNbSp:true},
		];

		let mediaUploadHistoryList = mediaUploadHistoryResponse && mediaUploadHistoryResponse.mediaDistributeList && 
		mediaUploadHistoryResponse.mediaDistributeList.length>0 ? mediaUploadHistoryResponse.mediaDistributeList : [];	

		// User Story 48542 - Added this to update the count of items per status of all pages
		if (distributionCounts) {
			updatequeued = distributionCounts.Queued ? distributionCounts.Queued : 0;
			updating = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			updatecomplete = distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;			
			updatefailed = distributionCounts.FAILED ? distributionCounts.FAILED : 0;
			canceled = distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;						
		}

		mediaUploadHistoryList.forEach(item => {
			item.status = item.DISTRIBUTION_STATE === 'FAILED' ? "update failed" : item.DISTRIBUTION_STATE === 'REJECTED' ? "update failed" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "update complete" : item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? "updating" : item.DISTRIBUTION_STATE === 'CANCELED' ? 'update canceled' : "update queued"
		});

		mediaUploadHistoryList = _.orderBy(mediaUploadHistoryList , [sortColumn.path], [sortColumn.order]);

		const Deleteimage = require("../../../images/icons/DeleteIcon.png");
		const zipimage = require("../../../images/icons/ZipIcon.png");
		let rowNumber = (currentPage * pageSize) - pageSize;

		return (
            <React.Fragment>
    <div id="adminWrapper">
        {/* <!-- Loading Progress --> */}
        <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
            <div className="loadingProgressIn"></div>
        </div>
        {/* <!-- //Loading Progress -->
        <!-- Fixed Header navbar --> */}
        
        {/* <!-- //Fixed Header navbar --> */}
 
        <div id="page-content-wrapper">
			
			<div className="mediaWrapper"> 
				<div className="tableHNav">
					<ul>
						<li>
							<Link to="/mediaLibraryList" title={formatMessage({ id: 'KC0714' })} onClick={(e)=> this.trackEvent(e, 'Media Libraries')}><FormattedMessage id="KC0714"/></Link>
							<Link to="#" title={formatMessage({ id: 'KC0713' })} className="active" onClick={(e)=> this.trackEvent(e, 'Upload History')}><FormattedMessage id="KC0713"/></Link>
							<Link to="/mediaStatus" title={formatMessage({ id: 'KC0523' })} onClick={(e)=> this.trackEvent(e, 'Status')}><FormattedMessage id="KC0523"/></Link>
						</li> 
					</ul>
				</div>
                <div className="selectedUnitHeader">
					<ul>
						<li> 
							<div className="checkBoxListGrp">
								<div className="customCheckbox nonCrticalCBox">
									<input onClick={(e) => this.checkBoxHandler(e)} id="Queued" type="checkbox" value="Queued" checked = {Queued}/>
									<label for="Queued" title={formatMessage({ id: 'KC1728' })}><FormattedMessage id="KC0691"/> <span>{updatequeued}</span></label>
								</div>
								<div className="customCheckbox">
									<input onClick={(e) => this.checkBoxHandler(e)} id="Updating" type="checkbox" value="Updating" checked = {Updating}/>
									<label for="Updating" title={formatMessage({ id: 'KC1729' })}><FormattedMessage id="KC0692"/> <span>{updating}</span></label>
								</div>
								<div className="customCheckbox">
									<input onClick={(e) => this.checkBoxHandler(e)} id="Failed" type="checkbox" value="Failed" checked = {Failed}/>
									<label for="Failed" title={formatMessage({ id: 'KC1730' })}><FormattedMessage id="KC1119"/> <span>{updatefailed}</span></label>
								</div>
								<div className="customCheckbox">
									<input onClick={(e) => this.checkBoxHandler(e)} id="Completed" type="checkbox" value="Completed" checked = {Completed}/>
									<label for="Completed" title={formatMessage({ id: 'KC1731' })}><FormattedMessage id="KC1118"/> <span>{updatecomplete}</span></label>
								</div>
								<div className="customCheckbox">
									<input onClick={(e) => this.checkBoxHandler(e)} id="Canceled" type="checkbox" value="Canceled" checked = {Canceled}/>
									<label for="Canceled" title={formatMessage({ id: 'KC1732' })}><FormattedMessage id="KC0694"/> <span>{canceled}</span></label>
								</div>
							</div>
						</li>
						<li>
							<div className="dateRangeForm">
								<span><FormattedMessage id="KC0695"/>:</span>
								<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
								<span><FormattedMessage id="KC0696"/>:</span>								
								<DatePicker locale={datePickerLocale} popperModifiers={{preventOverflow: {enabled: true,},}} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate}/>
							</div>
						</li>
					</ul>
				</div>
				<div className="updateStatusTable">
					<TableHeader 
								sortColumn ={sortColumn}
								onSort={this.onSort}
								columns={columns}/>
				</div> 
				<div className="statusTableSrlBar">
					<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
								{/* <!-- Header 01 --> */}
					{mediaUploadHistoryList.map((item,i) => {
						let MOBILE_MODELFAMILY_COLUMN = item.ModelFamily === "eikon® e1s" ? (<span>eikon<sup>®</sup> e1s</span>) : item.ModelFamily === "eikon® e2s" ? (<span>eikon<sup>®</sup> e2s</span>) : item.ModelFamily === "eikon® e4s" ? (<span>eikon<sup>®</sup> e4s</span>) : (<span>{item.ModelFamily}</span>)
						return (
								<React.Fragment>
								<div className="updateStatusTable">
									<ul className="tBody" key={i}>
										<li>{ rowNumber +i +1 }</li>
										<li>{item.BRAND}
										<div className="updateStatusSM">{item.ModelName}, {MOBILE_MODELFAMILY_COLUMN}</div>
											<div className="updateStatusSB">
													<div className="progress">
													<div className={item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "progress-bar completedStatus" : "progress-bar updatingSW"} style={{width: item.DISTRIBUTION_PROGRESS+'%'}}></div>
													</div>
											</div>
											<div className="updateStatusSB">{item.status}</div>
											</li> 
										<li>{item.ModelName}</li>
										<li>{item.UNITID}</li>
										<li>{item.status}</li> 
										<li>
									<img src={item.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default : item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png') : require('../../../images/icons/UpdateD.svg').default} alt="" />
										</li>
										<li>
											<div className="progress">
											<div className={item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "progress-bar completedStatus" : "progress-bar updatingSW"} style={{width: item.DISTRIBUTION_PROGRESS+'%'}}></div>
											</div>
										</li>
										<li><button role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc1` + item.ID} aria-expanded="false" aria-controls="availableUpdatesAcc1" className="btn collapseExpand collapsed">&nbsp;</button></li>
									</ul>
								</div>
								{/* <!-- Header 01 Content --> */} 
								<div className="panel-body uploadHistoryExpTable">
									<ul className={item.mediaList.length !== 1 ? "tBody panel-collapse collapse doubleUploadHistory": "tBody panel-collapse collapse singleUploadHistory"} id={`availableUpdatesAcc1` + item.ID} role="tabpanel" aria-labelledby={`availableUpdatesAccH1` + item.ID} key={i}>
										{mediaUploadHistoryList[i].mediaList.map((items,i) =>
									<React.Fragment>
									{i===0 ? <li>&nbsp;</li>:''}																				
									<li><span className="mediaFileSpan"><img src={ items.STATUS ==="DELETED" ? Deleteimage :(items.FILE_FORMAT === '.zip'? zipimage: items.thumbUrl )}  alt="" /></span></li>
									<li>
										<h4>{items.TYPE}</h4>
										<h5>{items.FILE_NAME}</h5>
										<h5>{items.RESOLUTION} <FormattedMessage id="KC1527"/></h5> 
										<h6><FormattedMessage id="KC1734" values={{ size: items.FILE_SIZE }}/></h6>
									</li> 									
									</React.Fragment> 
									)}  
									{item.mediaList.length === 1  ? <React.Fragment><li className="mediaUploadHideLi">&nbsp;</li><li>&nbsp;</li></React.Fragment>:''}
										<li>
											<h4><FormattedMessage id="KC1116"/></h4> 
											<h6><FormattedMessage id="KC1734" values={{ size: item.totalSize }}/></h6>
											<h4><FormattedMessage id="KC1528"/></h4> 
											<h6>{item.DISTRIBUTION_DATE_TIME}</h6>
										</li>
									</ul> 
								</div> 
								</React.Fragment> 
								)
							}
						)}
					</div> 
					{/* <Paginator
						pageNumber={this.state.currentPage}
						pageCount={this.state.totalPages}
						onNextPage={(p) => this.onPageChange(p)}
						onPreviousPage={(p) => this.onPageChange(p)}
						applyClass="tablePagination"
					/>  */}
					{this.state.totalPages && this.state.totalPages > 1 ? <GoToPagination
						totalPageNumber={this.state.totalPages}
						pageNumber={this.state.currentPage}
						navigateTo={(p) => this.onPageChange(p)} /> : null}
			</div>
			</div>
			<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
        </div>
		<UserManagementHeader headerName="Media Management" headerNameStringID="KC0632" activeClass="mediaNav"/>
		<NoRecordsFound loaderIconVisible={loaderIconVisible} length={mediaUploadHistoryList !== undefined ? mediaUploadHistoryList.length : 0} classname={'norecordsfoundbluetext'} />
    </div> 
        </React.Fragment>	
            
		)
	}
};

function mapStateToProps(state) {
	// debugger;
	return {
		mediaUploadHistoryInfo : state.mediaUploadHistoryResponse.mediaUploadHistoryResponse,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaUploadHistory,
		searchVisible, searchText, clearSearchText
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaUploadHistory));

