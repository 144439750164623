import { GET_DATA, SET_DATA } from '../../../actions/stateActionsData/type';

const initialState = {
    UserModel: {
        user: {
            name: "",
            email: "",
            retypeEmail: "",
            country_code: "",
            phoneNumber: "",
            customerId: localStorage.getItem("customerId")
        },
        roleId: 0,
        userGroup: [],
        userPreference: [],
        countryCodeList: []
    }
}

export default function(state = initialState, action){
    switch(action.type) {
        case GET_DATA:
        return {
            ...state,
            UserModel: action.payload
        }
debugger;
        case SET_DATA:
        return {
            ...state,
            UserModel: action.payload
        }
        
        default:
        return state;
    }
}