import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { getSoftwareUpdateStatus } from '../../../actions/navigation/softwareManagement/softwareUpdateStatusActions'
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import _ from 'lodash';
import $ from 'jquery';
import http from '../../../service/httpService';
import { SoftwareManagementAPI,smartGroupStaus,UnitInfo } from '../../../service/api';
import { BRAND_ID,BASECOUNTRY_ID } from '../../../utils/appConstants';
import ErrorMoal from '../../modal/error-model';
import moment from '../../../utils/momentHelper.js';
import appconstants from '../../../utils/appConstants';
import { updateSelectedFilterList,resetSelectedFilterList,} from '../../../actions/listView/locations/filterListAction';
import { Paginator } from '../../controls/paginator';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

class softwareSmartGroupStatus extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			success_modal: {
                open: false,
                message: ""
            },
			error_modal: {
				open: false,
				message: ""
			},
			deleteIcon:false,
			cancelIcon:false,
			retryIcon:false,
			currentPage : 1,
			pageSize: 10,	
			totalPages:null,
			distributionCounts:{},
			accessUnits	:[],
		};
	}
	updateDimension() {
		var windowHeight = $(window).height();
		var tableHNav = $(".tableHNav").height();
		var selectedUnitHeader = $(".selectedUnitHeader").height();
		var statusTableH = $(".statusTable .tHead").height();
		var statusTableSrlBarH = ((windowHeight) - (tableHNav + selectedUnitHeader + statusTableH)) - 70;
		$(".statusTableSrlBar").css("height", statusTableSrlBarH);
	}
	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimension);
		document.body.style.backgroundColor = "#f2f2f2";
		//this.props.getSoftwareUpdateStatus();
		await this.getCount();
		await this.getUnitsViaCGID();
		
		this.getSoftwareUpdateStatus().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.message : ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		// this.interval = setInterval(() => this.props.getSoftwareUpdateStatus(), 30000);
	}

	checkDeleteAccess = async (mediaValue) => {
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.DISTRIBUTED_BY && mediaValue.DISTRIBUTED_BY.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || username === mediaValue.DISTRIBUTED_BY ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		
		return isDelete;
	}

	getCount = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}

		let filtertypeVa="Scheduled,Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";		
		let headerVal = {
			'Content-Type': 'application/json',
			'basecountry': BASECOUNTRY_ID,
			'customerId': localStorage.getItem("customerId"),			
			'brandId':localStorage.getItem("unitBrandId") ,
			'cgids': cgids,
			//"filtertype" : 	filtertypeVa,
		};        
		
		let url = SoftwareManagementAPI.softwareSGStatusList;
        http.get(url, {
			headers: headerVal, 
			data: {}
        }).then(response => {
            if (response && response.data) {
				this.setState({
					distributionCounts : response.data && response.data.statusCount ? response.data.statusCount : {},
				});
            }
        }).catch(err => {
            this.setState({ 
				distributionCounts:{},
			 });
        });
	}

	getSoftwareUpdateStatus = () => {
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		let responseResult = {
			data: []
		}

		let url = SoftwareManagementAPI.softwareSGStatusList;
		let pageNumber = '?pageNum=' + this.state.currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;
		
		url = url + pageNumber + pageLimit;

		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'customerId': localStorage.getItem("customerId"),
					'brandId':localStorage.getItem("unitBrandId") ,
					'cgids': cgids,
					'basecountry': BASECOUNTRY_ID
				},
				data: {}
			}).then(async response => {
				let arr = [];
				let isDelete = false;
                for (let i = 0; i < response.data.softwareDistributeList.length; i++) { 
                    const ele =  response.data.softwareDistributeList[i];                                  
                    isDelete = await this.checkDeleteAccess(ele);
					if (isDelete){
						isDelete = await this.checkAccessToPerform(ele);
					}					
					arr.push({...ele,isAuthorized: isDelete})
                };

				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation: arr,
					totalPages: response.data && response.data.numberOfPages ? response.data.numberOfPages : 0,
				});
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation:[],
					totalPages:0
				});
				reject(err);
			});
		});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			deleteIcon:false, 
			cancelIcon:false,
		});
	}

	onSmartGroupClick() {
		WoopraEvents(`${Constants.LIST_STATUS}`);
		this.props.history.push({
            pathname: "/softwareStatus",
        })
    }

	onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false
            },
			deleteIcon:false, 
			cancelIcon:false,
        })
		await this.getCount();
        this.getSoftwareUpdateStatus();
    }  

	onClear = () => {
		this.setState({ loaderIconVisible: true });
        let deleteList=[];
		let reqData;
		let obj={}
        var rolesList = [...this.state.softwareUpdateStatusInformation];
        rolesList.forEach(i => {
            if (i.checked === true) { 
				obj = {
					'sgid' : i.SMG_ID,
					'sgstatus' : i.smartgroupStatus,
					'softwareid': i.SOFTWARE_ID,
				}
                deleteList.push(obj);
            }
        });
			
        reqData = {
            "cancelList": deleteList,
        }
        let url = smartGroupStaus.softwareSGCancelStatus;
        http.put(url,reqData,{
            headers: {
				'Content-Type': 'application/json',
				'customerId': localStorage.getItem("customerId"),
				'brandId':localStorage.getItem("unitBrandId") ,
				'user':localStorage.getItem("user") ,
				'cgids': localStorage.getItem("custGroupID"),
				'basecountry': BASECOUNTRY_ID
			},
        }).then(response => {
			WoopraEvents(`${Constants.CANCEL_DISTRIBUTION}`,reqData);
            let { data } = response;                       
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC1512"
				},
			});                 
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                },
            });
        });
    }

	onDelete = () => {
        this.setState({ loaderIconVisible: true });
        let deleteList=[];
		let reqData;
		let obj={}
        var rolesList = [...this.state.softwareUpdateStatusInformation];
        rolesList.forEach(i => {
            if (i.checked === true) { 
				obj = {
					'sgid' : i.SMG_ID,
					'sgstatus' : i.smartgroupStatus,
					'softwareid': i.SOFTWARE_ID,
				}
                deleteList.push(obj);
            }
        });
			
        reqData = {
            "deleteList": deleteList,
        }
        let url = SoftwareManagementAPI.softwareSGStatusDelete;
        http.delete(url,{
            headers: {
				'Content-Type': 'application/json',
				'customerId': localStorage.getItem("customerId"),
				'brandId':localStorage.getItem("unitBrandId") ,
				'user':localStorage.getItem("user") ,
				'cgids': localStorage.getItem("custGroupID"),
				'basecountry': BASECOUNTRY_ID
			},
            data: reqData,
        }).then(response => {
			WoopraEvents(`${Constants.DELETE_DISTRIBUTION}`,reqData);
            let { data } = response;                       
			this.setState({
				curr_Prod_ID: "",
				curr_Prod_Name: "",
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC1561"
				},
			});            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1511",
                },
            });
        });
	}
	
	onSelectRole = (e, item) => {
        if (this.state.softwareUpdateStatusInformation && this.state.softwareUpdateStatusInformation.length > 0) {
            const rolesList = [...this.state.softwareUpdateStatusInformation];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;
            this.setState({
                softwareUpdateStatusInformation: rolesList,                
			},() => {
				this.controlTheButtonClick(rolesList);
			});
		}            
	}	 

	controlTheButtonClick = (SelData) => {
		let selectedData = SelData.filter(item => item.checked === true && item.isAuthorized);
		let allowCancel = selectedData.length > 0 ? true : false;
		let allowDelete = selectedData.length > 0 ? true : false;
		for (let i = 0; i < selectedData.length; i++) {
			if (allowCancel) {
				allowCancel = selectedData[i].smartgroupCancelFlag ? true : false;
			} else {
				break;
			}
		}

		for (let i = 0; i < selectedData.length; i++) {
			if (allowDelete) {
				allowDelete = selectedData[i].smartgroupDeleteFlag ? true : false;
			} else {
				break;
			}
		}
		this.setState({
			cancelIcon:allowCancel,
			deleteIcon:allowDelete,
		});
	}

	onClickStatusButton = async (value, ID, name, type) => {		
		if (value > 0) {
			await this.removeLocalStorage();

			let editedSmartGroupDetails={};
			editedSmartGroupDetails.field= "smartgroup";
			editedSmartGroupDetails.isChecked = true;
			editedSmartGroupDetails.groupsize = 9;
			editedSmartGroupDetails.value=name;
			this.props.updateSelectedFilterList(editedSmartGroupDetails, appconstants.softwareFilters)
			
			localStorage.setItem("statusType",type);
			this.props.history.push({
				pathname: "/softwareStatus",
				smart_groups_id: ID,
				smart_groups_name: name,
				type,
			});
		};
	};

	removeLocalStorage = async () => {
        localStorage.removeItem(appconstants.softwareFilters);
		localStorage.removeItem("statusType");
        const arr=[];
        this.props.resetSelectedFilterList(arr);
    }

	/*** When navigate new page this event will return current page number @param {*} currentPage  */
	onPageChange = (currentPage) => {
		this.setState({ currentPage, loaderIconVisible: true }, async () => {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			await this.getSoftwareUpdateStatus();
			this.setState({ loaderIconVisible: false })
		});
	}

	getUnitsViaCGID = async () => {
		this.setState({ loaderIconVisible: true })
		await http({
			method: "GET",
			url: UnitInfo.unitList,
			headers: {
				'Content-Type': 'application/json',
				'cgid': localStorage.getItem("custGroupID"),
				'cid': localStorage.getItem("customerId"),
				'brandid': BRAND_ID ? BRAND_ID : "",
				"basecountry": BASECOUNTRY_ID,
				"userid": localStorage.getItem("user"),
			}, data: {},
		}).then(response => {
			if (response && response.data) {
				let unitSelected = [];
				response.data.units && response.data.units.length > 0 && response.data.units.map(item => {
					return unitSelected.push(
						item.UNITID,
					)
				});
				this.setState({ accessUnits: unitSelected, loaderIconVisible: false })
			}

		}).catch(err => {
			this.setState({ loaderIconVisible: false })
		});
	}

	checkAccessToPerform = async (elmVal) => {
		const roleName = localStorage.getItem('roleName') || '';
		if (roleName.toLowerCase() === 'customer admin') {
			return true;
		}
		let haveAccess = true;
		let smgunits = elmVal.availableUnits ? elmVal.availableUnits : [];
		let accessunits = this.state.accessUnits ? this.state.accessUnits : [];
		let difference = smgunits.filter(x => !accessunits.includes(x));
		if (difference.length > 0) {
			haveAccess = false;
		}
		return haveAccess;
	}

	// Woopra Events
    trackEvent = (e) => {
        WoopraEvents(`${Constants.SOFTWARE}-${e.target.title}`);
    }

	// Woopra Events
    trackEvent_For_Cancel = (e) => {
        WoopraEvents(e);
    }

	render() {
		const { formatMessage } = this.props.intl;
		let { loaderIconVisible, sortColumn, softwareUpdateStatusInformation, success_modal,
			deleteIcon, cancelIcon,retryIcon, error_modal,distributionCounts } = this.state;
		const { softwareUpdateStatusInfo } = this.props;

		let columns = [
			{ path: "", label: "", isNbSp: true },			
			{ path: "BRAND", labelStringID: "KC0520" },			
			{ path: "UNITID", labelStringID: "KC0737" },
			{ path: "locationName", labelStringID: "KC0834" },
			{ path: "within_location", labelStringID: "KC0576" },
			{ path: "StartTime", labelStringID: "KC1562" },
			{ path: "EndTime", labelStringID: "KC1563" },
			{ path: "status", labelStringID: "KC0523" },
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "", isNbSp: true },			
		];
		
		let softwareUpdateStatusList = softwareUpdateStatusInformation &&softwareUpdateStatusInformation.length > 0 ? softwareUpdateStatusInformation : [];
		softwareUpdateStatusList = _.orderBy(softwareUpdateStatusList, [sortColumn.path], [sortColumn.order]);

		//Status Count 
		const updatequeued = distributionCounts && distributionCounts.Queued ? distributionCounts.Queued : 0;
		const updating = distributionCounts && distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
		//const pending = distributionCounts && distributionCounts.totalPending ? distributionCounts.totalPending : 0;
		const scheduled = distributionCounts && distributionCounts.Scheduled ? distributionCounts.Scheduled : 0;
		const updatecomplete = distributionCounts && distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;
		const updatefailed = distributionCounts && distributionCounts.FAILED ? distributionCounts.FAILED : 0;
		const canceledCount = distributionCounts && distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress -->*/}
					<div id="page-content-wrapper">

						<div className="softwareUpdateWrapper"> 
							<div className="tableHNav">
								<ul>
									<li>
										<Link to="/softwareLibraryUpdate" title={formatMessage({ id: 'KC0830' })} onClick={(e) => this.trackEvent(e)}><FormattedMessage id="KC0830"/></Link>
										<Link to="#" title={formatMessage({ id: 'KC0659' })} className="active" onClick={(e) => this.trackEvent(e)}><FormattedMessage id="KC0523"/></Link>
									</li>
									<li>    
										<button type="button" className="btn gridSG active">&nbsp;</button>
										<button onClick={() => this.onSmartGroupClick()} type="button" className="btn listView">&nbsp;</button>
										<button type="button" className= {cancelIcon?"btn cancelTransfer":"btn cancelTransfer disabled"} data-toggle="modal" data-target="#CancelConfirmationModal">&nbsp;</button>
										<button type="button" className={deleteIcon?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal">&nbsp;</button>
									</li>
								</ul>
							</div>								
							<div class="selectedUnitHeader">
								<ul>
									<li> 
										<div class="checkBoxListGrp">
											<div class="customCheckbox crticalCBox">													
												<label for="Scheduled"><FormattedMessage id="KC0573"/> <span> {scheduled?scheduled:"-"}</span></label>
											</div>
											<div class="customCheckbox nonCrticalCBox">													
												<label for="Queued"><FormattedMessage id="KC0691"/> <span>{updatequeued?updatequeued:"-"}</span></label>
											</div>
											<div class="customCheckbox">													
												<label for="Updating"><FormattedMessage id="KC0692"/> <span>{updating?updating:"-"}</span></label>
											</div>
											{/* <div class="customCheckbox">													
												<label for="Pending">Pending <span>{pending?pending:"-"}</span></label>
											</div> */}
											<div class="customCheckbox">													
												<label for="Completed"><FormattedMessage id="KC0324"/> <span>{updatecomplete?updatecomplete:"-"}</span></label>
											</div>
											<div class="customCheckbox">				
												<label for="Failed"><FormattedMessage id="KC1552"/> <span>{updatefailed?updatefailed:"-"}</span></label>
											</div>
											<div class="customCheckbox">													
												<label for="Canceled"><FormattedMessage id="KC0694"/> <span>{canceledCount?canceledCount:"-"}</span></label>
											</div>
										</div> 
										
									</li> 
								</ul>
							</div>
							<div className="SWStatusTable">
								<TableHeader
									userMgmtListScreen={true}
									sortColumn={sortColumn}
									onSort={this.onSort}
									columns={columns} />
							</div> 
							<div className="statusTableSrlBar"> 
									<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
										{/* <!-- Header 01 --> */}
										{/* softwareUpdateStatusList */}
										{softwareUpdateStatusInformation && softwareUpdateStatusInformation.map((item, i) => {
											return (
												<React.Fragment key={i}>
													<div className="SWStatusTable">
														<ul className="tBody">
															<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc` + i} aria-expanded="false" aria-controls="availableUpdatesAcc1" className="btn collapseExpand collapsed">&nbsp;</a></li>															
															<li>{item.SMARTGROUPS_NAME}</li>
															<li> {item.FILE_NAME} </li>
															<li>{item.VERSION}</li>
															<li>{item.DISTRIBUTED_BY}</li>
															<li>{item.START_TIME ? moment.GetDateTimeByFormat(item.START_TIME, 'LLL') : ""}</li>
															<li>{item.END_TIME ? moment.GetDateTimeByFormat(item.END_TIME,  'LLL') : ""}</li>
															<li>
																<img src={
																	item.smartgroupStatus.toUpperCase() === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default
																	:	item.smartgroupStatus.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png')  
																	: item.smartgroupStatus.toUpperCase() === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default 																	
																	: item.smartgroupStatus.toUpperCase() === 'SCHEDULED' ? require('../../../images/icons/clockS.svg').default 
																	: item.smartgroupStatus.toUpperCase() === 'OPERATOR-INSTALL' ? require('../../../images/icons/usersD.svg').default
																	: item.smartgroupStatus.toUpperCase() === 'SUCCEEDED' || item.smartgroupStatus.toUpperCase() === 'COMPLETED' ? require('../../../images/icons/confirmGreen.svg').default 
																	: require('../../../images/icons/UpdateD.svg').default} />
															</li>
															<li>
																<div className="progress">
																	{item.failedPercentage !== 0 && <div className="progress-bar warningStatus" style={{width:`${item.failedPercentage}%`}}></div> }
																	{item.completePercentage !== 0 && <div className="progress-bar completedStatus" style={{width:`${item.completePercentage}%`}}></div> }																	
																	{item.cancelledPercentage !== 0 && <div className="progress-bar cancelStatus" style={{width:`${item.cancelledPercentage}%`}}></div> }
																	{item.pendingPercentage !== 0 && <div className="progress-bar pendingSW" style={{width:`${item.pendingPercentage}%`}}></div> }
																</div>
															</li>
															<li>																
																{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1733' })} direction="left" className="targetdisabled">
																	<div className={"customCheckbox customCheckboxWOlabel disabled"}>
																		<input id={i} name={i} type="checkbox"
																			checked={item.checked}
																			disabled
																			onChange={(e) => this.onSelectRole(e, item)} />
																		<label htmlFor={i}></label>
																	</div>
																</Tooltip> : <div className={"customCheckbox customCheckboxWOlabel"}>
																	<input id={i} name={i} type="checkbox"
																		checked={item.checked}
																		onChange={(e) => this.onSelectRole(e, item)} />
																	<label htmlFor={i}></label>
																</div>}
															</li>
														</ul>
													</div>					

													{/* <!-- Header 01 Content --> */}
													<div id={`availableUpdatesAcc` + i} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`availableUpdatesAccH` + i}>
														<div className="panel-body SWStatusExpTable">
														<div class="SWsExpTable failed">
																<ul onClick={(e) => this.onClickStatusButton(item.failedUnitCount?item.failedUnitCount:0, item.SMARTGROUPS_ID,item.SMARTGROUPS_NAME,`REJECTED,FAILED`)}>
																	<li className="failed"><img src={ require('../../../images/icons/highLevel.svg').default }/></li>
																	<li title = {item.SMARTGROUPS_NAME} id = {item.SMARTGROUPS_ID} type = "REJECTED,FAILED" value = {item.failedUnitCount?item.failedUnitCount:0}>
																		<h4><FormattedMessage id="KC1552"/></h4>
																		<h5 title = {item.SMARTGROUPS_NAME} id = {item.SMARTGROUPS_ID} value = {item.failedUnitCount?item.failedUnitCount:0}>{item.failedUnitCount || 0} <FormattedMessage id="KC0045"/> / {item.failedPercentage.toFixed(2)} %</h5>																		
																	</li>
																</ul>
															</div>
															<div class="SWsExpTable completed">
																<ul onClick={(e) => this.onClickStatusButton(item.completedUnitCount?item.completedUnitCount:0, item.SMARTGROUPS_ID,item.SMARTGROUPS_NAME,`SUCCEEDED`)}>
																	<li className="completed"><img src={ require('../../../images/icons/Confirm_A.svg').default }/></li>
																	<li title = {item.SMARTGROUPS_NAME} id = {item.SMARTGROUPS_ID} type = "SUCCEEDED" value = {item.completedUnitCount?item.completedUnitCount:0}>
																		<h4><FormattedMessage id="KC1564"/></h4>
																		<h5>{item.completedUnitCount || 0} <FormattedMessage id="KC0045"/> / {item.completePercentage.toFixed(2)} %</h5>
																	</li>
																</ul>
															</div>
															<div class="SWsExpTable inProgress">
																<ul onClick={(e) => this.onClickStatusButton(item.pendingUnitCount?item.pendingUnitCount:0, item.SMARTGROUPS_ID,item.SMARTGROUPS_NAME,`IN_PROGRESS`)}>
																	<li className="inProgress"><img src={ require('../../../images/icons/refresh.svg').default }/></li>
																	<li title = {item.SMARTGROUPS_NAME} id = {item.SMARTGROUPS_ID} type = "IN_PROGRESS" value = {item.inprogressUnitCount?item.inprogressUnitCount:0}>
																		<h4><FormattedMessage id="KC0693"/></h4>
																		<h5>{item.pendingUnitCount || 0} <FormattedMessage id="KC0045"/> / {item.pendingPercentage.toFixed(2)} %</h5>
																	</li>
																</ul>
															</div>
															<div class="SWsExpTable canceled">
																<ul onClick={(e) => this.onClickStatusButton(item.cancelledUnitCount?item.cancelledUnitCount:0, item.SMARTGROUPS_ID,item.SMARTGROUPS_NAME,`CANCELED`)}>
																	<li  className="canceled"><span className="text-white ">X</span></li>
																	<li title = {item.SMARTGROUPS_NAME} id = {item.SMARTGROUPS_ID} type = "CANCELED" value = {item.cancelledUnitCount?item.cancelledUnitCount:0}>
																		<h4><FormattedMessage id="KC0694"/></h4>
																		<h5>{item.cancelledUnitCount || 0} <FormattedMessage id="KC0045"/> / {item.cancelledPercentage.toFixed(2)} %</h5>																	
																	</li>
																</ul>
															</div>
															<div class="SWsExpTable totalUnitsDiv">
																<ul>
																	<li>&nbsp;</li>
																	<li>
																	<h4><FormattedMessage id="KC1539"/></h4>
																	<h5>
																		{`${Number(item.failedUnitCount)+Number(item.completedUnitCount)+Number(item.cancelledUnitCount)} / ${Number(item.failedUnitCount)+Number(item.completedUnitCount)+Number(item.pendingUnitCount)+Number(item.cancelledUnitCount)}` }
																	</h5>
																	</li>
																</ul>
															</div>
														</div>
													</div>

												</React.Fragment>
											)
										})
										}
									</div>
									<Paginator
										pageNumber={this.state.currentPage}
										pageCount={this.state.totalPages}
										onNextPage={(p) => this.onPageChange(p)}
										onPreviousPage={(p) => this.onPageChange(p)}
										applyClass="tablePagination"
									/> 
							</div>

						</div>
						<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					</div>
					{/* Modal for cancel */}
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="CancelConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >
								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC1513"/>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Action Canceled")}><FormattedMessage id="KC0580"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onClear()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
								</div>
							</div>
						</div>
					</div>		

					{/* Modal for delete */}
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >
								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC1565"/>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={() => this.trackEvent_For_Cancel("Canceled smartgroup Deletion")}><FormattedMessage id="KC0580"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.onDelete()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
								</div>
							</div>
						</div>
					</div>					
					<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				</div>
			</React.Fragment>

		)
	}
};

function mapStateToProps(state) {
	return {
		softwareUpdateStatusInfo: state.softwareUpdateStatusInformation.softwareUpdateStatusInformation,
	}
}

function mapDispatchToProps(dispatch) {	
	return bindActionCreators({
		getSoftwareUpdateStatus,
		updateSelectedFilterList,
		resetSelectedFilterList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(softwareSmartGroupStatus));